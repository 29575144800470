import { useEffect, useState, useRef } from "react";
import { instance, ModalInfo, ModalInfo1 } from "../../utils/utils";
export const Lesiones = (props) => {
  const elevvaT = props.elevvaT;
  const nameFigure=props.nameFigure || "figureParts"
  const classMargin=props.classMargin || "m-auto"
  const backView = props.backView;
  const updateLesion = props.updateLesion;
  const frontalView = props.frontalView;
  const modalesDolores = props.modalesDolores;
  const modalesDoloresBack = props.modalesDoloresBack;
  const sendDataLes = props.sendDataLes;
  const injuriesDelete = props.injuriesDelete;
  const infoLesiones=props.infoLesiones || null;
  const showDolor = (ref) => {
    const all = document.getElementsByClassName("all");

    for (const i of all) {
      i.style.display = "none";
    }

    ref.current.style.display = "flex";
  };

  const doloresFrontales = [
    ["Cuello", "17%", "45%", useRef(null), useRef(null)],
    ["Hombro derecho", "21%", "22%", useRef(null), useRef(null)],
    ["Codo derecho", "35%", "18%", useRef(null), useRef(null)],
    ["Muñeca/Mano derecha", "50%", "15%", useRef(null), useRef(null)],
    ["Hombro izquierdo", "21%", "66%", useRef(null), useRef(null)],
    ["Codo izquierdo", "35%", "70%", useRef(null), useRef(null)],
    ["Muñeca/Mano izquierda", "50%", "74%", useRef(null), useRef(null)],
    ["Abdomen", "34.5%", "44%", useRef(null), useRef(null)],
    ["Ingle / Cadera", "42%", "44%", useRef(null), useRef(null)],
    ["Rodilla Izquierda", "67%", "57%", useRef(null), useRef(null)],
    ["Rodilla derecha", "67%", "30%", useRef(null), useRef(null)],
    ["Pie Izquierdo", "83%", "57%", useRef(null), useRef(null)],
    ["Pie derecho", "83%", "29%", useRef(null), useRef(null)],
  ];
  const doloresPosteriores = [
    ["Columna alta", "17%", "46%", useRef(null), useRef(null), useRef(null)],
    ["Columna baja", "26.5%", "46%", useRef(null), useRef(null), useRef(null)],
    ["Lumbares", "36%", "46%", useRef(null), useRef(null), useRef(null)],
    ["Gluteos", "46%", "46%", useRef(null), useRef(null), useRef(null)],
  ];
  useEffect(() => {
    modalesDolores.current = doloresFrontales.map((dolor, index) => {
      return (
        <ModalInfo1
          key={index}
          position={props.position}
          ref={dolor[3]}
          textDelete="Borrar lesión"
          handleClick={() => ocultarDolor(dolor[3])}
          handleClose={()=>cerrarDolor(dolor[3])}
          handleDelete={(e) =>
          eliminarDolor(convertId(parseInt(index), false, false)) ||cerrarDolor(dolor[3])
        }
          title={dolor[0]}
          text="input"
          display="none"
          handleInput={(e) => changeDolorFrontal(index, e.target.value)}
        />
      );
    });
    modalesDoloresBack.current = doloresPosteriores.map((dolor, index) => {
      return (
        <ModalInfo1
          key={index}
          position={props.position}
          ref={dolor[3]}
          textDelete="Borrar lesión"
          handleClick={() => ocultarDolor(dolor[3])}
          handleClose={()=>cerrarDolor(dolor[3])}
          handleDelete={(e) =>
          eliminarDolor(convertId(parseInt(index), false, true)) ||cerrarDolor(dolor[3])
        }
          title={dolor[0]}
          text="input"
          display="none"
          handleInput={(e) => changeDolorPosterior(index, e.target.value)}
        />
      );
    });
    
  }, [doloresFrontales, doloresPosteriores]);
  const [msgDoloresFrontales, setFrontalMsg] = useState(
    new Array(doloresFrontales.length).fill("")
  );

  const [oldMsgFront, setOldFront] = useState(
    new Array(doloresFrontales.length).fill("")
  );
  const [Modal, setModal] = useState(null);
  //console.log(msgDoloresFrontales, "msgDoloresFrontales")
  const [msgDoloresPosteriores, setPosteriorMsg] = useState(
    new Array(doloresPosteriores.length).fill("")
  );

  const [oldMsgBack, setOldBack] = useState(
    new Array(doloresPosteriores.length).fill("")
  );
  //console.log(msgDoloresPosteriores, "soy mensaje");
  const botonesDolores = doloresFrontales.map((dolor, index) => {
    return (
      <span key={index} style={{ cursor: "pointer" }}>
        <img
          src={process.env.PUBLIC_URL + "/assets/img/dolorA.svg"}
          ref={dolor[4]}
          className={`dolor rounded-circle`}
          style={{
            top: dolor[1],
            left: dolor[2],
          }}
          onClick={() => showDolor(dolor[3])}
        ></img>
      </span>
    );
  });
  const botonesDoloresBack = doloresPosteriores.map((dolor, index) => {
    return (
      <span key={index} style={{ cursor: "pointer" }}>
        <img
          src={process.env.PUBLIC_URL + "/assets/img/dolorA.svg"}
          ref={dolor[4]}
          className="dolor rounded-circle"
          style={{ top: dolor[1], left: dolor[2] }}
          onClick={() => showDolor(dolor[3])}
        ></img>
      </span>
    );
  });

  const dolorActivoFrontal = new Array(doloresFrontales.length).fill(false);
  const dolorActivoBack = new Array(doloresPosteriores.length).fill(false);
  function convertId(id, id_to_index = true, is_back = false) {
    if (id_to_index) {
      switch (id) {
        case 1:
          return [0, true]; //p2_cuello_data => Cuello
        case 3:
          return [1, true]; // p1_ombro1_data => hombro derecho
        case 5:
          return [2, true]; //p2_codo2_data => Codo derecho
        case 7:
          return [3, true]; //p1_mano1_data => Mano Derecha
        case 2:
          return [4, true]; //p1_ombro2_data => hombro izquierdo
        case 4:
          return [5, true]; //p2_codo1_data => Codo izq
        case 6:
          return [6, true]; //p1_mano2_data => Mano Izquierda
        case 8:
          return [7, true]; //p1_abdomen_data => Abdomen
        case 9:
          return [8, true]; //p1_cadera_data => Ingle Cadera
        case 10:
          return [9, true]; //p1_rodilla2_data => Rodilla Izquierda
        case 11:
          return [10, true]; //p1_rodilla1_data => Rodilla  derecha
        case 12:
          return [11, true]; //p1_pie2_data => Pie izqueirdo
        case 13:
          return [12, true]; //p1_pie1_data => Pie derecho

        case 14:
          return [0, false]; //p2_espalda_data => Columna alta
        case 16:
          return [1, false]; //p2_colubna_data => Columna baja
        case 17:
          return [2, false]; //p1_muslo2_data => Lumbares
        case 15:
          return [3, false]; //p1_muslo1_data => Gluteos
        default:
          return [-1, false];
      }
    } else {
      if (is_back) {
        switch (id) {
          case 0:
            return 14; //p2_espalda_data => Columna alta
          case 1:
            return 16; //p2_colubna_data => Columna baja
          case 2:
            return 17; //p1_muslo2_data => Lumbares
          case 3:
            return 15; //p1_muslo1_data => Gluteos
          default:
            return [-1, false];
        }
      } else {
        switch (id) {
          case 0:
            return 1; //p2_cuello_data => Cuello
          case 1:
            return 3; // p1_ombro1_data => hombro derecho
          case 2:
            return 5; //p2_codo2_data => Codo derecho
          case 3:
            return 7; //p1_mano1_data => Mano Derecha
          case 4:
            return 2; //p1_ombro2_data => hombro izquierdo
          case 5:
            return 4; //p2_codo1_data => Codo izq
          case 6:
            return 6; //p1_mano2_data => Mano Izquierda
          case 7:
            return 8; //p1_abdomen_data => Abdomen
          case 8:
            return 9; //p1_cadera_data => Ingle Cadera
          case 9:
            return 10; //p1_rodilla2_data => Rodilla Izquierda
          case 10:
            return 11; //p1_rodilla1_data => Rodilla  derecha
          case 11:
            return 12; //p1_pie2_data => Pie izqueirdo
          case 12:
            return 13; //p1_pie1_data => Pie derecho
          default:
            return [-1, false];
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (sendDataLes) {
      for (const key in msgDoloresPosteriores) {
        if (
          msgDoloresPosteriores[key].normalize() != oldMsgBack[key].normalize()
        ) {
          const formData1 = new FormData();
          formData1.append("part", convertId(parseInt(key), false, true));
          formData1.append("summary", msgDoloresPosteriores[key]);
          formData1.append("customer", localStorage.uuid);
          formData1.append("active", msgDoloresPosteriores[key] ? true : false);

          instance
            .post(`customers/injuries/new/`, formData1)
            .then((response) => {
              const data = response.data;
              console.log(data);
            })
            .catch((err) => {
              if (err.response.status === 403) {
                setModal(
                  <ModalInfo
                    error="false"
                    handleClick={() => setModal(null)}
                    textButtonConfirmar={"Mejorar mi plan"}
                    classcorrecta={"recuerdaTitleBlock"}
                    handleCancellcargue={() => setModal(null)}
                    title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
                    text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
                    classesMsg="colorText_Modal"
                  />
                );
              }
            });
        }
      }
      for (const key in msgDoloresFrontales) {
        if (
          msgDoloresFrontales[key].normalize() != oldMsgFront[key].normalize()
        ) {
          const formData1 = new FormData();
          formData1.append("part", convertId(parseInt(key), false, false));
          formData1.append("summary", msgDoloresFrontales[key]);
          formData1.append("customer", localStorage.uuid);
          formData1.append("active", msgDoloresFrontales[key] ? true : false);
          instance
            .post(`customers/injuries/new/`, formData1)
            .then((response) => {
              const data = response.data;
            })
            .catch((err) => {
              if (err.response.status === 403) {
                setModal(
                  <ModalInfo
                    error="false"
                    handleClick={() => setModal(null)}
                    textButtonConfirmar={"Mejorar mi plan"}
                    classcorrecta={"recuerdaTitleBlock"}
                    handleCancellcargue={() => setModal(null)}
                    title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
                    text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
                    classesMsg="colorText_Modal"
                  />
                );
              }
            });
        }
      }
    }
  }, [sendDataLes]);
  function setDoloresRender(info) {
    for (const les of info.sort(function (a, b) {
      const fechaA = b.id;
      const fechaB = a.id;
      if (fechaA > fechaB) {
        return -1;
      }
      if (fechaA < fechaB) {
        return 1;
      }
      // names must be equal
      return 0;
    })) {
      // alert(info);
      const msg = les.summary;

      const [id, isFront] = convertId(les.part, true, true);

      if (id == -1) {
        continue;
      }
      if (isFront) {
        changeDolorFrontal(id, msg, true);
      } else {
        changeDolorPosterior(id, msg, true);
      }
    }
  }

  useEffect(() => {
    if (
      infoLesiones &&
      infoLesiones.length > 0 &&
      nameFigure == "figurePartsPerfilNew"
    ) {
      setDoloresRender(infoLesiones);
    }
    return () => {};
  }, [infoLesiones]);

  const ocultarDolor = (ref) => {
    if (nameFigure == "figurePartsPerfilNew") {
      updateLesion();
    }
    ref.current.style.display = "none";
  };
  const cerrarDolor = (ref) => {
    ref.current.style.display = "none";
  };
  const changeDolorFrontal = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresFrontales;

    copy[index] = msg;
    // console.log(copy[index]);
    dolorActivoFrontal[index] = msg != "";
    doloresFrontales[index][4].current.src = dolorActivoFrontal[index]
      ? process.env.PUBLIC_URL + "/assets/img/dolorB.svg"
      : process.env.PUBLIC_URL + "/assets/img/dolorA.svg";
    if (placeTextarea) {
      const modalText = doloresFrontales[index][3];

      if (modalText.current) {
        modalText.current.querySelector("textarea").value = msg;
      }
      const otherCopy = [...copy];
      setOldFront(otherCopy);
      // console.log(modalText.current.querySelector("textarea"));
    }

    setFrontalMsg(copy);
  };
  const changeDolorPosterior = (index, msg, placeTextarea = false) => {
    const copy = msgDoloresPosteriores;

    copy[index] = msg;
    dolorActivoBack[index] = msg != "";
    //doloresPosteriores[index][4].current.style.backgroundColor = (dolorActivoBack[index] ? "red" : "")
    doloresPosteriores[index][4].current.src = dolorActivoBack[index]
      ? process.env.PUBLIC_URL + "/assets/img/dolorB.svg"
      : process.env.PUBLIC_URL + "/assets/img/dolorA.svg";
    if (placeTextarea) {
      const modalText = doloresPosteriores[index][3];

      if (modalText.current) {
        modalText.current.querySelector("textarea").value = msg;
      }
      const otherCopy = [...copy];
      setOldBack(otherCopy);
    }
    setPosteriorMsg(copy);
  };

  function eliminarDolor(entrada) {
    if (entrada) {
      instance
        .get(`customers/injuries/?customer=${localStorage.id}`)
        .then((response) => {
          const info = response.data;

          const buscarId = info
            .filter((e) => e.part == entrada)
            .sort(function (a, b) {
              const fechaA = a.id;
              const fechaB = b.id;
              if (fechaA > fechaB) {
                return -1;
              }
              if (fechaA < fechaB) {
                return 1;
              }
              // names must be equal
              return 0;
            });

          if (buscarId.length > 0) {
            let formData = new FormData();
            formData.append("active", false);
            instance
              .patch(`customers/injuries/${buscarId[0].id}`, formData)
              .then((response) => {
                if (response.data) {
                  injuriesDelete();
                }
                // window.location.reload();
              })
              .catch((err) => {
                if (err.response.status === 403) {
                  setModal(
                    <ModalInfo
                      error="false"
                      handleClick={() => setModal(null)}
                      textButtonConfirmar={"Mejorar mi plan"}
                      classcorrecta={"recuerdaTitleBlock"}
                      handleCancellcargue={() => setModal(null)}
                      title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
                      text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
                      classesMsg="colorText_Modal"
                    />
                  );
                }
              });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }

  return (
    <div className={"position-relative display-block" + ` ${classMargin}`}>
      {Modal}
      <div
        className={`containerCuerpo col-12 ${
          props.forceMediaMatch === undefined ? "col-lg-12 " : null
        }`}
        ref={frontalView}
        style={{ display: "block" }}
      >
        {botonesDolores}{" "}
        <img
          className={nameFigure}
          src={process.env.PUBLIC_URL + "/assets/img/onboarding/FRENTE.png"}
        />
      </div>
      <div
        className={`containerCuerpo col-12  ${
          props.forceMediaMatch === undefined ? "col-lg-12 " : null
        }`}
        ref={backView}
        style={{ display: "none" }}
      >
        {botonesDoloresBack}
        <img
          className={nameFigure}
          src={process.env.PUBLIC_URL + "/assets/img/onboarding/ATRAS.png"}
        />
      </div>
    </div>
  );
};
