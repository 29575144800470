import { Loading } from "../TutorialReserva/stepsHelp";
import { Averages, Graphics, Level, LevelMobile } from "./helpActividad";
import { useState, useEffect } from "react";
import { instance, laptop575 } from "../../utils/utils";
import { ButtonTutorial, ButtonTutorialReserva } from "../Dashboard/helpResponsive";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const ActivityApp = (props) => {

  const [modalInfo, setModalInfo] = useState(null);
  const [alllevels, setalllevels] = useState(null);
  const [correr, setcorrer] = useState(false);
  const [heightjoyride, setheightjoyride] = useState(false);
  const [userData, setUserData] = useState([]);
  const [stepIndex, setstepIndex] = useState(0);
  const stepsMobile = [
    {
      placement: "center",
      target: "body",
     
      content: (
        <div style={{color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
       En tu panel de estadísticas podrás verificar datos e historial acerca de tu entrenamiento a nivel general.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      placement: "bottom",
      target: "#days_estadistica_mobi",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
        En esta sección veras el récord de días que realizaste actividad de tu plan de entrenamiento
        </div>
      ),

      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },

    {
      target: "#graphics_estadisticas_mobi",
      placement: "bottom",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
          Acá encontrarás el histórico de los datos
de tu entrenamiento visualizados
en diversas categorías.
        </div>
      ),

      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#graphics_estadisticas_mobi",
      placement: "right",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
      Puedes visualizar y escoger entre tensión arterial, frecuencia cardiaca, distancia recorrida y quema de calorías respectivamente.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#graphics_estadisticas_mobi",
      placement: "left",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
          Además, puedes comparar y visualizar información semanal, mensual, trimestral o anualmente entre otras opciones.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#promedioEstadisticas_mobi",
      placement: "top",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
        Acá encontrarás el promedio general de tu actividad y entrenamiento dentro de la plataforma.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#nivelEstadisticas_mobi",
      placement: "top",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
     Por último, acá podrás visualizar tu nivel y avance de tu plan de entrenamiento.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
  
  
  ]

  const steps = [
  

    {
      placement: "center",
      target: "body",
     
      content: (
        <div style={{color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
       En tu panel de estadísticas podrás verificar datos e historial acerca de tu entrenamiento a nivel general.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      placement: "bottom",
      target: "#days_estadistica",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
        En esta sección veras el récord de días que realizaste actividad de tu plan de entrenamiento
        </div>
      ),

      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },

    {
      target: "#graphics_estadisticas",
      placement: "bottom",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
          Acá encontrarás el histórico de los datos
de tu entrenamiento visualizados
en diversas categorías.
        </div>
      ),

      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#button_estadisticas",
      placement: "right",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
      Puedes visualizar y escoger entre tensión arterial, frecuencia cardiaca, distancia recorrida y quema de calorías respectivamente.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#idJoyOpen",
      placement: "left",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
          Además, puedes comparar y visualizar información semanal, mensual, trimestral o anualmente entre otras opciones.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#promedioEstadisticas",
      placement: "top",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
        Acá encontrarás el promedio general de tu actividad y entrenamiento dentro de la plataforma.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#nivelEstadisticas",
      placement: "top",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
     Por último, acá podrás visualizar tu nivel y avance de tu plan de entrenamiento.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
   
  ];

  const handleJoyrideCallback = (data) => {
    const { status, type, action, index } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setstepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setcorrer(false);
      setstepIndex(0);
    }
  };
  useEffect(() => {
    window.scrollTo( 0, 0 )

    const levels = [];
    const getData = async () => {
      await  instance
      .get("users/current/")
      .then((response) => {
      
        setUserData(response.data);
    
      })
      .catch((error) => {
        console.log(error);
      });

      await instance.get(`customers/level/`).then(async (res) => {
        const daysselected = res.data;

        if (daysselected) {
          for (const i of daysselected) {
            const identrenamiento = i.training;
            const lev = i.level;
            const uuid = i.id;
            const sublev = i.sublevel;
            const current_sesio = i.current_session;
            await instance.get(`trainings/${identrenamiento}`).then((res) => {
              let info = res.data;
              if (info && info.group == false) {
                levels[identrenamiento] = {
                  id: identrenamiento,
                  uuid: uuid,
                  tiene_valoration: info.is_valoration,
                  titulo: info.name.toLowerCase(),
                  descripcion: info.description,
                  level: lev,
                  subnivel: sublev,
                  current_session: current_sesio - 1,
                };
              }
            });
          }

          //
        }
      });
      setalllevels(levels.filter((e) => e && e.tiene_valoration == false));
    };
    getData();
  }, []);
  const totalallevel = alllevels ? alllevels.length : 0;
  const unidadtotal = alllevels
    ? alllevels.map((e) => e.current_session).reduce((a, b) => a + b, 0)
    : 0;
  const ver_level = unidadtotal > 0 ? unidadtotal / totalallevel : 0;
  const now =
    totalallevel == 0
      ? 0
      : Math.round(((unidadtotal / totalallevel) * 100) / 120);
    
  return (<> {modalInfo}

<Joyride
        steps={window.innerWidth < 768 ?stepsMobile: steps}
        run={correr}
        hideCloseButton
        disableScrolling={heightjoyride}
        showSkipButton
        continuous
        stepIndex={stepIndex}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        styles={{
          spotlight: {
            // Set your border-radius here!
            borderRadius: 20,
          },
          options: {
            primaryColor: "#CB3D82",
          },
        }}
        callback={handleJoyrideCallback}
      />
    {laptop575.matches?<div
    className="d-none d-md-block col-10" style={{height:'100%'}}
  >
    <div
      className="position-relative"
      style={{
        background: "rgba(0,0,0,.7)",
        height: "100vh",
        padding: "2% 4% 2% 2%",
      }}
    >
      <div className="d-flex flex-column col-12" style={{ gap: "2%", height: "100%" }}>
      <Graphics heightContainer={"70%"} idJoy={"days_estadistica"} idGraphics={"graphics_estadisticas"} idButtonSelect={"button_estadisticas"} idJoyOpenSelect={"idJoyOpen"} stepIndex={stepIndex}/>
      <div className="d-flex" style={{height:"30%",gap: "1%"}}>
        <Averages widthContainer={"49.5%"} idJoyProm={"promedioEstadisticas"} stepIndex={stepIndex}/>
        <Level userData={userData} stepIndex={stepIndex} widthContainer={"49.5%"} nowProgress={now} idJoyLevel={"nivelEstadisticas"} />
      </div>
            </div>
          </div>
       
        </div>:null}
     <div className="d-md-none  w-100 d-flex flex-column" style={{ height: "100%" }}>
      
    <Graphics buttonBack ={"d-none"} centerTitle={"m-auto fw-bold"} borderBody={"0"}  idJoy={"days_estadistica_mobi"}  idGraphics={"graphics_estadisticas_mobi"} idButtonSelect={"button_estadisticas_mobi"} idJoyOpenSelect={"idJoyOpen_mobi"} stepIndex={stepIndex}/>
        <Averages widthContainer={"100%"} idJoyProm={"promedioEstadisticas_mobi"} stepIndex={stepIndex}/>
         <LevelMobile userData={userData} stepIndex={stepIndex} widthContainer={"100%"} idJoyLevel={"nivelEstadisticas_mobi"} nowProgress={now} mobileOn={true} borderBody={"0"} /> 
         <ButtonTutorialReserva  classButton={"tutorial_styles_mobile_app"} handleClick={()=>setcorrer(!correr)}/>
         <br/>
         <br/>
         <br/>
        </div>
        
        </>)

}
  export default ActivityApp;