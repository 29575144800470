import { LoginComponentUser } from "../../utils/utils";
import './loginStart.css'

const LoginCustomer = (props) => {
  const elevvaT = props.elevvaT;
  const userName = props.type;
  return <LoginComponentUser elevvaT={elevvaT} type={userName} />;
};

export default LoginCustomer;
