
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation ,EffectCoverflow } from "swiper";
import ReactHtmlParser from "react-html-parser";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import cardioProgram from "../../assets/assets/run.png"
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import testClass from "../../assets/assets/testClass.jpg";
import actividad from "../../assets/assets/mi_actividad.png";
import tucuerpo from "../../assets/assets/conoce_cuerpo.png";
import recientes from "../../assets/assets/sesiones_rec.png";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { iconReview, iconReviewColor } from "../Perfil/helpPerfil";
import {
  ButtonMob,
  instance,
  laptop1600,
  laptop575,
  Loadingspinner,
} from "../../utils/utils";
import { useCompareDateOrder } from "../Mis clases/misclases_utils";
import { whatmonthSwiper } from "../TutorialReserva/stepsHelp";
import campana_notifit from "../../assets/assets/campananoti.png";

export const ComponentIcons = (props) => {
  const classPadre = props.classPadre;
  const fontSizeicons = props.fontSizeicons;
  const leftIcon = props.leftIcon;
  const mxIcons = props.mxIcons;
  const pointIcon = props.pointIcon;
  const notifications = props.notificationsq || null;
  const [modalInfo, setmodalInfo] = useState(null);
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 9999,
    whiteSpace: "pre-line",
  };
  function readAll() {
    instance
      .post(`app/notification/read/all`)
      .then((res) => {
        setmodalInfo(null);
      })
      .catch((err) => console.log(err.response));
  }
  function mostrarNotificat() {
    setmodalInfo(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido" style={{ maxWidth: "100%" }}>
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setmodalInfo(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div className="col-12 d-flex justify-content-center my-2"></div>
          <div
            className="title col-12 my-2 fw-bold"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            Notificaciones
          </div>

          {notifications && notifications.length > 0 ? (
            <PerfectScrollbar
              className="title col-12 my-2 mx-auto"
              style={{
                lineHeight: "100%",
                textAlign: "center",
                maxWidth: "20rem",
                height: "15rem",
              }}
            >
              {notifications &&
                notifications.map((noti, index) => (
                  <>
                    <div className="d-flex">
                      <img
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          margin: "auto 1rem auto auto",
                        }}
                        src={campana_notifit}
                      ></img>
                      <span
                        key={index}
                        style={{
                          fontSize: "0.8rem",
                          color: "#9DACBD",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        {noti.message}{" "}
                      </span>
                    </div>
                    <hr></hr>
                  </>
                ))}
            </PerfectScrollbar>
          ) : (
            <span
              style={{ fontSize: "1rem", color: "#9DACBD", textAlign: "left" }}
            >
              {" "}
              No tienes notificaciones
            </span>
          )}
          <ButtonMob handleClick={() => readAll()} text={"Cerrar"}></ButtonMob>
        </div>
      </div>
    );
  }

  return (
    <div className={classPadre}>
      {" "}
      {modalInfo}{" "}
      <span
        id={"MessageTutorial"}
        onClick={() => mostrarNotificat()}
        className={`${mxIcons} d-flex`}
        style={{
          fontSize: fontSizeicons,
          alignItems: "center",
          color: "var(--elevvaArrowColor)",
          cursor: "pointer",
        }}
      >
        <i className="bi bi-envelope-fill position-relative">
          {" "}
          <span
            className="position-absolute"
            style={{
              top: "6%",
              left: leftIcon,
              borderRadius: "50%",
              width: pointIcon,
              height: pointIcon,
              backgroundColor: "var(--colorNotifications)",
            }}
          ></span>
        </i>
      </span>
      <Link
        to={`/perfil`}
        className={`${mxIcons} d-flex`}
        style={{
          fontSize: fontSizeicons,
          alignItems: "center",
          color: "var(--elevvaArrowColor)",
        }}
      >
        <i className="bi bi-person-circle "></i>
      </Link>
    </div>
  );
};

export const ComponentName = (props) => {
  const firstName = props.firstName;
  const lastName = props.lastName;
  const profilePic = props.profilePic;
  const padreclass = props.padreclass;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("/perfil")}
      className={padreclass}
      style={{ justifyContent: "center", cursor: "pointer" }}
    >
      {" "}
      <span
        className="fw-bold  d-flex col-5 mx-auto"
        style={{
          fontSize: "1.6vmin",
          alignItems: "center",
          justifyContent: "center",
          color: "var(--elevvaTextTitle)",
        }}
      >
        <img
          src={profilePic || localStorage.profile_pic}
          alt="Avatar"
          className="rounded-circle imageCustomer"
        />
      </span>
      <span className="fw-bold  mx-auto  d-flex col-7 letraName">
        {/* localStorage.variable, se trata como objeto */}

        {firstName && lastName ? (
          <>
            <span>{`${firstName.toUpperCase()}`}</span>

            <span>{`${lastName.toUpperCase()}`}</span>
          </>
        ) : (
          <>{`${localStorage.first_name.toUpperCase()} ${localStorage.last_name.toUpperCase()}`}</>
        )}
      </span>
    </div>
  );
};

export const SemanaCustomer = (props) => {
  const dayselected = props.dayselected;
  const semanaON = props.semanaON || false;
  const classsemana = props.classsemana || "trainerDays";
  const classDays = props.classDays || "trainerDays_days";
  const idJoy = props.idJoy || "";

  return (
    <div id={idJoy} className={`direcccionately  ${classsemana}`}>
      <span
        style={semanaON ? { paddingTop: "2px" } : null}
        className="fw-bold   mx-auto  d-flex trainerDays_texto"
      >
        Esta semana entrenaste
      </span>
      <div
        className="d-flex mx-auto"
        style={
          semanaON
            ? {
                justifyContent: "space-around",
                width: "60%",
                padding: "1% 0",
              }
            : {
                width: "100%",
                justifyContent: "space-around",
                padding: "2% 0",
              }
        }
      >
        {Object.entries(dayselected).map((er, idx) => (
          <div className="" key={idx}>
            <div
              className={`mx-auto d-flex text-center justify-content-center align-items-center ${classDays}`}
              style={
                er[1]
                  ? {
                      background: "var(--elevva)",
                      borderRadius: "50%",
                      color: "var(--white)",
                    }
                  : {
                      color: "var(--elevvaTexttitleLow)",
                    }
              }
            >
              {er[0].slice(0, 3)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export const NivelCustomer = (props) => {
  const padreclass = props.padreclass;
  const alllevels = props.alllevels;
  const colorClassText = props.colorClassText || " letraPequeña ";
  const perfilMobile = props.perfilMobile || false;
  const userData = props.userData;
  const totalallevel = alllevels ? alllevels.length : 0;
  const unidadtotal = alllevels
    ? alllevels.map((e) => e.current_session).reduce((a, b) => a + b, 0)
    : 0;
  // const ver_level = unidadtotal > 0 ? unidadtotal / totalallevel : 0;
  const [loading, setLoading] = useState(true);
  const now =
    totalallevel == 0
      ? 0
      : Math.round(((unidadtotal / totalallevel) * 100) / 120);

  useEffect(() => {
    if (userData.customer && alllevels) {
      setLoading(false);
    }
  }, [userData, alllevels]);

  return (
    <div className={padreclass} style={{ color: "var(--elevvaTextTitle)" }}>
      {" "}
      {loading ? (
        <span
          className={`col m-auto text-left ${colorClassText}`}
          style={perfilMobile ? { textAlign: "right" } : null}
        >
          <Loadingspinner customStyle="grow" size={"2rem"} />{" "}
        </span>
      ) : (
        <>
          <span
            className={`col mx-auto text-center ${colorClassText}  paddingLevelText`}
          >
            {/* {userData.customer.level == 30
              ? "Avanzado"
              : userData.customer.level == 20
              ? "Intermedio"
              : userData?.customer?.initial_level == 30
              ? "Avanzado"
              : userData?.customer?.initial_level == 20
              ? "Intermedio"
              : "Principiante"} */}
            {userData?.customer?.level == 30
              ? "Avanzado"
              : userData?.customer?.level == 20
              ? "Intermedio"
              : userData?.customer?.level == 10
              ? "Principiante"
              : null}
          </span>
          {now ? (
            <span
              className={`col m-auto text-left ${colorClassText}`}
              style={perfilMobile ? { textAlign: "right" } : null}
            >
              {now}%
            </span>
          ) : null}
        </>
      )}
      {/* <span className="col m-auto text-left letraPequeña">50%</span> */}
    </div>
  );
};

      export const Puntoselevva = (props) => {
        const handleClick = props.handleClick;
        const padreclass = props.padreclass;
        const userData  = props.userData ;
return(
  <div
  className={padreclass}
 
>
  <div
    className="direcccionately col  "
 
  >
    {/* <span className="letrapequeñaProfile">130</span> */}
    <span className="letrapequeñaProfile">{userData?.customer?.mob_points || "000"}</span>
    <span className=" letrapequeñaProfileDown_other">
      Puntos Elevva
    </span>
  </div>
  <div
    className="direcccionately_other col "
    style={{
      borderLeft: "1px solid gray",
      borderRight: "1px solid gray",
    
    }}
  >
    <span className="letrapequeñaProfile">{userData?.customer?.credits_balance || "000"}</span>
    <span className=" letrapequeñaProfileDown_other">
    Créditos
    </span>
  </div>
  <div
    className="direcccionately col "
  
  >
    <button
      onClick={handleClick}
      className="m-auto d-flex buttonVerPlan"
      
    >
      Ver tu plan
    </button>

  </div>
</div>
)

      }

export const RangeProgress = (props)=>{
  const idRange=props.idRange;
  const valPercentage=props.valPercentage;
  const radius=props.radius || "5.2vmin";
 

  return(  <div className="pt-2 pb-1">
  <div className="skill ">
    <div className="outer">
      <div className="inner">
        <div  style={{fontWeight:"bold"}}>{valPercentage || 0}%</div>
      </div>
    </div>
    <svg
      id="svgt"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="100%"
      height="100%"
    >
      <defs>
        <linearGradient id="GradientColor">
          <stop
            offset="100%"
            stopColor="var(--vercardtext)"
            // stop-color="#b4b4b4"
          />
        </linearGradient>
      </defs>
      <circle
        id="circleRound"
        cx="50%"
        cy="50%"
        r={radius}
        strokeLinecap="round"
      />
    </svg>
  </div>
</div>)
}
      export const PromediosPanel = (props) => {
        const TrainingEstadistics= props.TrainingEstadistics;
        const idPanel= props.idPanel;
        const paddingProm = props.paddingProm || "2%" ;
        const widthCases = props.widthCases || "80%";
        const backgroundPromed = props.backgroundPromed || "var(--white)";
       

return (
  <div
  id={idPanel}
  className=" mx-auto d-flex"
  style={{
    width: widthCases,
    background:backgroundPromed,
    borderRadius: "20px",
    padding: paddingProm,
    gap: "2%",
  }}
>

  {TrainingEstadistics.map((e, i) => (
    <div
      key={i}
      style={{
        background: e[2],
        width: "calc(100%/4)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "1.1rem",
        padding: "2%",
      }}
    >
      <button
        style={{
          background: "var(--white)",
          borderRadius: "0.9rem",
          border: "1px solid transparent",
          margin: "0 auto 0 0",
        }}
      >
        <img
          alt="e1"
          src={e[1]}
          style={{ width: "6vmin", height: "6vmin" }}
        />
      </button>

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginTop: "10%",
          justifyContent: "center",
        }}
      >
        <span
          className="fw-bold letraPequeñaotherDashboard"
          style={{ color: e[5], lineHeight: "90%",textAlign:"left" }}
        >
          {ReactHtmlParser(e[0])}
        </span>

        <span
          className=""
          style={{
            color: "var(--elevvaTextTitle)",
            fontSize: "calc(0.6rem + 1.3vmin)",textAlign:"left"
          }}
        >
          {/* Se muestra la carga usuario */}
          {/* {e[4]} */}
          {e[4]}  {e[3] == "por minuto"?<span
          className="letraPequeñaotherDashboard"
          style={{ color: "var(--elevvaTextTitle)",fontWeight:"400",textAlign:"left" }}
        > Pulsaciones</span>:""}
        
        </span>

        <span
          className="letraPequeñaotherDashboard"
          style={{ color: "var(--elevvaTextTitle)" ,textAlign:"left"}}
        >
          {e[3]}
        </span>
      </div>
    </div>
  ))}
</div>
)
      }
      export const PromediosPanelMobile = (props) => {
        const TrainingEstadistics= props.TrainingEstadistics;
        const idPanel= props.idPanel;
        const paddingProm = props.paddingProm || "2%" ;
        const widthCases = props.widthCases || "80%";
        const backgroundPromed = props.backgroundPromed || "var(--white)";
       

return (<div className="d-flex flex-column gap-0 my-1">
  <div
  id={idPanel}
  className=" mx-auto d-flex"
  style={{
    width: "95%",
    background:backgroundPromed,
    borderRadius: "20px",
    padding: paddingProm,
    gap: "2%",
  }}
>

  {TrainingEstadistics.slice(0,3).map((e, i) => (
    <div
      key={i}
      style={{
        background: e[2],
        width: "calc(100%/3)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "1.1rem",
        padding: "3% 4%",
      }}
    >
      <button
        style={{
          background: "var(--white)",
          borderRadius: "0.9rem",
          border: "1px solid transparent",
          margin: "0 auto 0 0",
          padding:"4%"
        }}
      >
        <img className="image-results-promedios"
          alt="e1"
          src={e[1]}
        
        />
      </button>

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginTop: "10%",
          justifyContent: "center",
        }}
      >
        <span
          className="fw-bold letraPequeResults"
          style={{ color: e[5], lineHeight: "120%" }}
        >
          {ReactHtmlParser(e[0])}
        </span>

        <span
          className=""
          style={{
            color: "var(--elevvaTextTitle)",
            fontSize: "calc(0.6rem + 1.3vmin)",
          }}
        >
          {/* Se muestra la carga usuario */}
          {/* {e[4]} */}
          {e[4]}  {e[3] == "por minuto"?<span
          className="letraPequeResults"
          style={{ color: "var(--elevvaTextTitle)",fontWeight:"400" }}
        > Pulsaciones</span>:""}
        
        </span>

        <span
          className="letraPequeResults"
          style={{ color: "var(--elevvaTextTitle)" }}
        >
          {e[3]}
        </span>
      </div>
    </div>
  ))}
 
</div>
 <div
  id={idPanel + 2}
  className=" mx-auto d-flex"
  style={{
    width: "63.33%",
    background:backgroundPromed,
    borderRadius: "20px",
    padding: paddingProm,
    gap: "2%",
  }}
>

  {TrainingEstadistics.slice(3,5).map((e, i) => (
    <div
      key={i}
      style={{
        background: e[2],
        width: "calc(100%/2)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "1.1rem",
        padding: "3% 4%",
      }}
    >
      <button
        style={{
          background: "var(--white)",
          borderRadius: "0.9rem",
          border: "1px solid transparent",
          margin: "0 auto 0 0",
          padding:"4%"
        }}
      >
        <img className="image-results-promedios"
          alt="e1"
          src={e[1]}
          
        />
      </button>

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginTop: "10%",
          justifyContent: "center",
        }}
      >
        <span
          className="fw-bold letraPequeResults"
          style={{ color: e[5], lineHeight: "120%" }}
        >
          {ReactHtmlParser(e[0])}
        </span>

        <span
          className=""
          style={{
            color: "var(--elevvaTextTitle)",
            fontSize: "calc(0.6rem + 1.3vmin)",
          }}
        >
          {/* Se muestra la carga usuario */}
          {/* {e[4]} */}
          {e[4]}  {e[3] == "por minuto"?<span
          className="letraPequeResults"
          style={{ color: "var(--elevvaTextTitle)",fontWeight:"400" }}
        > Pulsaciones</span>:""}
        
        </span>

        <span
          className="letraPequeResults"
          style={{ color: "var(--elevvaTextTitle)" }}
        >
          {e[3]}
        </span>
      </div>
    </div>
  ))}
 
</div>
</div>
)
      }



      export function AllPromedios(props) {
        SwiperCore.use([Pagination, Autoplay, Navigation]);
        const spaceBetween = props.spaceBetween || 0;
        const ancho = props.ancho || '22vmin'
        const alto = props.alto || '34vmin'
        const grow = props.grow || 'growing'
        const widthSwiper = props.widthSwiper || "85vmin";
      
        const classSwiper = props.classSwiper || "py-3";
        const classMarginCard = props.classSwiper || "my-3";
        //const trainingsId = props.trainings
        //const images = props.videos;
        function handleClick(event) {
          props.buttonClick(event);
        }
     
      
        // Filtrar entrenador si es para clases grupales es true
        const TrainingEstadistics = props.TrainingEstadistics && props.TrainingEstadistics;
       
 
        const id = props.id;
        SwiperCore.use([Pagination, Autoplay, Navigation]);
        const render_img_desk =
          TrainingEstadistics &&
          TrainingEstadistics.map((e, i) => {
            return (
              <SwiperSlide 
                key={'tre' + i}
              
                className={props.className}
                style={{ width: ancho, height: alto }}
              >
<div className="d-flex position-relative imagenreserva"
    key={i}
      style={{
        background: e[2],
        width: widthSwiper,
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",
        borderRadius: "1.1rem",
        height:'55%',
        minHeight:'11rem',
        padding: "10%",
      }}
    >
      <button
        style={{
          background: "var(--white)",
          borderRadius: "0.9rem",
          border: "1px solid transparent",
          margin: "0 auto 0 0",
        }}
      >
        <img
          alt="e1"
          src={e[1]}
          style={{ width: "2rem", height: "2rem" }}
        />
      </button>

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginTop: "10%",
          justifyContent: "center",
        }}
      >
        <span
          className="fw-bold letraPequeñaotherDashboard"
          style={{ color: e[5], lineHeight: "90%" }}
        >
          {ReactHtmlParser(e[0])}
        </span>

        <span
          className="fw-bold "
          style={{
            color: "var(--elevvaTextTitle)",
            fontSize: "1rem",
          }}
        >
          {/* Se muestra la carga usuario */}
          {/* {e[4]} */}
          {e[4]}
        
        </span>

        <span
          className="letraPequeñaotherDashboard"
          style={{ color: "var(--elevvaTextTitle)" }}
        >
          {e[3]}
        </span>
      </div>
    </div>

              
              </SwiperSlide>
            );
          });
      
      
        return (
          <div className="position-relative reconocer">
            
            <div className={`d-flex swiper-pagination_p  ${classMarginCard}`}>
             
            </div>
            <Swiper 
             id={props.idJoyride || 'swiperIde'}
              style={{ margin: "auto ", width: widthSwiper }}
              className={`slidersNetflixReservas ${classSwiper}`}
            
              modules={[Navigation  ]}
              slidesPerView="auto"
              spaceBetween={spaceBetween}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              slidesPerGroup={1}
              
              slidesPerGroupSkip={1}
            >
          {render_img_desk}
              
            </Swiper>
          </div>
        );
      }

      export function IngresaTension(props){
      
        const widthContainer= props.widthContainer;
        const idJoyride=props.idJoyride;
        const handleClick=props.handleClick;
      
      
        var regex = new RegExp("[0-9]{1,3}");
        let item = document.querySelector(".inputTensionArterial");
      
        item &&item.addEventListener("keypress", (e) => {
       
          if (!regex.test(e.key) || e.target.value.length > 3) {
            e.preventDefault();
            return false;
          }
          let val = e.target.value;
          if (val.length === 3) {
            e.preventDefault()
         
          }
          e.target.value = val;
        });
        item &&item.addEventListener("keyup", (e) => {});
        let item2 = document.querySelector(".inputTensionArterial1");
      
        item2 &&item2.addEventListener("keypress", (e) => {
         
          if (!regex.test(e.key) || e.target.value.length > 3) {
            e.preventDefault();
            return false;
          }
          let val = e.target.value;
          if (val.length === 3) {
            e.preventDefault();
          }
          e.target.value = val;
        });
        item2 &&item2.addEventListener("keyup", (e) => {});
       
      
        return (     
      
       
        <form onSubmit={handleClick}
          className=" mx-auto position-relative d-flex padding_Tension_Container"
          id={idJoyride}
          style={{
            background: "white",
            width: widthContainer,
            
            borderRadius: "20px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
       
          <span
            className="text-center letraPequeña mx-auto"
            style={{
              color: "var(--elevvaTextTitle)",
              lineHeight: "100%",
              maxWidth: "70%",
            }}
          >
            Ingresa <strong>tu tensión arterial</strong>{" "}
          </span>
          <div className="d-flex flex-row mx-auto my-2  my-md-0 col-10 col-md-12 flex-md-column justify-content-center">
          <div className="text-center col-4 col-md-12">
            <img className="heartIngresar"
              alt="tensión arterial"
              src={
                process.env.PUBLIC_URL +
                "/assets/img/tensionarterial3D.svg"
              }
             
            />
          </div>
    
          <div
            style={{ justifyContent: "center" }}
            className="d-flex m-auto col-9 col-md-12"
          >
            <span
              className="letraPequeñaTension_arterial"
              style={{
                color: "var(--elevvaTextTitle)",
               
              }}
            >
           
             <input className="m-auto inputTensionArterial mb-0 mt-auto"  
              
              type="text"
              inputMode="numeric"
                placeholder={"---"}></input>/ <input  className="m-auto inputTensionArterial1 mb-0 mt-auto"
                inputMode="numeric"
               type="text"
               maxLength={3}
                placeholder={"--"}></input>
            </span>
            <span
              className="letraPequeñaotherDashboard mb-0 mt-auto mx-1"
              style={{ color: "var(--elevvaTextTitle)" }}
            >
              mm Hg
            </span>
          </div>
</div>
          <div
            className="py-2 d-flex"
            style={{ justifyContent: "center" }}
          >
            <button 
              className=" d-flex guardarTension"
              type="submit"
            
            >
              Guardar
            </button>
          </div>
        </form>
        )
      }

      export function Proximas(props) {
        const clasesReservadas = props.clasesReserva;
        const handleClick = props.handleClick;
        const stepIndex = props.stepIndex || null;
        const stepIndexMob = props.stepIndexMob || null;
        const widtMap = props.widtMap;
        const idIdentificar = props.idIdentificar;
        const userData = props.userData;
        const planDaySelected = props.planDaySelected;
        const postNextDay = props.postNextDay;
        const verplan = props.verplan;
        const CardioTraining = props.CardioTraining;
        const navigate = useNavigate();
      const BlockTrainingPlan = props.BlockTrainingPlan;
      const planToWork = props.mesPrograma;

      const planReview =
        planToWork?.length == planDaySelected
          ? planDaySelected - 1
          : planDaySelected;
      console.log(planToWork);
      return (
        <div
          id={idIdentificar}
          className={
            userData?.customer?.had_valoration == false
              ? "d-flex flex-column justify-content-center text-center resplandor"
              : `d-flex flex-column justify-content-center text-center `
          }
          style={{
            background: "var(--white)",
            height: "100%",

            margin: "1% 0px 0px",
            gap: "1.5vmin",
            padding: "3% 0%",
            borderRadius: "20px",
          }}
        >
          <span
            className=" letraPequeña px-2"
            style={{ lineHeight: "100%", color: "var(--elevvaTextTitle)" }}
          >
            Próximas actividades <br />
            <strong>de tu plan de entrenamiento</strong>
          </span>

          {planToWork.length > 0 ? (
            <PerfectScrollbar
              className="w-100 text-center d-flex flex-column scrollPlan_proximas"
              style={{ height: "auto" }}
            >
              {(stepIndex && stepIndex == 9) ||
              (stepIndexMob && stepIndexMob == 9) ? (
                <div
                  className=" text-center d-flex flex-row col-12"
                  style={{
                    borderRadius: "20px",
                    padding: "3%",
                    width: widtMap,
                    alignItems: "center",
                  }}
                >
                  <div className="col-2">
                    <img src={cardioProgram}></img>
                  </div>
                  <span
                    className="m-auto letraPequeña col-5"
                    style={{
                      color: "var(--elevvaTextTitle)",
                      padding: "1vmin",
                    }}
                  >
                    <p className="pTextProx">Programa</p>
                    <p className="pTextProx">Cardio</p>
                  </span>
                  <button className="m-auto d-flex col-5 ver_programa_button">
                    Ver <br /> programa
                  </button>
                </div>
              ) : userData?.customer?.had_valoration == true ? (
                <>
                  <div
                    className=" text-center d-flex flex-row col-12"
                    style={{
                      borderRadius: "20px",
                      padding: "3%",
                      width: widtMap,
                      alignItems: "center",
                    }}
                  >
                    <div className="col-2 ">
                      <div
                        className="d-flex flex-column m-auto justify-content-center"
                        style={{
                          background: iconReviewColor(
                            planToWork[planReview].type
                          ),
                          borderRadius: "100%",
                          width: laptop575.matches ? "30px" : "40px",
                          height: laptop575.matches ? "30px" : "40px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="d-flex flex-column m-auto justify-content-center"
                          style={{
                            width: laptop575.matches ? "15px" : "25px",
                            height: laptop575.matches ? "15px" : "25px",
                          }}
                        >
                          {iconReview(planToWork[planReview]?.type)}
                        </div>
                      </div>
                    </div>
                    <span
                      className="m-auto letraPequeña col-5"
                      style={{
                        fontWeight: "600",
                        color: "var(--elevvaTextTitle)",
                        padding: laptop1600.matches
                          ? "1vmin"
                          : laptop575.matches
                          ? "1vmin 3vmin"
                          : "1vmin",
                      }}
                    >
                      <p className="pTextProx" style={{ textAlign: "left" }}>
                        {planToWork[planReview].label}
                      </p>
                    </span>
                    <button
                      style={{ lineHeight: "110%" }}
                      onClick={
                        () =>
                          CardioTraining([
                            planToWork[planReview],
                            planReview + 1,
                          ])
                        // planToWork[planReview].type == "cardio"
                        //   ? CardioTraining([
                        //       planToWork[planReview],
                        //       planReview + 1,
                        //     ])
                        //   : planToWork[planReview].type ==
                        //       "valoracion_fisica" ||
                        //     planToWork[planReview].type ==
                        //       "valoracion_nutricional" ||
                        //     planToWork[planReview].type == "reserva"
                        //   ? navigate(
                        //       `/training/${planToWork[planReview].id_content}`
                        //     )
                        //   : planToWork[planReview].type == "video"
                        //   ? navigate("/videos/card/6/1")
                        //   : planToWork[planReview].type == "grupal"
                        //   ? navigate(`/reservas`)
                        //   : verplan()
                      }
                      className="my-auto mx-2 d-flex col-5 ver_programa_button py-2"
                    >
                      Ver actividad
                    </button>
                  </div>
                  <button
                    style={{
                      lineHeight: "110%",
                      background: "white",
                      color: "var(--elevva)",
                      width: "70%",
                      fontWeight: "600",
                    }}
                    onClick={() => postNextDay(planToWork[planReview].type)}
                    className="m-auto d-flex col-5 ver_programa_button"
                  >
                    Confírmanos si ya completaste <br />{" "}
                    {planToWork[planReview].label} !
                  </button>
                </>
              ) : clasesReservadas && clasesReservadas.length > 0 ? (
                <div
                  className=" text-center d-flex flex-column"
                  style={{
                    backgroundColor: "var(--colorsesiones)",
                    borderRadius: "20px",
                    padding: "3%",
                    width: "80%",

                    alignItems: "center",
                  }}
                >
                  <span
                    className="m-auto letraPequeña "
                    style={{
                      color: "var(--elevvaTextTitle)",
                      padding: "1vmin",
                      lineHeight: "110%",
                      fontWeight: "600",
                    }}
                  >
                    <p className="pTextProx">Valoración</p>
                  </span>
                </div>
              ) : (
                <div
                  className=" text-center d-flex flex-column"
                  style={{
                    backgroundColor: "var(--colorsesiones)",
                    borderRadius: "20px",
                    padding: "3%",
                    width: "80%",

                    alignItems: "center",
                  }}
                >
                  <span
                    className="m-auto letraPequeña "
                    style={{
                      color: "var(--elevvaTextTitle)",
                      padding: "1vmin",
                      lineHeight: "110%",
                      fontWeight: "600",
                    }}
                  >
                    <p className="pTextProx">Reserva tu clase de</p>
                    <p className="pTextProx">valoración</p>
                  </span>
                </div>
              )}
            </PerfectScrollbar>
          ) : (
            <PerfectScrollbar
              className="w-100 text-center d-flex flex-column scrollPlan_proximas"
              style={{ height: "auto" }}
            >
              <div
                className=" text-center d-flex flex-column"
                style={{
                  backgroundColor: "var(--colorsesiones)",
                  borderRadius: "20px",
                  padding: "3%",
                  width: "80%",

                  alignItems: "center",
                }}
              >
                <span
                  className="m-auto letraPequeña "
                  style={{
                    color: "var(--elevvaTextTitle)",
                    padding: "1vmin",
                    lineHeight: "110%",
                    fontWeight: "600",
                  }}
                >
                  <p className="pTextProx">No tienes plan</p>
                  <p className="pTextProx">de entrenamiento</p>
                </span>
              </div>
            </PerfectScrollbar>
          )}
          {userData?.customer?.had_valoration ? (
            <button
              className="mx-auto d-flex relative"
              style={
                laptop575.matches
                  ? {
                      color: "var(--white)",
                      fontWeight: "600",
                      padding: "1% 5%",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      verticalAlign: "middle",
                      background: "var(--elevva)",
                      borderRadius: "20px",
                      border: "1px solid var(--elevva)",
                      fontSize: "1.4vmin",
                    }
                  : {
                      color: "var(--white)",
                      fontWeight: "600",
                      padding: "1% 10%",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      verticalAlign: "middle",
                      background: "var(--elevva)",
                      borderRadius: "20px",
                      border: "1px solid var(--elevva)",
                      fontSize: "0.8rem",
                      maxWidth: "10rem",
                    }
              }
              onClick={handleClick}
            >
              Ver plan de <br /> entrenamiento{" "}
              <i className="bi bi-chevron-right"></i>
            </button>
          ) : clasesReservadas && clasesReservadas.length > 0 ? (
            <button
              className="mx-auto d-flex buttonProximas"
              onClick={() => navigate(`/misclases/${clasesReservadas[0].id}`)}
            >
              Ver<i className=" bi bi-chevron-right"></i>
            </button>
          ) : (
            <button
              className="mx-auto d-flex buttonProximas"
              onClick={handleClick}
            >
              Reserva<i className=" bi bi-chevron-right"></i>
            </button>
          )}
        </div>
      );
      }
      export function ButtonTutorial(props) {
        const handleClick = props.handleClick;
        return (
          <div
            onClick={handleClick}
            className="d-flex elevation-18 tutorial_styles"
          >
            <span style={{ fontSize: "0.8rem", cursor: "pointer" }}>
              Tutorial
            </span>
          </div>
        );
      }
      export function ButtonTutorialReserva(props) {
        const handleClick = props.handleClick;
        const classButton = props.classButton || "tutorial_styles_mobile";
        return (
          <div
            onClick={handleClick}
            className={"d-flex elevation-18 " + classButton}
          >
            <span style={{ fontSize: "0.8rem", cursor: "pointer" }}>
              Tutorial
            </span>
          </div>
        );
      }
      export function NextClass(props) {
        const moment = extendMoment(Moment);

        require("moment-timezone");
        const handleClick = props.handleClick;
        const handleClickVer = props.handleClickVer;
        const idIdentificar = props.idIdentificar;
        const restrict = props.restrict;
        const stepIndex = props.stepIndex || null;
        const stepIndexMob = props.stepIndexMob || null;
        const clasesReservadas = props.clasesReservadas;
        const heightMap = props.heightMap;

        function TextStatusBook(e) {
          if (e == 10) {
            return ReactHtmlParser(`EN ESPERA DE <br/> CONFIRMACIÓN`);
          } else if (e == 20) {
            return ReactHtmlParser(`RESERVA <br/>CONFIRMADA`);
          } else {
            return ReactHtmlParser(`RESERVA <br/> EN CURSO`);
          }
        }
        return (
          <div
            id={idIdentificar}
            className="d-flex flex-column justify-content-center text-center"
            style={{
              background: "var(--white)",
              // filter:
              //   (stepIndex && stepIndex > 8) ||
              //   (stepIndexMob && stepIndexMob == 10 && restrict)
              //     ? "blur(0)"
              //     : (stepIndex && stepIndex > 8) ||
              //       (stepIndexMob && stepIndexMob == 10 && !restrict)
              //     ? "blur(0)"
              //     : "blur(4px)",
              // pointerEvents: restrict ? "none" : "auto",
              height: "100%",
              margin: "1% 0px 0px",
              gap: "1vmin",
              padding: "3% 0%",
              borderRadius: "20px",
            }}
          >
            {" "}
            <span
              className=" letraPequeña"
              style={{
                color: "var(--elevvaTextTitle)",
              }}
            >
              Tu próxima{" "}
              <strong>
                reserva{" "}
                <img
                  className="mx-2 calendar_Mini"
                  alt="CALENDAR"
                  src={process.env.PUBLIC_URL + "/assets/img/calendar.svg"}
                />
              </strong>
            </span>
            {(stepIndex && stepIndex > 8) ||
            (stepIndexMob && stepIndexMob === 10) ? (
              <div
                className="mx-auto"
                key={"test"}
                style={{ fontSize: "3.5vmin", width: "90%" }}
              >
                <Link to={`#`}>
                  <div
                    className="position-relative "
                    style={{
                      backgroundImage: `url(${testClass})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      borderRadius: "20px",
                      width: "100%",
                      height: heightMap,
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      className="position-absolute "
                      style={{
                        color: "#394F65",
                        display: "flex",
                        height: "100%",
                        left: "5%",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "left",
                      }}
                    >
                      <span
                        className="letraPequeñaother mb-1"
                        style={{ color: "#394F65" }}
                      >
                        Entrenamiento
                      </span>
                      <div
                        style={{
                          maxWidth: "14vmin",
                          lineHeight: "100%",
                        }}
                      >
                        <span
                          className="letraPequeñaCardTitle fw-bold"
                          style={{ color: "#394F65" }}
                        >
                          Hipertensión
                        </span>
                      </div>

                      <span className="letraPequeñaother mt-1">
                        {moment
                          .tz("2023-07-30T18:00:00-05:00", "America/Bogota")
                          .format("dddd DD")}
                      </span>
                      <span className="letraPequeñaother ">
                        {moment
                          .tz("2023-07-30T18:00:00-05:00", "America/Bogota")
                          .format("LT a")}
                      </span>
                    </div>

                    <div
                      className="position-absolute fw-bold"
                      style={{
                        background: "#9EACED",
                        bottom: "13%",
                        right: "4%",
                        padding: "2% 5%",
                        color: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <span className="letraPequeñaother ">Ver</span>
                    </div>
                  </div>
                </Link>
              </div>
            ) : clasesReservadas && clasesReservadas.length > 0 ? (
              clasesReservadas
                .sort(useCompareDateOrder)
                .slice(0, 1)
                .map((e, index) => (
                  <BookCard
                    book={e}
                    index={index}
                    heightMap={heightMap}
                    TextStatusBook={TextStatusBook}
                    handleClick={handleClickVer}
                  />
                ))
            ) : (
              <div
                className=" text-center d-flex"
                style={{
                  backgroundColor: "var(--colorsesiones)",
                  borderRadius: "20px",
                  padding: "3%",
                  margin: "4% 5% ",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <span
                  className="m-auto letraPequeña "
                  style={{
                    color: "var(--elevvaTextTitle)",
                    padding: "1vmin",
                  }}
                >
                  <p className="pTextProx">No tienes</p>
                  <p className="pTextProx">clases reservadas</p>
                </span>
              </div>
            )}
            <button className="mx-auto d-flex ver_mas" onClick={handleClick}>
              Ver más <i className=" bi bi-chevron-right"></i>
            </button>
          </div>
        );
      }

      export  const TestSlider = (props) => {

        const idJoyride=props.idJoyride;
        const handleClick =props.handleClick
        const stepIndex=props.stepIndex;
        SwiperCore.use([Pagination, Autoplay, Navigation,EffectCoverflow]);
        let ArrayDecided;
        let array=[
          {
            name:`Mis <br/>estadísticas`,
            image:actividad
          },
          {
            name:`Sesiones recientes`,
            image:recientes
          },
          {
            name:`Conoce tu <br/> cuerpo`,
            image:tucuerpo
          }
        ];
        let array_step2=[
          {
            name:`Sesiones recientes`,
            image:recientes
          },
          {
            name:`Mis <br/>estadísticas`,
            image:actividad
          },
         
          {
            name:`Conoce tu cuerpo`,
            image:tucuerpo
          }
        ]
        let array_step3=[
          {
            name:`Conoce tu cuerpo`,
            image:tucuerpo
          },
          {
            name:`Mis <br/>estadísticas`,
            image:actividad
          },
          {
            name:`Sesiones recientes`,
            image:recientes
          }
          
        ];
        

       
          if(stepIndex ==7){
            ArrayDecided=array_step2
          }else if(stepIndex ==8){
            ArrayDecided=array_step3
          } else{
            ArrayDecided=array
          }
        
         

        let render_paneles = ArrayDecided.map((imagen, index) => {

          return(
            <SwiperSlide 
     onClick={()=>handleClick(imagen.name)}
            key={'panel' + index}
          
           
              style={{ width: 'auto', height: 'auto' }}
            > <div 
              
                  className="content text-center position-relative d-flex flex-column justify-content-center p-3"
                  style={{ width: "10rem" ,borderRadius: "20px",height:"10rem" }}
                ><span >{ReactHtmlParser(imagen.name)}</span>
                  {" "}
                  <img
                    id={'miniImagen' + index}
                    className="m-auto"
                    src={imagen.image}
                    style={{  cursor: "pointer",padding:'2% 20%',maxHeight:"7rem",maxWidth:"7rem" }}
                    width={"100%"}
                    height="auto"
                 
                  />
                </div>
            </SwiperSlide>
          )
        })
        return(<Swiper    
         effect={'coverflow'}
          id={idJoyride}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
  
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 5,
          depth: 10,
          modifier: 15,
        
         slideShadows: false,
        }}
      
        style={{ margin: "auto ", width: '100%',height: '100%' }}
        className=" py-3"
        modules={[EffectCoverflow, Pagination, Navigation]}
        pagination={{ el: '.swiper-pagination', clickable: true,  type: "custom",
        }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
               
              }}
           
        
        spaceBetween={20}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        slidesPerGroup={1}
        slidesPerGroupSkip={1}
      >
      
        {render_paneles}
      
      
         </Swiper>
        )
      }
      ;
      export const BookCard =(props)=>{
        const e=props.book;
        const index =props.index;
        const heightMap=props.heightMap;
        const moment = extendMoment(Moment);
        const TextStatusBook=props.TextStatusBook;
        const widthCard =props.widthCard || "90%";
        const myCard =props.myCard || "";
        const handleClick=props.handleClick;
        console.log("e-------------", e);

        require("moment-timezone");
        return (
          <div
            className={"mx-auto " + myCard}
            key={e.id}
            style={{ fontSize: "3.5vmin", width: widthCard }}
          >
            <Link to={`/misclases/${e.id}`}>
              <div
                key={index}
                className="position-relative"
                style={{
                  backgroundImage: `url(${e.training.thumbnail_picture})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",

                  borderRadius: "10px",
                  width: "100%",
                  height: heightMap,
                }}
              >
                <div
                  className="position-absolute "
                  style={{
                    color: "#394F65",
                    display: "flex",
                    height: "100%",
                    left: "5%",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <div className="fw-bold circleHourglas">
                    <span className="letraPkStatus w-100">
                      {TextStatusBook(e.status)}
                    </span>
                  </div>
                  <span
                    className="letraPequeñaother "
                    style={{ color: "#394F65" }}
                  >
                    Entrenamiento
                  </span>
                  <div
                    style={{
                      maxWidth: "19vmin",
                      lineHeight: "90%",
                    }}
                  >
                    <span
                      className="letraPequeñaCardTitle fw-bold"
                      style={{ color: "#394F65" }}
                    >
                      {e.training.name}
                    </span>
                  </div>

                  <span className="letraPequeñaother">
                    {moment.tz(e.date, e.tz).format("MMMM DD")}
                  </span>
                  <span className="letraPequeñaother ">
                    {Moment.tz(e?.date, e?.tz).format("hh:mm a") +
                      " / " +
                      Moment.tz(e?.date, e?.tz).add(1, "h").format("hh:mm a")}
                  </span>
                </div>

                <div
                  onClick={() => handleClick(e.id)}
                  className="position-absolute fw-bold d-flex"
                  style={{
                    background: "var(--verBooking)",
                    bottom: "10%",
                    right: "4%",
                    padding: "2% 3%",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  <span className="letraPequeñaother ">Ver</span>
                </div>
              </div>
            </Link>
          </div>
        );
      }




      export const SwiperPlan=(props)=>{
        const mesPrograma=props.mesPrograma;
       const userData=props.userData;
       const CardioTraining=props.CardioTraining;
       const anchoGrid=props.anchoGrid;
       const widthBox =props.widthBox || "100%";
       const minWidthBox = props.minWidthBox || "auto"
       const monthSwiper = whatmonthSwiper(userData.membership.current_plan_day);
   

console.log(mesPrograma)
       return (
         <Swiper
           initialSlide={monthSwiper}
           style={{
             width: "100%",
             margin: "auto",
             justifyContent: "center",
           }}
           navigation={{
             nextEl: `.swiper-button-next-months`,
             prevEl: `.swiper-button-prev-months`,
           }}
           modules={[Navigation]}
           slidesPerView="auto"
           spaceBetween={0}
           slidesOffsetBefore={0}
           slidesOffsetAfter={0}
           slidesPerGroup={1}
           slidesPerGroupSkip={1}
         >
           {/* mes1 */}
           <SwiperSlide>
             <div
               className={"d-flex justify-content-center mb-4"}
               style={{ alignItems: "center" }}
             >
               <div className={`swiper-button-prev-months tonin-prev`}></div>
               <div className="mx-5">Mes 1</div>
               <div className={`swiper-button-next-months tonin-next`}></div>
             </div>

             <div
               className="grid-container-perfil  justify-content-center m-auto"
               style={{ width: anchoGrid }}
             >
               {mesPrograma &&
                 mesPrograma.slice(0, 28).map((elemento, index) => (
                   <div className="grid-item-perfil">
                     <div className="box">
                       <div
                         className={`box-content ${
                           userData.membership.current_plan_day == index + 1
                             ? "tooltip_bottom_select"
                             : ""
                         }`}
                         style={{
                           position: "relative",
                           zIndex: "1",
                           borderRadius: "20px",
                           background:
                             userData.membership.current_plan_day == index + 1
                               ? "rgb(243, 83, 155)"
                               : "#D7DBDD",
                           color:
                             userData.membership.current_plan_day == index + 1
                               ? "#ffff"
                               : "black",
                           minWidth: minWidthBox,
                           width: widthBox,
                         }}
                       >
                         Día {index + 1}
                       </div>
                       <div
                         onClick={() => CardioTraining([elemento, index + 1])}
                         className={`box-text ${
                           userData.membership.current_plan_day == index + 1
                             ? " resplandor"
                             : ""
                         }`}
                         style={{
                           cursor: "pointer",
                           borderRadius: "20px",
                           background: iconReviewColor(elemento.type),
                           minWidth: minWidthBox,
                           width: widthBox,
                         }}
                       >
                         {iconReview(elemento.type)}
                       </div>
                     </div>
                   </div>
                 ))}
             </div>
           </SwiperSlide>
           {/* mes2 */}
           {mesPrograma && mesPrograma.length > 28 ? (
             <SwiperSlide>
               <div
                 className={"d-flex justify-content-center mb-4"}
                 style={{ alignItems: "center" }}
               >
                 <div className={`swiper-button-prev-months tonin-prev`}></div>
                 <div className="mx-5">Mes 2</div>
                 <div className={`swiper-button-next-months tonin-next`}></div>
               </div>
               <div
                 className="grid-container-perfil  justify-content-center m-auto"
                 style={{ width: anchoGrid }}
               >
                 {mesPrograma &&
                   mesPrograma.slice(28, 56).map((elemento, index) => (
                     <div className="grid-item-perfil">
                       <div className="box">
                         <div
                            className={`box-content ${
                             userData.membership.current_plan_day == index + 29
                               ? "tooltip_bottom_select"
                               : ""
                           }`}
                           style={{
                             position: "relative",
                             zIndex: "1",
                             borderRadius: "20px",
                             background:
                               userData.membership.current_plan_day ==
                               index + 29
                                 ? "rgb(243, 83, 155)"
                                 : "#D7DBDD",
                             color:
                               userData.membership.current_plan_day ==
                               index + 29
                                 ? "#ffff"
                                 : "black",
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           Día {index + 29}
                         </div>
                         <div
                           onClick={() =>
                             CardioTraining([elemento, index + 29])
                           }
                           className={`box-text ${
                             userData.membership.current_plan_day == index + 29
                               ? " resplandor"
                               : ""
                           }`}
                           style={{
                             cursor: "pointer",
                             borderRadius: "20px",
                             background: iconReviewColor(elemento.type),
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           {iconReview(elemento.type)}
                         </div>
                       </div>
                     </div>
                   ))}
               </div>
             </SwiperSlide>
           ) : null}
           {/* mes3 */}
           {mesPrograma && mesPrograma.length > 56 ? (
             <SwiperSlide>
               <div
                 className={"d-flex justify-content-center mb-4"}
                 style={{ alignItems: "center" }}
               >
                 <div className={`swiper-button-prev-months tonin-prev`}></div>
                 <div className="mx-5">Mes 3</div>
                 <div className={`swiper-button-next-months tonin-next`}></div>
               </div>
               <div
                 className="grid-container-perfil  justify-content-center m-auto"
                 style={{ width: anchoGrid }}
               >
                 {mesPrograma &&
                   mesPrograma.slice(56, 84).map((elemento, index) => (
                     <div className="grid-item-perfil">
                       <div className="box">
                         <div
                           className={`box-content ${
                             userData.membership.current_plan_day == index + 57
                               ? "tooltip_bottom_select"
                               : ""
                           }`}
                           style={{
                             position: "relative",
                             zIndex: "1",
                             borderRadius: "20px",
                             background:
                               userData.membership.current_plan_day ==
                               index + 57
                                 ? "rgb(243, 83, 155)"
                                 : "#D7DBDD",
                             color:
                               userData.membership.current_plan_day ==
                               index + 57
                                 ? "#ffff"
                                 : "black",
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           Día {index + 57}
                         </div>
                         <div
                           onClick={() =>
                             CardioTraining([elemento, index + 57])
                           }
                           className={`box-text ${
                             userData.membership.current_plan_day == index + 57
                               ? " resplandor"
                               : ""
                           }`}
                           style={{
                             cursor: "pointer",
                             borderRadius: "20px",
                             background: iconReviewColor(elemento.type),
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           {iconReview(elemento.type)}
                         </div>
                       </div>
                     </div>
                   ))}
               </div>
             </SwiperSlide>
           ) : null}
           {/* mes4 */}
           {mesPrograma && mesPrograma.length > 84 ? (
             <SwiperSlide>
               <div
                 className={"d-flex justify-content-center mb-4"}
                 style={{ alignItems: "center" }}
               >
                 <div className={`swiper-button-prev-months tonin-prev`}></div>
                 <div className="mx-5">Mes 4</div>
                 <div className={`swiper-button-next-months tonin-next`}></div>
               </div>
               <div
                 className="grid-container-perfil  justify-content-center m-auto"
                 style={{ width: anchoGrid }}
               >
                 {mesPrograma && mesPrograma.slice(84, 112).map((elemento, index) => (
                   <div className="grid-item-perfil">
                     <div className="box">
                       <div
                         className={`box-content ${
                           userData.membership.current_plan_day == index + 85
                             ? "tooltip_bottom_select"
                             : ""
                         }`}
                         style={{
                           position: "relative",
                           zIndex: "1",
                           borderRadius: "20px",
                           background:
                             userData.membership.current_plan_day == index + 85
                               ? "rgb(243, 83, 155)"
                               : "#D7DBDD",
                           color:
                             userData.membership.current_plan_day == index + 85
                               ? "#ffff"
                               : "black",
                           minWidth: minWidthBox,
                           width: widthBox,
                         }}
                       >
                         Día {index + 85}
                       </div>
                       <div
                         onClick={() => CardioTraining([elemento, index + 85])}
                       
                         className={`box-text ${
                           userData.membership.current_plan_day == index + 85
                             ? " resplandor"
                             : ""
                         }`}
                         style={{
                           cursor: "pointer",
                           borderRadius: "20px",
                           background: iconReviewColor(elemento.type),
                           minWidth: minWidthBox,
                           width: widthBox,
                         }}
                       >
                         {iconReview(elemento.type)}
                       </div>
                     </div>
                   </div>
                 ))}
               </div>
             </SwiperSlide>
           ) : null}
           {/* mes5 */}
           {mesPrograma && mesPrograma.length > 112 ? (
             <SwiperSlide>
               <div
                 className={"d-flex justify-content-center mb-4"}
                 style={{ alignItems: "center" }}
               >
                 <div className={`swiper-button-prev-months tonin-prev`}></div>
                 <div className="mx-5">Mes 5</div>
                 <div className={`swiper-button-next-months tonin-next`}></div>
               </div>
               <div
                 className="grid-container-perfil  justify-content-center m-auto"
                 style={{ width: anchoGrid }}
               >
                 {mesPrograma &&
                   mesPrograma.slice(112,140).map((elemento, index) => (
                     <div className="grid-item-perfil">
                       <div className="box">
                         <div
                           className={`box-content ${
                             userData.membership.current_plan_day == index + 113
                               ? "tooltip_bottom_select"
                               : ""
                           }`}
                           style={{
                             position: "relative",
                             zIndex: "1",
                             borderRadius: "20px",
                             background:
                               userData.membership.current_plan_day ==
                               index + 113
                                 ? "rgb(243, 83, 155)"
                                 : "#D7DBDD",
                             color:
                               userData.membership.current_plan_day ==
                               index + 113
                                 ? "#ffff"
                                 : "black",
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           Día {index + 113}
                         </div>
                         <div
                           onClick={() =>
                             CardioTraining([elemento, index + 113])
                           }
                           className={`box-text ${
                             userData.membership.current_plan_day == index + 113
                               ? " resplandor"
                               : ""
                           }`}
                           style={{
                             cursor: "pointer",
                             borderRadius: "20px",
                             background: iconReviewColor(elemento.type),
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           {iconReview(elemento.type)}
                         </div>
                       </div>
                     </div>
                   ))}
               </div>
             </SwiperSlide>
           ) : null}
         
           {/* mes6 */}
           {mesPrograma && mesPrograma.length > 140 ? (
             <SwiperSlide>
               <div
                 className={"d-flex justify-content-center mb-4"}
                 style={{ alignItems: "center" }}
               >
                 <div className={`swiper-button-prev-months tonin-prev`}></div>
                 <div className="mx-5">Mes 6</div>
                 <div className={`swiper-button-next-months tonin-next`}></div>
               </div>
               <div
                 className="grid-container-perfil  justify-content-center m-auto"
                 style={{ width: anchoGrid }}
               >
                 {mesPrograma &&
                   mesPrograma.slice(140,168).map((elemento, index) => (
                     <div className="grid-item-perfil">
                       <div className="box">
                         <div
                           className={`box-content ${
                             userData.membership.current_plan_day == index + 141
                               ? "tooltip_bottom_select"
                               : ""
                           }`}
                           style={{
                             position: "relative",
                             zIndex: "1",
                             borderRadius: "20px",
                             background:
                               userData.membership.current_plan_day ==
                               index + 141
                                 ? "rgb(243, 83, 155)"
                                 : "#D7DBDD",
                             color:
                               userData.membership.current_plan_day ==
                               index + 141
                                 ? "#ffff"
                                 : "black",
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           Día {index + 141}
                         </div>
                         <div
                           onClick={() =>
                             CardioTraining([elemento, index + 141])
                           }
                           className={`box-text ${
                             userData.membership.current_plan_day == index + 141
                               ? " resplandor"
                               : ""
                           }`}
                           style={{
                             cursor: "pointer",
                             borderRadius: "20px",
                             background: iconReviewColor(elemento.type),
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           {iconReview(elemento.type)}
                         </div>
                       </div>
                     </div>
                   ))}
               </div>
             </SwiperSlide>
           ) : null}
          
           {/* mes7 */}
           {mesPrograma && mesPrograma.length > 168 ? (
             <SwiperSlide>
               <div
                 className={"d-flex justify-content-center mb-4"}
                 style={{ alignItems: "center" }}
               >
                 <div className={`swiper-button-prev-months tonin-prev`}></div>
                 <div className="mx-5">Mes 7</div>
                 <div className={`swiper-button-next-months tonin-next`}></div>
               </div>
               <div
                 className="grid-container-perfil  justify-content-center m-auto"
                 style={{ width: anchoGrid }}
               >
                 {mesPrograma &&
                   mesPrograma.slice(168,196).map((elemento, index) => (
                     <div className="grid-item-perfil">
                       <div className="box">
                         <div
                           className={`box-content ${
                             userData.membership.current_plan_day == index + 169
                               ? "tooltip_bottom_select"
                               : ""
                           }`}
                           style={{
                             position: "relative",
                             zIndex: "1",
                             borderRadius: "20px",
                             background:
                               userData.membership.current_plan_day ==
                               index + 169
                                 ? "rgb(243, 83, 155)"
                                 : "#D7DBDD",
                             color:
                               userData.membership.current_plan_day ==
                               index + 169
                                 ? "#ffff"
                                 : "black",
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           Día {index + 169}
                         </div>
                         <div
                           onClick={() =>
                             CardioTraining([elemento, index + 169])
                           }
                           className={`box-text ${
                             userData.membership.current_plan_day == index + 169
                               ? " resplandor"
                               : ""
                           }`}
                           style={{
                             cursor: "pointer",
                             borderRadius: "20px",
                             background: iconReviewColor(elemento.type),
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           {iconReview(elemento.type)}
                         </div>
                       </div>
                     </div>
                   ))}
               </div>
             </SwiperSlide>
           ) : null}
         
           {/* mes8 */}
           {mesPrograma && mesPrograma.length > 196 ? (
             <SwiperSlide>
               <div
                 className={"d-flex justify-content-center mb-4"}
                 style={{ alignItems: "center" }}
               >
                 <div className={`swiper-button-prev-months tonin-prev`}></div>
                 <div className="mx-5">Mes 8</div>
                 <div className={`swiper-button-next-months tonin-next`}></div>
               </div>
               <div
                 className="grid-container-perfil  justify-content-center m-auto"
                 style={{ width: anchoGrid }}
               >
                 {mesPrograma &&
                   mesPrograma.slice(196,224).map((elemento, index) => (
                     <div className="grid-item-perfil">
                       <div className="box">
                         <div
                           className={`box-content ${
                             userData.membership.current_plan_day == index + 197
                               ? "tooltip_bottom_select"
                               : ""
                           }`}
                           style={{
                             position: "relative",
                             zIndex: "1",
                             borderRadius: "20px",
                             background:
                               userData.membership.current_plan_day ==
                               index + 197
                                 ? "rgb(243, 83, 155)"
                                 : "#D7DBDD",
                             color:
                               userData.membership.current_plan_day ==
                               index + 197
                                 ? "#ffff"
                                 : "black",
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           Día {index + 197}
                         </div>
                         <div
                           onClick={() =>
                             CardioTraining([elemento, index + 197])
                           }
                           className={`box-text ${
                             userData.membership.current_plan_day == index + 197
                               ? " resplandor"
                               : ""
                           }`}
                           style={{
                             cursor: "pointer",
                             borderRadius: "20px",
                             background: iconReviewColor(elemento.type),
                             minWidth: minWidthBox,
                             width: widthBox,
                           }}
                         >
                           {iconReview(elemento.type)}
                         </div>
                       </div>
                     </div>
                   ))}
               </div>
             </SwiperSlide>
           ) : null}
           {/* {mesPrograma&&mesPrograma.length>196?  <SwiperSlide >
    <div className={"d-flex justify-content-center mb-4"} style={{alignItems:"center"}}>
              <div className={`swiper-button-prev-months tonin-prev`}></div>
              <div className="mx-5">Mes 2</div>
              <div className={`swiper-button-next-months tonin-next`}></div>
            </div>
             <div className="grid-container-perfil  justify-content-center m-auto" style={{width:anchoGrid}}>
      { mesPrograma.slice(196,224).map((elemento,index)=>
    <div className="grid-item-perfil"><div className="box">
        <div className={`box-content ${userData.membership.current_plan_day ==index + 197  ? "tooltip_bottom_select" : ""}`} style={{position:"relative",zIndex:"1",borderRadius:"20px",background:userData.membership.current_plan_day ==index + 197  ?"rgb(243, 83, 155)" :"#D7DBDD",color:userData.membership.current_plan_day ==index + 197  ?"#ffff" :"black",minWidth:minWidthBox,width:widthBox}}>Día {index + 1}</div>
        <div onClick={()=>elemento.type ==("cardio")?CardioTraining([elemento,index + 197]):elemento.type ==("valoracion_fisica") || elemento.type ==("valoracion_nutricional") || elemento.type ==("reserva")?navigate(`/training/${elemento.id_content}`):elemento.type ==("video")?navigate("/videos/card/6/1"):null} className={`box-text ${userData.membership.current_plan_day ==index + 197  ? " resplandor" : ""}`}  style={{cursor:"pointer",borderRadius:"20px",background:elemento.type ==("cardio")?"#A43E80":elemento.type ==("descanso")?"#CBA791":elemento.type ==("video")?"#4B6380":elemento.type ==("reserva")?"#8089CC":"#70AAD8",minWidth:minWidthBox,width:widthBox}}>
  
       {iconReview(elemento.type)}</div>
      </div></div>
   
  )}
  </div>
  </SwiperSlide>:null} */}
         </Swiper>
       );
      }
