// Use the same css as Ejercicios.css

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg"
import { AccordionItem } from "./index";

import PerfectScrollbar from "react-perfect-scrollbar";

import imageWebQuestion from "../../assets/newIcons/image-web-questions.jpg"
import arrowLogo from "../../assets/newIcons/image_arow_logo.jpg"
import {
  instance,
} from "../../utils/utils";


const Faq = (props) => {

   const ComponentFaqs = (props) => {
const classMobile=props.classMobile;
const heightScroll=props.heightScroll;
const borderRadius=props.borderRadius;
const navigate = useNavigate();

const [accordionItems, setAccordionItems] = useState(null);
const searchText = useRef(null);

useEffect(() => {
  searchFaq(); // search all the faqs
}, []);

function searchFaq(search = "") {
  instance
    .get(`app/faqs`)
    .then((res) => {
      const info = res.data.filter((e) =>
        e.question.toLowerCase().includes(search.toLowerCase())
      );

      const arrData = [];

      let count = 0;
      if (info) {
        for (const iterator of info) {
          const name = unescape(iterator.question);
          const desc = unescape(iterator.answer);
          arrData.push(<div key={"acor" + count}>
            <AccordionItem
               identify={iterator}
              dataBsParent="#accordionGlosario"
              title={name}
              item={count}
              classQuestion={"question_Class"}
            >
              <div  className=" answer_Class">{desc}</div>
            </AccordionItem> </div>
          );
          count += 1;
        }
      }
      if (count === 0) {
        setAccordionItems(
          <div className="form-text text-center" style={{ color: "#394F65" }}>
            No se encontrarón preguntas.
          </div>
        );
      } else {
        setAccordionItems(arrData);
      }
    })
    .catch((err) => {
      console.log("Error load_pregunras_a", err);
    });
}

    return(
      <div className={`${classMobile} mobile-login w-100 h-100` } style={{ background:"#fff",borderRadius:borderRadius }}>
      <div className="mx-3 my-3 d-flex">
   <button onClick={()=>navigate("/Dashboard")} className={"button_w_h_back"} style={{background:"transparent",border:"none",}}>
   <BackArrow width={"100%"} height={"100%"} /></button><span className="title my-auto fw-light">Preguntas <strong>frecuentes</strong></span>     
        <div className="separador my-3"></div>
      </div>
    <div className=" preguntasfrecuentesweb ">
      <div className="searchEjercicio  d-flex">
        <input
          ref={searchText}
          type="search"
          className="input_search mx-auto"
          onChange={() => searchFaq(searchText.current.value)}
        />
     
      </div>
      <PerfectScrollbar style={{height:heightScroll}}>
      <div className="glosario glosariopreguntas">
        <div
         
          className="accordion accordion-flush pt-3"
          id="accordionGlosario"
        >
        {accordionItems}
        </div>
      </div></PerfectScrollbar>
    </div>
  </div>
    )
   }
  return (<>
  {/* web */}
  <div className="d-none d-md-block  col-10 " style={{ height: "100%" }}>
     
     <div
       className=" position-relative "
       style={{
         background: "#FAF8F5",
         height: "100vh",
         padding: "2% 3% 2% 2%",
       }}
     >
       <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
       <div className="col-5 "><ComponentFaqs classMobile={""} heightScroll={"60vh"} borderRadius={"20px"} /></div>
       <div className="col-7 h-100 d-flex flex-column justify-content-around"  style={{background:"#fff",borderRadius:"20px" }}>
       
      
        <div className="col-md-12 px-4 d-flex">
        <span className="col-6 fw-bold"  style={{fontSize:"calc(0.8rem + 0.8vmin)"}}></span>
<span className="col-6" style={{width:"5rem",height:"5rem"}}><img style={{backgroundSize:"contain"}} src={arrowLogo}/></span></div>
        <div className="col-md-12"><img className="w-100" src={imageWebQuestion} /></div>
        <div className="col-md-12 px-4 answer_Class" style={{opacity:"0.6"}}></div>
   

       </div>
       </div>
     </div>
   </div>
  {/* mobile */}
   <ComponentFaqs classMobile={"d-md-none"} heightScroll={"50vh"} borderRadius={"0px"}/>
   </>
  
  );
};

export default Faq;



// export const Politicas = (props) => {
//   const user = props.user;
//   const [accordionItems, setAccordionItems] = useState(null);

//   useEffect(() => {
//     searchPoliticas(); // search all the faqs
//   }, []);

//   function searchPoliticas() {
//     axios
//       .get(`${NuevaAxiosURL}app/config`)
//       .then((res) => {
//         const info = res.data;
//         const arrData = [];
//         let count = 0;
//         if (info) {
//           for (const iterator of info) {
//             const desc_items = unescape(iterator.privacy_policy)
//               .split("\n")
//               .map(function (item, idx) {
//                 return (
//                   <span key={idx}>
//                     {item}
//                     <br />
//                   </span>
//                 );
//               });
//             const desc = desc_items;
//             arrData.push(
//               <div
//                 key={count}
//                 className={
//                   laptop575.matches
//                     ? "desc p-5 infotext_see m-auto"
//                     : "mb-3 infotext_see p-2 m-auto"
//                 }
//               >
//                 {desc}
//               </div>
//             );
//             count += 1;
//           }
//         }
//         if (count === 0) {
//           setAccordionItems(
//             <div className="form-text text-center" style={{ color: "#394F65" }}>
//               No se encontrarón preguntas.
//             </div>
//           );
//         } else {
//           setAccordionItems(arrData);
//         }
//       })
//       .catch((err) => {
//         console.log("Error load_politicas_a", err);
//       });
//   }

//   return (
//     <div className="contenedorFijo" style={marginTawk}>
//       <div className="text-center mt-2">
//         <img
//           src={politicas}
//           alt="logo"
//           style={laptop575.matches ? null : { width: "50px" }}
//         />
//       </div>
//       <div className="title mt-3">
//         Políticas de privacidad
//         {laptop575.matches ? null : (
//           <div className="breadcum mt-1">
//             Inicio
//             <FontAwesomeIcon className="mx-2" icon={faPlay} color="#CB3D82" />
//             Políticas
//           </div>
//         )}
//       </div>
//       <div className="separador my-3"></div>
//       <div className="w-100">
//         <div className="glosario">
//           <div
//             className="accordion accordion-flush pt-3"
//             id="accordionGlosario"
//           >
//             {accordionItems}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
