import React from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import "./videos.css";
import "swiper/swiper-bundle.min.css";

export function Allvideos() {
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const videos = [
    process.env.PUBLIC_URL + "/assets/videos/absOblicuosconpeso.mp4",
    process.env.PUBLIC_URL + "/assets/videos/ABSPlanchabrazosextendidos.mp4",
    process.env.PUBLIC_URL +
      "/assets/videos/ABSRunningMountaingclimbercruzado.mp4",
    process.env.PUBLIC_URL + "/assets/videos/ABSSitups.mp4",
    process.env.PUBLIC_URL + "/assets/videos/ABSSPIDERMOUNTAINCLIMBER.mp4",
  ];
  const sw = window.screen.width;
  const render_img_desk = videos.map((imagen, index) => {
    const def_img = imagen;

    return (
      <SwiperSlide
        key={index}
        className={
          index == 0
            ? "sliderNetFlixvideo resplandor-sliderNetFlixvideo"
            : "sliderNetFlixvideo"
        }
        style={index == 0 ? { paddingTop: "2%" } : null}
      >
        <div
          className="content mx-auto d-flex  "
          style={{ justifyContent: "center" }}
        >
          <ReactPlayer url={def_img} width={"95%"} height="100%" controls />
        </div>
      </SwiperSlide>
    );
  });

  return (
    <Swiper
      style={{
        width: "100%",
        justifyContent: "center",
      }}
      navigation={true}
      modules={[Navigation]}
      slidesPerView="auto"
      className="mySwiperde"
      spaceBetween={0}
      slidesOffsetBefore={0}
      slidesOffsetAfter={0}
      slidesPerGroup={1}
      slidesPerGroupSkip={1}
    >
      {render_img_desk}
    </Swiper>
  );
}
