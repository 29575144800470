
import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function Estandarimagenes(props) {
  const heightMarks_peso = {
    0: "",
    25: "Puedes subir un poco",
    50: "Adecuado",
    75: "Puedes bajar un poco",
    100: "Debes bajar un poco",
  };
  const heightMarks_nada = {
    0: "",
  };
  const heightMarks_imc = {
    0: "",
    25: "Puedes subir un poco",
    50: "Adecuado",
    75: "Puedes bajar un poco",
    100: "Debes bajar un poco",
  };
  const heightMarks_grasacorporal = {
    0: "",
    25: "Debes subir un poco",
    50: "Adecuado",
    75: "Puedes bajar un poco",
    100: "Debes bajar un poco",
  };
  const heightMarks_grasasubcutanea = {
    0: "",
    25: "Debes subir un poco",
    50: "Puedes subir un poco",
    75: "Adecuado",
    100: "Debes bajar un poco",
  };
  const heightMarks_grasavisceral = {
    0: "",
    50: "Adecuado",
    100: "Puedes bajar un poco",
  };
  const heightMarks_aguacorpotal = {
    0: "",
    33.333: "Puedes subir un poco",
    66.333: "Adecuado",
    100: "Debes bajar un poco",
  };
  const heightMarks_musculoesqueletico = {
    0: "",
    25: "Puedes subir un poco",
    50: "Adecuado",
    75: "Puedes bajar un poco",
    100: "Debes bajar un poco",
  };
  const heightMarks_masaesqueletica = {
    0: "",
    50: "Promedio",
    100: "Particular",
  };
  const heightMarks_masamuscular = {
    0: "",
    33.333: "Puedes subir un poco",
    66.333: "Adecuado",
    100: "Debes bajar un poco",
  };
  const heightMarks_protenia = {
    0: "",
    33.333: "Puedes subir un poco",
    66.333: "Adecuado",
    100: "Debes bajar un poco",
  };
  function select_value_mark(indice, value) {
    if (indice == 0) {
      if (value == "MUY ALTO") return 85;
      else if (value == "ALTO") return 65;
      else if (value == "SALUDABLE") return 35;
      else if (value == "____") return 0;
      else return 15;
    } else if (indice == 1) {
      if (value == "MUY ALTO") return 85;
      else if (value == "ALTO") return 65;
      else if (value == "SALUDABLE") return 35;
      else if (value == "____") return 0;
      else return 15;
    } else if (indice == 2) {
      if (value == "muy ALTO") return 85;
      else if (value == "ALTO") return 65;
      else if (value == "SALUDABLE") return 35;
      else if (value == "____") return 0;
      else return 15;
    } else if (indice == 3) {
      if (value == "ALTO") return 85;
      else if (value == "NORMAL") return 65;
      else if (value == "ESENCIAL") return 35;
      else if (value == "____") return 0;
      else return 15;
    } else if (indice == 4) {
      if (value == "EXCESIVO") return 85;
      else if (value == "____") return 0;
      else return 35;
    } else if (indice == 5) {
      if (value == "ALTO") return 85;
      else if (value == "____") return 0;
      else if (value == "SALUDABLE") return 48;
      else return 25;
    } else if (indice == 6) {
      if (value == "MUY ALTA") return 85;
      else if (value == "ALTA") return 65;
      else if (value == "NORMAL") return 35;
      else if (value == "____") return 0;
      else return 15;
    } else if (indice == 7) {
      if (value == "PARTICULAR") return 85;
      else if (value == "____") return 0;
      else return 35;
    } else if (indice == 8) {
      if (value == "ALTO") return 85;
      else if (value == "____") return 0;
      else if (value == "NORMAL") return 48;
      else return 25;
    } else if (indice == 9) {
      if (value == "EXCESIVO") return 85;
      else if (value == "____") return 0;
      else if (value == "NORMAL") return 48;
      else return 25;
    }
  }
  function select_marks(indice) {
  
    if (indice == 0) {
      return heightMarks_peso;
    } else if (indice == 1) {
      return heightMarks_imc;
    } else if (indice == 2) {
      return heightMarks_grasacorporal;
    } else if (indice == 3) {
      return heightMarks_grasasubcutanea;
    } else if (indice == 4) {
      return heightMarks_grasavisceral;
    } else if (indice == 5) {
      return heightMarks_aguacorpotal;
    } else if (indice == 6) {
      return heightMarks_musculoesqueletico;
    } else if (indice == 7) {
      return heightMarks_masaesqueletica;
    } else if (indice == 8) {
      return heightMarks_masamuscular;
    } else if (indice == 9) {
      return heightMarks_protenia;
    }
  }
  // console.log("ListaEntrenamientos", entrenamientos);
  return (
    <div
      className={
        props.nameObjetivo == 4 || props.nameObjetivo == 7
          ? "cuadrorangoschange pb-5"
          : " cuadrorangos pb-5"
      }
    >
      <Slider
        defaultValue={select_value_mark(props.nameObjetivo, props.dateObjetivo)}
        min={0}
        max={100}
        step={2}
        marks={select_marks(props.nameObjetivo)}
        style={{
          width: "100%",
          justifyContent: "center",
          margin: "auto 0 0 auto",
        }}
      />
    </div>
  );
}
export default Estandarimagenes;
