import { LoginComponentUser } from "../../utils/utils";
// import { useGLTF } from "@react-three/drei";
import React, { Suspense } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls } from "@react-three/drei";
import Model from "./preload";
const LoginTrainer = (props) => {
  const elevvaT = props.elevvaT;
  const userName = props.type;
  return <LoginComponentUser elevvaT={elevvaT} type={userName} />;

  // return (
  //   <Canvas
  //     camera={{ position: [2, 20, 15], fov: 4.5, rotateY: 0 }}
  //     style={{
  //       backgroundColor: "var(--elevvabutonLogin_User)",
  //       width: "100%",

  //       height: "70vh",
  //     }}
  //   >
  //     <ambientLight intensity={1.25} />
  //     <ambientLight intensity={0.1} />
  //     <directionalLight intensity={0.4} />
  //     <Suspense fallback={null}>
  //       <Model position={[0.025, -0.9, 0]} rotation={[0, Math.PI / 0.95, 0]} />
  //     </Suspense>
  //     <OrbitControls
  //       enablePan={false}
  //       enableZoom={false}
  //       minPolarAngle={Math.PI / 2.2}
  //       maxPolarAngle={Math.PI / 2.2}
  //     />
  //   </Canvas>
  // );
};

export default LoginTrainer;
