import { Welcomecomponent } from "./utils";

const WelcometoElevva = (props) => {
  const elevvaT = props.elevvaT;
  const type = props.type;

  return <Welcomecomponent elevvaT={elevvaT} type={type} />;
};

export default WelcometoElevva;
