import { useEffect } from "react";
import React, { useRef } from "react";
import { CategoriasEmpty, Paso4Mostrar } from "../TutorialReserva/stepsHelp";
import { useNavigate,useParams  } from "react-router-dom";
import { instance } from "../../utils/utils";
import { useState } from "react";

const ResumenReserva = (props) => {

  const navigate = useNavigate();
  const zonahorairachange =props.zonahorairachange
  const { id } = useParams();
  const [infoTraining, setinfoTraining] = useState(null);
  const [customerInfo, setcustomerInfo] = useState(null);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {

    instance.get("customers/").then((response) => {
      setcustomerInfo(response.data)}) .catch((error) => {
      console.log(error);
    });
    instance
    .get(`trainings`)
    .then((response) => {
      setTrainings(response.data);
     
    })
    .catch((error) => {
      console.log(error);
    });
    instance
      .get(`trainings/${id}`)

      .then((response) => {
        setinfoTraining(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);
  const selectReserva =trainings && trainings.filter((e) => e.id == 9);
  const trainers = selectReserva[0]?.trainers;
  return (
   <>  <div className="d-none d-md-block  col-10 " style={{ height: "100%" }}>
    <div
      className=" position-relative "
      style={{
        background: "rgba(0,0,0,.7)",
        height: "100vh",
        padding: "2% 3% 2% 2%",
      }}
    >
      <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
      <CategoriasEmpty/>
        <Paso4Mostrar zonahorairachange = {zonahorairachange &&zonahorairachange.timezone} infoTraining={infoTraining} customerInfo={customerInfo} trainers={trainers&&trainers}/>
            </div>
          </div>
        </div>
        <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <Paso4Mostrar zonahorairachange = {zonahorairachange &&zonahorairachange.timezone} infoTraining={infoTraining} customerInfo={customerInfo} trainers={trainers&&trainers}/>
      </div></>
  )
}
export default ResumenReserva