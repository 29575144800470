import {
  perlo,
} from "../../utils/utils";

import CalendarProfe from "./Calendar"
import React, { useRef } from "react";
import "../inicio/inicio.css";
import { useNavigate, Navigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
// import { ButtonMob } from "../../utils/utils";
import { ModalSolid } from "../Modal/ModalSolid"
const HoraEntrenamiento = (props) => {
  const elevvaT = props.elevvaT;
  const pathHere = props.path;
  const navigate = useNavigate();
  const theme1 = {
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
      },
      ticks: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
        text: {
          fill: "var(--elevvaArrowColor)",
        },
      },
      legend: {
        text: {
          fontSize: "3vw",

          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "var(--backgroundGraficGrid)",

        strokeWidth: 1,
      },
    },
  };
  const Barra = [
    [
      "Progreso",
      <i className="bi bi-house"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/Inicio",
    ],
    [
      "Mi perfil",
      <i class="bi bi-person-circle"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/Perfil",
    ],
    [
      "Videos de bienestar y entrenamiento",
      <i className="bi bi-play-btn-fill"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Reserva de clases",
      <i className="bi bi-calendar-date"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/groupals",
    ],
    [
      "Adquiere equipos",
      <i className="bi bi-box2"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Preguntas frecuentes",
      <i className="bi bi-chat-right-quote"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Términos y condiciones políticas de privacidad",
      <i class="bi bi-pencil-square"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Cerrar sesión",
      <i className="bi bi-box-arrow-left"></i>,
      useRef(null),
      useRef(null),
      "out",
    ],
  ];
  const [counter, setcounter] = useState(0);
  const [modalInfo, setModalInfo] = useState(null);
  useEffect(() => {
    const rootr = document.documentElement;
    var number = document.getElementById("number");
    var count = 0;
    rootr.style.setProperty(
      "--percentageCircularBar",
      300 - (300 * counter) / 100 + "%"
    );
    setInterval(() => {
      if (count === counter) {
        clearInterval();
      } else {
        count += 1;
        if (number) number.innerHTML = count + "%";
      }
    }, 30);
  }, [counter]);
  useEffect(() => {
    setcounter(50);
  }, []);


  const selectOption = (
    refContainer,
    index,
    classess,
    arr,
    onlyone,
    pathHere
  ) => {
    if (onlyone === true) {
      for (const iterator of arr) {
        
        iterator[2].current.classList = classess;
        iterator[2].current.active = false;
        iterator[3] = false;
      }
    }

    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (pathHere === "out") {
      perlo();
    } else {
      return navigate(pathHere);
    }
  };

  const categorias = [
    [
      "Clases",
      "grupales en vivo",
      process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
    ],
    [
      "Intensidad",
      "baja",
      process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
    ],
    [
      "Intensidad",
      "media",
      process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
    ],
    [
      "Intensidad",
      "alta",
      process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
    ],
  ];

  const entrenamientoHoras = [
    {
      dia: "Enero 13",
      credito: "2 créditos",
      hora: "9:00 / 10:00 am"
    },
    {
      dia: "Enero 19",
      credito: "2 créditos",
      hora: "9:00 / 10:00 am"
    },
    {
      dia: "Enero 13",
      credito: "2 créditos",
      hora: "9:00 / 10:00 am"
    }
  ]

  return (
    <div
      className="d-flex col-12 "
      style={{
        height: "100%",
      }}
    >
      <div
        className="col-2"
        style={{
          flexDirection: "column",
        }}
      >
        <div
          className=" w-100 mx-auto py-4  text-center"
          style={{ height: "20%" }}
        >
          <img
            alt="logo_alta_black"
            style={{ padding: "5% 20%", width: "80%" }}
            src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
          />
        </div>
        <div
          className=" w-100"
          style={{
            height: "80%",
          }}
        >
          {Barra.map((elementos, index) => (
            <div
              key={index}
              ref={elementos[2]}
              style={{ fontWeight: "600" }}
              className="d-flex option-page text-color-title  py-3 "
              onClick={() =>
                selectOption(
                  elementos[2],
                  index,
                  "d-flex option-page fw-bold  py-3 ",
                  Barra,
                  true,
                  elementos[4]
                )
              }
            >
              <div className="my-auto mx-2 Iconresponsive">{elementos[1]} </div>
              <div className="mx-2 letraPequeña">{elementos[0]} </div>
            </div>
          ))}
        </div>
      </div>
      {modalInfo}
      <div
        className="col-10  position-relative"
        style={{
          background: "rgba(0,0,0,.7)",
          height: "100vh",

          padding: "2% 3% 2% 2%",
        }}
      >
        <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
          <div
            className="col-3"
            style={{
              background: "white",
              height: "100%",
              padding: "2%",
              borderRadius: "20px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="d-flex my-auto">
              <div
                onClick={() => navigate(`/Usuario/Inicio`)}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <span
                className="fw-bold my-auto ms-2"
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "2.0vmin",
                }}
              >
                {" "}
                Mis reservas Elevva
              </span>
            </div>
            <hr />
            <div style={{ lineHeight: "110%", margin: "2vmin auto" }}>
              <span
                style={{
                  color: "rgba(57,79,101,0.5)",
                  fontSize: "2.0vmin",
                }}
              >
                Selecciona
              </span>
              <br />
              <span
                className="fw-bold"
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "2.0vmin",
                }}
              >
                {" "}
                Categoria de interes
              </span>
            </div>
            {categorias.map((e) => (
              <div
                className="position-relative mx-auto"
                style={{
                  backgroundImage: `url(${e[2]})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "20px",
                  width: "100%",
                  height: "18%",
                  marginTop: "1.5vmin",
                  marginBottom: "1.5vmin",
                }}
              >
                <div
                  className="position-absolute "
                  style={{
                    color: "var(--elevvaTextTitle)",
                    display: "flex",
                    height: "100%",
                    left: "5%",
                    justifyContent: "center",
                    flexDirection: "column",
                    lineHeight: "90%",
                  }}
                >
                  <span className="letraPequeñaother mb-1">{e[0]}</span>
                  <div style={{ maxWidth: "10vmin", lineHeight: "110%" }}>
                    <span className="letraPequeñaCardTitleBook fw-bold">
                      {e[1]}
                    </span>
                  </div>
                </div>

                <div
                  className="position-absolute fw-bold booking-all"
                  style={{
                    background: "var(--backgroundOnboardingObjective)",
                    bottom: "9%",
                    right: "5%",
                    padding: "2%",
                    fontSize: "1vmin",
                    color: "var(--white)",
                    borderRadius: "10px",
                  }}
                >
                  <span style={{ fontSize: "0.5vmin" }}>
                    <i className="bi bi-chevron-right"></i>
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div
            className="col-9"
            style={{
              background: "white",
              height: "100%",
              padding: "3% 0",
              borderRadius: "20px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex"
              style={{
                height: "20%",
                justifyContentc: "center",
                flexDirection: "column",
                backgroundImage: `url(${process.env.PUBLIC_URL + "/img/estres.png"
                  })`,
                backgroundSize: "cover",
                backgroundPositionX: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "59px",
                height: "25%"

              }}
            >
              <div className="d-flex my-auto">
                <div
                  onClick={() => navigate(`/Usuario/Inicio`)}
                  className=" fw-bold booking-all"
                  style={{
                    background: "transparent",
                    border: "1px solid #A8C2D7",
                    padding: "1%",
                    fontSize: "1vmin",
                    height: "fit-content",
                    cursor: "pointer",
                    color: "#A8C2D7",
                    borderRadius: "10px",
                    position: "relative",
                    left: "1%"
                  }}
                >
                  <span style={{ fontSize: "0.5vmin" }}>
                    <i className="bi bi-chevron-left"></i>
                  </span>
                </div>
                <div style={{ padding: "0% 3% 0% 2%", lineHeight: "220%" }}>
                  {" "}
                  <div
                    style={{
                      color: "#394F65",
                      fontSize: "4vmin",
                    }}
                  >

                    <span
                      style={{
                        fontWeight: "300",
                        color: "#394F65",
                        fontSize: "4vmin",
                      }}
                    >
                      Entrenamiento
                    </span>
                    <br />
                    <strong>HIIT training</strong>
                  </div>
                </div>
              </div>

              <br />
            </div>
            <div className="d-flex  mx-auto  ">
              <div
                className="col-5"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2%",
                }}
              >
                <div className="d-flex my-auto">
                  <div
                    onClick={() => navigate(`/Usuario/Inicio`)} className=" fw-bold booking-all"
                    style={{
                      background: "transparent",
                      // border: "1px solid #A8C2D7",
                      padding: "2%",
                      fontSize: "1vmin",
                      height: "fit-content",
                      cursor: "pointer",
                      // color: "#A8C2D7",
                      borderRadius: "10px",
                    }}
                  >
                    <span style={{ fontSize: "4.5vmin" }}>
                      <i className="bi bi-2-circle"></i>
                    </span>
                  </div>
                  <div className=" my-auto mx-12">
                    <span

                      style={{
                        color: "#394F65", fontSize: "2.5vmin",
                      }}
                    >
                      Selecciona tus <strong style={{
                        color: "#394F65",
                        fontWeight: "bold",
                        fontSize: "3vmin",

                      }}>fechas de entrenamiento</strong>

                    </span>

                  </div>

                </div>
                <div className="d-flex my-auto py-4 mx-4">
                  <span
                    style={{
                      color: "#A9B6C4",
                      fontWeight: "bold",
                      fontSize: "1.5vmin",
                      textAlign: "initial"
                    }}
                  >
                    {elevvaT("text_entrenamiento")}
                  </span>
                </div>
                <div className="d-flex my-auto col-12 justify-content-center ">
                  <CalendarProfe />
                </div>
                <div className="py-4 justify-content-center col-12 d-flex my-auto">
                  <button
                    className="d-flex"
                    // onClick={handleClick}
                    style={{
                      background: "var(--elevva)",
                      border: "1px solid var(--elevva)",
                      borderRadius: "20px",
                      color: "var(--white)",
                      fontWeight: "400",
                      fontSize: "1.5vmin",
                      padding: "2% 5%",
                    }}
                  ><i className="bi bi-chevron-left"></i>
                    Regresar
                  </button>
                </div>

              </div>
              <div className="direcccionately col-7 d-flex">
                <div
                  className="col-12 d-flex"
                  style={{
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2%",
                  }}
                >
                  <div className="d-flex my-auto">
                    <div
                      onClick={() => navigate(`/Usuario/Inicio`)} className=" fw-bold booking-all"
                      style={{
                        background: "transparent",
                        // border: "1px solid #A8C2D7",
                        padding: "2%",
                        fontSize: "1vmin",
                        height: "fit-content",
                        cursor: "pointer",
                        // color: "#A8C2D7",
                        borderRadius: "10px",
                      }}
                    >
                      <span style={{ fontSize: "4.5vmin" }}>
                        <i className="bi bi-3-circle"></i>
                      </span>
                    </div>
                    <div className=" my-auto mx-12">
                      <span

                        style={{
                          color: "#394F65", fontSize: "2.5vmin",
                        }}
                      >
                        Selecciona tus <strong style={{
                          color: "#394F65",
                          fontWeight: "bold",
                          fontSize: "3vmin",

                        }}>fechas de entrenamiento</strong>

                      </span>
                      <div className="my-auto mx-12 py-3 p-2 g-col-6">
                        {entrenamientoHoras.map((e, i) => (
                          <div
                            key={i}
                            className="d-flex position-relative changecolortable my-3"
                            style={{
                              justifyContent: "space-around",
                              borderRadius: "40px",
                              textAlign: "center",
                              padding: "2%",
                            }}
                          >
                            <div
                              onClick={() => navigate(`/Usuario/Inicio`)} className=" fw-bold booking-all"
                              style={{
                                background: "transparent",
                                // border: "1px solid #A8C2D7",
                                padding: "2%",
                                fontSize: "1vmin",
                                height: "fit-content",
                                cursor: "pointer",
                                color: "#A8C2D7",
                                borderRadius: "10px",
                              }}
                            >
                              <span style={{ fontSize: "3.5vmin" }}>
                                <i class="bi bi-trash3"></i>
                              </span>
                            </div>
                            <div
                              className="d-flex "
                              style={{
                                justifyContent: "center",
                                flexDirection: "column",
                                textAlign: "center",
                                padding: "2%",
                              }}
                            >
                              <span className="fw-bold my-auto ms-2" style={{
                                color: "var(--elevvaTextTitle)",
                                fontSize: "2.0vmin",
                              }}>{e.dia}</span>
                              <span className="fw-bold my-auto ms-2" style={{
                                color: "var(--elevvaTextTitle)",
                                fontSize: "2.0vmin",
                              }}>{e.credito}</span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: "center",
                                flexDirection: "column",
                                textAlign: "center",
                                padding: "2%",
                              }}
                            >
                              <span className="fw-bold my-auto ms-2" style={{
                                color: "var(--elevvaTextTitle)",
                                fontSize: "2.0vmin",
                              }}>{e.hora}</span>                                                </div>
                            <div className="d-flex my-auto">
                              <div
                                className="position-absolute fw-bold booking-all"
                                style={{
                                  background: "transparent",
                                  border: "2px solid #A8C2D7",
                                  padding: "2%",
                                  fontSize: "1vmin",
                                  height: "fit-content",
                                  cursor: "pointer",
                                  // color: "#A8C2D7",
                                  borderRadius: "10px",
                                  bottom: "27%",
                                  right: "3%",
                                }}
                              >
                                <span style={{ fontSize: "1.5vmin" }}>
                                  <i className="bi bi-chevron-down"></i>
                                </span>
                              </div>

                            </div>
                          </div>
                        ))}
                      </div>
                    </div>


                  </div>
                  <div
                    className="my-2"
                    style={{
                      color: "#394F65",
                      fontWeight: "600",
                      fontSize: "1.5vmin",
                    }}
                  >
                    Costo total de tu reserva - 4 créditos
                  </div>
                  <div style={{ margin: "2vmin 0" }}>
                    {" "}
                    <button
                    onClick={() =>
                              setModalInfo(
                                  <ModalSolid
                                      handleCancellcargue={() => setModalInfo(null)}
                                      error="tru"
                                      text={
                                          "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                                      }
                                      classesMsg=""
                                  />
                              )
                          }
                      className="d-flex"
                      // onClick={handleClick}
                      style={{
                        background: "var(--elevva)",
                        border: "1px solid var(--elevva)",
                        borderRadius: "20px",
                        color: "var(--white)",
                        fontWeight: "400",
                        fontSize: "2.5vmin",
                        padding: "2% 27%",
                      }}
                    >
                      Reserva <i className="ms-1 bi bi-journal-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const ReservaCustomer = (props) => {
  const navigate = useNavigate();
  const [optionsProfe, setoptionsProfe] = useState("inicio");
  function handleClick() {
    props.handleClick(null, true);
  }
  function optionsProfeClick() {
    setoptionsProfe("seleccionar");
  }

  return (
    <div></div>
  )
};
export default HoraEntrenamiento;
