import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./App.css";
import i18n from "./i18n";
import { Helmet } from 'react-helmet';
import { Buffer } from 'buffer';
import { useEffect, useState } from "react";
import Login from "./components/Login/login";

import LoginCustomer from "./components/LoginStart/loginCustomer";
import LoginTrainer from "./components/LoginStart/loginTrainer";
import Createaccount from "./components/createAccount/createaccount";
import WelcometoElevva from "./components/welcometoElevva/welcometoElevva";

// Onboardind which objective
import { YourObjective } from "./components/welcometoElevva/YourObjectives"

import { OnboardingTrainer } from "./components/OnboardingTrainer/documents";
import { OnboardingCustomer } from "./components/OnboardingCustomer/onboardingCustomer";
import Inicio from "./components/inicio/inicio";
import Reservas from "./components/grupales/viewGroupals";

// Entrenamiento bajo
import Low  from "./components/Baja/Low"

// Reserva individuales

// Reserva individualMedia
import  { IndividualMedia }  from "./components/Individual/IndividualMedia"
// seleccionar Hora Profe
import  HoraEntrenamiento from "./components/Individual/HoraEntrenamiento"
// Seleccionar Profe individual
import SeleccionarProfe from "./components/Individual/SeleccionarProfe"

//Resumen final
import IndividualResumen from "./components/Individual/IndividualResumen";

//Terminos 
// import Terminos  from "./components/FAQ/FAQ";

import Ejercicios from "./components/Ejercicios/Ejercicios";
import VerinfoIndividual from "./components/vertipo/verinfoindividual";
import VerTipoIndividual from "./components/vertipo/vertipoindividual";
import CreateaccountPay from "./components/createAccountPay/createaccountPay";
import Payment from "./components/payment/payment";
//import {Payment} from "./components/payment/payment";

import {tawkToPropertyId } from "./utils/utils";
import Dashboard from "./components/Dashboard/dashboard";
import LoginCustomerConvenio from "./components/LoginConvenio/loginConvenio";
import CreateaccountMember from "./components/createAccountMembership/createaccountMember";
import { YourObjectivesMember } from "./components/welcometoElevva/YourObjectivesMember";
import TutorialReserva from "./components/TutorialReserva/TutorialReserva";
import TestSlider from "./components/testslider";
import NavBar from "./Navbar/navBar";
import TrainingId from "./components/trainingId/trainingId";
import SelectTrainer from "./components/SelectTrainer/selectTrainer";
import SelectSchedule from "./components/selectSchedule/selectSchedule";
import ResumenReserva from "./components/ResumenReserva/ResumenReserva";

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import BookingBook from "./components/BookingBook/bookingBook";
import TrainerCv from "./components/Conoceme/conoceme";
import ScheduleMobile from "./components/selectSchedule/selectScheduleMobile";
import TutorialReservaMobile from "./components/TutorialReserva/TutotialReservaMob";
import Faq from "./components/FAQ/FAQ";
import TyC from "./components/Terminos/terminos.js";
import Misclases from "./components/Mis clases/misclases";
import YourBody from "./components/yourBody/yourBody";
import Activity from "./components/Actividad/actividad";
import Results from "./components/results/results";
import Recientes from "./components/results/recientes";
import ReservasAll from "./components/Reservas/Reservas";
import Clase from "./components/Clase/Clase";
import Clasestream from "./components/Clase/Clasestream";
import ShareWhastapp from "./components/results/ShareWhastapp";
import VerTipo from "./components/vertipo/vertipo";
import GroupList from "./components/GroupList/GroupList";
import Perfil from "./components/Perfil/Perfil";
import MiCuenta from "./components/Perfil/MiCuenta";
import Transacciones from "./components/Perfil/TransaccionesMobile";
import MisLesiones from "./components/Perfil/LesionesMobile";
import MisObjetivos from "./components/Perfil/MisObjetivos";
import PlanTraining from "./components/Perfil/Plan";
import VerinfoIndividualTutorial from "./components/vertipo/tutorialVideosInfo";
import ActivityApp from "./components/Actividad/actividadApp";
import RecoverPassword from "./components/recoverPassword/recoverPass";





function App() {
  const { t } = useTranslation();

  // Asigna Buffer a una variable global
  global.Buffer = Buffer;
  const NameCustomer = t("cover_start_login_customer");
  const NameTrainer = t("cover_start_login_trainer");
  const [AllVideos, setAllVideos] = useState(null);

  const [location_all_information, setlocation_all_information] =
    useState(null);

  useEffect(() => {
    axios.get("https://ipwhois.app/json/").then((res) => {
      const data_location = res.data;
      if (data_location) {
        if (data_location.continent === "South America")
          i18n.changeLanguage("es");
        else i18n.changeLanguage("en");
        setlocation_all_information(data_location);
      }
    });
    axios.get("/data/videos.json").then((res) => setAllVideos(res.data));
  }, []);

  //soccket.imageOrientation:

  // const enviarSyncClock = () => {
  //   console.log("socket",socket.connected,socket);

  //   socket.emit("sync_clock", { }, (response) => {
  //     console.log(response); // ok
  //   });
  // };

  // useEffect(() => {
  //   checkData()
  // }, []);

  // chek data login

  const [isLogged, setLogged] = useState(null);

  const [user, setUser] = useState({
    username: null,
    type: null,
    code: null,
    img: null,
    credits: null,
  });

  // function checkData(id_generado) {
  //   if (id_generado) {
  //     const formData_o = new FormData();
  //     formData_o.append("onesignal_id", id_generado);
  //     instance
  //       .post(`users/push/onesignal-id`, formData_o)
  //       .then((res) => {})
  //       .catch((err) => console.log(err.response));
  //   }
  //   instance
  //     .get("users/")
  //     .then((response) => {
  //       const data_user = response.data;

  //       if (data_user) {
  //         const formData = new FormData();
  //         formData.append("data", localStorage.my_code);

  //         if (localStorage.type == userType) {
  //           if (!localStorage.type) {
  //             localStorage.removeItem("my_code");
  //             localStorage.removeItem("type");
  //             setUser({
  //               username: null,
  //               type: null,
  //               code: null,
  //             });
  //             setLogged(false);
  //           } else {
  //             localStorage.id = data_user.id;

  //             setUser({
  //               type: localStorage.type,
  //               code: localStorage.my_code,
  //               firts_name: data_user.first_name,
  //               last_name: data_user.last_name,
  //               customer: data_user.customer ? data_user.customer : null,
  //               email: data_user.email,
  //               img: data_user.customer.profile_pic,
  //               credits: data_user.customer
  //                 ? data_user.customer.credits_balance
  //                 : 0,
  //             });

  //             setLogged(true);
  //           }
  //         } else {
  //           if (!localStorage.type) {
  //             localStorage.removeItem("my_code");
  //             localStorage.removeItem("type");
  //             setUser({
  //               username: null,
  //               type: null,
  //               code: null,
  //             });
  //             setLogged(false);
  //           } else {
  //             localStorage.id = data_user.id;

  //             setUser({
  //               type: localStorage.type,
  //               code: localStorage.my_code,
  //               firts_name: data_user.first_name,
  //               last_name: data_user.last_name,
  //               trainer: data_user.trainer ? data_user.trainer : null,
  //               email: data_user.email,
  //               img: data_user.trainer.profile_pic,
  //               credits: data_user.trainer
  //                 ? data_user.trainer.credits_balance
  //                 : 0,
  //             });

  //             setLogged(true);
  //           }
  //         }
  //         setRenderroute(true);
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         localStorage.clear();

  //         window.location.href = `${basename}/`;
  //       }
  //     });
  // }

  const videos = [
    [
      process.env.PUBLIC_URL + "/img/Enmarcarar3.jpg",
      "Entrenamiento en familia",
      "Nuestro programa de Entrenamiento en Familia, tiene como objetivo dar un espacio de integración en donde padres e hijos logren desarrollo motriz además de salud física y emocional.",
      6,
      process.env.PUBLIC_URL + "/videos/ENTRENAMIENTOFAMILIABANNERopti.mp4",
      "vivo",
    ],
    [
      process.env.PUBLIC_URL + "/img/Enmascar.jpg",
      "Tono Muscular",
      "Nuestro programa de Tono Muscular es ideal para personas que inician actividad deportiva, realizando un trabajo efectivo en tu cuerpo, cuidando tus articulaciones.",
      7,
      process.env.PUBLIC_URL + "/videos/TONOMUSCULARBANNERopti.mp4",
      "Entrenamiento",
    ],
    [
      process.env.PUBLIC_URL + "/img/hipotiroides.jpg",
      "Recetas rápidas y saludables",
      "Nuestro programa de recetas rápidas y saludables, tiene como objetivo presentarte recetas novedosas y nutritivas con ingredientes alternativos.",
      10,
      process.env.PUBLIC_URL + "/videos/ALIMENTACIONALTERNATIABANNERopti.mp4",
      "Nutrición",
    ],
    [
      process.env.PUBLIC_URL + "/img/estres.png",
      "Yoga prenatal",
      "Nuestro programa Yoga Prenatal, ayudará con rutinas específicas a resolver síntomas asociados al estrés, inflamación y demás padecimientos asociados al embarazo.",
      8,
      process.env.PUBLIC_URL + "/videos/YOGAPRENATALBANNERopti.mp4",
      "Mente",
    ],
  ];

  const customStyle = {
    visibility: {
      mobile: {
        xOffset: 15,
        yOffset: 60,
        position: "br",
      },
    },
  };

  return (
    <Router>
      <div
        className="col-12 d-md-flex"
        style={{
          height: "100%",
        }}
      >
        {window.location.pathname.includes(`/Usuario/clasestream/`) ||
        window.location.pathname.includes(`/actividadApp`) ? null : (
          <TawkMessengerReact
            customStyle={
              window.location.pathname == `/Onboarding` ? customStyle : null
            }
            propertyId={tawkToPropertyId}
            widgetId="default"
          />
        )}
        <NavBar path={"Nav"} typeCustomer={NameCustomer} />

        <Routes>
          <Route
            exact
            path="/"
            element={<LoginCustomerConvenio elevvaT={t} />}
          />
          <Route
            exact
            path="/recuperar"
            element={<RecoverPassword elevvaT={t} />}
          />
          <Route
            exact
            path={`/${NameCustomer}`}
            element={<LoginCustomer elevvaT={t} type={NameCustomer} />}
          />
          <Route
            exact
            path={`/${NameTrainer}`}
            element={<LoginTrainer elevvaT={t} type={NameTrainer} />}
          />
          <Route
            exact
            path={`createUser/:user`}
            element={<Createaccount elevvaT={t} type={NameCustomer} />}
          />

          {/* <Route
         exact
         path={`seleccionaConvenio/Usuario`}
         element={<LoginCustomerConvenio type={NameCustomer} elevvaT={t} />}
       /> */}
          <Route
            exact
            path={`selectMembership/Usuario`}
            element={<CreateaccountMember type={NameCustomer} elevvaT={t} />}
          />
          <Route
            exact
            path={`createUserPay/Usuario`}
            element={<CreateaccountPay elevvaT={t} />}
          />
          <Route
            exact
            path={`/${NameCustomer}/welcometoElevva`}
            element={<WelcometoElevva type={NameCustomer} elevvaT={t} />}
          />

          {/* Rute the  YourObjective */}

          <Route
            exact
            path={`/${NameCustomer}/YourObjectivesMember`}
            element={<YourObjectivesMember elevvaT={t} />}
          />

          {/* Rute the conoce tu cuerpo */}

          <Route exact path={`/yourBody`} element={<YourBody elevvaT={t} />} />
          {/* Rute the results */}

          <Route
            exact
            path={`/results/:idTraining`}
            element={<Results elevvaT={t} />}
          />

          {/* Rute mi actividad */}

          <Route exact path={`/actividad`} element={<Activity elevvaT={t} />} />

          {/* Rute mi actividad */}

          <Route
            exact
            path={`/actividadApp`}
            element={<ActivityApp elevvaT={t} />}
          />
          {/* Rute the  Training */}
          <Route
            exact
            path={`/training/:id`}
            element={<TrainingId elevvaT={t} user={user} />}
          />
          {/* Rute the  groupList */}
          <Route
            exact
            path={`/selectGrupalList/:id`}
            element={
              <GroupList
                elevvaT={t}
                user={user}
                zonahorairachange={location_all_information}
              />
            }
          />

          {/* Rute the  select teacher */}
          <Route
            exact
            path={`/selectTeacher/:id`}
            element={<SelectTrainer elevvaT={t} />}
          />

          {/* Rute the call class */}
          <Route
            path={`/${NameCustomer}/clase/:reserva`}
            exact
            element={<Clase />}
          />
          {/* Rute the Perfil */}
          <Route
            exact
            path={`/perfil`}
            element={
              <Perfil
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />
          {/* Rute the Perfil */}
          <Route
            exact
            path={`/Micuenta`}
            element={
              <MiCuenta
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />
          {/* Rute the Transacciones */}
          <Route
            exact
            path={`/Transacciones`}
            element={
              <Transacciones
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />
          {/* Rute the Plan */}
          <Route
            exact
            path={`/Plan`}
            element={
              <PlanTraining
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />
          {/* Rute the Lesiones */}
          <Route
            exact
            path={`/MisLesiones`}
            element={
              <MisLesiones
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />
          {/* Rute the misObjetivos */}
          <Route
            exact
            path={`/MisObjetivos`}
            element={
              <MisObjetivos
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />
          {/* Rute the  book Ready */}
          <Route
            exact
            path={`/Book/:id`}
            element={
              <BookingBook
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />

          {/* Rute the  select schedule */}
          <Route
            exact
            path={`/selectSchedule/:id`}
            element={<SelectSchedule elevvaT={t} />}
          />

          {/* Rute the  select houronly */}
          <Route
            exact
            path={`/ScheduleMobile/:id`}
            element={<ScheduleMobile elevvaT={t} />}
          />

          {/* Rute the  trainer info */}
          <Route
            exact
            path={`/trainerInfo/:id/:idTrainer`}
            element={<TrainerCv elevvaT={t} />}
          />
          {/* Rute the  select schedule */}
          <Route
            exact
            path={`/resumenReserva/:id`}
            element={
              <ResumenReserva
                elevvaT={t}
                zonahorairachange={location_all_information}
              />
            }
          />
          <Route exact path={`Slider`} element={<TestSlider elevvaT={t} />} />

          <Route
            exact
            path={`/${NameTrainer}/Onboarding`}
            element={<OnboardingTrainer elevvaT={t} />}
          />
          {/* faqs  rutas */}
          <Route exact path={`faqs`} element={<Faq elevvaT={t} />} />
          {/* terminos rutas */}
          <Route exact path={`tyc`} element={<TyC elevvaT={t} />} />
          <Route
            exact
            path={`/Onboarding`}
            element={<OnboardingCustomer elevvaT={t} />}
          />

          {/* Ruta Individual Media */}
          {/* <Route
         exact
         path={`/IndividualMedia`}
         element={<IndividualMedia elevvaT={t} />}

       /> */}
          {/* Ruta SeleccionarProfe */}
          <Route
            exact
            path={`/SeleccionarProfe`}
            element={<SeleccionarProfe elevvaT={t} />}
          />
          {/* Ruta Tutorial */}
          <Route
            exact
            path={`/TutorialReserva/:id`}
            element={
              <TutorialReserva
                elevvaT={t}
                zonahorairachange={location_all_information}
                typeCustomer={NameCustomer}
              />
            }
          />
          {/* Ruta sesiones recientes */}
          <Route
            exact
            path={`/sesiones`}
            element={
              <Recientes
                elevvaT={t}
                zonahorairachange={location_all_information}
                typeCustomer={NameCustomer}
              />
            }
          />
          {/* Ruta reservas */}
          <Route
            exact
            path={`/reservas`}
            element={
              <ReservasAll
                elevvaT={t}
                zonahorairachange={location_all_information}
                typeCustomer={NameCustomer}
              />
            }
          />

          {/* Ruta Tutorial responsive */}
          <Route
            exact
            path={`/TutorialResponsive/:id`}
            element={
              <TutorialReservaMobile
                elevvaT={t}
                zonahorairachange={location_all_information}
                typeCustomer={NameCustomer}
              />
            }
          />
          {/* Ruta Mis clases */}
          <Route
            exact
            path={`/misclases/:id`}
            element={<Misclases elevvaT={t} user={user} />}
          />

          {/* Ruta SeleccionarProfe */}
          <Route
            exact
            path={`/HoraEntrenamiento`}
            element={<HoraEntrenamiento elevvaT={t} />}
          />
          {/* Ruta Resumen */}
          {/* <Route
         exact
         path={`/IndividualResumen`}
         element={<IndividualResumen elevvaT={t} />}

       /> */}

          <Route
            exact
            path={`/${NameCustomer}/Inicio`}
            element={<Inicio elevvaT={t} path={"Progreso"} />}
          />
          <Route
            exact
            path={`/Dashboard`}
            element={<Dashboard elevvaT={t} path={"Progreso"} user={user} />}
          />

          <Route
            exact
            path={`/${NameCustomer}/clasestream/:reserva`}
            element={<Clasestream elevvaT={t} user={user} />}
          />

          <Route
            exact
            path={`/${NameCustomer}/groupals`}
            element={
              <Reservas elevvaT={t} path={"Reserva"} AllVideos={AllVideos} />
            }
          />
          {/* Lows */}
          <Route
            exact
            path={`/${NameCustomer}/Low`}
            element={<Low elevvaT={t} path={"Low"} AllVideos={AllVideos} />}
          />
          <Route exact path={`/Share/:url`} element={<ShareWhastapp />} />
          <Route
            exact
            path={`/${NameCustomer}/payment`}
            element={<Payment elevvaT={t} path={"payment"} />}
          />
          <Route
            exact
            path={`/videos`}
            element={
              <Ejercicios
                elevvaT={t}
                path={"videos"}
                AllVideos={AllVideos}
                videos={videos}
              />
            }
          />
          <Route
            exact
            path={`/videos/info/:id`}
            element={
              <VerinfoIndividual
                elevvaT={t}
                path={"videos"}
                AllVideos={AllVideos}
                videos={videos}
              />
            }
          />
          <Route
            exact
            path={`/videos/infoTutorial`}
            element={
              <VerinfoIndividualTutorial
                elevvaT={t}
                path={"videos"}
                AllVideos={AllVideos}
                videos={videos}
              />
            }
          />
          <Route
            path={`/videos/:tipo`}
            exact
            element={<VerTipo AllVideos={AllVideos} />}
          />

          <Route
            exact
            path={`/videos/card/:id/:idvideo`}
            element={
              <VerTipoIndividual
                elevvaT={t}
                path={"videos"}
                AllVideos={AllVideos}
                videos={videos}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
