import React, { useRef } from "react"; // import { Lesiones } from "../Lesiones/lesiones";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/swiper.min.css";

import "swiper/swiper-bundle.min.css";
import "./payment.css";
import { Allcategory, ReservaCustomer, } from "./utils";
import {
  // ModalInfoMembresias, 
  perlo,
  instance,
  NuevaAxiosURL
} from "../../utils/utils";
import UtilsName from "./utils"
// const Payment = (props) => {
function Payment(props) {

  const navigate = useNavigate();
  //const pathHere = props.path;

  // const elevvaT = props.elevvaT;

  const Barra = [
    [
      "Progreso",
      <i className="bi bi-house"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/Inicio",
    ],

    [
      "Mi perfil",
      <i class="bi bi-person-circle"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Videos de bienestar y entrenamiento",
      <i className="bi bi-play-btn-fill"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Reserva de clases",
      <i className="bi bi-calendar-date"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/groupals",
    ],
    [
      "Adquiere equipos",
      <i className="bi bi-box2"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Preguntas frecuentes",
      <i className="bi bi-chat-right-quote"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Términos y condiciones políticas de privacidad",
      <i class="bi bi-pencil-square"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Cerrar sesión",
      <i className="bi bi-box-arrow-left"></i>,
      useRef(null),
      useRef(null),
      "out",
    ],
  ];
  const categorias = [
    ["Plan", "elevva gratuito"],
    ["Plan", "elevva básico"],
    ["Plan", "elevva pro"],
    ["Plan", "elevva plus"],
  ];
  const selectOption = (
    refContainer,
    index,
    classess,
    arr,
    onlyone,
    pathHere
  ) => {
    if (onlyone === true) {
      for (const iterator of arr) {
       
        iterator[2].current.classList = classess;
        iterator[2].current.active = false;
        iterator[3] = false;
      }
    }

    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (pathHere == "out") {
      perlo();
    } else {
      return navigate(pathHere);
    }
  };
  const [reservaId, setreservaId] = useState(null);

  // useEffect(() => {
  //   var reserva = AllVideos;
  //   setreservaId(
  //     <Allcategory
  //       AllVideos={AllVideos}
  //       handleClick={(e) =>
  //         setreservaId(<ReservaCustomer id={e} AllVideos={reserva} />)
  //       }
  //     />
  //   );
  // }, [AllVideos]);
  //   useEffect(() => {
  //     var activeme = Barra.filter((e) => e[0].includes(pathHere));

  //     if (activeme[0][2]) {
  //       activeme[0][2].current.classList =
  //         "d-flex option-page fw-bold  py-3 active";
  //       activeme[0][2].current.active = true;
  //     }
  //   }, [pathHere]);
  // const [selectedMembership, setSelectedMembership] = useState("");

  const [selectedMembership, setSelectedMembership] = useState("");

  function handleMembershipChange(event) {
    setSelectedMembership(event.target.value);
  }
  //console.log(selectDescription);
  // Memberships
  const [dataMemberships, setDataMemberships] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await instance.get(`${NuevaAxiosURL}memberships/?ordering=price`);
        const data = response.data;
        
        const newDataMemberships = data.map((item) => {
          const description = item.description.split('\r\n');
          return {
            id: item.id,
            name: item.name,
            //description: item.description,
            description:description,
            price: item.fee,
            modoPago: item.payment_currency,
            pagoFrecuente: item.payment_frequency_time,
            tiempoPago: item.payment_frequency_time,
            active: item.is_active,
            corporate: item.is_corporate
          };
        });
        setDataMemberships(newDataMemberships);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  //console.log(dataMemberships);

  // Delimitador de nombre
  const filteredMemberships = dataMemberships.filter(
    (membership) =>
      membership.name === "Básico" ||
      membership.name === "Gratuito" ||
      membership.name === "Pro" ||
      membership.name === "Elevva plus"
  );
  const sortedMemberships = filteredMemberships.sort(
    (a, b) => a.price - b.price
  );
  const limitedMemberships = sortedMemberships.slice(0, 5);
  //console.log(limitedMemberships);

  return (
    <div
      className="d-flex col-12 "
      style={{
        height: "100%",
      }}
    >
      <div
        className="col-2"
        style={{
          flexDirection: "column",
        }}
      >
        <div
          className=" w-100 mx-auto py-4  text-center"
          style={{ height: "20%" }}
        >
          <img
            alt="logo_alta_black"
            style={{ padding: "5% 20%", width: "80%" }}
            src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
          />
        </div>
        <div
          className=" w-100"
          style={{
            height: "80%",
          }}
        >
          {Barra.map((elementos, index) => (
            <div
              key={index}
              ref={elementos[2]}
              style={{ fontWeight: "600" }}
              className="d-flex option-page text-color-title  py-3 "
              onClick={() =>
                selectOption(
                  elementos[2],
                  index,
                  "d-flex option-page fw-bold  py-3 ",
                  Barra,
                  true,
                  elementos[4]
                )
              }
            >
              <div className="my-auto mx-2 Iconresponsive">{elementos[1]} </div>
              <div className="mx-2 letraPequeña">{elementos[0]} </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="col-10  position-relative"
        style={{
          background: "rgba(0,0,0,.7)",
          height: "100vh",

          padding: "2% 3% 2% 2%",
        }}
      >
        <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
          <div
            className="col-3"
            style={{
              background: "white",
              height: "100%",
              padding: "2%",
              borderRadius: "20px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="d-flex">
              <div
                onClick={() => navigate(`/Usuario/Inicio`)}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div style={{ lineHeight: "110%", margin: "0.5vmin auto" }}>
                <span
                  style={{
                    color: "rgba(57,79,101,0.5)",
                    fontSize: "2.0vmin",
                  }}
                >
                  No pares de entrenar
                </span>
                <br />
                <span
                  className="fw-bold"
                  style={{
                    color: "var(--elevvaTextTitle)",
                    fontSize: "2.0vmin",
                  }}
                >
                  {" "}
                  selecciona tu plan
                </span>
              </div>
            </div>
            {limitedMemberships.map(membership => (
              <div
                className="position-relative mx-auto"
                style={{
                  background: `#F4F0ED`,
                  borderRadius: "30px",
                  width: "100%",
                  marginTop: "1.5vmin",
                  marginBottom: "1.5vmin",
                  padding: "4% 8%",
                }}
                key={membership.id}
              >
                <div className="d-flex">
                  <div
                    className="w-50"
                    style={{
                      fontSize: "1.5vmin",
                      color: "#394F65",
                      fontWeight: "600",
                    }}
                  >
                    {"Plan"}
                    <br></br>
                    {membership.name}
                  </div>
                  <div
                    className="w-50 my-auto mx-2 d-flex"
                    style={{ justifyContent: "right" }}
                  >
                  <div className="rosa-background">
                    <input
                      type="radio"
                      name="membership"
                      value={membership.name}
                      onChange={handleMembershipChange}
                    />  
                    </div>               
                    </div>
                    {/* <p>{selectedMembership}</p> */}
                </div>
              </div>
            ))}
            {/* <UtilsName selectedMembership={selectedMembership} />           */}
            </div>
          {reservaId ? (
            reservaId
          ) : (
            <Allcategory
            selectedMembership={selectedMembership} 
            limitedMemberships={limitedMemberships}  
              handleClick={(e) =>
                setreservaId(
                  <ReservaCustomer
                    id={e}
                    handleClick={(e) => setreservaId(e)}
                  />
                )
              }
            />
          )}

        </div>
      </div>
    </div>
  );
};

export default Payment;


// export const Utils = ({ selectedMembership }) => {
//   return (
//     <div>
//       <Utils selectedMembership={selectedMembership} />    </div>
//   );
// };
