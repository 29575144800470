import React, { useRef, useState } from "react";
import "../../utils/utils.css";

import { useNavigate, useLocation } from "react-router-dom";
import { ButtonMob } from "../../utils/utils";

export const ModalSolid = React.forwardRef((props, ref) => {
  const imgDiv = "../../assets/img/ilustracion_1.png"
  const navigate = useNavigate();
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleCancellcargue = props.handleCancellcargue;
  const elevvaT = props.elevvaT;

  const [inputs, setInputs] = useState({
    answer_1: false,
    answer_3: false,
  });

  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };

  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs((val) => ({ ...val, [name]: value }));
  };
  const selectCircleText = (refContainer, index, classess, arr, onlyone) => {
    if (onlyone === true) {
      for (const iterator of arr) {
        iterator[1].current.classList = classess;
        iterator[1].current.active = false;
        iterator[2] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
  };

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className={"modalInfoContenido col-2 nopadding"}
      >




        <div
          className="position-relative mx-auto"
        >
          {error === "none" || error === "true" ? (
            <div
              className=""
              style={{
                position: "absolute",
                top: "6%",
                right: "5%",
                fontWeight: "bold",
                fontSize: "1.0rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={handleCancel}
            >
              <i class="bi bi-x-circle"></i>
            </div>
          ) : (
            <div
              className=""
              style={{
                position: "absolute",
                top: "5%",
                right: "5%",
                fontWeight: "bold",
                fontSize: "2.5vmin",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
            >
              <i class="bi bi-x-circle"></i>
            </div>
          )}
          <img src={imgDiv} style={
            {
              width: "100%",
              height: "'auto',",
            }
          } />
        </div>


        <div className="d-flex my-auto col-12 justify-content-center" >
          <div style={{ padding: "4% 3% 0% 4%", lineHeight: "220%" }}>
            {" "}
            <div
              style={{
                color: "#394F65",
                fontSize: "4vmin",
              }}
            >
              <span
                style={{
                  fontWeight: "300",
                  color: "#394F65",
                  fontSize: "4vmin",
                }}
              >
                Hemos recibido{" "}
              </span>
              <br />
              <strong>tu solicitud</strong>

            </div>
            <br />
            <div>
              <p
                style={{
                  color: "#A9B6C4",
                  fontWeight: "bold",
                  fontSize: "1.5vmin",
                  textAlign: "center",
                  lineHeight: 1
                }}
              >
                {/* {elevvaT("texto_profe")} */}
                Próximamente estaremos confirmando que Sofía García ha aceptado tu clase.
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex my-auto col-12 justify-content-center" >

        <div
          className="position-relative mx-auto my-3"
        >
          {error === "none" || error === "true" ? (
            <div
              className=""
              onClick={handleCancel}
            >
              <ButtonMob
                className="marginButtonReserva"
                text="Cerrar"
              />
            </div>
          ) : (
            <div
              className=""
              onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
            >
              <ButtonMob
                className="marginButtonReserva"
                text="Cerrar"
              />            </div>
          )}
        </div>
        </div>      
        </div>
    </div>
  );
});
