import {
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  instance,
  NuevaAxiosURL,
  ButtonMob,
  laptop575,
  allAvatars,
} from "../../utils/utils";
import React, { useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from "swiper";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import iconoFlechaElevva from "../../assets/newIcons/ICO-FLECHA-ELEVVA.svg"
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg";
import {ReactComponent as IcoArrowRight } from "../../assets/newIcons/ICO-ARROW-RIGTH.svg";
import {ReactComponent as IcoPerfilCuenta } from "../../assets/newIcons/ICO-PERFIL-CUENTA.svg";
import {ReactComponent as IcoPerfilObjetivos } from "../../assets/newIcons/ICO-PERFIL-OBJETIVOS.svg";
import {ReactComponent as IcoPerfilLesiones } from "../../assets/newIcons/ICO-PERFIL-LESIONES.svg";
import {ReactComponent as IcoPerfilTrain } from "../../assets/newIcons/ICO-PERFIL-PLAN-TRAIN.svg";
import {ReactComponent as IcoPerfilCompras } from "../../assets/newIcons/ICO-PERFIL-COMPRAS.svg";
import { ReactComponent as Diabetes } from "../../assets/newIcons/OBJ-DIABETES.svg";
import { ReactComponent as Estado } from "../../assets/newIcons/OBJ-ESTADO.svg";
import { ReactComponent as Peso } from "../../assets/newIcons/OBJ-PESO.svg";
import { ReactComponent as Tension } from "../../assets/newIcons/OBJ-TENSION.svg";
import { ReactComponent as Nutricion } from "../../assets/newIcons/OBJ-NUTRICION.svg";
//spinner
import { ReactComponent as ValoraIco } from "../../assets/newIcons/ICO-PLAN-VALORACION.svg";
import { ReactComponent as TrainIcon } from "../../assets/newIcons/ICO-PLAN-ENTRENAMIENTO.svg";
import { ReactComponent as CardioIcon } from "../../assets/newIcons/ICO-PLAN-CARDIO.svg";

import { ReactComponent as VideoIcon } from "../../assets/newIcons/ICO-PLAN-VIDEO.svg";
import { ReactComponent as NutriIcon } from "../../assets/newIcons/ICO-PLAN-NUTRICION.svg";

import { ReactComponent as IconCaminar } from "../../assets/newIcons/ICO-CAMINAR.svg";
import { ReactComponent as IconClock } from "../../assets/newIcons/ICO-CLOCK.svg";
import { ReactComponent as IconIntensity } from "../../assets/newIcons/ICO-INTENSITY.svg";
import { ReactComponent as IconCaminata } from "../../assets/newIcons/ICO-CAMINATA.svg";
// import elevva from "../../assets/img/general/logo"
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
// Buton for class grups
import Moment from "moment";
import { extendMoment } from "moment-range";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { iconReview, iconReviewColor, mestraining, MiniPerfil, ObjetivosCustomer, ObjetivosPanel, ResumenCompras } from "./helpPerfil";
import { ButtonTutorial, ButtonTutorialReserva, NivelCustomer, SwiperPlan } from "../Dashboard/helpResponsive";
import { Lesiones } from "../Lesiones/lesiones";
import { ProgressBar } from "react-bootstrap";
import MiCuenta from "./MiCuenta";
import { constants } from "crypto-browserify";
import MisObjetivos from "./MisObjetivos";
import MisLesiones from "./LesionesMobile";
import PlanTraining from "./Plan";
import Transacciones from "./TransaccionesMobile";
import { whatMonth } from "../TutorialReserva/stepsHelp";
import axios from "axios";


const Perfil = (props) => {
  const stepsMobile = [ {
      target: "#capsulesItems",
      placement: "top",
      disableBeacon: true,
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
         Puedes acceder a más información más concreta
sobre ti en el panel de tu perfil.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip" >Finalizar tutorial</span>,
        skip: (
          <span  
            aria-label="skip" 
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#Micuenta",
      placement: "bottom",
      
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
        Acá podrás verificar tus datos básicos de usuario,
así como cambiar tu contraseña y ver / modificar
tu plan de entrenamiento.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span onClick={()=>window.location.reload()}
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#Micuenta",
      placement: "bottom",
      
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
       Para acceder y/o modificar tu plan de entrenamiento, presiona la opción ver tu plan.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span onClick={()=>window.location.reload()}
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#miplanPopUp",
      placement: "bottom",
      
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
       En este panel podrás verificar el estado y condiciones actuales de tu plan si dispones de alguno o modificarlo.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span onClick={()=>window.location.reload()}
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#ObjetivosSemanales",
      placement: "bottom",
      
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
      En la sección objetivos generales, podrás revisar tus guías y objetivos basados en tu plan de entrenamiento
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span onClick={()=>window.location.reload()}
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#bodyPanelPerfilMobile",
      placement: "top",
      
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
   En mis lesiones podrás indiciar si presentas alguna lesión y describirla; eso nos ayudará a personalizar tu entrenamiento.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span onClick={()=>window.location.reload()}
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#PlanJoyce",
      placement: "top",
      
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
   En plan de entrenamiento  podrás verificar tu programa semana a semana, hecho a tu medida.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span onClick={()=>window.location.reload()}
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#comprasPerfil",
      placement: "top",
      
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
   Podrás revisar tu histórico de transacciones realizadas en la plataforma como planes adquiridos, créditos comprados o bonos redimidos.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip" onClick={()=>window.location.reload()}>Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"  onClick={()=>window.location.reload()}
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip" onClick={()=>navigate(`/perfil`)} >Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
  ]

  const steps = [
   

   
  

    {
      target: "#miniPerfil",
      placement: "right",
      disableBeacon: true,
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
          Acá podrás verificar tus datos básicos de usuario,
así como cambiar tu contraseña y ver / modificar
tu plan de entrenamiento.
        </div>
      ),

      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#bodyPanelPerfil",
      placement: "right",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
         En esta sección podrás indiciar si presentas alguna lesión y describirla; eso nos ayudará a personalizar tu entrenamiento.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#objetivosPanel",
      placement: "bottom",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
        Podrás revisar tus objetivos generales basados en tu plan de entrenamiento, así como el plan de cardio asignado para ti y la siguiente actividad del mismo.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#planTraining",
      placement: "top",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
        Acá podrás verificar tu programa de entrenamiento semana a semana, hecho a tu medida.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#resumenCompras",
      placement: "left",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "400",lineHeight:"110%",fontSize:"1rem" }}>
        Podrás revisar tu histórico de transacciones realizadas en la plataforma como planes adquiridos, créditos comprados o bonos redimidos.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },

 
  ];

  const arrayPrograma =[{
    semana:1,
    contenido:[{name:"Valoración física",icon:<ValoraIco width={"100%"} height={"100%"}/>},
    {name:"Valoración nutrición",icon:<NutriIcon width={"100%"} height={"100%"}/>},
    {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>},
    {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}
  ]
  },{
    semana:2,
    contenido:[{name:"Pilates",icon:<VideoIcon width={"100%"} height={"100%"}/>},
    {name:"Hipertensión",icon:<TrainIcon width={"100%"} height={"100%"}/>},
    
    {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}]
  },
  {
    semana:3,
    contenido:[ {name:"Hipertensión",icon:<TrainIcon width={"100%"} height={"100%"}/>},
    {name:"Pilates",icon:<VideoIcon width={"100%"} height={"100%"}/>},
   {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}]
  },
   
   {
    semana:4,
    contenido:[    {name:"Hipertensión",icon:<TrainIcon width={"100%"} height={"100%"}/>},
    {name:"Pilates",icon:<VideoIcon width={"100%"} height={"100%"}/>},
    {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}]
  }
];


const perilMobileIcons=[
  {name:"Mi cuenta",icon : <IcoPerfilCuenta width={'100%'} height={"100%"}/>},
  {name:"Mis Objetivos",icon : <IcoPerfilObjetivos width={'100%'} height={"100%"}/>},
  {name:"Mis lesiones",icon : <IcoPerfilLesiones width={'100%'} height={"100%"} />},
  {name:"Programa de entrenamiento",icon : <IcoPerfilTrain width={'100%'} height={"100%"} />},
  {name:"Resumen de compras",icon : <IcoPerfilCompras width={'100%'} height={"100%"}/>}

]

 const elevvaT = props.elevvaT;
  const objetivesCustomer=[
    {name:"Perder peso",icon:<Peso/>,code:10},
  
    {name:"Mejorar estado físico",icon:<Estado/>,code:30},
    {name:"Mejorar Nivel",icon:<Nutricion/>,code:60}
  ]
  const objetivesOldCustomer=[
    
    {name:"Mejorar tensión",icon:<Tension/>,code:10},
    {name:"Controlar diabetes",icon:<Diabetes/>,code:20},
    {name:"Mejorar estado físico",icon:<Estado/>,code:30},
    
  ]
  const [userData, setUserData] = useState([]);
  

  const [clasemodalAvatars, setclasemodalAvatars] = useState("d-none");
  const frontalView = useRef(null);
  const backView = useRef(null);
  const modalesDolores = useRef(null);
  const modalesDoloresBack = useRef(null);
  const [stepIndex, setstepIndex] = useState(0);
  const [stepIndexFast, setstepIndexFast] = useState(0);
  const [correr, setcorrer] = useState(false);
  const [mesPrograma, setmesPrograma] = useState([]);
  const [BlockTrainingPlan, setBlockTrainingPlan] = useState(false);
  const [heightjoyride, setheightjoyride] = useState(false);
  const [buttonChange, setbuttonChange] = useState("Ver vista Posterior");
  const [modalInfoCardio, setModalInfoCardio] = useState(null);
  const [photoProfile, setPhotoProfile] = useState(null);
  const [modificarLesiones, setModificarLesiones] = useState(false);
  const [arterial_prom, setarterial_prom] = useState("--- dias");
  const [cardiaca_prom, setcardiaca_prom] = useState("---");
  const [calorias_prom, setcalorias_prom] = useState("---");
  const [ProgressLevel, setProgressLevel] = useState(0);
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState(null);
  const [dataTransaccions, setdataTransaccions] = useState(null);
  const [infoLesiones, setInfoLesiones] = useState(null);
  const [Year, setYear] = useState("");
  const [alllevels, setalllevels] = useState(null);
  const [objetives_user, setobjetives_user] = useState(null);
  const [previewPhotoProfile, setPreviewPhotoProfile] = useState(null);
  const location = useLocation();
  const añopresent = Moment().format("YYYY");
  const anioCreditCard = [
    añopresent,
    añopresent - 1,
    añopresent - 2,
    añopresent - 3,
  ];
  let promedios;
  // promedios
  console.log(previewPhotoProfile);
  function plural(valor) {
    if (valor == 1) {
      return "día";
    } else return "días";
  }

  useEffect(() => {
    let dias =
      objetives_user?.days_activity == 0
        ? "--- días"
        : objetives_user?.days_activity +
          " " +
          plural(objetives_user?.days_activity);
    setarterial_prom(dias);
    setcardiaca_prom(
      objetives_user?.distance == 0 ? "---" : objetives_user?.distance
    );
    setcalorias_prom(objetives_user?.kcal == 0 ? "---" : objetives_user?.kcal);
  }, [objetives_user]);
  useEffect(() => {
    instance
      .get("users/current/")
      .then((response) => {
        setUserData(response.data);
        instance
          .get(`memberships/training_plan`)
          .then((response) => {
            let dataRes = response.data;
            console.log(dataRes);
            if (
              dataRes.personal_training_plan &&
              JSON.parse(dataRes.personal_training_plan) > 0
            ) {
              setmesPrograma(JSON.parse(dataRes.personal_training_plan));
            } else {
              setmesPrograma(dataRes.training_plan);
            }
          })
          .catch((error) => {
            if (error.response.status === 403) {
              setBlockTrainingPlan(true);
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
    instance
      .get("customers/metrics/weekly/")
      .then((res) => {
        if (res.data) {
          // console.log("............................", res.data);
          setobjetives_user(res.data.current);
        }
      })
      .catch((err) => {
        if (err.response) {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Algo salio mal"
              text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
              classesMsg=""
            />
          );
        }
      });

    instance
      .get(`${NuevaAxiosURL}customers/transactions/`)
      .then((resEn) => {
        setdataTransaccions(resEn.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setBlockTransactions(true);
        } else {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Algo salio mal"
              text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
              classesMsg=""
            />
          );
        }
      });
    const levels = [];
    const getData = async () => {
      await instance.get(`customers/level/`).then(async (res) => {
        const daysselected = res.data;

        if (daysselected) {
          for (const i of daysselected) {
            const identrenamiento = i.training;
            const lev = i.level;
            const uuid = i.id;
            const sublev = i.sublevel;
            const current_sesio = i.current_session;
            await instance.get(`trainings/${identrenamiento}`).then((res) => {
              let info = res.data;
              if (info && info.group == false) {
                levels[identrenamiento] = {
                  id: identrenamiento,
                  uuid: uuid,
                  tiene_valoration: info.is_valoration,
                  titulo: info.name.toLowerCase(),
                  descripcion: info.description,
                  level: lev,
                  subnivel: sublev,
                  current_session: current_sesio - 1,
                };
              }
            });
          }
        }
      });

      setalllevels(levels.filter((e) => e && e.tiene_valoration == false));
    };
    getData();
  }, [location.pathname]);

  useEffect(() => {
    const totalallevel = alllevels ? alllevels.length : 0;
    const unidadtotal = alllevels
      ? alllevels.map((e) => e.current_session).reduce((a, b) => a + b, 0)
      : 0;

    const now =
      totalallevel == 0
        ? 0
        : Math.round(((unidadtotal / totalallevel) * 100) / 120);
    setProgressLevel(now);
  }, [alllevels]);

  const changeView = (ref) => {
    if (ref == backView) {
      backView.current.style.display = "block";
      frontalView.current.style.display = "none";
      setbuttonChange("Ver vista frontal");
    } else {
      backView.current.style.display = "none";
      frontalView.current.style.display = "block";
      setbuttonChange("Ver vista Posterior");
    }
  };
  function injuriesConsult() {
    instance
      .get(`customers/injuries/?customer=${localStorage.id}`)
      .then((response) => {
        const info = response.data;

        if (info != null) {
          setInfoLesiones(info);
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        }
      });
  }
  useEffect(() => {
    injuriesConsult();
  }, []);

  useEffect(() => {
    const navSelect = document.getElementById("navMobile");
    if (stepIndex > 0) {
      if (navSelect) {
        navSelect.style.position = "absolute";
        navSelect.style = "z-index:0";
      }
    } else {
      if (navSelect) {
        navSelect.style.position = "fixed";
        navSelect.style = "z-index:1030";
      }
    }
  }, [stepIndex]);

  function referPlan() {
    if (localStorage.membership) {
      instance
        .get("memberships/summary/")
        .then(async (res) => {
          console.log("resssssssssssssssssss", res.data);
          setModalInfo(
            <ModalInfoPlan
              handleCancel={hideModal}
              idMembership={localStorage.membership}
              infoData={res.data}
            />
          );
        })
        .catch((err) => console.log(err));
    } else {
      setModalInfo(
        <ModalInfo
          error="false"
          handleClick={hideModal}
          textButtonConfirmar={"Entendido"}
          classcorrecta={"recuerdaTitle"}
          handleCancellcargue={hideModal}
          title="Lo sentimos"
          text={`Aún no tienes una mebresía activa`}
          classesMsg="colorText_Modal"
        />
      );
    }
  }
  const moment = extendMoment(Moment);

  require("moment-timezone");
  const handleJoyrideCallback = (data) => {
    const { status, type, action, index } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      let timeDelay = (index >= 2 && index < 4) || index > 5 ? 800 : 0;
      setstepIndexFast(index + (action === ACTIONS.PREV ? -1 : 1));
      // Update state to advance the tour
      setTimeout(() => {
        setstepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }, timeDelay);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setcorrer(false);
      setstepIndex(0);
    }
  };
  function searchNumbers(str) {
    return str.match(/\d+/g);
  }

  const navigate = useNavigate();

  const hideModal = () => {
    setModalInfo(null);
  };

  const [confirm, setModalConfirm] = useState(null);
  // const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_two`]);
  // avisoa
  const [modalInfo, setModalInfo] = useState(null);
  const [BlockTransactions, setBlockTransactions] = useState(false);

  const TrainingEstadistics = [
    [
      "Objetivo de entrenamiento",
      process.env.PUBLIC_URL + "/assets/img/objTraining.svg",
      "var(--objEntrenamiento)",
      "a la semana",
      arterial_prom,
      "var(--colorObjTraining)",
    ],
    [
      "Objetivo de <br/> pasos recorridos",
      process.env.PUBLIC_URL + "/assets/img/distance3D.svg",
      "var(--colorsesionesKm)",
      "Pasos a la semana",
      cardiaca_prom,
      "var(--elevvabutonLogin_User)",
    ],
    [
      "Objetivo de <br/> quema calórica",
      process.env.PUBLIC_URL + "/assets/img/cal3D.svg",
      "var(--colorsesionesCal)",
      "Kcal a la semana",
      calorias_prom,
      "var(--textCal)",
    ],
  ];

  useEffect(() => {
    if (displayCorrect) handleAtualizarcontraseña();
  }, [displayCorrect]);
  useEffect(() => {
    if (previewPhotoProfile) showPopCamera();
  }, [previewPhotoProfile]);
  useEffect(() => {
    if (Year) mostrarTransactions();
  }, [Year]);
  function Change_contraseña(event) {
    let change1 = event.target[0].value;
    let change2 = event.target[1].value;

    var formData = new FormData();
    formData.append("password", change1.trim()); //pass1
    formData.append("password2", change2.trim()); //pass1

    instance
      .post(`users/auth/change-password/`, formData)
      .then((res) => {
        if (res.data) {
          setModalConfirm(null);
          setModalInfo(
            <ModalInfo
              error="none"
              textButtonAction="Entendido"
              handleCancellcargue={hideModal}
              handleReservar={hideModal}
              iconoWidth={"2rem"}
              textSizeUp={"1.2rem"}
              icono={icoPositivo}
              text={`<br/>Tu contraseña ha <br/> sido modificada <br/> correctamente.<br/><br/>`}
              classesMsg="mb-2"
            />
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          console.log(msg_error);
          seterroraGregado(msg_error);

          setDisplayCorrect("inherit");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });

    event.preventDefault();
  }

  function handleAtualizarcontraseña() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalConfirm(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalConfirm(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className="title mb-3 py-1"
            style={{
              lineHeight: "100%",
              textAlign: "left",
              fontSize: "1.5rem",
            }}
          >
            <strong>Ingresa tu</strong> <br /> contraseña
          </div>
          <form
            onSubmit={(e) => {
              Change_contraseña(e);
            }}
          >
            {" "}
            <input
              className="form-control input-style-elevva "
              style={
                laptop575.matches
                  ? {
                      minWidth: "calc(20rem + 1vmin)",
                      fontSize: "1rem",
                    }
                  : { width: "70%" }
              }
              name="tester"
              placeholder="Contraseña"
              required
            />
            <br />
            <input
              className="form-control input-style-elevva "
              style={
                laptop575.matches
                  ? {
                      minWidth: "calc(20rem + 1vmin)",
                      fontSize: "1rem",
                    }
                  : { marginBottom: "0vh", width: "70%" }
              }
              name="tester"
              placeholder="Repite tu contraseña"
              required
            />
            <div
              id="passmail_error2"
              className=""
              style={
                laptop575.matches
                  ? {
                      display: `${displayCorrect}`,
                      color: "var(--elevva)",
                      fontSize: "0.8rem",
                      margin: "0 4rem",
                    }
                  : {
                      display: `${displayCorrect}`,
                      color: "var(--elevva)",
                      fontSize: "0.6rem",
                      margin: "0 2rem",
                      maxHeight: "2rem",
                    }
              }
            >
              {ErroraGregado ? ErroraGregado.password : null}
            </div>
            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"#9EACED"}
                  text="Actualizar"
                  className={
                    laptop575.matches
                      ? "mb-3 mt-3 letraspopfi2actualizar"
                      : " letraspopfi2actualizar py-2"
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  function mostrarTransactions() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    if (BlockTransactions) {
      avisoBloqueo();
    } else {
      setModalInfo(
        <div
          className="modalInfo justify-content-center align-items-center"
          style={styleModal}
        >
          <div className="modalInfoContenido">
            <div
              className=""
              style={{
                position: "absolute",
                top: "5%",
                right: "5%",
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={() => setModalInfo(null)}
            >
              <i className="bi bi-x-circle"></i>
            </div>
            <div
              className="d-flex justify-content-around"
              style={{ minWidth: "calc(20rem + 2vmin)" }}
            >
              <div
                className="title mb-3 py-1 col-7 "
                style={{
                  lineHeight: "100%",
                  textAlign: "left",
                  fontSize: "1.5rem",
                }}
              >
                Todas las transacciones
              </div>
              <div className=" mx-0 tipopersonP col-4 mx-auto">
                <select
                  onChange={(event) => {
                    setYear(event.target.value);
                  }}
                  name="Year"
                  style={{
                    fontSize: "1rem",
                    padding: " 1vh 1.5vw",
                    borderRadius: "20px",
                  }}
                  className="form-select   mt-4"
                >
                  <option style={{ color: "#9DACBD" }} value="">
                    Año
                  </option>
                  {anioCreditCard.map((aniocreditcard) => (
                    <option
                      key={aniocreditcard}
                      style={{ color: "#9DACBD" }}
                      value={aniocreditcard}
                    >
                      {aniocreditcard}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <PerfectScrollbar
              className={
                dataTransaccions &&
                dataTransaccions.filter(
                  (e) =>
                    moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") ==
                    Year
                ).length > 0
                  ? "d-flex flex-column "
                  : "d-flex flex-column justify-content-center"
              }
              style={{ height: "calc(10rem + 3vmin)" }}
            >
              {dataTransaccions &&
              dataTransaccions.filter(
                (e) =>
                  moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") ==
                  Year
              ).length > 0 ? (
                dataTransaccions
                  .filter(
                    (e) =>
                      moment(e.date.slice(0, 10), "YYYY-MM-DD").format(
                        "YYYY"
                      ) == Year
                  )
                  .map((trans) => (
                    <div className="containerCompras ">
                      <div className="itemCompras d-flex flex-column">
                        <span className="letraPequeña fw-bold">Clase</span>
                        <span className="letraPequeña fw-light">
                          {searchNumbers(trans?.description)}
                        </span>
                      </div>
                      <div className="itemCompras d-flex flex-column">
                        <span className="letraPequeña fw-bold">Fecha</span>
                        <span className="letraPequeña fw-light">
                          {Moment(trans?.date).format("YYYY-MM-DD")}
                        </span>
                      </div>
                      <div className="itemCompras d-flex flex-column">
                        <span className="letraPequeña fw-bold">Hora</span>
                        <span className="letraPequeña fw-light">
                          {Moment(trans?.date).format("LT a")}
                        </span>
                      </div>
                      <div className="itemCompras d-flex flex-column">
                        <span className="letraPequeña fw-bold">Valor</span>
                        <span className="letraPequeña fw-light">
                          {" "}
                          {Number(trans?.amount) == 1
                            ? Number(trans?.amount) + " crédito"
                            : Number(trans?.amount) < 500
                            ? Number(trans?.amount) + " créditos"
                            : trans?.amount + " " + trans?.currency}{" "}
                        </span>
                      </div>
                      <div className="itemCompras d-flex flex-column">
                        <span className="letraPequeña fw-bold">Estado</span>
                        <span className="letraPequeña fw-light">
                          {" "}
                          {trans?.status == 4
                            ? "Aprobada"
                            : trans?.status == 6
                            ? "Declinada"
                            : trans?.status == 7
                            ? "Fondos insuficientes"
                            : "Sin procesar"}
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))
              ) : (
                <div className="d-flex flex-column justify-content-center">
                  <div className="title mx-auto">
                    {Year == ""
                      ? "Selecciona el periodo que deseas ver."
                      : "No hay transacciones disponibles para el año seleccionado."}
                  </div>
                </div>
              )}
            </PerfectScrollbar>

            <div className="col-12 btnAction_pruebas letraspopfi2">
              <ButtonMob
                handleClick={() => setModalInfo(null)}
                colorButton={"#9EACED"}
                text="Aceptar"
                className={
                  laptop575.matches
                    ? "mb-3 mt-3 letraspopfi2actualizar"
                    : " letraspopfi2actualizar py-2"
                }
              />
            </div>
          </div>
        </div>
      );
    }
  }
  function avisoBloqueo() {
    return setModalInfo(
      <ModalInfo
        error="false"
        handleClick={hideModal}
        textButtonConfirmar={"Mejorar mi plan"}
        classcorrecta={"recuerdaTitleBlock"}
        handleCancellcargue={hideModal}
        title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
        text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
        classesMsg="colorText_Modal"
      />
    );
  }
  function programaTraining() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    if (BlockTrainingPlan) {
      avisoBloqueo()
    } else {
      setModalInfo(
        <div
          className="modalInfo justify-content-center align-items-center"
          style={styleModal}
        >
          <div
            className="modalInfoContenido"
            style={{ width: "calc(70rem + 2vmin)", maxWidth: "100%" }}
          >
            <div
              className=""
              style={{
                position: "absolute",
                top: "5%",
                right: "5%",
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={() => setModalInfo(null)}
            >
              <i className="bi bi-x-circle"></i>
            </div>

            <div
              className="title mb-3 py-1 col-7 fw-light"
              style={{
                lineHeight: "100%",
                textAlign: "left",
                fontSize: "1.3rem",
              }}
            >
              <strong>Programa</strong> de entrenamiento
            </div>
            <div className="col-12 d-flex">
              <SwiperPlan
                userData={userData}
                mesPrograma={mesPrograma}
                CardioTraining={(e) => CardioTraining(e)}
                anchoGrid={"70%"}
                widthBox={"auto"}
                minWidthBox={"calc(5rem + 0.5vmin)"}
              />

              <div
                className="me-auto d-flex"
                style={{
                  padding: "0 3%",
                  width: "50%",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {/* ini */}
                {mestraining.map((day) => (
                  <div
                    className="d-flex my-3"
                    style={{ alignItems: "center", width: "48%" }}
                  >
                    <div
                      className="col-3 d-flex flex-column m-auto justify-content-center"
                      style={{
                        background: day.color,
                        borderRadius: "100%",
                        width: "40px",
                        height: "40px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="d-flex flex-column m-auto justify-content-center"
                        style={{ width: "25px", height: "25px" }}
                      >
                        {day.icon}
                      </div>
                    </div>
                    <div
                      className="mx-1 w-100"
                      style={{
                        fontSize: "calc(0.6rem + 0.5vmin)",
                        textAlign: "left",
                        lineHeight: "100%",
                      }}
                    >
                      {day.name}
                    </div>
                  </div>
                ))}
                {/* out */}
              </div>
            </div>

            <div
              className="btnAction_pruebas letraspopfi2"
              style={{ width: "70%" }}
            >
              <ButtonMob
                handleClick={() => setModalInfo(null)}
                colorButton={"#9EACED"}
                text="Aceptar"
                className={
                  laptop575.matches
                    ? "mb-3 mt-3 letraspopfi2actualizar"
                    : " letraspopfi2actualizar py-2"
                }
              />
            </div>
          </div>
        </div>
      );
    }
  }

  function CardioTraining(elemento) {
    let e = elemento[0];
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 9999,
      whiteSpace: "pre-line",
    };

    setModalInfoCardio(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido" style={{ maxWidth: "100%" }}>
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfoCardio(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div
            className="title col-12 mb-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            Programa de Cardio / Mes {whatMonth(elemento[1])}
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              Día plan entrenamiento: {elemento[1]}
            </button>
            <button
              className="mx-auto d-flex relative"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.label.replace("Cardio", "")}
            </button>
          </div>
          <div
            className="d-flex m-auto  "
            style={{
              background: "#F4F0ED",
              borderRadius: "40px",
              padding: "5% 2%",
              minWidth: "20rem",
            }}
          >
            <div className="m-auto">
              <div
                className="col-3 d-flex flex-column m-auto justify-content-center"
                style={{
                  background: "var(--elevva)",
                  borderRadius: "100%",
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                }}
              >
                <div
                  className="d-flex flex-column m-auto justify-content-center"
                  style={{ width: "25px", height: "25px" }}
                >
                  <IconCaminar />
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-column m-auto "
              style={{ textAlign: "left" }}
            >
              <div className="fw-bold">{e.detail.type}</div>
              <div className="m-auto d-flex " style={{ alignItems: "center" }}>
                <IconClock />
                <span className="ms-1"> {e.detail.duration} min</span>
              </div>
            </div>
            <div
              className="d-flex flex-column m-auto"
              style={{ textAlign: "left" }}
            >
              <div className="my-auto d-flex " style={{ alignItems: "center" }}>
                <IconIntensity />
                <span className="ms-1"> Intensidad {e.detail.intensity}</span>
              </div>
              <div
                className="my-auto mx-1 d-flex "
                style={{ alignItems: "center" }}
              >
                <IconCaminata />
                <span className="ms-1">{e.detail.type}</span>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Metodología
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.method}
            </button>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Objetivo
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.target}
            </button>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Zona de frecuencia cardiaca
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.hr_zone}
            </button>
          </div>
          <div
            className="title col-12 my-2"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            Observaciones
          </div>

          <PerfectScrollbar
            className="title col-12 my-2 mx-auto"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              maxWidth: "20rem",
              height: "5rem",
            }}
          >
            <span style={{ fontSize: "0.8rem", color: "#9DACBD" }}>
              {e.detail.notes}{" "}
            </span>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const BannerAvatars = (props) => {
    const avatarPersons = props.avatarPersons;
    const render_avatarPersons = avatarPersons.map((imagen, index) => {
      return (
        <SwiperSlide key={index} className="w-100 my-3 mx-auto p-3">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3,1fr)",
              justifySelf: " center",
              justifyItems: "center",
              gap: "1%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {imagen.map((avatar, index) => (
              <button
                key={index}
                className="buttonIcon_Avatar"
                onClick={() => {
                  axios.get(avatar).then((res) => {
                    getBase64FromUrl(`${avatar}`).then((avata) => {
                      var blob = dataURItoBlob(avata);
                      let imagen_firma = new File([blob], "photoAvatar.png", {
                        type: "image/png",
                      });
                      setPreviewPhotoProfile(URL.createObjectURL(imagen_firma));
                      setPhotoProfile(imagen_firma);

                      setclasemodalAvatars("d-none");
                    });
                  });
                }}
              >
                <img className="iconavatar" src={avatar} />
              </button>
            ))}
          </div>
        </SwiperSlide>
      );
    });

    return (
      <>
        <Swiper
          style={{ maxWidth: "60vmin" }}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            el: ".bulletsPublicidad",
            type: "custom",
            clickable: true,
            renderCustom: function (swip, current, total) {
              current -= 1;
              return avatarPersons
                .map((value, index) => {
                  return `<div class=' swiper-pagination-bullet pointerinAvatar mx-2 ${
                    current == index ? "active" : ""
                  }'></div>`;
                })
                .join("");
            },
          }}
        >
          {render_avatarPersons}
        </Swiper>

        <div className="bulletsPublicidad"></div>
      </>
    );
  };

  function changeData() {
    setModificarLesiones(true);
    setTimeout(() => {
      setModificarLesiones(false);
    }, 1000);
  }
  const changePhoto = (event) => {
    const photo = event.target.files[0];
    // console.log("event.target.files[0]", event.target.files[0]);
    setPhotoProfile(photo);
    setPreviewPhotoProfile(URL.createObjectURL(photo));
  };
  function postChangePicture() {
    const formData1 = new FormData();
    formData1.append("profile_pic", photoProfile ? photoProfile : "");

    instance
      .patch(`${NuevaAxiosURL}customers/`, formData1)
      .then((response) => {
        const data = response.data;

        localStorage.profile_pic = data.profile_pic;
        setPhotoProfile(null);
        setModalInfo(null);
      })
      .catch((err) => {
        console.error(`Error en customers/`, err.response);
      });
  }
  const ModalInfoAvatares = React.forwardRef((props, ref) => {
    const display = props.display || "flex";
    const position = props.position || "fixed";
    const title = props.title;
    let styleModal = {
      display: display,
      position: position,
      whiteSpace: "pre-line",
    };
    return (
      <div
        ref={ref}
        className={`${props.classes} modalInfo justify-content-center align-items-center`}
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div
            className="position-absolute m-4"
            style={{
              color: "var(--elevvaTextSubtitle)",
              top: "0",
              right: "0",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => setclasemodalAvatars("d-none")}
          >
            <i className="bi bi-x-circle-fill"></i>
          </div>
          <div>
            <img
              className="logo-camera-responsive"
              src={process.env.PUBLIC_URL + "/assets/img/select_image.png"}
            />
          </div>
          <h4 className="text-color-title mt-2">{ReactHtmlParser(title)}</h4>

          <BannerAvatars avatarPersons={allAvatars} />
        </div>
      </div>
    );
  });
  function showPopCamera() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    // setPreviewPhotoProfile( userData?.customer?.profile_pic)
    setModalInfo(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfo(null) || setPhotoProfile(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ minWidth: "calc(20rem + 2vmin)" }}
          >
            <div
              className="title mb-3 py-1 col-12 d-flex"
              style={{
                lineHeight: "100%",
                textAlign: "left",
                fontSize: "1.5rem",
                position: "relative",
                justifyContent: "center",
              }}
            >
              {" "}
              <img
                src={previewPhotoProfile || localStorage.profile_pic}
                alt="Avatar"
                className="rounded-circle imageCustomerPerfil"
              />{" "}
              <input
                style={{ position: "absolute" }}
                type="file"
                accept="image/*"
                className="photoProfile"
                name="photo"
                id="photo"
                onChange={changePhoto}
              />
            </div>
          </div>

          {photoProfile ? (
            <ButtonMob
              handleClick={() => postChangePicture()}
              colorButton={"#9EACED"}
              text="Aceptar"
              className={
                laptop575.matches
                  ? "mb-3 mt-3 letraspopfi2actualizar"
                  : " letraspopfi2actualizar py-2"
              }
            />
          ) : (
            <div className="col-12 btnAction_pruebas letraspopfi2">
              <div className="position-relative mx-auto w-100 d-flex justify-content-center">
                <ButtonMob
                  text={"Seleccionar foto"}
                  className="btnAction  py-2 sizebuttonLarge"
                />{" "}
                <input
                  type="file"
                  name="profile_pic"
                  accept="image/*"
                  className="photoProfile"
                  id="profile_pic"
                  onChange={changePhoto}
                />
              </div>
              <br></br>
              <ButtonMob
                text={"Escoge tu avatar"}
                className="btnAction  py-2 sizebuttonLarge escoger_avatar_bottom"
                handleClickCamera={() => setclasemodalAvatars("")}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="col-12 col-md-10">
      <Joyride
        steps={window.innerWidth < 768 ? stepsMobile : steps}
        run={correr}
        hideCloseButton
        disableScrolling={heightjoyride}
        showSkipButton
        continuous
        stepIndex={stepIndex}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        styles={{
          spotlight: {
            // Set your border-radius here!
            borderRadius: 20,
          },
          options: {
            primaryColor: "#CB3D82",
          },
        }}
        callback={handleJoyrideCallback}
      />

      <PerfectScrollbar>
        {modalInfo} {modalInfoCardio}
        {modalesDolores && modalesDolores.current}
        {modalesDoloresBack.current} {confirm}
        <ModalInfoAvatares classes={clasemodalAvatars} title={`Avatares`} />
        {/* desktop */}
        <div
          className="position-relative d-none d-md-block"
          style={{
            background: "var(--backgroundOnboardingContain)",
            height: "100vh",

            padding: "0.5% 4% 1.3% 3%",
          }}
        >
          <div className="d-flex h-100">
            <div className="col-12">
              <div
                style={{
                  height: "100%",
                  padding: "1%",
                  flexDirection: "column",
                }}
              >
                <div
                  className=" d-flex"
                  style={{
                    height: "30%",
                    gap: "1%",
                  }}
                >
                  <MiniPerfil
                    showPopCamera={showPopCamera}
                    padreclass={"d-flex col-11 mx-auto"}
                    lastName={userData && userData.last_name}
                    firstName={userData && userData.first_name}
                    profilePic={
                      previewPhotoProfile ||
                      (userData && userData.customer?.profile_pic)
                    }
                    handlePlan={referPlan}
                    widthContainer={"30%"}
                    idJoyride={"miniPerfil"}
                    email={userData && userData?.email}
                    mobile_number={userData && userData?.mobile_number}
                    handlePassword={handleAtualizarcontraseña}
                  />
                  <ObjetivosPanel
                    TrainingEstadistics={TrainingEstadistics}
                    idPanel={"objetivosPanel"}
                  />
                  <ResumenCompras
                    stepIndex={stepIndexFast}
                    popUptransacciones={mostrarTransactions}
                    idPanel={"resumenCompras"}
                    dataTransaccions={dataTransaccions}
                  />
                </div>
                <div
                  className="d-flex"
                  style={{
                    height: "70%",

                    gap: "1%",
                    margin: "1% 0 0 0",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    id={`bodyPanelPerfil`}
                    className="position-relative"
                    style={{
                      background: "white",
                      width: "30%",
                      borderRadius: "20px",
                      flexDirection: "column",
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="letraPequeña my-0"
                      style={{
                        color: "var(--elevvaTextTitle)",
                      }}
                    >
                      Tus <strong>lesiones</strong>
                    </span>
                    <span className="letraPequeña-Perfil text-center">
                      Indícanos la zona del cuerpo y haz una breve descripción
                      de tu lesión.
                    </span>
                    <Lesiones
                      sendDataLes={modificarLesiones}
                      injuriesDelete={() => window.location.reload()}
                      modalesDolores={modalesDolores}
                      modalesDoloresBack={modalesDoloresBack}
                      elevvaT={elevvaT}
                      frontalView={frontalView}
                      backView={backView}
                      nameFigure={"figurePartsPerfilNew"}
                      classMargin={"m-0"}
                      updateLesion={changeData}
                      infoLesiones={infoLesiones}
                    />
                    <div className="m-0">
                      <ButtonMob
                        className="m-0 button-Body-perfil"
                        handleClick={() =>
                          buttonChange == "Ver vista frontal"
                            ? changeView(frontalView)
                            : changeView(backView)
                        }
                        text={buttonChange}
                      />
                    </div>
                  </div>
                  <ObjetivosCustomer 
                  mesPrograma={mesPrograma}
                    userData={userData}
                    joyRide={"planTraining"}
                    programaTraining={programaTraining}
                    arrayPrograma={arrayPrograma}
                    objetivesCustomer={objetivesCustomer}
                    objetivesOldCustomer={objetivesOldCustomer}
                    stepIndex={stepIndex}
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonTutorial handleClick={() => setcorrer(!correr)} />{" "}
        </div>
        {/* mobile */}
        <div
          className="w-100  position-relative d-md-none"
          style={{ height: "100vh" }}
        >
          {stepIndexFast == 0 ? (
            <div
              className="d-md-none mobile-login w-100 position-relative"
              style={{ height: "50%", alignItems: "center" }}
            >
              <div className="position-relative w-100">
                <div
                  className="containerBGClase"
                  style={{
                    backgroundSize: "cover",
                    height: "50vh",
                    backgroundImage: `url(${
                      userData && userData.customer?.profile_pic
                    })`,
                  }}
                ></div>
                <div
                  className="w-100 h-100"
                  style={{
                    top: "0",
                    position: "absolute",
                    color: "#ffff",
                    background: "rgba(0, 0, 0, 0.4)",

                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="position-absolute w-100 mx-auto "
                    style={{ top: "20%" }}
                  >
                    <div
                      className="d-flex px-4 col-12 flex-column mb-2"
                      style={{ lineHeight: "120%" }}
                    >
                      <span
                        className="fw-bold my-2 d-flex flex-column"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {/* localStorage.variable, se trata como objeto */}

                        {userData.last_name && userData.first_name ? (
                          <>
                            <span
                              style={{ fontSize: "1.5rem" }}
                            >{`${userData.first_name.toUpperCase()}`}</span>

                            <span
                              style={{ fontSize: "1.5rem" }}
                            >{`${userData.last_name.toUpperCase()}`}</span>
                          </>
                        ) : (
                          <>{`${localStorage.first_name.toUpperCase()} ${localStorage.last_name.toUpperCase()}`}</>
                        )}
                      </span>

                      <span style={{ fontWeight: "500" }}>
                        {userData?.customer?.mob_points || "000"}
                      </span>
                      <span style={{ fontWeight: "500", fontSize: "0.8rem" }}>
                        Puntos Elevva
                      </span>
                    </div>

                    <div className="d-flex px-4 col-12">
                      <div
                        className="d-flex"
                        style={{
                          padding: "0 3vmin",
                          justifyContent: "center",
                        }}
                      >
                        <div className="d-flex">
                          <img
                            style={{ width: "2rem" }}
                            alt="tierra"
                            src={
                              process.env.PUBLIC_URL + "/assets/img/tierra.svg"
                            }
                          />
                        </div>
                        <NivelCustomer
                          userData={userData}
                          colorClassText={`letraPequeñaWhite`}
                          perfilMobile={true}
                          padreclass={"d-flex col-12 my-auto  px-0"}
                          alllevels={alllevels}
                        />
                      </div>
                    </div>
                    <div className="d-flex px-4 col-12 my-2">
                      {" "}
                      <ProgressBar
                        now={ProgressLevel}
                        style={{
                          border: "1px solid var(--backgrounColorBar)",
                          borderRadius: "10px",
                          backgroundColor: "#F9F9F9",
                          width: "42vmin",
                          height: "0.6rem",
                          margin: "auto 0",
                        }}
                      />{" "}
                    </div>
                    <div className="position-relative"> </div>
                  </div>
                </div>
              </div>

              <div
                id="capsulesItems"
                className="mx-auto elevation-2 d-flex flex-column py-3"
                style={{
                  width: "90%",
                  height: "auto",
                  background: "white",
                  position: "absolute",
                  borderRadius: "15px",
                  zIndex: "99",
                  top: "35vh",
                }}
              >
                {perilMobileIcons &&
                  perilMobileIcons.slice(0, 2).map((item, index) => (
                    <div
                      style={{ padding: "0 6%" }}
                      onClick={() =>
                        item.name == "Mi cuenta"
                          ? navigate(`/Micuenta`)
                          : navigate(`/MisObjetivos`)
                      }
                    >
                      <div className="d-flex m-auto justify-content-between">
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            style={{
                              maxWidth: "1.5rem",
                              width: "100%",
                              height: "100%",
                              maxHeight: "1.5rem",
                            }}
                          >
                            {" "}
                            {item.icon}{" "}
                          </div>
                          <div className="ms-2" style={{ color: "#394F65" }}>
                            <span>{item.name}</span>{" "}
                          </div>
                        </div>

                        <div style={{ width: "1rem", height: "1rem" }}>
                          {" "}
                          <IcoArrowRight width={"100%"} height={"100%"} />
                        </div>
                      </div>
                      {index == 0 ? <hr /> : null}
                    </div>
                  ))}
              </div>
              <div
                className="mx-auto elevation-2 d-flex flex-column py-3"
                style={{
                  width: "90%",
                  height: "auto",
                  background: "white",
                  position: "absolute",
                  borderRadius: "15px",
                  zIndex: "99",
                  top: "53vh",
                }}
              >
                {perilMobileIcons &&
                  perilMobileIcons.slice(2).map((item, index) => (
                    <div
                      key={"iconmo" + index}
                      style={{ padding: "0 6%" }}
                      onClick={() =>{
                        if(item.name == "Resumen de compras"){
                          BlockTransactions?avisoBloqueo():   navigate(`/Transacciones`)
                        }else if(item.name == "Mis lesiones"){
                          navigate(`/MisLesiones`)
                        }else{
                          BlockTrainingPlan?avisoBloqueo(): navigate(`/Plan`)
                        }
                      }
                       
                      }
                    >
                      <div className="d-flex m-auto justify-content-between">
                        <div
                          className="d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            style={{
                              maxWidth: "1.5rem",
                              width: "100%",
                              height: "100%",
                              maxHeight: "1.5rem",
                            }}
                          >
                            {" "}
                            {item.icon}{" "}
                          </div>
                          <div className="ms-2" style={{ color: "#394F65" }}>
                            <span>{item.name}</span>{" "}
                          </div>
                        </div>

                        <div style={{ width: "1rem", height: "1rem" }}>
                          {" "}
                          <IcoArrowRight width={"100%"} height={"100%"} />
                        </div>
                      </div>
                      {index < 2 ? <hr /> : null}
                    </div>
                  ))}
              </div>
              <div
                className="mobile-welcome-perfil  text-left w-100 "
                style={{
                  top: "40vh",
                  justifyContent: "start",
                  height: "50%",
                  zIndex: "9",
                }}
              ></div>
              <ButtonTutorialReserva handleClick={() => setcorrer(!correr)} />
            </div>
          ) : stepIndexFast < 4 ? (
            <MiCuenta stepIndex={stepIndexFast} />
          ) : stepIndexFast == 4 ? (
            <MisObjetivos />
          ) : stepIndexFast == 5 ? (
            <MisLesiones />
          ) : stepIndexFast == 6 ? (
            <PlanTraining stepIndex={stepIndexFast}  />
          ) : (
            <Transacciones stepIndex={stepIndexFast} />
          )}
        </div>
      </PerfectScrollbar>
      {/* )} */}
    </div>
  );
};
export default Perfil;
