import { Fragment, useEffect, useState, useRef, createRef } from "react";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
// import html2canvas from "html2canvas";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ButtonMob, laptop575, laptop1600 } from "../../utils/utils.js";
import Moment from "moment";
import "./results.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-vis/dist/style.css";
import Chart from "./example.js";
import { PromediosPanel, PromediosPanelMobile } from "../Dashboard/helpResponsive.js";
import { Loading } from "../TutorialReserva/stepsHelp.js";
import shareIcon from "../../assets/newIcons/shareIcon.svg";
import ChartMobile from "./exampleMobile.js";

export function ModeloResults2(props) {
  const mobileOn = props.mobileOn || false;
  const borderBody = props.borderBody || "20px";
  const heightContainer = props.heightContainer;
   const maxl=props.maxl;
  const base = props.base;
  const maximo = props.maximo;
  const muy_suave = props.muy_suave;
  const luz = props.luz;
  const intenso = props.intenso;
  const moderado = props.moderado;
  const colorsPaint = props.colorsPaint;
  const [modalInfo, setModalInfo] = useState(null);
  const hours = props.hours;

  const colorsPaint1 =
    colorsPaint[0] && colorsPaint[0].length > 0 ? colorsPaint[0].length : 0;
  const colorsPaint2 =
    colorsPaint[1] && colorsPaint[1].length > 0 ? colorsPaint[1].length : 0;
  const colorsPaint3 =
    colorsPaint[2] && colorsPaint[2].length > 0 ? colorsPaint[2].length : 0;
  const colorsPaint4 =
    colorsPaint[3] && colorsPaint[3].length > 0 ? colorsPaint[3].length : 0;
  const colorsPaint5 =
    colorsPaint[4] && colorsPaint[4].length > 0 ? colorsPaint[4].length : 0;
  const totalPaint =
    colorsPaint1 + colorsPaint2 + colorsPaint3 + colorsPaint4 + colorsPaint5;

  return laptop575.matches ? (
    <>
      {" "}
      {hours ? (
        <div
          className={
            mobileOn
              ? "col-12  d-flex  flex-column h-100"
              : "col-12 d-flex justify-content-center flex-column h-100"
          }
          style={{
            background: "white",
            position: "relative",
            padding: " 0",
            height: heightContainer,
            borderRadius: borderBody,
            overflow: "hidden",
          }}
        >
          <div
            className="d-flex w-100 flex-column justify-content-center text-center py-2"
            style={{ height: "12%" }}
          >
            <span>Zona cardiaca sobre tiempo de entrenamiento</span>
          </div>
          <div
            className="col-12 d-flex justify-content-center gap-1"
            style={{
              background: "#FAF8F5",
              padding: "0 2% 2% 2%",
              height: "88%",
            }}
          >
            <div className="col-6 d-flex justify-content-around flex-column">
              <div
                className="d-flex w-100 flex-column justify-content-center text-center p-1"
                style={{ height: "auto" }}
              >
                <span
                  style={{ fontSize: "calc(0.5rem + 0.3vw)", color: "#A9B6C4" }}
                >
                  Variación de frecuencia cardiaca durante <br /> tu actividad
                  por zona cardiaca
                </span>
              </div>
              <div
                className="  roma_web d-flex justify-content-center flex-column"
                style={{
                  background: "#ffff",
                  padding: "0 5%",
                  borderRadius: "20px",
                  width: "100%",
                  height: "85%",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    style={{ width: "70px", fontSize: "calc(0.5rem + 0.3vw)" }}
                  >
                    Zona rpm
                  </div>
                  <div
                    style={{ width: "30px", fontSize: "calc(0.5rem + 0.3vw)" }}
                  >
                    %
                  </div>
                </div>
                <div style={{ width: "100%", height: "auto" }}>
                  <Chart
                    maxl={maxl}
                    dataServer={hours}
                    base={base}
                    maximo={maximo}
                    muy_suave={muy_suave}
                    luz={luz}
                    moderado={moderado}
                    intenso={intenso}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-around flex-column">
              <div
                className="d-flex w-100 flex-column justify-content-center text-center p-1"
                style={{ height: "auto" }}
              >
                <span
                  style={{ fontSize: "calc(0.5rem + 0.3vw)", color: "#A9B6C4" }}
                >
                  Tiempo de permanencia <br /> en cada zona cardiaca
                </span>
              </div>
              <div
                style={{
                  background: "#ffff",
                  padding: "0 5%",
                  borderRadius: "20px",
                  width: "100%",
                  height: "85%",
                }}
              >
                <div className="h-100 col-12 d-flex justify-content-center">
                  <div
                    className="all_bar d-flex flex-column justify-content-center"
                    style={{ width: "11%" }}
                  >
                    <div className="my-1 containerProgress_one">
                      {" "}
                      <ProgressBar
                        now={100}
                        label={`5`}
                        style={{
                          border: "1px solid #E16250",
                          borderRadius: "10px",

                          backgroundColor: "#E16250",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_two">
                      <ProgressBar
                        now={100}
                        label={`4`}
                        style={{
                          border: "1px solid #FFB6A4",
                          borderRadius: "10px",
                          backgroundColor: "#FFB6A4",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_three">
                      {" "}
                      <ProgressBar
                        now={100}
                        label={`3`}
                        style={{
                          border: "1px solid #F5D7CF",
                          borderRadius: "10px",
                          backgroundColor: "#F5D7CF",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_four">
                      {" "}
                      <ProgressBar
                        now={100}
                        label={`2`}
                        style={{
                          border: "1px solid #7988F6",
                          borderRadius: "10px",
                          backgroundColor: "#7988F6",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_five">
                      <ProgressBar
                        now={100}
                        label={`1`}
                        style={{
                          border: "1px solid #6EE1FB",
                          borderRadius: "10px",
                          backgroundColor: "#6EE1FB",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-8 px-2  d-flex flex-column justify-content-center text-center">
                    {" "}
                    <div className="my-1 containerProgress_one active_one_progress">
                      {" "}
                      <ProgressBar
                        now={
                          colorsPaint1 && typeof ((colorsPaint1 * 100) / totalPaint) == "number"
                            ? (colorsPaint1 * 100) / totalPaint
                            : 0
                        }
                        label={`5`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#ffff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_two active_two_progress">
                      <ProgressBar
                        now={
                          colorsPaint2 &&  typeof ((colorsPaint2 * 100) / totalPaint) == "number"
                            ? (colorsPaint2 * 100) / totalPaint
                            : 0
                        }
                        label={`4`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_three active_three_progress">
                      {" "}
                      <ProgressBar
                        now={
                          colorsPaint3 &&  typeof ((colorsPaint3 * 100) / totalPaint) == "number"
                            ? (colorsPaint3 * 100) / totalPaint
                            : 0
                        }
                        label={`3`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_four active_four_progress">
                      {" "}
                      <ProgressBar
                        now={
                          colorsPaint4 &&  typeof ((colorsPaint4 * 100) / totalPaint) == "number"
                            ? (colorsPaint4 * 100) / totalPaint
                            : 0
                        }
                        label={`2`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_five active_five_progress">
                      <ProgressBar
                        now={
                          colorsPaint5 && typeof ((colorsPaint5 * 100) / totalPaint) == "number"
                            ? (colorsPaint5 * 100) / totalPaint
                            : 0
                        }
                        label={`1`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-2 d-flex flex-column justify-content-center text-left">
                    {" "}
                    <div className=" containerProgress_one active_one_progress">
                      {colorsPaint[0] && colorsPaint[0].length > 0
                        ? moment
                            .utc(colorsPaint[0].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_two active_two_progress">
                      {colorsPaint[1] && colorsPaint[1].length > 0
                        ? moment
                            .utc(colorsPaint[1].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_three active_three_progress">
                      {colorsPaint[2] && colorsPaint[2].length > 0
                        ? moment
                            .utc(colorsPaint[2].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_four active_four_progress">
                      {colorsPaint[3] && colorsPaint[3].length > 0
                        ? moment
                            .utc(colorsPaint[3].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_five active_five_progress">
                      {colorsPaint[4] && colorsPaint[4].length > 0
                        ? moment
                            .utc(colorsPaint[4].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="position-absolute col-6 d-flex justify-content-center mx-auto" style={{bottom:"3%",left:"50%",transform:"translateX(-50%)"}}>
           <ButtonMob handleClick={shareImage} className="m-0 col-6 button-share me-1" text="Comparte tus resultados"/>
           <ButtonMob className=" my-xl-0 ms-1 col-6 px-xl-0  d-flex button-share" text="Guardar entrenamiento"/></div> */}
        </div>
      ) : (
        <div
          className={
            mobileOn
              ? "col-12  d-flex  flex-column h-100"
              : "col-12 d-flex justify-content-center flex-column h-100"
          }
          style={{
            background: "white",
            position: "relative",
            padding: " 0",
            height: heightContainer,
            borderRadius: borderBody,
            overflow: "hidden",
          }}
        >
          <Loading />
        </div>
      )}
      {modalInfo}
    </>
  ) : (
    <>
      {" "}
      {hours ? (
        <div
          className={
            mobileOn
              ? "col-12  d-flex  flex-column h-100"
              : "col-12 d-flex justify-content-center flex-column h-100"
          }
          style={{
            background: "white",
            position: "relative",
            padding: " 0",
            height: heightContainer,
            borderRadius: 0,
            overflow: "hidden",
          }}
        >
          <div
            className="d-flex w-100 flex-column justify-content-center text-center py-2"
            style={{ height: "12%" ,background:"#F9F9F9"}}
          >
            <span>Zona cardiaca sobre <br/>tiempo de entrenamiento</span>
          </div>
          <div
            className="col-12 d-flex justify-content-center gap-1 flex-column"
            style={{
              background: "#FAF8F5",
              padding: "0 2% 2% 2%",
              height: "100%",
            }}
          >
            <div className="col-12 d-flex justify-content-around flex-column">
              <div
                className="d-flex w-100 flex-column justify-content-center text-center p-1"
                style={{ height: "auto" }}
              >
                <span
                  style={{ fontSize: "calc(0.8rem + 0.3vw)", color: "#A9B6C4" }}
                >
                  Variación de frecuencia cardiaca durante <br /> tu actividad
                  por zona cardiaca
                </span>
              </div>
              <div
                className="  roma_web d-flex justify-content-center flex-column"
                style={{
                  background: "#ffff",
                  padding: "4% 5%",
                  borderRadius: "20px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    style={{ width: "70px", fontSize: "calc(0.5rem + 0.3vw)" }}
                  >
                    Zona rpm
                  </div>
                  <div
                    style={{ width: "30px", fontSize: "calc(0.5rem + 0.3vw)" }}
                  >
                    %
                  </div>
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                  <ChartMobile
                    dataServer={hours}
                    base={base}
                    maxl={maxl}
                    maximo={maximo}
                    muy_suave={muy_suave}
                    luz={luz}
                    moderado={moderado}
                    intenso={intenso}
                  />
                </div>
              </div>
            </div>
          
          </div>
          <div
            className="col-12 d-flex justify-content-center gap-1 flex-column"
            style={{
          
              padding: "0 2% 2% 2%",
              height: "100%",
            }}
          >  <div className="col-12 d-flex justify-content-around flex-column">
              <div
                className="d-flex w-100 flex-column justify-content-center text-center my-2"
                style={{ height: "auto" }}
              >
                <span
                  style={{ fontSize: "calc(0.8rem + 0.3vw)", color: "#A9B6C4" }}
                >
                  Tiempo de permanencia <br /> en cada zona cardiaca
                </span>
              </div>
              <div
                style={{
                  background: "#ffff",
                  padding: "0 5%",
                  borderRadius: "20px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="h-100 col-12 d-flex justify-content-center">
                  <div
                    className="all_bar d-flex flex-column justify-content-center"
                    style={{ width: "11%" }}
                  >
                    <div className="my-1 containerProgress_one">
                      {" "}
                      <ProgressBar
                        now={100}
                        label={`5`}
                        style={{
                          border: "1px solid #E16250",
                          borderRadius: "10px",

                          backgroundColor: "#E16250",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_two">
                      <ProgressBar
                        now={100}
                        label={`4`}
                        style={{
                          border: "1px solid #FFB6A4",
                          borderRadius: "10px",
                          backgroundColor: "#FFB6A4",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_three">
                      {" "}
                      <ProgressBar
                        now={100}
                        label={`3`}
                        style={{
                          border: "1px solid #F5D7CF",
                          borderRadius: "10px",
                          backgroundColor: "#F5D7CF",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_four">
                      {" "}
                      <ProgressBar
                        now={100}
                        label={`2`}
                        style={{
                          border: "1px solid #7988F6",
                          borderRadius: "10px",
                          backgroundColor: "#7988F6",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_five">
                      <ProgressBar
                        now={100}
                        label={`1`}
                        style={{
                          border: "1px solid #6EE1FB",
                          borderRadius: "10px",
                          backgroundColor: "#6EE1FB",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-8 px-2  d-flex flex-column justify-content-center text-center mobileProgressHei">
                    {" "}
                    <div className="my-1 containerProgress_one active_one_progress">
                      {" "}
                      <ProgressBar
                         now={
                          colorsPaint1 && typeof ((colorsPaint1 * 100) / totalPaint) == "number"
                            ? (colorsPaint1 * 100) / totalPaint
                            : 0
                        }
                        label={`5`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#ffff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_two active_two_progress">
                      <ProgressBar
                          now={
                          colorsPaint2 &&  typeof ((colorsPaint2 * 100) / totalPaint) == "number"
                            ? (colorsPaint2 * 100) / totalPaint
                            : 0
                        }
                        label={`4`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_three active_three_progress">
                      {" "}
                      <ProgressBar
                        now={
                          colorsPaint3 &&  typeof ((colorsPaint3 * 100) / totalPaint) == "number"
                            ? (colorsPaint3 * 100) / totalPaint
                            : 0
                        }
                        label={`3`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_four active_four_progress">
                      {" "}
                      <ProgressBar
                           now={
                          colorsPaint4 &&  typeof ((colorsPaint4 * 100) / totalPaint) == "number"
                            ? (colorsPaint4 * 100) / totalPaint
                            : 0
                        }
                        label={`2`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                    <div className="my-1 containerProgress_five active_five_progress">
                      <ProgressBar
                          now={
                          colorsPaint5 && typeof ((colorsPaint5 * 100) / totalPaint) == "number"
                            ? (colorsPaint5 * 100) / totalPaint
                            : 0
                        }
                        label={`1`}
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "10px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-2 d-flex flex-column justify-content-center text-left">
                    {" "}
                    <div className=" containerProgress_one active_one_progress">
                      {colorsPaint[0] && colorsPaint[0].length > 0
                        ? moment
                            .utc(colorsPaint[0].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_two active_two_progress">
                      {colorsPaint[1] && colorsPaint[1].length > 0
                        ? moment
                            .utc(colorsPaint[1].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_three active_three_progress">
                      {colorsPaint[2] && colorsPaint[2].length > 0
                        ? moment
                            .utc(colorsPaint[2].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_four active_four_progress">
                      {colorsPaint[3] && colorsPaint[3].length > 0
                        ? moment
                            .utc(colorsPaint[3].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                    <div className=" containerProgress_five active_five_progress">
                      {colorsPaint[4] && colorsPaint[4].length > 0
                        ? moment
                            .utc(colorsPaint[4].length * 1000)
                            .format("HH:mm:ss")
                        : "00:00:00"}
                    </div>
                  </div>
                </div>
              </div>
            </div></div>
            <br/><br/><br/><br/>
        </div>
      ) : (
        <div
          className={
            mobileOn
              ? "col-12  d-flex  flex-column h-100"
              : "col-12 d-flex justify-content-center flex-column h-100"
          }
          style={{
            background: "white",
            position: "relative",
            padding: " 0",
            height: heightContainer,
            borderRadius: borderBody,
            overflow: "hidden",
          }}
        >
          <Loading />
        </div>
      )}
      {modalInfo}
    </>
  );
}

export const TableSesions = (props) => {
  const shareImage = props.shareImage;
  const [cardiaca_prom, setcardiaca_prom] = useState("---");
  const [recorrida_prom, setrecorrida_prom] = useState("---");
  const [calorias_prom, setcalorias_prom] = useState("---");
  const [tiempo_sesion, setiempo_sesion] = useState("---");
  const [cardiaca_max, setcardiaca_max] = useState("---");
  const navigate =useNavigate()
  const infoAll = props.infoAll;
  const TrainingEstadistics = [
    [
      "Distancia recorrida  <br/>actividad libre",
      process.env.PUBLIC_URL + "/assets/img/distance3D.svg",
      "var(--colorsesionesKm)",
      "Pasos",
      recorrida_prom,
      "var(--elevvabutonLogin_User)",
    ],
    [
      "Quema <br/>de calorias",
      process.env.PUBLIC_URL + "/assets/img/cal3D.svg",
      "var(--colorsesionesCal)",
      "Kilocalorias",
      calorias_prom,
      "var(--textCal)",
    ],
    [
      "Tiempo <br/>de la sesión",
      process.env.PUBLIC_URL + "/assets/img/clockSesions.svg",
      "#E4F8FB",
      "Duración",
      tiempo_sesion,
      "#7B86FA",
    ],

    [
      "Frecuencia cardiaca promedio",
      process.env.PUBLIC_URL + "/assets/img/heart3D.svg",
      "var(--colorsesionesHr)",
      "por minuto",
      cardiaca_prom,
      "var(--elevva)",
    ],
    [
      "Frecuencia cardiaca máxima",
      process.env.PUBLIC_URL + "/assets/img/frecuencia_maxima.svg",
      "#FDDADA",
      "por minuto",
      cardiaca_max,
      "var(--elevva)",
    ],
  ];

  useEffect(() => {
    if (infoAll) {
      setcardiaca_prom(infoAll[0].hr_med);
      setrecorrida_prom(infoAll[0].distance);
      setcalorias_prom(infoAll[0].calorias);
      setiempo_sesion(infoAll[0].tiempo);
      setcardiaca_max(infoAll[0].hr_max);
    }
  }, [infoAll]);

  const mobileOn = props.mobileOn || false;
  const borderBody = props.borderBody || "20px";
  const widthContainer = props.widthContainer;

  return infoAll ? (
    <div
      className={
        mobileOn
          ? "col-12 d-flex  flex-column h-100"
          : "col-12  d-flex justify-content-center flex-column h-100"
      }
      style={{
        background: "white",
        padding:  laptop575.matches?"2%":"0",
        borderRadius: borderBody,
        width: widthContainer,
      }}
    >
    {mobileOn ?  (
          <div>
            <div
              className="d-flex my-auto p-3 p-md-0"
              style={{ height: "auto" }}
            >
              <div
                onClick={() =>laptop575.matches? navigate(-1):navigate(`/sesiones`)}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <span className="my-auto ms-2 text_arrow_top" style={{ fontWeight:"500",}}>
                {" "}
                Sesiones recientes
              </span>
            </div>
          
          </div>
        ):null}
      <div
        className="d-flex justify-content-center justify-content-md-between"
        style={laptop575.matches?{ padding: "0 2%" }:{ padding: "0" ,width:"100%"}}
      >
        <div className="d-flex my-auto pb-md-2 " style={laptop575.matches?null:{flexDirection:"column",alignItems:"center",background:"#F9F9F9",width:"100%",padding:"5% 0"}}>
          <div
            className=" d-flex justify-content-center flex-column results-image-icon"
            
          >
            <img width={40} height={40}  src={infoAll[0].icon} />
          </div>

          <div
            className="d-flex flex-column mx-2 my-auto"
            style={laptop575.matches?{lineHeight: "100%"}:{ lineHeight: "100%",alignItems:"center" }}
          >
            <span className="results-icon-label-u mt-1 mt-md-0"
             
            >
              {infoAll[0].nombresesion}
            </span>
            <span className="results-icon-label-d mt-1 mt-md-0" style={{whiteSpace: 'nowrap'}}
            
            >
              {infoAll[0].date}
            </span>
          </div>
        </div>
        <div style={laptop575.matches?null:{position:"fixed",right:"5%",top:"19%",zIndex:"99"}}> {/*  buttonShare */}
          <div
            onClick={shareImage}
            className=" d-flex justify-content-center flex-column"
            style={{
              cursor: "pointer",
              background: "#CB3D82",
              borderRadius: "50%",
              width: "calc(3rem + 0.3vw)",
              height: "calc(3rem + 0.3vw)",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "100%",
                maxWidth: "calc(1.5rem + 0.3vw)",
                maxHeight: "calc(1.5rem + 0.3vw)",
              }}
              src={shareIcon}
            />
          </div>
        </div>
      </div>{" "}
    { mobileOn? <PromediosPanelMobile
        paddingProm={laptop1600.matches ? "1% 4%" :laptop575.matches? "0 2%":"3.5% 2%"}
        backgroundPromed={"transparent"}
        widthCases={"100%"}
        TrainingEstadistics={TrainingEstadistics}
        idPanel={"averageSesions"}
      />:<PromediosPanel
        paddingProm={laptop1600.matches ? "1% 4%" : "0 2%"}
        backgroundPromed={"transparent"}
        widthCases={"100%"}
        TrainingEstadistics={TrainingEstadistics}
        idPanel={"averageSesions"}
      />}
    </div>
  ) : (
    <div
      className={
        mobileOn
          ? "col-12 d-flex  flex-column h-100"
          : "col-12  d-flex justify-content-center flex-column h-100"
      }
      style={{
        background: "white",
        padding: "2%",
        borderRadius: borderBody,
        width: widthContainer,
      }}
    >
      <Loading />{" "}
    </div>
  );
};
export const MisSesiones = (props) => {
  const navigate = useNavigate();
  const bookings = props.bookings;
  const handleClick = props.handleClick;
  const user = props.user;
  const radius = props.radius || "20px";
  const mobileOn = props.mobileOn || false;
  const infoAll = props.infoAll || false;
  const [Idselec, setIdselec] = useState(null);

  useEffect(() => {
    if (bookings.length > 0) {
      if (laptop575.matches) {
        setIdselec(bookings[0].UUid);
      }
    }
  }, [bookings]);
  return (
    <div
      className={
        mobileOn
          ? "col-12 col-md-3 d-flex  flex-column h-100"
          : "col-12 col-md-3 d-flex justify-content-center flex-column h-100"
      }
      style={{
        background: "white",
        padding: laptop575.matches ? "2%" : "0",
        borderRadius: radius,
      }}
    >
      <div style={{ flex: "1" }}>
        {mobileOn ? null : (
          <div>
            <div
              className="d-flex my-auto p-3 p-md-0"
              style={{ height: "auto" }}
            >
              <div
                onClick={() => navigate(`/Dashboard`)}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <span className=" fw-bold my-auto ms-2 text_arrow_top">
                {" "}
                Sesiones recientes
              </span>
            </div>
            {laptop575.matches ? <hr /> : null}
          </div>
        )}
        <div>
          <div
            className="d-flex justify-content-center"
            style={{
              lineHeight: "110%",
              margin: "0vmin auto",
              textAlign: "center",
              height: "auto",
            }}
          ></div>
        </div>
      </div>
      <div
        className="w-100 altura_change_sesiones"
        style={
          laptop575.matches ? null : { background: "#F9F9F9", padding: "4%" }
        }
      >
        {mobileOn ? (
          <>
            {" "}
            {bookings.map((e, index) => (
              <div
                key={index}
                className="my-2 text-center d-flex flex-row col-12"
                style={{
                  backgroundColor: "var(--colorsesiones)",
                  borderRadius: "20px",
                  padding: "2% 5%",
                  width: "90%",
                  alignItems: "center",
                }}
              >
                <div className="col-2">
                  <img src={e.icon}></img>
                </div>
                <span
                  className="m-auto letraPequeña col-8"
                  style={{
                    color: "var(--elevvaTextTitle)",
                    padding: "1vmin",
                    lineHeight: "110%",
                  }}
                >
                  <p style={{ fontSize: "1.7vmin", margin: "auto" }}>
                    {e.titulo}
                  </p>
                  <p style={{ fontSize: "1.7vmin", margin: "auto" }}>
                    23/05/2021
                  </p>
                </span>
                <div className="col-2">
                  {" "}
                  <div
                    onClick={() => handleClick(e.id)}
                    className=" fw-bold booking-all"
                    style={{
                      background: "transparent",
                      border: "1px solid #A8C2D7",
                      padding: "2%",
                      fontSize: "1vmin",
                      height: "fit-content",
                      cursor: "pointer",
                      color: "#A8C2D7",
                      borderRadius: "10px",
                    }}
                  >
                    <span style={{ fontSize: "0.5vmin" }}>
                      <i className="bi bi-chevron-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <PerfectScrollbar style={{ height: "90%" }}>
            {bookings &&
              bookings.map((e, index) => (
                <>
                  <div
                    key={index}
                    className={" text-center d-flex flex-row col-12 my-2"}
                    style={{
                      backgroundColor:
                        Idselec == e.UUid ? "#9EACED " : "#F4F0ED",
                      borderRadius: "20px",
                      padding: "3% 5%",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-2">
                      <img
                        className={Idselec == e.UUid ? "" : "addColorSelected"}
                        style={{ width: "calc(2.5rem + 0.3vmin)" }}
                        src={e.icon}
                      ></img>
                    </div>
                    <span
                      className="m-auto letraPequeña col-8"
                      style={
                        Idselec == e.UUid
                          ? {
                              color: "#ffff",
                              padding: "1vmin 4vmin",
                              textAlign: "left",
                              lineHeight: "110%",
                            }
                          : {
                              color: "#394F65",
                              padding: "1vmin 4vmin",
                              textAlign: "left",
                              lineHeight: "110%",
                            }
                      }
                    >
                      <p style={{ margin: "auto" }}>{e.titulo}</p>
                      <p style={{ margin: "auto" }}>
                        {Moment(e.data).format("DD/MM/YYYY")}
                      </p>
                    </span>
                    <div className="col-2 d-flex justify-content-center">
                      {" "}
                      <div
                        onClick={() =>
                          handleClick(
                            typeof e.id == "number" ? e.id : "d" + e.UUid
                          ) || setIdselec(e.UUid)
                        }
                        className=" fw-bold booking-all "
                        style={{
                          background: "transparent",
                          border:
                            Idselec == e.UUid
                              ? "1px solid  #ffff "
                              : "1px solid #A8C2D7",
                          padding: "0.7vmin",

                          cursor: "pointer",
                          color: Idselec == e.UUid ? "#ffff" : "#A8C2D7",
                          borderRadius: "10px",
                        }}
                      >
                        <span style={{ fontSize: "0.5vmin" }}>
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </PerfectScrollbar>
        )}
      </div>
    </div>
  );
};
