
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg";
import React from "react";
import {
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  instance,
  NuevaAxiosURL,
  ButtonMob,
  laptop575,
  allAvatars,
} from "../../utils/utils";
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg";
import { MiniPerfil } from "./helpPerfil";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

const MiCuenta = (props) => {
  const [modalInfo, setModalInfo] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState(null);
  const [userData, setUserData] = useState([]);
  const [confirm, setModalConfirm] = useState(null);
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [previewPhotoProfile, setPreviewPhotoProfile] = useState(null);
  const [photoProfile, setPhotoProfile] = useState(null);
  const [clasemodalAvatars, setclasemodalAvatars] = useState("d-none");
  const navigate = useNavigate();
  const location = useLocation();
  const stepIndex=props.stepIndex;
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const BannerAvatars = (props) => {
    const avatarPersons = props.avatarPersons;
    const render_avatarPersons = avatarPersons.map((imagen, index) => {
      return (
        <SwiperSlide key={index} className="w-100 my-3 mx-auto p-3">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3,1fr)",
              justifySelf: " center",
              justifyItems: "center",
              gap: "1%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {imagen.map((avatar, index) => (
              <button
                key={index}
                className="buttonIcon_Avatar"
                onClick={() => {
                  axios.get(avatar).then((res) => {
                    getBase64FromUrl(`${avatar}`).then((avata) => {
                     
                      var blob = dataURItoBlob(avata);
                      let imagen_firma = new File([blob], "photoAvatar.png", {
                        type: "image/png",
                      });
                      setPreviewPhotoProfile(URL.createObjectURL(imagen_firma));
                      setPhotoProfile(imagen_firma);
  
                    
                      setclasemodalAvatars("d-none");
                    });
                  });
                }}
              >
                <img className="iconavatar" src={avatar} />
              </button>
            ))}
          </div>
        </SwiperSlide>
      );
    });

    return (
      <>
        <Swiper
          style={{ maxWidth: "60vmin" }}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            el: ".bulletsPublicidad",
            type: "custom",
            clickable: true,
            renderCustom: function (swip, current, total) {
              current -= 1;
              return avatarPersons
                .map((value, index) => {
                  return `<div class=' swiper-pagination-bullet pointerinAvatar mx-2 ${current == index ? "active" : ""
                    }'></div>`;
                })
                .join("");
            },
          }}
        >
          {render_avatarPersons}
        </Swiper>

        <div className="bulletsPublicidad"></div>
      </>
    );
  };
  const changePhoto = (event) => {
    const photo = event.target.files[0];
    // console.log("event.target.files[0]", event.target.files[0]);
    setPhotoProfile(photo);
    setPreviewPhotoProfile(URL.createObjectURL(photo));
  };
  useEffect(() => {
    if (previewPhotoProfile) showPopCamera();
  }, [previewPhotoProfile]);
function postChangePicture(){
  const formData1 = new FormData();
  formData1.append("profile_pic", photoProfile ? photoProfile : "");

  instance
    .patch(`${NuevaAxiosURL}customers/`, formData1)
    .then((response) => {
      const data = response.data;
     
      localStorage.profile_pic =data.profile_pic;
    
       setModalInfo(null)
    })
    .catch((err) => {
      console.error(`Error en customers/`, err.response);
    });
}
  function Change_contraseña(event) {
    let change1 = event.target[0].value;
    let change2 = event.target[1].value;

    var formData = new FormData();
    formData.append("password", change1.trim()); //pass1
    formData.append("password2", change2.trim()); //pass1

    instance
      .post(`users/auth/change-password/`, formData)
      .then((res) => {
        if (res.data) {
          setModalConfirm(null);
          setModalInfo(
            <ModalInfo
              error="none"
              textButtonAction="Entendido"
              handleCancellcargue={hideModal}
              handleReservar={hideModal}
              iconoWidth={"2rem"}
              textSizeUp={"1.2rem"}
              icono={icoPositivo}
              text={ `<br/>Tu contraseña ha <br/> sido modificada <br/> correctamente.<br/>`}
              classesMsg="mb-2"
            />
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          console.log(msg_error)
          seterroraGregado(msg_error);

          setDisplayCorrect("inherit");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });

    event.preventDefault();
  }

  const hideModal = () => {
    setModalInfo(null);
  };

  function handleAtualizarcontraseña() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalConfirm(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
        <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={()=>setModalConfirm(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div className="title mb-3 py-1" style={{lineHeight:"100%",textAlign:"left",fontSize:"1.5rem"}}>Ingresa tu <br/> <span style={{fontWeight:"500",fontSize:"1.5rem"}}>contraseña</span> </div>
          <form 
            onSubmit={(e) => {
              Change_contraseña(e);
            }}
          >
            {" "}
            
            <input className="form-control input-style-elevva "
              style={
                laptop575.matches
                  ? {
                    minWidth:"calc(20rem + 1vmin)",
                      fontSize: "1rem",
                    }
                  : { width: "100%" }
              }
              name="tester"
              placeholder="Contraseña"
              required
            />
          
            <br/>
            <input className="form-control input-style-elevva "
              style={
                laptop575.matches
                  ? {
                    minWidth:"calc(20rem + 1vmin)",
                      fontSize: "1rem",
                    }
                  : { marginBottom: "0vh", width: "100%" }
              }
              name="tester"
              placeholder="Repite tu contraseña"
              required
            />
            <div
              id="passmail_error2"
              className=""
              style={
                laptop575.matches
                  ? {
                      display: `${displayCorrect}`,
                      color: "var(--elevva)",
                      fontSize: "0.8rem",
                      margin: "0 4rem",
                    }
                  : {
                      display: `${displayCorrect}`,
                      color: "var(--elevva)",
                      fontSize: "0.8rem",
                      margin: "0",
                     
                    }
              }
            >
              {ErroraGregado ? ErroraGregado.password : null}
            </div>
            <div className="col-12">
             
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"#9EACED"}
                  text="Actualizar"
                  className={
                    laptop575.matches
                      ? "mb-3 mt-3 letraspopfi2actualizar"
                      : " letraspopfi2actualizar py-2"
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  useEffect(() => {
    if (displayCorrect) handleAtualizarcontraseña();
  }, [displayCorrect]);

  useEffect(() => {
  
    if (stepIndex &&stepIndex >=3) {referPlan();}
    else{
      setModalInfo(null)
    }
  }, [stepIndex]);

  useEffect(() => {


    instance
      .get("users/current/")
      .then((response) => {
     
        setUserData(response.data);
     
      })
      .catch((error) => {
        console.log(error);
      });

  }, [location.pathname]);

  function referPlan(){
    if(localStorage.membership){
      instance
        .get("memberships/summary/")
        .then(async (res) => {
          console.log("resssssssssssssssssss", res.data);
          setModalInfo(
            <ModalInfoPlan
              zindex={"99"}
              handleCancel={hideModal}
              idMembership={localStorage.membership}
              infoData={res.data}
            />
          );
        })
        .catch((err) => console.log(err));
    
      
    }else{
      setModalInfo( <ModalInfo
        error="false"
        handleClick={hideModal}
        textButtonConfirmar={"Entendido"}
        classcorrecta={"recuerdaTitle"}
        handleCancellcargue={hideModal}
        title="Lo sentimos"
        text={`Aún no tienes una mebresía activa`}
        classesMsg="colorText_Modal"
      />)
    }
    }
    function showPopCamera(){
      let styleModal = {
        display: "flex",
        position: "fixed",
        zIndex: 999,
        whiteSpace: "pre-line",
      };
      // setPreviewPhotoProfile( userData?.customer?.profile_pic)
      setModalInfo(
        <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
        <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={()=>setModalInfo(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div className="d-flex justify-content-around" style={laptop575.matches?{minWidth:"calc(20rem + 2vmin)"}:{maxWidth:"calc(10rem + 2vmin)",margin:"5% auto"}}>
          
            <div className={laptop575.matches?"title mb-3 py-1 col-12 d-flex":"title mb-3 pt-2 col-12 d-flex"} style={{lineHeight:"100%",textAlign:"left",fontSize:"1.5rem",position:"relative",justifyContent:"center"}}> <img
              src={previewPhotoProfile || localStorage.profile_pic}
              alt="Avatar"
              className="rounded-circle imageCustomerPerfil"
            /> <input style={{position:"absolute"}}
                      type="file"
                      accept="image/*"
                      className="photoProfile"
                      name="photo"
                      id="photo"
                       onChange={changePhoto}
                    /></div> 
           
          </div>
        
          {photoProfile?<ButtonMob   handleClick={()=>postChangePicture()}
              colorButton={"#9EACED"}
              text="Aceptar"
              className={
                laptop575.matches
                  ? "mb-3 mt-3 letraspopfi2actualizar"
                  : " letraspopfi2actualizar py-2"
              }
            />:<div className="col-12 btnAction_pruebas letraspopfi2">
         
            <div className="position-relative mx-auto w-100 d-flex justify-content-center">
            <ButtonMob
              text={"Seleccionar foto"}
              className="btnAction  py-2 sizebuttonLarge"
            />{" "}
            <input
              type="file"
              name="profile_pic"
              accept="image/*"
              className="photoProfile"
              id="profile_pic"
              onChange={changePhoto}
            />
          </div>
        <br></br>
              <ButtonMob
            text={'Escoge tu avatar'}
            className="btnAction  py-2 sizebuttonLarge escoger_avatar_bottom"
            handleClickCamera={() => setclasemodalAvatars("")}
          />
          </div>}
       
        </div>
      </div>
      )
     }
     const ModalInfoAvatares = React.forwardRef((props, ref) => {
      const display = props.display || "flex";
      const position = props.position || "fixed";
      const title = props.title;
      let styleModal = {
        display: display,
        position: position,
        whiteSpace: "pre-line",
      };
      return (
        <div
          ref={ref}
          className={`${props.classes} modalInfo justify-content-center align-items-center`}
          style={styleModal}
        >
          <div className="modalInfoContenido">
            <div
              className="position-absolute m-4"
              style={{
                color: "var(--elevvaTextSubtitle)",
                top: "0",
                right: "0",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
              onClick={() => setclasemodalAvatars("d-none")}
            >
              <i className="bi bi-x-circle-fill"></i>
            </div>
            <div>
              <img
                className="logo-camera-responsive"
                src={process.env.PUBLIC_URL + "/assets/img/select_image.png"}
              />
            </div>
            <h4 className="text-color-title mt-2">{ReactHtmlParser(title)}</h4>
    
            <BannerAvatars avatarPersons={allAvatars} />
          </div>
        </div>
      );
    });
  return(<> {modalInfo} {confirm}
  <ModalInfoAvatares
          classes={clasemodalAvatars}
          title={`Avatares`}
        />
<div className="mx-3 my-3 d-flex">
   <button onClick={()=>navigate(-1)} className={"button_w_h_back"} style={{background:"transparent",border:"none",}}>
   <BackArrow width={"100%"} height={"100%"} /></button><span className="title my-auto fw-light" style={{fontSize:"1.2rem"}}>Mi <strong>cuenta</strong></span>     
      
      </div>
    
    <MiniPerfil showPopCamera={showPopCamera} stepIndex={stepIndex} mobileON={true} padreclass={"d-flex col-12 mx-0"} lastName={userData && userData.last_name} firstName={userData && userData.first_name} profilePic={previewPhotoProfile || userData && userData.customer?.profile_pic}  handlePlan={referPlan}  widthContainer={'100%'} idJoyride={"Micuenta"} email={userData && userData?.email} mobile_number={userData && userData?.mobile_number} handlePassword={handleAtualizarcontraseña}/></>
  )
}
  export default MiCuenta;