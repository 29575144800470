import React from "react";
// import ReactHtmlParser from "react-html-parser";
import { useEffect, useState, useRef } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import icoNegativo from "../../assets/newIcons/ICO-NEGATIVO.svg"
// import moment from "moment";
import Switch from "react-switch";
import { Link, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
// Modal
import Modalcito from "./utils/index";
import { ModalcitoOk } from "./utils/index";
import Button from 'react-bootstrap/Button';
import ReactHtmlParser from "react-html-parser";
import {
  Loadingspinner,
  instance,
  ModalInfo,
  NuevaAxiosURL,
  ButtonMob,
  SetModalError
} from "../../utils/utils";
import "swiper/swiper.min.css";
import "../OnboardingCustomer/onboardingCustomer.css";
import './utils/index.css'

SwiperCore.use([Pagination]);
export const YourObjectivesMember = (props) => {
  // console.log(moment());
  // const [Modal, setModal] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowOk, setModalShowOk] = useState(false);

  const navigate = useNavigate();
  const textBirth = useRef(null);
  const modalesDolores = useRef(null);
  const modalesDoloresBack = useRef(null);
  const elevvaT = props.elevvaT;
  const [Modal, setModal] = useState(null);
  const [chekeaprofile, setchekeaprofile] = useState(true);
  const [confirmado, setModalConfirm] = useState(null);
  // modal
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }
  const Ocultar = () => {
    setTimeout(() => {
      setModal(null);
    }, 0);
  };

  const hideModal = () => {
    setModal(null);
  };
  const objetivos = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      `Bajar <br/> de peso`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/PESO-B.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/PESO-A.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Mejorar <br/> mi salud`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/bestHeathB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/bestHeathA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Fortalecimiento <br/> de mi cuerpo`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/FORTALECIMIENTO-B.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/FORTALECIMIENTO-A.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Ganar <br/> masa muscular`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/MASA-B.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/MASA-A.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Tonificar <br/> mi cuerpo`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/TONIFICAR-B.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/TONIFICAR-A.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    
    [
      `Recuperar <br/> y cuidar lesión`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/LESION-B.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/LESION-A.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Controlar mi hipertensión",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Controlar mi diabetes",
      process.env.PUBLIC_URL + "/assets/img/onboarding/gotaA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/gotaB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],

 
   
  
   
  ];

  const [selectObjetivos1, setselectObjetivos1] = useState(new Array(objetivos.length));
console.log("selectObjetivos1",selectObjetivos1)
  const service = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      `Entrenamiento con profesor <br/> 1:1  en vivo`,
      // "1:1 online",
      process.env.PUBLIC_URL + "/assets/img/onboarding/onlineB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/onlineA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Series <br/> pregrabadas`,
      // "pregrabados",
      process.env.PUBLIC_URL + "/assets/img/onboarding/videosA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/videosB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Clases grupales <br/> online en vivo`,
      // "online",
      process.env.PUBLIC_URL + "/assets/img/onboarding/grupalesonlineB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/grupalesonlineA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Desarrollo <br/> emocional`, //0
      // "presencial y online", //1
      process.env.PUBLIC_URL + "/assets/img/onboarding/presencialB.svg", //2
      process.env.PUBLIC_URL + "/assets/img/onboarding/presencialA.svg", //3
      useRef(null), //4
      useRef(null), //5
      useRef(null), //6
    ],
    [
      `Asesoría profesional <br/> en nutrición`,
      // "en nutrición",
      process.env.PUBLIC_URL + "/assets/img/onboarding/asnutriB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/asnutriA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Monitoreo remoto <br/> de resultados`,
      // "medición de resultados",
      process.env.PUBLIC_URL + "/assets/img/onboarding/seguimientoB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/seguimientoA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];

  const [selectObjetivos, setselectObjetivos] = useState(null);
  const [selectService, setselectService] = useState(null);
  const [selectService1, setselectService1] = useState(
    new Array(service.length)
  );
  const selectCircleImg = (
    refContainer,
    refImg,
    index,
    classess,
    arr,
    onlyone
  ) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        iterator[3].current.classList = classess;
        iterator[3].current.style.fontWeight = `400`;
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
        iterator[4].current.style.border = `6px solid var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[4].current.style.backgroundColor = `var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[5] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
      refImg.current.style.backgroundImage = `url(${arr[index][1]})`;
      refImg.current.style.backgroundColor = `var(--backgroundOnboardingObjectiveBorderOff)`;
    } else {
      refContainer.current.style.fontWeight = `600`;
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;

      refImg.current.style.backgroundImage = `url(${arr[index][2]})`;
      refImg.current.style.backgroundColor = `var(--backgroundOnboardingObjective)`;
    }
    if (arr === objetivos) {
       objetivos[index][5] = refContainer.current.active;
       const copy = selectObjetivos1;
       copy[index] = refContainer.current.active;
     
      // inputs["objectives"] = (index + 1) * 10;
      // setselectObjetivos1((index + 1) * 10);
      setselectObjetivos(copy)
      setselectObjetivos1(copy); // 10 20 30
      // console.log(setselectObjetivos1);
    }
    if (arr === service) {
      service[index][5] = refContainer.current.active;
      const copy = selectService1;
      copy[index] = refContainer.current.active;
      setselectService(copy);
      // inputs["objectives"] = (index + 1) * 10;
      setselectService1(copy); //1 2 3 3
      // console.log(index, "Preference");
    }
  };

  const [currentSlider, setCurrentSlider] = useState(1);
  const [theSwipper, setTheSwipper] = useState(null);
  const [canNext, setCanNext] = useState(false);
  const [numberStep, setnumberStep] = useState(null);
  useEffect(() => {
    if (checkCurrentForm(false)) {
      setCanNext(true);
    }
    return () => {};
  }, [currentSlider]);

  function nextSlider() {
    setCanNext(false);
    theSwipper.slideNext();
  }

  function prevSlider() {
    theSwipper.slidePrev();
  }

  const CompletarFooter = (props) => {
    const cargarname = props.cargarname;
    const [modalInfo, setModalInfo] = useState(null);

    const current = props.current;
    const valorllenotelefono = cargarname ? 6 : 7;
    const renderFootBar = current == valorllenotelefono ? true : false;
    const siguienteborrar = document.querySelector(
      ".CompletarFooterMembers .anterior"
    );
    if (siguienteborrar) {
      if (current >= 1 && current < 2) {
        siguienteborrar.style = "background-color: transparent;";
      } else {
        siguienteborrar.style = "background-color: #CB3D82";
      }
    }
    // console.log("renderFootBar", renderFootBar);
    function completeProfile() {
      props.completeProfile();
    }
    function nextSlider() {
      if (checkCurrentForm()) {
        setCanNext(false);
        theSwipper.slideNext();

        if (current == 2) completeProfile();
      }
    }

    function prevSlider() {
      theSwipper.slidePrev();
    }

    const classNext = `siguiente ${props.canNext == true ? "canNext" : ""}`;
    return modalInfo == null ? (
      <>
        <nav
          className="CompletarFooterMembers navFooter  next_stop "
          style={{ height: "10%" }}
        >
          <ButtonMob
            className={"d-none"}
            icon={"bi bi-chevron-left"}
            colorButton={`var(--elevva)`}
            handleClick={prevSlider}
            // handleClick={() => navigate(`/Usuario/groupals`)}

            text={"Anterior"}
          ></ButtonMob>
          <ButtonMob
            icon={"bi bi-chevron-right"}
            colorButton={
              current == 2 ? "#CB3D82" : `var(--elevvabutonLogin_User)`
            }
            handleClick={nextSlider}
            className=""
            text={current == 2 ? "Finalizar" : "Siguiente"}
            // handleClick={() => navigate(`/Usuario/groupals`)}
          ></ButtonMob>

          {/* )} */}
        </nav>
      </>
    ) : (
      <>{modalInfo}</>
    );
  };

  function checkCurrentForm(show_warn = true) {
    const checkSlider = currentSlider;

    let lleno = true;
    function showModalWarn(faltaLLenar) {
      if (show_warn) {
        // setModalShow(true);
        setModal(
          <ModalInfo
            error="none"
            textButtonAction="Entendido"
            handleCancellcargue={hideModal}
            handleReservar={hideModal}
            iconoWidth={"2.5rem"}
            textSizeUp={"1.2rem"}
            icono={icoNegativo}
            text={
              "Faltan algunos datos por llenar: \n\n" +
              `<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>` +
              faltaLLenar.join("\n") +
              "</br>"
            }
            classesMsg={"FaltanItems"}
          />
        );
        // setModal(
        //   <ModalInfo
        //     error="true"
        //     handleClick={hideModal}
        //     handleCancellcargue={hideModal}
        //     text={
        //       "Faltan algunos datos por llenar: \n\n" + `<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>` + faltaLLenar.join("\n")
        //     }
        //     classesMsg={"FaltanItems"}
        //   />
        // );
      }
    }
    const faltaLLenar = [];

    switch (checkSlider) {
      case 1:
        lleno = false;
        for (const key in selectObjetivos1) {
          if (selectObjetivos1[key] == true) lleno = true;
        }
       
        if (!lleno) {
          faltaLLenar.push("Objetivo principal");
        }
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;

      case 2:
        lleno = false;

        for (const key in selectService1) {
          if (selectService1[key] == true) lleno = true;
        }

        if (!lleno) {
          faltaLLenar.push("Debes seleccionar una de las opciones disponibles");
        }
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  // function dataURItoBlob(dataURI) {
  //   // convert base64/URLEncoded data component to raw binary data held in a string
  //   var byteString;
  //   if (dataURI.split(",")[0].indexOf("base64") >= 0)
  //     byteString = atob(dataURI.split(",")[1]);
  //   else byteString = unescape(dataURI.split(",")[1]);

  //   // separate out the mime component
  //   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  //   // write the bytes of the string to a typed array
  //   var ia = new Uint8Array(byteString.length);
  //   for (var i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }

  //   return new Blob([ia], {
  //     type: mimeString,
  //   });
  // }

  const labelInput = (name, inputClasses, placeholder, type) => {
    return (
      <>
        <input
          type={type}
          name={name}
          className={inputClasses}
          placeholder={placeholder}
          // onChange={handleChange}
        />{" "}
      </>
    );
  };
  const videoConstraints = {
    width: 500,
    maxWidth: "5vw",
    height: 500,
    facingMode: "user",
    position: "absolute",
  };

  const completeProfile = () => {
    //toca defiinir
    const faltaLLenar = [];
    let lleno = false;

   
    for (const key in selectObjetivos1) {
      if (selectObjetivos1[key] == true) lleno = true;
    }

    for (const key in selectService1) {
      if (selectService1[key] == true) lleno = true;
    }

    if (lleno) sendData();
  };

  const Paso1 = (
    <SwiperSlide
      className="slider_form d-flex flex-column "
      style={{ height: "100%", padding: "0 2%" }}
    >
      <div style={{ height: "49vh", alignItems: "center" }}>
        <div className=" w-100 d-none d-md-flex" style={{ height: "auto" }}>
          <div style={{ width: "100%", minWidth: "380px", height: "auto" }}>
            <h1 className="text-color-title m-auto">
              {elevvaT("objective_text")}{" "}
              <strong>{elevvaT("objective_perfil")}</strong>
            </h1>
          </div>
          <div
            className="m-auto"
            style={{
              width: "100%",
              color: "var(--elevvaTexttitleLow)",
            }}
          >
            <hr />
          </div>
        </div>
        <div>
          <h4 className="text-color-title-members leftTextoInterno">
            {" "}
            ¿Cuáles son tus objetivos de bienestar?
          </h4>
        </div>
        <div
          className="d-flex flex-column flex-md-row justify-content-center novoHeightMem"
          style={{ alignItems: "center" }}
        >
          {" "}
          <div className="formSteps scrollObjectives row justify-content-center ">
            {" "}
            {objetivos.map((objetivo, index, objectives) => {
              return (
                <div
                  key={index}
                  ref={objetivo[3]}
                  className="objetivo col-5 col-lg-3 "
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    selectCircleImg(
                      objetivo[3],
                      objetivo[4],
                      index,
                      "objetivo col-5 col-lg-3",
                      objectives,
                      false
                    )
                  }
                >
                  <div
                    // ref={objetivo[4].current}
                    ref={objetivo[4]}
                    id="icon"
                    style={{
                      backgroundImage: `url(${objetivo[1]})`,
                    }}
                    className="rounded-circle crecerMiMember text-center"
                  ></div>
                  <div
                    id="text"
                    className="my-2  text-center text_icons_responsive"
                    style={{
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    {ReactHtmlParser(objetivo[0])}{" "}
                  </div>{" "}
                </div>
              );
            })}{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </SwiperSlide>
  );

  const Paso2 = (
    <SwiperSlide
      className="slider_form d-flex flex-column "
      style={{ height: "100%", padding: "0 2%" }}
    >
      <div style={{ height: "50vh", alignItems: "center" }}>
        <div className=" w-100 d-none d-md-flex" style={{ height: "auto" }}>
          <div style={{ width: "100%", minWidth: "380px", height: "auto" }}>
            <h1 className="text-color-title m-auto">
              {elevvaT("objective_text")}{" "}
              <strong>{elevvaT("objective_perfil")}</strong>
            </h1>
          </div>
          <div
            className="m-auto"
            style={{
              width: "90%",
              color: "var(--elevvaTexttitleLow)",
            }}
          >
            <hr />
          </div>
        </div>
        <div className="px-3 py-1 px-md-0 py-md-0">
          <h4 className="text-color-title-members leftTextoInterno">
            {" "}
            ¿Con que servicio gustaría cambiar tu estilo de vida?{" "}
          </h4>
        </div>
        <PerfectScrollbar
          className="novoHeightMemPaso2"
          style={{ alignItems: "center" }}
        >
          <div
            className="d-flex flex-column flex-md-row justify-content-center"
            style={{ alignItems: "center" }}
          >
            {" "}
            <div className="formSteps  row col-12 ">
              {" "}
              {service.map((objetivo, index, preference_activity) => {
                return (
                  <div
                    key={index}
                    // ref={objetivo[3].current}
                    ref={objetivo[3]}
                    className="objetivo col-4 my-1"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      selectCircleImg(
                        objetivo[3],
                        objetivo[4],
                        index,
                        "objetivo col-4",
                        preference_activity,
                        false
                      )
                    }
                  >
                    <div
                      // ref={objetivo[4].current}
                      ref={objetivo[4]}
                      id="icon"
                      style={{
                        backgroundImage: `url(${objetivo[1]})`,
                      }}
                      className="rounded-circle crecerMiMember text-center"
                    ></div>{" "}
                    <div
                      id="text"
                      className="my-2  text-center text_icons_responsive"
                      style={{
                        color: "var(--elevvaTextTitle)",
                      }}
                    >
                      {ReactHtmlParser(objetivo[0])}{" "}
                      {/* <span className="secondary-text">{objetivo[1]}</span>                 */}
                    </div>{" "}
                  </div>
                );
              })}{" "}
            </div>{" "}
          </div>{" "}
        </PerfectScrollbar>{" "}
      </div>{" "}
    </SwiperSlide>
  );

  const sendData = () => {
    sendDataFinal();
  };

  const sendDataFinal = () => {
    var selectServicesArray = [];
    var selectObjectivesArray = [];
    selectService1.filter(function (value, index, arr) {
      if (value === true) {
        let new_index = index + 1;

        selectServicesArray.push(new_index);
      }
      return true;
    });
    selectObjetivos1.filter(function (value, index, arr) {
      if (value === true) {
        let new_index = (index + 1) *10;

        selectObjectivesArray.push(new_index);
      }
      return true;
    });

    const formData = new FormData();

    // objectives 10.20.30.40.
    // formData.append("objectives", selectObjectivesArray);
    selectObjectivesArray.forEach((item) =>
    formData.append("objectives", item))

    // preference_activity 1,2,3,4,5
    formData.append("wellness_services", JSON.stringify(selectServicesArray)); // Agregando datos

    instance
      .patch(`customers/`, formData)

      .then((res) => {
        if (res.data) {
          navigate(`/Dashboard`);
        }
      })
      .catch((err) => {
        if (err.response) {
          setModal(
            <SetModalError
              handleClick={() => setModal(null)}
              title="Algo salio mal"
            />
          );
        }
      });
  };

  return (
    <>

      <ModalInfo
        handleClick={() => {
          // salvedadMedico.current.style.display = "none";
          sendData();
        }}
        error="false"
        title="Antes de empezar a hacer actividad física"
        // ref={salvedadMedico}
        display="none"
        text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
      />

      <div className="d-md-flex  CompletarPerfil col-12 colorBackgroundMem" >

      <div className="w-100 d-md-none m-auto d-flex justify-content-center " style={{position:'relative',height:'7%', background:'#F1F6F9',alignItems:'center'}}> <img
             style={{width:'20%' }} 
              src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
            /></div>
      
        <div className="d-none d-md-block background-left-onboarding " style={{width:"10%"}}>
          <div className="w-100 d-flex justify-content-center " style={{height:"25%"}}>
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo_elevva_alta.png"}
              className="logo-center-onboarding"
            />
          </div>{" "}
        </div>
        
        <div className="position-relative mobileMembers" >
  
        <div className="inside-content-full-Members  d-flex flex-column flex-md-row justify-content-center align-items-center"  > 
  <div className="d-none d-md-block primer-columna" style={{backgroundSize: "cover",backgroundRepeat: "no-repeat",
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/img/oldmanElevva.jpg"
              })`,
          }}>

          </div>
                 <div className="d-md-none my-auto py-1" ><h1 className="text-color-title text-center m-auto " style={{height:'auto'}}>
            {elevvaT("objective_text")} <strong>{elevvaT("objective_perfil")}</strong>
          </h1></div>
            <div className="stepsBullets bulletsOnborMembership" > </div>
            <div className="fondoImgenMobile"></div>
  <div className="primer-columna-dos">

          <div className="primer-fila-dos d-flex position-relative" style={{height:'100%'}}>
      
        {" "}

      
        <div className="d-flex flex-column flex-md-row contain-info-onboarding-Objectives-member justify-content-start" >
        <div className="col-12 otherheigthObjectives" >
              <Swiper style={{height:"100%"}}
                spaceBetween={100}
                slidesPerView={1}
                height={100}
                allowTouchMove={false}
                pagination={{
                  el: ".stepsBullets",
                  type: "custom",
                  renderCustom: function (swiper, current, total) {
                    setnumberStep(current);
                    if (theSwipper == null) {
                      setTheSwipper(swiper);
                    }
                    setCurrentSlider(current);
                    return `<div class='swiper-pagination-bullet Bullet position-relative  ${current >= 0 ? "active " : ""
                      }'><div class='centraNumberSteps'>1</div></div>
                                                        <div class='swipersep  ${current >0
                        ? "active"
                        : ""
                      }'  ></div>
                                                        <div class='swiper-pagination-bullet Bullet position-relative  ${current == 2
                        ? "active"
                        : ""
                      }'><div class='centraNumberSteps'>2</div></div>
                                                         </div>
                                                      
                                                    `;
                  },
                  clickable: false,
                }}
              >
                <>
                  {Paso1}
                  {Paso2}
                </>
              </Swiper>
              </div>
          </div>
          </div>
              <CompletarFooter
            current={currentSlider}
            completeProfile={completeProfile}
            nextSlider={nextSlider}
            prevSlider={prevSlider}
            chekeaprofile={chekeaprofile}
            canNext={canNext}
          />
          <div className="other_nav" >    <nav id="navTerminos" className=" text-color-subtitle position-absolute" style={{height:'10%'}} >
            {elevvaT("objective_skip")}&nbsp;
            <Link to={`/Usuario/Inicio`}>
              <u className="text-color-elevva">
                {elevvaT("objective_guide")}
              </u>
            </Link>
          </nav></div>
              {confirmado}
           
          
  </div>
       </div>{" "}
  </div>
        {modalesDolores && modalesDolores.current} {modalesDoloresBack.current}{" "}
        <div
          ref={textBirth}
          className="position-absolute "
          style={{
            display: "none",
          }}
        // onClick={() => (textBirth.current.style = "display:none")}
        >
        </div>
      </div>  {Modal}
    </>
  );
};
