import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { 
  // Link, 
  // Navigate, 
  useNavigate 
} from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { 
  // ButtonMob,
  instance,
  NuevaAxiosURL 
} from "../../utils/utils";

export function Allvideos(props) {

  const images = props.videos;
  function handleClick(event) {
    props.buttonClick(event);
  }
  useEffect(() => {
    var principal = document.querySelector(".swiper-wrapper").children;

    for (let i = 0; i < principal.length; i++) {
      // le asiganos un evento mouseover
      principal[i].addEventListener("mouseover", (e) => {
        if (e.target.id == i) {
          principal[i].children[0].children[0].classList.add("growing");
        }
      });
      principal[i].addEventListener("mouseout", (e) => {
        if (e.target.id == i) {
          principal[i].children[0].children[0].classList.remove("growing");
        }
      });
    }
  });
  const id = props.id;
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const render_img_desk =
    images &&
    images.map((imagen, index) => {
      return (
        <SwiperSlide
          key={index}
          value={imagen.id}
          className={props.className}
          style={{ width: "22vmin", minHeight: "34vmin" }}
        >
          <div className="d-flex position-relative imagenreserva">
            <div
              onClick={() => handleClick(imagen.id)}
              className="content position-relative "
              style={{ minWidth: "25vmin" }}
            >
              {" "}
              <img
                id={index}
                className=""
                src={process.env.PUBLIC_URL + `${imagen.thumbnail}`}
                style={{ borderRadius: "20px", cursor: "pointer" }}
                width={"80%"}
                height="100%"
              />
              <div
                className="position-absolute w-100"
                style={{
                  top: "0",
                  color: "#394F65",
                  padding: "8% 10%",
                  maxWidth: "80%",
                }}
              >
                {imagen.subcategory}

                <br />
                <div
                  className="fw-bold"
                  style={{
                    fontSize: "1.7vmin",
                    lineHeight: "100%",
                  }}
                >
                  {imagen.name}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });

  return (
    <div className="position-relative reconocer">
      <br />
      <div className="d-flex swiper-pagination_p  my-3">
        {id == "numbers" ? (
          <>
            <div
              className={"d-flex position-absolute"}
              style={{ top: "50%", left: "2.5%" }}
            >
              <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            </div>
            <div
              className={"d-flex position-absolute"}
              style={{ top: "50%", right: "2.5%" }}
            >
              <div className={`swiper-button-next-${id} tonin-next`}></div>
            </div>
          </>
        ) : (
          <div className={"d-flex"}>
            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            <div className={`swiper-button-next-${id} tonin-next`}></div>
          </div>
        )}
      </div>
      <Swiper
        style={{ margin: "auto ", width: "85vmin" }}
        className="slidersNetflixReservas py-3"
        navigation={{
          nextEl: `.swiper-button-next-${id}`,
          prevEl: `.swiper-button-prev-${id}`,
        }}
        modules={[Navigation]}
        slidesPerView="auto"
        spaceBetween={0}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        slidesPerGroup={1}
        slidesPerGroupSkip={1}
      >
        {render_img_desk}
      </Swiper>
    </div>
  );
}




export const Allcategory = ({selectedMembership, props, limitedMemberships}) => {
  // const { descriptionMemverships } = limitedMemberships

// const recorrer = limitedMemberships.map((e) => {
//   return { id: e.id, name: e.name, description: e.description };})

// console.log(limitedMemberships);
  // modales de errores
  //const [Modal, setModal] = useState(null);
  //const [currentSlider, setCurrentSlider] = useState(1);

  // comparando input con limite
  const filteredMemberships = limitedMemberships.filter(
    (membership) =>
    membership.name ===  selectedMembership
  );
  
  // sacando id
  const idMembership = filteredMemberships.map(e => {
    return e.id;
  })

  // array id a number
  //console.log(numeroId, "numeroid");
  

  //Capturando info pago
  const [nombrePago, setNombrePago] = useState('')
  const [correoPago, setCorreoPago] = useState('')
  const [titularPago, setTitularPago] = useState('')
  const [numeroPago, setNumeroPago] = useState('')
  const [codigoPago, setCodigoPago] = useState('')
  const [mmPago, setMmPago] = useState('')
  const [aaPago, setAaPago] = useState('')
  const[recuerdamePago, setrecuerdamePago] = useState(false)
  const [totalPago, setTotalPago] = useState(0)
  

  const [redimeloCodigo, setRedimeloCodigo] = useState('')

  // Datos de resquest membership
  const userId = localStorage.id // id de usuario  definir otra vez
  //console.log(userId);
  const [optionPago, setOptionPago] = useState(null) 
  const membershipsId = parseInt(idMembership[0])
  //console.log(membershipsId); // Es el id de cada membresia
  const [isActive, setIsActive] =  useState(true)  // si el usuario es activo o no
  const [freeMium, setFreeMium] = useState(true) // si tiene prueba gratis la membresia
  
  //console.log(is_active);
  // Fecha y hora actual
  const fechaHora = new Date();
  const fecha = { fechaHora: fechaHora.toISOString() };
  //console.log(fecha.fechaHora); 

  // datos

  //Enviando datos
  const enviarDataForm = (e) => {
    e.preventDefault(e)

    const formData = new FormData();
    const formPago = new FormData()

    // Info de pago de membresía
    formData.append('user', userId) // id del usuario
    formData.append('membership', membershipsId) // id
    formData.append('active', isActive ) // true, estoy filtrando las membresias por active
    formData.append('freemium_ended', freeMium )
    formData.append('last_payment', fecha.fechaHora ) // hora actual
    // formData.append('next_payment',fecha.fechaHora) // definir 
    // formData.append('end_date', fecha.fechaHora)     // definir
    formData.append('start_date', fecha.fechaHora); // Fecha de inicio de la membresia   
    formData.append('id', membershipsId) // id memberships
  

    // Info de pago 
    formPago.append('nombrePago', nombrePago)
    formPago.append('correoPago', correoPago)
    formPago.append('titularPago', titularPago)
    formPago.append('numeroPago', numeroPago)
    formPago.append('mmPago', mmPago)
    formPago.append('aaPago', aaPago)
    formPago.append('recuerdamePago', recuerdamePago)
    formPago.append('optionPago', optionPago)
    formPago.append('redimeloCodigo', redimeloCodigo)
    formPago.append('totalPago', totalPago)
   

    // Enviando datos
    instance
      //https://api.staging.elevva.com.co/api/v1/memberships/{id}/signout/ = {id de la memberships}
      .post(`${NuevaAxiosURL}memberships/${membershipsId}/signup/`, formData, formPago, {
      })
      .then((res) => res)
      .catch((res) => {
        if (res.response.status === 404) {
          console.log("Error 404");
        }
        console.log(res.message, "Error");
      })
  }

  // Validando option de pago 
  const handlePlanChange = (e) => {
    setOptionPago(e.target.value);
  
  };


  
// valdiacion
// const [membresias, setMembresias] = useState([]);
//   //let userId = localStorage.id // id de usuario 
//   const [usuario, setUsuario] = useState(null);
//   //console.log(userId); 
//   //https://api.staging.elevva.com.co/api/v1/memberships/1/detail // Supongamos que el usuario tiene un ID de 1
//   useEffect(() => {
//     instance
//     .get(`${NuevaAxiosURL}memberships/${membershipsId}/detail`)
//     .then(response => {
//         setMembresias(response.data);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//       const usuario = localStorage.id
//       if(usuario) {
//         setUsuario(JSON.parse(usuario))
//       }
//   }, []);

//   //console.log(membresias);
//   const nombre  =  membresias.members //aceciendo al objecto por nombre,.

  //const hasSeven = nombre.includes(1);
  
  
//console.log(nombre);
  // Validaciones futuras

  // const handleSubmit = (e) => {

  //   e.preventDefault()

  //   // validando llenado de formulario
  //   if([nombrePago, correoPago, titularPago, numeroPago, codigoPago, mmPago, aaPago].includes('')) {
  //     console.log("Tenemos un campo vacio");
  //   }
  //   console.log("Todo lleno");
  // }  

  // validacion de ultimo pago y primero 
//   function calculateNextPayment(lastPaymentDate, paymentCycle, cycleUnit) {
//     // Obtener la fecha actual
//     const now = new Date();
  
//     // Convertir la fecha del último pago a objeto de fecha
//     const lastPayment = new Date(lastPaymentDate);
  
//     // Calcular la fecha del próximo pago
//     let nextPayment = new Date(lastPayment.getTime());
//     switch (cycleUnit) {
//       case "days":
//         nextPayment.setDate(nextPayment.getDate() + paymentCycle);
//         break;
//       case "weeks":
//         nextPayment.setDate(nextPayment.getDate() + paymentCycle * 7);
//         break;
//       case "months":
//         nextPayment.setMonth(nextPayment.getMonth() + paymentCycle);
//         break;
//       case "years":
//         nextPayment.setFullYear(nextPayment.getFullYear() + paymentCycle);
//         break;
//       default:
//         throw new Error("Unidad de tiempo no válida");
//     }
//     // Devolver la fecha del próximo pago y el monto a pagar
//    const amount = 100; // reemplazar con el monto real
//    return { date: nextPayment, amount };
//   }
//  }
  
//  function getMillisecondsPerCycle(paymentCycle, cycleUnit) {
//   switch (cycleUnit) {
//     case "days":
//       return paymentCycle * 86400000;
//     case "weeks":
//       return paymentCycle * 604800000;
//     case "months":
//       return paymentCycle * 2592000000;
//     case "years":
//       return paymentCycle * 31536000000;
//     default:
//       throw new Error("Unidad de tiempo no válida");
//   }
// }
// function Membership(props) {
//   // Definir el estado de la fecha del último pago
//   const [lastPaymentDate, setLastPaymentDate] = useState("2022-01-01");

//   // Definir la duración del ciclo de pago y la unidad de tiempo
//   const paymentCycle = 1;
//   const cycleUnit = "years";

//   // Calcular la fecha del próximo pago y el monto a pagar
//   const { date: nextPaymentDate, amount: nextPaymentAmount } = calculateNextPayment(
//     lastPaymentDate,
//     paymentCycle,
//     cycleUnit
//   );
  
  function handleClick(event) {
    // props.handleClick(event);
  }
  const membresiaSelect = {
    Plan: [
      {
        type: "Mensual",
        includes:
          "Tu cortesía de 7 días tendrá vigencia hasta el 22 de diciembre de 2022. Después de esta fecha, Tu plan tendrá un débito automático mensual",
        price: "$9.99 /mes",
        ahorro: null,
      },
      {
        type: "Anual",
        includes:
          "Tu cortesía de 7 días tendrá vigencia hasta el 22 de diciembre de 2022. Después de esta fecha, Tu plan tendrá un débito automático anual.",
        price: "$90.99 /año",
        ahorro: "30%",
      },
    ],
    namePlan: "Plan elevva gratuito",
    containPlan: [
      "12 créditos de entrenamiento",
      "1 clase grupal gratis semanal",
      "Plan de entrenamiento",
      "Plan de alimentación",
      "Acceso a 1 video diario",
    ],
  };  

  return (
    <div
      className="col-9"
      style={{
        background: "white",
        height: "100%",
        padding: "2% 0",
        borderRadius: "20px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ height: "8%" }}>
        {" "}
        <div
          style={{
            color: "var(--elevvaTextTitle)",
            justifyContent: "center",
            padding: "0vmin 8vmin",
          }}
          className={"title  d-flex my-auto"}
        >
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className=" d-flex tabulete-active mx-auto">
              <div
                className="centraNumberSteps fw-bold"
                style={{ fontSize: "1.5vmin", width: "10px", color: "#ffff" }}
              >
                1
              </div>
            </div>
          </div>

          <div
            className="linememberstep mx-1"
            style={{
              verticalAlign: "middle",
              width: "11vmin",
            }}
          >
            <hr style={{ color: "#7B86FA", opacity: "1" }} />
          </div>
          <div
            className="d-flex"
            style={{ flexDirection: "column", justifyContent: "center" }}
          >
            <div className=" d-flex tabulete-active mx-auto">
              <div
                className="centraNumberSteps  fw-bold"
                style={{ fontSize: "1.5vmin", color: "#ffff" }}
              >
                2
              </div>
            </div>
          </div>
          <div
            className="linememberstep mx-1"
            style={{
              verticalAlign: "middle",
              width: "11vmin",
            }}
          >
            <hr style={{ color: "#7B86FA", opacity: "1" }} />
          </div>
          <div
            className="d-flex"
            style={{ flexDirection: "column", justifyContent: "center" }}
          >
            <div className=" d-flex tabulete mx-auto">
              <div
                className="centraNumberSteps  fw-bold"
                style={{ fontSize: "1.5vmin" }}
              >
                3
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            color: "var(--elevvaTextTitle)",
            justifyContent: "center",
            padding: "0vmin 8vmin",
          }}
          className={"title  d-flex my-auto"}
        >
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className=" d-flex  mx-auto">
              <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
                Confirma tu pago
              </div>
            </div>
          </div>

          <div
            className="linememberstep"
            style={{
              verticalAlign: "middle",
              width: "4.5vmin",
            }}
          ></div>
          <div
            className="d-flex"
            style={{ flexDirection: "column", justifyContent: "center" }}
          >
            <div className=" d-flex  mx-auto">
              <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
                Selecciona
              </div>
            </div>
          </div>
          <div
            className="linememberstep"
            style={{
              verticalAlign: "middle",
              width: "6.0vmin",
            }}
          ></div>
          <div
            className="d-flex"
            style={{ flexDirection: "column", justifyContent: "center" }}
          >
            <div className=" d-flex  mx-auto">
              <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
                Disfruta tu plan
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex"
        style={{
          justifyContent: "center",

          padding: "2%",
          height: "94%",
        }}
      >
        <div
          className="w-50 d-flex"
          style={{
            padding: " 3%",
            justifyContent: "center",
            flexDirection: "column",
            margin: "3% 0",
          }}
        >
          <div className="d-flex my-auto" style={{ paddingTop: "6vmin" }}>
            <div
              className=" fw-bold booking-all"
              style={{
                background: "transparent",
                border: "1px solid #A8C2D7",
                padding: "2%",
                fontSize: "1vmin",
                height: "fit-content",
                cursor: "pointer",
                color: "#A8C2D7",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontSize: "0.5vmin" }}>
                <i className="bi bi-chevron-left"></i>
              </span>
            </div>
            <div className=" my-auto mx-2">
              <div
                className="my-2"
                style={{
                  color: "#394F65",
                  fontSize: "2.0vmin",
                  fontWeight: "bold",
                }}
              >
            <UtilsName selectedMembership={selectedMembership}/>
            {/* {console.log(selectedMembership)} */}
           </div>
              <div
                style={{
                  color: "#394F65",

                  fontSize: "3vmin",
                }}
              >
                <div
                  className="m-auto d-flex mt-3"
                  style={{
                    width: "auto",
                    flexDirection: "column",
                    rowGap: "0.7vmin",
                  }}
                >
                  {/* {limitedMemberships.map((e, i) => ( */}
                                    {membresiaSelect.containPlan.map((e, i) => (

                    <div key={i} className="divIndividualTypes me-auto d-flex">
                      <span
                        className=""
                        style={{ fontSize: "1.7vmin", color: "var(--elevva)" }}
                      >
                        <i class="bi bi-check-lg"></i>
                      </span>{" "}
                      <span
                        className="me-auto"
                        style={{ fontSize: "1.5vmin", whiteSpace: "nowrap" }}
                      >
                        {/* {e.description} */}
                        {e}
                      </span>
                    </div>
                  ))}
                </div>
              </div>{" "}
              <br />{" "}
            </div>
          </div>
          <div style={{ marginTop: "4vmin", marginBottom: "2vmin" }}>
            {membresiaSelect.Plan.map((e) => (
              <div className="d-flex">
                <div style={{ width: "10%" }}>
                  <input type="radio" name="planPay" value={e.type} checked={optionPago === e.type } onChange={handlePlanChange} />
                  {/* <ladel>{optionPago}</ladel> */}
                </div>
                <div />
                <div style={{ width: "90%" }}>
                  <div
                    className="fw-bold d-flex"
                    style={{ color: "#394F65", fontSize: "1.5vmin" }}
                  >
                    <div className="my-auto">{e.type}</div>
                    {e.ahorro ? (
                      <button
                        className="d-flex ms-1"
                        // onClick={() => navigate("/Usuario/Inicio")}
                        style={{
                          background: "transparent",
                          border: "1px solid var(--elevva)",
                          borderRadius: "20px",
                          color: "var(--elevva)",
                          fontWeight: "400",
                          fontSize: "1.5vmin",
                        }}
                      >
                        Ahorra {e.ahorro}{" "}
                      </button>
                    ) : null}
                  </div>
                  <div style={{ color: "#A9B6C4", fontSize: "1.5vmin" }}>
                    {e.includes}
                  </div>
                  <div
                    className="fw-bold"
                    style={{ color: "#394F65", fontSize: "1.5vmin" }}
                  >
                    {e.price}
                  </div>
                  <hr />
                </div>
              </div>
            ))}
          </div>
          <div style={{ marginLeft: "10%", marginBottom: "6vmin" }}>
            {" "}
            <div className="fw-bold" style={{ color: "#394F65" }}>
              ¿Tienes un código?
            </div>
            <div style={{ width: "30vmin" }}>
              <input
                style={{
                  lineHeight: "0",
                  fontSize: "1.5vmin",
                  padding: "1vmin 10px",
                }}
                placeholder={"Redímelo aquí"}
                name={"last_name"}
                type="text"
                className="form-control input-style-elevva-pay my-1"
                // onChange={handleChange}
                value={redimeloCodigo}
                onChange={e => setRedimeloCodigo(e.target.value)}
                required
              ></input>
            </div>
          </div>
        </div>
        <div
          className="w-50  d-flex mx-auto"
          style={{
            background: "#F4F0ED",
            borderRadius: "15px",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0 4vmin",
          }}
        >
        <fom
          onSubmit={enviarDataForm}>
          <div className="my-3" style={{ color: "#394F65", fontWeight: "700" }}>
            {" "}
            Información de pago
          </div>
          <div style={{ width: "40vmin" }}>
            <input
              style={{
                lineHeight: "0",
                fontSize: "1.5vmin",

                margin: "1vmin 0",
              }}
              placeholder={"Nombre del titular"}
              name={"last_name"}
              type="text"
              className="form-control input-style-elevva-pay "
              // onChange={handleChange}
              value={nombrePago}
              onChange={e => setNombrePago(e.target.value)} 
              required
            ></input>
            <input
              style={{
                lineHeight: "0",
                fontSize: "1.5vmin",
                margin: "1vmin 0",
              }}
              placeholder={"Correo electronico"}
              name={"last_name"}
              type="text"
              className="form-control input-style-elevva-pay "
              // onChange={handleChange}
              value={correoPago}
              onChange={e => setCorreoPago(e.target.value)}
              required
            ></input>
            <input
              style={{
                lineHeight: "0",
                fontSize: "1.5vmin",
                margin: "1vmin 0",
              }}
              placeholder={"Titular de la tarjeta"}
              name={"last_name"}
              type="text"
              className="form-control input-style-elevva-pay "
              // onChange={handleChange}
              value={titularPago}
              onChange={e => setTitularPago(e.target.value)}
              required
            ></input>
            <input
              style={{
                lineHeight: "0",
                fontSize: "1.5vmin",
                margin: "1vmin 0",
              }}
              placeholder={"Número de la tarjeta*"}
              name={"last_name"}
              type="text"
              className="form-control input-style-elevva-pay"
              // onChange={handleChange}
              value={numeroPago}
              onChange={e => setNumeroPago(e.target.value)}
              required
            ></input>
            <div className="d-flex mx-auto " style={{ margin: "1vmin 0" }}>
              <input
                style={{ lineHeight: "0", fontSize: "1.5vmin", width: "49%" }}
                placeholder={"Código de seguridad*"}
                name={"last_name"}
                type="text"
                className="form-control input-style-elevva-pay  mx-auto"
                // onChange={handleChange}
                onChange={e => setCodigoPago(e.target.value)}
                required
              ></input>
              <input
                style={{ lineHeight: "0", fontSize: "1.5vmin", width: "24%" }}
                placeholder={"Mes"}
                name={"last_name"}
                type="number"
                className="form-control input-style-elevva-pay  mx-auto"
                // onChange={handleChange}
                value={mmPago}
                onChange={e => setMmPago(e.target.value)}
                required
              ></input>
              <input
                style={{ lineHeight: "0", fontSize: "1.5vmin", width: "24%" }}
                placeholder={"Año"}
                name={"last_name"}
                type="text"
                className="form-control input-style-elevva-pay  mx-auto"
                // onChange={handleChange}
                value={aaPago}
                onChange={e => setAaPago(e.target.value)}
                required
              ></input>
            </div>

            <div className="d-flex mx-auto my-2">
              <div className="mx-1">
                <input type="checkbox" id="recuerdamePago" checked={recuerdamePago} onChange={(e) => setrecuerdamePago(e.target.checked)}></input>
              </div>
              <div
                className="mx-1 my-auto"
                style={{ color: "#9DACBD", fontSize: "1.5vmin" }}
              >
                Recuérdame para agilizar mis próximas compras
              </div>
            </div>

            <div style={{ maxWidth: "25vmin" }}>
              <hr />
            </div>
            <div className="d-flex">
              <span
                className="w-50"
                style={{ color: "#394F65", fontSize: "1.5vmin" }}
              >
                Subtotal
              </span>
              <span
                className="w-50"
                style={{ color: "#394F65", fontSize: "1.5vmin" }}
              >
                $150.000 cop
              </span>
            </div>
            <div style={{ maxWidth: "25vmin" }}>
              <hr />
            </div>

            <div className="d-flex">
              <span
                className="w-50"
                style={{ color: "#394F65", fontSize: "1.5vmin" }}
              >
                Impuesto
              </span>
              <span
                className="w-50"
                style={{ color: "#394F65", fontSize: "1.5vmin" }}
              >
                $0.0 cop
              </span>
            </div>
            <div style={{ maxWidth: "25vmin" }}>
              <hr />
            </div>
            <div className="d-flex">
              <span
                className="w-50 fw-bold"
                style={{ color: "#394F65", fontSize: "1.5vmin" }}
              >
                Total a pagar
              </span>
              <span
                className="w-50 fw-bold"
                style={{ color: "#394F65", fontSize: "1.5vmin" }}
              >
                $150.000 cop
              </span>
            </div>
            <div style={{ margin: "2vmin 0" }}>
              {" "}
              <button
                className="d-flex"
                //onClick={handleClick}
                style={{
                  background: "var(--elevva)",
                  border: "1px solid var(--elevva)",
                  borderRadius: "20px",
                  color: "var(--white)",
                  fontWeight: "400",
                  fontSize: "1.5vmin",
                  padding: "2% 12%",
                }}
                type="submit"
                // onClick={handleSubmit}
                onClick={enviarDataForm} 
              >
                Pagar <i className="ms-1 bi bi-c-circle"></i>
              </button>
            </div>

            <div
              style={{
                margin: "1vmin 0",
                lineHeight: "1.5vmin",
              }}
            >
              <span
                style={{
                  color: "#9DACBD",
                  fontSize: "1.5vmin",
                  fontWeight: "500",
                }}
              >
                Al dar click en "pagar" aceptas
              </span>{" "}
              <span
                style={{
                  color: "#CB3D82",
                  fontSize: "1.5vmin",
                  fontWeight: "500",
                }}
              >
                términos y condiciones de eleva, este respectivo plan
              </span>{" "}
              <span
                style={{
                  color: "#9DACBD",
                  fontSize: "1.5vmin",
                  fontWeight: "500",
                }}
              >
                y autorizas el tratamiento de datos personales
              </span>
            </div>
          </div>
          </fom>
        </div>
      </div>
    </div>
  );
};
export const ReservaCustomer = (props) => {
  const navigate = useNavigate();

  const [options, setoptions] = useState("reservafinal");
  function handleClick() {
    props.handleClick(null, true);
  }
  function optionsClick() {
    setoptions("seleccionar");
  }

  return (
    <div
      className="col-9 d-flex"
      style={{
        background: "white",
        height: "100%",
        padding: "2%",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "20px",
      }}
    >
      <div>
        <div style={{ height: "10%" }}>
          {" "}
          <div
            style={{
              color: "var(--elevvaTextTitle)",
              justifyContent: "center",
              padding: "0vmin 8vmin",
            }}
            className={"title  d-flex my-auto"}
          >
            <div
              className="d-flex"
              style={{
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className=" d-flex tabulete-active mx-auto">
                <div
                  className="centraNumberSteps fw-bold"
                  style={{ fontSize: "1.5vmin", width: "10px", color: "#ffff" }}
                >
                  1
                </div>
              </div>
            </div>

            <div
              className="linememberstep mx-1"
              style={{
                verticalAlign: "middle",
                width: "11vmin",
              }}
            >
              <hr style={{ color: "#7B86FA", opacity: "1" }} />
            </div>
            <div
              className="d-flex"
              style={{ flexDirection: "column", justifyContent: "center" }}
            >
              <div className=" d-flex tabulete-active mx-auto">
                <div
                  className="centraNumberSteps  fw-bold"
                  style={{ fontSize: "1.5vmin", color: "#ffff" }}
                >
                  2
                </div>
              </div>
            </div>
            <div
              className="linememberstep mx-1"
              style={{
                verticalAlign: "middle",
                width: "11vmin",
              }}
            >
              <hr style={{ color: "#7B86FA", opacity: "1" }} />
            </div>
            <div
              className="d-flex"
              style={{ flexDirection: "column", justifyContent: "center" }}
            >
              <div className=" d-flex tabulete-active mx-auto">
                <div
                  className="centraNumberSteps  fw-bold"
                  style={{ fontSize: "1.5vmin", color: "var(--white)" }}
                >
                  3
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "var(--elevvaTextTitle)",
              justifyContent: "center",
              padding: "0vmin 8vmin",
            }}
            className={"title  d-flex my-auto"}
          >
            <div
              className="d-flex"
              style={{
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className=" d-flex  mx-auto">
                <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
                  Confirma tu pago
                </div>
              </div>
            </div>

            <div
              className="linememberstep"
              style={{
                verticalAlign: "middle",
                width: "4.5vmin",
              }}
            ></div>
            <div
              className="d-flex"
              style={{ flexDirection: "column", justifyContent: "center" }}
            >
              <div className=" d-flex  mx-auto">
                <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
                  Selecciona
                </div>
              </div>
            </div>
            <div
              className="linememberstep"
              style={{
                verticalAlign: "middle",
                width: "6.0vmin",
              }}
            ></div>
            <div
              className="d-flex"
              style={{ flexDirection: "column", justifyContent: "center" }}
            >
              <div className=" d-flex  mx-auto">
                <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
                  Disfruta tu plan
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex " style={{ height: "90%" }}>
        <div
          className="col-6 d-flex "
          style={{
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
            padding: "2% 6%",
          }}
        >
          <img
            style={{ height: "100%", width: "100%" }}
            src={
              options == "final"
                ? process.env.PUBLIC_URL + "/img/mascarareservafinal.png"
                : options == "reservafinal"
                ? process.env.PUBLIC_URL + "/img/finalreserva.png"
                : process.env.PUBLIC_URL + "/img/marcaraEjemplo.png"
            }
          />
        </div>
        {options == "reservafinal" ? (
          <div
            className="col-6 d-flex"
            style={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "2% 2% 2% 0%",
            }}
          >
            <div className=" m-auto">
              <div
                style={{
                  color: "#394F65",
                  fontSize: "3.0vmin",
                  lineHeight: "100%",
                }}
              >
                Bienvenido a
              </div>
              <div
                style={{
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "3.5vmin",
                  whiteSpace: "nowrap",
                }}
              >
                Elevva
              </div>{" "}
              <hr />
              <div
                className="fw-600 mx-auto"
                style={{
                  background: "#B07EBC",
                  color: "white",
                  width: "45vmin",
                  fontSize: "2vmin",
                  padding: "3% 0",
                }}
              >
                ¡Disfruta <strong>tu plan!</strong>
              </div>
              <div
                className="d-flex"
                style={{
                  background: "#F4F0ED",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                  borderRadius: "25px",
                  padding: "5%",
                  width: "38vmin",
                  margin: "5vmin auto",
                }}
              >
                <span style={{ textAlign: "center", fontSize: "3vmin" }}>
                  <i className="bi bi-clock-history"></i>
                </span>

                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Plan de entrenamiento
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#D74688",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  elevva básico
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Diciembre 20 de 2022 / Enero 19 de 2023
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  7 días de cortesía
                </span>
              </div>
              <div
                className="d-flex mx-auto"
                style={{
                  border: "1px solid #9DACBD",
                  padding: "2%",
                  borderRadius: "20px",
                  width: "45vmin",
                }}
              >
                <div
                  className="my-auto"
                  style={{ padding: "8%", borderRight: "1px solid #9DACBD" }}
                >
                  <img
                    style={{ width: "4vmin" }}
                    src={process.env.PUBLIC_URL + "/img/personIconplan.png"}
                  />
                </div>
                <div style={{ padding: "4%" }}>
                  <span
                    style={{
                      color: "#9DACBD",
                      textAlign: "justify",
                      fontSize: "1.2vmin",
                    }}
                  >
                    Recuerda que puedes actualizar, modificar o cancelar tu
                    membresía en cualquier momento; solo debes acceder a la
                    sección de perfil, seleccionando{" "}
                    <span
                      style={{ color: "var(--elevva)", fontSize: "1.2vmin" }}
                    >
                      modificar tu plan
                    </span>
                  </span>
                </div>
              </div>
              <br />
              <div className="d-flex py-2" style={{ justifyContent: "center" }}>
                <button
                  // onClick={() => navigate("/Usuario/Inicio")}
                  onClick={() => navigate("/Usuario/Onboarding")}
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "400",
                    padding: "2% 12%",
                  }}
                >
                  Ir a <i class="bi bi-house"></i>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function UtilsName(props) {
  //console.log(props, "desde utils");
  return (
    <div>
      {/* <h3>Membresía seleccionada:</h3> */}
      <h3>Plan {props.selectedMembership ? props.selectedMembership : 'Gratuito'}</h3>    
      </div>
      
  );
}
export default UtilsName;


// export const ultisPrice = (props) => {
//   return (
//     <>
//       <span
//         className="w-50"
//         style={{ color: "#394F65", fontSize: "1.5vmin" }}
//       >
//         {props.fee}
//       </span>
//     </>
//   )
// }
