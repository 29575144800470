import {
   instance,
  ModalInfo,
  NuevaAxiosURL,
 
} from "../../utils/utils";
import React from "react";
import "../inicio/inicio.css";
import { useNavigate,  } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";



import {
  Paso1,
  Categorias,
  CategoriasMobile,
} from "../TutorialReserva/stepsHelp";
import {
  ButtonTutorial,
  ButtonTutorialReserva,
} from "../Dashboard/helpResponsive";
const ReservasAll = (props) => {
 const navigate = useNavigate();
const [trainings, setTrainings] = useState([]);
  const [tomorrow, setTomorrow] = useState("");
  const [Grupal, setGrupal] = useState(true);
  const [Intensity, setIntensity] = useState(10);
  const [Loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [Modal, setModal] = useState(null);
  const [BlockIndividuales, setBlockIndividuales] = useState(false);
  const [BlockGroupals, setBlockGroupals] = useState(false);
  function hideModal(){
    setModal(null)
  }
  function popUpBlock(){
    setModal(
      <ModalInfo
        error="false"
        handleClick={hideModal}
        textButtonConfirmar={"Mejorar mi plan"}
        classcorrecta={"recuerdaTitleBlock"}
        handleCancellcargue={hideModal}
        title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
        text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
        classesMsg="colorText_Modal"
      />
    );
  }
  useEffect(() => {
    instance
      .get(`${NuevaAxiosURL}trainings`)
      .then((response) => {
        setTrainings(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    if (localStorage.trainingVector) {
      let category = JSON.parse(localStorage.trainingVector);
      selectCategory(category);
    }

    const today = new Date();
    const tomorrowDate = new Date(today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const month = monthNames[tomorrowDate.getMonth()];
    const day = tomorrowDate.getDate();

    setTomorrow(`${month} ${day}`);
    if (localStorage.elevvaPageReserva) {
      if (localStorage.elevvaPageReserva == 2) {
        setPage(2);
      } else {
        setPage(1);
      }
      localStorage.removeItem("elevvaPageReserva");
    }
    instance
      .get(`booking/can_book`)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setBlockIndividuales(true);
        }
      });
      instance
      .get(`booking/group/can_book/`)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setBlockGroupals(true);
        }
      });
  }, []);

  function selectCategory(e) {
    if (e[0] == false && BlockIndividuales) {
      popUpBlock()
    };
    if (e[0] == true && BlockGroupals) {
      popUpBlock()
    }
    
    setGrupal(e[0]);
    setIntensity(e[1]);
    localStorage.setItem("trainingVector", JSON.stringify([e[0], e[1]]));
  }

  function selectCategoryMobile(e) {
    setGrupal(e[0]);
    setIntensity(e[1]);
    localStorage.setItem("trainingVector", JSON.stringify([e[0], e[1]]));
    if (e[0] == false && BlockIndividuales) {
      popUpBlock()
    }
     if (e[0] == false && !BlockIndividuales) {
      setPage(2);
    }
     if (e[0] == true && BlockGroupals) {
      popUpBlock()
    }if (e[0] == true && !BlockGroupals){

      setPage(2);
    }

  }

  // const selectOption = (
  //   refContainer,
  //   index,
  //   classess,
  //   arr,
  //   onlyone,
  //   pathHere
  // ) => {
  //   if (onlyone === true) {
  //     for (const iterator of arr) {

  //       iterator[2].current.classList = classess;
  //       iterator[2].current.active = false;
  //       iterator[3] = false;
  //     }
  //   }

  //   if (refContainer.current.active === true) {
  //     refContainer.current.classList = classess;
  //     refContainer.current.active = false;
  //   } else {
  //     refContainer.current.classList = classess + " active";
  //     refContainer.current.active = true;
  //   }
  //   if (pathHere === "out") {
  //     perlo();
  //   } else {
  //     return navigate(pathHere);
  //   }
  // };
  const selectReserva =
    trainings && trainings.filter((e) => e.is_valoration == true);
 function handleClick(e) {
    let filterTraining= trainings.filter((el) => el.id == e)[0].group;
    console.log(filterTraining);
    if(BlockIndividuales && !filterTraining){
      popUpBlock()
    } else if(BlockGroupals && filterTraining){
      popUpBlock()
    }  else{
      navigate(`/training/${e}`);
    }
  }

  // const Paso3 = (props) => {
  //   const trainers = props.trainers;
  //   return (
  //     <div
  //       className="d-flex col-9"
  //       style={{
  //         background: "white",
  //         height: "100%",

  //         borderRadius: "20px",

  //         justifyContent: "center",
  //       }}
  //     >
  //       <div
  //         className="col-6 d-flex"
  //         style={{
  //           position: "relative",
  //           justifyContent: "center",
  //           padding: "5%",
  //         }}
  //       >
  //         <img
  //           className="w-100"
  //           style={{ padding: "0 4%", backgroundImage: "cover" }}
  //           src={
  //             process.env.PUBLIC_URL + "/img/mascarareservafinal.png"
  //             // Aquí se pasa la propiedad cover_picture del objeto
  //           }
  //           alt=""
  //         />

  //         <div
  //           style={{ top: "11%", position: "absolute", left: "18%" }}
  //           className="w-100"
  //         >
  //           <div className="d-flex my-auto ">
  //             <div
  //               className=""
  //               style={{
  //                 background: "transparent",
  //                 border: "1px solid #A8C2D7",
  //                 padding: "1vmin",

  //                 height: "fit-content",
  //                 cursor: "pointer",
  //                 color: "#A8C2D7",
  //                 borderRadius: "10px",
  //               }}
  //             >
  //               <span style={{ fontSize: "1rem" }}>
  //                 <i className="bi bi-chevron-left"></i>
  //               </span>
  //             </div>
  //             <div className=" my-auto mx-2 d-flex flex-column gap-2 justify-content-center  ">
  //               <span
  //                 className="mt-2"
  //                 style={{ color: "#394F65", fontSize: "calc(0.7rem + 0.3vw)" }}
  //               >
  //                 Entrenamiento
  //               </span>
  //               <span
  //                 style={{
  //                   color: "#394F65",
  //                   fontWeight: "bold",
  //                   fontSize: "calc(1.3rem + 0.3vw)",
  //                 }}
  //               >
  //                 Zumba grupal
  //               </span>{" "}
  //               <button
  //                 style={{
  //                   background: "var(--elevva)",
  //                   border: "1px solid var(--elevva)",
  //                   borderRadius: "20px",
  //                   color: "var(--white)",
  //                   fontWeight: "300",
  //                   maxWidth: "60%",
  //                   padding: "1% 0%",
  //                 }}
  //               >
  //                 GRATIS
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         className="col-6 d-flex"
  //         style={{
  //           justifyContent: "center",
  //           flexDirection: "column",
  //           gap: "2%",
  //           padding: "5% 5% 5% 0",
  //         }}
  //       >
  //         <div
  //           className="col-6 d-flex w-100"
  //           style={{
  //             justifyContent: "center",
  //             flexDirection: "column",
  //             textAlign: "center",
  //             gap: "2%",
  //             padding: "0 4%",
  //           }}
  //         >
  //           <span
  //             style={{
  //               color: "#394F65",

  //               fontSize: "calc(1rem + 0.3vw)",
  //             }}
  //           >
  //             {" "}
  //             Selecciona tu
  //           </span>

  //           <span
  //             style={{
  //               color: "#394F65",
  //               fontWeight: "600",
  //               fontSize: "calc(1rem + 0.3vw)",
  //             }}
  //           >
  //             día y hora de clase
  //           </span>
  //           <div
  //             className="d-flex flex-column justify-content-strench h-100"
  //             style={{ overflowY: "auto" }}
  //             id={"SelectHourTutorial"}
  //           >
  //             {trainers &&
  //               trainers.map((trainer) => (
  //                 <div
  //                   key={trainer.id}
  //                   className="d-flex position-relative changecolortable my-1"
  //                   style={{
  //                     justifyContent: "space-around",
  //                     borderRadius: "40px",
  //                     textAlign: "center",
  //                     padding: "2.5%",
  //                   }}
  //                 >
  //                   <div
  //                     className="d-flex "
  //                     style={{
  //                       justifyContent: "center",
  //                       flexDirection: "column",
  //                       textAlign: "center",
  //                     }}
  //                   >
  //                     <span
  //                       style={{
  //                         textAlign: "left",
  //                         color: "#394F65",

  //                         fontSize: "calc(0.7rem + 0.2vw)",
  //                         whiteSpace: "nowrap",
  //                       }}
  //                     >
  //                       Prof. {trainer.name}
  //                     </span>
  //                     <span
  //                       style={{
  //                         textAlign: "left",
  //                         color: "#394F65",

  //                         fontSize: "calc(0.7rem + 0.2vw)",
  //                         whiteSpace: "nowrap",
  //                       }}
  //                     >
  //                       20 CUPOS{" "}
  //                     </span>
  //                   </div>
  //                   <div
  //                     className="d-flex"
  //                     style={{
  //                       justifyContent: "center",
  //                       flexDirection: "column",
  //                       textAlign: "center",
  //                       padding: "2%",
  //                     }}
  //                   >
  //                     {/* <span>{trainer.dia}</span>
  //   <span>{trainer.hora}</span> */}
  //                     {tomorrow}
  //                   </div>
  //                   <div className="my-auto d-flex  position-relative">
  //                     <input
  //                       name="radioteachers"
  //                       type="radio"
  //                       // onChange={() => handleTrainerChange(trainer)}
  //                     ></input>{" "}
  //                   </div>
  //                 </div>
  //               ))}
  //           </div>
  //           <div
  //             className="my-2"
  //             style={{
  //               color: "#394F65",
  //               fontWeight: "600",
  //               fontSize: "1.5vmin",
  //             }}
  //           >
  //             <span
  //               style={{
  //                 textAlign: "center",
  //                 color: "#394F65",

  //                 fontSize: "calc(0.7rem + 0.3vw)",
  //                 whiteSpace: "nowrap",
  //               }}
  //             >
  //               Costo total de tu reserva - 0 créditos
  //             </span>
  //           </div>
  //           <div className="my-0 ">
  //             <ButtonMob
  //               icon={"ms-2 bi bi-calendar3 right"}
  //               className=""
  //               text="Reserva"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const Paso4 = (props) => {
  //   return (
  //     <div
  //       className="d-flex col-9"
  //       style={{
  //         background: "white",
  //         height: "100%",

  //         borderRadius: "20px",

  //         justifyContent: "center",
  //       }}
  //     >
  //       <div
  //         className="col-6 d-flex"
  //         style={{
  //           position: "relative",
  //           justifyContent: "center",
  //           padding: "5%",
  //         }}
  //       >
  //         <img
  //           className="w-100"
  //           style={{ padding: "0 4%", backgroundImage: "cover" }}
  //           src={
  //             process.env.PUBLIC_URL + "/img/mascarareservafinal.png"
  //             // Aquí se pasa la propiedad cover_picture del objeto
  //           }
  //           alt=""
  //         />

  //         <div
  //           style={{ top: "11%", position: "absolute", left: "18%" }}
  //           className="w-100"
  //         >
  //           <div className="d-flex my-auto "></div>
  //         </div>
  //       </div>
  //       <div
  //         className="col-6 d-flex"
  //         style={{
  //           justifyContent: "center",
  //           flexDirection: "column",
  //           gap: "1%",
  //           padding: "5% 5% 5% 0",
  //         }}
  //       >
  //         <div className="d-flex justify-content-center ">
  //           <div
  //             className=""
  //             style={{
  //               background: "transparent",
  //               border: "1px solid #A8C2D7",
  //               padding: "1vmin",

  //               height: "fit-content",
  //               cursor: "pointer",
  //               color: "#A8C2D7",
  //               borderRadius: "10px",
  //             }}
  //           >
  //             <span style={{ fontSize: "1rem" }}>
  //               <i className="bi bi-chevron-left"></i>
  //             </span>
  //           </div>
  //           <div className=" my-auto mx-2 d-flex flex-column justify-content-center  ">
  //             <span
  //               className="mt-2"
  //               style={{ color: "#394F65", fontSize: "calc(0.7rem + 0.3vw)" }}
  //             >
  //               Resumen
  //             </span>
  //             <span
  //               style={{
  //                 color: "#394F65",
  //                 fontWeight: "bold",
  //                 fontSize: "calc(1.3rem + 0.3vw)",
  //               }}
  //             >
  //               de tu reserva
  //             </span>{" "}
  //           </div>
  //         </div>{" "}
  //         <div className="d-flex justify-content-center flex-column gap-1">
  //           {props.trainers.slice(0, 2).map((trainer) => (
  //             <div
  //               id="Selectclickreserva"
  //               className="d-flex"
  //               style={{
  //                 background: "#F4F0ED",
  //                 justifyContent: "center",
  //                 flexDirection: "column",
  //                 borderRadius: "25px",
  //                 padding: "2%",

  //                 width: "100%",
  //               }}
  //             >
  //               <span
  //                 style={{
  //                   textAlign: "center",
  //                   fontSize: "calc(1rem + 0.3vw)",
  //                 }}
  //               >
  //                 <i className="bi bi-clock-history"></i>
  //               </span>

  //               <span
  //                 style={{
  //                   textAlign: "center",
  //                   color: "#394F65",

  //                   fontSize: "calc(0.5rem + 0.3vw)",
  //                   whiteSpace: "nowrap",
  //                 }}
  //               >
  //                 {/* <span>Prof. {trainer[0].name}</span> */}

  //                 {trainer.name}
  //               </span>
  //               <span
  //                 style={{
  //                   textAlign: "center",
  //                   color: "#D74688",

  //                   fontSize: "calc(0.5rem + 0.3vw)",
  //                   whiteSpace: "nowrap",
  //                 }}
  //               >
  //                 Zumba grupal
  //               </span>
  //               <span
  //                 style={{
  //                   textAlign: "center",
  //                   color: "#394F65",

  //                   fontSize: "calc(0.5rem + 0.3vw)",
  //                   whiteSpace: "nowrap",
  //                 }}
  //               >
  //                 Agosto 17 de 2020 / 9:00 a.m / 10:00 a.m
  //               </span>
  //             </div>
  //           ))}

  //           <hr />
  //           <div>
  //             <div className="d-flex justify-content-center col-12">
  //               <div
  //                 className="col-6"
  //                 style={{
  //                   textAlign: "right",
  //                   fontSize: "calc(0.5rem + 0.3vw)",
  //                 }}
  //               >
  //                 2 clases:
  //               </div>
  //               <div
  //                 className="col-6"
  //                 style={{ fontSize: "calc(0.5rem + 0.3vw)" }}
  //               >
  //                 0 creditos
  //               </div>
  //             </div>
  //             <div className="d-flex justify-content-center col-12">
  //               <div
  //                 className="col-6"
  //                 style={{
  //                   textAlign: "right",
  //                   fontSize: "calc(0.5rem + 0.3vw)",
  //                 }}
  //               >
  //                 Descuento:
  //               </div>
  //               <div
  //                 className="col-6"
  //                 style={{ fontSize: "calc(0.5rem + 0.3vw)" }}
  //               >
  //                 0 creditos
  //               </div>
  //             </div>
  //             <div className="d-flex justify-content-center col-12">
  //               <div
  //                 className="col-6"
  //                 style={{
  //                   textAlign: "right",
  //                   fontSize: "calc(0.5rem + 0.3vw)",
  //                 }}
  //               >
  //                 Créditos restantes:
  //               </div>
  //               <div
  //                 className="col-6"
  //                 style={{ fontSize: "calc(0.5rem + 0.3vw)" }}
  //               >
  //                 0 creditos
  //               </div>
  //             </div>
  //           </div>

  //           <div className="d-flex py-2" style={{ justifyContent: "center" }}>
  //             <ButtonMob
  //               icon={"ms-2 bi bi-calendar3 right"}
  //               className=""
  //               text="Reserva"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const moment = extendMoment(Moment);

  return (
    <>
      {Modal}
      <div className="col-10 d-none d-md-block" style={{ height: "100%" }}>
        <div
          className=" position-relative "
          style={{
            background: "rgba(0,0,0,.7)",
            height: "100vh",

            padding: "2% 3% 2% 2%",
          }}
        >
          <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
            <Categorias categoryClick={(e) => selectCategory(e)} />
            <Paso1
              trainings={trainings}
              BlockIndividuales={BlockIndividuales}
              typeClasses={Grupal}
              intensity={Intensity}
              loading={Loading}
              handleClick={handleClick}
            />
            <ButtonTutorial
              handleClick={() =>
                navigate(`/TutorialReserva/${selectReserva[0].id}`)
              }
            />
          </div>
        </div>
      </div>
      <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        {page == 1 ? (
          <>
            <CategoriasMobile categoryClick={(e) => selectCategoryMobile(e)} />
            <ButtonTutorialReserva
              handleClick={() =>
                navigate(`/TutorialResponsive/${selectReserva[0].id}`)
              }
            />
          </>
        ) : (
          <Paso1
            idJoyride={"totalClasesMobile"}
            trainings={trainings}
            typeClasses={Grupal}
            intensity={Intensity}
            loading={Loading}
            handleClick={handleClick}
            backClick={() => setPage(1)}
          />
        )}
      </div>
    </>
  );
};
export default ReservasAll;
