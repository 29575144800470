import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";

import "swiper/swiper-bundle.min.css";
import { laptop575, Loadingspinner } from "../../utils/utils";

import ReactPlayer from "react-player";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

export function Videos_two(props) {
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const imagenes = props.videosTime;
  const idTutorial = props.idTutorial || "";
  const stepIndex = props.stepIndex || 0;
  const [VideoDurationList, setVideoDurationList] = useState([]);
  const onlytrue = props.onlytrue || null;
  const id = props.id;


  const handleDuration = (duration, index) => {
    console.log(duration)
    setVideoDurationList((prevList) => {
      const updatedList = [...prevList];
      updatedList[index] = duration;
      
     
      return updatedList;
    });
  };
  const playerRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState(imagenes&&Array(imagenes[0].videos.length).fill(true));
  const [loadingVideos, setLoadingVideos] = useState(Array(imagenes[0].videos.length).fill(true));
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {

    
if(loadingVideos.length>0)
  { 
      if(currentIndex === loadingVideos.length ){
       return // Volver al inicio si llegamos al final del array
      } else {
        const newArray = [...loadingVideos]; // Crear una copia del array
        newArray[currentIndex] = false; // Cambiar el elemento actual a false
        let newCurrency= currentIndex + 1;
        let time=1000
        setTimeout(() => {
        setCurrentIndex(newCurrency)
        
            setIsPlaying(newArray); // Actualizar el estado del array
            setLoadingVideos(newArray)
            
          }, time);
      }}

  
    
  },[currentIndex])

  const textTitleOne = props.textTitleOne || null;
  const textTitleTwo = props.textTitleTwo || null;
  if (imagenes) {
    var unificar = [];
    for (let i of imagenes) {
      
      i.videos
   
        .map((e) => {
          e.id_padre =i.id;
          unificar.push(e)});
    }
   
  }


  
  const handleHover = (index) => {
    setIsPlaying((prevList) => {
      const updatedList = [...prevList];
    
      updatedList[index] = true;
      return updatedList;
    });

  };

  const handleLeave = (index) => {
    setIsPlaying((prevList) => {
      const updatedList = [...prevList];
      updatedList[index] = false;
      return updatedList;
    });
  };
  const render_img =
    unificar &&
    unificar
      
      .map((imagen, index) => {
        const def_img = imagen;

        return (
          <SwiperSlide 
            key={index}
            className="sliderNetFlixvideorozo py-1"
            style={
              laptop575.matches
                ? { borderRadius: "20px" }
                : { borderRadius: "20px", width: "65%" }
            }
          > 
          <div className={loadingVideos[index]?"d-flex":"d-none"}>
               <Loadingspinner  customStyle="grow" size="2rem"/> 
               </div>
            <div style={{textDecoration:"none"}} id={`video${def_img.id}`}   className={loadingVideos[index]?"d-none ":"content position-relative"}
               
              onClick={()=>window.location.href=(`/videos/card/${def_img.id_padre}/${def_img.id}`)
                
              }
            >
              
              <div  className={"position-relative playervideo__Optional"}   >
                <ReactPlayer style={{borderRadius:"20px"}}
                  className={"playervideo" } 
                  config={{
                      file: {
                        attributes: {
                          preload: "true",
                        },
                      },
                    }}
                  url={def_img.file}
                  playing={isPlaying[index]}
                  muted={true}
                  light={
                    !isPlaying[index]?def_img.background:false 
                      }
                  width={"100%"}
                  height="100%"
              controls={laptop575.matches?!isPlaying[index]:false}
               onMouseEnter={() => laptop575.matches? handleHover(index):null}
             onMouseLeave={() =>laptop575.matches? handleLeave(index):null}
                  
                  onDuration={(duration) => handleDuration(duration, index)}
                />
            
              </div>
              <div
                className="d-flex py-2 px-2"
                style={{ justifyContent: "space-between" }}
              >
                <span style={{ color: "#394F65" }}>{def_img.name}</span>
               {VideoDurationList[index]? <span className="fw-bold" style={{ color: "#CB3D82",whiteSpace:"nowrap" }}>
                   {Math.round(Number(VideoDurationList[index])/60)} min
                </span>:<span className="fw-bold" style={{ color: "#CB3D82",whiteSpace:"nowrap" }}>
                   60 min
                </span>}
              </div>
            </div>
          </SwiperSlide>
        );
      });

  return laptop575.matches ? (
    <>
      <div id={"demo2"} className="position-relative reconocer1 pt-3">
        <div
          className="d-flex swiper-pagination_p  my-2 "
          style={{ justifyContent: "space-between" }}
        >
          <h2 style={{ color: "#394F65", fontSize: "25px" }}>
            <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
            <strong> {textTitleTwo}</strong>
          </h2>

          <div className={"d-flex"}>
            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            <div className={`swiper-button-next-${id} tonin-next`}></div>
          </div>
        </div>
        <div style={{ height: "180px" }}>
          {" "}
          <Swiper id={idTutorial}
            data-id={"demo" + id}
            navigation={{
              nextEl: `.swiper-button-next-${id}`,
              prevEl: `.swiper-button-prev-${id}`,
            }}
            className="slidersNetflixReservas "
            spaceBetween={15}
            slidesPerView="auto"
            slidesOffsetBefore={10}
            slidesOffsetAfter={30}
            slidesPerGroup={1}
            slidesPerGroupSkip={1}
            onUpdate={(swiper) => swiper.updateSlides()}
          >
            {render_img}
          </Swiper>
        </div>
      </div>
    </>
  ) : (
    <>
      <div id={"demo2"} className="position-relative reconocer1 mb-4">
        <div
          className="d-flex swiper-pagination_p  my-4 "
          style={{ justifyContent: "space-between" }}
        >
          <h2 style={{ color: "#394F65", fontSize: "20px" }}>
            <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
            <strong> {textTitleTwo}</strong>
          </h2>

          <div className={"d-flex"}>
            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            <div className={`swiper-button-next-${id} tonin-next`}></div>
          </div>
        </div>
      </div>
      <Swiper
        data-id={"demo2"}
        navigation={{
          nextEl: `.swiper-button-next-${id}`,
          prevEl: `.swiper-button-prev-${id}`,
        }}
        className="slidersNetflixReservas "
        spaceBetween={15}
        slidesPerView="auto"
        slidesOffsetBefore={10}
        slidesOffsetAfter={30}
        slidesPerGroup={1}
        slidesPerGroupSkip={1}
        onUpdate={(swiper) => swiper.updateSlides()}
      >
        {render_img}
      </Swiper>
      <br />
    </>
  );
}
