
import React, { useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {ReactComponent as MisclasesIcon} from "../assets/newIcons/LAT-CLASES-A.svg";
import {ReactComponent as Home} from "../assets/newIcons/LAT-HOME-A.svg";
import {ReactComponent as Perfil} from "../assets/newIcons/LAT-PERFIL-A.svg";
import {ReactComponent as Videos} from "../assets/newIcons/LAT-VIDEOS-A.svg";
import {ReactComponent as Out} from "../assets/newIcons/LAT-APAGADO-A.svg";
import {ReactComponent as Terminos} from "../assets/newIcons/LAT-TERMINOS-A.svg";
import {ReactComponent as Faqs} from "../assets/newIcons/LAT-PREGUNTAS-A.svg";
import {ReactComponent as Equipos} from "../assets/newIcons/LAT-COMPRA-EQUIPOS-A.svg";
import {ReactComponent as Books} from "../assets/newIcons/LAT-RESERVAS-A.svg";
import { checkPathNavFoot, checkPathNavFootMobile, laptop575, perlo } from "../utils/utils";
export const  Allitems=(props)=>{
  const navigate = useNavigate();
  const typeCustomer = props.typeCustomer || null;
  const location = useLocation();
 const Barra = [
    [
      "Mi progreso",
      <Home width={"100%"} />,
      useRef(null),
      useRef(null),
      "/Dashboard",
    ],
    [
      "Mi perfil",
      <Perfil width={"100%"} />,
      useRef(null),
      useRef(null),
      "/perfil",
    ],
    [
      "Videos de bienestar y entrenamiento",
      <Videos width={"100%"} />,
      useRef(null),
      useRef(null),
      "/videos",
    ],
    [
      "Reserva de clases",
      <Books width={"100%"} />,
      useRef(null),
      useRef(null),
      "/reservas",
    ],
    [
      "Mis clases",
      <MisclasesIcon width={"100%"} />,
      useRef(null),
      useRef(null),
      "/misclases/all",
    ],
    [
      "Adquiere equipos",
      <Equipos width={"100%"} />,
      useRef(null),
      useRef(null),
      "Equipos",
    ],
    [
      "Preguntas frecuentes",
      <Faqs width={"100%"} />,
      useRef(null),
      useRef(null),
      "/faqs",
    ],
    [
      "Términos y condiciones políticas de privacidad",
      <Terminos width={"100%"} />,
      useRef(null),
      useRef(null),
      "/tyc",
    ],
    [
      "Cerrar sesión",
      <Out width={"100%"} />,
      useRef(null),
      useRef(null),
      "out",
    ],
  ];
  useEffect(() => {
    if (!checkPathNavFoot(location.pathname, typeCustomer)) {
      var activeme = Barra.forEach((e) => {
        if (e[4] == location.pathname) {
          e[2].current.classList = "d-flex option-page fw-bold  paddingNav_list active";
          e[2].current.active = true;
        } else {
          e[2].current.classList = "d-flex option-page fw-bold  paddingNav_list ";
          e[2].current.active = false;
        }
      });
   
    }
    if (!checkPathNavFootMobile(location.pathname, typeCustomer)) {
      var activeme = Barra.forEach((e) => {
        if (e[4] == location.pathname) {
          e[2].current.classList = "d-flex option-page fw-bold  paddingNav_list active";
          e[2].current.active = true;
        } else {
          e[2].current.classList = "d-flex option-page fw-bold  paddingNav_list ";
          e[2].current.active = false;
        }
      });
      
    }
  }, [location]);
  const selectOption = (
    refContainer,
    index,
    classess,
    arr,
    onlyone,
    pathHere
  ) => {
    if (onlyone === true) {
      for (const iterator of arr) {
        
        iterator[2].current.classList = classess;
        iterator[2].current.active = false;
        iterator[3] = false;
      }
    }

    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (pathHere == "out") {
      perlo();
    } if(pathHere == "Equipos"){
      refContainer.current.classList = classess + " active";
      window.open("https://supremacyequipment.com/")
    }else {
      return navigate(pathHere);
    }
  };

  return(  <div
    className=" w-100"
    style={{
      height: "auto",
    }}
  >
    {Barra.map((elementos, index) => (
      <div data-bs-dismiss={laptop575.matches?"":"offcanvas"}
        key={index}
        id={`Barra` + index}
        ref={elementos[2]}
        style={{ fontWeight: "600" }}
        className="d-flex option-page text-color-title"
        onClick={() =>
          selectOption(
            elementos[2],
            index,
            "d-flex option-page fw-bold paddingNav_list",
            Barra,
            true,
            elementos[4]
          )
        }
      >
        <div className="my-auto mx-2 Iconresponsive d-flex h-100 col-2 col-md-3">{elementos[1]} </div>
        <div className="mx-2 letraPequeña col-10 col-md-9">{elementos[0]} </div>
      </div>
    ))}
  </div>)

}
