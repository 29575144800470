import React, { useState, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";


import { ButtonMob, laptop575 } from "../../utils/utils";
import { useNavigate, Link } from "react-router-dom";

import { useEffect } from "react";
import {
  faVolumeDown,
  faVolumeMute,
  faVolumeOff,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";

export function Allvideos(props) {
  const images = props.videos;
  const navigate = useNavigate();
  const contenido = props.contenido;
  const textTitleOne = props.textTitleOne || null;
  const textTitleTwo = props.textTitleTwo || null;
  const id = props.id;
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const render_img_desk =
    images &&
    images.map((imagen, index) => {
   
      return (
        <SwiperSlide
          key={index}
          className={props.className}
          style={laptop575.matches ? { width: "45vmin" } : null}
        >
          <div className="d-flex position-relative">
            {laptop575.matches ? (
              <div style={{ minWidth: "200px" }}>
                <img
                  style={
                    index + 1 == 1
                      ? { top: "0", left: "22%", position: "absolute" }
                      : { top: "0", left: "13%", position: "absolute" }
                  }
                  src={process.env.PUBLIC_URL + `/img/${index + 1}.png`}
                  alt=""
                />
              </div>
            ) : (
              <div style={{ minWidth: "120px" }}>
                <img
                  style={
                    index + 1 == 1
                      ? {
                          top: "0",
                          left: "12%",
                          position: "absolute",
                          width: "25vmin",
                        }
                      : {
                          top: "0",
                          left: "10%",
                          position: "absolute",
                          width: "30vmin",
                        }
                  }
                  alt=""
                  src={process.env.PUBLIC_URL + `/img/${index + 1}.png`}
                />
              </div>
            )}

            <Link
              to={
                contenido == "vivo"
                  ? `/videos/reservando/${imagen.id_clase}`
                  : `/videos/info/${imagen.id}`
              }
              className="content position-relative"
              style={{ minWidth: "233px" }}
            >
              
              <img
                src={process.env.PUBLIC_URL + `${imagen.thumbnail}`}
                style={{ borderRadius: "20px" }}
                width={laptop575.matches ? "100%" : "70%"}
                height="100%"
                alt=""
              />
              {laptop575.matches ? (
                <div
                  className="position-absolute w-100"
                  style={
                    imagen.category != "vivo"
                      ? {
                          top: "0",
                          color: "#394F65",
                          padding: "8%",
                        }
                      : {
                          top: "0",
                          color: "#ffff",
                          zIndex: "2",
                          background: "rgba(0, 0, 0, 0.5)",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          padding: "8%",
                        }
                  }
                >
                  {imagen.subcategory}

                  <br />
                  <span
                    className="fw-bold"
                    style={{ fontSize: "20px", lineHeight: "100%" }}
                  >
                    {imagen.name}
                  </span>
                </div>
              ) : (
                <div
                  className="position-absolute"
                  style={
                    imagen.category != "vivo"
                      ? {
                          top: "0",
                          color: "#394F65",
                          padding: "8%",
                          width: "70%",
                          fontSize: "13px",
                        }
                      : {
                          fontSize: "13px",
                          top: "0",
                          color: "#ffff",
                          zIndex: "2",
                          width: "70%",
                          background: "rgba(0, 0, 0, 0.5)",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          padding: "8%",
                        }
                  }
                >
                  {imagen.subcategory}

                  <br />
                  <span
                    className="fw-bold"
                    style={{ fontSize: "13px", lineHeight: "100%" }}
                  >
                    {imagen.name}
                  </span>
                </div>
              )}
              {imagen.available == false ? (
                <div
                  className="w-100 h-100"
                  style={{
                    top: "0",
                    position: "absolute",
                    color: "#ffff",
                    background: "rgba(235, 237, 239, 0.8)",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="position-absolute w-100 mx-auto d-flex">
                    <span
                      className="p-2 fw-bold w-100"
                      style={{
                        color: "#9EACED",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      Disponible
                      <br /> próximamente
                    </span>
                  </div>
                </div>
              ) : null}
            </Link>
          </div>
        </SwiperSlide>
      );
    });

    
  return laptop575.matches ? (
    <div className="position-relative reconocer">
      <div
        className="d-flex swiper-pagination_p  my-3"
        style={{ justifyContent: "space-between" }}
      >
        <h2 style={{ color: "#394F65", fontSize: "25px" }}>
          <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
          <strong> {textTitleTwo}</strong>
        </h2>
        {id == "numbers" ? (
          <>
            <div
              className={"d-flex position-absolute"}
              style={{ top: "50%", left: "0%" }}
            >
              <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            </div>
            <div
              className={"d-flex position-absolute"}
              style={{ top: "50%", right: "2%" }}
            >
              <div className={`swiper-button-next-${id} tonin-next`}></div>
            </div>
          </>
        ) : (
          <div className={"d-flex"}>
            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            <div className={`swiper-button-next-${id} tonin-next`}></div>
          </div>
        )}
      </div>
      <Swiper
        style={{ margin: "auto 10% auto 5%" }}
        className="slidersNetflixReservas py-2"
        navigation={{
          nextEl: `.swiper-button-next-${id}`,
          prevEl: `.swiper-button-prev-${id}`,
        }}
        modules={[Navigation]}
        slidesPerView="auto"
        spaceBetween={150}
        slidesOffsetBefore={0}
        slidesOffsetAfter={220}
        slidesPerGroup={1}
        slidesPerGroupSkip={1}
      >
        {render_img_desk}
      </Swiper>
    </div>
  ) : (
    <>
      <div className="position-relative reconocer">
        <div
          className="d-flex swiper-pagination_p  my-3"
          style={{ justifyContent: "space-between" }}
        >
          <h2 style={{ color: "#394F65", fontSize: "25px" }}>
            <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
            <strong> {textTitleTwo}</strong>
          </h2>
          {id == "numbers" ? (
            <>
              <div
                className={"d-flex position-absolute"}
                style={{ top: "50%", left: "0%" }}
              >
                <div className={`swiper-button-prev-${id} tonin-prev`}></div>
              </div>
              <div
                className={"d-flex position-absolute"}
                style={{ top: "50%", right: "2%" }}
              >
                <div className={`swiper-button-next-${id} tonin-next`}></div>
              </div>
            </>
          ) : (
            <div className={"d-flex"}>
              <div className={`swiper-button-prev-${id} tonin-prev`}></div>
              <div className={`swiper-button-next-${id} tonin-next`}></div>
            </div>
          )}
        </div>
        <Swiper
          style={{ margin: "auto 10% auto 0%" }}
          className="slidersNetflixReservas py-2"
          navigation={{
            nextEl: `.swiper-button-next-${id}`,
            prevEl: `.swiper-button-prev-${id}`,
          }}
          modules={[Navigation]}
          slidesPerView="auto"
          spaceBetween={10}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          slidesPerGroup={1}
          slidesPerGroupSkip={1}
        >
          {render_img_desk}
        </Swiper>
      </div>
    </>
  );
}
export function AllImagenes(props) {
  const Innerwidth=props.Innerwidth || null
  const images = props.videos;
  const navigate = useNavigate();
  const textTitleOne = props.textTitleOne || null;
  const textTitleTwo = props.textTitleTwo || null;
  const id = props.id;
  const contenido = props.contenido;

  const [ModalInfo, setModalInfo] = useState(null);
  SwiperCore.use([Pagination, Autoplay, Navigation]);

  const render_img_desk =
    images &&
    images.map((imagen, index) => {
      return (
        <SwiperSlide
          key={index}
          className={props.className}
          style={laptop575.matches ? null : { maxWidth: "45vmin" }}
        >
          <div
            onClick={() =>
               navigate(`/videos/info/${imagen.id}`)
            }
            // setModalInfo(
            //   <Redirect
            //     push={true}
            //     to={
            //       contenido == "vivo" || imagen.category == "vivo"
            //         ? `/ejercicios/reservando/${imagen.id_clase}`
            //         : `/ejercicios/info/${imagen.id}`
            //     }
            //   />
            // )
            className="content position-relative"
          >
            <img
              src={imagen.thumbnail}
              style={
                laptop575.matches
                  ? { borderRadius: "20px" }
                  : { borderRadius: "20px", maxWidth: "45vmin" }
              }
              width={"100%"}
              height="100%"
            />
            
           
              {/* <div
                className="w-100 h-100"
                style={{
                  top: "0",
                  position: "absolute",
                  color: "#ffff",
                  background: "rgba(235, 237, 239, 0.8)",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                <div
                  className="position-absolute w-100 mx-auto d-flex"
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <span
                    className="p-2 fw-bold"
                    style={{ color: "rgb(57, 79, 101)", zIndex: "3" }}
                  >
                    Disponible
                    <br /> próximamente
                  </span>
                </div>
              </div> */}
            
            {laptop575.matches ? (
              <div
                className="position-absolute"
                style={
                 {
                        minHeight: "100px",
                        top: "0",
                        color: "#394F65",

                        borderRadius: "20px",
                        padding: "10%",
                        fontSize: "13px",
                      }
                }
              >
              
                  <div
                    className="d-flex "
                    style={{ justifyContent: "space-between" }}
                  >
                    <span style={
                    { fontSize: "1.3vmin", lineHeight: "100%" }
                  }> {imagen.main_topic == 10 ? "Entrenamiento" :"Nutrición"}</span>
                  </div>
                
                <span
                  className="fw-bold"
                  style={
                    { fontSize: "2vmin", lineHeight: "100%" }
                  }
                >
                  {imagen.name}
                </span>{" "}
                <br /> 
              </div>
            ) : (
              <div
                className="position-absolute"
                style={
                  {
                        top: "0",
                        color: "#394F65",
                        width: "100%",
                        borderRadius: "20px",
                        padding: "8%",
                        fontSize: "13px",
                        maxWidth: "45vmin",
                      }
                }
              >
                
                  <div
                    className="d-flex "
                    style={{ justifyContent: "space-between" }}
                  >
                    <span> {imagen.main_topic}</span> </div>
                
                <span
                  className="fw-bold"
                  style={
                   { fontSize: "15px", lineHeight: "100%" }
                  }
                >
                  {imagen.name}
                </span>{" "}
                <br /> 
              </div>
            )}
          </div>
        </SwiperSlide>
      );
    });
  const render_img_mobile =
    images &&
    images.map((imagen, index) => {
      
      return (
        <SwiperSlide
          key={index}
          className={props.className}
          style={{ maxWidth: "45vmin" }}
        >
          <div w-100 mx-auto className="content position-relative">
            <Link
              to={
              
                  `/videos/info/${imagen.id}`
              }
            >
              <img
                src={ imagen.thumbnail}
                style={{ borderRadius: "20px", maxWidth: "45vmin" }}
                width={"100%"}
                height="100%"
                alt=""
              />
               <div
                className="position-absolute"
                style={
                 
                    {
                        top: "0",
                        color: "#394F65",
                        width: "100%",
                        borderRadius: "20px",
                        padding: "8%",
                      
                        maxWidth: "45vmin",
                      }
                }
              >
                
                  <div
                    className="d-flex "
                    style={{ justifyContent: "space-between" }}
                  >
                    <span style={{  fontSize: "13px"}}> {imagen.main_topic == 10 ? "Entrenamiento" :"Nutrición"}</span>

                  
                  </div>
                
                <span
                  className="fw-bold"
                  style={
                   
                       { fontSize: "19px", lineHeight: "100%" }
                  }
                >
                  {imagen.name}
                </span>{" "}
                <br />
              </div>
            </Link>
          </div>
        </SwiperSlide>
      );
    });

    function rezizeArrows(){
     if(Innerwidth&&images) {
let cardsSize=(images.length * 182);
let diference= Innerwidth - cardsSize;

if(diference > 100){
return true
}else {
  return false
}

     }else return true
    }

  return laptop575.matches ? (
    <>
      <div className="position-relative reconocer">
        <div
          className="d-flex swiper-pagination_p  my-4 tomesamba"
          style={{ justifyContent: "space-between",paddingLeft:textTitleOne &&textTitleOne.length > 0?"5%":'0' }}
        >
          
            <h2 style={{ color: "#394F65", fontSize: "25px" }}>
              <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
              <strong> {textTitleTwo}</strong>
            </h2>
          

         {rezizeArrows(Innerwidth,images) ? null: <div className=" d-flex">
            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            <div className={`swiper-button-next-${id} tonin-next`}></div>
          </div>}
        </div>

        <Swiper
          style={id != "vertipo" ? { margin: "auto 5%" } : null}
          className="slidersNetflixReservas py-0"
          navigation={{
            nextEl: `.swiper-button-next-${id}`,
            prevEl: `.swiper-button-prev-${id}`,
          }}
          modules={[Navigation]}
          slidesPerView="auto"
          // loop={true}
          spaceBetween={10}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          slidesPerGroup={1}
          slidesPerGroupSkip={1}
        >
          {render_img_desk}
        </Swiper>
      </div>
      {ModalInfo}
    </>
  ) : (
    <>
      <div className="position-relative reconocer">
        <div
          className="d-flex swiper-pagination_p  my-4 tomesamba"
          style={{ justifyContent: "space-between",paddingLeft: "5%" }}
        >
       
            <h2
              style={
                { color: "#394F65", fontSize: "25px" }
              }
            >
              <span style={{ fontWeight: "400" }}>{textTitleOne}</span>
             
              <strong> {textTitleTwo}</strong>
            </h2>
       

            {rezizeArrows(Innerwidth,images) ? null:  <div className=" d-flex">
            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            <div className={`swiper-button-next-${id} tonin-next`}></div>
          </div>}
        </div>
      </div>
      <Swiper
        className="pt-2 pb-4"
        modules={[Navigation]}
        navigation={{
          nextEl: `.swiper-button-next-${id}`,
          prevEl: `.swiper-button-prev-${id}`,
        }}
     
        spaceBetween={10}
        slidesPerView="auto"
        
        slidesOffsetBefore={10}
        slidesOffsetAfter={10}
     
      >
        {render_img_mobile}
      </Swiper>{" "}
    </>
  );
}

export function AllvideosBanner(props) {
  const videoEl = useRef(null);
  const navigate = useNavigate();
 const vid=props.videos;

  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const [ModalInfo, setModalInfo] = useState(null);
  const [deseoplayer1, setdeseoplayer1] = useState(false);
  const [mutedOption, setmutedOption] = useState(false);
  const [theSwipper, setTheSwipper] = useState(null);
  const bannersele1 = document.querySelector(".swiper-wrapper");
  useEffect(() => {
    if (theSwipper) {
      theSwipper.slideTo(1, 0, true);
    
    }
  }, [vid]);
  useEffect(() => {
    if (theSwipper) {
      theSwipper.slideTo(props.swipperchange, 0, true);
    
    }
  }, [props.swipperchange]);

  function func1() {
    if (deseoplayer1 == false) {
      setdeseoplayer1(true);
    }
  }
  function funcout1() {
    if (deseoplayer1) {
      setdeseoplayer1(false);
    }
  }
  useEffect(() => {
    if (laptop575.matches) {
      if (theSwipper && theSwipper.activeIndex) {
        const wrapper = document.querySelector(".swiper-wrapper");

        var videoElem1 =
          wrapper.children[theSwipper.activeIndex].querySelector("video");
          console.log(videoElem1)
        if (videoElem1) {
          if (deseoplayer1) {
            if (videoElem1.paused) {
              playVideo();
            } else {
              videoElem1.pause();
            }
            async function playVideo() {
              try {
                await videoElem1.play();
              } catch (err) {
                console.log(err);
              }
            }

            playVideo();
          } else {
            videoElem1.pause();
          }
        }
      }
    }
  }, [deseoplayer1]);

  bannersele1 &&
    bannersele1.addEventListener("mousemove", func1, { once: true });

  bannersele1 &&
    bannersele1.addEventListener("mouseleave", funcout1, { once: true });



  const render_img_desk =vid&&vid.filter(ele=>ele.show_in_banner ==true && ele.banner).map((imagen, i) => {
    
 return (
        <SwiperSlide key={"txc" + i} className="sliderNetFlixvideoNew">
          <div
            id={`banner${imagen.id}`}
            style={deseoplayer1? {
                    zIndex: "1",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    // minHeight: "calc(80vmin - 0rem)",
                  }
                : {
                    position: "relative",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundRepeat: "no-repeat",
                    // minHeight: "calc(80vmin - 0rem)",
                  }
            }
            className={"content mx-auto d-flex"}
          > 
             <video
              muted={mutedOption}
              style={{
                width: "100%",
                margin: "0 auto",
                height: "100%",
              }}
              preload="auto"
              playsInline
              loop
              alt="All the devices"
              ref={videoEl}
            >
              <source src={imagen.banner} type="video/mp4" />
            </video>
           <button
              className="position-absolute d-flex"
              onClick={() => setmutedOption(!mutedOption)}
              style={{
                bottom: "19%",
                right: "7%",
                background: "transparent",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                borderRadius: "50%",
                padding: "1vmin",
                width: "6vmin",
                height: "6vmin",
                zIndex: "10",
                border: "2px solid white",
              }}
            >
              <FontAwesomeIcon
                icon={mutedOption ? faVolumeMute : faVolumeUp}
                style={
                  mutedOption
                    ? { color: "white", width: "3.5vmin", height: "3.5vmin" }
                    : { color: "white", width: "4vmin", height: "4vmin" }
                }
              />
            </button>
            <div
              className="position-absolute w-100"
              style={{
                background: "rgba(0, 0, 0, 0.2)",
                width: "100%",
                height: "100%",
                zIndex: "1",
              }}
            ></div>
            <div
              className="position-absolute d-flex "
              style={
                laptop575.matches
                  ? {
                      flexDirection: "column",
                      justifyContent: "center",
                      left: "12vmin",
                      zIndex: "2",
                      maxWidth: "50vmin",
                      color: "white",
                    }
                  : {
                      flexDirection: "column",
                      justifyContent: "center",
                      left: "12vmin",
                      zIndex: "2",
                      width: "35vmin",

                      color: "white",
                    }
              }
            >
              <span style={{ fontSize: "2.5vmin", fontWeight: "600" }}>
                Nuestro plan
              </span>{" "}
              <br />
              <span
                style={
                  laptop575.matches
                    ? {
                        fontSize: "7vmin",
                        fontWeight: "800",
                        lineHeight: "100%",
                      }
                    : {
                        fontSize: "4vmin",
                        fontWeight: "800",
                        lineHeight: "100%",
                      }
                }
              >
                {imagen.name}
              </span>{" "}
              <br />
              <span
                style={
                  laptop575.matches
                    ? {
                        fontSize: "1.5vmin",
                      }
                    : {
                        fontSize: "1.0vmin",
                      }
                }
              >
                {imagen.description} <br />
              </span>{" "}
              <div style={{ marginTop: "1vmin" }}>
                {" "}
                <span style={{ fontSize: "1.5vmin" }}>DURACIÓN: {imagen.videos.length} {imagen.videos.length == 1?"CLASE":"CLASES"} </span>
              </div>
              <br />
              <div className="d-flex ms-0 " style={{ marginRight: "6rem" }}>
                <ButtonMob
                  text="Ver ahora"
                  className="butonvideobanner"
                  handleClick={() => navigate(`/videos/card/${imagen.id}/${imagen.videos[0].id}`)}
                />

                <ButtonMob
                  handleClick={() => navigate(`/videos/info/${imagen.id}`)}
                  text="Más información"
                  className="butonvideobanner_two ms-2"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
       
      );
    });

  const render_img_mobile = vid&&vid.filter(ele=>ele.show_in_banner ==true).map((imagen, index) => {
   
    return (
      <SwiperSlide key={index} className="sliderNetFlixvideoNew">
        <div
          id={`bannermobile${index + 1}`}
          style={
            deseoplayer1
              ? {
                  zIndex: "1",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  height: "120vmin",
                }
              : {
                  position: "relative",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundRepeat: "no-repeat",
                  height: "120vmin",
                  // minHeight: "calc(80vmin - 0rem)",
                }
          }
          className={"content mx-auto d-flex"}
        >
          <img
            width="100%"
            // height="100vmin"
            style={{ backgroundSize: "contain" }}
            src={imagen.search_poster}
          />

          <div
            className="position-absolute d-flex "
            style={
              laptop575.matches
                ? {
                    flexDirection: "column",
                    justifyContent: "center",
                    left: "8vmin",
                    zIndex: "2",
                    maxWidth: "50vmin",
                    color: "white",
                  }
                : {
                    flexDirection: "column",
                    justifyContent: "center",
                    left: "8vmin",
                    zIndex: "2",
                    width: "90%",

                    color: "white",
                  }
            }
          >
            <span
              style={
                laptop575.matches
                  ? { fontSize: "2.5vmin", fontWeight: "600" }
                  : {
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      marginTop: "30vmin",
                    }
              }
            >
              Nuestro plan
            </span>{" "}
            {laptop575.matches ? <br /> : null}
            <span
              style={
                laptop575.matches
                  ? {
                      fontSize: "7vmin",
                      fontWeight: "800",
                      lineHeight: "100%",
                    }
                  : {
                      fontSize: "2rem",
                      fontWeight: "800",
                      lineHeight: "100%",
                    }
              }
            >
              {imagen.name}
            </span>{" "}
            <br />
            <span
              style={
                laptop575.matches
                  ? {
                      fontSize: "1.5vmin",
                    }
                  : {
                      fontSize: "0.8rem",
                      padding: "0 10% 0 0",
                    }
              }
            >
              {imagen.description} <br />
            </span>{" "}
            <div style={{ marginTop: "1vmin" }}>
              {" "}
              <span
                style={
                  laptop575.matches
                    ? {
                        fontSize: "1.5vmin",
                      }
                    : {
                        fontSize: "0.8rem",
                      }
                }
              >
                DURACIÓN: {imagen.videos.length} {imagen.videos.length == 1?"CLASE":"CLASES"}
              </span>
            </div>
            <div className="d-flex ms-0 " >
              <ButtonMob
                text="Ver ahora"
                className="butonvideobanner"
                handleClick={() => navigate(`/videos/card/${imagen.id}/${imagen.videos[0].id}`)}
              />

              <ButtonMob
                handleClick={() => navigate(`/videos/info/${imagen.id}`)}
                text="Más información"
                className="butonvideobanner_two ms-2 me-3"
              />
            </div>
          </div>
        </div>
      </SwiperSlide>
    );
  });

  function handleChange_bullet(e) {
    props.handleInputBullet(e);
  }
  return (
    <div className="position-relative">
      <div
        className=" d-flex position-absolute "
        style={{
          zIndex: "2",
          justifyContent: "space-between",
          top: "50%",
          left: "0%",
        }}
      >
        {theSwipper && theSwipper.activeIndex != 1 ? (
          <div className={`swiper-button-prev-banners `}></div>
        ) : (
          <div className={`swiper-button-prev-banners d-none`}></div>
        )}
      </div>
      <div
        className=" d-flex position-absolute"
        style={{
          zIndex: "2",
          justifyContent: "space-between",
          top: "50%",
          right: "0%",
        }}
      >
        <div className={`swiper-button-next-banners`}></div>
      </div>
      <Swiper
        className="sliderPublicidadnew slidersNetflixReservasNew"
        spaceBetween={0}
        slidesPerView={1}
        modules={[Navigation,Pagination]}
        navigation={{
          nextEl: `.swiper-button-next-banners`,
          prevEl: `.swiper-button-prev-banners`,
        }}
       
       loop
        pagination={{
          clickable: true,
          el: ".bulletsVideos",
          type: "custom",
          renderCustom: function (swip, current) {
          
            if (theSwipper == null) {
              setTheSwipper(swip);
            }
            console.log(current)
             localStorage.currentBanner = current;
            if(vid){

            handleChange_bullet(vid[current - 1].main_topic);
            }
            current -= 1;
             if (laptop575.matches) {
              if(render_img_desk)
              {return render_img_desk
                .map((value, index) => {
                  return `<div key=${index} id=${"pointerCompanie" + index} 
                   class='pointerCompanie swiper-pagination-bullet mx-2 ${
                     current  == index ? "active" : ""
                   }'></div>`;
                })
                .join("");}
            } else {
              return render_img_mobile && render_img_mobile
                .map((value, index) => {
                  return `<div key=${index} class='pointerCompanie swiper-pagination-bullet mx-2 ${
                    current == index ? "active" : ""
                  }'></div>`;
                })
                .join("");
            }
          },
        }}
      > 
     
      
     {laptop575.matches ? render_img_desk : render_img_mobile}
      </Swiper>
      {ModalInfo}
      <div className="bulletsVideos"></div>
    </div>
  );
}
