import "./Ejercicios.css";
import { useEffect, useRef, useState } from "react";
import { ButtonMob, laptop575, instance, perlo, ModalInfo, NuevaAxiosURL } from "../../utils/utils";
import Moment from "moment";
import { extendMoment } from "moment-range";

import { AllImagenes, Allvideos, AllvideosBanner } from "./allvideos";
import { Videos_two } from "./othervideos";
import { Link, useNavigate } from "react-router-dom";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import {  ButtonTutorialReserva } from "../Dashboard/helpResponsive";
import { ContinuousColorLegend } from "react-vis";
import VerinfoIndividualTutorial from "../vertipo/tutorialVideosInfo";
import { Loading } from "../TutorialReserva/stepsHelp";


const Ejercicios = (props) => {
  const [confirm, setModalConfirm] = useState(null);
  const [Modal, setModal] = useState(null);
  const [AllVideos1, setAllVideos1] = useState(null);
  const [Innerwidth, setInnerwidth] = useState(0);
  const [stepIndexFast, setstepIndexFast] = useState(0);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (props.isLogged == false) {
  //     history.push("/");
  //   }
  // }, [props.isLogged]);

  // const [reproducir, setreproducir] = useState(false);
  require("moment-timezone");

  const [swipperchange, setswipperchange] = useState(1);

  const [color_change, setcolor_change] = useState(10);
  const [allTopics, setallTopics] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const steps = [
    {
      content: (
        <div
          className="d-flex mx-auto px-2"
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <div className="mx-auto ">
            {" "}
            <img
              style={{ width: "100%" }}
              src={process.env.PUBLIC_URL + "/img/MODELOYoce.png"}
            />
          </div>
          <div className=" px-3">
            {laptop575.matches ? (
              <h3
                className=" mt-4"
                style={{
                  color: "#394F65",
                  fontWeight: "500",
                  fontSize: "1.5rem",
                }}
              >
                Bienvenido a nuestra plataforma de
                <br /> bienestar elevva
              </h3>
            ) : (
              <h3
                className=" mt-4"
                style={{
                  color: "#394F65",
                  fontWeight: "500",
                  fontSize: "1.5rem",
                }}
              >
                Bienvenido a nuestra plataforma de bienestar elevva
              </h3>
            )}
            {laptop575.matches ? (
              <span style={{ color: "#9DACBD", fontWeight: "600" }}>
                Acá encontraras el contenido <br />
                perfecto para iniciar un estilo <br />
                de vida saludable que te hará <br />
                pleno y feliz.
              </span>
            ) : (
              <span style={{ color: "#9DACBD", fontWeight: "600" }}>
                Acá encontraras el contenido perfecto para iniciar un estilo de
                vida saludable que te hará pleno y feliz.
              </span>
            )}
          </div>
        </div>
      ),

      locale: {
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
      },
      spotlightClicks: true,
      placement: "center",
      target: "body",
    },

    {
      target: "body",
      placement: "center",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "600" }}>
          Tendrás a tu disposición nuestras clases y videos pregrabados.
        </div>
      ),
      spotlightClicks: true,
      spotlightPadding: 0,
      locale: {
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#trestepbanner",
      placement: "auto",
      spotlightPadding: 90,

      content: (
        <div style={{ color: "#9DACBD", fontWeight: "600" }}>
          Accede a nuestro contenido pregrabado seleccionando la pestaña y
          después el programa que quieras.
        </div>
      ),

      locale: {
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: ".trestep",
      placement: "bottom",
      spotlightClicks: true,
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "600" }}>
          Podrás seleccionar tu disciplina favorita acorde a su intensidad y
          dando click sobre su imagen.
        </div>
      ),
      spotlightPadding: 60,
      locale: {
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#allvideosTutorialInfo",
      placement: "top",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "600" }}>
          Accede al contenido de nuestros programas dando click en empezar o
          viendo cada video.
        </div>
      ),
      spotlightClicks: true,

      locale: {
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#backInfoTutorial",
      placement: "top",
      content: (
        <div style={{ color: "#9DACBD", fontWeight: "600" }}>
          Para regresar al menú de contenido, debes dar click en regresar
        </div>
      ),
      spotlightClicks: true,

      locale: {
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },

    {
      target: "iframe[title='chat widget']",

      content: (
        <div style={{ color: "#9DACBD", fontWeight: "600" }}>
          No dudes en contactarnos, queremos hacer tu experiencia con nosotros
          la mejor.
        </div>
      ),
      spotlightClicks: true,
      locale: {
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        last: <span aria-label="skip">Comenzar a ver vídeos</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
  ];
  useEffect(() => {
    var anchoVentana =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var porcentaje575 = anchoVentana - anchoVentana * 0.17;
    var porcentaje0 = anchoVentana;
    setInnerwidth(laptop575.matches ? porcentaje575 : porcentaje0);
  }, [Innerwidth]);

  useEffect(() => {
    instance
      .get(`videos/programs`)
      .then((response) => {
        const all_videos = response.data;
        setisLoading(false);
        setAllVideos1(all_videos);
      })
      .catch((error) => {
        if (error.response.status === 403) {
         
          setModal(
            <ModalInfo
              error="false"
              handleClick={() => navigate(`/Dashboard`)}
              textButtonConfirmar={"Mejorar mi plan"}
              classcorrecta={"recuerdaTitleBlock"}
              handleCancellcargue={() => navigate(`/Dashboard`)}
              title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
              text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
              classesMsg="colorText_Modal"
            />
          );
        }
      });
    if (localStorage.membership) {
      instance
        .get(`${NuevaAxiosURL}memberships/${localStorage.membership}/detail`)
        .then((response) => {
          const data = response.data;
          console.log(data);
          // if(!data.rules.includes(1)){
          //   setModalConfirm(
          //     <ModalInfo
          //       error="false"
          //       handleClick={()=>navigate("/Dashboard")}
          //       handleCancellcargue={()=>navigate("/Dashboard")}
          //       classcorrecta={"recuerdaTitle"}
          //       title="Recuerda"
          //       text="Tu plan actual no te permite acceder a esta clase de contenido"
          //       classesMsg="colorText_Modal"
          //     />
          //   );
          // }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setModal(
              <ModalInfo
                error="false"
                handleClick={() => navigate(`/Dashboard`)}
                textButtonConfirmar={"Mejorar mi plan"}
                classcorrecta={"recuerdaTitleBlock"}
                handleCancellcargue={() => navigate(`/Dashboard`)}
                title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
                text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
                classesMsg="colorText_Modal"
              />
            );
          }
        });
    } else {
      setModal(
        <ModalInfo
          error="false"
          handleClick={() => navigate("/Dashboard")}
          handleCancellcargue={() => navigate("/Dashboard")}
          classcorrecta={"recuerdaTitle"}
          title="Recuerda"
          text=" No tienes una membresía activa"
          classesMsg="colorText_Modal"
        />
      );
    }
  }, []);
  useEffect(() => {
    instance
      .get(`videos/programs/?main_topic=${color_change}`)
      .then((response) => {
        const all_topics = response.data;

        setallTopics(all_topics);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [color_change]);

  // var reserva = AllVideos;
  const [pasos, setpasos] = useState(steps);
  const [stepIndex, setstepIndex] = useState(0);
  const [correr, setcorrer] = useState(false);
  const [heightjoyride, setheightjoyride] = useState(true);
  const [reproducir, setreproducir] = useState([
    AllVideos1 && AllVideos1.filter((e) => e.main_topic == 10),
    "Entrenamiento",
  ]);

  useEffect(() => {
    var reserva = AllVideos1;
    if (localStorage.pathCom == undefined) {
      setreproducir([
        reserva && reserva.filter((e) => e.main_topic == 10),
        "Entrenamiento",
      ]);
    }
  }, [AllVideos1]);

  const [reproducirtexto, setreproducirtexto] = useState("Entrenamiento");
  let listenerbutton_Entrenamiento = document.getElementById(
    "Entrenamiento_button"
  );
  if (listenerbutton_Entrenamiento && AllVideos1) {
    listenerbutton_Entrenamiento.addEventListener("click", (e) => {
      let index =
        AllVideos1 && AllVideos1.findIndex((x) => x.main_topic === 10);

      e.preventDefault();
      setswipperchange(index + 1);
      setreproducirtexto(e.target.value);
    });
  }
  let listenerbuttons_nutri = document.getElementById("Nutri_button");
  if (listenerbuttons_nutri && AllVideos1) {
    listenerbuttons_nutri.addEventListener("click", (e) => {
      let index = AllVideos1.findIndex((x) => x.main_topic === 20);
      console.log(index);
      e.preventDefault();
      setswipperchange(index + 1);
      setreproducirtexto(e.target.value);
    });
  }

  useEffect(() => {
    if (stepIndex > 1) {
      setheightjoyride(false);
    }
  }, [stepIndex]);

  useEffect(() => {
    if (AllVideos1) {
      if (reproducirtexto == "Nutrición") {
        setreproducir([
          AllVideos1.filter((e) => e.main_topic == 20),
          "Nutrición",
        ]);
        setcolor_change(20);

        // setswipperchange(2);
      } else {
        setreproducir([
          AllVideos1.filter((e) => e.main_topic == 10),
          "Entrenamiento",
        ]);
        setcolor_change(10);
      }
    }
  }, [reproducirtexto]);

  function salirSure() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalConfirm(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <br></br>
          {laptop575.matches ? (
            <div
              className=" text-center "
              style={{
                color: "#394F65",
                fontWeight: "600",
                fontSize: " calc(.5rem + 0.7vw)",
              }}
            >
              ¿Estás seguro que deseas de salir?
            </div>
          ) : (
            <div
              className=" text-center "
              style={{
                color: "#394F65",
                fontWeight: "600",
              }}
            >
              ¿Estás seguro que <br />
              deseas de salir?
            </div>
          )}
          {laptop575.matches ? <br /> : null}
          <div className="btnAction" onClick={perlo}>
            <ButtonMob
              colorButton={"rgb(158, 172, 237)"}
              text="Confirmar"
              className="mb-3"
            />
          </div>
          <div className="btnAction" onClick={() => setModalConfirm(null)}>
            <ButtonMob text="Descartar" className="mt-3 mb-3 btn-danger" />
          </div>
        </div>
      </div>
    );
  }
  function darclickbullet(e) {
    var pather = localStorage.pathCom;

    if (pather && AllVideos1) {
      if (pather == 10) {
        setreproducirtexto("Entrenamiento");
        let index = AllVideos1.findIndex((x) => x.main_topic === 10);
        setswipperchange(index + 1);
      } else {
        setreproducirtexto("Nutrición");
        let index = AllVideos1.findIndex((x) => x.main_topic === 20);
        setswipperchange(index + 1);
      }
      localStorage.removeItem("pathCom");
    } else {
      var flag = false;
      if (flag == false) {
        console.log("entro");

        if (e == 20) {
          setreproducirtexto("Nutrición");
        } else {
          setreproducirtexto("Entrenamiento");
        }

        flag = true;
      }
    }
  }

  const handleJoyrideCallback = (data) => {
    const { status, type, action, index } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      let timeDelay = 0;
      setstepIndexFast(index + (action === ACTIONS.PREV ? -1 : 1));
      // Update state to advance the tour
      setTimeout(() => {
        setstepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }, timeDelay);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setcorrer(false);
      setstepIndex(0);
    }
  };
  function handleClickStart() {
    setreproducir([
      AllVideos1.filter((e) => e.main_topic == 10),
      "Entrenamiento",
    ]);
    setcolor_change(10);
    setswipperchange(1);

    setcorrer(true);
  }

  return isLoading ? (
    <>
      {" "}
      <Loading />
      {Modal}
    </>
  ) : (
    <>
      {reproducir ? (
        <div id="maximomi" className="d-flex col-12 col-md-10 m-auto ">
          <Joyride
            steps={pasos}
            run={correr}
            hideCloseButton
            disableScrolling={heightjoyride}
            disableOverlayClose={true}
            disableCloseOnEsc={true}
            showSkipButton
            continuous
            stepIndex={stepIndex}
            styles={{
              options: {
                primaryColor: "#CB3D82",
              },
            }}
            callback={handleJoyrideCallback}
          />

          {laptop575.matches ? (
            stepIndexFast == 4 || stepIndexFast == 5 ? (
              <VerinfoIndividualTutorial stepIndex={stepIndexFast} />
            ) : (
              <div
                className="mx-auto movies"
                style={{
                  width: "100%",
                  height: "100vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div
                  className="bannervideos d-flex"
                  style={{
                    background: "rgb(241, 246, 249)",
                    position: "sticky",
                    top: "0",
                    zIndex: "3",
                    minheight: "10vmin",
                    left: "0",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="py-2 mx-3 banner_one "
                    style={{
                      background: "rgb(241, 246, 249)",

                      color: "white",
                    }}
                  >
                    <div
                      onClick={salirSure}
                      className=" position-absolute"
                      style={{
                        left: "5%",
                        top: "35%",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontWeight: "500",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                    </div>
                    <div
                      className="d-flex position-absolute"
                      style={{
                        right: "10%",
                        verticalAlign: "middle",
                        top: "30%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ButtonMob
                        handleClick={() => handleClickStart()}
                        text="Ver tutorial"
                        className="mt-0 letrabuttontutorial "
                      />
                    </div>
                    <Link
                      className="form  my-2 d-flex"
                      style={{ float: "right", marginRight: "3%" }}
                      to="/videos/Buscar"
                    >
                      <button
                        className="elevation-3 input_searchvideos"
                        style={{
                          bottom: "19%",

                          background: "white",
                          justifyContent: "center",
                          flexDirection: "column",
                          textAlign: "center",
                          borderRadius: "50%",
                          padding: "1vmin",

                          color: "#9DACBD",

                          zIndex: "10",
                          border: "2px solid rgba(157,172,189,0.4)",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 17 17"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="contenedorImagenes">
                  <AllvideosBanner
                    color_changese={reproducirtexto}
                    handleInputBullet={(e) => darclickbullet(e)}
                    swipperchange={swipperchange}
                    videos={AllVideos1}
                  />
                </div>

                <div
                  id="trestepbanner"
                  className=" d-flex w-100 "
                  style={{
                    paddingLeft: "5%",

                    background: "rgba(253,241,232,0.5)",
                  }}
                >
                  <button
                    onClick={() => setcolor_change(10)}
                    style={
                      color_change == 10
                        ? {
                            background: "#ffff",
                            color: "#CB3D82",
                            border: "1px solid #ffff",
                          }
                        : null
                    }
                    id="Entrenamiento_button"
                    value="Entrenamiento"
                    className="clasebutton2"
                  >
                    Entrenamiento
                  </button>
                  <button
                    onClick={() => setcolor_change(20)}
                    id="Nutri_button"
                    value="Nutrición"
                    className={`clasebutton2 `}
                    style={
                      color_change == 20
                        ? {
                            background: "#ffff",
                            color: "#CB3D82",
                            border: "1px solid #ffff",
                          }
                        : null
                    }
                  >
                    Nutrición
                  </button>
                </div>

                <div
                  className="trestep "
                  style={{
                    paddingLeft: "0%",
                  }}
                >
                  {allTopics &&
                  allTopics.filter((training) => training.skill_level == 10)
                    .length > 0 ? (
                    <AllImagenes
                      Innerwidth={Innerwidth}
                      textTitleOne={
                        color_change == 10 ? "Intensidad" : "Recetas"
                      }
                      textTitleTwo={color_change == 10 ? "Baja" : "express"}
                      id="comidas"
                      contenido={reproducir[1]}
                      videos={allTopics.filter(
                        (training) => training.skill_level == 10
                      )}
                      textealo={reproducirtexto}
                      className="sliderNetFlixvideocomidas"
                    />
                  ) : null}
                </div>
                <div
                  className="trestep "
                  style={{
                    paddingLeft: "0%",
                  }}
                >
                  {allTopics &&
                  allTopics.filter((training) => training.skill_level == 20)
                    .length > 0 ? (
                    <AllImagenes
                      Innerwidth={Innerwidth}
                      textTitleOne={
                        color_change == 10 ? "Intensidad" : "Recetas"
                      }
                      textTitleTwo={
                        color_change == 10 ? "Media" : "intermedias"
                      }
                      id="pindos"
                      contenido={reproducir[1]}
                      videos={allTopics.filter(
                        (training) => training.skill_level == 20
                      )}
                      textealo={reproducirtexto}
                      className="sliderNetFlixvideocomidas"
                    />
                  ) : null}
                </div>
                <div
                  className="trestep "
                  style={{
                    paddingLeft: "0%",
                  }}
                >
                  {allTopics &&
                  allTopics.filter((training) => training.skill_level == 30)
                    .length > 0 ? (
                    <AllImagenes
                      Innerwidth={Innerwidth}
                      textTitleOne={
                        color_change == 10 ? "Intensidad" : "Recetas"
                      }
                      textTitleTwo={color_change == 10 ? "Alta" : "gourmet"}
                      id="numbers"
                      contenido={reproducir[1]}
                      videos={allTopics.filter(
                        (training) => training.skill_level == 30
                      )}
                      textealo={reproducirtexto}
                      className="sliderNetFlixvideocomidas"
                    />
                  ) : null}
                </div>
                <br />
              </div>
            )
          ) : stepIndexFast == 4 || stepIndexFast == 5 ? (
            <VerinfoIndividualTutorial stepIndex={stepIndexFast} />
          ) : (
            <div
              className="mx-auto movies"
              style={{
                width: "100%",
                height: "100%",
                // overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div>
                <div className="contenedorImagenes position-relative">
                  <AllvideosBanner
                    swipperchange={swipperchange}
                    handleInputBullet={(e) => darclickbullet(e)}
                    videos={AllVideos1}
                  />
                  <Link
                    className="form  my-2 d-flex position-absolute"
                    style={{ zIndex: "999", right: "5%", top: "5%" }}
                    to="/videos/Buscar"
                  >
                    <button
                      className="elevation-3 d-flex"
                      style={{
                        background: "white",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        borderRadius: "50%",
                        padding: "1.3vmin",

                        color: "#394F65",

                        zIndex: "10",
                        border: "2px solid transparent",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 17 17"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </Link>
                </div>

                <div
                  id="trestepbanner"
                  className=" d-flex w-100 "
                  style={{
                    background: "rgba(253,241,232,0.5)",
                  }}
                >
                  <button
                    onClick={() => setcolor_change(10)}
                    style={
                      color_change == 10
                        ? {
                            background: "#ffff",
                            color: "#CB3D82",
                            border: "1px solid #ffff",
                          }
                        : null
                    }
                    id="Entrenamiento_button"
                    value="Entrenamiento"
                    className="clasebutton2"
                  >
                    Entrenamiento
                  </button>
                  <button
                    onClick={() => setcolor_change(20)}
                    id="Nutri_button"
                    value="Nutrición"
                    className={`clasebutton2 `}
                    style={
                      color_change == 20
                        ? {
                            background: "#ffff",
                            color: "#CB3D82",
                            border: "1px solid #ffff",
                          }
                        : null
                    }
                  >
                    Nutrición
                  </button>
                </div>
                <br />
                <div className="position-relative">
                  <div
                    className="position-absolute w-100"
                    style={{
                      top: "0",
                      backgroundColor: "#FFFF",
                      height: "60vmin",
                      zIndex: "0",
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundColor: "#FDF1E8",

                      zIndex: "1",
                      paddingTop: "2%",
                      paddingBottom: "2%",
                    }}
                  >
                    <div className="RecomendadoparaTi trestep">
                      {allTopics &&
                      allTopics.filter((training) => training.skill_level == 10)
                        .length > 0 ? (
                        <AllImagenes
                          Innerwidth={Innerwidth}
                          textTitleOne={"Intensidad"}
                          textTitleTwo={"Baja"}
                          id="comidas"
                          contenido={reproducir[1]}
                          videos={allTopics.filter(
                            (training) => training.skill_level == 10
                          )}
                          textealo={reproducirtexto}
                          className="sliderNetFlixvideocomidas"
                        />
                      ) : null}
                    </div>
                    <div
                      style={{
                        paddingLeft: "0%",
                      }}
                    >
                      {allTopics &&
                      allTopics.filter((training) => training.skill_level == 20)
                        .length > 0 ? (
                        <AllImagenes
                          Innerwidth={Innerwidth}
                          textTitleOne={"Intensidad"}
                          textTitleTwo={"Media"}
                          id="pindos"
                          contenido={reproducir[1]}
                          videos={allTopics.filter(
                            (training) => training.skill_level == 20
                          )}
                          textealo={reproducirtexto}
                          className="sliderNetFlixvideocomidas"
                        />
                      ) : null}
                    </div>
                    <div
                      style={{
                        paddingLeft: "0%",
                      }}
                    >
                      {allTopics &&
                      allTopics.filter((training) => training.skill_level == 30)
                        .length > 0 ? (
                        <AllImagenes
                          Innerwidth={Innerwidth}
                          textTitleOne={"Intensidad"}
                          textTitleTwo={"Alta"}
                          id="numbers"
                          contenido={reproducir[1]}
                          videos={allTopics.filter(
                            (training) => training.skill_level == 30
                          )}
                          textealo={reproducirtexto}
                          className="sliderNetFlixvideocomidas"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <ButtonTutorialReserva handleClick={() => handleClickStart()} />
            </div>
          )}
        </div>
      ) : null}
      {confirm}
    </>
  );
};
export default Ejercicios;
// https://gist.github.com/jctosta/af918e1618682638aa82  screenws
// ffmpeg -n -loglevel error -i YOGAPRENATALCLASE1mm720p.mp4 -vcodec libx264 -crf 28 -preset faster -tune film outputfilename.mp4            PROBAR
