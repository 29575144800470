import { useEffect } from "react";
import React, { useRef } from "react";
import { Categorias, Paso3, Paso3Mobile } from "../TutorialReserva/stepsHelp";
import { useNavigate,useParams  } from "react-router-dom";
import { instance, ModalInfo } from "../../utils/utils";
import { useState } from "react";
import Moment, { defineLocale, months } from "moment";
import icoNegativo from "../../assets/newIcons/ICO-NEGATIVO.svg"


const GroupList = (props) => {
  const navigate = useNavigate();
  const user= props.user;
  const { id } = useParams();
  const zonahorairachange =props.zonahorairachange
  const [infoTraining, setinfoTraining] = useState(null);
  const [groupalInfo, setgroupalInfo] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  useEffect(() => {

    const inicioday = Moment().format().slice(0, 11) + "00:00";
  
    instance
      .get(`/booking/group/${id}/schedule/`)
      .then((res) => {
        const data = res.data.filter((e) => e.schedule_date >= inicioday);
        if (data) {
          
          if(data.length>0){

            setgroupalInfo(data);
          }else{
            setModalInfo(
              <ModalInfo
                error="none"
                textButtonAction="Entendido"
                handleClick={()=>navigate(-1)}
                handleCancellcargue={()=>navigate(-1)}
                text={"Aún no tenemos clases programadas"}
                classesMsg="colorText_Modal my-2"
                iconoWidth={"2rem"}
            textSizeUp={"1.2rem"}
            icono={icoNegativo}
              />
            );
          }
        }
      })
      .catch((err) => {
       console.log(err.response)
      });
      instance
        .get(`trainings/${id}`)
        .then((response) => {
          setinfoTraining(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    
  }, []);

  function handleClick(e){
    
    localStorage.setItem("trainingVector", JSON.stringify(e));
    return navigate(`/reservas`)
  
   }
   function hideModal() {
    setModalInfo(null);
  }
function popupError(err){
  setModalInfo(
    <ModalInfo
      error="true"
      handleClick={hideModal}
      handleCancellcargue={hideModal}
      title="Lo sentimos"
      text={err.response.data[0]}
      classesMsg="colorText_Modal my-2"
    />
  );
}

  return (<>{modalInfo} 
    <div
    className="d-none d-md-block col-10" style={{height:'100%'}}
  >
    <div
      className=" position-relative "
      style={{
        background: "rgba(0,0,0,.7)",
        height: "100vh",
        padding: "2% 3% 2% 2%",
      }}
    >
      <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
      <Categorias  categoryClick={handleClick}/>
        <Paso3 infoTraining={infoTraining} groupalInfo={groupalInfo} alertError={(e)=>popupError(e)} zonahorairachange={zonahorairachange}/>
            </div>
          </div>
        </div>
        <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <Paso3Mobile infoTraining={infoTraining} groupalInfo={groupalInfo} alertError={(e)=>popupError(e)} zonahorairachange={zonahorairachange}/>
        </div>
        
        </>)

}
export default GroupList;