
import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg"
import {
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  instance,
  NuevaAxiosURL,
  ButtonMob,
  laptop575,
} from "../../utils/utils";

import { Lesiones } from "../Lesiones/lesiones";

const MisLesiones = (props) => {
  const [modalInfo, setModalInfo] = useState(null);
  const [infoLesiones, setInfoLesiones] = useState(null);
  const [buttonChange, setbuttonChange] = useState("Ver vista Posterior");
  const [userData, setUserData] = useState([]);
  const frontalView = useRef(null);
  const backView = useRef(null);
  const modalesDolores = useRef(null);
  const modalesDoloresBack = useRef(null);
  const [modificarLesiones, setModificarLesiones] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const elevvaT=props.elevvaT

  const changeView = (ref) => {
    if (ref == backView) {
      backView.current.style.display = "block";
      frontalView.current.style.display = "none";
      setbuttonChange("Ver vista frontal");
    } else {
      backView.current.style.display = "none";
      frontalView.current.style.display = "block";
      setbuttonChange("Ver vista Posterior");
    }
  };
  const hideModal = () => {
    setModalInfo(null);
  };

  function changeData(){
    setModificarLesiones(true);
    setTimeout(() => {
      setModificarLesiones(false);
    }, 1000);
  
  }

  function injuriesConsult(){
 
    instance
    .get(`customers/injuries/?customer=${localStorage.id}`)
    .then((response) => {
      const info = response.data;
      
      if (info != null) {
        setInfoLesiones(info);
      }
    })
    .catch((err) => {
      if (err.response) {
     console.log(err.response)
      }
   
    });
  }
    useEffect(() => {
     injuriesConsult()
    }, []);

  useEffect(() => {
    instance
      .get("users/current/")
      .then((response) => {
        console.log(response.data)
        setUserData(response.data);
     
      })
      .catch((error) => {
        console.log(error);
      });

  }, [location.pathname]);

 

  return(<> {modalInfo} 

<div className="mx-3 my-3 d-flex">
   <button onClick={()=>navigate(-1)} className={"button_w_h_back"} style={{background:"transparent",border:"none",}}>
   <BackArrow width={"100%"} height={"100%"} /></button><span className="title my-auto fw-light" style={{fontSize:"1.2rem"}}>Mis <strong>lesiones</strong></span>     
      
      </div>
    
     
                    <div
                      
                      className="position-relative"
                      style={{
                        background: "white",
                        width: "100%",
                        borderRadius: "20px",
                        flexDirection: "column",
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
        <div style={{background:"#F9F9F9",textAlign:"center",padding:"3% 5%"}}><span
        className="letraPequeña-Perfil text-center"
       
      >
      Indícanos la zona del cuerpo y haz una breve descripción de tu lesión.
      </span></div>        
      
              <div id={`bodyPanelPerfilMobile`}>         <Lesiones    sendDataLes={modificarLesiones}
                       injuriesDelete={()=>window.location.reload() }
          modalesDolores={modalesDolores}
          modalesDoloresBack={modalesDoloresBack}
          elevvaT={elevvaT}
          frontalView={frontalView}
          backView={backView}
          nameFigure={"figurePartsPerfilNew"}
          classMargin={"m-0"}
          updateLesion={changeData}
          infoLesiones={infoLesiones}
        /></div>
        <div className="m-0">
        
          <ButtonMob
            className="m-0 button-Body-perfil"
            handleClick={() =>
              buttonChange == "Ver vista frontal"
                ? changeView(frontalView)
                : changeView(backView)
            }
            text={buttonChange}
          />
        </div>
                    </div>
                 
                    {modalesDolores && modalesDolores.current}
        {modalesDoloresBack.current}{" "}       
    
    </>
  )
}
  export default MisLesiones;