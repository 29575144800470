import { MisSesiones } from "./helpResults";
import { useState } from "react";
import { useEffect } from "react";
import { instance, ModalInfo } from "../../utils/utils";
import moment from "moment";
import { useCompareDate } from "../Mis clases/misclases_utils";
import { useNavigate } from "react-router-dom";

const Recientes = (props) => {
  const [copyAllEntrenamientos, setCopyAllEntrenamientos] = useState(null);
  const [userData, setUserData] = useState(null);
  const [age, setage] = useState(null);
  const navigate=useNavigate()

  useEffect(() => {

    instance
    .get("users/current/")
    .then((response) => {
          
      const customer = response.data.customer;
      setage(customer.age)
        
      setUserData(customer);
    })
    .catch((error) => {
      console.log(error);
    });

///other
    const copyEntrenamientos = [];
    const copyDisciplines = [];
    const getData = async () => {
      await instance.get("customers/disciplines/").then(async (res) => {
        const mor = res.data;
        if (mor) {
          for (const i of mor) {
            const idDiscipline = i.discipline.id;
            const UUid = i.id;
            const kcal = i.kcal;
            const data = i.date;
            const hr_max = i.hr_max;
            const hr_med = i.hr_med;
            const status = i.status;
            copyDisciplines[UUid] = {
              id: "d" + idDiscipline,
              UUid: UUid,
              data: data,
              status: i.discipline.active,
              titulo: i.discipline.name.toLowerCase(),
              descripcion: i.discipline.summary,
              icon: i.discipline.icon,
              numeric_value: hr_med,
              duracion: i.duration,
              calorias: kcal,
            };
          }
          // console.log(copyDisciplines);
        }
      });

      await instance.get("booking/?any").then(async (res) => {
        const data =res.data.filter(
                (e) =>
                  e.status > 30 &&
                  e.status != 70 &&
                  e.status != 90 &&
                  e.status != 50 &&
                  e.date < moment().format()
              );

        if (data) {
         
          for (const i of data.filter((e) => e.training)) {
            const name = i.training.name;
            const description = i.training.description;
            const icon = i.training.icon;
            const UUid = i.id;
            const dataT = i.date;
            
            const img = i.training.cover_picture; //.match(/(img[\d]*b.png)/)[0]

            if (data != null) {
              copyEntrenamientos[UUid] = {
                id: UUid,
                titulo: name.toLowerCase(),
                img: img,
                UUid:UUid,
                data: dataT,
                icon: icon,
                
              };


          
            }
          }
        }
      });
      let totalSesions=copyEntrenamientos
      .filter((e) => e)
      .concat(copyDisciplines.filter((e) => e))
 
      setCopyAllEntrenamientos(
        totalSesions.sort(useCompareDate)
      );
    
    };
  
  
    getData();
  }, []);
return(<div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
<MisSesiones radius={"0"} bookings={copyAllEntrenamientos?copyAllEntrenamientos:[]}
     handleClick={(e)=>navigate(`/results/${e}`)
      }
       />

</div>)
}
export default Recientes;