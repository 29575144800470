
import {useParams } from "react-router-dom";
const ShareWhastapp = (props) => {
  const { url } = useParams();
// Obtén la etiqueta meta og:image existente
// let host =`https://42c0-186-29-73-38.ngrok-free.app/Share`;

// const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(host)}`;
// window.open(whatsappUrl, "https://42c0-186-29-73-38.ngrok-free.app/Share");

// const text = '¡Echa un vistazo a esta imagen increíble!';;

// const url = 'https://api.staging.elevva.com.co/uploads/customers/default.jpg';


return <div>


  <img src={url} />
</div>
}
  export default ShareWhastapp;