import React from 'react'
import { useLocation } from "react-router-dom";
import {

  laptop575,
  basename,
} from "../../utils/utils";

export const AccordionItem = (props) => {

  const path = useLocation();
  const parent = props.dataBsParent;
  const children = props.children;
  const classQuestion =props.classQuestion || ""
  const title = props.title;
  const clase = props.clase || "";
  const identify =props.identify || ""
 
  const item = props.item;
  function goToAcoordion(item) {
    if (path.pathname.includes("ejercicios") && laptop575.matches) {
      const toda = document.querySelector(".glosarioVideo .accordion");
      for (let i = 0; i < toda.children.length; i++) {
        const solouno1 = toda.children[i].querySelector(".flechaenvideos");
        solouno1.style = "display:none";
      }

      const solouno = document
        .querySelector(`#heading${item}`)
        .querySelector(".flechaenvideos");
      if (solouno) solouno.style = "display:block";
    }

    // const el = document.querySelector(`#heading${item}`);
  }

  return (
    <div key={item} className={` accordion-item ${clase} `}>
      <span className={` accordion-header`} id={`heading${item}`}>
        <button
          id={`heading${item}`}
          onClick={() =>
            setTimeout(() => {
              goToAcoordion(item);
            }, 500)
          }
          className={
             `${classQuestion} accordion-button collapsed `
          }
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseItem${item}`}
          aria-expanded="true"
          aria-controls={`collapseItem${item}`}
        >
          {title}
        </button>
      </span>
      <div
        id={`collapseItem${item}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${item}`}
        data-bs-parent={parent}
      >
        <div
          style={
            laptop575.matches &&
              window.location.pathname == `${basename}/Usuario/cuerpo`
              ? {
                position: "absolute",
                zIndex: "9999",
                backgroundColor: "white",
                marginTop: "0vh",

                width: "90%",
              }
              : null
          }
          className={
            laptop575.matches && path.pathname.includes("ejercicios")
              ? null
              : "accordion-body "
          }
        >
          {children}
        </div>
      </div>
      <hr className='mx-auto' style={{width:"70%",color:"#C9C9C9"}}/>
    </div>
  );
};