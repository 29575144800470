import {
  AllPromedios,
  PromediosPanel,
  RangeProgress,
  SemanaCustomer,
} from "../Dashboard/helpResponsive";
import { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import "./actividad.css";
import ReactTooltip from "react-tooltip";
import { ReactComponent as BackArrow } from "../../assets/newIcons/arrow-back.svg";
import { useNavigate } from "react-router-dom";
import { ResponsiveLine } from "@nivo/line";
import { instance, laptop575 } from "../../utils/utils";
import moment from "moment";
import { format, set } from "date-fns";


export const Graphics = (props) => {
  const navigate = useNavigate();
  const mobileOn = props.mobileOn || false;
  const borderBody = props.borderBody || "20px";
  const idJoy = props.idJoy || "";
  const idGraphics = props.idGraphics || "";
  const idButtonSelect = props.idButtonSelect || "";
  const idJoyOpenSelect = props.idJoyOpenSelect || "";
  const heightContainer = props.heightContainer;
  const stepIndex = props.stepIndex || 0;
  const buttonBack = props.buttonBack || ""
  const centerTitle = props.centerTitle || "my-auto fw-light"
  const clickCorporative = props.clickCorporative || null;
  const corporate =props.corporate || '';
  const corporateScore =props.corporateScore || '';
  let graphics;
  const [yMin, setyMin] = useState(0)
  const [yMax, setyMax] = useState(100)
  const [data, setData] = useState([
    {
      id: "Hr",
      data: [
        { x: "2023-05-01", y: 0 },
        { x: "2023-05-02", y: 20 },
        { x: "2023-05-05", y: 60 },
        { x: "2023-05-07", y: 60 },
        { x: "2023-05-10", y: 20 },
      ],
    },
  ]);


  const [dayselected, setdayselected] = useState({
    lunes: false,
    martes: false,
    miércoles: false,
    jueves: false,
    viernes: false,
    sábado: false,
    domingo: false,
  });
  const [Range, setRange] = useState("lm");
  const [Type, setType] = useState(99);
  const [frecuencyUp, setfrecuency] = useState("day");

  function openDropdown(length) {
    var dropdown = document.querySelector("#" + "periodoSelect");
    function down() {
      if (length == 0) {
        dropdown.classList.remove("dropdown");
      } else {
        dropdown.classList.add("dropdown");
      }

      dropdown.size = length;
    }
    dropdown.addEventListener("focus", down);
    //  dropdown.addEventListener('blur', up);
    //  dropdown.addEventListener('click', upClick);
    dropdown.focus();
  }
   useEffect(() => {
    instance
    .get(`customers/metrics/weekly/${corporate}`)
    .then((response) => {
      if(response.data)
    {
      let currentWeek= response.data.current.week_days
    
      setdayselected({
         lunes: currentWeek[0]==0?false:true,
        martes: currentWeek[1]==0?false:true,
        miércoles: currentWeek[2]==0?false:true,
        jueves: currentWeek[3]==0?false:true,
        viernes: currentWeek[4]==0?false:true,
        sábado: currentWeek[5]==0?false:true,
        domingo: currentWeek[6]==0?false:true
      })
    }

    }).catch(err=>console.log(err.response))
    let frequency=resolveFrec(Range,frecuencyUp);

    if(Type == "99" || Type=="14"){

      rangeChallenger(Range,Type,frequency)
    }else{
      rangeDisciplines(Range,Type,frequency)
    }
   
    }, [Range,frecuencyUp]);

  const updateGrapichs = () => {
    if (stepIndex == 2) {
      graphics =
        !graphics &&
        setInterval(() => {
          if (data[0].id == "Calorias") {
            setData([
              {
                id: "Cal",
                data: [
                  { x: "2023-05-01", y: 0 },
                  { x: "2023-05-02", y: 20 },
                  { x: "2023-05-05", y: 60 },
                  { x: "2023-05-07", y: 60 },
                  { x: "2023-05-10", y: 20 },
                ],
              },
            ]);
          } else {
            setData([
              {
                id: "Calorias",
                data: [
                  { x: "2023-05-01", y: 0 },
        { x: "2023-05-02", y: 0 },
        { x: "2023-05-05", y: 0 },
        { x: "2023-05-07", y: 0 },
        { x: "2023-05-10", y: 0 },
                ],
              },
            ]);
          }
        }, 1000);
    } else {
      clearInterval(graphics);
      
    }
  };
  useEffect(() => {
    updateGrapichs(stepIndex);

    return () => clearInterval(graphics);
  }, [data[0]?.id, stepIndex]);

 async function rangeChallenger(Ranger,type,frequency){
  await  instance
    .get(`customers/metrics/healthlog/data/?range=${Ranger}&type=${type}&frequency=${frequency}${corporateScore}`)
    .then(async(response) => {
          
      const summary = response.data;
      if(summary){
     
        let dateConvert = [{id:"",data:[]}];
        dateConvert[0]["id"]= type==99?"Hr":"Tensión sistólica"
        summary[String(type)].map((e, index) => {
          
          dateConvert[0]["data"].push ( {
         x: e.date,
          y:   e.value
          });
       
        });
        
      
       
      if(type == 14){
      await  instance
    .get(`customers/metrics/healthlog/data/?range=${Ranger}&type=${15}&frequency=${frequency}${corporateScore}`)
    .then((response) => {
          
      const summaryDiastole = response.data;
      if(summaryDiastole){
     
        let dateConvertDiastole = [{id:"",data:[]}];
        dateConvertDiastole[0]["id"]= "Tensión diastólica"
        summaryDiastole[String(15)].map((e, index) => {
          
          dateConvertDiastole[0]["data"].push ( {
         x: e.date,
          y:   e.value
          });
       
        });
      
        dateConvert.push(dateConvertDiastole[0]);
       
      }
     
    })
    .catch((error) => {
      console.log(error);
    });
      }
      setData([]);
      setData(dateConvert);
      
      if(dateConvert[0].data.length>0){
        let yMax=Math.max(...dateConvert[0].data.map((entry) => entry.y))
 
        let yMin =Math.min(...dateConvert[0].data.map((entry) => entry.y));
        setyMax(yMax)
        setyMin(yMin)
      }
      
      }
     
    })
    .catch((error) => {
      console.log(error);
    });
  }
  function rangeDisciplines(Ranger,type,frequency){
    instance
    .get(`customers/metrics/disciplines/data/?range=${Ranger}&type=${type}&frequency=${frequency}${corporateScore}`)
    .then((response) => {
          
      const summary = response.data;
      if(summary){
        let dateConvert = [{id:"",data:[]}];
        dateConvert[0]["id"]= type=="kcal"?"kcal":"distance"
        summary[String(type)].map((e, index) => {
          
          dateConvert[0]["data"].push ( {
         x: e.date,
          y:   e.value
          });
       
        });
         setData([])
        setData(dateConvert);
        if(dateConvert[0].data.length>0){
          let yMax=Math.max(...dateConvert[0].data.map((entry) => entry.y))
   
          let yMin =Math.min(...dateConvert[0].data.map((entry) => entry.y));
          setyMax(yMax)
          setyMin(yMin)
        }

      
      }
     
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (stepIndex == 0) {
      if(window.innerWidth > 768)   {  openDropdown(1);}
     
      rangeChallenger(Range,Type,frecuencyUp)
    }
    if (stepIndex == 4) {
      if(window.innerWidth > 768)   { openDropdown(6);}
    }
  }, [stepIndex]);

  const theme1 = {
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
      },

      ticks: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
        text: {
          fill: "var(--elevvaArrowColor)",
        },
      },
      legend: {
        text: {
          fontSize: "3vw",

          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "var(--backgroundGraficGrid)",

        strokeWidth: 1,
      },
    },
  };
  const xAxisFormat = (date) =>{
    function formatResolve(Ranger,Type,frequency){
      if(Ranger == "cw" ){
        if(frequency =="day"){
          return "ddd"
        }else return "ddd hh:mm"
    
      }if(Ranger == "cm" || Ranger == "lm"){
        if(frequency =="day"){
          return "MMM DD"
        }else return "MMM DD hh:mm"
      }
      else return "MMMM"
          }
    let range=formatResolve(Range,Type,frecuencyUp);
    return moment(date).format(range)
  };

  const yAxisFormat = (date) =>{
if(data.length == 1){
return Math.round(date)
}else{
return date
}
  };

  function formatMin (){
    if(data[0] &&data[0].data.length === 1  && data.length ==1){
    return "auto"
    }else return 0
    
      };

      function resolveFrec(Ranger,frequency){
  if(Ranger == "cw" || Ranger == "cm" || Ranger == "lm"){
    if(frequency =="day"){
      return "day"
    }else return "hour"

  }else return "month"
      }
      function changeFrecuency(e){
        let frequency= resolveFrec(Range,e.target.value)
        rangeChallenger(Range,Type,frequency);
        setfrecuency(frequency);
          }

          function  colorType(type){
            if(type == "99"){
              return "var(--pointsGraficaHr)"
            }else if(type == "14"){
              return "var(--pointsGraficaTension)"
            }else if(type == "distance"){
              return "var(--pointsGraficaDistance)"
            }else return "var(--pointsGrafica)"
          }

  function changeRange(e){
  
    
let frequency=resolveFrec(e.target.value,frecuencyUp);
if(Type == "99" || Type=="14"){
  rangeChallenger(e.target.value,Type,frequency);
}else{
  rangeDisciplines(Range,e.target.value,frequency)
}

setRange(e.target.value);
  }
  function changeType(e){
    
    let frequency=resolveFrec(Range,frecuencyUp);
    if(e.target.value == "99" || e.target.value=="14"){

      rangeChallenger(Range,e.target.value,frequency);
    }else{
      rangeDisciplines(Range,e.target.value,frequency)
    }
setType(e.target.value);
  }
 

  
  let yTicks =  Array.from({ length: 5 }).map((_, index) => {
    const difference = yMax - 0;
    const stepSize = difference / 5;
    let valueid=0 + (stepSize) * index;
    let newvalue =yMax - valueid;
    let diferencenew =newvalue/5
    let value;
   
    if (diferencenew >= 1000) {
      // Aproximar a unidades de decenas
      value= Math.ceil(newvalue / 1000) * 1000;;
    } else if (diferencenew >= 100) {
      // Aproximar a unidades de centenas
      value= Math.ceil(newvalue / 100) * 100;
    } else if(diferencenew >=10) {
      // Aproximar a unidades de miles
      value= Math.ceil(newvalue / 10) * 10;
    }else{
      value= Math.ceil(newvalue / 1) * 1;
    }
      // Round the max value to the nearest step
  
    return { value };
  });

  return (
    <>
      {laptop575.matches?<div
        className={"d-none col-12 d-md-flex justify-content-center flex-column"}
        style={{
          background: "white",
          padding: "2%",
          height: heightContainer,
          borderRadius: borderBody,
        }}
      >
        <div className="mx-3 my-1 d-flex">
          {" "}
          <button
            onClick={() =>clickCorporative?clickCorporative(): navigate("/Dashboard")}
            className={"button_w_h_back"}
            style={{ background: "transparent", border: "none" }}
          >
            <BackArrow width={"100%"} height={"100%"} />
          </button>
          <span className="title my-auto fw-light">
            Mis <strong> Estadísticas</strong>
          </span>
        </div>
        <SemanaCustomer
          classDays={"trainerDays_days_activity"}
          idJoy={idJoy}
          dayselected={dayselected}
          semanaON={true}
          classsemana={"container_semana_activity"}
        />
        <div className="d-flex w-100 justify-content-between py-2">
          <div
            className="content-select-gender_activity d-flex"
            id={idButtonSelect}
          >
            <span
              className=" my-auto text-right"
              style={{
                whiteSpace: "nowrap",
                fontSize: "calc(0.5rem + 0.5vw)",
                color: "var(--elevvaTextTitle)",
              }}
            >
              Mi actividad /
            </span>{" "}
            <select
              className="form-control selector_items_activity"
              name="actividad"
              style={{
                border: "none",
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(0.9rem + 0.4vw)",
              }}
              onChange={changeType}
            >
              <option value="99">HR</option>
              <option value="14">Tensión</option>
              <option value="kcal">Calorías</option>
              <option value="distance">Distancia</option>
              {/* <option value="">Calorías</option>
              <option value="">Pasos</option> */}
            </select>{" "}
            <i></i>
          </div>
          <div className="d-flex">
          <div
            className="content-select-gender_activity position-relative me-2"
            id={idJoyOpenSelect}
          >
            <select
              id="periodoSelect"
              className="form-control selector_items_time"
              name="time"
              style={{ color: "var(--elevvaTextTitle)", fontSize: "auto" }}
              onChange={changeRange}
            >
              <option value="">Seleccionar período</option>
              <option value="cw">Esta semana</option>
              <option value="cm">Este mes</option>
              <option value="lm">Mes anterior</option>
              <option value="cq">Este trimestre</option>
              <option value="cy">Este Año</option>
              <option value="ly">Año anterior</option>
            </select>
            <i></i>
          </div>
          <div
            className="content-select-gender_activity position-relative"
           
          >
            <select
           
           className="form-control selector_items_time"
              name="frecuency"
              style={{ color: "var(--elevvaTextTitle)", fontSize: "auto" }}
               onChange={changeFrecuency}
            >
            <option value="">Seleccionar frecuencia</option>
              {Range == "cw" ?<option value="hour">Por hora</option>:null}
              { Range == "cm" || Range == "lm"?<option value="day">Por días</option>:null}
              {Range == "cq" || Range == "cy" || Range == "ly"?<option value="month">Por mes</option>:null}
             
            </select>
            <i></i>
          </div>

          </div>
        

        </div>
       {data && data[0].data.length>0? <div className="mx-auto"
          id={idGraphics}
          style={{
            height: "100%",
            minHeight: "33vmin",
            position: "relative",
            width: "100%",
          }}
        >
      <ResponsiveLine
                        data={data}
                        margin={{
                          top: 20,
                          right: 38,
                          bottom: 40,
                          left: 38,
                        }}
                        xScale={{
          type: "point",
          format: "%Y-%m-%d",
          min: "auto",
           max: 'auto',
        }}
       
                        yScale={{
                          type: "linear",
                          min: formatMin(),
                          max: "auto",
                          reverse: false,
                        }}
                        axisTop={null}
                        axisRight={null}
                        enableGridX={false}
                        lineWidth={2}
                        axisBottom={{
                          format: xAxisFormat,
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "",
                          legendOffset: 36,
                          legendPosition: "middle",
                           // Display dates in Spanish
                        }}
                        curve="monotoneX"
                        axisLeft={data[0].data && data[0].data.length>1 || data.length>1? { format: yAxisFormat,
                          orient: "left",
                          tickSize: 4,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendOffset: 40,
                          legendPosition: "middle",
                          tickValues: yTicks.map((tick) => tick.value 
                          )}:{
                          format: yAxisFormat,
                          orient: "left",
                          tickSize: 4,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendOffset: 40,
                          legendPosition: "middle"
                         
                          
                        }}
                        colors={[colorType(Type),"var(--elevva)"]}
                        theme={theme1}
                        pointSize={9}
                        pointColor={"#ffffff"}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: "serieColor" }}
                        pointLabel="y"
                         enableArea={true}
                        pointLabelYOffset={-12}
                        areaOpacity={0.3}
                        defs={[
                          {
                            id: "gradientC",

                            type: "linearGradient",

                            colors: [
                              { offset: 15, color: colorType(Type) },

                              { offset: 100, color: "#ffff" },
                            ],
                          },
                        ]}
                        fill={[{ match: "*", id: "gradientC" }]}
                        useMesh={true}
                        tooltip={(data) => {
              // Aquí creamos nuestro tooltip personalizado
              // Extrayendo el nombre de la serie y el valor del punto;
         
              const { point } = data;
           
              return (
              
                  <div key={point.serieId}
                    className="text-center p-2 elevation-18"
                    style={{
                      color: "#A8C2D7",
                      background: "#fff",
                      fontSize: "calc(0.6rem + 0.2vmin)",
                      borderRadius: "5px",
                      maxWidth:"4rem"
                    }}
                  >
                    {point.serieId} <br /> {Math.round(point.data.yFormatted)}
                  </div>
               
              );
            }}  /> 
        </div>:<div
          className="d-flex justify-content-center m-auto flex-column text-center"
          style={{
            height: "100%",
            minHeight: "33vmin",
            position: "relative",
            width: "100%",
          }}
        ><span style={{fontSize: "calc(0.5rem + 0.5vw)", color: "var(--elevvaTextTitle)",}}>No tiene datos</span></div>}
      </div>:null}
      {/* mobile */}
      <div 
        className={"col-12  d-flex d-md-none flex-column h-100"}
        style={{
          background: "white",
          padding: "2% 0",
          height: heightContainer,
          borderRadius: borderBody,
        }}
      >
        <div className="mx-3 my-1 d-flex">
          {" "}
          <button
            onClick={() => navigate("/Dashboard")}
            className={"button_w_h_back " + buttonBack}
            style={{ background: "transparent", border: "none" }}
          >
            <BackArrow width={"100%"} height={"100%"} />
          </button>
          {buttonBack == ""?<span className={"title " + centerTitle } >
            Mis <strong> Estadísticas</strong>
          </span>:<span className={"title " + centerTitle } style={{fontSize:"1.5rem"}}>
            Mis  Estadísticas
          </span>}
        </div>
        <SemanaCustomer
          classDays={"trainerDays_days_activity"}
          idJoy={idJoy}
          dayselected={dayselected}
          semanaON={false}
          classsemana={"container_semana_activity_mobile"}
        />
        <div style={{background:"#F9F9F9",padding:"3%"}} id={idGraphics}>
        <div style={{background:"#FFFF",borderRadius:"20px"}}>
        {/* primero */}
        <div className="d-flex w-100 justify-content-center py-2 px-3">
          <div
            className="content-select-gender_activity d-flex me-auto"
            id={idButtonSelect}
          >
            <span
              className=" my-auto text-right"
              style={{
                whiteSpace: "nowrap",
                fontSize: "calc(0.7rem + 0.4vw)",
                color: "var(--elevvaTextTitle)",
              }}
            >
              Mi actividad /
            </span>{" "}
            <select
              className="form-control selector_items_activity"
              name="actividad"
              style={{
                border: "none",
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(0.7rem + 0.4vw)",
              }}
              onChange={changeType}
            >
          <option value="99">HR</option>
              <option value="14">Tensión</option>
              <option value="kcal">Calorías</option>
              <option value="distance">Distancia</option>
            </select>{" "}
            <i></i>
          </div>
        
        </div>
        {/* segundo */}
        <div className="d-flex w-100 justify-content-center p-2">
          
          <div
            className="content-select-gender_activity position-relative me-auto"
            id={idJoyOpenSelect}
          >
            <select
              id="periodoSelect"
              className="form-control selector_items_time"
              name="time"
              style={{ color: "var(--elevvaTextTitle)", fontSize: "auto" }}
              onChange={changeRange}
            >
            <option value="">Elegir período</option>
              <option value="cw">Esta semana</option>
              <option value="cm">Este mes</option>
              <option value="lm">Mes anterior</option>
              <option value="cq">Este trimestre</option>
              <option value="cy">Este Año</option>
              <option value="ly">Año anterior</option>
            </select>
            <i></i>
          </div>
          <div
            className="content-select-gender_activity position-relative ms-auto"
          
          >
            <select
              id="frecuenciaSelect"
              className="form-control selector_items_time"
              name="time"
              style={{ color: "var(--elevvaTextTitle)", fontSize: "auto" }}
              onChange={changeFrecuency}
            >
            <option value="">Elegir frecuencia</option>
            {Range == "cw" ?<option value="hour">Por hora</option>:null}
              { Range == "cm" || Range == "lm"?<option value="day">Por días</option>:null}
              {Range == "cq" || Range == "cy" || Range == "ly"?<option value="month">Por mes</option>:null}
            </select>
            <i></i>
          </div>
        </div>
        {data && data[0].data.length>0? <div
          style={{
            height: 250,
            marginBottom: "0vh",
            width: "100%",
            marginLeft: "0%",
          }}
        >
          <ResponsiveLine
            data={data}
            margin={{
              top: 50,
              right: 38,
              bottom: 50,
              left: 38,
            }}
            xScale={{
          type: "point",
          format: "%Y-%m-%d",
          min: "auto",
           max: 'auto',
        }}
        curve="monotoneX"
       
            yScale={{
              type: "linear",
              min: formatMin(),
              max: "auto",
              step:20,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            enableGridX={false}
            lineWidth={2}
            axisBottom={{
              format: xAxisFormat,
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={data[0].data && data[0].data.length>1? { format: yAxisFormat,
                          orient: "left",
                          tickSize: 4,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendOffset: -40,
                          legendPosition: "middle",
                          tickValues: yTicks.map((tick) => tick.value 
                          )}:{
                          format: yAxisFormat,
                          orient: "left",
                          tickSize: 4,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendOffset: -40,
                          legendPosition: "middle"
                         
                          
                        }}
                        colors={[colorType(Type),"var(--elevva)"]}
                        theme={theme1}
            pointSize={9}
            pointColor={"#ffffff"}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="y"
            enableArea={true}
            areaOpacity={0.3}
            defs={[
              {
                id: "gradientC",

                type: "linearGradient",

                colors: [
                  { offset: 15, color: colorType(Type) },

                  { offset: 100, color: "#ffff" },
                ],
              },
            ]}
            fill={[{ match: "*", id: "gradientC" }]}
            useMesh={true}
            tooltip={(data) => {
              // Aquí creamos nuestro tooltip personalizado
              // Extrayendo el nombre de la serie y el valor del punto
              const { point } = data;
              return (
                <div>
                  <div
                    className="text-center p-2 elevation-18"
                    style={{
                      color: "#A8C2D7",
                      background: "#fff",
                      fontSize: "calc(0.6rem + 0.2vmin)",
                      borderRadius: "5px",
                    }}
                  >
                    {point.serieId} <br /> {Math.round(point.data.yFormatted)}
                  </div>
                </div>
              );
            }}
             
          />
        </div>:<div
          className="d-flex justify-content-center m-auto flex-column text-center"
          style={{
            height: "100%",
            minHeight: "33vmin",
            position: "relative",
            width: "100%",
          }}
        ><span style={{fontSize: "calc(0.7rem + 0.4vw)", color: "var(--elevvaTextTitle)",}}>No tiene datos</span></div>}
        </div>
        </div>
       
      </div>
    </>
  );
};

export const Averages = (props) => {
  const idJoyProm = props.idJoyProm || "";
  const corporate = props.corporate || "";
  const stepIndex = props.stepIndex || 0;
  const [arterial_prom, setarterial_prom] = useState("---/--");
  const [cardiaca_prom, setcardiaca_prom] = useState("---");
  const [recorrida_prom, setrecorrida_prom] = useState("---");
  const [calorias_prom, setcalorias_prom] = useState("---");

  const TrainingEstadistics = [
    [
      "Tensión  <br/> arterial <br/> promedio",
      process.env.PUBLIC_URL + "/assets/img/tensionarterial3D.svg",
      "var(--colorsesionesTension)",
      "mm Hg",
      arterial_prom,
      "var(--colorsesionesTensionBack)",
    ],
    [
      "Frecuencia <br/> cardiaca promedio",
      process.env.PUBLIC_URL + "/assets/img/heart3D.svg",
      "var(--colorsesionesHr)",
      "Ppm",
      cardiaca_prom,
      "var(--elevva)",
    ],
    [
      "Distancia recorrida  <br/>actividad libre",
      process.env.PUBLIC_URL + "/assets/img/distance3D.svg",
      "var(--colorsesionesKm)",
      "Pasos",
      recorrida_prom,
      "var(--elevvabutonLogin_User)",
    ],
    [
      "Quema <br/>de <br/>calorias",
      process.env.PUBLIC_URL + "/assets/img/cal3D.svg",
      "var(--colorsesionesCal)",
      "Kilocalorias",
      calorias_prom,
      "var(--textCal)",
    ],
  ];

  const mobileOn = props.mobileOn || false;
  const borderBody = props.borderBody || "20px";
  const widthContainer = props.widthContainer;
  let promedios;
  const updatePromedios = () => {
    if (
      (stepIndex == 5 && window.innerWidth > 768) ||
      (stepIndex == 4 && window.innerWidth < 768)
    ) {
      promedios =
        !promedios &&
        setInterval(() => {
          if (arterial_prom == "120/80") {
            setarterial_prom("---/--");
            setcardiaca_prom("---");
            setrecorrida_prom("---");
            setcalorias_prom("---");
          } else {
            setarterial_prom("120/80");
            setcardiaca_prom("120");
            setrecorrida_prom("5000");
            setcalorias_prom("105.5");
          }
        }, 1000);
    } else {
      clearInterval(promedios);
     
    }
  };

  useEffect(() => {
    updatePromedios(stepIndex);

    return () => clearInterval(promedios);
  }, [arterial_prom, stepIndex]);
  useEffect(() => {
    if (stepIndex == 0) {
      setarterial_prom("---/--");
      setcardiaca_prom("---");
      setrecorrida_prom("---");
      setcalorias_prom("---");
    }
  }, [stepIndex]);

  useEffect(() => {
    instance
    .get(`customers/metrics/summary/${corporate}`)
    .then((response) => {
          
      const summary = response.data;
      if(summary){
        const valueSteps= Math.round(summary.distance * 1.31233595800525);
        const valorDistance = valueSteps.toLocaleString('en-US', { minimumFractionDigits: 0 }).replace(',', '.');
        
        const valorKcal = summary?.kcal.toLocaleString('en-US', { minimumFractionDigits: 0 }).replace(',', '.');
       
        setcalorias_prom(valorKcal || "--")
        setrecorrida_prom(valorDistance  || "--")
        setcardiaca_prom(Math.round(summary.hr )  || "--");
        if(summary.blood_pressure ){
          let pressureS=Math.round(summary.blood_pressure.s);
          let pressureD=Math.round(summary.blood_pressure.d);
          if(pressureS>0){
          if( pressureS>999){
            pressureS= summary.blood_pressure.s.toString().slice(0, 3)  + "..."
          
          }
          if(pressureD>999){
            pressureD= summary.blood_pressure.d.toString().slice(0, 3)+"..."
          }
          
         
          setarterial_prom(pressureS + "/"+ pressureD)
        }
        }
      
      }
     
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <>
      {laptop575.matches?<div
        id={idJoyProm}
        className={
          "col-12 d-none d-md-flex justify-content-center flex-column h-100"
        }
        style={{
          background: "white",
          padding: "2%",
          borderRadius: borderBody,
          width: widthContainer,
        }}
      >
        {" "}
        <PromediosPanel
          widthCases={"100%"}
          TrainingEstadistics={TrainingEstadistics}
          idPanel={"averages"}
        />{" "}
      </div>:null}
      <div id={idJoyProm}
        className={"col-12  d-flex d-md-none flex-column h-100"}
        style={{ background: "white" }}
      >
        <AllPromedios
          spaceBetween={5}
          ancho={"9rem"}
          grow={"growingTutorial"}
          alto={"100%"}
          widthSwiper={"95%"}
          id="numbers"
          idJoyride={"totalClasesTutorialMobi"}
          // buttonClick={(e) => handleClick(e)}
          textTitleOne={"Reserva tu primer "}
          textTitleTwo={"clase grupal en vivo gratis"}
          //trainingsId={trainingsId}

          className="d-flex justify-content-center flex-column"
          TrainingEstadistics={TrainingEstadistics}
          idPanel={"promediosPanelMobile"}
        />
      </div>
    </>
  );
};

export const Level = (props) => {
  const mobileOn = props.mobileOn || false;
  const borderBody = props.borderBody || "20px";
  const widthContainer = props.widthContainer;
  const nowProgress = props.nowProgress;
  const [counter, setcounter] = useState(null);
  const [counterStorage, setcounterStorage] = useState(null);
  const idJoyLevel = props.idJoyLevel || "";
  const stepIndex = props.stepIndex || 0;
  const [mesPrograma, setmesPrograma] = useState([]);
  const userData = props.userData || null;
  useEffect(() => {
    instance
      .get(`memberships/training_plan`)
      .then((response) => {
        let dataRes = response.data;
        console.log(dataRes);
        if (
          dataRes.personal_training_plan &&
          JSON.parse(dataRes.personal_training_plan) > 0
        ) {
          setmesPrograma(JSON.parse(dataRes.personal_training_plan));
        } else {
          setmesPrograma(dataRes.training_plan);
        }
      })
      .catch((error) => {});
  }, []);
  let timer;
  useEffect(() => {
    if (userData) {
      const progressTrainig = userData?.membership?.current_plan_day;
      const arrayPlan = mesPrograma.length;

      const totalPlan = (Number(progressTrainig) * 100) / Number(arrayPlan);
      if (typeof totalPlan == "number" && progressTrainig > 1) {
        setcounter(Math.round(totalPlan));
        setcounterStorage(Math.round(totalPlan));
      } else {
        setcounter(0);
        setcounterStorage(0);
      }
    }
  }, [userData, mesPrograma]);

  useEffect(() => {
    const rootr = document.documentElement;

    if (counter) {
      var count = 0;

      rootr.style.setProperty(
        "--percentageCircularBar",
        300 - (300 * counter) / 100 + "%"
      );

      const intervalId = setInterval(() => {
        if (count == counter) {
          clearInterval(intervalId);
        } else {
          count += 1;
        }
      }, 30);
    } else {
      rootr.style.setProperty("--percentageCircularBar", -290 + "%");
    }
  }, [counter]);
  const updateCounter = () => {
    if (stepIndex == 6) {
      timer =
        !timer &&
        setInterval(() => {
          if (counter == 0) {
            setcounter(50);
          } else {
            setcounter(0);
          }
        }, 1000);
    } else {
      clearInterval(timer);
    }
  };
  useEffect(() => {
    if (stepIndex == 0) {
      setcounter(counterStorage);
    }
  }, [stepIndex]);

  useEffect(() => {
    updateCounter(stepIndex);

    return () => clearInterval(timer);
  }, [counter, stepIndex]);

  return (
    <div
      id={idJoyLevel}
      className={
        mobileOn
          ? "col-12  d-flex  flex-column h-100"
          : "col-12 d-flex justify-content-center h-100"
      }
      style={{
        background: "white",
        padding: "2%",
        borderRadius: borderBody,
        width: widthContainer,
      }}
    >
      <div className="d-flex col-12 col-md-7 flex-column justify-content-center gap-2">
        <span
          className="text-center letraPequeña "
          style={{
            color: "var(--elevvaTextTitle)",
          }}
        >
          {" "}
          Mi nivel general
        </span>
        <span
          className="text-center letraPequeña "
          style={{
            color: "var(--elevvaTextTitle)",
          }}
        >
          {userData?.customer?.level == 30
            ? "Avanzado"
            : userData?.customer?.level == 20
            ? "Intermedio"
            : userData?.customer?.level == 10
            ? "Principiante"
            : "---"}
        </span>
        <div
          className="d-flex  w-100 container-ProgressBar"
          style={{
            padding: "0 1vmin",
            justifyContent: "center",
          }}
        >
          <div className="d-flex ">
            <img
              style={{ width: "6vmin" }}
              alt="tierra"
              src={process.env.PUBLIC_URL + "/assets/img/tierra.svg"}
            />
          </div>
          <ProgressBar
            now={nowProgress}
            animated
            label={`${nowProgress}%`}
            style={{
              border: "1px solid var(--backgrounColorBar)",
              borderRadius: "10px",
              backgroundColor: "#F9F9F9",
              width: "100%",

              margin: "auto 0",
            }}
          />
        </div>
        <span
          className="text-center letraPequeña "
          style={{
            color: "var(--level-actividad-sub)",
          }}
        >
          Este es el nivel promedio que tienes según tu avance en tu plan de
          entrenamiento
        </span>
      </div>
      <div
        className="col-12 col-md-5 text-center gap-2"
        style={{
          background: "white",
          display: "flex",

          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "20px",
          alignItems: "center",
          position: "relative",
          padding: "2%",
        }}
      >
        <span
          className="letraPequeña "
          style={{
            color: "var(--elevvaTextTitle)",
          }}
        >
          Progreso de <br />
          mi entrenamiento
        </span>
        <RangeProgress idRange={"numberActivity"} valPercentage={counter} />
        <button
          data-effect="solid"
          data-place="top"
          data-tip="Acá podrás ver tu progreso de tus sesiones en tu plan de entrenamiento"
          data-background-color="#E9E3DF"
          data-border-radius="20px"
          data-text-color="#394F65"
          style={{
            position: "absolute",
            bottom: "0",
            right: "15%",
            textAlign: "center",
            color: "var(--backgroundOnboardingObjective)",
            background: "transparent",
            border: "none",
          }}
        >
          <i
            className="bi bi-question-circle-fill"
            style={{ fontSize: "1.4rem" }}
          ></i>
        </button>
        <ReactTooltip globalEventOff="" />
      </div>
    </div>
  );
};
export const LevelMobile = (props) => {
  const mobileOn = props.mobileOn || false;
  const borderBody = props.borderBody || "20px";
  const widthContainer = props.widthContainer;
  const nowProgress = props.nowProgress;
  const [counter, setcounter] = useState(null);
  const [counterStorage, setcounterStorage] = useState(null);
  const [mesPrograma, setmesPrograma] = useState([]);
  const idJoyLevel = props.idJoyLevel || "";
  const stepIndex = props.stepIndex || 0;
  const userData = props.userData || null;

  let timer;
  useEffect(() => {
    instance
      .get(`memberships/training_plan`)
      .then((response) => {
        let dataRes = response.data;
        console.log(dataRes);
        if (
          dataRes.personal_training_plan &&
          JSON.parse(dataRes.personal_training_plan) > 0
        ) {
          setmesPrograma(JSON.parse(dataRes.personal_training_plan));
        } else {
          setmesPrograma(dataRes.training_plan);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (userData) {
      const progressTrainig = userData?.membership?.current_plan_day;
      let arrayPlan = mesPrograma.length;

      const totalPlan = (Number(progressTrainig) * 100) / Number(arrayPlan + 1);
      if (
        typeof totalPlan == "number" &&
        progressTrainig > 1 &&
        arrayPlan > 0
      ) {
        setcounter(Math.round(totalPlan));
        setcounterStorage(Math.round(totalPlan));
      } else {
        setcounter(0);
        setcounterStorage(0);
      }
    }
  }, [userData, mesPrograma]);

  useEffect(() => {
    const rootr = document.documentElement;

    if (counter) {
      var count = 0;

      rootr.style.setProperty(
        "--percentageCircularBar",
        300 - (300 * counter) / 100 + "%"
      );
      const intervalId = setInterval(() => {
        if (count == counter) {
          clearInterval(intervalId);
        } else {
          count += 1;
        }
      }, 30);
    } else {
      rootr.style.setProperty("--percentageCircularBar", -290 + "%");
    }
  }, [counter]);
  const updateCounter = () => {
    if (stepIndex == 6) {
      timer =
        !timer &&
        setInterval(() => {
          if (counter == 0) {
            setcounter(50);
          } else {
            setcounter(0);
          }
        }, 1000);
    } else {
      clearInterval(timer);
    }
  };
  useEffect(() => {
    if (stepIndex == 0) {
      setcounter(counterStorage);
    }
  }, [stepIndex]);

  useEffect(() => {
    updateCounter(stepIndex);

    return () => clearInterval(timer);
  }, [counter, stepIndex]);

  return (
    <div
      id={idJoyLevel}
      className={
        mobileOn
          ? "col-12  d-flex  flex-column h-100"
          : "col-12 d-flex justify-content-center h-100"
      }
      style={{
        background: "white",
        padding: "2%",
        borderRadius: borderBody,
        width: widthContainer,
      }}
    >
      <div className="d-flex col-12 col-md-7 flex-column justify-content-center gap-2">
        <span
          className="text-center letraPequeña "
          style={{
            color: "var(--elevvaTextTitle)",
          }}
        >
          {" "}
          Mi nivel general
        </span>
        <span
          className="text-center letraPequeña "
          style={{
            color: "var(--elevvaTextTitle)",
          }}
        >
          {/* {userData?.customer?.level == 30
          ? "Avanzado"
          : userData?.customer?.level == 20
          ? "Intermedio"
          : userData?.customer?.initial_level==30 ?"Avanzado":userData?.customer?.initial_level==20?"Intermedio":"Principiante"} */}
          {userData?.customer?.level == 30
            ? "Avanzado"
            : userData?.customer?.level == 20
            ? "Intermedio"
            : userData?.customer?.level == 10
            ? "Principiante"
            : "---"}
        </span>
        <div
          className="d-flex  w-100 container-ProgressBar"
          style={{
            padding: "0 1vmin",
            justifyContent: "center",
          }}
        >
          <div className="d-flex ">
            <img
              style={{ width: "6vmin" }}
              alt="tierra"
              src={process.env.PUBLIC_URL + "/assets/img/tierra.svg"}
            />
          </div>
          <ProgressBar
            now={nowProgress}
            animated
            label={`${nowProgress}%`}
            style={{
              border: "1px solid var(--backgrounColorBar)",
              borderRadius: "10px",
              backgroundColor: "#F9F9F9",
              width: "100%",

              margin: "auto 0",
            }}
          />
        </div>
        <span
          className="text-center letraPequeña "
          style={{
            color: "var(--level-actividad-sub)",
          }}
        >
          Este es el nivel promedio que tienes según tu avance en tu plan de
          entrenamiento
        </span>
      </div>
      <div
        className="col-12 col-md-5 text-center gap-2"
        style={{
          background: "white",
          display: "flex",

          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "20px",
          alignItems: "center",
          position: "relative",
          padding: "2%",
        }}
      >
        <span
          className="text-left letraPequeña "
          style={{
            color: "var(--elevvaTextTitle)",
          }}
        >
          Progreso de{" "}
          <strong>
            <br />
            mi entrenamiento
          </strong>
        </span>
        <RangeProgress
          idRange={"numberActivity_mobile"}
          radius={"4.40rem"}
          valPercentage={counter}
        />
        <button
          data-effect="solid"
          data-place="top"
          data-tip="Acá podrás ver tu progreso de tus sesiones en tu plan de entrenamiento"
          data-background-color="#E9E3DF"
          data-border-radius="20px"
          data-text-color="#394F65"
          style={{
            position: "absolute",
            bottom: "0",
            right: "15%",
            textAlign: "center",
            color: "var(--backgroundOnboardingObjective)",
            background: "transparent",
            border: "none",
          }}
        >
          <i
            className="bi bi-question-circle-fill"
            style={{ fontSize: "1.4rem" }}
          ></i>
        </button>
        <ReactTooltip globalEventOff="" />
      </div>
    </div>
  );
};
