import {
  ButtonArrow,
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  perlo,
  instance,
  NuevaAxiosURL
} from "../../utils/utils";
import React, { useRef } from "react";
import "./inicio.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import ReactHtmlParser from "react-html-parser";
import { Allvideos } from "../videosComponent/videos";
import { ResponsiveLine } from "@nivo/line";
//spinner
import { BarLoader  } from "react-spinners";
// import elevva from "../../assets/img/general/logo"

// Buton for class grups
import { ButtonMob } from "../../utils/utils";
import { use } from "i18next";

const Inicio = (props) => {

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    instance
      .get("users/current/")
      .then((response) => {
        const data = response.data
        //console.log(data, "data. local");
        const { first_name, last_name, id } = response.data;
        const customer = response.data.customer;
        const profilePic = customer.profile_pic;
        localStorage.setItem("id", id)
        localStorage.setItem("first_name", first_name);
        localStorage.setItem("last_name", last_name);
        localStorage.setItem('profile_pic', profilePic);
        //localStorage.setItem()
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  // Date user  
  //console.log(userData, "soy dattaa");
  const firstName = localStorage.getItem("first_name");
  const lastName = localStorage.getItem("last_name");
  const profilePic = localStorage.getItem("profile_pic");
  const id = localStorage.getItem("id");
  //console.log(id);
  const pathHere = props.path;

  const navigate = useNavigate();
  const elevvaT = props.elevvaT;
  const theme1 = {
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
      },
      ticks: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
        text: {
          fill: "var(--elevvaArrowColor)",
        },
      },
      legend: {
        text: {
          fontSize: "3vw",

          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "var(--backgroundGraficGrid)",

        strokeWidth: 1,
      },
    },
  };
  const Barra = [
    [
      "Progreso",
      <i className="bi bi-house"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/Inicio",
    ],
    [
      "Mi perfil",
      <i class="bi bi-person-circle"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Videos de bienestar y entrenamiento",
      <i className="bi bi-play-btn-fill"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Reserva de clases",
      <i className="bi bi-calendar-date"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/groupals",
    ],
    [
      "Adquiere equipos",
      <i className="bi bi-box2"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Preguntas frecuentes",
      <i className="bi bi-chat-right-quote"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Términos y condiciones políticas de privacidad",
      <i class="bi bi-pencil-square"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Cerrar sesión",
      <i className="bi bi-box-arrow-left"></i>,
      useRef(null),
      useRef(null),
      "out",
    ],
  ];
  useEffect(() => {
    setModalInfo(
      <ModalInfoMembresias
        handleCancellcargue={() => setModalInfo(null)}
        error="tru"
        text={
          "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
        }
        classesMsg=""
      />
    );
    var activeme = Barra.filter((e) => e[0] === pathHere);

    if (activeme[0][2]) {
      activeme[0][2].current.classList =
        "d-flex option-page fw-bold  py-3 active";
      activeme[0][2].current.active = true;
    }
  }, [pathHere]);
  const avisos = {
    aviso_one: [
      "Adquiere todos los beneficios de ser elevva+. Selecciona tu plan ahora y eleva tu estilo de vida",
      "Inicia tu prueba gratuita de 7 dias.",
      "var(--barraPublicidad)",
    ],
    aviso_two: [
      "Mejora tu plan y disfruta de muchos más beneficios con elevva+",
      "Mejorar tu plan.",
      "var(--elevvaTextTitle)",
    ],
    aviso_three: [
      "Tu prueba gratuita ha expirado. Selecciona un plan y sigue disfrutando elevva+. ",
      "Activar plan.",
      "var(--elevva)",
    ],
  };

  const [counter, setcounter] = useState(0);
  const [barraPublicidad, setbarraPublicidad] = useState(true);
  // const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_two`]);
  const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_one`]);
  const [modalInfo, setModalInfo] = useState(null);
  useEffect(() => {
    const rootr = document.documentElement;
    var number = document.getElementById("number");
    var count = 0;
    rootr.style.setProperty(
      "--percentageCircularBar",
      // 300 - (300 * counter) / 100 + "%"
      "300"
    );
    setInterval(() => {
      if (count === counter) {
        clearInterval();
      } else {
        count += 1;
        // if (number) number.innerHTML = count + "%";
        if (number) number.innerHTML = "0%";
      }
    }, 30);
  }, [counter]);
  useEffect(() => {
    setcounter(50);
  }, []);
  const dayselected = {
    lunes: false,
    martes: false,
    miércoles: false,
    jueves: false,
    viernes: false,
    sábado: false,
    domingo: false,
  };
  const TrainingEstadistics = [
    [
      "Frecuencia cardiaca promedio",
      process.env.PUBLIC_URL + "/assets/img/heart3D.svg",
      "var(--colorsesionesHr)",
      "Ppm",
      "120",
      "var(--elevva)",
    ],
    [
      "Distancia recorrida  <br/>actividad libre",
      process.env.PUBLIC_URL + "/assets/img/distance3D.svg",
      "var(--colorsesionesKm)",
      "Pasos",
      "5000",
      "var(--elevvabutonLogin_User)",
    ],
    [
      "Quema <br/>de calorias",
      process.env.PUBLIC_URL + "/assets/img/cal3D.svg",
      "var(--colorsesionesCal)",
      "Kilocalorias",
      "105.5",
      "var(--textCal)",
    ],
  ];
  const disciplinas = [
    [
      "Bicicleta",
      process.env.PUBLIC_URL + "/assets/img/onboarding/biciB.svg",
      "05/03/09",
    ],
    [
      "Correr",
      process.env.PUBLIC_URL + "/assets/img/onboarding/caminarA.svg",
      "10/03/09",
    ],
  ];
  const data = [
    {
      id: "Calorias",
      data: [
        { x: "Lun", y: 120 },
        { x: "Mar", y: 165 },
        { x: "Mier", y: 22 },
        { x: "Jue", y: 190 },
        { x: "Vie", y: 190 },
        { x: "Sab", y: 190 },
        { x: "Dom", y: 190 },
      ],
    },
  ];

  const selectOption = (
    refContainer,
    index,
    classess,
    arr,
    onlyone,
    pathHere
  ) => {
    if (onlyone === true) {
      for (const iterator of arr) {
        
        iterator[2].current.classList = classess;
        iterator[2].current.active = false;
        iterator[3] = false;
      }
    }

    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (pathHere == "out") {
      perlo();
    } else {
      return navigate(pathHere);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  // Simulando una carga de datos
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);


  return (
    <>
    {/* {isLoading ? (
         <div className="spinner-container-welcome flex-column">
         <img className="my-2" src={elevva} alt="Welcome" />
               <BarLoader  color="#8d97fd" width={100} />
             </div>
           ) : ( */}
    <div
      className="d-flex col-12 "
      style={{
        height: "100%",
      }}
    >
      <div
        className="col-2"
        style={{
          flexDirection: "column",
        }}
      >
        <div
          className=" w-100 mx-auto py-4  text-center"
          style={{ height: "20%" }}
        >
          <img
            alt="logo_alta_black"
            style={{ padding: "5% 20%", width: "80%" }}
            src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
          />
        </div>
        <div
          className=" w-100"
          style={{
            height: "80%",
          }}
        >
          {Barra.map((elementos, index) => (
            <div
              key={index}
              ref={elementos[2]}
              style={{ fontWeight: "600" }}
              className="d-flex option-page text-color-title  py-3 "
              onClick={() =>
                selectOption(
                  elementos[2],
                  index,
                  "d-flex option-page fw-bold  py-3 ",
                  Barra,
                  true,
                  elementos[4]
                )
              }
            >
              <div className="my-auto mx-2 Iconresponsive">{elementos[1]} </div>
              <div className="mx-2 letraPequeña">{elementos[0]} </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="col-10  position-relative"
        style={{
          background: "var(--backgroundOnboardingContain)",
          height: "100vh",

          padding: "1% 2% 2% 1%",
        }}
      >
        {modalInfo}
        <div
          className={barraPublicidad ? `d-flex ` : `d-none `}
          style={{
            background: avisosvarios[2],
            color: "var(--white)",
            maxWidth: "70%",
            padding: "5px 7vmin",
            justifyContent: "center",
            flexDirection: "row",
            alignContent: "center",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          <span
            className=" my-auto mx-2"
            style={{
              fontSize: "1.3vmin",
            }}
          >
            {avisosvarios[0]}{" "}
            <Link
              className="fw-bold"
              style={{ color: "var(--white)" }}
              // to="/ejercicios"
              to="/Usuario/groupals" //Reserva clases grupales
            >
              {avisosvarios[1]}
            </Link>
          </span>
          <span
            className="  my-auto"
            style={{
              fontSize: "1.5vmin",
              cursor: "pointer",
            }}
            onClick={() => setbarraPublicidad(!barraPublicidad)}

          >
            <i className="bi bi-x-circle"></i>
          </span>
        </div>
        <div className="d-flex">
          <div className="col-9">
            <div
              style={{
                height: "98%",
                padding: "1%",
                flexDirection: "column",
              }}
            >
              <div
                className=" d-flex"
                style={{
                  height: "30%",
                  gap: "10px",
                  margin: "1% 0px 0px",
                }}
              >
                <div
                  className=" mx-auto position-relative d-flex"
                  style={{
                    background: "white",
                    width: "30%",
                    padding: "2%",
                    borderRadius: "20px",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="text-center letraPequeña"
                    style={{ color: "var(--elevvaTextTitle)" }}
                  >
                    Reserva <strong>tu próxima clase</strong>{" "}
                  </span>
                  <Link
                    to="/Usuario/groupals"
                    className=" text-center d-flex resplandor"
                    style={{
                      textDecoration: "none",
                      backgroundImage: `url(${process.env.PUBLIC_URL +
                        "/assets/img/CLASES_GRUPALES.jpg"
                        })`,
                      backgroundPosition: "40%  30%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",

                      borderRadius: "15px",
                      padding: "7% 8%",
                      margin: "2%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="direcccionately"
                      style={{ textAlign: "left" }}
                    >
                      <span
                        className="m-auto"
                        style={{
                          color: "var(--elevvaTextTitle)",
                          fontSize: "2vmin",
                          lineHeight: "2vmin",
                        }}
                      >
                        Clases
                        <br />
                        <strong>grupales</strong>
                      </span>
                    </div>
                    <ButtonArrow
                      classes="btnarrowPanelColor"
                      colorBack={`var(--white)`}
                      icon="bi bi-chevron-right"
                    />
                  </Link>
                  <div
                    className=" text-center d-flex"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/img/INDI.jpg"
                        })`,
                      backgroundPosition: "80%  50%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",

                      borderRadius: "15px",
                      padding: "7% 8%",
                      margin: "2%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="direcccionately"
                      style={{ textAlign: "left" }}
                    >
                      <span
                        className=" "
                        style={{
                          color: "var(--elevvaTextTitle)",
                          justifyContent: "left",
                          fontSize: "2vmin",
                          lineHeight: "2vmin",
                        }}
                      >
                        Clases
                        <br />
                        <strong>individuales</strong>
                      </span>
                    </div>
                    <ButtonArrow
                      classes="btnarrowPanelColor"
                      colorBack={`var(--white)`}
                      icon="bi bi-chevron-right"
                    />
                  </div>
                </div>
                <div
                  className=" mx-auto d-flex"
                  style={{
                    width: "70%",
                    background: "var(--white)",
                    borderRadius: "20px",
                    padding: "2%",
                    gap: "10px",
                  }}
                >
                  {TrainingEstadistics.map((e, i) => (
                    <div
                      key={i}
                      style={{
                        background: e[2],
                        width: "calc(100%/3)",
                        borderRadius: "20px",
                        padding: "2% 0% 2% 5%",
                      }}
                    >
                      <button
                        style={{
                          background: "var(--white)",
                          borderRadius: "20px",
                          border: "1px solid transparent",
                        }}
                      >
                        <img
                          alt="e1"
                          src={e[1]}
                          style={{ width: "6vmin", height: "6vmin" }}
                        />
                      </button>

                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          marginTop: "10%",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          className="fw-bold letraPequeñaother"
                          style={{ color: e[5], lineHeight: "90%" }}
                        >
                          {ReactHtmlParser(e[0])}
                        </span>

                        <span
                          className="fw-bold "
                          style={{
                            color: "var(--elevvaTextTitle)",
                            fontSize: "3vmin",
                          }}
                        >
                          {/* Se muestra la carga usuario */}
                          {/* {e[4]} */}
                          {"---"}
                        </span>

                        <span
                          className="letraPequeñaother"
                          style={{ color: "var(--elevvaTextTitle)" }}
                        >
                          {e[3]}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="d-flex"
                style={{
                  height: "70%",

                  gap: "10px",
                  margin: "1% 0 0 0",
                  borderRadius: "20px",
                }}
              >
                <div
                  className="position-relative"
                  style={{
                    background: "white",
                    width: "calc(100%/2)",
                    borderRadius: "20px",
                    flexDirection: "column",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <br />
                  <span
                    className=" letraPequeña"
                    style={{
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    Conoce <strong>tu cuerpo</strong>
                    <hr />
                  </span>
                  <div
                    className=" letraPequeña d-flex w-100 "
                    style={{
                      padding: "0 10vmin",
                      justifyContent: "space-evenly",
                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    <span className="letraBody m-auto">
                      <img
                        alt="bascula"
                        style={{ width: "2.5vmin" }}
                        className="mx-1"
                        src={process.env.PUBLIC_URL + "/assets/img/bascula.svg"}
                      />
                      {/* <strong>Actual:</strong> 78,8 Kg */}
                      <strong>Actual:</strong> ---
                    </span>
                    <span className="letraBody m-auto">
                      <img
                        alt="bascula"
                        style={{ width: "2.5vmin" }}
                        className="mx-1"
                        src={process.env.PUBLIC_URL + "/assets/img/bascula.svg"}
                      />{" "}
                      <strong>Objetivo: </strong>
                      {/* <span
                        className="letraBody"
                        style={{ color: "var(--elevva)" }}
                      >
                        70,8 Kg
                      </span> */}
                      <span
                        className="letraBody"
                        style={{ color: "var(--elevva)" }}
                      >
                        ---
                      </span>
                    </span>
                  </div>
                  <img
                    alt="FRENTE"
                    className="figurePartsInicio"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/onboarding/FRENTE.png"
                    }
                  />
                  <div
                    className="position-absolute "
                    style={{
                      top: "22%",
                      left: "10%",
                      width: "30%",
                      transform: "rotate(-360deg)",
                    }}
                  >
                    <span
                      className="letraBodyInside"
                      style={{ color: "var(--elevvabutonLogin_User)" }}
                    >
                      Brazo derecho
                    </span>
                    <div style={{ lineHeight: "1.5vmin" }}>
                      {" "}
                      <span className="letraBodyInside">
                        {/* <i className="bi bi-caret-down-fill"></i> Gc: 5,8% */}
                        <i className="bi bi-caret-down-fill"></i> Gc: ---

                      </span>
                      <br />
                      <span className="letraBodyInside">
                        <i
                          className="bi bi-caret-up-fill"
                          style={{ color: "var(--elevvabutonLogin_User)" }}
                        ></i>{" "}
                        {/* Mm: 37 kg */}
                        Mm: ---
                      </span>
                    </div>
                  </div>
                  <div
                    className="position-absolute"
                    style={{
                      top: "22%",
                      right: "10%",
                      width: "30%",
                      textAlign: "right",
                    }}
                  >
                    <span
                      className="letraBodyInside "
                      style={{ color: "var(--elevvabutonLogin_User)" }}
                    >
                      Brazo izquierdo
                    </span>
                    <div style={{ lineHeight: "1.5vmin" }}>
                      {" "}
                      <span className="letraBodyInside ">
                        {/* Gc: 5,8% */}
                        Gc: ---
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <br />
                      <span className="letraBodyInside ">
                        {/* Mm: 37 kg{" "} */}
                        Mm: ---
                        <i
                          className="bi bi-caret-up-fill"
                          style={{ color: "var(--elevvabutonLogin_User)" }}
                        ></i>{" "}
                      </span>
                    </div>
                  </div>

                  <span
                    className="position-absolute linebodyInicio"
                    style={{ top: "30%", left: "10%", width: "30%" }}
                  >
                    <hr />
                    <span
                      className="rounded-circle position-absolute"
                      style={{
                        background: "var(--elevvaTexttitleLow)",
                        width: "8px",
                        height: "8px",
                        top: "40%",
                        left: "100%",
                      }}
                    ></span>
                  </span>
                  <div
                    className="position-absolute "
                    style={{
                      top: "48%",
                      left: "10%",
                    }}
                  >
                    <span
                      className="letraBodyInside"
                      style={{ color: "var(--elevvabutonLogin_User)" }}
                    >
                      Abdomen
                    </span>
                    <div style={{ lineHeight: "1.5vmin" }}>
                      {" "}
                      <span className="letraBodyInside">
                        {/* <i className="bi bi-caret-down-fill"></i> Gc: 5,8% */}
                        <i className="bi bi-caret-down-fill"></i> Gc: ---
                      </span>
                      <br />
                      <span className="letraBodyInside">
                        <i
                          className="bi bi-caret-up-fill"
                          style={{ color: "var(--elevvabutonLogin_User)" }}
                        ></i>{" "}
                        {/* Mm: 37 kg */}
                        Mm: ---
                      </span>
                    </div>
                  </div>
                  <div
                    className="position-absolute "
                    style={{
                      top: "70%",
                      left: "10%",
                      width: "30%",
                      transform: "rotate(-360deg)",
                    }}
                  >
                    <span
                      className="letraBodyInside"
                      style={{ color: "var(--elevvabutonLogin_User)" }}
                    >
                      Pierna derecha
                    </span>
                    <div style={{ lineHeight: "1.5vmin" }}>
                      {" "}
                      <span className="letraBodyInside">
                        {/* <i className="bi bi-caret-down-fill"></i> Gc: 5,8% */}
                        <i className="bi bi-caret-down-fill"></i> Gc: ---
                      </span>
                      <br />
                      <span className="letraBodyInside">
                        <i
                          className="bi bi-caret-up-fill"
                          style={{ color: "var(--elevvabutonLogin_User)" }}
                        ></i>{" "}
                        {/* Mm: 37 kg */}
                        Mm: ---
                      </span>
                    </div>
                  </div>
                  <div
                    className="position-absolute"
                    style={{
                      top: "70%",
                      right: "10%",
                      width: "30%",
                      textAlign: "right",
                    }}
                  >
                    <span
                      className="letraBodyInside "
                      style={{ color: "var(--elevvabutonLogin_User)" }}
                    >
                      Pierna izquierda
                    </span>
                    <div style={{ lineHeight: "1.5vmin" }}>
                      {" "}
                      <span className="letraBodyInside ">
                        {/* Gc: 5,8% */}
                        Gc: ---
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <br />
                      <span className="letraBodyInside ">
                        {/* Mm: 37 kg{" "} */}
                        Mm: ---
                        <i
                          className="bi bi-caret-up-fill"
                          style={{ color: "var(--elevvabutonLogin_User)" }}
                        ></i>{" "}
                      </span>
                    </div>
                  </div>
                  <span
                    className="position-absolute linebodyInicio"
                    style={{ top: "45%", left: "10%", width: "38%" }}
                  >
                    <hr />
                    <span
                      className="rounded-circle position-absolute "
                      style={{
                        background: "var(--elevvaTexttitleLow)",
                        width: "8px",
                        height: "8px",
                        top: "40%",
                        left: "100%",
                      }}
                    ></span>
                  </span>
                  <span
                    className="position-absolute linebodyInicio"
                    style={{
                      top: "30%",
                      right: "10%",
                      width: "30%",
                      transform: "rotate(-180deg)",
                    }}
                  >
                    <hr />
                    <span
                      className="rounded-circle position-absolute "
                      style={{
                        background: "var(--elevvaTexttitleLow)",
                        width: "8px",
                        height: "8px",
                        top: "40%",
                        left: "100%",
                      }}
                    ></span>
                  </span>
                  <span
                    className="position-absolute linebodyInicio"
                    style={{ top: "66%", left: "10%", width: "35%" }}
                  >
                    <hr />
                    <span
                      className="rounded-circle position-absolute"
                      style={{
                        background: "var(--elevvaTexttitleLow)",
                        width: "8px",
                        height: "8px",
                        top: "40%",
                        left: "100%",
                      }}
                    ></span>
                  </span>
                  <span
                    className="position-absolute linebodyInicio"
                    style={{
                      top: "66%",
                      right: "10%",
                      width: "35%",
                      transform: "rotate(-180deg)",
                    }}
                  >
                    <hr />
                    <span
                      className="rounded-circle position-absolute"
                      style={{
                        background: "var(--elevvaTexttitleLow)",
                        width: "8px",
                        height: "8px",
                        top: "40%",
                        left: "100%",
                      }}
                    ></span>
                  </span>
                  <span
                    className=" row"
                    style={{
                      position: "absolute",

                      padding: "1% 0%",
                      bottom: "5%",
                      width: "15vmin",
                      borderRadius: "10px",
                      left: "11%",
                      border: "1px solid var(--elevvaTexttitleLow)",
                    }}
                  >
                    <span className="letraBodyInsidemoresmall ">
                      Gc:Grasa corporal
                    </span>
                    <span className="letraBodyInsidemoresmall ">
                      Mm:Masa muscular
                    </span>
                  </span>
                  <button
                    className="m-auto d-flex position-absolute"
                    style={{
                      color: "var(--white)",
                      position: "absolute",
                      bottom: "5%",
                      right: "5%",
                      padding: "1% 5%",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      verticalAlign: "middle",
                      background: "var(--elevva)",
                      borderRadius: "20px",
                      border: "1px solid var(--elevva)",
                      fontSize: "1.4vmin",
                    }}
                    onClick={() => navigate(`/Individuales`)}
                  >
                    Ver más <i className="bi bi-chevron-right"></i>
                  </button>

                </div>
                <div
                  style={{
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateColumns: "auto",
                    width: "50%",
                    height: "100%",
                    gap: "10px",
                  }}
                >
                  <div
                    className="direcccionately"
                    style={{
                      background: "white",
                      height: "100%",
                      borderRadius: "20px",

                      padding: "4% 5%",
                    }}
                  >
                    {" "}
                    <span
                      className="letraPequeña py-1"
                      style={{
                        color: "var(--elevvaTextTitle)",
                      }}
                    >
                      Mi <strong>actividad</strong>
                    </span>
                    <div
                      style={{
                        height: "100%",
                        maxHeight: "25vmin",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <ResponsiveLine
                        data={data}
                        margin={{ top: 10, right: 15, bottom: 20, left: 30 }}
                        xScale={{ type: "point" }}
                        curve="monotoneX"
                        axisTop={null}
                        axisRight={null}
                        enableGridX={false}
                        lineWidth={5}
                        axisBottom={{
                          orient: "bottom",
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: "",
                          legendOffset: 36,
                          legendPosition: "middle",
                        }}
                        axisLeft={{
                          orient: "left",
                          tickSize: 4,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendOffset: 40,
                          legendPosition: "middle",
                        }}
                        colors={["var(--pointsGrafica)"]}
                        theme={theme1}
                        pointSize={10}
                        pointColor={"#ffffff"}
                        pointBorderWidth={3}
                        pointBorderColor="var(--pointsGraficaCircle)"
                        pointLabel="y"
                        pointLabelYOffset={-12}
                        enableArea={true}
                        areaOpacity={0.8}
                        defs={[
                          {
                            id: "gradientC",

                            type: "linearGradient",

                            colors: [
                              { offset: 100, color: "var(--downGrafica)" },

                              // { offset: 100, color: "var(--downGrafica)" },
                            ],
                          },
                        ]}
                        // areaBaselineValue={data[0].data.map((e, i) => e.y).sort()[0]}
                        fill={[{ match: "*", id: "gradientC" }]}
                        useMesh={true}
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex w-100"
                    style={{
                      gap: "10px",
                      margin: "1% 0px 0px",
                    }}
                  >
                    <div
                      className="w-50"
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderRadius: "20px",
                        alignItems: "center",
                        position: "relative",
                        padding: "2%",
                      }}
                    >
                      <span
                        className="text-left letraPequeña"
                        style={{
                          color: "var(--elevvaTextTitle)",
                        }}
                      >
                        Progreso de
                      </span>
                      <span
                        className="text-left letraPequeña"
                        style={{
                          color: "var(--elevvaTextTitle)",
                          marginBottom: "3vmin",
                          lineHeight: "70%",
                        }}
                      >
                        <strong>mi entrenamiento</strong>
                      </span>

                      <div className="skill ">
                        <div className="outer">
                          <div className="inner">
                            <div id="number"></div>
                          </div>
                        </div>
                        <svg
                          id="svgt"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                        >
                          <defs>
                            <linearGradient id="GradientColor">
                              {/* <stop offset="0%" stop-color="#e91e63" /> */}
                              <stop
                                offset="100%"
                                //stop-color="var(--vercardtext)"
                                stopColor="#b4b4b4"
                              />
                            </linearGradient>
                          </defs>
                          <circle
                            id="circleRound"
                            cx="50%"
                            cy="50%"
                            r={"5.2vmin"}
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>

                      <button
                        onClick={() =>
                          setModalInfo(
                            <ModalPhoto
                              handleCancellcargue={() => setModalInfo(null)}
                              error="tru"
                              text={
                                "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                              }
                              classesMsg=""
                            />
                          )
                        }
                        data-effect="solid"
                        data-place="right"
                        data-tip="Ver más"
                        data-background-color="var(--elevvaTextTitle)"
                        style={{
                          // position: "absolute",
                          // bottom: "5%",
                          // right: "5%",
                          padding: "5% 0",
                          textAlign: "center",
                          color: "var(--elevva)",
                          background: "transparent",
                          border: "none",
                          fontSize: "3vmin",
                        }}
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                      <ReactTooltip globalEventOff="" />
                    </div>
                    <div
                      className="w-50"
                      style={{
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderRadius: "20px",
                        position: "relative",
                        padding: "1%",
                      }}
                    >
                      <div
                        className="d-flex mx-auto "
                        style={{ paddingBottom: "5%" }}
                      >
                        <span className="  letraPequeña  m-auto">
                          Sesiones <strong>recientes</strong>
                        </span>
                      </div>
                      <div>
                        {" "}
                        {/* {disciplinas.map((e) => (
                          <div
                            className=" text-center d-flex"
                            style={{
                              backgroundColor: "var(--colorsesiones)",
                              borderRadius: "15px",
                              padding: "3%",
                              margin: "4% 5% ",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <span>
                              <img
                                style={{ width: "3vmin" }}
                                src={e[1]}
                                alt="sesiones"
                              />
                            </span>
                            <div className="direcccionately">
                              <span
                                className="m-auto letraPequeña fw-bold"
                                style={{ color: "var(--elevvaTextTitle)" }}
                              >
                                {e[0]}
                              </span>
                              <span
                                className=""
                                style={{
                                  fontWeight: "500",
                                  color: "var(--elevvaTextTitle)",
                                  fontSize: "1.2vmin",
                                }}
                              >
                                {e[2]}
                              </span>
                            </div>
                            <ButtonArrow
                              classes="btnarrowPanel"
                              colorBack={`var(--elevvaArrowColor)`}
                              icon="bi bi-chevron-right"
                            />
                          </div>
                        ))} */}
                        <div
                          className=" text-center d-flex"
                          style={{
                            backgroundColor: "var(--colorsesiones)",
                            borderRadius: "20px",
                            padding: "3%",
                            margin: "4% 5% ",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span
                            className="m-auto letraPequeña fw-bold"
                            style={{ color: "var(--elevvaTextTitle)" }}
                          >
                            <p style={{ fontSize: "19px" }}>No tienes sesiones recientes</p>

                          </span>



                        </div>
                      </div>
                      <button
                        data-effect="solid"
                        data-place="right"
                        data-tip="Ver más"
                        data-background-color="var(--elevvaTextTitle)"
                        style={{
                          // position: "absolute",
                          // top: "5%",
                          // right: "5%",

                          textAlign: "center",
                          color: "var(--elevva)",
                          background: "transparent",
                          border: "none",
                          fontSize: "3vmin",
                        }}
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                      <ReactTooltip globalEventOff="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-3"
          // style={{
          //   padding: "1% 0%",
          // }}
          >
            <div
              style={{
                height: "100%",
                padding: "4% 0%",

                flexDirection: "column",
              }}
            >
              <div
                style={{
                  background: "var(--white)",
                  height: "100%",
                  margin: "1% 0px 0px",
                  padding: "3% 0%",
                  borderRadius: "20px",
                }}
              >
                <div
                  className="d-flex col-12 m-auto"
                  style={{ padding: "2vmin" }}
                >
                  {" "}
                  <div
                    className="d-flex col-8 "
                    style={{ justifyContent: "center" }}
                  >
                    {" "}
                    <span
                      className="fw-bold  d-flex col-5 mx-auto"
                      style={{
                        fontSize: "1.6vmin",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "var(--elevvaTextTitle)",
                      }}
                    >{profilePic ? (
                      <img
                        src={profilePic}
                        // alt="Avatar"
                        style={{
                          width: "7vmin",
                          height: "7vmin",
                          border: "1px solid var(--elevvaArrowColor)",
                        }}
                        className="rounded-circle"
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/assets/img/usuario.png"}
                        // alt="Avatar"
                        style={{
                          width: "7vmin",
                          height: "7vmin",
                          border: "1px solid var(--elevvaArrowColor)",
                        }}
                        className="rounded-circle"
                      />
                    )}
                    </span>
                    <span
                      className="fw-bold  mx-auto  d-flex col-7"
                      style={{
                        fontSize: "1.8vmin",

                        flexDirection: "column",
                        justifyContent: "center",
                        lineHeight: "2vmin",
                        color: "var(--elevvaTextTitle)",
                      }}
                    >
                      {/* localStorage.variable, se trata como objeto */}

                      {firstName && lastName ? (
                        <>{`${firstName.toUpperCase()} ${lastName.toUpperCase()}`}</>
                      ) : (
                        <>{`${localStorage.first_name.toUpperCase()} ${localStorage.last_name.toUpperCase()}`}</>
                      )}
                    </span>

                  </div>
                  <div className="d-flex col-4">
                    {" "}
                    <span
                      className="mx-auto d-flex"
                      style={{
                        fontSize: "3vmin",
                        alignItems: "center",
                        color: "var(--elevvaArrowColor)",
                      }}
                      onClick={() => alert("ewe")}
                    >
                      <i className="bi bi-envelope-fill position-relative">
                        {" "}
                        <span
                          className="position-absolute"
                          style={{
                            top: "6%",
                            left: "-10%",
                            borderRadius: "50%",
                            width: "1vmin",
                            height: "1vmin",
                            backgroundColor: "var(--colorNotifications)",
                          }}
                        ></span>
                      </i>
                    </span>
                    <span
                      className="mx-auto d-flex"
                      style={{
                        fontSize: "3vmin",
                        alignItems: "center",
                        color: "var(--elevvaArrowColor)",
                      }}
                    >
                      <i className="bi bi-person-circle "></i>
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex col-12 m-auto  px-4  fw-bold 
                "
                  style={{ color: "var(--elevvaTextTitle)" }}
                >
                  {" "}
                  <span
                    className="col mx-auto text-center letraPequeña"
                    style={{ padding: "2% 0%" }}
                  >
                    Principiante
                  </span>
                  {/* <span className="col m-auto text-left letraPequeña">50%</span> */}
                  <span className="col m-auto text-left letraPequeña">0%</span>
                </div>
                <div
                  className="position-relative"
                  style={{ padding: "1% 10% " }}
                >
                  {" "}
                  <ProgressBar
                    now={50}
                    style={{
                      border: "2px solid var(--backgrounColorBar)",
                      borderRadius: "10px",
                      backgroundColor: "var(--backgrounColorBar)",
                      width: "22vmin",
                    }}
                  />
                  <img
                    className="position-absolute"
                    alt="tierra"
                    style={{ top: "-0.3rem" }}
                    src={process.env.PUBLIC_URL + "/assets/img/tierra.svg"}
                  />
                </div>

                <div
                  className="d-flex  mx-auto "
                  style={{
                    padding: "1vmin",
                    justifyContent: "center",
                    margin: "1vmin 0 ",
                  }}
                >
                  <div
                    className="direcccionately col  "
                    style={{ padding: "0 0%" }}
                  >
                    {/* <span className="letrapequeñaProfile">130</span> */}
                    <span className="letrapequeñaProfile">0</span>
                    <span className=" letrapequeñaProfileDown_other">
                      Puntos Elevva
                    </span>
                  </div>
                  <div
                    className="direcccionately_other col "
                    style={{
                      borderLeft: "1px solid gray",
                      borderRight: "1px solid gray",
                      padding: "0 0%",
                    }}
                  >
                    <span className="letrapequeñaProfile">000</span>
                    <span className=" letrapequeñaProfileDown_other">
                      Créditos
                    </span>
                  </div>
                  <div
                    className="direcccionately col "
                    style={{ padding: "0 0%" }}
                  >
                    <button
                      onClick={() =>
                        setModalInfo(
                          <ModalInfoPlan
                            handleCancellcargue={() => setModalInfo(null)}
                            error="tru"
                            text={
                              "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                            }
                            classesMsg=""
                          />
                        )
                      }
                      className="m-auto d-flex"
                      style={{
                        color: "var(--white)",
                        padding: "8% 9%",
                        justifyContent: "center",

                        alignItems: "center",

                        background: "var(--elevva)",
                        borderRadius: "20px",
                        border: "1px solid var(--elevva)",
                        fontSize: "1.4vmin",
                      }}
                    >
                      Ver tu plan
                    </button>
                    {/* <span className="letrapequeñaProfile">05/09/22</span>
                    <span className=" letrapequeñaProfileDown">
                      Vencimiento Créditos
                    </span> */}
                  </div>
                </div>

                <div
                  className=" direcccionately"
                  style={{
                    background: "var(--clasesPending)",

                    padding: "1vmin",
                    margin: "2vmin 0 ",
                  }}
                >
                  <span
                    className="fw-bold   mx-auto  d-flex "
                    style={{
                      fontSize: "1.8vmin",

                      flexDirection: "column",

                      color: "var(--elevvaTextTitle)",
                    }}
                  >
                    Esta semana entrenaste
                  </span>
                  <div
                    className="d-flex w-100"
                    style={{ justifyContent: "space-around" }}
                  >
                    {Object.entries(dayselected).map((er, idx) => (
                      <div className="" key={idx}>
                        <div
                          className="mx-auto d-flex fw-bold "
                          style={
                            er[0] === "lunes"
                              ? {
                                textTransform: "capitalize",
                                textAlign: "center",
                                width: "3vmin",
                                height: "3vmin",
                                fontSize: "1.0vmin",
                                background: "var(--elevva)",
                                borderRadius: "50%",
                                alignItems: "center",
                                color: "var(--white)",
                                justifyContent: "center",
                              }
                              : {
                                textTransform: "capitalize",
                                textAlign: "center",
                                width: "3vmin",
                                height: "3vmin",
                                fontSize: "1.0vmin",
                                color: "var(--elevvaTexttitleLow)",

                                alignItems: "center",
                                justifyContent: "center",
                              }
                          }
                        >
                          {er[0].slice(0, 3)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    color: "var(--elevvaTextTitle)",
                    margin: "2% 0",
                    position: "relative",
                  }}
                ></div>
                <div className="d-flex  my-1" style={{ padding: "0 3vmin " }}>
                  <span
                    className="  letraPequeña text-left m-auto"
                    style={{ color: "var(--elevvaTextTitle)" }}
                  >
                    {/* Videos <strong>más recientes</strong>{" "} */}
                    Prueba tu primer <strong>video de bienestar</strong>{" "}
                  </span>
                </div>
                <div className="mx-auto" style={{ width: "80%" }}>
                  <Allvideos />
                </div>
                <span
                  onClick={() => navigate(`/videos`)}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <button
                    data-background-color="var(--elevvaTextTitle)"
                    style={{
                      textAlign: "center",
                      color: "var(--elevva)",
                      background: "transparent",
                      border: "none",
                      fontSize: "1.6vmin",
                    }}
                  >
                    Ver todos
                    <i className="bi bi-plus-circle-fill mx-2"></i>
                  </button>
                </span>
                <div className="d-flex  my-1" style={{ padding: "0 3vmin " }}>
                  <span
                    className="  letraPequeña text-left m-auto "
                    style={{ color: "var(--elevvaTextTitle)" }}
                  >
                    {/* Tus clases <strong>reservadas</strong>{" "} */}
                    Tus próxima <strong>clase</strong> <i className="ms-1 bi bi-journal-plus align-middle" style={{fontSize: "2rem"}}></i>
                  </span>
                </div>
                {/* <div
                  className="position-relative mx-auto"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/img/GAP-prueba.jpg"
                      })`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "20px",
                    width: "80%",
                    height: "18%",
                  }}
                >
                  <div
                    className="position-absolute "
                    style={{
                      color: "var(--elevvaTextTitle)",
                      display: "flex",
                      height: "100%",
                      left: "5%",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span className="letraPequeñaother mb-1">
                      Entrenamiento
                    </span>
                    <div style={{ maxWidth: "4vmin", lineHeight: "100%" }}>
                      <span className="letraPequeñaCardTitle fw-bold">
                        Zumba grupal
                      </span>
                    </div>

                    <span className="letraPequeñaother mt-1">Agosto 17</span>
                    <span className="letraPequeñaother ">9:00 / 10:00 am</span>
                  </div>
                  <div
                    className="position-absolute fw-bold rounded-circle"
                    style={{
                      background: "var(--backgroundOnboardingObjective)",
                      top: "10%",
                      right: "5%",
                      width: "4vmin",
                      height: "4vmin",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      color: "var(--white)",
                      borderRadius: "50%",
                    }}
                  >
                    <span className="letraPequeñaother ">
                      <i class="bi bi-hourglass"></i>
                    </span>
                  </div>
                  <div
                    className="position-absolute fw-bold"
                    style={{
                      background: "var(--vercardtext)",
                      bottom: "5%",
                      right: "5%",
                      padding: "2% 5%",

                      color: "var(--white)",
                      borderRadius: "10px",
                    }}
                  >
                    <span className="letraPequeñaother ">Ver</span>
                  </div>
                </div> */}
                <div
                  className="position-relative mx-auto d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "var(--colorsesiones)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "20px",
                    width: "80%",
                    height: "18%",
                    flexDirection: "column"
                  }}
                >
                  <span
                    className="m-auto letraPequeña fw-bold text-center"
                    style={{ color: "var(--elevvaTextTitle)" }}
                  >
                    <p style={{ fontSize: "19px" }}>No tienes sesiones recientes</p>
                  </span>
                </div>

                <br />
                {/* <button
                  className="m-auto d-flex"
                  onClick={() => navigate("/Usuario/Inicio")}

                  style={{
                    color: "var(--white)",
                    padding: "1.5% 7%",
                    justifyContent: "center",

                    alignItems: "center",

                    background: "var(--elevva)",
                    borderRadius: "20px",
                    border: "1px solid var(--elevva)",
                    fontSize: "1.4vmin",
                  }}
                >
                  Ver más <i className=" bi bi-chevron-right"></i>
                  
                </button> */}
                <ButtonMob className="noMargin"
                  handleClick={() => navigate(`/videos`)} //videos
                  style={{
                    color: "var(--white)",
                    padding: "1.5% 7%",
                    justifyContent: "center",

                    alignItems: "center",

                    background: "var(--elevva)",
                    borderRadius: "20px",
                    border: "1px solid var(--elevva)",
                    fontSize: "1.4vmin",

                  }}
                  text={"Ver más"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          color: "var(--white)",
          width: "8vmin",
          height: "8vmin",
          justifyContent: "center",
          bottom: "4%",
          right: "2%",
          alignItems: "center",
          zIndex: "10",
          background: "var(--elevva)",
          borderRadius: "50%",
          border: "1px solid var(--elevva)",
        }}
        className="position-absolute d-flex elevation-18"
      >
        <span style={{ fontSize: "1.5vmin" }}>Tutorial</span>
      </div>
    </div>
           {/* )} */}
    </>
  );
};
export default Inicio;
