import "./createaccount.css";
import { useParams } from "react-router";

import { useNavigate } from "react-router-dom";

import { React, useState, } from "react";

import { ButtonArrow,   ModalCuenta
} from "../../utils/utils";

import { FormRegister, } from "./utils";

const Createaccount = (props) => {
  
  
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  

  const handleSuccess = () => {
    setShowSuccessModal(true);
  }

  const elevvaT = props.elevvaT;
  const { user } = useParams();

  const navigate = useNavigate();

  const type = props.type;

  return (
    <>
      <div id="mobile" className="d-md-none mobile-login w-100">
        <div style={{ height: "calc(100vh - 60vh)" }}>
          <div
            className="containerBGClase"
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${
                process.env.PUBLIC_URL +
                "/assets/img/imagen_mobile_createUser.jpg"
              })`,
            }}
          >
            <div className="button-back-start">
              <ButtonArrow
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
        <div
          className="mobile-welcome-login-row  text-left w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            alignItems: "center",
          }}
        >
          <FormRegister
            classes={"LoginForm Registro "}
            elevvaT={elevvaT}
            type={type}
            onSuccess={handleSuccess}
          />
        </div>
      </div>
      <div className=" d-none d-md-flex col-12 " style={{ height: "100vh" }}>
        <div
          className="imagen-LoginCustomer-Welcome"
          style={{
            width: "53%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/img/createUser_web_image.jpg"
            })`,
          }}
        ></div>
        <div
          style={{
            width: "50%",
            margin: "auto",
            zIndex: "2",
            height: "100%",
            position: "absolute",
            left: "50%",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div
            className="box-Welcome-create text-left col-12 d-flex"
            style={{ flexDirection: "column" }}
          >
            <div className="w-100 mx-auto d-flex">
              <div className="total-width-arrow"></div>
              <div className=" d-flex justify-content-center text-color-subtitle text-left">
                Ingreso usuario
              </div>
            </div>
            <div className="w-100 mx-auto d-flex">
              <div className="total-width-arrow">
                <ButtonArrow
                  colorBack={`var(--elevvaArrowColor)`}
                  handleClick={() => navigate(-1)}
                />
              </div>

              <FormRegister
                classes={"LoginForm Registro"}
                elevvaT={elevvaT}
                type={type}
                onSuccess={handleSuccess}
              />
            </div>
          </div>
        </div>
      </div>
      {showSuccessModal && (
        <ModalCuenta
          text={`Tu cuenta ha <br/> sido creada <br/>correctamente`}
          text2={` Hemos enviado un correo de <br/> verificación, por favor revisa tu <br/> bandeja de correo.`}
        />
      )}
    </>
  );
};

export default Createaccount;
