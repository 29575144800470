
import { useEffect } from "react";
import React from "react";
import {
  CategoriasEmpty,
  Paso7,
} from "../TutorialReserva/stepsHelp";
import {useLocation, useParams } from "react-router-dom";
import { instance, laptop575 } from "../../utils/utils";
import { useState } from "react";
import "moment/locale/es";
const SelectSchedule = (props) => {
  const { id } = useParams();
  const [trainerGlobal, setTrainerGlobal] = useState(null);
  const [infoTraining, setinfoTraining] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (localStorage.mobileBooking){
      localStorage.removeItem("mobileBooking")
    }
  }, [location.pathname]);
  useEffect(() => {
    instance
      .get(`trainings/${id}`)
      .then((response) => {
        setinfoTraining(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

if(localStorage.trainerSelected){
  setTrainerGlobal(localStorage.trainerSelected)
}
  }, [id]);
  return (<> 
  
 { laptop575.matches ?<div className="d-none d-md-block col-10" style={{height:'100%'}}>
      <div
        className=" position-relative "
        style={{
          background: "rgba(0,0,0,.7)",
          height: "100vh",
          padding: "2% 3% 2% 2%",
        }}
      >
        <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
          <CategoriasEmpty />
          <Paso7  infoTraining={infoTraining}  Trainer={trainerGlobal} />
        </div>
      </div>
    </div>
    :<div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <Paso7 infoTraining={infoTraining} Trainer={trainerGlobal} vistaMostrar={1} />        
        </div>}</>
  );

}
export default SelectSchedule;