
import React, { useRef } from "react";
import Moment from "moment";
import { useNavigate,useParams,useLocation  } from "react-router-dom";
import { instance, ModalInfo } from "../../utils/utils";



import { useState, useEffect } from "react";
import { Body, InfoBody } from "./helpBody";
import { Loading } from "../TutorialReserva/stepsHelp";

const YourBody = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState([]);
  const [modalInfo, setModalInfo] = useState(null);
  const [dataexist, setdataexist] = useState(false);
  const [brazoiz, setbrazoiz] = useState(0);
  const [brazoizgrasa, setbrazoizgrasa] = useState(0);
  const [brazode, setbrazode] = useState(0);
  const [brazodegrasa, setbrazodegrasa] = useState(0);
  const [piernaiz, setpiernaiz] = useState(0);
  const [piernaizgrasa, setpiernaizgrasa] = useState(0);
  const [piernade, setpiernade] = useState(0);
  const [piernadegrasa, setpiernadegrasa] = useState(0);
  const [abdomenp, setabdomen] = useState(0);
  const [abdomenpgrasa, setabdomengrasa] = useState(0);


  const [Imc, setImc] = useState(null);
  const [pesoActual, setPeso] = useState(null);
  const [grasa, setgrasa] = useState(null);
  const [perdida, setperdida] = useState(null);
  const [subcutanea, setsubcutanea] = useState(null);
  const [visceral, setvisceral] = useState(null);
  const [water, setwater] = useState(null);
  const [hueso, sethueso] = useState(null);
  const [esqueletica, setesqueletica] = useState(null);
  const [musculo, setmusculo] = useState(null);
  const [proteina, setproteina] = useState(null);
  const [bmr , setbmr] = useState(null);
  const [edad, setedad] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    instance
    .get("users/current/")
    .then((response) => {
         if(response.data) {
 
          setUserData(response.data);
         }
    })
    .catch((error) => {
      console.log(error);
    });
    instance.get(`customers/healthlog/?part=20`).then((res) => {
      const daysselected = res.data;
      // console.log(daysselected);
      if (daysselected.length > 0) {
        const grasabrazo_iz = daysselected.filter((e) => e.type == 3);
        const masabrazo_iz = daysselected.filter((e) => e.type == 10);
        // console.log(masabrazo_iz);
        setbrazoiz(
          masabrazo_iz.length == 1
            ? masabrazo_iz[0].numeric_value
            : masabrazo_iz.length > 1
            ? darsigno(
                masabrazo_iz[0].numeric_value,
                masabrazo_iz[1].numeric_value
              )
            : 0
        );
        setbrazoizgrasa(
          grasabrazo_iz.length == 1
            ? grasabrazo_iz[0].numeric_value
            : grasabrazo_iz.length > 1
            ? darsigno(
                grasabrazo_iz[0].numeric_value,
                grasabrazo_iz[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=30`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        // console.log(daysselected);
        let grasabrazo_de = daysselected.filter((e) => e.type == 3);
        let masabrazo_de = daysselected.filter((e) => e.type == 10);
        // console.log(grasabrazo_de, masabrazo_de);
        setbrazode(
          masabrazo_de.length == 1
            ? masabrazo_de[0].numeric_value
            : masabrazo_de.length > 1
            ? darsigno(
                masabrazo_de[0].numeric_value,
                masabrazo_de[1].numeric_value
              )
            : 0
        );
        setbrazodegrasa(
          grasabrazo_de.length == 1
            ? grasabrazo_de[0].numeric_value
            : grasabrazo_de.length > 1
            ? darsigno(
                grasabrazo_de[0].numeric_value,
                grasabrazo_de[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=40`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasapierna_iz = daysselected.filter((e) => e.type == 3);
        const masapierna_iz = daysselected.filter((e) => e.type == 10);

        setpiernaiz(
          masapierna_iz.length == 1
            ? masapierna_iz[0].numeric_value
            : masapierna_iz.length > 1
            ? darsigno(
                masapierna_iz[0].numeric_value,
                masapierna_iz[1].numeric_value
              )
            : 0
        );
        setpiernaizgrasa(
          grasapierna_iz.length == 1
            ? grasapierna_iz[0].numeric_value
            : grasapierna_iz.length > 1
            ? darsigno(
                grasapierna_iz[0].numeric_value,
                grasapierna_iz[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=50`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasapierna_de = daysselected.filter((e) => e.type == 3);
        const masapierna_de = daysselected.filter((e) => e.type == 10);

        setpiernade(
          masapierna_de.length == 1
            ? masapierna_de[0].numeric_value
            : masapierna_de.length > 1
            ? darsigno(
                masapierna_de[0].numeric_value,
                masapierna_de[1].numeric_value
              )
            : 0
        );
        setpiernadegrasa(
          grasapierna_de.length == 1
            ? grasapierna_de[0].numeric_value
            : grasapierna_de.length > 1
            ? darsigno(
                grasapierna_de[0].numeric_value,
                grasapierna_de[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=60`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasaabdomen = daysselected.filter((e) => e.type == 3);
        const masaabdomen = daysselected.filter((e) => e.type == 10);

        setabdomen(
          masaabdomen.length == 1
            ? masaabdomen[0].numeric_value
            : masaabdomen.length > 1
            ? darsigno(
                masaabdomen[0].numeric_value,
                masaabdomen[1].numeric_value
              )
            : 0
        );
        setabdomengrasa(
          grasaabdomen.length == 1
            ? grasaabdomen[0].numeric_value
            : grasaabdomen.length > 1
            ? darsigno(
                grasaabdomen[0].numeric_value,
                grasaabdomen[1].numeric_value
              )
            : 0
        );
      }
    });
  }, []);

   function fetchData() {
 function type_1() {
   return instance.get(`customers/healthlog/?type=1`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       // console.log("PESO", daysselected);

       let peso_grafica = [];
       daysselected.map((e) => {
         peso_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "Peso",

           data: peso_grafica.filter((e) => e),
         },
       ];
       // console.log(daysselected.slice(1, -1)[0].numeric_value);
       if (daysselected.length == 1) {
         setPeso([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setPeso([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_2() {
   return instance.get(`customers/healthlog/?type=2`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let IMC_grafica = [];
       daysselected.map((e) => {
         IMC_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "IMC",

           data: IMC_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setImc([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setImc([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_3() {
   return instance.get(`customers/healthlog/?type=3&part=10`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       // console.log(daysselected);
       let grasa_grafica = [];
       daysselected.map((e) => {
         grasa_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "grasa",

           data: grasa_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setgrasa([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setgrasa([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_4() {
   return instance.get(`customers/healthlog/?type=4`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let perdida_grafica = [];
       daysselected.map((e) => {
         perdida_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "perdida",

           data: perdida_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setperdida([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setperdida([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_5() {
   return instance.get(`customers/healthlog/?type=5`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let subcutanea_grafica = [];
       daysselected.map((e) => {
         subcutanea_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "subcutanea",

           data: subcutanea_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setsubcutanea([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setsubcutanea([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_6() {
   return instance.get(`customers/healthlog/?type=6`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let visceral_grafica = [];
       daysselected.map((e) => {
         visceral_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "visceral",

           data: visceral_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setvisceral([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setvisceral([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_7() {
   return instance.get(`customers/healthlog/?type=7`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let water_grafica = [];
       daysselected.map((e) => {
         water_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "water",

           data: water_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setwater([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setwater([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_8() {
   return instance.get(`customers/healthlog/?type=8`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let hueso_grafica = [];
       daysselected.map((e) => {
         hueso_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "hueso",

           data: hueso_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         sethueso([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         sethueso([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_9() {
   return instance.get(`customers/healthlog/?type=9`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let esqueletica_grafica = [];
       daysselected.map((e) => {
         esqueletica_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "esqueletica",

           data: esqueletica_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setesqueletica([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setesqueletica([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_10() {
   return instance.get(`customers/healthlog/?type=10&part=10`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       // console.log("------------------------------------------", daysselected);
       let musculo_grafica = [];
       daysselected.map((e) => {
         musculo_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "musculo",

           data: musculo_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setmusculo([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else setmusculo([daysselected.slice(0, -1)[0].numeric_value, data, 0]);
     }
     return daysselected;
   });
 }
 function type_11() {
   return instance.get(`customers/healthlog/?type=11`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let proteina_grafica = [];
       daysselected.map((e) => {
         proteina_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "proteina",

           data: proteina_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setproteina([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setproteina([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_12() {
   return instance.get(`customers/healthlog/?type=12`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let bmr_grafica = [];
       daysselected.map((e) => {
         bmr_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "bmr",

           data: bmr_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setbmr([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setbmr([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }
 function type_13() {
   return instance.get(`customers/healthlog/?type=13`).then((res) => {
     const daysselected = res.data;
     if (daysselected.length > 0) {
       let edad_grafica = [];
       daysselected.map((e) => {
         edad_grafica[e.id] = {
           y: e.numeric_value,
           x: Moment(e.date).format("L"),
         };
       });
       const data = [
         {
           id: "edad",

           data: edad_grafica.filter((e) => e),
         },
       ];
       if (daysselected.length == 1) {
         setedad([daysselected.slice(-1)[0].numeric_value, data, 0]);
       } else
         setedad([
           daysselected.slice(0, -1)[0].numeric_value,
           data,
           daysselected[1].numeric_value,
         ]);
     }
     return daysselected;
   });
 }

    Promise.all([type_1(),type_2(),type_3(),type_4(),type_5(),type_6(),type_7(),type_8(),type_9(),type_10(),type_11(),type_12(),type_13()])
    .then((response) => {
      let [type_1_res,type_2_res,type_3_res,type_4_res,type_5_res,type_6_res,type_7_res,type_8_res,type_9_res,type_10_res,type_11_res,type_12_res,type_13_res] = response;
      return [].concat(type_1_res).concat(type_2_res).concat(type_3_res).concat(type_4_res).concat(type_5_res).concat(type_6_res).concat(type_7_res).concat(type_8_res).concat(type_9_res).concat(type_10_res).concat(type_11_res).concat(type_12_res).concat(type_13_res);
    })
    .then((totalreq) => {
  
     if(totalreq && totalreq.length>0){
      setLoading(false);
     }else{
      setModalInfo(
        <ModalInfo
          error="false"
          handleClick={()=>navigate("/Dashboard")}
          textButtonConfirmar={"Entendido"}
          classcorrecta={"recuerdaTitle"}
          handleCancellcargue={()=>navigate("/Dashboard")}
          title={`Mejora tu <br/> experiencia elevva`}
          text={`<br/> Para llevar un registro exitoso de tu información corporal, debes hacerlo con nuestra bascula elevva y tomar tus datos a través de nuestra app.
          <br/>Descarga nuestra app en PlaySore o AppStore. <br/><br/> `}
          classesMsg="colorText_Modal"
        />
      );
     
     }
    })
    .catch((err) => console.log(err));

   
  }
  function darsigno(x, y) {
    if (x - y >= 0) {
      return [+x];
    } else {
      return [-x];
    }
  }
  function dejarsolonumber(x) {
    if (x < 0) {
      return x * -1;
    } else {
      return x;
    }
  }
  useEffect(() => {
    fetchData();
  }, []);


  return (<> {modalInfo}
    <div
    className="d-none d-md-block col-10" style={{height:'100%'}}
  >
    <div
      className=" position-relative "
      style={{
        background: "rgba(0,0,0,.7)",
        height: "100vh",
        padding: "2% 3% 2% 2%",
      }}
    >
      <div className="d-flex col-12" style={{ gap: "1%", height: "100%" }}>
      <Body brazoiz={brazoiz} brazoizgrasa={brazoizgrasa} abdomenpgrasa={abdomenpgrasa} abdomenp={abdomenp} piernadegrasa={piernadegrasa} brazodegrasa={brazodegrasa} brazode={brazode} dejarsolonumber={(e)=>dejarsolonumber(e)} piernaiz={piernaiz} piernaizgrasa={piernaizgrasa} piernade={piernade} data_user={userData} borderBody={"20px"}   />
     <InfoBody   loading={loading}    
          data_user={userData}
          Imc={Imc}
          pesoActual={pesoActual}
          grasa={grasa}
          perdida={perdida}
          subcutanea={subcutanea}
          visceral={visceral}
          water={water}
          hueso={hueso}
          esqueletica={esqueletica}
          musculo={musculo}
          proteina={proteina}
          bmr={bmr}
          edad={edad} borderBody={"20px"}    />
            </div>
          </div>
        </div>
    { loading ?<div className="d-md-none mobile-login w-100" style={{ height: "100%" }}> <Loading/></div>:   <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <Body brazoiz={brazoiz} brazoizgrasa={brazoizgrasa} abdomenpgrasa={abdomenpgrasa} abdomenp={abdomenp} piernadegrasa={piernadegrasa} brazodegrasa={brazodegrasa} brazode={brazode} dejarsolonumber={(e)=>dejarsolonumber(e)} piernaiz={piernaiz} piernaizgrasa={piernaizgrasa} piernade={piernade} data_user={userData} borderBody={"0px"}    />
        <InfoBody mobileOn={true} loading={loading}  borderBody={"0px"}   
          data_user={userData}
          Imc={Imc}
          pesoActual={pesoActual}
          grasa={grasa}
          perdida={perdida}
          subcutanea={subcutanea}
          visceral={visceral}
          water={water}
          hueso={hueso}
          esqueletica={esqueletica}
          musculo={musculo}
          proteina={proteina}
          bmr={bmr}
          edad={edad} />
        
        
        </div>}
        
        </>)

}
export default YourBody;