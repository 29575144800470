import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonArrow, ButtonMob } from "../../../utils/utils";
// Modal
import Modal from 'react-bootstrap/Modal';
//spinner
import { BarLoader  } from "react-spinners";
import elevva from "../../../assets/img/general/logo_alta.png"

import "./index.css";


export const Welcomecomponent = (props) => {
  const type = props.type;
  const [isLoading, setIsLoading] = useState(true);

  // Simulando una carga de datos
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  const navigate = useNavigate();
  const elevvaT = props.elevvaT;
  const WelcomecomponentwebMobile = (props) => {
    const classes = props.classes || "";
    return (<div className={classes} >
      <div className="panel-welcome-responsive">
        <h1 className="text-color-title">{elevvaT("WelcomePost_one")}</h1>
        <h1 className="text-color-title">
          <strong>{elevvaT("WelcomePost_two")}</strong>
        </h1>
        <h1 className="text-color-title">
          <strong>de bienestar</strong>
        </h1>
        <label className="text-color-subtitle my-2">
          {elevvaT("WelcomePost_text")}
        </label>

        <label className="text-color-subtitle my-3">
          {elevvaT("WelcomePost_text_subtitle")}
        </label>

        <ButtonMob
          handleClick={() => navigate(`/${type}/YourObjectivesMember`)}
          className="mx-0 my-0 "
          icon={"bi bi-chevron-right"}
          colorButton={`var(--elevvabutonLogin_User)`}
          text={elevvaT("ButtonStartNow_text")}
        />
      </div></div>
    );
  };
  return (
    <>
      <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
      
      
        <div  
          className="containerBGClase border_bottom_welcome"
          style={{backgroundSize: "cover",height: "35vh",zIndex:"3",
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/img/StartwithUs_web.jpg"
              })`,
          }}
        ></div>
        <div className="mobile-welcome-login-row  text-left w-100" style={{borderRadius:"0"}}>
          <WelcomecomponentwebMobile classes={"d-flex flex-column justify-content-center"} />
        </div>
      </div>
      
      
      {isLoading ? (
           <div className="spinner-container-welcome flex-column w-100" style={{height:'100vh'}}>
           <img className="my-2" src={elevva} alt="Welcome" />
                 <BarLoader  color="#8d97fd" width={100} />
               </div>
             ) : (
      <div className=" d-none d-md-flex col-12 " style={{height:'100vh'}}>
        <div
          className="imagen-LoginCustomer-WelcomePost col-6"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/img/StartwithUs_web.jpg"
              })`,
          }}
        ></div>

        <div className="col-6">
          <div
            className="box-Welcome-login text-left"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/img/backgroundElevva-logo.jpg"
                })`,
              backgroundSize: "cover",
              backgroundPositionX: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <WelcomecomponentwebMobile classes={"d-flex flex-column justify-content-center h-100"} />
          </div>
        </div>
      </div>
             )}
      
    </>
  );
};


// Modal 
export default function Modalcito(props) {
  const { title = "▶ Objetivo principal" } = props;
  return (
    <Modal
      {...props}
      // size="md"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body >
        <div className="d-flex justify-content-center">
          <i
            className="bi bi-x-circle-fill align-self-center"
            style={{ color: "#9EACED", fontSize: "3.5rem" }}
          ></i>
        </div>
        <div className="text-center">

          <Modal.Title>Falto por seleccionar:</Modal.Title>

          <br />
          <h4>{title ? title : "▶ Objetivo otros"}</h4>
        </div>
      </Modal.Body>
      <Modal.Footer className="align-self-center">
        {/* <Button className="mx-2 sizebuttonLarge" style={{ backgroundColor: "#cb3d82", borderColor: "#cb3d82", borderRadius: "20px" }} onClick={props.onHide}>Entendido</Button> */}
        <ButtonMob
        colorButton={`var(--elevva)`}
        className="mx-2 sizebuttonLarge"
        text={"Entendido"}
        handleClick={props.onHide}
        />
      </Modal.Footer>
    </Modal>
  );
}

export const ModalcitoOk = (props) => {
  const { title = "Tus datos se han enviado correctamente" } = props;
  return (
    <Modal
      {...props}
      // size="md"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body >
        <div className="d-flex justify-content-center">
          <i
            className="bi bi-check-lg align-self-center modalcitoOk"
          ></i>
        </div>
        <div className="text-center">

          <Modal.Title>{title}</Modal.Title>

          <br />
        </div>
      </Modal.Body>
      <Modal.Footer className="align-self-center">
        {/* <Button className="mx-2 sizebuttonLarge" style={{ backgroundColor: "#cb3d82", borderColor: "#cb3d82", borderRadius: "20px" }} onClick={props.onHide}>Entendido</Button> */}
        <ButtonMob
        colorButton={`var(--elevva)`}
        className="mx-2 sizebuttonLarge"
        text={"Entendido"}
        handleClick={() => {
          window.location.href="/Usuario/Inicio"
        }}
        />
      </Modal.Footer>
    </Modal>
  );
}
// Button 

// export const SliderButton = (props) => {
//   const [isPrev, setIsPrev] = useState(true);

//   useEffect(() => {
//     if (props.currentSlide === props.totalSlides - 1) {
//       setIsPrev(true);
//     } else if (props.currentSlide === 0) {
//       setIsPrev(false);
//     }
//   }, [props.currentSlide, props.totalSlides]);

//   const handleClick = () => {
//     if (isPrev) {
//       props.prevSlider();
//     } else {
//       props.nextSlider();
//     }
//   };

//   return (
//     <ButtonMob
//       icon={`bi bi-chevron-${isPrev ? 'left' : 'right'}`}
//       colorButton={`var(--elevva)`}
//       handleClick={handleClick}
//       className="mx-2 sizebuttonLarge"
//       text={isPrev ? 'Anterior' : 'Finalizar'}
//     />
//   );
// };

