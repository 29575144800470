
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BookCard } from "../Dashboard/helpResponsive";
import ReactHtmlParser from "react-html-parser";
import Moment from "moment";
import { useState, useEffect } from "react";
import {ReactComponent as Recomen_A} from "../../assets/newIcons/ICO-RECOMENDACIONES-A.svg"
import {ReactComponent as Recomen_B} from "../../assets/newIcons/ICO-RECOMENDACIONES-B.svg"
import {ReactComponent as Recomen_C} from "../../assets/newIcons/ICO-RECOMENDACIONES-C.svg";
import {ReactComponent as Calendar} from "../../assets/newIcons/calendarImage.svg"
import { ButtonMob, emit_is_hr_device, eventGetRooms, instance, laptop575, ModalInfo, TitleRow } from "../../utils/utils";
import { Loading } from "../TutorialReserva/stepsHelp";
import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg"
import {ReactComponent as CrediIcon } from "../../assets/newIcons/mis-credi-icon.svg"
import { FacebookShareButton } from 'react-share';
import html2canvas from 'html2canvas';
import { laptop1600 } from "../../utils/utils";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; 
// import FacebookLogin from "react-facebook-login";


export function useCompareIn(a, b) {
  // console.log(Moment(a.date).format('YYYY-MM-DD'))
  if (Moment(a.date).format('DD-MM-YYYY HH:mm') < Moment(b.date).format('DD-MM-YYYY HH:mm')) return -1;
  if (Moment(a.date).format('DD-MM-YYYY HH:mm') > Moment(b.date).format('DD-MM-YYYY HH:mm')) return 1;
  return 0;
}
export function useCompareDate(a, b) {
  let getHour = function (date) {
    return date.getTime();
  };

  if (getHour(new Date(a.data)) > getHour(new Date(b.data))) return -1;
  if (getHour(new Date(a.data)) < getHour(new Date(b.data))) return 1;
  return 0;
}
export function useCompareDateOrder(a, b) {
  let getHour = function (date) {
    return date.getTime();
  };

  if (getHour(new Date(a.date)) < getHour(new Date(b.date))) return -1;
  if (getHour(new Date(a.date)) > getHour(new Date(b.date))) return 1;
  return 0;
}


export  const TitleRowClases = (props) => {
  const infoTraining = props.infoTraining;
  const infoTrainingTime = props.infoTrainingTime;
  const handleclick=props.handleclick;
  
  return(
    <div  
       className="w-70 w-md-100 ubication_arrow_title">
            <div className="d-flex my-auto ">
              <div className=" my-auto mx-2 d-flex flex-column gap-0 justify-content-center ">
                <span className="mt-2  text_arrow_top text_hour_misclases">
                  Entrenamiento
                </span>
                <span
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    lineHeight: "100%",
                    fontSize: "calc(1.1rem + 0.3vw)",
                  }}
                >
                  Clase de <br />
                  {infoTraining &&infoTraining.name}
                </span>{" "}
                <span className="text_arrow_top text_hour_misclases">
                {Moment.tz(infoTrainingTime?.date, infoTrainingTime?.customer_tz).format("MMMM DD")} •  {Moment.tz(infoTrainingTime?.date, infoTrainingTime?.customer_tz)
                        .format("hh:mm a") + " / " + Moment.tz(infoTrainingTime?.date, infoTrainingTime?.customer_tz)
                        .add(1, "h")
                        .format("hh:mm a")}</span>
                {infoTraining?.credits == 0 ? (
                  <button
                    style={{
                      background: "var(--elevva)",
                      border: "1px solid var(--elevva)",
                      borderRadius: "20px",
                      color: "var(--white)",
                      fontWeight: "300",
                      maxWidth: "60%",
                      padding: "1% 0%",
                    }}
                  >
                    GRATIS
                  </button>
                ) : null}
              </div>
            </div>
          </div>
  )
}
export const Misreservas = (props) => {
  const navigate = useNavigate();
  const bookings = props.bookings;
  const handleClick = props.handleClick;
  const user=props.user;
  const mobileOn= props.mobileOn || false

  function TextStatusBook(e){
    if ( e ==10) { return ReactHtmlParser( `EN ESPERA DE <br/> CONFIRMACIÓN`)}
    else if (e ==20){ return ReactHtmlParser (`RESERVA <br/>CONFIRMADA`)}
     else{return ReactHtmlParser(`RESERVA <br/> EN CURSO`)};
    };

  return (
    <div
      id={"categoryReservaTutorial"}
      className={mobileOn?"col-12 col-md-3 d-flex  flex-column h-100":"col-12 col-md-3 d-flex justify-content-center flex-column h-100"}
      style={{
        background: "white",
        padding: "2%",
        borderRadius: "20px",
      }}
    >
    <div style={{flex:"1"}} >
  {mobileOn?null:  <div>
      <div className="d-flex my-auto" style={{height:"auto"}}>
        <div
          onClick={() => navigate(`/Dashboard`)}
          className=" fw-bold booking-all"
          style={{
            background: "transparent",
            border: "1px solid #A8C2D7",
            padding: "2%",
            fontSize: "1vmin",
            height: "fit-content",
            cursor: "pointer",
            color: "#A8C2D7",
            borderRadius: "10px",
          }}
        >
          <span style={{ fontSize: "0.5vmin" }}>
            <i className="bi bi-chevron-left"></i>
          </span>
        </div>
        <span
          className="fw-bold my-auto ms-2 text_arrow_top"
       
        >
          {" "}
          Mis reservas
        </span>
      </div>
      <hr /></div>}
      <div >
      <div className="d-flex justify-content-center" style={{ lineHeight: "110%", margin: "0vmin auto",textAlign:"center",height:"auto" }}>
      <div className="my-auto">
        <span className="text_arrow_top"
        >
         Mis 
        </span>  <span
          className="fw-bold text_arrow_top"
      
        >
          {" "}
          Créditos
        </span></div>
       
         <div className="mx-1 my-auto d-flex justify-content-center" style={{width:"1.2rem"}}>
        <CrediIcon width={"100%"}  /></div> 
        
      
      </div>
     
     <div className=" d-flex justify-content-center" style={{height:"auto"}}>
     <span style={{color:"#ADBAC4",fontSize:"calc(1rem + 0.3vw)"}} className="text_arrow_top mx-auto"
        >
        {user?.customer?.credits_balance}
        </span></div>
        <div style={{height:"auto"}}>   <ButtonMob
                   handleClick={() =>
                     navigate(`/reservas`)
                   }
                  // id={"tutorialButtonReserva"}
                  icon={"ms-2 bi bi-calendar3 right my-auto"}
                  className={mobileOn?"col-9 mx-auto":"col-9 mx-auto nueva_reserva"}
                  text="Nueva reserva"
                /> </div>
     </div>
      </div>
    <div className="w-100 altura_change_clases">
    <div className="d-flex justify-content-center mb-2" style={{ lineHeight: "110%", margin: "0vmin auto",textAlign:"center",height:"auto" }}>
      <div className="my-auto">
        <span className="text_arrow_top"
        >
          Tus clases
        </span>  <span
          className="fw-bold text_arrow_top"
      
        >
          {" "}
          reservadas
        </span></div> <div className="mx-1 my-auto d-flex justify-content-center" style={{width:"1.2rem"}}>
        <Calendar width={"100%"}  /></div> 
        
      
      </div>
  { mobileOn?<> {bookings.map((e, index) => (
        <BookCard book={e} index={index} heightMap={"10rem"} TextStatusBook={TextStatusBook} widthCard={"100%"} myCard={"ms-0 my-2"} handleClick={handleClick}/>
      ))}</>: <PerfectScrollbar style={{height:"90%"}} >
      {bookings.map((e, index) => (
        <BookCard book={e} index={index} heightMap={laptop1600.matches? "calc(8.5rem + 2vmin)":"calc(6.3rem + 1.5vmin)"} TextStatusBook={TextStatusBook} widthCard={"100%"} myCard={"ms-0 my-2"} handleClick={(e)=>handleClick(e)}/>
      ))}
      </PerfectScrollbar>}
       </div>
      {mobileOn?<> <br/>
        <br/>
        <br/></>:null}
    </div>
  );
};
export const PersonalBook = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  
  const [ModalInfo, setModalInfo] = useState(null);
  const [message, setmessage] = useState(false);
  require("moment-timezone");
  const navigate = useNavigate();
  const infoTraining = props.infoTraining || null;
  const recomendaciones = props.recomendaciones || false;
  const infoTrainingTime = props.infoTrainingTime || false;
  const handleClick = props.handleClick || null;
  const handleAlertBand = props.handleAlertBand || null;
  const handleAlertBandTrue = props.handleAlertBandTrue || null;
  const notClassYet = props.notClassYet || null;
  const user = props.user || null;
  const bookings = props.bookings || null;

  const ButtonTrainerStart = (props) => {
    const infoTraining = props.infoTraining;
    const classes = props.classes || null;
    const sizeFont = props.sizeFont || null;
    return (
      <div className={classes}>
        <button
          className="me-1"
          style={{
            background: "#F4F0ED",
            border: "1px solid #F4F0ED",
            borderRadius: "20px",
            color: "#394F65",
            fontWeight: "300",
            padding: "1% 8%",
            fontSize: sizeFont,
          }}
        >
          {/* 45min */}
          {infoTraining && infoTraining.time} {""} {"Min"}
        </button>
        <button
          className="mx-1"
          style={{
            background: "#F4F0ED",
            border: "1px solid #F4F0ED",
            borderRadius: "20px",
            color: "#394F65",
            fontWeight: "300",
            padding: "1% 8%",
            fontSize: sizeFont,
          }}
        >
          {/* 500 cal */}
          {infoTraining && infoTraining.calories} {""}
          {"Cal"}
        </button>
      </div>
    );
  };

  const recomendacionesText=[{"texto":"Recuerda siempre estar cerca de tu wifi para tener una conexión estable.","icon":<Recomen_A/>},{"texto":"Verifica que tienes buen internet.","icon":<Recomen_B/>},{
    "texto":"Si tu clase se congela o se pierde la imagen de tu profesor durante el desarrollo de esta, ingresa nuevamente a la clase desde la app; es probable que debas reiniciar la aplicación si el problema persiste.","icon":<Recomen_C/>
  }]
  useEffect(() => {
    // setupConnection((res,reason,finish) => {
      
    //   console.log('hr_device_connected',res);
    //   // Puedes agregar lógica adicional aquí si es necesario
    // },(reason) => {
      
    //   console.log('hr_device_disconnected',reason);
    //   // Puedes agregar lógica adicional aquí si es necesario
    // },(finish) => {
      
    //   console.log('customer_finish',finish);
    //   // Puedes agregar lógica adicional aquí si es necesario
    // })

    if (infoTraining) {
    
      setIsLoading(false);
    }
  }, [infoTraining]);
  useEffect(() => {
    if(props.bandConnected == true){

      goToClassLink();
    }
}, [props.consultbandConnected]);

// useEffect(() => {
//   if(message == true){

//     if(props.bandConnected){
//       handleAlertBandTrue()
//     }else{

//       handleAlertBand()

  
//   }
  

//   }
// }, [message]);


 
function hideModal (){
  setModalInfo(null)
}
const goToClassLink = () => {
  // if (infoTrainingTime?.is_group) {
  //   window.location.href = `/Usuario/clasestream/${infoTrainingTime?.id}`;
  // } else {


    if (localStorage.room_idUser && localStorage.room_idUser != infoTrainingTime?.room_id) {
    
      localStorage.removeItem("room_idUser");
      localStorage.removeItem('MethodologyCustomer');
      localStorage.removeItem("workoutsetActual");
    
      localStorage.removeItem("contadorActual");
    }
    //setClase(<Redirect   to={`/Usuario/clase/${id}`} />)
    window.location.href = `/Usuario/clase/${infoTrainingTime?.id}`;
  }

// }

  const goToClass = () => {
    
    localStorage.removeItem("allBokkingsElevva");
    // localStorage.elevvaAppReserva=infoTrainingTime?.id;
    if(infoTrainingTime.date>Moment().format()){
      notClassYet()
     
    }else{
      

        props.startSocket(infoTrainingTime?.id);
        setTimeout(() => {
          emit_is_hr_device();
        }, 400);
     
       
      
           
}
  };

  const submit = async () => {
    // const res = await instance.post(`booking/book/cancel/${id}`);
    // console.log("reseses", res.data, res);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="cambioCuaro">
            <div
              className="custom-ui CancelarReservaClase"
              style={ { fontSize: "1rem" } }
            >
              <h1
                className="title text-center"
                style={
                  { fontSize: "1.5rem" }
                }
              >
                ¿Deseas cancelar tu entrenamiento?
              </h1>
              <div
                className=" text-center my-3"
                style={laptop575.matches? {
                  width:"60%",margin:"auto",
                  color: "#9DACBD",
                  fontSize: "1rem",
                }:{
             
             color: "#9DACBD",
             fontSize: "1rem",
           
           }}
              >
                Ten en cuenta que si cancelas dentro de las 24 horas  antes de tu
                clase, se te descontara el valor de tu reserva y perderás los
                créditos usados.{" "}
              </div>
        
              <div
                className=" d-row d-md-flex justify-content-center"
                style={laptop575.matches ? { margin: "auto",width:"30%" } : null}
              >
 <ButtonMob 
                handleClick={onClose}
               
              className="col-12 col-md-4 widthYesNo mx-1"
                text={"No quiero cancelar"}
              /> 
               <ButtonMob colorButton={"#9EACED"} 
                handleClick={async () => {
                    const resC = await instance.post(
                      `booking/book/cancel/${infoTrainingTime?.id}`
                    );
                    onClose();
                    navigate(`/Dashboard`);
                  }}
              
              className="col-12 col-md-4 widthYesNo mx-1"
                text={"Cancelar el entrenamiento"}
              /> 

              </div>
            </div>
          </div>
        );
      },
    });
  };


  return (
    <>
      <div
        className=" d-none d-md-flex col-9"
        style={{
          background: "white",
          height: "100%",

          borderRadius: "20px",

          justifyContent: "center",
        }}
      >
        {ModalInfo}
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div
              className="col-6 d-flex"
              style={{
                position: "relative",
                justifyContent: "center",
                padding: "5% 6%",
              }}
            >
              <img
                className="w-100"
                style={{
                  borderRadius: "20px",

                  backgroundImage: "cover",
                  objectFit: "cover",
                }}
                src={infoTraining && infoTraining.cover_picture}
                alt=""
              />
              <TitleRowClases
                infoTrainingTime={infoTrainingTime}
                infoTraining={infoTraining}
                handleclick={() => navigate(-1)}
              />
            </div>

            <div
              className="col-6 d-flex h-100"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                gap: "2%",
                padding: "5% 5% 5% 0",
              }}
            >
              <PerfectScrollbar
                className="pe-3 d-flex flex-column justify-content-stretch justify-content-xxl-center"
                style={{ height: "90%" }}
              >
                <ButtonTrainerStart
                  infoTraining={infoTraining}
                  sizeFont={"auto"}
                />

                <div className="my-2 d-flex flex-column">
                  <span
                    style={{
                      color: "#394F65",
                      fontWeight: "400",
                      fontSize: "calc(0.9rem + 0.3vw)",
                    }}
                  >
                    Metodologia
                  </span>
                  <span
                    style={{
                      color: "#A9B6C4",

                      fontSize: "calc(0.5rem + 0.3vw)",
                    }}
                  >
                    Disciplina {infoTraining.group ? "GRUPAL" : "individual"}
                  </span>
                </div>

                <div className="d-flex flex-column my-2">
                  <span
                    style={{
                      color: "#394F65",
                      fontWeight: "400",
                      fontSize: "calc(0.9rem + 0.3vw)",
                    }}
                  >
                    Información
                  </span>

                  <span
                    style={{
                      color: "#A9B6C4",

                      fontSize: "calc(0.5rem + 0.3vw)",
                    }}
                  >
                    {infoTraining.description}{" "}
                  </span>
                </div>
                {recomendaciones ? (
                  <div className="d-flex flex-column my-2">
                    <span
                      style={{
                        color: "#394F65",
                        fontWeight: "400",
                        fontSize: "calc(0.9rem + 0.3vw)",
                      }}
                    >
                      Recomendaciones
                    </span>

                    {recomendacionesText.map((e) => (
                      <div className="d-flex my-2">
                        <span className="my-auto col-2"> {e.icon} </span>
                        <span
                          className="col-10 my-auto"
                          style={{
                            color: "#A9B6C4",
                            fontSize: "calc(0.5rem + 0.3vw)",
                          }}
                        >
                          {" "}
                          {e.texto}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : null}
                {infoTraining.group ? (
                  <span
                    style={{
                      color: "#394F65",
                      fontWeight: "400",
                      fontSize: "calc(0.7rem + 0.3vw)",
                    }}
                  >
                    {/* Cupo disponible: 20 personas */}
                    <p>Cupos disponibles:20 personas</p>
                  </span>
                ) : null}
              </PerfectScrollbar>
              <div
                className="my-2 d-flex justify-content-start "
                style={{ height: "10%", maxHeight: "calc(1.3rem + 1vw)" }}
              >
                <ButtonMob
                  handleClick={goToClass}
                  // handleClick={() =>
                  //   navigate(`/selectTeacher/${infoTraining.id}`) }
                  // handleClick={shareScreenshot}
                  className={"marginButtonReserva col-4 me-2"}
                  text={"Iniciar"}
                />

                <ButtonMob
                  handleClick={submit}
                  colorButton={"white"}
                  colorTextButton={`var(--elevva)`}
                  borderbutton={`1px solid var(--elevva)`}
                  className={"marginButtonReserva col-4"}
                  text={"Cancelar"}
                />
                <div></div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* mobile */}
      {isLoading ? (
        <div className="d-md-none mobile-login ">
          <Loading className="d-md-none " />
        </div>
      ) : (
        <div
          className="d-md-none mobile-login w-100"
          style={{ height: "100%", background: "#fff" }}
        >
          <div className="d-flex px-md-4" style={{ background: "#fff" }}>
            <button
              onClick={() => navigate("/Dashboard")}
              className={"button_w_h_back d-md-none"}
              style={{ background: "transparent", border: "none" }}
            >
              <BackArrow width={40} />
            </button>
            <span className="title my-auto fw-light">
              Mis
              <strong> Reservas</strong>
            </span>
          </div>
          <div
            className=" mx-auto my-0"
            style={{ background: "#fff", width: "90%" }}
          >
            {" "}
            <hr />
          </div>
          <div
            className="containerBGClase"
            style={{
              objectFit: "cover",
              height: "30vh",
              backgroundImage: `url(${
                infoTraining && infoTraining.main_picture
              })`,
            }}
          >
            <TitleRowClases
              infoTrainingTime={infoTrainingTime}
              infoTraining={infoTraining}
              handleclick={() => navigate(-1)}
            />
          </div>
          <div
            className="mobile-welcome-login-row  text-left w-100 "
            style={{ top: "40vh", justifyContent: "start" }}
          >
            <div className={"d-flex flex-column justify-content-center gap-3"}>
              <PerfectScrollbar
                className="p-3 d-flex flex-column justify-content-stretch justify-content-xxl-center"
                style={{ height: "55vh" }}
              >
                {recomendaciones ? (
                  <div className="d-flex flex-column my-2 px-2">
                    {recomendacionesText.map((e) => (
                      <div className="d-flex my-2">
                        <span className="my-auto col-2"> {e.icon} </span>
                        <span
                          className="col-10 my-auto"
                          style={{
                            color: "#A9B6C4",
                            fontSize: "calc(0.9rem + 0.1vw)",
                          }}
                        >
                          {" "}
                          {e.texto}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className="d-flex col-12">
                  <ButtonMob
                    handleClick={goToClass}
                    id={"tutorialButtonReservaMobile"}
                    className="col-4"
                    text={"Iniciar"}
                  />{" "}
                  <ButtonMob
                    handleClick={submit}
                    colorButton={"white"}
                    colorTextButton={`var(--elevva)`}
                    borderbutton={`1px solid var(--elevva)`}
                    id={"tutorialButtonReservaMobile"}
                    className="col-4"
                    text={"Cancelar"}
                  />
                </div>
                {bookings ? (
                  <Misreservas
                    user={user}
                    bookings={bookings}
                    handleClick={handleClick}
                    mobileOn={true}
                  />
                ) : null}
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      )}
    </>
  );
};