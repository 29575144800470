import React, { useState, useEffect } from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,FlexibleWidthXYPlot, Borders, GradientDefs, LineMarkSeries
} from 'react-vis';


const ChartMobile = (props) => {
  const dataServer=props.dataServer;
  const maxl = props.maxl;
  const maximo = props.maximo;
  const intenso = props.intenso;
  const moderado = props.moderado;
  const luz = props.luz;
  const muy_suave = props.muy_suave;
  const base =props.base;
  const [data, setData] = useState( [
    { x: new Date('2022-01-01 12:00'), y: 100 },
    { x: new Date('2022-01-01 12:10'), y: 200 },
    { x: new Date('2022-01-01 12:15'), y: 150 },
    { x: new Date('2022-01-01 12:20'), y: 300 },
    { x: new Date('2022-01-01 12:25'), y: 250 },
    { x: new Date('2022-01-01 12:30'), y: 400 },
    { x: new Date('2022-01-01 12:35'), y: 350 },
    { x: new Date('2022-01-01 12:40'), y: 500 },
    { x: new Date('2022-01-01 12:45'), y: 450},
    { x: new Date('2022-01-01 12:47'), y: 600 },
    { x: new Date('2022-01-01 12:48'), y: 550 },
    { x: new Date('2022-01-01 12:49'), y: 700 },
    { x: new Date('2022-01-01 12:50'), y: 650 },
    { x: new Date('2022-01-01 12:51'), y: 800 },
    { x: new Date('2022-01-01 12:55'), y: 750 },
  ]);


useEffect(() => {
  if(dataServer){
    setData(dataServer)
  }
  
    }, [dataServer]);

  return (
    dataServer?<FlexibleWidthXYPlot yDomain={[base, maximo]}  style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",flexDirection:"column",  }} 
    height={200} margin={{ top: 20, left: 50, bottom: 30,right:50 }}>
    {/* <VerticalGridLines/> */}
   
   
<GradientDefs>
<linearGradient id='CoolGradientMo' x1='0' x2='0' y1='0' y2='1' >
<stop offset='15%' stopColor='rgba(225,98,80,1)' stopOpacity={1.0} />
      <stop offset='33%' stopColor='rgba(255,182,164,1)' stopOpacity={1.0} />
      <stop offset='51%' stopColor='rgba(245,215,207,1)' stopOpacity={1.0} />
      <stop offset='69%' stopColor='rgba(121,136,246,1)' stopOpacity={1.0}/>
      <stop offset='75%' stopColor='rgba(110,225,251,1)' stopOpacity={1.0}  />

    </linearGradient>
    
  </GradientDefs>
  <GradientDefs >
    <linearGradient  id='CoolGradientMo2' x1='0' x2='0' y1='0' y2='1'>
  
    <stop offset='15%' stopColor='rgba(225,98,80,1)' stopOpacity={1.0} />
      <stop offset='33%' stopColor='rgba(255,182,164,1)' stopOpacity={1.0} />
      <stop offset='51%' stopColor='rgba(245,215,207,1)' stopOpacity={1.0} />
      <stop offset='69%' stopColor='rgba(121,136,246,1)' stopOpacity={1.0}/>
      <stop offset='75%' stopColor='rgba(110,225,251,1)' stopOpacity={1.0}  />
      
    </linearGradient>
  </GradientDefs>
      <HorizontalGridLines />
      <Borders  style={{ bottom: {fill: 'transparent',opacity: 1},
    left: {rx:"20px", ry:"20px",height:"90%", fill:"url(#CoolGradientMo)",opacity: 1,},
    right: {rx:"20px", ry:"20px",height:"90%",fill:'url(#CoolGradientMo2)',opacity: 1},
    top: {fill: 'transparent',opacity: 1}
   }}/>
      <XAxis 
                    tickFormat={(value) =>
                      new Date(value).toString().substr(16, 8)}
                       tickTotal={3}
                    style={{ 
                      text: {
                        fontColor: `#6E6E6E `,
                          transform: "translateY(0, 0%)",
                         
                      },
                      line: { stroke: "#ffff" },
                      // ticks: { stroke: "#6E6E6E" },
                    }}
                  />
      <YAxis 
          // tickValues={[base,muy_suave,luz,moderado,intenso,maximo]}   
                     yDomain={[ base ,maximo]}
                    hideLine={true}
                    title="Zona ppm"
                   
                    orientation="left"
               
                    
                    style={{ 
                
                   
                         ticks: { stroke: "#ffff" },
                     
                   
                      text: {
                        fontWeight: '200',
                        //  fontWeight: 'bold',
                      // zIndex:"9999999999",
                        textAlign:"center",
                       
                      },
                      title: {
                       display:"none",
                     
                        
                        fontWeight: "700",
                     
                       
                         transform: "translate(1px, -12px)",
                      },
                      line: { stroke: "transparent" },
                    }}
                  />
      <YAxis

  position="middle"
  orientation="right"
  title="%"
  style={{ 
    ticks: { stroke: "#ffff" },
    line: { stroke: 'transparent' },
    title: {
      display:"none",
      fontColor: `#6E6E6E  `,
      fontWeight: '900',
      color: `#6E6E6E `,
       transform: 'translate(11px, -6px)',
    },
    text: {
  color:"#ffff",
  
       fontWeight: '200',
      
   
    
      //  transform: 'translate(15px, -57px)',
    },
  }}
  tickValues={[ 50, 60, 70, 80, 90, 100]} 

  yDomain={[50,100]}
/>


<LineSeries data={maxl ==0?[{x:0,y:0}]:data} color={"var(--elevva)"}/> 

      
    </FlexibleWidthXYPlot>:null
  );
};

export default ChartMobile;
