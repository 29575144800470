
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg"
import {
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  instance,
  NuevaAxiosURL,
  ButtonMob,
  laptop575,
} from "../../utils/utils";
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg";
import { MiniPerfil } from "./helpPerfil";
import Moment from "moment";
import { extendMoment } from "moment-range";
import PerfectScrollbar from "react-perfect-scrollbar";

const Transacciones = (props) => {
  const moment = extendMoment(Moment);
  const [modalInfo, setModalInfo] = useState(null);
  const [Year, setYear] = useState(Moment().format("YYYY"));
  const [userData, setUserData] = useState([]);
  const [dataTransaccions, setdataTransaccions] = useState(null);
  const stepIndex=props.stepIndex || 0
  const navigate = useNavigate();
  const location = useLocation();
  const añopresent = Moment().format("YYYY");
  const anioCreditCard = [
    añopresent,
    añopresent - 1,
    añopresent - 2,
    añopresent - 3,
  ];

  const hideModal = () => {
    setModalInfo(null);
  };

  function searchNumbers(str){
   
return str.match(/\d+/g)
  }


  useEffect(() => {


    instance
      .get("users/current/")
      .then((response) => {
      
        setUserData(response.data);
     
      })
      .catch((error) => {
        console.log(error);
      });
      instance
      .get(`${NuevaAxiosURL}customers/transactions/`)
      .then((resEn) => {
        setdataTransaccions(resEn.data);
      })
      .catch((err) => {
        console.error("Error en customers/plans/", err);
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Algo salio mal"
            text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
            classesMsg=""
          />
        );
      });

  }, [location.pathname]);



  return(<> {modalInfo} 

<div className="mx-3 my-3 d-flex">
   <button onClick={()=>navigate(-1)} className={"button_w_h_back"} style={{background:"transparent",border:"none",}}>
   <BackArrow width={"100%"} height={"100%"} /></button><span className="title my-auto fw-light" style={{fontSize:"1.2rem"}}>Resumen <strong>de compras</strong></span>     
    
      </div>
      <div className="mx-5">   <hr/></div>
      <div className=" tipopersonP col-4 ms-auto pe-4">
                    <select
                      onChange={(event) => {
                        setYear(event.target.value);
                      }}
                      name="Year"
                      style={{
                        fontSize: "1rem",
                        padding: " 1vh 1.5vw",
                        borderRadius: "20px",
                      }}
                      className="form-select px-2"
                    >
                      <option style={{ color: "#9DACBD" }} value="">
                        Año
                      </option>
                      {anioCreditCard.map((aniocreditcard) => (
                        <option
                          key={aniocreditcard}
                          style={{ color: "#9DACBD" }}
                          value={aniocreditcard}
                        >
                          {aniocreditcard}
                        </option>
                      ))}
                    </select>
                  </div>
               {stepIndex >0? <div className="containerCompras " id={"comprasPerfil"}>

<div className="itemCompras d-flex flex-column"><span className="letraPequeña fw-bold">Clase</span><span className="letraPequeña fw-light">1087</span></div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Fecha</span>
<span className="letraPequeña fw-light">2023-06-01</span>
</div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Hora</span>
<span className="letraPequeña fw-light">12:45 pm</span>
</div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Valor</span>
<span className="letraPequeña fw-light"> 1 crédito{" "}</span>
</div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Estado</span>
<span className="letraPequeña fw-light"> Aprobada</span>
</div>
<hr/>
</div>:   <PerfectScrollbar className={dataTransaccions &&
          dataTransaccions.filter(
            (e) =>
              moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") == Year
          ).length > 0?"d-flex flex-column ":"d-flex flex-column justify-content-center"} style={{height:"60vh",padding:"1.5rem"}}>
  {dataTransaccions &&
          dataTransaccions.filter(
            (e) =>
              moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") == Year
          ).length > 0 ?(
            dataTransaccions
              .filter(
                (e) =>
                  moment(e.date.slice(0, 10), "YYYY-MM-DD").format("YYYY") ==
                  Year
              )
              .map((trans,index)=>(<div className="containerCompras " id={index ==0?"comprasPerfil":""}>

<div className="itemCompras d-flex flex-column"><span className="letraPequeña fw-bold">Clase</span><span className="letraPequeña fw-light">{searchNumbers(trans?.description)}</span></div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Fecha</span>
<span className="letraPequeña fw-light">{Moment(trans?.date).format("YYYY-MM-DD")}</span>
</div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Hora</span>
<span className="letraPequeña fw-light">{Moment(trans?.date).format("LT a")}</span>
</div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Valor</span>
<span className="letraPequeña fw-light"> {Number(trans?.amount) == 1
                          ? Number(trans?.amount) + " crédito"
                          : Number(trans?.amount) < 500
                          ? Number(trans?.amount) + " créditos"
                          : trans?.amount +
                            " " +
                            trans?.currency}{" "}</span>
</div>
<div className="itemCompras d-flex flex-column">
<span className="letraPequeña fw-bold">Estado</span>
<span className="letraPequeña fw-light"> {trans?.status == 4
                          ? "Aprobada"
                          : trans?.status == 6
                          ? "Declinada"
                          : trans?.status == 7
                          ? "Fondos insuficientes"
                          : "Sin procesar"}</span>
</div>
<hr/>
</div>))):(
            <div className="d-flex flex-column justify-content-center">
             
              <div
                className="title mx-auto"
                style={{textAlign:"center"}}
              >
                {Year == ""
                  ? "Selecciona el periodo que deseas ver."
                  : "No hay transacciones disponibles para el año seleccionado."}
              </div>
            </div>
          )}
</PerfectScrollbar>}
    </>
  )
}
  export default Transacciones;