import React from "react";
// import ReactHtmlParser from "react-html-parser";
import { useEffect, useState, useRef } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import moment from "moment";
import Switch from "react-switch";
import { Link } from "react-router-dom";

// Modal
import Modalcito from "./utils/index";
import { ModalcitoOk } from "./utils/index";
import Button from 'react-bootstrap/Button';

import {
  Loadingspinner,
  instance,
  ModalInfo,
  NuevaAxiosURL,
  ButtonMob
} from "../../utils/utils";
import "swiper/swiper.min.css";
import "../OnboardingCustomer/onboardingCustomer.css";

SwiperCore.use([Pagination]);
export const YourObjective = (props) => {
  // console.log(moment());
  // const [Modal, setModal] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowOk, setModalShowOk] = useState(false);


  const textBirth = useRef(null);
  const modalesDolores = useRef(null);
  const modalesDoloresBack = useRef(null);
  const elevvaT = props.elevvaT;
  const [Modal, setModal] = useState(false);
  const [chekeaprofile, setchekeaprofile] = useState(true);
  const [confirmado, setModalConfirm] = useState(null);
  // modal
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  const [inputs, setInputs] = useState({
    birthday: "",
    gender: "",
    country: "",
    city: "",
    answer_1: false,
    answer_3: false,
  });


  const Ocultar = () => {
    setTimeout(() => {
      setModal(null);
    }, 0);
  };


  const hideModal = () => {
    setModal(false);
  };


  const objetivos = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      "Perder peso",
      process.env.PUBLIC_URL + "/assets/img/onboarding/loseB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/loseA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Mantener peso",
      process.env.PUBLIC_URL + "/assets/img/onboarding/physiqueB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/physiqueA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Salud",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Fuerza",
      process.env.PUBLIC_URL + "/assets/img/onboarding/forceB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/forceA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Tonificar",
      process.env.PUBLIC_URL + "/assets/img/onboarding/toneB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/toneA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Mejorar nivel",
      process.env.PUBLIC_URL + "/assets/img/onboarding/levelupB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/levelupA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];

  const [selectObjetivos, setselectObjetivos] = useState(
    new Array(objetivos.length)
  )
  const [selectObjetivos1, setselectObjetivos1] = useState(null)

  const service = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      "Entrenamiento",
      // "1:1 online",
      process.env.PUBLIC_URL + "/assets/img/onboarding/loseB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/loseA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Vídeos",
      // "pregrabados",
      process.env.PUBLIC_URL + "/assets/img/onboarding/levelupB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/levelupA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Clases grupales",
      // "online",
      process.env.PUBLIC_URL + "/assets/img/onboarding/toneB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/toneA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Entrenamiento 1:1", //0
      // "presencial y online", //1
      process.env.PUBLIC_URL + "/assets/img/onboarding/forceB.svg", //2
      process.env.PUBLIC_URL + "/assets/img/onboarding/forceA.svg", //3
      useRef(null), //4
      useRef(null), //5 
      useRef(null), //6

    ],
    [
      "Asesoria",
      // "en nutrición",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Seguimiento y",
      // "medición de resultados",
      process.env.PUBLIC_URL + "/assets/img/onboarding/physiqueB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/physiqueA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ]
  ]

  const [selectService, setselectService] = useState(
    new Array(service.length)
  )
  const [selectService1, setselectService1] = useState(null)


  // const renderSwitchh = (
  //   index,
  //   label,
  //   name,
  //   isCheck,
  //   textclasses,
  //   switchClasses
  // ) => {
  //   return (
  //     <>
  //       {" "}
  //       {label ? (
  //         <div
  //           className={textclasses}
  //           style={{
  //             maxWidth: "50%",
  //             margin: "auto 10%",
  //           }}
  //         >
  //           {label}{" "}
  //         </div>
  //       ) : null}{" "}
  //       <Switch
  //         name={name}
  //         onChange={(value) =>
  //           setInputs((val) => ({
  //             ...val,
  //             [name]: value,
  //           }))
  //         }
  //         checked={isCheck}
  //         className={switchClasses}
  //         handleDiameter={25}
  //         height={35}
  //         width={75}
  //         onHandleColor="#FFFF"
  //         offHandleColor="#9EACED"
  //         offColor="#E0E7FF"
  //         onColor="#9EACED"
  //         uncheckedIcon={
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               height: "100%",
  //               fontSize: 15,
  //               color: "#9EACED",
  //               paddingRight: 2,
  //               fontWeight: "700",
  //             }}
  //           >
  //             NO{" "}
  //           </div>
  //         }
  //         checkedIcon={
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               height: "100%",
  //               fontSize: 15,
  //               color: "white",
  //               paddingRight: 2,
  //               fontWeight: "700",
  //             }}
  //           >
  //             SI{" "}
  //           </div>
  //         }
  //       />{" "}
  //     </>
  //   );
  // };

  const selectCircleImg = (
    refContainer,
    refImg,
    index,
    classess,
    arr,
    onlyone
  ) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        iterator[3].current.classList = classess;
        iterator[3].current.style.fontWeight = `400`;
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
        iterator[4].current.style.border = `6px solid var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[4].current.style.backgroundColor = `var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[5] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
      refImg.current.style.backgroundImage = `url(${arr[index][1]})`;
    } else {
      refContainer.current.style.fontWeight = `600`;
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
      refImg.current.style.backgroundImage = `url(${arr[index][2]})`;
      refImg.current.style.backgroundColor = `var(--backgroundOnboardingObjective)`;
    }
    if (arr === objetivos) {
      // objetivos[index][5] = refContainer.current.active;
      // const copy = selectObjetivos;
      // copy[index] = refContainer.current.active;
      objetivos[index][5] = refContainer.current.active;
      // inputs["objectives"] = (index + 1) * 10;
      setselectObjetivos1((index + 1) * 10); // 10 20 30
      // console.log(setselectObjetivos1);

    }
    if (arr === service) {
      service[index][5] = refContainer.current.active;
      // inputs["objectives"] = (index + 1) * 10;
      setselectService1((index + 1) * 1); //1 2 3 3 
      // console.log(index, "Preference");
    }

  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const [currentSlider, setCurrentSlider] = useState(1);
  const [theSwipper, setTheSwipper] = useState(null);
  const [canNext, setCanNext] = useState(false);
  const [numberStep, setnumberStep] = useState(null);
  const [previewPhotoProfile, setPreviewPhotoProfile] = useState(
    process.env.PUBLIC_URL + "/assets/img/camera_update_picture.png"
  );
  // var handleChange = (event) => {
  //   let name = event.target.name;
  //   let value = event.target.value;
  //   setInputs((val) => ({
  //     ...val,
  //     [name]: value,
  //   }));
  // };
  useEffect(() => {
    if (checkCurrentForm(false)) {
      setCanNext(true);
    }
    return () => { };
  }, [currentSlider, selectObjetivos, selectObjetivos1, selectService, selectService1]);

  function nextSlider() {
    setCanNext(false);
    theSwipper.slideNext();
    if (checkCurrentForm()) {
    }
  }

  function prevSlider() {
    theSwipper.slidePrev();
  }


  const CompletarFooter = (props) => {
    const [modalInfo, setModalInfo] = useState(null);
    const chekeaprofile = props.chekeaprofile;

    const current = props.current;
    // const renderFootBar = current == valorllenotelefono ? true : false;
    const siguienteborrar = document.querySelector(
      ".CompletarFooter .anterior"
    );
    if (siguienteborrar) {
      if (current >= 1 && current < 2) {
        siguienteborrar.style = "background-color: transparent;";
      } else {
        siguienteborrar.style = "background-color: #CB3D82";
      }
    }

    function nextSlider() {
      props.nextSlider();
    }

    function prevSlider() {
      props.prevSlider();
    }

    // function mostraryatusabes() {
    //   if (chekeaprofile === true) {
    //     const fin = document.getElementById("tyc1");
    //     if (fin) {
    //       fin.style = "display:none";
    //     }
    //   }
    // }

    function mostraryatusabes() {
      if (chekeaprofile === true) {
        const fin = document.getElementById("tyc1");
        if (fin) {
          fin.style = "display:none";
        }
        {
          setModalInfo(
            <ModalInfo
              error="false"
              handleClick={() => {
                setModalInfo(null);
                completeProfile();
              }}
              title="¡Ya eres un Mobster!"
              text={
                "Tu cuenta a sido creada exitosamente. Al dar click vivirás una nueva experiencia."
              }
              classesMsg={"FaltanItemsmobster"}
            />
          );
        }
      }
    }
    const [isPrev, setIsPrev] = useState(null);

    const handleClick = () => {
      if (isPrev === null) {
        props.nextSlider();
        setIsPrev(false);
      } else if (isPrev) {
        props.prevSlider();
        setIsPrev(false);
      } else {
        props.nextSlider();
        setIsPrev(true);
      }
    };
    const classNext = `siguiente ${props.canNext == true ? "canNext" : ""}`;
    return modalInfo == null ? (


      <>
        <>
          {/* <Button variant="primary" onClick={() => setModalShow(true)}>
            Launch
          </Button> */}
          {/* <button onClick={sendDataFinal}>api</button> */}

          <Modalcito
            show={modalShow}
            onHide={() => setModalShow(false)}
          /> 
          
          <ModalcitoOk
            show={modalShowOk}
            onHide={() => setModalShowOk(false)}
          /> 
        </>
        <nav className="CompletarFooter navFooter">
          <div className=" d-flex justify-content-center text-color-subtitle texto_footer">
            {elevvaT("objective_skip")}&nbsp;
            <Link to={`/Usuario/Inicio`}>
              <u className="text-color-elevva">
                {elevvaT("objective_guide")}
              </u>
            </Link>
          </div>
          <ButtonMob
            // className={"d-none"}
            icon={"bi bi-chevron-left"}
            colorButton={`var(--elevva)`}
            handleClick={prevSlider}
            className="mx-2 sizebuttonLarge"
            text={"Anterior"}
          ></ButtonMob>
          {renderFootBar == true && props.canNext == true ? (
            <div className={classNext} onClick={mostraryatusabes}>
            Finalizar
            </div>
          ) : (
            <ButtonMob
              icon={"bi bi-chevron-right"}
              colorButton={`var(--elevvabutonLogin_User)`}
              handleClick={checkSlider}
              className="mx-2 sizebuttonLarge"
              text={"Siguiente"}
            ></ButtonMob>
            

          )}

          {/* <ButtonMob
      icon={`bi bi-chevron-${isPrev ? 'left' : 'right'}`}
      colorButton={`var(--elevva)`}
      handleClick={handleClick}
      className="mx-2 sizebuttonLarge"
      text={isPrev ? 'Sifuinetye' : 'Siguiente'}
    /> */}

        </nav>{" "}

      </>
    ) : (
      <> {modalInfo} </>
    );
  };


  function checkCurrentForm(show_warn = true) {
    const checkSlider = currentSlider;

    let lleno = true;
    function showModalWarn(faltaLLenar) {
      if (show_warn) {
        setModalShow(true);
        setModal(
          // <Modalcito
          // show={modalShow}
          // onHide={() => setModalShow(false)}
          // // faltaLLenar={props.faltaLLenar} // Pasar la propiedad faltaLLenar aquí
          // />
          
          
        );
      }
    }
    const faltaLLenar = [];
    switch (checkSlider) {

      case 1:
        lleno = false
        if (selectObjetivos1) lleno = true;

        if (!lleno) {
          faltaLLenar.push("▶ Objetivo principal");
        }
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;

      case 2:
        lleno = false
        if (selectService1) lleno = true;

        if (!lleno) {
          faltaLLenar.push("▶ Objetivo principales");
        }
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar)
          }
          return false
        }
        return true;
      case 3:
        if (faltaLLenar.length == 0) {
          if (selectObjetivos1 == true) {
            return (selectObjetivos1.current.style.display = "flex");
          } else {
            sendData();
          }
        } else {
          setModal(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Falta algo por llenar"
              text={
                "Faltan algunos datos por llenar: \n\n" + faltaLLenar.join("\n")
              }
              classesMsg={"FaltanItems"}
            />
          );
          Ocultar();
        }
      default:
        return true

    }
  }

  // function dataURItoBlob(dataURI) {
  //   // convert base64/URLEncoded data component to raw binary data held in a string
  //   var byteString;
  //   if (dataURI.split(",")[0].indexOf("base64") >= 0)
  //     byteString = atob(dataURI.split(",")[1]);
  //   else byteString = unescape(dataURI.split(",")[1]);

  //   // separate out the mime component
  //   var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  //   // write the bytes of the string to a typed array
  //   var ia = new Uint8Array(byteString.length);
  //   for (var i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }

  //   return new Blob([ia], {
  //     type: mimeString,
  //   });
  // }

  const labelInput = (name, inputClasses, placeholder, type) => {
    return (
      <>
        <input
          type={type}
          name={name}
          className={inputClasses}
          placeholder={placeholder}
        // onChange={handleChange}
        />{" "}
      </>
    );
  };
  const videoConstraints = {
    width: 500,
    maxWidth: "5vw",
    height: 500,
    facingMode: "user",
    position: "absolute",
  };


  const completeProfile = () => { //toca defiinir
    const faltaLLenar = [];
    let lleno = false;

    if (selectObjetivos1) lleno = true;
    if (!lleno) faltaLLenar.push("Objetivos")

    if (selectService1) lleno = true;
    if (!lleno) faltaLLenar.push("preference_activity")

  }

  // Validar slider

  function checkSlider() {
    
    if(selectObjetivos1 !== null) {
      nextSlider();
    }
    else {
      setModalShow(true);
      setModal(
        <Modalcito
        show={modalShow}
        onHide={() => setModalShow(false)}
        />
      );
    }
    
    if(selectObjetivos1 && selectService1) {

      const formData = new FormData();

      // objectives 10.20.30.40.
      formData.append("objectives", selectObjetivos1);

      // preference_activity 1,2,3,4,5
      formData.append("preference_activity", selectService1); // Agregando datos

      instance
      .patch(`${NuevaAxiosURL}customers/`, formData)
      .then(response => {
      // Los datos se enviaron con éxito, configurar el estado de modalShow en verdadero
      setModalShowOk(true);
      setModal(
        <ModalcitoOk
          show={modalShowOk}
          onHide={() => setModalShowOk(false)}
        />
      );
      })
      .catch(error => {
        //Ocurrio un error
        console.log(error);
      });
    }else {
      // setModalShow(true);
      // setModal(
      //   <Modalcito
      //     show={modalShow}
      //     onHide={() => setModalShow(false)}
      //     title="Por favor, completa todos los campos"
      //   />
      // );
      console.log("error");
    }
}


// Validacion los dos slider
// function checkSlider2() {
//   if( selectService1 !== null && selectObjetivos1 !== null){
//     alert("Enviando")
//   }else {
//     setModalShow(true);
//       setModal(
//         <Modalcito
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//         // faltaLLenar={props.faltaLLenar} // Pasar la propiedad faltaLLenar aquí
//         title="hola"
//         />
//       );
//   }
  
// }


  const Paso1 = (
    <SwiperSlide className="slider_form py-5 px-5 ">
      <div className="d-flex w-100">
        <div
          style={{
            width: "100%",
            minWidth: "380px",
          }}
        >
          <h1 className="text-color-title m-auto">
            {" "}
            {elevvaT("objective_text")} <strong> {elevvaT("objective_perfil")} </strong>{" "}
          </h1>{" "}
        </div>{" "}
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
          }}
        >
          <hr />
        </div>{" "}
      </div>{" "}
      <h2 className="text-color-title-low"> {elevvaT("objective")} </h2>
      <div
        className=" w-100"
        style={{
          justifyContent: "center",
          maxWidth: "80%",
          margin: "auto",
          marginTop: "10%",
        }}
      >
        {" "}
        <div className="formSteps  row  ">
          {" "}
          {objetivos.map((objetivo, index, objectives) => {
            return (
              <div
                key={index}
                ref={objetivo[3]}
                className="objetivo col-6 col-lg-4 "
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  selectCircleImg(
                    objetivo[3],
                    objetivo[4],
                    index,
                    "objetivo col-6 col-lg-4",
                    objectives,
                    true
                  )
                }
              >
                <div
                  ref={objetivo[4]}
                  id="icon"
                  style={{
                    backgroundImage: `url(${objetivo[1]})`,
                  }}
                  className="rounded-circle  text-center"
                ></div>{" "}
                <div
                  id="text"
                  className="m-2  text-center "
                  style={{
                    color: "var(--elevvaTextTitle)",
                  }}
                >
                  {objetivo[0]}{" "}
                </div>{" "}
              </div>
            );
          })}{" "}
        </div>{" "}
      </div>{" "}
    </SwiperSlide>
  );

  const Paso2 = (
    <SwiperSlide className="slider_form  ps-5">
      <div className="d-flex w-100">
        <div
          style={{
            width: "100%",
            minWidth: "380px",
          }}
        >
          <h1 className="text-color-title m-auto">
            {" "}
            {elevvaT("objective_text")} <strong> {elevvaT("objective_perfil")} </strong>{" "}
          </h1>{" "}
        </div>{" "}
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
          }}
        >
          <hr />
        </div>{" "}
      </div>{" "}
      <h2 className="text-color-title-low">
        {" "}
        {elevvaT("help_create_profile")}{" "}
      </h2>{" "}
      <h2 className="text-color-title-low"> {elevvaT("objective")} </h2>
      <div
        className=" w-100"
        style={{
          justifyContent: "center",
          maxWidth: "80%",
          margin: "auto",
          marginTop: "10%",
        }}
      >
        {" "}
        <div className="formSteps  row  ">
          {" "}
          {service.map((objetivo, index, preference_activity) => {
            return (
              <div
                key={index}
                // ref={objetivo[3].current}
                ref={objetivo[3]}
                className="objetivo col-6 col-lg-4 "
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                
                  selectCircleImg(
                    objetivo[3],
                    objetivo[4],
                    index,
                    "objetivo col-6 col-lg-4",
                    preference_activity,
                    true
                  )
                }
              >
                <div
                  // ref={objetivo[4].current}
                  ref={objetivo[4]}
                  id="icon"
                  style={{
                    backgroundImage: `url(${objetivo[2]})`,
                  }}
                  className="rounded-circle  text-center"
                ></div>{" "}
                <div
                  id="text"
                  className="m-2  text-center "
                  style={{
                    color: "var(--elevvaTextTitle)",
                  }}
                >
                  {objetivo[0]}{" "}
                  <br />
                  {/* <span className="secondary-text">{objetivo[1]}</span>                 */}
                  </div>{" "}
              </div>
            );
          })}{" "}
        </div>{" "}
      </div>{" "}

    </SwiperSlide>
  );

  const sendData = () => {
    setModal(
      <ModalInfo
        error="none"
        handleClick={hideModal}
        title="procesando"
        text={<Loadingspinner customStyle="grow" size="5rem" />}
        classesMsg=""
      />
    );
    sendDataFinal()
  }

 
  const sendDataFinal = () => {
   

    const formData = new FormData();

    // objectives 10.20.30.40.
    formData.append("objectives", selectObjetivos1);

    // preference_activity 1,2,3,4,5
    formData.append("preference_activity", selectService1); // Agregando datos


    // Agregando datos
    // formData.append("preference_activity", [1]); // con el  1 se le pasa el dato por default
    // formData.append("objectives", selectObjetivos1);
    // formData.append("preference_activity", selectService1);


    instance
      .patch(`${NuevaAxiosURL}customers/`, formData)

      .then((res) => {
        if (res.data) {
          // setselectObjetivos(true);
          // setselectObjetivos(false);
          // setselectService(true)
          // setselectService(false)
          // if(selectService1  == true) {
          //   window.location.href = `${basename}/${userType}/preference_activity`;
          // } if(selectObjetivos1  == true) {
          //   window.location.href = `${basename}/${userType}/objectives`;
          // }  else {
          //   window.location.href = `${basename}/${userType}/reservas/10`;
          //   // setModal(<Redirect to={`/${userType}/reservas/10`} />);
          // }
        }
      })
      .catch((err) => {
        console.log(err.response);
        // setModal(
        //   setModal(
        //     <SetModalError 
        //       handleClick={() => setModal(null)}
        //       title="Algo salio mal"
        //     />
        //   )
        // )
      })
  }
  const renderFootBar = (
    <>
      <nav className="footerStep6 ubicarfooter" onClick={completeProfile}>
        <div className="lh-1 siguiente">
          Empieza a reservar{" "}
          <ModalInfo
            // icon={faCheckCircle}
            size="2x"
            color="#2A9DA5"
            className="ms-2"
          />
        </div>
      </nav>
    </>
  );
  return (
    <>

      <ModalInfo
        handleClick={() => {
          // salvedadMedico.current.style.display = "none";
          sendData();
        }}
        error="false"
        title="Antes de empezar a hacer actividad física"
        // ref={salvedadMedico}
        display="none"
        text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
      />

      <div className="d-flex col-12 ">
        <div className="col-1 background-left-onboarding ">
          <div className="m-auto w-100 ">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo_elevva_alta.png"}
              className="logo-center-onboarding"
            />
          </div>{" "}
        </div>{" "}
        <div className="col-11 background-right-onboarding ">
          <div className="inside-content-imgLogo">
            <img
              className="img-logo-back-onboarding"
              src={process.env.PUBLIC_URL + "/assets/img/logooutback.png"}
            />{" "}
          </div>{" "}
          <div className="d-flex contain-info-onboarding ">
            <img
              className="col-3"
              src={process.env.PUBLIC_URL + "/assets/img/onboarding-girl.jpg"}
            />{" "}
            <div className="stepsBullets m-auto col-1"> </div>{" "}
            <div className="col-9 ">
              {/* {matches => 
           matches ? ( */}
              <Swiper
                spaceBetween={100}
                slidesPerView={1}
                autoHeight
                allowTouchMove={false}
                pagination={{
                  el: ".stepsBullets",
                  type: "custom",
                  renderCustom: function (swiper, current, total) {
                    setnumberStep(current);
                    if (theSwipper == null) {
                      setTheSwipper(swiper);
                    }
                    setCurrentSlider(current);
                    return `<div class='swiper-pagination-bullet Bullet position-relative  ${current >= 0 ? "active " : ""
                      }'><div class='centraNumberSteps'>1</div></div>
                                                        <div class='swipersep  ${current >= 1
                        ? "active"
                        : ""
                      }'  ></div>
                                                        <div class='swiper-pagination-bullet Bullet position-relative  ${current >= 1
                        ? "active"
                        : ""
                      }'><div class='centraNumberSteps'>2</div></div>
                                                         </div>
                                                      
                                                    `;
                  },
                  clickable: false,
                }}
              >
                <>
                  {Paso1}
                  {Paso2}
                </>
              </Swiper>
              {Modal}
              {confirmado}
              {/* ):(
              <div>
                <>
                  {Paso1}
                  {Paso2}
                </>
              </div>
            )} */}
            </div>{" "}
            
          </div>
          <CompletarFooter
            current={currentSlider}
            completeProfile={completeProfile}
            nextSlider={nextSlider}
            prevSlider={prevSlider}
            chekeaprofile={chekeaprofile}
            canNext={canNext}
          // current={currentSlider}
          // nextSlider={nextSlider}
          // prevSlider={prevSlider}
          // canNext={canNext}

          />{" "}
          <div className="inside-content-full"> </div>{" "}
        </div>{" "}
        {modalesDolores && modalesDolores.current} {modalesDoloresBack.current}{" "}
        <div
          ref={textBirth}
          className="position-absolute "
          style={{
            display: "none",
          }}
        // onClick={() => (textBirth.current.style = "display:none")}
        >
        </div>
      </div>
    </>
  );
};
