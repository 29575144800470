// Use the same css as Ejercicios.css

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg"
import { ReactComponent as IconTerminos} from "../../assets/newIcons/terminosIcon.svg"

import PerfectScrollbar from "react-perfect-scrollbar";


import {
  NuevaAxiosURL,
} from "../../utils/utils";





 const TyC = (props) => {

   

  const ComponentTerms = (props) => {
    const [accordionItems, setAccordionItems] = useState(null);
    const classMobile=props.classMobile;
    const heightScroll=props.heightScroll;
    const borderRadius=props.borderRadius;
    const navigate = useNavigate();
    useEffect(() => {
      searchTerminos(); // search all the faqs
    }, []);
  
    function searchTerminos() {
      const formData = new FormData();
      formData.append("data", 1);
      axios
        .get(`${NuevaAxiosURL}app/config`)
  
        .then((res) => {
          const info = res.data;
          const arrData = [];
          let count = 0;
          if (info) {
            for (const iterator of info) {
              // const name = (
              //   <span className="TyC">Términos y condiciones</span>
              // );
              
            
              const desc_items = unescape(iterator.terms)
                .split("\n")
                .map(function (item, idx) {
                  return (
                    <span key={idx} style={{color:"#9DACBD"}}>
                      {item}
                      <br />
                    </span>
                  );
                });
              const desc = desc_items;
              arrData.push(
             
                <div key={iterator}  className="p-2 m-auto">{desc}</div>
                
              );
              count += 1;
            }
          }
          if (count === 0) {
            setAccordionItems(
              <div className="form-text text-center" style={{ color: "#394F65" }}>
                No se encontrarón términos.
              </div>
            );
          } else {
            setAccordionItems(arrData);
          }
        })
        .catch((err) => {
          console.log("Error load_terminos_a", err);
        });
    }
   
    
        return(
          <div className={`${classMobile} mobile-login w-100 h-100` } style={{ background:"#fff",borderRadius:borderRadius }}>
          <div className="mx-3 my-3 d-flex px-md-4">
       <button onClick={()=>navigate("/Dashboard")} className={"button_w_h_back d-md-none"} style={{background:"transparent",border:"none",}}>
       <BackArrow width={"100%"} height={"100%"} /></button><span className="title my-auto fw-light">Políticas de privacidad <br/> <strong>Términos y condiciones</strong></span>     
            <div className="separador my-3"></div>
          </div>  <hr className='mx-auto' style={{width:"70%",color:"#C9C9C9"}}/>
      <div className="d-flex justify-content-center mx-auto d-md-none my-2"><IconTerminos  style={{width:"4rem", height:"4rem"}} /></div>    
        <div className=" preguntasfrecuentesweb w-100 ">
        
          <PerfectScrollbar className="w-100 position-relative" style={{height:heightScroll}}>
          <div className="glosario glosariopreguntas">
            <div
             
              className="accordion accordion-flush mt-4 px-3"
              id=""
            >
            {accordionItems}
            </div>
          </div>
          </PerfectScrollbar>
        </div>
      </div>
        )
       }
  return (
    <>
  {/* web */}
  <div className="d-none d-md-block  col-10 " style={{ height: "100%" }}>
     
     <div
       className=" position-relative "
       style={{
         background: "#FAF8F5",
         height: "100vh",
         padding: "2% 3% 2% 2%",
       }}
     >
       <div className="d-flex col-12" style={{ height: "100%" }}>
       <ComponentTerms classMobile={""} heightScroll={"calc(100vh - 15rem)"} borderRadius={"20px"} />
       
       </div>
     </div>
   </div>
  {/* mobile */}
   <ComponentTerms classMobile={"d-md-none"} heightScroll={"50vh"} borderRadius={"0px"}/>
   </>
  );
};
export default TyC;