
import React, { useRef } from "react";
import Moment from "moment";
import { useNavigate,useParams  } from "react-router-dom";
import {
  closeSocketConnection,
  disconnectPeer,
  emit_is_hr_device,
  initSocketConnection,
  instance,
  laptop575,
  ModalInfo,
  muteAudio,
  muteVideo,
  rtc,
  socket,
} from "../../utils/utils";
import "./misClases.css";
import {
  ButtonTutorial,
  ButtonTutorialReserva,
} from "../Dashboard/helpResponsive";
import {
  Misreservas,
  PersonalBook,
  useCompareDateOrder,
} from "./misclases_utils";
import icoNegativo from "../../assets/newIcons/bandC.svg";
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg";
import { useState, useEffect } from "react";
import { formControlLabelClasses } from "@mui/material";

const Misclases = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [modalInfo, setModalInfo] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [bookingId, setBookingId] = useState([]);
  const [userData, setUserData] = useState([]);

  const [bandConnected, setBandConnected] = useState("static");
  const [consultbandConnected, setconsultBandConnected] = useState(false);
  useEffect(() => {
    setBandConnected("static");

    if (socket.connected) {
      muteVideo();
      muteAudio();
      if (rtc.client) {
        rtc.client.leave();
      }
      closeSocketConnection();
    }
    const inicioday = Moment().format().slice(0, 11) + "00:00";

    const finislongdays =
      Moment().add(30, "days").format().slice(0, 11) + "00:00";
    instance
      .get(
        `booking/?available&date__lte=${finislongdays}&date__gte=${inicioday}`
      )

      .then((response) => {
        if (response.data) {
          let bookings_to = response.data;

          let bookings = bookings_to.sort(useCompareDateOrder);

          if (bookings.length == 0) {
            setModalInfo(
              <ModalInfo
                error="false"
                handleClick={() => navigate(`/Dashboard`)}
                classcorrecta={"recuerdaTitle"}
                handleCancellcargue={() => navigate(`/Dashboard`)}
                text={`No tienes reservas por el momento.<br/>`}
                classesMsg="pb-1"
              />
            );
          }

          setBookings(bookings);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setModalInfo(
            <ModalInfo
              error="false"
              handleClick={() => navigate(`/Dashboard`)}
              textButtonConfirmar={"Mejorar mi plan"}
              classcorrecta={"recuerdaTitleBlock"}
              handleCancellcargue={() => navigate(`/Dashboard`)}
              title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
              text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
              classesMsg="colorText_Modal"
            />
          );
          // setModalInfo(
          //   <ModalInfo
          //     error="false"
          //     handleClick={() => navigate(`/Dashboard`)}
          //     classcorrecta={"recuerdaTitle"}
          //     handleCancellcargue={() => navigate(`/Dashboard`)}
          //     text={`${error.response.data.detail}`}
          //     classesMsg="pb-1"
          //   />
          // );
        } else {
          setModalInfo(
            <ModalInfo
              error="false"
              handleClick={() => navigate(`/Dashboard`)}
              classcorrecta={"recuerdaTitle"}
              handleCancellcargue={() => navigate(`/Dashboard`)}
              text={`Algo ha ocurrido`}
              classesMsg="pb-1"
            />
          );
        }
      });

    instance
      .get("users/current/")
      .then((response) => {
        if (response.data) {
          setUserData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const goToClassLink = () => {
    // if (bookingId?.is_group) {
    //   window.location.href = `/Usuario/clasestream/${bookingId?.id}`;
    // } else {
    console.log(
      "entro",
      localStorage.room_idUser != bookingId?.room_id,
      localStorage.room_idUser
    );

    if (
      localStorage.room_idUser &&
      localStorage.room_idUser != bookingId?.room_id
    ) {
      localStorage.removeItem("room_idUser");
      localStorage.removeItem("MethodologyCustomer");
      localStorage.removeItem("workoutsetActual");
      localStorage.removeItem("ElevvaSockedId");
      localStorage.removeItem("contadorActual");
      localStorage.removeItem("lastWorkoutlog");
    }
    //setClase(<Redirect   to={`/Usuario/clase/${id}`} />)
    return navigate(`/Usuario/clase/${bookingId?.id}`);
    // }
  };

  function socketInit(idreserva) {
    if (
      localStorage.ElevvaSockedId &&
      localStorage.elevvaAppReserva &&
      Number(localStorage.elevvaAppReserva) != idreserva
    ) {
      closeSocketConnection();
    }

    initSocketConnection({
      onConnected: (data) => {
        // Aquí actualizas el estado con los datos recibidos desde el servidor para el evento "booking_trainer_connected"
        console.log(JSON.parse(data));
        console.log(JSON.parse(data).from);
      },
      // Función de devolución de llamada para manejar la desconexión
      onDisconnected: (reason) => {
        console.log(reason);
        console.log("Desconectado del servidor de Socket.IO");
        // Puedes agregar lógica adicional aquí si es necesario
      },
      onConnectedHrDevice: (band) => {
        console.log(band);
        let stateResponse = JSON.parse(band);
        if (stateResponse.customer == localStorage.id) {
          setBandConnected(true);
          console.log("se conecto la banda");
        }
        // Puedes agregar lógica adicional aquí si es necesario
      },
      onDisconnectedHrDevice: (bandDisconnected) => {
        console.log(bandDisconnected);
        let stateResponse = JSON.parse(bandDisconnected);
        if (stateResponse.customer == localStorage.id) {
          setBandConnected(false);
          console.log("se desconecto la banda");
        }
        // Puedes agregar lógica adicional aquí si es necesario
      },
      onHrdevice: (hrdevice) => {
        console.log(hrdevice, JSON.parse(hrdevice));
        let stateResponse = JSON.parse(hrdevice);
        if (stateResponse.customer == localStorage.id) {
          console.log("va a entrar ?");
          changeBand(stateResponse.status);
        }
      },
      customProperty: idreserva,
    });
  }

  function changeBand(hrdevice) {
    if (hrdevice) {
      setBandConnected(true);
    } else {
      setBandConnected(false);
    }

    console.log("hrdevice==>>>>>", hrdevice);
  }

  useEffect(() => {
    if (bandConnected !== "static") {
      if (bandConnected == true) {
        setModalInfo(
          <ModalInfo
            error="none"
            textButtonAction="Omitir"
            handleCancellcargue={hideModal}
            textButtonConfirmar={"Continuar"}
            handleClick={() => {
              localStorage.bandElevvaConnected = true;
              goToClassLink();
              hideModal();
            }}
            iconoWidth={"3rem"}
            textSizeUp={"1.3rem"}
            icono={icoPositivo}
            loader={true}
            text={`<b>Tu HR Sensor se ha  <br/> conectado correctamente </b>
               <br/> <br/>`}
          />
        );
        setTimeout(() => {
          localStorage.bandElevvaConnected = true;
          goToClassLink();
          hideModal();
        }, 1500);
      } else {
        setModalInfo(
          <ModalInfo
            error="none"
            textButtonAction="Continuar sin banda"
            handleCancellcargue={hideModal}
            backgroundReservarBorder={
              laptop575.matches ? null : "btnActionCancelar_Without_band"
            }
            handleReservar={() => {
              localStorage.bandElevvaConnected = false;
              hideModal();
              goToClassLink();
            }}
            iconoWidth={"3rem"}
            textSizeUp={"1.3rem"}
            icono={icoNegativo}
            text={`<b>Tu HR Sensor no se <br/> encuentra conectado </b>
               <br/> <br/> Por favor ingresa a la app <br/>para configurarlo <br/><br/>`}
          />
        );
      }
    }
  }, [bandConnected]);
  useEffect(() => {
    if (id == "all") {
      setBookingId(bookings[0]);
      console.log(bookings);
      localStorage.elevvaAppReserva = bookings[0]?.id;

      // socketInit(bookings[0]?.id)
    } else {
      let bookingFilter = bookings.filter((e) => e.id == id);
      setBookingId(bookingFilter[0]);
      localStorage.elevvaAppReserva = bookingFilter[0]?.id;
      // socketInit(bookingFilter[0]?.id)
    }
  }, [bookings]);

  function hideModal() {
    setModalInfo(null);
    setBandConnected("static");

    // setconsultBandConnected(false)
  }

  return (
    <>
      {" "}
      {modalInfo}
      {laptop575.matches ? (
        <div className="d-none d-md-block col-10" style={{ height: "100%" }}>
          <div
            className=" position-relative "
            style={{
              background: "rgba(0,0,0,.7)",
              height: "100vh",
              padding: "2% 3% 2% 2%",
            }}
          >
            <div
              className="d-flex col-12"
              style={{ gap: "2%", height: "100%" }}
            >
              <Misreservas
                user={userData}
                bookings={bookings}
                handleClick={(e) => {
                  localStorage.elevvaAppReserva = e;
                  let selec = bookings.filter((ele) => ele.id == e);

                  setBookingId(selec[0]);
                }}
              />
              <PersonalBook
                bandConnected={bandConnected}
                startSocket={(e) => socketInit(e)}
                consultbandConnected={consultbandConnected}
                handleAlertBand={() => setconsultBandConnected(true)}
                notClassYet={() =>
                  setModalInfo(
                    <ModalInfo
                      error="false"
                      handleClick={hideModal}
                      textButtonConfirmar={"Entendido"}
                      classcorrecta={"recuerdaTitle"}
                      handleCancellcargue={hideModal}
                      title="Aún no ha iniciado tu clase"
                      text={`<br/>Debes ingresar<br/>a la hora seleccionada.<br/><br/>`}
                      classesMsg="colorText_Modal"
                    />
                  )
                }
                handleAlertBandTrue={() =>
                  setModalInfo(
                    <ModalInfo
                      error="none"
                      textButtonAction="Omitir"
                      handleCancellcargue={hideModal}
                      textButtonConfirmar={"Continuar"}
                      handleClick={() => {
                        setconsultBandConnected(true);
                        localStorage.bandElevvaConnected = true;
                        hideModal();
                      }}
                      iconoWidth={"3rem"}
                      textSizeUp={"1.3rem"}
                      icono={icoPositivo}
                      text={`<b>Tu HR Sensor se ha  <br/> conectado correctamente </b>
             <br/> <br/>`}
                    />
                  )
                }
                infoTraining={bookingId?.training}
                infoTrainingTime={bookingId && bookingId}
                recomendaciones={true}
                bookings={bookings}
              />

              <ButtonTutorial
                handleClick={() =>
                  navigate(`/TutorialReserva/${bookingId?.training?.id}`)
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-md-none mobile-login w-100"
          style={{ height: "100%" }}
        >
          <PersonalBook
            startSocket={(e) => socketInit(e)}
            bandConnected={bandConnected}
            infoTraining={bookingId?.training}
            infoTrainingTime={bookingId}
            recomendaciones={true}
            bookings={bookings}
            user={userData}
            handleClick={(e) => {
              let selec = bookings.filter((ele) => ele.id == e);

              setBookingId(selec[0]);
            }}
          />

          <ButtonTutorialReserva
            handleClick={() =>
              navigate(`/TutorialResponsive/${bookingId?.training?.id}`)
            }
          />
        </div>
      )}
    </>
  );
};
export default Misclases;