
import React from "react";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, navigate } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import iconoFlechaElevva from "../assets/newIcons/ICO-FLECHA-ELEVVA.svg";
import muted from "../assets/img/svgcall/SonidoOff.svg";
import AgoraRTM from "agora-rtm-sdk";
import noMuted from "../assets/img/svgcall/SonidoOn.svg";
import videoOn from "../assets/img/svgcall/videoOn.svg";
import videoOff from "../assets/img/svgcall/videoOff.svg";
import colgar from "../assets/img/svgcall/Colgar.svg";
import llamar from "../assets/img/svgcall/Llamar.svg";
import myCamera from "../assets/img/reservasimages/myCamera.png";
import camera_expand from "../assets/img/logosconvierte/expandircmar.png";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/styles.css";
import Moment from "moment";
import {
  faCheckCircle,
  faTimesCircle,
  faMusic,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
//spinner
import { BarLoader, HashLoader } from "react-spinners";
// modal bootstrap
import { Modal, Button } from "react-bootstrap";

import SwiperCore, { Virtual, Navigation, Pagination } from "swiper";
 import {  RtmTokenBuilder, RtmRole } from "agora-access-token";
// import { ChatTokenBuilder } from "agora-token";
//import { Swiper, SwiperSlide } from 'swiper/react';

import AgoraRTC from "agora-rtc-sdk-ng";
import { useRef } from "react";
// Import Swiper styles
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper.min.css";

import { decodeJwt } from "jose";
import io  from "socket.io-client";
//mport Slider from "../components/inicio/Slider";
import { Multiselect } from "react-widgets";
import "./utils.css";
// import { RtcRole } from "agora-access-token";
import { Allvideos } from "../components/grupales/utils";
import { renderCorazones } from "../components/TutorialReserva/stepsHelp";



export const laptop575 = window.matchMedia("(min-width: 921px)");
export const laptop1600 = window.matchMedia("(min-width: 1650px)");
// export const NuevaAxiosURL = `https://api.elevva.com.co/api/v1/`; // Enviando datos a Elevva API

export const NuevaAxiosURL = process.env.REACT_APP_URL_SERVER + `/api/v1/`; // Enviando datos a Staging API
export const urlImages = process.env.REACT_APP_URL_SERVER
export const basename = process.env.NODE_ENV == "development" ? `` : "";
export const instance = axios.create({
  baseURL: NuevaAxiosURL,

  params: {
    t: new Date().getTime(),
  },
});
export const tawkToPropertyId = "5efb722a4a7c6258179ba1b3";
export const tawkToKey = "default";
export const userType = "Usuario";


// Iniciar la conexión
export const socket = io("https://api.elevva.com.co", {
autoConnect: false,
  extraHeaders: {
    access_token: localStorage.my_code,
    booking_id: localStorage.elevvaAppReserva,
  },
});

export const initSocketConnection = async ({
  onConnected,
  onDisconnected,
  onConnectedHrDevice,
  onDisconnectedHrDevice,
  onworkoutlogData,
  onChangeExerciseData,
  onStart,
  onRoom,
  onFinishCustomer,
  syncLock,
  onHrdevice,
  onWorkoutAll,
  customProperty,
  onFinishTrainer,
  onCustomerConnectedGroup,
  onUsersInRoom,
}) => {
  // let socketURL;
  // await axios
  //   .get(`${NuevaAxiosURL}app/config`)
  //   .then((res) => {
  //     const info = res.data;
  //     if (info) {
  //       console.log(info);
  //       for (const iterator of info) {
  //         socketURL = iterator.socketio_url;
  //       }
  //     }
  //   })
  //   .catch((err) => alert("backend error"));

  // // socket =  io('https://api.elevva.com.co', {
  // socket = await io(socketURL || "https://api.staging.elevva.com.co:8000", {
  //   autoConnect: false,
  //   extraHeaders: {
  //     access_token: localStorage.my_code,
  //     booking_id: customProperty,
  //   },
  // });
  console.log('llega a iniit',socket);
  const engine = socket.io;
  engine.opts.extraHeaders["booking_id"] = customProperty;

  if(!socket.connected){
      socket.connect();
      socket.on("connect", () => {
        console.log(
          "Conectado al servidor de socket.IO",
          socket.id,
          socket.connected
        );
        localStorage.ElevvaSockedId = socket.id;
      });
  }
  if (onConnected != undefined){
    socket.on("booking_trainer_connected", (res) => {
      console.log("booking_trainer_connected", res);
      // Llamamos a la función de devolución de llamada para manejar la conexión
      onConnected(res);
    });
  }
  if (onDisconnected != undefined){
  socket.on("disconnect", (reason) => {
    console.log(reason);
    console.log("Desconectado del servidor de socket.IO");
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onDisconnected(reason);
  });
}
if (onConnectedHrDevice != undefined){
  socket.on("hr_device_connected", (band) => {
    console.log(band);
    console.log("hr_device_connected true");
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onConnectedHrDevice(band);
  });
}
if (onDisconnectedHrDevice != undefined){
  socket.on("hr_device_disconnected", (bandDisconnected) => {
    console.log(bandDisconnected);
    console.log("hr_device_disconnected true");
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onDisconnectedHrDevice(bandDisconnected);
  });
}
if (onworkoutlogData != undefined){
  socket.on("hr_data", (workoutlog) => {
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onworkoutlogData(workoutlog);
  });
}
if (onChangeExerciseData != undefined){
  socket.on("exercise_changed", (change) => {
    console.log("exercise_changed");
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onChangeExerciseData(change);
  });
}
if (onStart != undefined){
  socket.on("booking_started", (start) => {
    console.log("booking_started", start);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onStart(start);
  });
}
if (onRoom != undefined){
  socket.on("your_room", (room) => {
    console.log("your_room", room);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onRoom(room);
  });
}
if (onFinishCustomer != undefined){
  socket.on("booking_finish_customer", (finish) => {
    console.log("booking_finish_customer", finish);
    onFinishCustomer(finish);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
  });
}
if (syncLock != undefined){
  socket.on("get_clock", (lock) => {
    console.log("get_clock", lock);
    syncLock(lock);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
  });
}
 if (onHrdevice != undefined){
  socket.on("is_hr_device", (hrdevice) => {
    onHrdevice(hrdevice);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
  });
}
  if (onWorkoutAll != undefined){
  socket.on("workoutlog_data", (workoutlog) => {
    // Llamamos a la función de devolución de llamada para manejar la desconexión
    onWorkoutAll(workoutlog);
  });
}
if (onFinishTrainer != undefined){
  socket.on("booking_finish_trainer", (finish) => {
    console.log("booking_finish_trainer", finish);
    onFinishTrainer(finish);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
  });
}
if (onCustomerConnectedGroup != undefined){
  socket.on("booking_customer_connected", (whoConected) => {
    console.log("booking_customer_connected", whoConected);
    onCustomerConnectedGroup(whoConected);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
  });
}
if (onUsersInRoom != undefined){
  socket.on("users_in_room", (usersRoom) => {
    console.log("users_in_room", usersRoom);
    onUsersInRoom(usersRoom);
    // Llamamos a la función de devolución de llamada para manejar la desconexión
  });
}
};
export function sync_clock() {
  socket.emit("sync_clock", {});
}

export function booking_log_data_emit(data) {
  socket.emit("booking_log_data", data);
}
export function emit_is_hr_device() {
  socket.emit("is_hr_device", {});
}
export function hr_data_emit(data) {
  socket.emit("hr_data", data);
}
// Cerrar la conexión
export const closeSocketConnection = () => {
  socket.disconnect();
};
export function get_users_room_emit() {
  socket.emit("get_users_in_room", {});
}
export const eventClock = () => {
  socket.emit("sync_clock", {});
};
export const eventGetRooms = () => {
  socket.emit("get_rooms", {});
};
export function booking_client_connected_emit (data) {
  console.log(data)
  socket.emit("booking_customer_connected", data);
};

export function workoutlog_data_emit(data){
  console.log(data)
  socket.emit("workoutlog_data", data);
};

export function booking_finish_customer_emit(data){
  console.log(data)
  socket.emit("booking_finish_customer", data);
};



instance.interceptors.request.use(async function (response) {
  let refresh = "users/auth/login/refresh";
  // const data_user = response.data // avatar
  if (localStorage.my_code) {
    const { exp } = decodeJwt(localStorage.my_code);
    // console.log(Date.now(), exp * 1000);
    // var tiempoexp = exp * 1000 - 3580000;
    var tiempoexp = exp * 1000 - 35000;
    var tiempoahora = Date.now();
    // console.log(tiempoahora - tiempoexp);
    // console.log("refresh", localStorage.refresh);
    // console.log("accces", localStorage.my_code);
    if (tiempoahora >= tiempoexp) {
      var formData = new FormData();
      formData.append("refresh", localStorage.refresh);
      await axios({
        method: "post",
        contentType: "application/json",
        url: `${NuevaAxiosURL}${refresh}`,
        data: formData,
        // firts_name: data_user.first_name, // avatar
        // last_name: data_user.last_name,  // avatar
      })
        .then((res) => {
          const data = res.data;
          // console.log("datarararefreshokoko---------------", data);
          if (data) {
            localStorage.my_code = data.access;
            localStorage.refresh = data.refresh;
          }
        })
        .catch((err) => {
          // localStorage.clear();
          window.location.href="/";
          // window.location.reload();
        });
      response.params["t"] = new Date().getTime();
      response.headers["Authorization"] = "Bearer " + localStorage.my_code;
      return response;
    } else {
      response.params["t"] = new Date().getTime();
      response.headers["Authorization"] = "Bearer " + localStorage.my_code;
      return response;
    }
  } else {
    response.params["t"] = new Date().getTime();
    response.headers["Authorization"] = "Bearer " + localStorage.my_code;
    return response;
  }
});

/*
 * Connect to agora class
 *
 */
function addWaitMsg(
  where_element,
  msg = "Espera un momento por favor, estamos conectando con la clase. Por favor no actualice esta ventana."
) {
  const div = document.createElement("div");
  div.style.fontSize = "4vmin";
  div.style.fontFamily = "WorkSans";
  div.style.fontWeight = "bold";
  div.style.backgroundColor = "#ffffffCC";
  div.style.width = "100%";
  div.style.height = "100%";
  div.style.textAlign = "center";
  div.style.display = "grid";
  div.style.position = "absolute";
  div.style.zIndex = "0";
  div.style.color = "#CB3D82";
  const clockDiv = document.createElement("div");
  clockDiv.classList.add("clock");
  const containerClock = document.createElement("div");
  containerClock.id = "containerClock";
  containerClock.append(clockDiv);
  div.append(containerClock);

  const p = document.createElement("p");
  p.style.width = "80%";
  p.style.marginLeft = "auto";
  p.style.marginRight = "auto";
  p.append(msg);
  div.append(p);
  where_element.append(div);
}

export const rtc = {
  client: null,
  localAudioTrack: null,
  localVideoTrack: null,
  remoteVideoTrack: null,
  remoteAudioTrack: null,
  localAudioMusic: null,
  remoteUser: null,
  selectedCamId: null,
  selectedMicId: null,
  selectedOutputDevice: null,
};
export const rtcstream = {
  localAudioTrack: null,
  localVideoTrack: null,
  remoteVideoTrack: null,
  remoteAudioTrack: null,
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};

const dataStats = {
  intervalCheckNetwork: null,
  netQA: {},
  errorMsg: 0,
};
let toCheckRemoteAudioWebView = true; // check the remote audio
const expirationTimeInSeconds = 4000;
const currentTimestamp = Math.floor(Date.now() / 1000);
let intervalCheckingRemoteAudio = null;

const options = {
  uid: null,
  returnUid: null,
  account: "",
  type: null,
  
  appId: "e18a24f4daf54ec7bc47ff2ac70f791f",
  otherAppId: "f4e57630bd7b460caeb5df607f9ef34f",
  appCertificate: "9f5d1573584c4f33a3d560740c954b25",
  //channel: `mob_${reserva}`,
  token: null,
  privilegeExpiredTs: currentTimestamp + expirationTimeInSeconds,
};

export let timeOutcheckAudio = null;
export let timeOutcheckVideo = null;
let audioEnabled = false;

export async function muteVideo() {
  const iconMuted = document.querySelector("#videoIcon");
  if (!rtc.localVideoTrack || !iconMuted) {
    //If not have localVideoTrack or not rendered the icon
    return;
  }
  let activado = true;
  if (!rtc.localVideoTrack.isPlaying) {
    // if is playing,
    await rtc.localVideoTrack.setEnabled(true);
    await rtc.client.publish(rtc.localVideoTrack);
    rtc.localVideoTrack.play("miVideo"); //if not, play in div id suVideo
    iconMuted.src = videoOn;
    activado = true;
  } else {
    await rtc.client.unpublish(rtc.localVideoTrack);
    rtc.localVideoTrack.stop();
    iconMuted.src = videoOff;
    activado = false;
    await rtc.localVideoTrack.setEnabled(false);
  }
  toast(`Camara ${activado ? "activada" : "desactivada"}`);
}

export const RenderBtnClases = (props) => {
  const hidding = props.clientestream ? true : props.hidding;
  const timeout = useRef(null);
  const trainerMusic = props.trainerMusic || null;

  const ubication_iconos = props.clientestream
    ? `containerIconStream`
    : props.trainerMusic
    ? `containerIconMusic`
    : `containerIcon`;
   
  const point = {
    cursor: "pointer",
  };
  return (
    <>
      <ToastContainer />
      <div
        id={ubication_iconos}
        className={`${hidding ? "" : "hidding-blurred"}`}
      >
        {trainerMusic ? (
          <button
            id="commentsAllpeople"
            style={{
              background: "rgb(248, 220, 209)",
              borderRadius: "30px",
              width: "5.7vmin",

              border: "1px solid rgb(248, 220, 209)",
              height: "5.7vmin",
            }}
          >
            {" "}
            <FontAwesomeIcon
              icon={faComment}
              size="2x"
              style={{ color: "#7B86FA", padding: "0.4vmin" }}
            />
          </button>
        ) : null}
        {trainerMusic ? (
          <>
            <button
              id="MusicPlayList"
              style={{
                background: "#7B86FA",
                borderRadius: "30px",
                border: "1px solid #7B86FA",
                width: "5.5vmin",
                height: "5.5vmin",
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faMusic}
                size="2x"
                style={{ color: "white", padding: "0.6vmin" }}
              />
            </button>
            {/* <button id="startRecord">Inicia a grabar</button>
            <button id="queryRecord">Adquirir</button>
            <button id="stopRecord">Detener grabación</button> */}
          </>
        ) : null}
        {props.clientestream ? null : (
          <img
            src={muted}
            alt="audio"
            id="mutedIcon"
            onClick={hidding ? muteAudio : null}
            style={point}
          />
        )}
        {props.clientestream ? null : (
          <img
            src={videoOff}
            alt="video"
            id="videoIcon"
            onClick={hidding ? muteVideo : null}
            style={point}
          />
        )}
        {props.clientestream ? (
          <>
            <div className="searchprueba-now ">
              {" "}
              <div className="container">
                <div className="d-flex">
                  <input
                    id="send_channel_message"
                    placeholder="Envia un mensaje"
                  ></input>{" "}
                  <button
                    value=""
                    id="buttonSendMessage"
                    className="search"
                  ></button>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/* <img
          src={colgar}
          alt="colgar"
          id="colgar"
          onClick={colgarLlamada}
          style={point}
        /> */}
      </div>
    </>
  );
};

export async function muteAudio() {
  const iconMuted = document.querySelector("#mutedIcon");
  if (!rtc.localAudioTrack || !iconMuted) {
    //If not have localAudioTrack or not rendered the icon
    return;
  }
  if (!audioEnabled) {
    // if isn't playing,
    //rtc.localAudioTrack.setEnabled(audioEnabled) // setEnable automaticaly fire the publish or unpublish method
    await rtc.client.publish(rtc.localAudioTrack);
    // the localAudioTrack isn't neccessary to play to same cliente, because hear echo
    iconMuted.src = noMuted;
    audioEnabled = true;
  } else {
    // rtc.localAudioTrack.setEnabled(audioEnabled)
    await rtc.client.unpublish(rtc.localAudioTrack);
    iconMuted.src = muted;
    audioEnabled = false;
  }
  toast(`Microfono ${audioEnabled ? "activado" : "desactivado"}`);
}

function checkVideo() {
  const iconMuted = document.querySelector("#videoIcon");
  if (!rtc.localVideoTrack || !iconMuted) {
    // If not have localVideoTrack or not rendered the icon
    return;
  }
  // Now, check righ now the actual status
  if (rtc.localVideoTrack.isPlaying) {
    // if is playing
    iconMuted.src = videoOn; // show no muted icon
  } else {
    iconMuted.src = videoOff;
  }
}
export function objEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function checkAudio() {
  const iconMuted = document.querySelector("#mutedIcon");
  if (!iconMuted) {
    // If not have localAudioTrack or not rendered the icon
    return;
  }
  // Now, check righ now the actual status
  if (audioEnabled) {
    // if is playing
    iconMuted.src = noMuted; // show no muted icon
  } else {
    iconMuted.src = muted;
  }
}

let actualCheckingRemoteAudio = 0;
function checkingRemoteAudio(time = 2000) {
  clearInterval(intervalCheckingRemoteAudio);
  intervalCheckingRemoteAudio = setInterval(() => {
    if (actualCheckingRemoteAudio >= 15) {
      clearInterval(intervalCheckingRemoteAudio);
      return true;
    }
    if (!rtc.remoteAudioTrack?.isPlaying || localStorage.inWebView == "true") {
      colgarLlamada(true);
      clearInterval(intervalCheckingRemoteAudio);
      toast.info(
        "No pudimos conectarte automaticamente, Verifica tu red o actualiza la página",
        {
          position: "bottom-center",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      const colgarIcon = document.querySelector("#colgar");
      if (colgarIcon) {
        colgarIcon.classList.add("resplandor"); // add resplandor to ConnectClass button
      }
      return false; // toCheck = false
    }
    actualCheckingRemoteAudio++;
    return true; // toCheck = true
  }, time);
}

async function colgarLlamada(force_colgar = false) {
  clearInterval(intervalCheckingRemoteAudio);
  const iconColgar = document.querySelector("#colgar");
  if (!iconColgar) return;
  const iconMuted = document.querySelector("#mutedIcon");
  const iconVideo = document.querySelector("#videoIcon");
  iconColgar.style.pointerEvents = "none";
  clearInterval(dataStats.intervalCheckNetwork);
  if (
    (rtc.client && rtc.client.connectionState != "DISCONNECTED") ||
    force_colgar === true
  ) {
    await disconnectPeer();
    audioEnabled = false;
    const suVideo = document.querySelector("#suVideo");
    suVideo.innerHTML = "";
    iconColgar.src = llamar;
    iconVideo.style.pointerEvents = "none";
    iconMuted.style.pointerEvents = "none";
  } else {
    iconColgar.classList.remove("resplandor");
    const proper = {
      id_clase: options.idClase,
      uid: options.uid,
    };
    await connectPeerToPeer(proper);
    iconColgar.src = colgar;
    iconVideo.style.pointerEvents = "auto";
    iconMuted.style.pointerEvents = "auto";
  }
  iconColgar.style.pointerEvents = "auto";

  toast(`Clase ${audioEnabled ? "conectada" : "desconectada"}`);
}


function checkNetworkInfo() {
  if (!rtc.client) return;
  let info = {};
  //this 5 is to in call stats
  const getRTCStats = rtc.client.getRTCStats();
  const audioTrackStats = rtc.client.getLocalAudioStats();
  const videoTrackStats = rtc.client.getLocalVideoStats();
  const remoteAudioTrack = rtc.client.getRemoteAudioStats();
  const remoteVideoTrack = rtc.client.getRemoteVideoStats();
  let minBitRate = 400000;
  let minFps = 15;
  if (!objEmpty(getRTCStats)) {
    info.getRTCStats = getRTCStats;
    if (
      (getRTCStats.RecvBitrate < minBitRate && getRTCStats.RecvBitrate != 0) ||
      (getRTCStats.SendBitrate < minBitRate && getRTCStats.SendBitrate != 0)
    ) {
      showToastNetwork("getRTCStats", getRTCStats);
    }
  }
  if (!objEmpty(audioTrackStats)) {
    info.audioTrackStats = audioTrackStats;
  }
  if (!objEmpty(videoTrackStats)) {
    info.videoTrackStats = videoTrackStats;
  }
  if (!objEmpty(remoteAudioTrack)) {
    info.remoteAudioTrack = remoteAudioTrack;
  }
  if (!objEmpty(remoteVideoTrack)) {
    info.remoteVideoTrack = remoteVideoTrack;
    if (
      remoteVideoTrack.decodeFrameRate < minFps ||
      remoteVideoTrack.receiveBitrate < minBitRate ||
      remoteVideoTrack.receiveFrameRate < minFps ||
      remoteVideoTrack.renderFrameRate < minFps
    ) {
      showToastNetwork("remoteVideoTrack");
    }
  }
  if (!objEmpty(dataStats.netQA)) {
    info.netQA = dataStats.netQA;
  }
 sendDataToSave(info);
}

function sendDataToSave(info) {
  info.channel_name = options.channel;
  info.type = options.type;

  // const formData = new FormData();

  // formData.append("logdata", JSON.stringify(info));
 setTimeout(
    () => booking_log_data_emit({booking_id:options.reserva,data:JSON.stringify(info),type:"default"}),
    // () => instance.post(`booking/book/${options.reserva}/log/`, formData),

    2000
  );
}

async function leaveRemoteTrack(user, mediaType) {
  await rtc.client.unsubscribe(user, mediaType);
  if (mediaType === "video") {
  } else if (mediaType === "audio") {
  }
}
// subscribe the remote peer
async function createRemoteTrack(user, mediaType) {
  // subscribe to a remote user
  rtc.remoteUser = user;
  await rtc.client.subscribe(user, mediaType);
  if (mediaType === "video") {
    const remotediv = document.getElementById("suVideo");
    remotediv.innerHTML = "";
    const playerContainer = document.createElement("div");
    playerContainer.id = user.uid.toString();
    remotediv.append(playerContainer);

    user.videoTrack.play("suVideo");
    rtc.remoteVideoTrack = user.videoTrack;
  }
  if (mediaType === "audio") {
    user.audioTrack.play();
    rtc.remoteAudioTrack = user.audioTrack;
    if (toCheckRemoteAudioWebView) {
      toCheckRemoteAudioWebView = checkingRemoteAudio();
    }
  }
}



async function handleUserPublished(user, mediaType) {
  
  createRemoteTrack(user, mediaType);
}

function handleUserUnpublished(user, mediaType) {
  leaveRemoteTrack(user, mediaType);
  //  const suVideo = document.querySelector("#suVideo")
  //  suVideo.innerHTML = '';
  //  addWaitMsg(suVideo)
}



function showToastNetwork(where = "", obj = null) {
  if (obj != null) {
    // console.log(where, obj);
  }
  const now = Date.now();
  if (now - dataStats.errorMsg < 800000) {
    // 10 min
    dataStats.errorMsg = Date.now();
    toast.error(
      "Tu ancho de banda no es el adecuado para la clase. Es posible que experimentes caí­das de señal durante la clase.",
      {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }
}

export const showOrHidePanel = (
  contentRef,
  tabRef,
  align,
  isShow,
  otropanel
) => {
  if (!isShow) {
    let otropanelf = document.querySelector(".PanelTab.right.otro");
    let otropanelcontento = document.querySelector(".PanelContent.right.otro");
    if (otropanelcontento && align != "left") {
      otropanelcontento.style.display = "flex";
      otropanelcontento.style.overflow = "hidden";
      otropanelcontento.style.right = "21.7rem";
      if (otropanel) {
        otropanelf.style.right = "22.3rem";
      }
    }
    contentRef.current.style.width = "25%";
    if (align == "left") {
      tabRef.current.style.marginLeft = "25%";
    } else {
      if (otropanel != undefined) {
        tabRef.current.style.marginRight = "21.2rem";
      } else {
        if (otropanelf) {
          otropanelf.style.marginRight = "21.8rem";
          otropanelf.style.display = "flex";
        }
        tabRef.current.style.marginRight = "25%";
        if (otropanelf) {
          otropanelf.style.display = "flex";
        }
      }
    }
    return true;
  } else {
    let otropanelf = document.querySelector(".PanelTab.right.otro");
    let otropanelcontento = document.querySelector(".PanelContent.right.otro");
    if (otropanelcontento && otropanelf && align != "left") {
      otropanelcontento.style.display = "none";
      otropanelf.style.marginRight = "0%";
      if (otropanel) {
        otropanelf.style.right = "0%";
      }
    }
    contentRef.current.style.width = "0";
    if (align == "left") {
      tabRef.current.style.marginLeft = "0";
    } else {
      if (otropanel != undefined) {
        tabRef.current.style.marginRight = "21.8rem"; //rutina cerrado
      } else {
        if (otropanelf) {
          otropanelf.style.marginRight = "21.2rem";
          otropanelf.style.display = "none";
        }
        tabRef.current.style.marginRight = "0%";
        if (otropanelf) {
          otropanelf.style.display = "none";
          otropanelf.style.marginRight = "0%";
        }
      }
    }
    return false;
  }
};

export const ContenedorClase = (props) => {
  const [hidding, setHidding] = useState(false);
  const clientestream = props.clientestream || null;
  const trainerpeer = props.trainerpeer || null;
  const suVideo = useRef(null);
  const miVideo = useRef(null);
  const { user, paneles, contentInfo } = props;

  return (
    <>
      <div className="containerClase">
        {paneles}
        <div id="suVideo" className="suVideo" ref={suVideo}></div>
        {trainerpeer ? (
          <div
            id="miVideo"
            className={`${hidding ? "" : "hidding-blurred"} miVideo`}
            ref={miVideo}
            style={{
              backgroundImage: `url(${myCamera})`,
            }}
          ></div>
        ) : null}
         {clientestream || props.trainerMusic ? null : (
          <div
            id="miVideo"
            className={`${hidding ? "" : "hidding-blurred"} miVideo`}
            ref={miVideo}
            style={{
              backgroundImage: `url(${myCamera})`,
            }}
          ></div>
        )} 

        {clientestream ? null : (
          <div
            className="miVideoexpandir"
            onClick={() =>
              hidding == false ? setHidding(true) : setHidding(false)
            }
          >
            {" "}
            <img
              src={camera_expand}
              style={{
                width: "2rem",
                height: "2rem",
                display: "inline-flex",
              }}
            ></img>
          </div>
        )}
        {contentInfo}
      </div>
      <RenderBtnClases
        hidding={hidding}
        trainerMusic={props.trainerMusic}
        clientestream={clientestream}
      />
    </>
  );
};
export const allAvatars = [
  [
    process.env.PUBLIC_URL + "/assets/img/avatars/persons/Beard-min-min.png",
    process.env.PUBLIC_URL + "/assets/img/avatars/persons/Boss-min-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/persons/Employee-min-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/persons/Girl Student-min-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/persons/Manager-min-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/persons/Secretary-min-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/persons/Teacher-min-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/persons/Teen Boy-min-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/persons/Teen Girl-min-min.png",
  ],
  [
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Chicken Pose - 01-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Bunny Pose - 01-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Cat Pose - 02-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Cow Pose - 01-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Crocodile Pose - 05-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Dog Pose - 03-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Elephant Pose - 01-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Fox Pose - 03-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/animals/Pig Pose - 01-min.png",
  ],
  [
    process.env.PUBLIC_URL + "/assets/img/avatars/faces/calm_male-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/faces/confident_female01-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/faces/confident_female02-min.png",
    process.env.PUBLIC_URL + "/assets/img/avatars/faces/distrust_mal.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/faces/distrust_males-min.png",
    process.env.PUBLIC_URL + "/assets/img/avatars/faces/doubt_girl02-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/faces/laughing_female-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/faces/neutral_face_male-min.png",
    process.env.PUBLIC_URL + "/assets/img/avatars/faces/smirking_boy-min.png",
  ],
  [
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Black_kiss256px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Black_laugh_with_tear256px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Brown_beaming_smile512px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Brown_mad512px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Brown_pleased256px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Brown_shy256px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Brown_Smile256px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Black_falling_in_love256px-min.png",
    process.env.PUBLIC_URL +
    "/assets/img/avatars/pets/Brown_smirk256px-min.png",
  ],
];

export const ModalInfoVideosFilter = React.forwardRef((props, ref) => {


  const disciplinas=props.disciplinas;
  
  const variables_entrenamiento = {
    main_topic: ["Entrenamiento", "Nutrición"],
    "workout_zone": [
      
      "Tren inferior",
      "Abdomen",
      "Tren superior",
      "Todo el cuerpo",
      
    ],
    workout_equipment:["Mancuernas","Bandas cerradas de resistencia","Mat de yoga","Colchoneta","Muñequeras de peso","Fit ball / Balón de pilates","Bandas de resistencia con mangos","Guantes de boxeo","Lazo de salto","Set de pump","Caminadora eléctrica","Bicicleta estática","Implementos del hogar","Sin equipos peso corporal"],
    discipline:["Yoga","Funcional","Fuerza","Balance","Bajo impacto","Cardio funcional","HIIT","Pilates","Nutrición","Mente"],
    skill_level: ["Bajo", "Medio", "Alto"],
    workout_type: ["Cardiovascular", "Fuerza", "Movilidad", "Flexibilidad"],
    workout_time: ["Entre 10 a 30 minutos", "Entre 30 a 60 minutos", "Más de 60 minutos"],
 

  };
 

  const title = props.title;

  const [variables, setvariables] = useState(variables_entrenamiento);
  const text = props.text || "none";

  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;

  function handleClick(event) {
    setvariables(variables_entrenamiento);
    props.handleClick();
  }
  function changeselect(target) {
    props.handleInputCategory(target);
  
      setvariables(variables_entrenamiento);
    
  }
  function handleChange(e) {
    console.log(e)
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  const path = useLocation();

  const classesMsg = props.classesMsg || null;
  const Rendershit = ({ e, handleChange, index, variables }) => {
    return (
      <Multiselect
        name={e[0]}
        placeholder="Selecciona"
        textField={e[0]}
        data={variables[`${e[0]}`]}
        onChange={(nextValue) => handleChange({ [`${e[0]}`]: nextValue })}
      />
    );
  };
  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center "
      style={styleModal}
    >
      <div className={"modalInfoContenidovideos"}>
       
        <div
          className={
            path.pathname.includes("Entrenador/clase")
              ? title == "Lesionado"
                ? classesMsg + " text-left text colornuevaletra_entre my-3"
                : classesMsg + " text"
              : title == "Lesionado"
              ? classesMsg + " text-left text colornuevaletra my-0"
              : classesMsg + " text"
          }
          style={{ display: displayText }}
        >
          {text}
        </div>
        {variables &&
          Object.entries(variables).map((e, index) => (
            <div key={index}  style={{ color: "#394F65",textAlign:"left" }}>
              {e[0] == "main_topic" ? (
                <div className="my-1">
                  <span>Tema</span>
                 
                </div>
              )
              :e[0] == "workout_zone" ? (
                <div className="my-1">
                  <span>Zona a </span>
                  <span>
                    <strong> trabajar</strong>
                  </span>
                </div>
              ) : e[0] == "workout_type" ? (
                <div className="my-1">
                  <span>Tipo</span>
                  <span>
                    <strong> de entrenamiento</strong>
                  </span>
                </div>
              ) : e[0] == "discipline" ? (
                <div className="my-1">
                  <span>Disciplina</span>
                </div>
              ) : e[0] == "workout_equipment" ? (
                <div className="my-1">
                  <span>Equipo para</span>
                  <span>
                    <strong> entrenar</strong>
                  </span>
                </div>
              ) :  e[0] == "skill_level" ? (
                <div className="my-1">
                  <span>Nivel de</span>
                  <span>
                    <strong> difícultad</strong>
                  </span>
                </div>
              ) :(
                <div className="my-1"> Tiempo</div>
              )}
              {variables ? (
                <Rendershit
                  e={e}
                  index={index}
                  handleChange={handleChange}
                  variables={variables}
                />
              ) : null}
            </div>
          ))}
        <div className=" botonesModalInfo my-2 col-12">
          {laptop575.matches ? <div className="col-4"></div> : null}
          {props.handleClick ? (
            <ButtonMob
              text="Remover filtros"
              colorButton={"transparent"
              }
              borderbutton={`1px solid var(--elevva)`}
              colorTextButton={`var(--elevva)`}
              className={
                laptop575.matches
                  ? "btnAction butonvideobannerfilter px-2  py-2 col-3 mx-1"
                  : "btnAction butonvideobannerfilter px-2  py-2 col-7 mx-1"
              }
              handleClick={() => {
                handleClick();

                let boxes =
                  document.getElementsByClassName("rw-multiselect-tag");
                let place = document.getElementsByClassName(
                  "rw-multiselect-input"
                );

                for (let i = 0; i < place.length; i++) {
                  place[i].placeholder = "Selecciona";
                  place[i].size = "11";
                  setvariables(variables_entrenamiento)
                }
                while (boxes.length > 0) {
                  for (let i = 0; i < boxes.length; i++) {
                    boxes[i].remove();
                    // loscreados.appendChild(paragraph);
                  }
                // setvariables(variables_entrenamiento)
                }
              }}
            />
          ) : null}

          {handleCancel ? (
            <ButtonMob
              text="Cerrar"
              className="btnActionCancelar butonvideobannerfilter py-2 col-3"
              handleClick={() => handleCancel()}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});
// Axios rutas
export const checkPathNavFoot = (path, type) => {
  return (
    path == `/` ||
    path.includes(type) ||  path.includes(`Onboarding`) || path.includes(`/TutorialReserva/`) ||
    path.includes(`Entrenador`)||  path.includes(`recuperar`)||
    path.includes(`Completar`) ||  path.includes(`Share`)||  path.includes(`Corporativo`) 
  );
};
export const checkPathNavFootMobile = (path, type) => {
  return (
    path == `/` || path.includes(`recuperar`) ||
    path.includes(type) ||   path.includes(`/trainerInfo/`) ||   path.includes(`/actividadApp`)  ||  path.includes(`/selectGrupalList/`) ||  path.includes(`/TutorialResponsive/`) ||  path.includes(`/selectSchedule/`)  ||  path.includes(`Onboarding`) ||   path.includes(`/training/`) ||  path.includes(`/selectTeacher/`)  || path.includes(`Entrenador`)|| path.includes(`Completar`) ||  path.includes(`/ScheduleMobile/`) ||  path.includes(`Share`) );
};

export const notInPublic = (path) => {
  return (
    path == `/` ||
    path == "/tipos_entrenamientos" ||
    path == "/lineamientos" ||
    path == "/beneficios" ||
    path == "/servicios" ||  
    path == "/como_funciona"
  );
};
export  const TitleRow = (props) => {
  const infoTraining = props.infoTraining;
  const handleclick=props.handleclick;
  return(
    <div 
            
            className="w-70 w-md-100 ubication_arrow_title"
          >
            <div className="d-flex my-auto ">
              <div
                onClick={handleclick}
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "1vmin",

                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "1rem" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div className=" my-auto mx-2 d-flex flex-column gap-2 justify-content-center ">
                <span
                  className="mt-2  text_arrow_top"
               
                >
                  Entrenamiento
                </span>
                <span
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    lineHeight: "100%",
                    fontSize: "calc(1.3rem + 0.3vw)",
                  }}
                >
                  Clase de <br />
                  {infoTraining &&infoTraining.name}
                </span>{" "}
                {infoTraining?.credits == 0 ? (
                  <button
                    style={{
                      background: "var(--elevva)",
                      border: "1px solid var(--elevva)",
                      borderRadius: "20px",
                      color: "var(--white)",
                      fontWeight: "300",
                      maxWidth: "60%",
                      padding: "1% 0%",
                    }}
                  >
                    GRATIS
                  </button>
                ) : null}
              </div>
            </div>
          </div>
  )
}

export const ComponenteKnow =(props) =>{
const infoTrainer= props.infoTrainer;
const clicKnow=props.clicKnow;


return(
  <div
  className="col-12 col-md-7 d-flex contenedorInfo_trainer"

>
  <div className="container text-center" style={{height:'20%'}}>
    
      <div className="row">
        <div className="col text-start">
          <span
            className="my-1 text-color-subtitle"
            style={{ fontSize: "3.0vmin" }}
          >
            Clases
          </span>

          <br />
          <span
            className="fw-bold"
            style={{
              color: "var(--elevvaTextTitle)",
              fontSize: "calc(1.3rem + 0.5vmin)",
            }}
          >
       {infoTrainer &&infoTrainer.bookings_count}
          </span>
        </div>

        <div className="col ">
          <span
            className="my-1 text-color-subtitle"
            style={{ fontSize: "3.0vmin" }}
          >
            Edad
          </span>

          <br />
          <span
            className="fw-bold"
            style={{
              color: "var(--elevvaTextTitle)",
              fontSize: "calc(1.3rem + 0.5vmin)",
            }}
          >
            {infoTrainer &&infoTrainer.age}
          </span>
        </div>

        <div className="col">
          <span
            className="my-2 text-color-subtitle"
            style={{ fontSize: "3.0vmin" }}
          >
            Calificación
          </span>

          <br />
        { infoTrainer? <span
            className="fw-bold d-flex justify-content-center"
            style={{
              color: "var(--elevvaTextTitle)",
              fontSize: "calc(1.3rem + 0.5vmin)",
            }}
          >
                 {renderCorazones(infoTrainer?.current_rating)}
          </span>:null}
        </div>
      

      </div>
   

 
  </div>
  <PerfectScrollbar className="container d-flex" style={{overflowX:"hidden"}}>
  <div  className=" d-flex flex-column justify-content-between text-color-subtitle gap-2 w-100">
  <span
          className=" text-left my-1"
          style={{ fontSize: "3.0vmin" ,textAlign:'left',color: "var(--elevvaTextTitle)"}}
        >
          Perfil
        </span>

        <span
          className="text-color-subtitle "
          style={{textAlign:'left',lineHeight:"110%"}}
        >
{infoTrainer?.description}                 
        </span>
        <span
      className=" "
      style={{ fontSize: "3.0vmin",color: "var(--elevvaTextTitle)"  ,textAlign:'left'}}
    >
      Disciplinas / Especialidades
    </span>
    <div className="px-2">
    <Allvideos 
    groupTraining={false}
  spaceBetween={0}
  ancho={"auto"}
  grow={"growingTutorial"}
  alto={"calc(15rem + 0.7vw)"}
  widthSwiper={"100%"}
  id="numbers"
  arrowDisplay={laptop575.matches?"d-flex":"d-none"}
  sliderWidth={'90%'}
  classMarginCard={'my-0'}
  cardWidth={'10rem'}
  idJoyride={'sliderConoceme'}
  classSwiper={'py-0'}
  // buttonClick={(e) => handleClick(e)}
  textTitleOne={"Reserva tu primer "}
  textTitleTwo={"clase grupal en vivo gratis"}
  //trainingsId={trainingsId}
  videos={
    infoTrainer&& infoTrainer.related_trainings
  }
  className="d-flex justify-content-center flex-column"
/></div>

  </div>
        
     </PerfectScrollbar>
 
<ButtonMob  text={'Reservar'}  handleClick={clicKnow}></ButtonMob>
</div>
)
}


export const LoginUtil = (props) => {
  const navigate = useNavigate();
  const elevvaT = props.elevvaT;
  const logoElevva = props.logoElevva;
  const NameCustomer = elevvaT("cover_start_login_customer");
  const NameTrainer = elevvaT("cover_start_login_trainer");

  return (
    <div className="m-auto text-center ">
      <img src={logoElevva} className="logoElevva_size" />
      <div>
        <label className="labelLogin mt-2">
          {elevvaT("cover_start_login")}
        </label>
        <ButtonMob
          handleClick={() => navigate("/" + NameCustomer)}
          text={NameCustomer}
        ></ButtonMob>
        <ButtonMob
          colorButton={`var(--elevvabutonLogin)`}
          handleClick={() => navigate("/" + NameTrainer)}
          colorTextButton={`var(--elevvaTextLogin)`}
          text={NameTrainer}
        ></ButtonMob>

        <p className="labelLoginLaws">{elevvaT("law_reserved_elevva")}</p>
      </div>
    </div>
  );
};

export const ModalInfo1 = React.forwardRef((props, ref) => {
  const title = props.title;
  const text = props.text || "none";
  const textDelete = props.textDelete;
  const icon = props.icon || "bi bi-check-circle-fill";
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;
  function handleClick(event) {
    props.handleClick();
  }
  function handleClose(event) {
    props.handleClose();
  }
  function handleDelete(event) {
    props.handleDelete(event);
  }
  function handleInput(e) {
    props.handleInput(e);
   
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };

  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  const classesMsg = props.classesMsg || null;
  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div className="modalInfoContenido">
        <div className="icon my-3" style={{ display: displayError }}>
          {error == "false" ? (
            <i className={icon}></i>
          ) : (
            <i className={icon}></i>
          )}
        </div>

        <div
          onClick={handleClose}
          style={{
            position: "absolute",
            right: "6%",
            top: "2vmin",
            color: "#969696",
            fontWeight: "700",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className="text-color-title"
          style={{
            textAlign: "left",
            fontSize: "1.5rem",
            maxWidth: "20vmin",
            lineHeight: "1.1",
            fontWeight: "bold",
          }}
        >
          {title}
        </div>

        <div className={classesMsg + " text"} style={{ display: displayText }}>
          {text}
        </div>
        <textarea
          placeholder="Describe aquí tu lesión."
          className="placeholder_onboarding"
          style={{
            display: displayInput,
          }}
          autoFocus
          rows="5"
          onInput={handleInput}
        ></textarea>
        <div className={textDelete ? "d-row" : "botonesModalInfo"}>
          <ButtonMob
            colorButton={`var(--elevvabutonLogin_User)`}
            text="Guardar lesión"
            className="btnAction1"
            handleClick={handleClick}
          />
          {textDelete && window.location.pathname.includes(`/perfil`) || textDelete && window.location.pathname.includes(`/MisLesiones`) ? (
            <ButtonMob
              text="Borrar lesión"
              className="btnAction1 btn-danger"
              handleClick={handleDelete}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});

export const LoginComponentUser = (props) => {
  const elevvaT = props.elevvaT;
  const navigate = useNavigate();

  const type = props.type;
  const [displayError, setDisplayError] = useState("none");

  const [recover, setRecover] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(null);
  const [autenticado, setautenticado] = useState("norefrescado");
  const [modalInfo, setModalInfo] = useState(null);

  const handleSubmit = (event) => {
    const usuario = event.target[0].value.trim();
    const password = event.target[1].value.trim();
    const servicio = "users/auth/login/";

    setDisplayLoading(
      <div className="spinner-container-login">
        <HashLoader color="#8d97fd" />
      </div>
    );

    const formData = new FormData();
    formData.append("email", usuario);
    formData.append("password", password);
   

    axios({
      method: "post",
      contentType: "application/json",
      url: `${NuevaAxiosURL}${servicio}`,
      params: {
        t: new Date().getTime(),
      },
      data: formData,
    })
      .then(async(res) => {
        const data = res.data;
        if (data) {
          if (autenticado == "norefrescado") {
            localStorage.my_code = data.access;
            localStorage.refresh = data.refresh;
            localStorage.type = type;
           

            //localStorage.setItem('my_code', data.access);
            //localStorage.setItem('refresh', data.refresh);
            //localStorage.setItem('type', type);

            const instanceLogin = axios.create({
              baseURL: NuevaAxiosURL,
              params: {
                t: new Date().getTime(),
              },
              headers: { Authorization: "Bearer " + data.access },
            });

          await instanceLogin.get("users/").then((response) => {
              let data_user = response.data;
              localStorage.first_name = data_user.first_name;
              localStorage.last_name = data_user.last_name;
              localStorage.email = data_user.email;
              localStorage.id = data_user.id;
              localStorage.mobile_numberMob = data_user.customer.document_id;
              localStorage.profile_pic = data_user.customer.profile_pic;
              if(data_user.membership){
                 localStorage.membership = data_user.membership.membership.id
                 localStorage.membershipEndDate = data_user.membership.end_date;
                 localStorage.membershipStartDate =
                 data_user.membership.start_date;
                }
                if (
                  data_user.membership &&
                  data_user.membership.membership &&
                  data_user.membership.membership.company.mob_link
                ) {
                  localStorage.membershipCompanyElevva =
                    data_user.membership.membership.company.mob_link;
                }
              if (data_user.type == 20 && type == "Entrenador") {
                if (data_user.trainer.is_enabled) {
                  alert('page not created')
                  // document.location.href = `${basename}/${type}/inicio`;
                } else {
                  setModalInfo(
                    <ModalInfo
                      error="none"
                      handleClick={() =>
                        localStorage.clear() ||
                        (document.location.href = `${basename}/`)
                      }
                      title="Hola entrenador"
                      text={
                        "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                      }
                      classesMsg=""
                    />
                  );
                }
              } else if (data_user.type == 10 ) {
                document.location.href = `${basename}/Dashboard`;
              } else {
                localStorage.clear();

                setModalInfo(
                  <ModalInfo
                    error="none"
                    handleClick={() =>
                      (document.location.href = `${basename}/`)
                    }
                    title="Error de ingreso"
                    text={"Tu tipo de ingreso es incorrecto"}
                    classesMsg=""
                  />
                );
              }
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
        
          localStorage.clear();
          setDisplayError("inherit");
          setTimeout(() => {
            setDisplayError("none");
          }, 2000);
          setDisplayLoading(null);
        }
      });

    event.preventDefault();
  };

  const FormLogin = () => {
    const [actualIconEye, setActualIcon] = useState("bi bi-eye-fill");
    const [showOrNo, setShow] = useState("password");
    const handleShowPass = () => {
      if (actualIconEye == "bi bi-eye-fill") {
        setActualIcon("bi bi-eye-slash-fill");
        setShow("text");
      } else {
        setActualIcon("bi bi-eye-fill");
        setShow("password");
      }
    };
    return (
      <div className="total-width-login">
        <h1 className=" m-0">{elevvaT("welcome")}</h1>
        <p className="text-color-subtitle">{elevvaT("label_welcome_down")}</p>
        <form
          className="LoginForm Registro"
          style={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            
          }}
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            type="email"
            placeholder={elevvaT("email_text")}
            name="mail"
            id={props.idMail}
            className="form-control input-style-elevva mx-auto"
            required
          ></input>
          <div
            id="passmail_error"
            className="errorRequest"
            style={{
              display: displayError,
              color: "#CB3D82",
              fontWeight: "400",
            }}
          >
            Correo y/o contraseña incorrectas.{" "}
          </div>

          <div className="inputs-inside position-relative">
            <input
            id={props.idPassword}
              type={showOrNo}
              placeholder={elevvaT("password_text")}
              name="pass"
              autoComplete="on"
              className="form-control input-style-elevva mx-auto"
              required
            ></input>
            <div className="eye-color-location" onClick={handleShowPass}>
              <i className={actualIconEye}></i>
            </div>
          </div>
          <div
            id="passmail_error"
            className="errorRequest"
            style={{
              display: displayError,
              color: "#CB3D82",
              fontWeight: "400",
            }}
          >
            Correo y/o contraseña incorrectas.{" "}
          </div>
          {displayLoading}

          <div
            onClick={() => setRecover(true)}
            className="d-flex  justify-content-center "
          >
            <u className="text-color-elevva"> {elevvaT("forgot_password")}</u>
          </div>

          <div className="d-flex justify-content-center ">
            <ButtonMob
              className="m-0 buttonLoginStart"
              colorButton={`var(--elevvabutonLogin_User)`}
              text={elevvaT("log_in")}
            />
          </div>

          <div className=" d-flex justify-content-center text-color-subtitle">
            {elevvaT("you_new")}&nbsp;
            <Link to={`/createUser/${type}`}>
              <u className="text-color-elevva">{elevvaT("new_account_text")}</u>
            </Link>
          </div>
          <div id="text-w-line" className="text-color-subtitle">
            <span style={{ zIndex: "2" }}>Ó</span>
          </div>

          <ButtonMob  handleClick={() => navigate("/seleccionaConvenio/Usuario")}
            className="spacebuttonLogin my-0"
            colorButton={`var(--elevvabutonLogin_User)`}
            text={"Iniciar sesión con convenio"}
            
          />

          <div className=" d-flex justify-content-center text-color-subtitle text-center">
            ¿Tienes una cuenta de elevva con algún convenio?
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="d-md-none mobile-login w-100 ">
        <div style={{ height: "calc(100vh - 65vh)" }}>
          <div
            className="containerBGClase"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/assets/img/welcome-login-old.jpg"
              })`,
              backgroundSize: "cover",
            }}
          >
            <div className="button-back-start">
              <ButtonArrow
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
        <div
          className="mobile-welcome-login-row  text-left w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
           
            alignItems: "center",
           
          }}
        >
          <FormLogin idMail={'mailResponsive'}  idPassword={'passResponsive'}/>
        </div>
      </div>
      <div className=" d-none d-md-flex col-12 ">
        <div
          className="backgrounImgLoginStart"
          style={{
            width: "53%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/img/welcome-login-old.jpg"
            })`,
          }}
        ></div>
        <div
          className=""
          style={{
            width: "50%",
            margin: "auto",
            zIndex: "2",
            height: "100%",
            position: "absolute",
            left: "50%",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div className="box-Welcome-login text-left col-12 d-flex">
            <div className="w-100 mx-auto d-flex">
              <div className="total-width-arrow">
                <ButtonArrow
                  colorBack={`var(--elevvaArrowColor)`}
                  handleClick={() => navigate(-1)}
                />
              </div>
              <FormLogin idMail={'mailWeb'}  idPassword={'passWeb'} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export function perlo() {
  if (sessionStorage.onesignal_id) {
    let formData = new FormData();
    formData.append("refresh_token", localStorage.refresh);
    formData.append("onesignal_id", sessionStorage.onesignal_id);
    instance
      .post(`users/auth/logout/`, formData)
      .then((res) => {
        if (res.status == 205 || res.status == 200) {
          localStorage.clear();
          window.location.href = `/`;
        }
      })
      .catch((err) => {
        localStorage.clear();
        window.location.href = `/`;
      });
  } else {
    localStorage.clear();
    window.location.href = `/`;
  }
}
export const ButtonArrow = (props) => {
  const colorBack = props.colorBack;
  const buttonColor = props.buttonColor || `var(--elevvaArrowColor)`;
  const isDisabled = props.disabled || null;
  const icon = props.icon;
  const classes = `btnArrow ${props.classes}`;
  function handleClick() {
    props.handleClick();
  }
  return (
    <button
      disabled={isDisabled ? true : false}
      className={classes}
      style={{
        backgroundColor: "transparent",
        border: `1px solid ${colorBack}`,
        display: "flex",
      }}
      onClick={handleClick}
    >
      <i
        className={icon ? icon : "bi bi-chevron-left"}
        style={{
          color: colorBack,

          fontSize: "1.5rem",
          display: "inline-flex",
        }}
      ></i>
    </button>
  );
};
export const ButtonMob = (props) => {
  const text = props.text;
  const colorButton = props.colorButton || `var(--elevva)`;
  const borderbutton = props.borderbutton || ``;
  const colorTextButton = props.colorTextButton || `var(--white)`;
  const icon = props.icon || null;
  const sizeFont = props.sizeFont || "1rem";
  const isDisabled = props.disabled || null;
  const classes = `btnMOB ${props.className ? props.className : ""}`;
  const idRef=props.id

  function handleClick() {
    props.handleClick();
  }
  function handleClickCamera() {
    props.handleClickCamera();
  }

  return ( 
    <button id={idRef}
      disabled={isDisabled ? true : false}
      className={classes}
      style={{
        background: colorButton,
        color: colorTextButton,
        border: borderbutton,
        fontSize: sizeFont,
        cursor: "pointer",
      }}
      onClick={
        props.handleClick
          ? handleClick
          : props.handleClickCamera
          ? handleClickCamera
          : null
      }
    >
      {/* {image1 && laptop575.matches ? (
          <img src={image1} className={classesImg} alt="boton img" />
        ) : null}
        {image5 && laptop575.matches ? (
          <i className="fa fa-arrow-left corridoreservatuclase1"></i>
        ) : null} */}
      {icon && icon.includes("left") ? (
        <i className={`${icon} arrow-white-on-button`}></i>
      ) : null}
      {ReactHtmlParser(text)}
      {/* {image && !laptop575.matches ? (
          <img src={image} className={classesImg} alt="boton img" />
        ) : null}
  
        {image1 && laptop575.matches ? (
          <i className="fa fa-arrow-right corridoreservatuclase"></i>
        ) : null}
        {imageweb && laptop575.matches ? (
          <img src={imageweb} className={classesImg} alt="boton img" />
        ) : null} */}
      {icon && icon.includes("right") ? (
        <i className={`${icon} arrow-white-on-button`}></i>
      ) : null}
    </button>
  );
};
export const Loadingspinner = (props) => {
  const { t } = useTranslation();
  const size = props.size;
  const style = props.customStyle; // grow or border
  const classloading = props.classloading || null;
  const widthContain=props.widthContain || "auto"
  return (
  
      <div style={{width:widthContain,height:widthContain}}
        className={
         "d-flex justify-content-center align-items-center text-center m-auto"
        }
      >
        <div
          className={"imagenload "}
          role="status"
          style={{ width: size, height: size }}
        >
          <span className="visually-hidden">{t("loading_spinner")}</span>
        </div>
      </div>
   
  );
};

export const CustomModal = (props) => {
  const { show, handleClose, title, text, classesMsg, items } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="d-flex justify-content-center">
        <i
          className="bi bi-x-circle-fill align-self-center"
          style={{ color: "#9EACED", fontSize: "3.5rem" }}
        ></i>
      </div>
      <Modal.Body>
        <div className="text-center">
          <Modal.Title>{title}</Modal.Title>
          <br />
        </div>
        <div className="d-flex justify-content-center">
          <h4 className={classesMsg}>{items}</h4>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <ButtonMob
            colorButton={`var(--elevva)`}
            className="mx-2 sizebuttonLarge"
            text={"Entendido"}
            handleClick={handleClose}
          />
          {/* <Button  onClick={handleClose}>
          Cerrar
        </Button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const CustomModalError = (props) => {
  const { show, handleClose, title, text, classesMsg, items } = props;
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="d-flex justify-content-center">
        <i
          className="bi bi-x-circle-fill align-self-center"
          style={{ color: "#9EACED", fontSize: "3.5rem" }}
        ></i>
      </div>
      <Modal.Body>
        <div className="text-center">
          {/* <Modal.Title>{title}</Modal.Title> */}
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            {" "}
            <strong>Algo salio mal</strong>
          </span>
          <br />
        </div>
        <div className="modal-body">
          <p
            className="text-color-subtitle my-3 text-center"
            style={{
              lineHeight: "100%",
              // maxWidth: "35vmin",
            }}
          >
            Algo nos acaba de suceder, por favor intentalo nuevamente en unos
            minutos
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <ButtonMob
            colorButton={`var(--elevva)`}
            className="mx-2 sizebuttonLarge"
            text={"Entendido"}
            //handleClick={handleClose}
            handleClick={refreshPage}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const CustomModalOk = (props) => {
  const {
    show,
    handleClose,
    title,
    text,
    classesMsg,
    classesMsgs,
    sendDataFinal,
  } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="d-flex justify-content-center">
        <i
          className="bi bi-check-circle-fill align-self-center"
          style={{ color: "#9EACED", fontSize: "3.5rem" }}
        ></i>
      </div>
      <Modal.Body>
        <div className="text-center">
          <Modal.Title>{title}</Modal.Title>
          <br />
          <span
            className={classesMsg}
            style={{
              color: "rgb(169, 182, 196)",
              fontWeight: "bold",
              fontsize: "1.5vmin",
            }}
          >
            {classesMsg}
          </span>
          <br />
          <span
            className={classesMsg}
            style={{
              color: "rgb(169, 182, 196)",
              fontWeight: "bold",
              fontsize: "1.5vmin",
            }}
          >
            {classesMsgs}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <ButtonMob
            colorButton={`var(--elevva)`}
            className="mx-2 sizebuttonLarge"
            text={"Confirmar"}
            //handleClick={handleClose}
            // handleClick={() => {
            //     window.location.href = "/Usuario/Inicio"
            //   }}
            handleClick={sendDataFinal}
          />
          {/* <Button  onClick={handleClose}>
          Cerrar
        </Button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const ModalInfoProcess = React.forwardRef((props, ref) => {
 
  return (
    <div
   
      className="modalInfo justify-content-center align-items-center d-flex"
     
    >
      <div
        className={
          "modalInfoContenido d-flex justify-content-center flex-column"
        }
      >
       <Loadingspinner customStyle="border" size="7vmin" widthContain={"7rem"}/>
       
      </div>
    </div>
  );
});
export const ModalInfo = React.forwardRef((props, ref) => {
  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const textButtonAction = props.textButtonAction || "none";
  const backgroundReservarBorder = props.backgroundReservarBorder || "btnActionCancelar py-2";
  const loader=props.loader || false
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;
  const iconoWidth = props.iconoWidth || "12vmin";
  const textSizeUp = props.textSizeUp || "1rem";
  const textButtonConfirmar = props.textButtonConfirmar || "Confirmar"
  const sizeTitle = props.sizeTitle || null;
  
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;

  function handleReservar(event) {
    props.handleReservar();
  }
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  const path = useLocation();

  const classesMsg = props.classesMsg || null;
  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className={
          path.pathname.includes("Entrenador/clase")
            ? "modalInfoContenido_clase"
            : "modalInfoContenido"
        }
      >
        {error == "none" || error == "true" ? (
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        ) : (
          <div
            
            className=""
            style={{
              position: "absolute",
              top: "6%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        )}
        {icono ? (
          <img src={icono} style={{ width: iconoWidth, margin: "0.5rem 0 " }} />
        ) : null}
        {title == "Lesionado" ? null : (
          <div className="icon  p-2" style={{ display: displayError }}>
            {error == "false" ? (
              <i
                className="bi bi-x-circle-fill "
                style={{ color: "#9EACED",fontSize:'calc(3.0rem + 0.5vmin)' }}
              ></i>
            ) : (
              <i
                className="bi bi-check-circle-fill "
                style={{ color: "#CB3D82",fontSize:'calc(3.0rem + 0.5vmin)' }}
              ></i>
            )}
          </div>
        )}
        {classcorrecta ? (
          <div className={classcorrecta}>{ReactHtmlParser(title)} </div>
        ) : (
          <div style={sizeTitle?{fontSize:sizeTitle,fontWeight:'600'}:null}
            className={
              title == "Lesionado"
                ? "title text-left"
                : title == "¿Está seguro de generar esta cuenta de cobro?"
                ? "title_pago"
                : "title"
            }
          >
           {ReactHtmlParser(title)} 
          </div>
        )}

        <div
          className={
            path.pathname.includes("Entrenador/clase")
              ? title == "Lesionado"
                ? classesMsg + " text-left text colornuevaletra_entre my-3"
                : classesMsg + " text"
              : title == "Lesionado"
              ? classesMsg + " text-left text colornuevaletra my-0"
              : classesMsg + " text"
          }
          style={textSizeUp?{fontSize:textSizeUp,lineHeight:"110%"}:{ display: displayText }}
        >
          {ReactHtmlParser(text)}
        </div>
        <div
          className={title == "Lesionado" ? "scrolldolor" : "scrolldolornull"}
        >
          {title == "Lesionado"
            ? array.map((lesion) => {
                return (
                  <div className="d-row ">
                    <div className="d-flex">
                      <div
                        className={
                          path.pathname.includes("Entrenador/clase")
                            ? "circulorojolesion_clase "
                            : "circulorojolesion"
                        }
                      ></div>
                      <div className="text-left title ">
                        {lesion.part == 8
                          ? "Lesión Abdomen"
                          : lesion.part == 9
                          ? "Lesión Ingle Cadera"
                          : lesion.part == 1
                          ? "Lesión Cuello"
                          : lesion.part == 3
                          ? "Lesión hombro derecho"
                          : lesion.part == 5
                          ? "Lesión codo derecho"
                          : lesion.part == 7
                          ? "Lesión Mano derecha"
                          : lesion.part == 2
                          ? "Lesión hombro izquierdo"
                          : lesion.part == 4
                          ? "Lesión codo izquierdo"
                          : lesion.part == 6
                          ? "Lesión Mano izquierda"
                          : lesion.part == 10
                          ? "Lesión rodilla izquierda"
                          : lesion.part == 11
                          ? "Lesión rodilla derecha"
                          : lesion.part == 12
                          ? "Lesión pie izquierdo"
                          : lesion.part == 13
                          ? "Lesión pie derecho"
                          : lesion.part == 14
                          ? "Lesión columna alta"
                          : lesion.part == 17
                          ? "Lesión lumbares"
                          : lesion.part == 15
                          ? "Lesión gluteós"
                          : lesion.part == 16
                          ? "Lesión columna baja"
                          : null}
                      </div>
                    </div>
                    <div
                      className={
                        path.pathname.includes("Entrenador/clase")
                          ? "text text-left colornuevaletra_entre my-2"
                          : "text  colornuevaletra my-0"
                      }
                    >
                      {lesion.summary}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <textarea
          style={{ display: displayInput, width: "100%", marginTop: "5%" }}
          autoFocus
          rows="5"
          onInput={handleInput}
        ></textarea>
        <div className="botonesModalInfo my-1">
          {props.handleClick ? (
            <ButtonMob
              text={textButtonConfirmar}
              // className="btnAction  py-2"
              className={
                handleCancel  ? "btnAction-only  py-2" : "btnAction  py-2"
              }
              handleClick={handleClick}
            />
          ) : null}

          {handleCancel ? (
            <ButtonMob
              text="Cancelar"
              className="btnActionCancelar py-2"
              handleClick={() => handleCancel()}
            />
          ) : null}
          { props.handleReservar ? (
            <ButtonMob
              text={textButtonAction}
              className={backgroundReservarBorder}
              handleClick={() => handleReservar()}
            />
          ) : null}
        </div>
        {loader?<div className="d-flex justify-content-center mt-4"><BarLoader color="#8d97fd" width={100} /></div>:null}
      </div>
    </div>
  );
});
export const ModalInfoMembresias = React.forwardRef((props, ref) => {
  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
  const navigate = useNavigate();

 


  // memberships/
  const [isLoading, setIsLoading] = useState(true);
  const [dataMemberships, setDataMemberships] = useState([]);
  useEffect(() => {
    async function memberships() {
      setIsLoading(true);
      await instance
        .get(`${NuevaAxiosURL}memberships/?ordering=fee&is_active=true`)
        .then((response) => {
          const data = response.data;
          //console.log(data, "data acces ---------------");
          setDataMemberships(data);
          for (const i of data) {
            dataMemberships[i.id] = {
              name: i.name,
              description: i.description.split("\r\n"), // convertimos la descripción en un array de strings
              price: i.fee,
              modoPago: i.payment_currency,
              pagoFrecuente: i.payment_frequency_time,
              tiempoPago: i.payment_frequency_time,
              active: i.is_active,
              corporate: i.is_corporate,
            };
            // console.log(info, "soy infoo");
          }
        })
        .catch((error) => {
          console.log(error, "Memberships Error");
        });
      setIsLoading(false);
    }
    setTimeout(() => memberships(), 2000); // Agregar tiempo de espera de 2 segundos
  }, []);

  //console.log(setDataMemberships);

  // Delimitador limitedMembershipsName
  const limitedMembershipsName = dataMemberships.slice(0, 4);
  // console.log(limitedMembershipsName)

  // Cadena de texto array
  const descriptions = limitedMembershipsName.map((membership) => {
    return membership.description.split("\r\n");
  });
  const objeto = {
    containPlan: descriptions,
  };

  //console.log(objeto);
  //const membershipArray = description.split(", ");
  //console.log(membershipArray)

  //console.log(description);
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
    padding: "2%",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  // Slider
  SwiperCore.use([Virtual, Navigation, Pagination]);

  const [slides, setSlides] = useState(
    Array.from({ length: 10 }).map((index) => ``)
  );

  // Membresias
  const [selectedMembership, setSelectedMembership] = useState(null);

  //console.log(selectedMembership, "soy selected membership");
  return (
    <></>
    //   <div
    //     ref={ref}
    //     className="modalInfo justify-content-center align-items-center"
    //     style={styleModal}
    //   >
    //     <div
    //       style={{ padding: "4vmin 2%", width: "100%", height: "100%" }}
    //       className={"modalInfoContenido"}
    //     >
    //       <div
    //         className=""
    //         style={{
    //           position: "absolute",
    //           top: "5%",
    //           right: "5%",
    //           fontWeight: "bold",
    //           fontSize: "2.5vmin",
    //           color: " #9DACBD",
    //           cursor: "pointer",
    //         }}
    //         onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
    //       >
    //         <i class="bi bi-x-circle"></i>
    //       </div>
    //       <div
    //         style={{
    //           position: "absolute",
    //           bottom: "0%",
    //           right: "7%",
    //         }}
    //       >
    //         <img
    //           style={{ width: "30vmin" }}
    //           src={process.env.PUBLIC_URL + "/assets/img/logooutback.png"}
    //         />
    //       </div>

    //       <div
    //         style={{
    //           color: "var(--elevvaTextTitle)",
    //           justifyContent: "center",
    //           padding: "0vmin 8vmin",
    //         }}
    //         className={"title  d-flex my-auto"}
    //       >
    //         <div
    //           className="d-flex"
    //           style={{
    //             flexDirection: "column",
    //             justifyContent: "center",
    //           }}
    //         >
    //           <div className=" d-flex tabulete-active mx-auto">
    //             <div
    //               className="centraNumberSteps fw-bold"
    //               style={{ fontSize: "1.5vmin", width: "10px", color: "#ffff" }}
    //             >
    //               1
    //             </div>
    //           </div>
    //         </div>

    //         <div
    //           className="linememberstep mx-1"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "11vmin",
    //           }}
    //         >
    //           <hr />
    //         </div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex tabulete mx-auto">
    //             <div
    //               className="centraNumberSteps  fw-bold"
    //               style={{ fontSize: "1.5vmin" }}
    //             >
    //               2
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           className="linememberstep mx-1"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "11vmin",
    //           }}
    //         >
    //           <hr />
    //         </div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex tabulete mx-auto">
    //             <div
    //               className="centraNumberSteps  fw-bold"
    //               style={{ fontSize: "1.5vmin" }}
    //             >
    //               3
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           color: "var(--elevvaTextTitle)",
    //           justifyContent: "center",
    //           padding: "0vmin 8vmin",
    //         }}
    //         className={"title  d-flex my-auto"}
    //       >
    //         <div
    //           className="d-flex"
    //           style={{
    //             flexDirection: "column",
    //             justifyContent: "center",
    //           }}
    //         >
    //           <div className=" d-flex  mx-auto">
    //             <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
    //               Confirma tu pago
    //             </div>
    //           </div>
    //         </div>

    //         <div
    //           className="linememberstep"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "4.5vmin",
    //           }}
    //         ></div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex  mx-auto">
    //             <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
    //               Selecciona
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           className="linememberstep"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "6.0vmin",
    //           }}
    //         ></div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex  mx-auto">
    //             <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
    //               Disfruta tu plan
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div
    //         style={{
    //           fontSize: "3vmin",
    //           color: "var(--elevvaTextTitle)",
    //           justifyContent: "center",
    //           padding: "0vmin 8vmin",
    //           marginTop: "2vmin",
    //         }}
    //         className={"title  mb-0 d-flex"}
    //       >
    //         No pares de entrenar /{" "}
    //         <strong className="me-1">Selecciona tu plan</strong>
    //       </div>
    //       <div
    //         style={{
    //           marginBottom: "3vmin",
    //         }}
    //       >
    //         {" "}
    //         <span
    //           style={{
    //             fontSize: "2vmin",
    //             color: "var(--elevvaTextTitle)",
    //             fontWeight: "600",
    //             padding: "0vmin 8vmin",
    //           }}
    //         >
    //           Aprovecha nuestros planes de pago y obtén hasta un 40% de descuento

    //         </span>
    //       </div>
    //       {/* <Swiper
    //       slidesPerView={3}
    //       centeredSlides={true}
    //       spaceBetween={30}
    //       navigation={true}
    //       virtual> */}

    //       {/* <SwiperSlide> */}

    //       <div
    //         className="d-flex  mx-auto  "
    //         style={{
    //           padding: "1vmin",
    //           justifyContent: "center",
    //           margin: "1vmin 0 ",
    //           padding: "0% 1%",
    //         }}
    //       >
    //         {isLoading ? (
    //           <div className="spinner-container">
    //             <HashLoader color="#f3f4fe" size={90} />
    //           </div>
    //         ) : (
    //           <>
    //             <div
    //               className={

    //                 "direcccionately col d-flex resplandor"
    //               }
    //               style={

    //                 {
    //                   padding: "0 1%",
    //                   flexDirection: "column",
    //                   justifyContent: "center",
    //                   borderLeft: "1px solid #C9C9C9",
    //                   borderRadius: "20px",
    //                 }
    //               }
    //             >
    //               <div
    //                 className="d-flex w-100"
    //                 style={{ justifyContent: "center", flexDirection: "column" }}
    //               >
    //                 {imgMembresias.map(e => (
    //                   <div>
    //                     <img style={{ maxWidth: "25vmin" }} src={e.img} />{" "}
    //                   </div>
    //                 ))}
    //                 <div className="mt-1">
    //                   <span
    //                     className="fw-bold "
    //                     style={{
    //                       fontSize: "2.5vmin",
    //                       color: "var(--elevvaTextTitle)",
    //                     }}
    //                   // key={e.id}
    //                   >
    //                     {"Eleva a tu medida"}
    //                   </span>
    //                   {/* {index == 0 ? ( */}
    //                   <div style={{ padding: "0 6%" }}>
    //                     <span
    //                       className="fw-bold "
    //                       style={{
    //                         fontSize: "2.5vmin",
    //                         color: "var(--elevvaTextTitle)",
    //                       }}
    //                     >
    //                     </span>
    //                     <br />
    //                     <span style={{ fontSize: "1.3vmin" }}>
    //                       Con nuestro sistema de créditos, podrás reservar y tomar
    //                       clases personalizadas o grupales Online, el día y la
    //                       hora que desees, completamente a tu medida.
    //                     </span>
    //                     <br></br>

    //                     <div
    //                       style={{
    //                         color: "var(--elevvaTextTitle05)",
    //                         fontSize: "1.5vmin",

    //                         margin: "1vmin 0",
    //                       }}
    //                     >
    //                       {/* {e.savingPlan} */}
    //                       {"Vigencia 7 días"}
    //                     </div>

    //                     <div style={{ fontSize: "1.5vmin" }}>
    //                       Clases personalizadas
    //                     </div>
    //                   </div>
    //                   {/* ) : null} */}
    //                   <span
    //                     className="fw-bold"
    //                     style={{
    //                       color: "var(--elevva)",
    //                       fontSize: "2.0vmin",
    //                     }}
    //                   >
    //                     {/* {index == 0 ? "Desde" : ""} $ {e.fee} */}
    //                     {"Desde $ "}{15.99}
    //                   </span>{" "}
    //                   <br></br>
    //                   {/* {index !== 0 ? ( */}
    //                   <span
    //                     style={{
    //                       color: "var(--elevvaTextTitle05)",
    //                       fontSize: "1.5vmin",
    //                     }}
    //                   >
    //                     {/* {e.savingPlan} */}
    //                   </span>
    //                   {/* ) : null} */}
    //                 </div>
    //               </div>

    //               <div
    //                 className="m-auto d-flex mt-3"
    //                 style={{
    //                   width: "auto",
    //                   flexDirection: "column",
    //                   rowGap: "0.7vmin",
    //                 }}
    //               >
    //                 {/* {e.containPlan.map((e, i) => ( */}
    //                 <div className="divIndividualTypes me-auto d-flex">
    //                   <span
    //                     className=""
    //                     style={{ fontSize: "1.7vmin", color: "var(--elevva)" }}
    //                   >
    //                     {/* <i class="bi bi-check-lg"></i> */}
    //                   </span>{" "}
    //                   <span
    //                     className="me-auto"
    //                     style={{ fontSize: "1.5vmin", whiteSpace: "nowrap" }}
    //                   >
    //                     {/* {e} */}
    //                   </span>
    //                 </div>
    //                 {/* ))} */}
    //               </div>

    //               <ButtonMob
    //                 // colorTextButton={index == 0 ? "var(--white)" : "var(--elevva)"}
    //                 // colorButton={index == 0 ? "var(--elevva)" : "transparent"}
    //                 borderbutton={`2px solid var(--elevva)`}
    //                 text={"Adquiere tus créditos"}
    //                 className="btnAction  py-2 spacebutton"
    //               //handleClick={() => navigate(`/Usuario/payment`)}
    //               />
    //             </div>
    //             {/* {membresias.map(img => ( */}
    //             <>
    //               {limitedMembershipsName.map(e => (
    //                 <div
    //                   key={e.id}
    //                   className={
    //                     // index == 0
    //                     //   ? "direcccionately col d-flex resplandor"
    //                     // : "direcccionately col d-flex"
    //                     "direcccionately col d-flex"
    //                   }
    //                   style={
    //                     // index == 0 || index == 1
    //                     //   ? {
    //                     //       padding: "0 1%",
    //                     //       flexDirection: "column",
    //                     //       justifyContent: "center",
    //                     //       borderRadius: "20px",
    //                     //     }
    //                     //   : {
    //                     //       padding: "0 1%",
    //                     //       flexDirection: "column",
    //                     //       justifyContent: "center",
    //                     //       borderLeft: "1px solid #C9C9C9",
    //                     //     }
    //                     {
    //                       padding: "0 1%",
    //                       flexDirection: "column",
    //                       justifyContent: "center",
    //                       borderLeft: "1px solid #C9C9C9",

    //                     }
    //                   }
    //                 >
    //                   <div
    //                     className="d-flex w-100"
    //                     style={{ justifyContent: "center", flexDirection: "column" }}
    //                   >
    //                     <div>
    //                       <img style={{ maxWidth: "25vmin" }} src={process.env.PUBLIC_URL + "/assets/img/ilustracion_1.png"} alt="" />{" "}
    //                     </div>
    //                     <div className="mt-1">
    //                       <span
    //                         className="fw-bold "
    //                         style={{
    //                           fontSize: "2.5vmin",
    //                           color: "var(--elevvaTextTitle)",
    //                         }}
    //                         key={e.id}
    //                       >
    //                         {e.name}
    //                       </span>
    //                       <br></br>
    //                       <div style={{ padding: "0 6%" }}>
    //                         <span
    //                           className="fw-bold "
    //                           style={{
    //                             fontSize: "2.5vmin",
    //                             color: "var(--elevvaTextTitle)",
    //                           }}
    //                         >
    //                           {/* {"Eleva a tu medida"} */}
    //                           <span
    //                             className="fw-bold"
    //                             style={{
    //                               color: "var(--elevva)",
    //                               fontSize: "2.0vmin",
    //                             }}
    //                           >
    //                             $ {e.fee}
    //                           </span>
    //                         </span>

    //                       </div>
    //                       <span
    //                         style={{
    //                           color: "var(--elevvaTextTitle05)",
    //                           fontSize: "1.5vmin",
    //                         }}
    //                       >
    //                         {"Vigencia 7 días"}
    //                         <br></br>

    //                         {/* {e.description} */}
    //                       </span>

    //                     </div>
    //                   </div>
    //                   {/* {objeto.containPlan.map((description, index) => ( */}
    //                   <div
    //                     className="m-auto d-flex mt-3"
    //                     style={{
    //                       width: "auto",
    //                       flexDirection: "column",
    //                       rowGap: "0.7vmin",
    //                     }}
    //                   >
    //                     <div className="divIndividualTypes me-auto d-flex">
    //                       <span
    //                         className=""
    //                         style={{ fontSize: "1.7vmin", color: "var(--elevva)" }}
    //                       >
    //                         {/* <i class="bi bi-check-lg"></i> */}
    //                       </span>{" "}
    //                       <p
    //                         className="me-auto"
    //                         style={{ fontSize: "1.5vmin", whiteSpace: "pre-line" }}
    //                       // key={index}
    //                       >
    //                         {/* {e} */}
    //                         {e.description}
    //                         {/* {description} */}
    //                       </p>
    //                     </div>
    //                   </div>
    //                   {/* ))} */}
    //                   <ButtonMob
    //                     colorTextButton={"var(--elevva)"}
    //                     colorButton={"transparent"}
    //                     borderbutton={`2px solid var(--elevva)`}
    //                     text={"Seleccionar plan"}
    //                     className="btnAction  py-2 spacebutton button-position"
    //                     //handleClick={() => navigate(`/Usuario/payment`)}
    //                     // handleClick={() => handleMembershipSelect(e)}
    //                     handleClick={() => {
    //                       setSelectedMembership(e);
    //                       navigate(`/Usuario/payment`);
    //                     }}

    //                   />
    //                 </div>
    //               ))}
    //             </>
    //             {/* ))} */}
    //           </>
    //         )}
    //       </div>
    //       {/* </SwiperSlide>
    //       </Swiper> */}
    //       {/* <Slider /> */}

    //     </div>
    //   </div>
  );
});
export const ModalInfoPlan = React.forwardRef((props, ref) => {
  const [plan, setplan] = useState([]);
  const [DataPlan, setDataPlan] = useState("");
  const [isLoading, setisLoading] = useState(true);

  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;
  const idMembership=props.idMembership;
  const infoData=props.infoData;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
 const zindex=props.zindex || "999999999999"
 const navigate = useNavigate();
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
    zIndex:zindex
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  // Mostrar mi plan

  const idUser = localStorage.getItem("id");


  useEffect(() => {
    instance
      .get(`${NuevaAxiosURL}memberships/${idMembership}/detail`)
      .then((response) => {
        const data = response.data;
        const plan = data.description.split(`\r\n`)
        console.log(data);
        console.log(plan)
        setisLoading(false);
        setDataPlan(data);
        setplan(plan);
        // setMembershipsId(data);
        // setIsLoading(false); // establecer isLoading en false después de que se actualice membershipsId
      })
      .catch((error) => {
        if(error.response){
  
        console.log(error, "Memberships Error");}
      });
  }, [idMembership]);

  // Delimitador de Memberships
  // const filteredMemberships = membershipsId.filter(
  //   (membership) =>
  //     membership.name === "Básico" ||
  //     membership.name === "Gratuito" ||
  //     membership.name === "Pro" ||
  //     membership.name === "Elevva plus"
  // );

  // Encontrando user = memberships

  // filtrando solo id y members
  // const searchUsers = filteredMemberships.map(({ id, members }) => ({
  //   id,
  //   members,
  // }));
  // console.log(searchUsers, "array de id");

  // function buscarObjetoPorId(searchUsers, id) {
  //   for (let i = 0; i < searchUsers.length; i++) {
  //     if (searchUsers[i].members.includes(id)) {
  //       return { id: searchUsers[i].id, objeto: searchUsers[i] };
  //     }
  //   }
  //   return null;
  // }
  // buscarObjetoPorId(searchUsers, idUser);

  // useEffect(() => {
  //   function limite() {
  //     if (!isLoading && buscarObjetoPorId !== null) {
  //       // verificar si isLoading es false antes de llamar a buscarObjetoPorId
  //       console.log(buscarObjetoPorId(searchUsers, idUser));
  //       console.log("tengo cuenta");
  //     } else {
  //       console.log("soy nuevo");
  //     }
  //   }
  //   limite();
  // }, [searchUsers, isLoading]);

  // console.log(buscarObjetoPorId(searchUsers), "filter final");
  const classesMsg = props.classesMsg || "";
  return (
  <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      
      <div style={{ padding: "4vmin 0vmin" }} className={"modalInfoContenido_plan"}>

      {isLoading?<Loadingspinner customStyle="border" size="7vmin" widthContain={"10rem"}/>: plan.length>0? 
      <>
        {error == "none" || error == "true" ? (
          <div
            className=""
            style={{
              position: "absolute",
              top:laptop575.matches? "5%":"3%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        ) : (
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        )}

        <div
          style={{
            fontSize: "calc(1rem + 0.3vmin)",
            color: "var(--elevvaTextTitle)",
            justifyContent: "left",
            padding: "0vmin 8vmin",
          }}
          className={"title  mb-2 d-flex"}
        >
          <strong className="me-1">Mi</strong>
          plan
        </div>
<div id="miplanPopUp">
        <div
          className="d-flex mx-auto my-3 "
          style={{
            background: "#F4F0ED",
            padding:laptop575.matches? "3% 0%":"6% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",
            maxWidth: laptop575.matches?"60%":"80%",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <img
              src={iconoFlechaElevva} style={{ width: "1.3rem", margin: "0.5rem 0 " }}
            />{" "}
          </div>
          <span style={{ fontSize: "calc(0.7rem + 0.3vmin)",fontWeight:"bold"}} >Plan de entrenamiento</span>
          <span
           
            style={{
              color: "var(--elevva)",
              fontSize: "calc(0.7rem + 0.3vmin)",
              fontWeight:"bold"
            }}
          >
            {DataPlan.name}
          </span>
          <span  style={{ fontSize: "calc(0.7rem + 0.3vmin)",}} >{ Moment(localStorage.membershipStartDate).format('DD MMMM [de] YYYY')} / { Moment(localStorage.membershipEndDate).format('DD MMMM [de] YYYY')}</span>
        </div>
        <div
          className=" d-flex flex-column flex-md-row mx-auto" style={{    padding: "0% 10%",}}
          
        >
        <div className="d-flex col-12 col-md-10 mx-auto" style={{
           
           justifyContent: "center",
           margin: "1vmin 0 ",
       
         }}> <div className="direcccionately col  " style={{ padding: "0 0%" }}>
            <span className="letrapequeñaProfilePlan">{infoData.group }</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Clases grupales <br></br> restantes
            </span>
          </div>
          <div
            className="direcccionately_other col "
            style={laptop575.matches?{
              borderLeft: "1px solid gray",
              borderRight: "1px solid gray",
              padding: "0 0%",
            }:{
              borderLeft: "1px solid gray",
           
              padding: "0 0%",
            }}
          >
            <span className="letrapequeñaProfilePlan">{infoData.bookings_left }</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Clases individuales <br></br> restantes
            </span>
          </div>
          <div
            className="direcccionately col "
            style={laptop575.matches?{
           
              padding: "0 0%",
            }:{
              borderLeft: "1px solid gray",
           
              padding: "0 0%",
            }}
          >
            <span className="letrapequeñaProfilePlan">{infoData.videos_left }</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
            Acceso a <br></br> videos
            </span>
          </div>
          </div>
        {/* <div className="d-flex col-12 col-md-4  " style={{
           
           justifyContent: "center",
           margin: "1vmin 0 ",
        
         }} >
        <div
            className="direcccionately_other col "
            style={{
              borderRight: "1px solid gray",
              padding: "0 0%",
            }}
          >
            <span className="letrapequeñaProfilePlan">028</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Acceso a <br></br> videos
            </span>
          </div>

          <div className="direcccionately col " style={{ padding: "0 0%" }}>
            <span className="letrapequeñaProfilePlan">002</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Asesoría <br></br> en nutrición
            </span>
          </div>

        </div> */}
         
         
        </div>
</div>
        <div
          className={classesMsg + " my-3"}
          style={{ color: "var(--elevvaTextTitle)", fontSize: "calc(0.7rem + 0.3vmin)",fontWeight:"bold" }}
        >
          Especificaciones de tu plan
         
        </div>
        <div
          className="d-flex mx-auto"
          style={{
            marginTop: "1vmin",
            marginBottom: "1vmin",
            justifyContent: "flex-start",
            flexDirection:"row",
            flexWrap: "wrap",
            width:"90%"
          }}
        >
          {plan.map((e, i) => (
            <div className=" px-1"   style={plan.length>1?{width:"50%",}:{width:"100%",}}>  <button key={i} style={{fontWeight:"bold"}} className="buttonIndividualTypesCom py-2">
              {e}
            </button> </div>
          
          ))}
        </div>
        <ButtonMob colorButton={"white"} sizeFont={'calc(0.7rem + 0.3vmin)'}
                colorTextButton={`var(--elevva)`}
                borderbutton={`1px solid var(--elevva)`} text="Resumen de compras" handleClick={() => laptop575.matches?navigate("/perfil"): navigate("/Transacciones")}/>
        </>:<> <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div><div className="mt-2" style={{
            fontSize: "calc(1rem + 0.3vmin)",
            color: "var(--elevvaTextTitle)",
            justifyContent: "left",
            padding: "0vmin 8vmin",
          }}><span >No tienes una membresía activa</span></div>
          <ButtonMob text="Entendido" handleClick={handleCancel}/>
          </>}
      </div>
       </div> );
});
export const ModalPhoto = React.forwardRef((props, ref) => {
  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
  const plan = [
    "12 créditos de entrenamiento",
    "1 clase grupal gratis semanal",
    "Plan de entrenamiento",
    "Plan de alimentación",
    "Acceso a 1 video diario",
  ];

  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div style={{ padding: "0vmin 0vmin" }} className={"modalInfoContenido"}>
        <img
          style={{ width: "100%", borderRadius: "10px" }}
          src={process.env.PUBLIC_URL + "/assets/img/imagePopUp.jpg"}
        />
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "2.5vmin",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto my-3 fw-600"
          style={{
            padding: "3% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",

            textAlign: "center",
            fontSize: "3vmin",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            Más de 20 disciplinas disponibles{" "}
            <strong>en nuestra web y App</strong>
          </span>

          <button
            className="mx-auto mt-3 d-flex"
            style={{
              color: "var(--white)",

              bottom: "5%",
              right: "5%",
              padding: "1% 5%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
              background: "var(--elevva)",
              borderRadius: "20px",
              border: "1px solid var(--elevva)",
              fontSize: "1.4vmin",
            }}
          >
            Inicia tu prueba de 3 días <i className="bi bi-chevron-right"></i>
          </button>
          <span
            style={{
              padding: "3% 0%",
              color: "var(--elevvaTextTitle)",
              borderRadius: "30px",
              fontSize: "1.3vmin",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            Gracias, Tal vez más tarde
          </span>
        </div>
      </div>
    </div>
  );
});

//Modal Beneficios elevva +!
export const ModalElevva = React.forwardRef((props, ref) => {
  const handleClose = props.handleClose;
  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
  const plan = [
    "12 créditos de entrenamiento",
    "1 clase grupal gratis semanal",
    "Plan de entrenamiento",
    "Plan de alimentación",
    "Acceso a 1 video diario",
  ];

  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div style={{ padding: "0vmin 0vmin" }} className={"modalInfoContenido"}>
        <img
          style={{ width: "100%", borderRadius: "10px" }}
          src={process.env.PUBLIC_URL + "/assets/img/ilustracion_1.png"}
        />
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "2.5vmin",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto my-3 fw-600"
          style={{
            padding: "3% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",

            textAlign: "center",
            fontSize: "3vmin",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            ¡Adquiere todos los beneficios de <strong>elevva +!</strong>
          </span>
          <span
            style={{
              padding: "3% 0%",
              color: "var(--elevvaTextTitle)",
              borderRadius: "30px",
              fontSize: "2vmin",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            Inicia tu prueba gratuita de 7 días
          </span>
          {/* <button
            className="mx-auto mt-3 d-flex"
            style={{
              color: "var(--white)",

              bottom: "5%",
              right: "5%",
              padding: "1% 5%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
              background: "var(--elevva)",
              borderRadius: "20px",
              border: "1px solid var(--elevva)",
              fontSize: "1.4vmin",
            }}
            handleClick={() => {
              window.location.href = "/Usuario/Inicio"
            }}
          >
            Conoce más <i className="bi bi-chevron-right"></i>
          </button> */}
          <div className="d-flex align-items-center justify-content-center">
            <ButtonMob
              colorButton={`var(--elevva)`}
              className="mx-2 sizebuttonLarge"
              text={"Entendido"}
              handleClick={() => {
                window.location.href = "/Usuario/groupals";
              }}
            />
          </div>
          {/* <Link to={`/createUser/${type}`}> */}
        </div>
      </div>
    </div>
  );
});

//Modal creacion de cuenta
export const ModalCuenta = React.forwardRef((props, ref) => {
  const handleClose = props.handleClose;

  const text = props.text || "none";
  const text2 = props.text2 || "none";
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error === "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text === "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text === "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div  className={"modalInfoContenido"}>
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "calc(1rem + 0.5vw)",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto  fw-600 text-center  flex-column justify-content-center gap-3 gap-md-4"
          style={{
           
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",
            fontSize: "calc(0.5rem + 0.8vw)",
            
         
          }}
        >
          <div className="d-flex justify-content-center ">
            <i className="bi bi-check-lg align-self-center modalcitoOk"></i>
          </div>
         
          <span
            className="m-auto"
            style={{
              fontSize: "calc(0.9rem + 0.5vw)",
              lineHeight: "100%",
             
            }}
          >
          {ReactHtmlParser(text)}
            
          </span>
          <div className="modal-body">
            <span
              className="text-color-subtitle m-0"
              style={{
                lineHeight: "100%",
                fontSize: "calc(0.9rem + 0.5vw)",
              }}
            >
            {ReactHtmlParser(text2)}
             
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <ButtonMob
              colorButton={`var(--elevva)`}
              className="mx-2 sizebuttonLarge my-0"
              text={"Entendido"}
              handleClick={() => {
                window.location.href = "/Usuario/welcometoElevva";
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export const ModalCuentaError = React.forwardRef((props, ref) => {
  const handleClose = props.handleClose;

  const text = props.text || "none";
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error === "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text === "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text === "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div style={{ padding: "0vmin 0vmin" }} className={"modalInfoContenido"}>
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "2.5vmin",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto my-3 fw-600"
          style={{
            padding: "3% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",

            textAlign: "center",
            fontSize: "3vmin",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            Hay un <strong>error</strong>
            en el registro de
            <strong>tu contraseña</strong>
          </span>
          {/* <button
            className="mx-auto mt-3 d-flex"
            style={{
              color: "var(--white)",

              bottom: "5%",
              right: "5%",
              padding: "1% 5%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
              background: "var(--elevva)",
              borderRadius: "20px",
              border: "1px solid var(--elevva)",
              fontSize: "1.4vmin",
            }}
            handleClick={() => {
              window.location.href = "/Usuario/Inicio"
            }}
          >
            Conoce más <i className="bi bi-chevron-right"></i>
          </button> */}
          <p>
            Debe tener al menos 8 caracteres y recomendamos incluir letras
            mayúsculas y miúsculas, números no consecutivos y/o otros caracteres
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <ButtonMob
              colorButton={`var(--elevva)`}
              className="mx-2 sizebuttonLarge"
              text={"Entendido"}
              onClick={handleClose}
            />
          </div>
          {/* <Link to={`/createUser/${type}`}> */}
        </div>
      </div>
    </div>
  );
});

export const SetModalError = (props) => {
  const classes = props.className;
  const msgError =
    props.msg ||
    "Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos";
  const titleError = props.title || "Algo salio mal";
  const handleClick = props.handleClick;
  return (
    <ModalInfo
      error="false"
      handleClick={handleClick}
      handleCancellcargue={handleClick}
      title={titleError}
      text={msgError}
    />
  );
};

// Textarea

export const Panel = (props) => {
  const {
    children,
    align,
    id,
    classNameContent,
    contentRef,
    tabRef,
    tabAvatar,
    classNameTab,
    tabCerrar,
    otropanel,
  } = props;
  const [isShow, setIsShow] = useState(false);
  function showOrHideContent() {
    setIsShow(showOrHidePanel(contentRef, tabRef, align, isShow, otropanel));
    let width;
    setTimeout(() => {
      width = contentRef.current.clientWidth;

      if (align == "left") {
        tabRef.current.style.marginLeft = width + "px";
      } else {
        // right
        if (otropanel == undefined) {
          tabRef.current.style.marginRight = width + "px";
        }
      }
    }, 505);
  }
  return (
    <>
      <div
        id={id}
        className={`PanelContent ${classNameContent} ${align} ${otropanel}`}
        ref={contentRef}
      >
        {children}
      </div>
      <div
        className={`PanelTab ${classNameTab} ${align} ${otropanel}`}
        ref={tabRef}
        onClick={showOrHideContent}
      >
        {align == "left" && otropanel == undefined ? (
          <div className="text">{isShow ? "Cerrar" : "Abrir"}</div>
        ) : align == "right" && otropanel == undefined ? (
          <div className="text">{isShow ? "Cerrar" : "Abrir"}</div>
        ) : (
          <div className="text">{isShow ? "Cerrar" : "Rutina"}</div>
        )}
        {!isShow ? (
          <img src={tabAvatar} alt="Open/close" className="rounded-circle" />
        ) : (
          <img src={tabCerrar} alt="Open/close" className="rounded-circle" />
        )}
      </div>
    </>
  );
};




export const connectPeerToPeer = async (props, checkRemoteAudio = false) => {
  toCheckRemoteAudioWebView = checkRemoteAudio;
  clearInterval(dataStats.intervalCheckNetwork);
  const suVideo = document.querySelector("#suVideo");
  console.log(suVideo);
  var localAudioMusic;

  if (!suVideo) {
    setTimeout(() => {
      connectPeerToPeer(props);
    }, 1000);
    return;
  }
  suVideo.innerHTML = "";
  addWaitMsg(suVideo);
  const uid = props.uid || null;
  const idClase = props.id_clase;
  const reserva = props.reserva;
  const type = props.type;
  var tokendefelipe;
  console.log(options.role);
  options.reserva = reserva;
  options.uid = uid;
  options.type = type;
  options.idClase = idClase;
  options.channel = idClase;
  options.token = props.agoraToken;

//   const tokend = ChatTokenBuilder.buildUserToken(options.appId, options.appCertificate, options.uid.toString() + "-" + "felipe", options.privilegeExpiredTs);
// console.log(tokend)
  async function startCapture() {
    let captureStream;

    try {
      captureStream = await navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: true })
        .then((mediaStream) => {
          const audioMediaStreamTrack = mediaStream.getAudioTracks()[0];

          console.log(audioMediaStreamTrack);
          return AgoraRTC.createCustomAudioTrack({
            mediaStreamTrack: audioMediaStreamTrack,
          });
        });
    } catch (err) {
      captureStream = null;
      console.error(`Error: ${err}`);
    }
    return captureStream;
  }
  const element = document.getElementById("MusicPlayList");

  element && element.addEventListener("click", myFunction);

  let codec = "vp8";
  const result = await AgoraRTC.getSupportedCodec();

  //First create the client

  rtc.client = AgoraRTC.createClient({
    mode: "rtc",
    codec: codec,
  });

  rtc.client.on("user-published", handleUserPublished);
  rtc.client.on("user-unpublished", handleUserUnpublished);

  // join a channel and create local tracks, we can use Promise.all to run them concurrently
  [options.returnUid, rtc.localAudioTrack, rtc.localVideoTrack] =
    await Promise.all([
      // join the channel
      rtc.client.join(
        options.appId,
        options.channel,
        options.token || null,
        options.uid
      ),
      // create local tracks, using microphone and camera
      AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: "high_quality",
        microphoneId: rtc.selectedMicId,
      }),

      AgoraRTC.createCameraVideoTrack({
        // frameRate: 90,
        // bitrateMin: 600,
        // bitrateMax: 2000,

        encoderConfig: "480p_4",
        cameraId: rtc.selectedCamId,
      }),
    ]);
  console.log(options.returnUid, rtc.localAudioTrack, rtc.localVideoTrack)
  // play local video track
  const miVideo = document.querySelector("#miVideo");
  miVideo.innerHTML = "";
  rtc.localVideoTrack.play("miVideo");
  // Own video, not play own sound to avoid echo
  // publish local tracks to channel
  rtc.localAudioTrack.setVolume(200);
  async function myFunction() {
    localAudioMusic = await startCapture();
    if (
      localAudioMusic &&
      localAudioMusic.trackMediaType == "audio" &&
      type == 20
    ) {
      localAudioMusic && localAudioMusic.setVolume(70);
      await rtc.client.publish([
        rtc.localAudioTrack,
        localAudioMusic,
        rtc.localVideoTrack,
      ]);
    }
  }
  await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
  //-const containerIcon = document.querySelector("#containerIcon")
  //containerIcon.classList.remove("d-none")
  audioEnabled = true;
  clearInterval(timeOutcheckVideo);
  clearInterval(timeOutcheckAudio);
  timeOutcheckVideo = setInterval(() => {
    checkVideo();
  }, 500);

  timeOutcheckAudio = setInterval(() => {
    checkAudio();
  }, 500);
  checkNetworkInfo(); // Check inmediatly
  dataStats.intervalCheckNetwork = setInterval(() => {
    // and then, every 5 seconds
    checkNetworkInfo();
  }, 5000);
  rtc.client.on("network-quality", (stats) => {
    dataStats.netQA = stats;
    if (stats.uplinkNetworkQuality > 1 || stats.downlinkNetworkQuality > 1) {
      // 1 is ok, > 1 is bad nwtwork
      showToastNetwork("network-quality"); 
    }
  });
};

export const connectPeerToPeerUser = async (
  props,
  checkRemoteAudio = false
) => {
  toCheckRemoteAudioWebView = checkRemoteAudio;
  clearInterval(dataStats.intervalCheckNetwork);
  const suVideo = document.querySelector("#suVideo");

  var devices = {
    cameras: [],
    mics: [],
  };
  if (!suVideo) {
    setTimeout(() => {
      connectPeerToPeerUser(props);
    }, 1000);
    return;
  }
  suVideo.innerHTML = "";
  addWaitMsg(suVideo);
  const uid = props.uid || null;
  const idClase = props.id_clase;
  const reserva = props.reserva;
  const nameClient = props.nameClient || null;
  const classType = props.classType || null;
  const type = props.type;
  let optionschat = {
    uid: "",
    token: "",
  };
  options.reserva = reserva;
  options.type = type;
  options.uid = uid;
  options.idClase = idClase;
  optionschat.uid = uid.toString() + "-" + nameClient;
  options.channel = idClase;
  options.token = props.agoraToken;
  // Crea una instancia del objeto AgoraRTM
// const rtmClient = AgoraRTM.createInstance(options.appId);

const localVideo = await AgoraRTC.createCameraVideoTrack();
let codec = "vp8";
const result = await AgoraRTC.getSupportedCodec();
//First create the client
rtc.client = AgoraRTC.createClient({
  mode: "live",
  codec: codec,
});
// client.setClientRole("audience");
try {
  const uid = await rtc.client.join(
    options.appId,
    options.channel,
    options.token,
    options.uid,
 
  );


} catch (e) {
  console.log("join failed", e);
}
rtc.localVideoTrack = localVideo
rtc.client.on("user-published", async (remoteUser, mediaType) => {
  await rtc.client.subscribe(remoteUser, mediaType);
  if (mediaType === "video") {
    console.log("subscribe video success");
    remoteUser.videoTrack.play(suVideo);
   
  }
  if (mediaType === "audio") {
    console.log("subscribe audio success");
    remoteUser.audioTrack.play();
  }
});

const miVideo = document.querySelector("#miVideo");
// Inicia sesión en AgoraRTM

  optionschat.token = RtmTokenBuilder.buildToken(
    options.appId,
    options.appCertificate,
    options.uid.toString() + "-" + nameClient,
    RtmRole.published,
    options.privilegeExpiredTs
  );

  const clientChat = AgoraRTM.createInstance(options.appId);
  var channel = clientChat.createChannel(options.channel);
  const sendry= document.getElementById("send_channel_message");
 
  




  clientChat
  .login(optionschat)
  .then(() => {
    console.log("AgoraRTM client login success");
    channel
      .join()
      .then(() => {
        console.log("se unio");
      })
      .catch((error) => {
        console.log(error);
      });
  })
  .catch((err) => {
    console.log("AgoraRTM client login failure", err);
  });
 if(sendry){
    sendry.onkeyup = async function (e) {
    if (e.key == "Enter") {
      var comment = document.getElementById("send_channel_message");

      let channelMessage = comment.value;
      // document.getElementById("channelMessage").value.toString()
      if (channel != null && comment.value != "") {
        await channel
          .sendMessage({ text: channelMessage })
          .then((res) => {
            comment.value = "";
          })
          .catch((err) => console.log(err));
      }
    }
  };
  sendry
  .addEventListener("focusout", async (e) => {
    var comment = document.getElementById("send_channel_message");

    let channelMessage = comment.value;
    // document.getElementById("channelMessage").value.toString()
    if (channel != null && comment.value != "") {
      await channel
        .sendMessage({ text: channelMessage })
        .then((res) => {
          comment.value = "";
        })
        .catch((err) => console.log(err));
    }
  });
}

  if (classType == null) {
    miVideo.innerHTML = "";
    localVideo.play("miVideo");
  }

  //  await client.publish([localAudio, localVideo]);

  audioEnabled = true;
  clearInterval(timeOutcheckVideo);
  clearInterval(timeOutcheckAudio);
  timeOutcheckVideo = setInterval(() => {
    checkVideo();
  }, 500);

  timeOutcheckAudio = setInterval(() => {
    checkAudio();
  }, 500);
  checkNetworkInfo(); // Check inmediatly
  dataStats.intervalCheckNetwork = setInterval(() => {
    // and then, every 5 seconds
    checkNetworkInfo();
  }, 5000);
  rtc.client.on("network-quality", (stats) => {
    dataStats.netQA = stats;
    if (stats.uplinkNetworkQuality > 1 || stats.downlinkNetworkQuality > 1) {
      // 1 is ok, > 1 is bad nwtwork
      showToastNetwork("network-quality");
    }
  });
};

export async function disconnectPeer() {
  if(dataStats.intervalCheckNetwork){

    clearInterval(dataStats.intervalCheckNetwork);
  }
  console.log('rtc',rtc)
  if (rtc.localVideoTrack) {
    rtc.localVideoTrack.stop();
    rtc.localVideoTrack.close();
  }
  if (rtc.localAudioTrack) {
    rtc.localAudioTrack.stop();
    rtc.localAudioTrack.close();
  }

  if (rtc.remoteVideoTrack) {
    rtc.remoteVideoTrack.stop();
  }
  if (rtc.localAudioTrack) {
    rtc.localAudioTrack.stop();
  }
  // leave the channel
  if (rtc.client) {
    await rtc.client.leave();
   

    console.log("client leaves channel success");
  }
 
}

export const fancyTimeFormat = (mins) => {
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  if (hours < 10) hours = ("0" + hours).slice(-2);
  return `${hours}:${("0" + minutes).slice(-2)}`;
};


