import { useEffect } from "react";
import React from "react";
import {
  CategoriasEmpty,
  Paso7,
} from "../TutorialReserva/stepsHelp";
import {useParams } from "react-router-dom";
import { instance, laptop575 } from "../../utils/utils";
import { useState } from "react";
import "moment/locale/es";
const ScheduleMobile = (props) => {
  const { id } = useParams();
  const [trainerGlobal, setTrainerGlobal] = useState(null);
  const [infoTraining, setinfoTraining] = useState(null);

  useEffect(() => {
    instance
      .get(`trainings/${id}`)

      .then((response) => {
        setinfoTraining(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    if(localStorage.trainerSelected){

      setTrainerGlobal(localStorage.trainerSelected)
    }
  }, []);
  return (
<div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
 <Paso7 infoTraining={infoTraining} Trainer={trainerGlobal} vistaMostrar={2} />        
</div>
  );

}
export default ScheduleMobile;