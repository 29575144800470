import "./createaccountPay.css";
import { useParams } from "react-router";

import { useNavigate, useLocation } from "react-router-dom";

import React from "react";

import { ButtonArrow } from "../../utils/utils";

import { FormRegister } from "./utils";

const CreateaccountPay = (props) => {
  const elevvaT = props.elevvaT;
  const { user } = useParams();

  const navigate = useNavigate();

  const type = props.type;
  const membresias = [
    {
      datePlan: "Plan mensual",
      namePlan: "Gratuito",
      containPlan: [
        "12 créditos de entrenamiento",
        "1 clase grupal gratis semanal",
        "Plan de entrenamiento",
        "Plan de alimentación",
        "Acceso a 1 video diario",
      ],
      pricePlan: 15.99,
      beforePlan: 100000,
      savingPlan: "Vigencia 7 días",
      img: process.env.PUBLIC_URL + "/assets/img/ilustracion_2.png",
    },

    {
      datePlan: "Plan anual",
      namePlan: "Elevva plus",
      containPlan: [
        "12 créditos de entrenamiento",
        "1 clase grupal gratis semanal",
        "Plan de entrenamiento",
        "Plan de alimentación",
        "Acceso a 1 video diario",
      ],
      pricePlan: 999.99,
      beforePlan: 100000,
      savingPlan: "Débito mensual",
      img: process.env.PUBLIC_URL + "/assets/img/ilustracion_1.png",
    },
  ];
  return (
    <>
      <div id="mobile" className="d-md-none mobile-login">
        <div
          className="containerBackgroundCreateuser"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL +
              "/assets/img/imagen_mobile_createUser.jpg"
            })`,
          }}
        >
          <div className="button-back-start">
            <ButtonArrow
              colorBack={`var(--elevvaArrowColor)`}
              handleClick={() => navigate(-1)}
            />
          </div>
        </div>
        <div
          className="mobile-welcome-login-row  text-left w-100"
          style={{
            display: "flex",
            flexDirection: "column",

            height: "auto",
          }}
        >
          <br />
          <div className="mx-4">
            <span className="text-color-subtitle">
              {type == elevvaT("cover_start_login_customer")
                ? elevvaT("customer_login")
                : elevvaT("trainer_login")}
            </span>
            <h1 className="text-color-title">
              {elevvaT("Create")} <strong>{elevvaT("account")}</strong>
            </h1>
          </div>
          <FormRegister classes={"mx-4"} elevvaT={elevvaT} type={user} />
        </div>
      </div>
      <div id="web" className="d-none d-md-flex col-12 ">
        <div
          style={
            type == elevvaT("cover_start_login_customer")
              ? {
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL +
                    "/assets/img/createUser_web_image.jpg"
                  })`,
                }
              : {
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL +
                    "/assets/img/imagen_mobile_createUser.jpg"
                  })`,
                }
          }
          className={
            type == elevvaT("cover_start_login_customer")
              ? "imagen-createUser-background col-3"
              : "imagen-createTrainer-background col-3"
          }
        ></div>

        <div
          className={
            type == elevvaT("cover_start_login_customer")
              ? "container-create-web-customer col-9 d-flex"
              : "container-create-web-trainer col-9 d-flex"
          }
        >
          <div style={{ height: "100vh", width: "95%" }}>
            <div className=" box-create-account ">
              <div
                className="box-create-account-inside"
                style={{ flexDirection: "column", height: "100%" }}
              >
                {" "}
                <div>
                  <div
                    style={{
                      color: "var(--elevvaTextTitle)",
                      justifyContent: "center",
                      padding: "0vmin 8vmin",
                    }}
                    className={"title  d-flex my-auto"}
                  >
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div className=" d-flex tabulete-active mx-auto">
                        <div
                          className="centraNumberSteps fw-bold"
                          style={{
                            fontSize: "1.5vmin",
                            width: "10px",
                            color: "#ffff",
                          }}
                        >
                          1
                        </div>
                      </div>
                    </div>

                    <div
                      className="linememberstep mx-1"
                      style={{
                        verticalAlign: "middle",
                        width: "43vmin",
                      }}
                    >
                      <hr />
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div className=" d-flex tabulete-active mx-auto">
                        <div
                          className="centraNumberSteps  fw-bold"
                          style={{ fontSize: "1.5vmin", color: "#ffff" }}
                        >
                          2
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "var(--elevvaTextTitle)",
                      justifyContent: "center",
                      padding: "0vmin 8vmin",
                    }}
                    className={"title  d-flex my-auto"}
                  >
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div className=" d-flex  mx-auto">
                        <div
                          className=" d-flex "
                          style={{ fontSize: "1.7vmin" }}
                        >
                          Confirma tu pago
                        </div>
                      </div>
                    </div>

                    <div
                      className="linememberstep"
                      style={{
                        verticalAlign: "middle",
                        width: "36vmin",
                      }}
                    ></div>
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div className=" d-flex  mx-auto">
                        <div
                          className=" d-flex "
                          style={{ fontSize: "1.7vmin" }}
                        >
                          Selecciona
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="d-flex">
                  {" "}
                  <div className="col-6 d-flex" style={{ padding: "0 4% 0 0" }}>
                    {membresias.map((e, index) => (
                      <div
                        className={
                          "direcccionately col d-flex mx-1 elevation-3"
                        }
                        style={{
                          padding: "3%",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div
                          className="d-flex w-100 "
                          style={{
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <img style={{ maxWidth: "25vmin" }} src={e.img} />{" "}
                          </div>
                          <div className="mt-1">
                            <span
                              className="fw-bold "
                              style={{
                                fontSize: "2.5vmin",
                                color: "var(--elevvaTextTitle)",
                              }}
                            >
                              {e.namePlan}
                            </span>
                            <br></br>
                            <span
                              className="fw-bold"
                              style={{
                                color: "var(--elevva)",
                                fontSize: "2.0vmin",
                              }}
                            >
                              $ {e.pricePlan}
                            </span>{" "}
                            <br></br>
                            <span
                              className="fw-600"
                              style={{
                                color: "#C9C9C9",
                                fontSize: "1.5vmin",
                              }}
                            >
                              {" "}
                              {e.savingPlan}{" "}
                            </span>
                          </div>
                        </div>

                        <div
                          className="m-auto d-flex mt-3"
                          style={{
                            width: "auto",
                            flexDirection: "column",
                            rowGap: "0.7vmin",
                          }}
                        >
                          {e.containPlan.map((e, i) => (
                            <div
                              key={i}
                              className="divIndividualTypes me-auto d-flex"
                            >
                              <span
                                className=""
                                style={{
                                  fontSize: "1.7vmin",
                                  color: "var(--elevva)",
                                }}
                              >
                                <i class="bi bi-check-lg"></i>
                              </span>{" "}
                              <span
                                className="me-auto"
                                style={{
                                  fontSize: "1.5vmin",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {e}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-6" style={{ padding: "2%" }}>
                    <span style={{ color: "#9DACBD" }}>
                      Bienvenido a tu cambio de vida
                    </span>
                    <div
                      className="text-color-title"
                      style={{ fontSize: "4vmin" }}
                    >
                      Suscríbete a tu prueba <br />{" "}
                      <strong>gratuita de 7 días</strong>
                    </div>

                    <FormRegister
                      classes={"mx-0"}
                      elevvaT={elevvaT}
                      type={user}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ background: "#B1A0C4", width: "5%" }}> </div>
        </div>
      </div>
    </>
  );
};

export default CreateaccountPay;
