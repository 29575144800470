import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";

import axios from "axios";
import React from "react";
import "react-phone-input-2/lib/style.css";

import {
  basename,
  ButtonMob,
  NuevaAxiosURL,
  SetModalError,
} from "../../../utils/utils";
import "./index.css";

export const FormRegister = (props) => {
  const elevvaT = props.elevvaT;
  const type = props.type;
  const navigate = useNavigate();
  const NameCustomer = elevvaT("cover_start_login_customer");
  const [displayError, setDisplayError] = useState("none");
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState("none");
  const [DisplayCorrectphone, setDisplayCorrectphone] = useState("none");
  const [actualIconEye1, setActualIcon1] = useState("bi bi-eye-fill");
  const [actualIconEye2, setActualIcon2] = useState("bi bi-eye-slash-fill");
  const [showOrNo1, setShow1] = useState("password");
  const [showOrNo2, setShow2] = useState("password");
  const [Modal, setModal] = useState(null);

  const Ocultar = () => {
    setTimeout(() => {
      setModal(null);
    }, 5000);
  };
  const hideModal = () => {
    setModal(null);
  };
  const AutoLogin = (event) => {
    let servicio = "users/auth/login/";
    const usuario = event.target[0].value.trim();

    const password = event.target[3].value.trim();
    var formData = new FormData();

    formData.append("email", usuario);
    formData.append("password", password);
    axios({
      method: "post",
      contentType: "application/json",
      url: `${NuevaAxiosURL}${servicio}`,
      params: {
        t: new Date().getTime(),
      },
      data: formData,
    })
      .then((res) => {
        const data = res.data;

        if (!data) {
          setDisplayError("inherit");
          setTimeout(() => {
            setDisplayError("none");
          }, 5000);
        } else {
          localStorage.my_code = data.access;
          localStorage.type = type;

          const instance = axios.create({
            baseURL: NuevaAxiosURL,

            headers: { Authorization: "Bearer " + localStorage.my_code },
          });
          instance.get("users/current/").then((response) => {
            const data = response.data;
            localStorage.first_name = data.first_name;
            localStorage.last_name = data.last_name;
            localStorage.email = data.email;
            localStorage.type = type;
            localStorage.id = data.id;
            localStorage.mobile_numberMob = data.document_id;
            localStorage.profile_pic = data.profile_pic;
            if (data.membership) {
              localStorage.membership = data.membership.membership.id;
              localStorage.membershipEndDate = data.membership.end_date;
              localStorage.membershipStartDate =
              data.membership.start_date;
            }
            if (type == "Entrenador") {
              if (data.trainer.is_enabled) {
                document.location.href = `${basename}/${type}/inicio`;
              } else {
                document.location.href = `${basename}/OnboardingTrainer`;
              }
            } else {
              document.location.href = `${basename}/${type}/reservas`;
            }
          });
        }
      })
      .catch((err) => {
        setModal(<SetModalError msg={err.response} handleClick={hideModal} />);
      });
  };
  const handleShowPass = (how) => {
    if (how == 1) {
      if (actualIconEye1 == "bi bi-eye-fill") {
        setActualIcon1("bi bi-eye-slash-fill");
        setShow1("text");
      } else {
        setActualIcon1("bi bi-eye-fill");
        setShow1("password");
      }
    } else {
      if (actualIconEye2 == "bi bi-eye-fill") {
        setActualIcon2("bi bi-eye-slash-fill");
        setShow2("text");
      } else {
        setActualIcon2("bi bi-eye-fill");
        setShow2("password");
      }
    }
  };
  const [inputs, setInputs] = useState({});

  const handleSubmit = (event) => {
    if (inputs["mobile_number"].length >= 12) {
      var formData = new FormData();
      formData.append("email", inputs["email"]); //mail
      formData.append("mobile_number", inputs["mobile_number"]); //phone
      formData.append("password", inputs["password"]); //pass1
      formData.append("password2", inputs["password2"]); //pass2
      formData.append("type", type == "Usuario" ? 10 : 20); //TYPE
      formData.append("first_name", inputs["first_name"]); //Nombre
      formData.append("last_name", inputs["last_name"]); //Apellido
      axios({
        method: "post",
        contentType: "application/json",
        url: `${NuevaAxiosURL}users/auth/signin/`,
        params: {
          t: new Date().getTime(),
        },
        data: formData,
      })
        .then((res) => {
          const data = res.data;
          if (!data) {
            setDisplayError("inherit");
            setTimeout(() => {
              setDisplayError("none");
            }, 5000);
          } else {
            navigate("/" + NameCustomer + "/welcometoElevva");
          }
        })
        .catch((err) => {
          const msg_error = err.response.data;
          if (msg_error) {
           
            setDisplayCorrect("inherit");
            setTimeout(() => {
              setDisplayCorrect("none");
            }, 5000);
            setDisplayError("inherit");
            setTimeout(() => {
              setDisplayError("none");
            }, 5000);
            setDisplayCorrectphone("inherit");
            setTimeout(() => {
              setDisplayCorrectphone("none");
            }, 5000);

            seterroraGregado(msg_error);
          }
        });
    } else {
      setDisplayCorrectphone("inherit");
      setTimeout(() => {
        setDisplayCorrectphone("none");
      }, 5000);
      inputs["mobile_number"] = inputs["mobile_number"].slice(0, 2);
    }

    event.preventDefault();
  };
  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs((val) => ({ ...val, [name]: value }));
  };
  const classes = props.classes || "";
  return (
    <form className={classes} onSubmit={handleSubmit}>
      <div
        id={elevvaT("email_text")}
        className={"my-2 my-md-0 mx-md-auto my-md-3"}
      >
        <input
          type="email"
          placeholder={elevvaT("email_text")}
          name={"email"}
          className="form-control input-style-elevva "
          value={inputs.email || ""}
          onChange={handleChange}
          required
        ></input>
        <div
          className=""
          style={{
            display: displayError,
            color: "#CB3D82",
          }}
        >
          {ErroraGregado && ErroraGregado.email ? ErroraGregado.email : null}
        </div>
      </div>

      <div
        id={elevvaT("phone")}
        className={"phone-web my-2 my-md-0  mx-md-auto my-md-3"}
      >
        <ReactPhoneInput
          country={"co"}
          className="PEPITO"
          // countryCallingCodeEditable={true}
          name={"mobile_number"}
          placeholder={elevvaT("phone")}
          value={inputs.mobile_number || ""}
          onChange={(e) =>
            setInputs((val) => ({
              ...val,
              ["mobile_number"]: e,
            }))
          }
          required
        />
      </div>

      <div
        style={{
          display: DisplayCorrectphone,
          color: "#CB3D82",
        }}
      >
        {ErroraGregado && ErroraGregado.mobile_number
          ? ErroraGregado.mobile_number
          : ErroraGregado && !ErroraGregado.mobile_number
          ? ErroraGregado[0]
          : "El teléfono debe ser válido"}
      </div>

      <div className="d-row  d-md-flex col-md-12  my-md-3">
        <div
          id={elevvaT("password_text")}
          className={"my-2 my-md-0 col-md-6 pe-md-1"}
        >
          <div className="input-group " style={{ position: "relative" }}>
            <input
              type={showOrNo1}
              placeholder={elevvaT("password_text")}
              name={"password"}
              className="form-control input-style-elevva p-relative"
              value={inputs.password || ""}
              onChange={handleChange}
              required
            ></input>

            <div
              className="eye-color-location"
              onClick={() => handleShowPass(1)}
            >
              <i className={actualIconEye1}></i>
            </div>
          </div>

          <div
            id="passmail_error"
            className=""
            style={{
              display: displayCorrect,
              color: "#CB3D82",
            }}
          >
            {ErroraGregado &&
            ErroraGregado.password == "Password fields didn't match."
              ? "Las contraseñas no coinciden"
              : null}
            {ErroraGregado &&
            ErroraGregado.password != "Password fields didn't match."
              ? ErroraGregado.password
              : null}
          </div>
        </div>
        <div
          id={elevvaT("password_text")}
          className={"my-2 my-md-0 col-md-6 ps-md-1"}
        >
          <div className="input-group " style={{ position: "relative" }}>
            <input
              type={showOrNo2}
              value={inputs.password2 || ""}
              onChange={handleChange}
              placeholder={elevvaT("repeat_password")}
              name={"password2"}
              className="form-control input-style-elevva p-relative"
              required
            ></input>
            <div
              className="eye-color-location"
              onClick={() => handleShowPass(2)}
            >
              <i className={actualIconEye2}></i>
            </div>
          </div>

          <div
            id="passmail_error"
            className=""
            style={{
              display: displayCorrect,
              color: "#CB3D82",
            }}
          >
            {ErroraGregado &&
            ErroraGregado.password == "Password fields didn't match."
              ? "Las contraseñas no coinciden"
              : null}
            {ErroraGregado &&
            ErroraGregado.password != "Password fields didn't match."
              ? ErroraGregado.password
              : null}
          </div>
        </div>
      </div>

      <div className="d-row  d-md-flex col-md-12  my-md-3">
        <div
          id={elevvaT("name_create")}
          className={"my-2 my-md-0 col-md-6 pe-md-1"}
        >
          <input
            placeholder={elevvaT("name_create")}
            name={"first_name"}
            type="text"
            value={inputs.first_name || ""}
            className="form-control input-style-elevva p-relative"
            onChange={handleChange}
            required
          ></input>
        </div>

        <div
          id={elevvaT("last_name")}
          className={"my-2 my-md-0 col-md-6 ps-md-1"}
        >
          <input
            placeholder={elevvaT("last_name")}
            name={"last_name"}
            type="text"
            value={inputs.last_name || ""}
            className="form-control input-style-elevva"
            onChange={handleChange}
            required
          ></input>
        </div>
      </div>

      <TermsAndPolitics key={"terms"} id={"terms"} elevvaT={elevvaT} />

      <div className="d-flex justify-content-center">
        <ButtonMob
          colorButton={`var(--elevvabutonLogin_User)`}
          text="Suscríbete a plan gratuito"
          className="my-2 d-flex justify-content-center Botonentrar"
        />
      </div>
    </form>
  );
};
export const TermsAndPolitics = (props) => {
  const id = props.id;
  const elevvaT = props.elevvaT;
  return (
    <div id={id} className="my-4 d-flex justify-content-center ">
      <div className=" m-auto text-align-center ">
        <div id="TyC" className="text-color-subtitle text-center ">
          &nbsp;&nbsp;{elevvaT("confirm_start")}{" "}
          <a onClick={() => window.open("/tyc")} style={{ cursor: "pointer" }}>
            {elevvaT("Terms_conditions")}
          </a>{" "}
          {elevvaT("and")}
          <a
            onClick={() => window.open("/privacidad")}
            style={{ cursor: "pointer" }}
          >
            {" "}
            {elevvaT("politics")}
          </a>
        </div>
      </div>
    </div>
  );
};
