
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg"
import ReactHtmlParser from "react-html-parser";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  instance,
  NuevaAxiosURL,
  ButtonMob,
  laptop575,
} from "../../utils/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from "swiper";
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg";
import { ReactComponent as ValoraIco } from "../../assets/newIcons/ICO-PLAN-VALORACION.svg";
import { ReactComponent as TrainIcon } from "../../assets/newIcons/ICO-PLAN-ENTRENAMIENTO.svg";
import { ReactComponent as CardioIcon } from "../../assets/newIcons/ICO-PLAN-CARDIO.svg";

import { ReactComponent as VideoIcon } from "../../assets/newIcons/ICO-PLAN-VIDEO.svg";
import { ReactComponent as NutriIcon } from "../../assets/newIcons/ICO-PLAN-NUTRICION.svg";
import { ReactComponent as IconCaminar } from "../../assets/newIcons/ICO-CAMINAR.svg";
import { ReactComponent as IconClock } from "../../assets/newIcons/ICO-CLOCK.svg";
import { ReactComponent as IconIntensity } from "../../assets/newIcons/ICO-INTENSITY.svg";
import { ReactComponent as IconCaminata } from "../../assets/newIcons/ICO-CAMINATA.svg";
import { iconReview, iconReviewColor, mestraining } from "./helpPerfil";
import { SwiperPlan } from "../Dashboard/helpResponsive";
import { whatMonth, whatMonthONturn, whatWeek } from "../TutorialReserva/stepsHelp";


const PlanTraining = (props) => {
  const [modalInfo, setModalInfo] = useState(null);
  const [modalInfoCardio, setModalInfoCardio] = useState(null);
  const [objetives_user, setobjetives_user] = useState(null);
  const [userData, setUserData] = useState([]);
  const [objetivos_prom, setobjetivos_prom] = useState("--- dias");
  const [pasos_prom, setpasos_prom] = useState("---");
  const [calorias_prom, setcalorias_prom] = useState("---");
  const navigate = useNavigate();
  const location = useLocation();
  const [mesPrograma, setmesPrograma] = useState([]);
  const [currentDay, setcurrentDay] = useState(0);
  const [curretnWeek, setcurretnWeek] = useState(1);
 const stepIndex = props.stepIndex || 0;

 const hideModal = () => {
   setModalInfo(null);
 };

 useEffect(() => {
   instance
     .get(`memberships/training_plan`)
     .then((response) => {
       let dataRes = response.data;

       if (
         dataRes.personal_training_plan &&
         JSON.parse(dataRes.personal_training_plan) > 0
       ) {
         setmesPrograma(JSON.parse(dataRes.personal_training_plan));
       } else {
         setmesPrograma(dataRes.training_plan);
       }
     })
     .catch((error) => {
       console.log(error);
     });
   instance
     .get("users/current/")
     .then((response) => {
       setUserData(response.data);

       setcurrentDay(
         whatMonthONturn(response.data.membership.current_plan_day)
       );
       setcurretnWeek(whatWeek(response.data.membership.current_plan_day));
     })
     .catch((error) => {
       console.log(error);
     });
 }, [location.pathname]);

  function plural(valor){
if(valor == 1) {return "día"}
else return "días"
  }

  useEffect(() => {
    let dias= objetives_user?.days_activity == 0 ? "--- días": objetives_user?.days_activity + + ' '+ plural(objetives_user?.days_activity)
    setobjetivos_prom(dias);
    setpasos_prom(objetives_user?.distance == 0 ? "---": objetives_user?.distance)
    setcalorias_prom(objetives_user?.kcal == 0 ? "---": objetives_user?.kcal)

}, [objetives_user]);

 
const arrayPrograma =[{
  semana:1,
  contenido:[{name:"Valoración física",icon:<ValoraIco width={"100%"} height={"100%"}/>},
  {name:"Valoración nutrición",icon:<NutriIcon width={"100%"} height={"100%"}/>},
  {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>},
  {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}
]
},{
  semana:2,
  contenido:[{name:"Pilates",icon:<VideoIcon width={"100%"} height={"100%"}/>},
  {name:"Hipertensión",icon:<TrainIcon width={"100%"} height={"100%"}/>},
  
  {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}]
},
{
  semana:3,
  contenido:[ {name:"Hipertensión",icon:<TrainIcon width={"100%"} height={"100%"}/>},
  {name:"Pilates",icon:<VideoIcon width={"100%"} height={"100%"}/>},
 {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}]
},
{
  semana:4,
  contenido:[    {name:"Hipertensión",icon:<TrainIcon width={"100%"} height={"100%"}/>},
  {name:"Pilates",icon:<VideoIcon width={"100%"} height={"100%"}/>},
  {name:"Plan Cardio",icon:<CardioIcon width={"100%"} height={"100%"}/>}]
}
];
function programaTraining() {
  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 9999999,
    whiteSpace: "pre-line",
  };

  setModalInfo(
   laptop575.matches?null: <div
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div className="modalInfoContenido" style={{width: "calc(65rem + 2vmin)",maxWidth:"100%"}}>
      <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={()=>setModalInfo(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
      
        
          <div className="title mb-3 py-1 col-7 fw-light" style={{lineHeight:"100%",textAlign:"left",fontSize:"1.3rem"}}>
          <strong>Programa</strong> de entrenamiento</div> 
        <div className="col-12 d-flex flex-column">
        
        <SwiperPlan userData={userData} mesPrograma={mesPrograma} CardioTraining={(e)=>CardioTraining(e)} anchoGrid={"100%"} />
 <PerfectScrollbar style={{height:"10rem"}}>
<div className="mx-auto" style={{padding:"0 3%",width:"100%", display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)"/* Divide los elementos en dos columnas */
 }}>


{/* ini */}
{mestraining.map(day=><div className="d-flex my-2" style={{alignItems:"center"}}>
<div className="col-3 d-flex flex-column m-auto justify-content-center"  style={{background:day.color,borderRadius:"100%", width:"40px",height:"40px",alignItems:"center"}}>
<div className="d-flex flex-column m-auto justify-content-center"  style={{width:"25px",height:"25px"}}>{day.icon}</div></div>
<div className="mx-1 w-100" style={{fontSize:"calc(0.6rem + 0.5vmin)",textAlign:"left",lineHeight:"100%"}}>{day.name}</div></div>)}
{/* out */}
</div></PerfectScrollbar>


</div>



<div className="btnAction_pruebas letraspopfi2" style={{width:"100%"}}>
              <ButtonMob   handleClick={()=>setModalInfo(null)}
                colorButton={"#9EACED"}
                text="Aceptar"
                className={
                  laptop575.matches
                    ? "mb-3 mt-3 letraspopfi2actualizar"
                    : " letraspopfi2actualizar py-2"
                }
              />
            </div>
     
      </div>
    </div>
  );
}

function CardioTraining(elemento) {

  let e=elemento[0];

  let styleModal = {
    display: "flex",
    position: "fixed",
    zIndex: 99999999,
    whiteSpace: "pre-line",
  };
  setModalInfoCardio(
    <div
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div className="modalInfoContenido" style={{maxWidth:"100%",width:"calc(100vw - 10%)"}}>
      <div
          className=""
          style={{
            position: "absolute",
            top: "5%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={()=>setModalInfoCardio(null)}
        >
          <i className="bi bi-x-circle"></i>
        </div>
      
        
          <div className="title col-12 mb-3" style={{lineHeight:"100%",textAlign:"center",fontSize:"1rem"}}>Programa de Cardio / Mes {whatMonth(elemento[1])}</div> 
        <div className="col-12 d-flex justify-content-center my-2">
        <button
                      className="mx-auto d-flex relative"
                      style={{
                        color: "var(--white)",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "var(--elevva)",
                        borderRadius: "20px",
                        border: "1px solid var(--elevva)",
                       
                      }}
                     
                         
                  
                    >
                     Día plan entrenamiento: {elemento[1]}
                    </button>
                    <button
                      className="mx-auto d-flex relative"
                      style={{
                        color: "var(--white)",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "var(--elevva)",
                        borderRadius: "20px",
                        border: "1px solid var(--elevva)",
                       
                      }}
                      
                         
                  
                    >
                {e.label.replace("Cardio","")}
                    </button>

</div>
<div className="d-flex m-auto  " style={{background:"#F4F0ED",borderRadius:"40px",padding:"5% 2%",minWidth:"20rem"}}>
<div className="m-auto">
<div className="col-3 d-flex flex-column m-auto justify-content-center"  style={{background:"var(--elevva)",borderRadius:"100%", width:"50px",height:"50px",alignItems:"center"}}>
<div className="d-flex flex-column m-auto justify-content-center"  style={{width:"25px",height:"25px"}}><IconCaminar/></div>
</div></div>

<div className="d-flex flex-column m-auto " style={{textAlign:"left"}}>
<div className="fw-bold">{e.detail.type}</div>
<div className="m-auto d-flex " style={{alignItems:"center"}}><IconClock/><span className="ms-1"> {e.detail.duration} min</span></div>
</div>
<div className="d-flex flex-column m-auto" style={{textAlign:"left"}}>
<div className="my-auto d-flex " style={{alignItems:"center"}}><IconIntensity/><span className="ms-1"> Intensidad {e.detail.intensity}</span></div>
<div className="my-auto mx-1 d-flex " style={{alignItems:"center"}}><IconCaminata/><span className="ms-1"> {e.detail.type}</span></div>


</div>

</div>
<div className="col-12 d-flex justify-content-center my-2">
<button
                      className="mx-auto d-flex relative col-6"
                      style={{
                        color: "#394F65",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "start",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "#F4F0ED",
                        borderRadius: "20px",
                        border: "1px solid #F4F0ED",
                       
                      }}
                     
                         
                  
                    >
               Metodología
                    </button>
                    <button
                      className="mx-auto d-flex relative col-5"
                      style={{
                        color: "var(--white)",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "var(--elevva)",
                        borderRadius: "20px",
                        border: "1px solid var(--elevva)",
                       
                      }}
                        
                         
                  
                    >
               {e.detail.method}
                    </button>


</div>
<div className="col-12 d-flex justify-content-center my-2">
<button
                      className="mx-auto d-flex relative col-6"
                      style={{
                        color: "#394F65",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "start",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "#F4F0ED",
                        borderRadius: "20px",
                        border: "1px solid #F4F0ED",
                       
                      }}
                        
                         
                  
                    >
               Objetivo
                    </button>
                    <button
                      className="mx-auto d-flex relative col-5"
                      style={{
                        color: "var(--white)",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "var(--elevva)",
                        borderRadius: "20px",
                        border: "1px solid var(--elevva)",
                       
                      }}
                        
                         
                  
                    >
                   {e.detail.target}
                    </button>


</div>
<div className="col-12 d-flex justify-content-center my-2">
<button
                      className="mx-auto d-flex relative col-6"
                      style={{
                        color: "#394F65",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "start",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "#F4F0ED",
                        borderRadius: "20px",
                        border: "1px solid #F4F0ED",
                       
                      }}
                        
                         
                  
                    >
           Zona de frecuencia cardiaca
                    </button>
                    <button
                      className="mx-auto d-flex relative col-5"
                      style={{
                        color: "var(--white)",
                        fontSize:"calc(0.6rem + 0.5vmin)",
                        padding: "2% 5%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "var(--elevva)",
                        borderRadius: "20px",
                        border: "1px solid var(--elevva)",
                       
                      }}
                        
                         
                  
                    >
                   {e.detail.hr_zone}
                    </button>


</div>
<div className="title col-12 my-2" style={{lineHeight:"100%",textAlign:"center",fontSize:"1rem"}}>Observaciones</div> 
<PerfectScrollbar className="title col-12 my-2 mx-auto" style={{lineHeight:"100%",textAlign:"center",maxWidth:"20rem"}}><span style={{fontSize:"0.8rem",color:"#9DACBD",}}>{e.detail.notes}   </span></PerfectScrollbar> 

      </div>
    </div>
  );
}

  return(<> {modalInfo} {modalInfoCardio}

<div className="mx-3 my-3 d-flex">
   <button onClick={()=>navigate(-1)} className={"button_w_h_back"} style={{background:"transparent",border:"none",}}>
   <BackArrow width={"100%"} height={"100%"} /></button><span className="title my-auto fw-light" style={{fontSize:"1.2rem"}}>Plan <strong>de entrenamiento</strong></span>     
      

      </div>
      <div className=" mx-auto"
  style={{
    width: "100%",
    background:"#F9F9F9",
   
    padding: "2%",
    gap: "2%",
  }}>
     
      </div>
      <div
  
  className=" mx-auto d-flex flex-column"
  style={{
    width: "100%",
    background:"#F9F9F9",
   
    padding: "2%",
    gap: "2%",
  }}
>
{arrayPrograma.map((container,indice)=>(<div className="d-flex flex-column my-1"  style={{background:"var(--programa-training)",borderRadius:"20px",width:"100%",minHeight:"calc(10rem + 1vmin)",height:"15rem", padding:"2% 0"}}>
<div id={indice ==0?"PlanJoyce":""} className="d-flex col-12 h-100" style={{overflow:"hidden"}}>
<div className="col-6 d-flex justify-content-center h-100" style={{alignItems:"center"}}>
<span className="letraPequeña fw-light">{"Semana " + (container.semana - - curretnWeek)}</span>
</div>
<div class="dotted-line-container my-2">
  <div class="dotted-line-vertical"></div>
  <div class="dot"></div>
</div>

{stepIndex ==6 ?<div className="col-6 d-flex flex-column justify-content-center h-auto" style={{alignItems:"center",height:"calc(26vh + 6vmin)"}}>
{container.contenido.map((item,index)=>(
  <div className="d-flex  col-10 mx-auto my-1" >

  <div className="col-3 d-flex flex-column m-auto justify-content-center"  style={{background:index==0?"#70AAD8":index==1?"#4B6380":"var(--elevva)",borderRadius:"100%", width:"30px",height:"30px",alignItems:"center"}}  >
  <div className="d-flex flex-column m-auto justify-content-center"  style={{width:"12px",height:"12px"}}>{item.icon}</div></div>
  <div className="col-8 m-auto" ><div className="letraPequeña" style={{textAlign:"left"}}>{item.name}</div> </div>
  </div>
  ))}
</div>:<PerfectScrollbar 
className="col-6 d-flex flex-column justify-content-stretch " style={{alignItems:"center",height:"calc(26vh + 6vmin)"}} >
{userData?.membership?.training_plan.slice((indice *7) + currentDay,((indice *7) + currentDay) + 7).map((item)=>(
 
  <div className="d-flex  col-10 mx-auto my-1">

  <div className="col-3 d-flex flex-column m-auto justify-content-center"  style={{background:iconReviewColor(item.type),borderRadius:"100%", width:"30px",height:"30px",alignItems:"center"}}  >
  <div className="d-flex flex-column m-auto justify-content-center"  style={{width:"12px",height:"12px"}}>{iconReview(item.type)}</div></div>
  <div className="col-8 m-auto" ><div className="letraPequeña" style={{textAlign:"left"}}>{item.label}</div> </div>
  </div>
  ))}
  </PerfectScrollbar>}


</div>



</div>))}
  
  </div>


  <button
                        className="m-auto d-flex position-fixed"
                        style={{
                          color: "var(--white)",
                      
                          padding: "2% 5%",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          verticalAlign: "middle",
                          background: "var(--elevva)",
                          borderRadius: "20px",
                          border: "1px solid var(--elevva)",
                          fontSize: "1rem",
                          left: "50%",
                          top:"90%",
    transform: "translateX(-50%)"
                        }}
                        onClick={programaTraining}
                           
                    
                      >
                        Ver más <i className="bi bi-chevron-right"></i>
                      </button>

  <br/><br/>
    </>
  )
}
  export default PlanTraining;