import React from "react";
import {
  ButtonMob,
  instance,
  laptop575,
  ModalInfo,
  TitleRow,
} from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import elevva from "../../assets/img/general/logo_alta.png";
import { BarLoader } from "react-spinners";
import { ModalConoceme } from "../Modal/ModalConoceme";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Moment, { defineLocale, months } from "moment";
import { useLocation } from "react-router-dom";
import "./stepsHelp.css";
import { extendMoment } from "moment-range";
import axios from "axios";
import { Allvideos } from "../grupales/utils";

export const Categorias = (props) => {
  const navigate = useNavigate();
  const categoryClick = props.categoryClick;
  const categorias =
    localStorage.membershipCompanyElevva &&
    localStorage.email &&
    localStorage.mobile_numberMob
      ? [
          [
            "Clases",
            "presenciales",
            process.env.PUBLIC_URL + "/assets/img/CLASES_PRESENCIALES.jpg",
            true,
            null,
          ],
          [
            "Clases",
            "grupales",
            process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
            true,
            null,
          ],
          [
            "Intensidad",
            "baja",
            process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
            false,
            10,
          ],
          [
            "Intensidad",
            "media",
            process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
            false,
            20,
          ],
          [
            "Intensidad",
            "alta",
            process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
            false,
            30,
          ],
        ]
      : [
          [
            "Clases",
            "grupales",
            process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
            true,
            null,
          ],
          [
            "Intensidad",
            "baja",
            process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
            false,
            10,
          ],
          [
            "Intensidad",
            "media",
            process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
            false,
            20,
          ],
          [
            "Intensidad",
            "alta",
            process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
            false,
            30,
          ],
        ];
  return (
    <div
      id={"categoryReservaTutorial"}
      className="col-12 col-md-3"
      style={{
        background: "white",
        height: "100%",
        padding: "2%",
        borderRadius: "20px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="d-flex my-auto">
        <div
          onClick={() => navigate(`/Dashboard`)}
          className=" fw-bold booking-all"
          style={{
            background: "transparent",
            border: "1px solid #A8C2D7",
            padding: "2%",
            fontSize: "1vmin",
            height: "fit-content",
            cursor: "pointer",
            color: "#A8C2D7",
            borderRadius: "10px",
          }}
        >
          <span style={{ fontSize: "0.5vmin" }}>
            <i className="bi bi-chevron-left"></i>
          </span>
        </div>
        <span className="fw-bold my-auto ms-2 text_arrow_top">
          {" "}
          Mis reservas Elevva
        </span>
      </div>
      <hr />
      <div
        style={{ lineHeight: "110%", margin: "2vmin auto", textAlign: "left" }}
      >
        <span className="text_arrow_top">Selecciona</span>
        <br />
        <span className="fw-bold text_arrow_top">Categoría de interés</span>
      </div>
      <PerfectScrollbar style={{ height: "70vh" }}>
        {categorias.map((e, i) => (
          <div
            key={"cat" + i}
            className="position-relative mx-auto"
            style={{
              backgroundImage: `url(${e[2]})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderRadius: "20px",
              width: "100%",
              height: "18%",
              marginTop: "1.5vmin",
              marginBottom: "1.5vmin",
            }}
          >
            <div
              className="position-absolute "
              style={{
                color: "var(--elevvaTextTitle)",
                display: "flex",
                height: "100%",
                left: "5%",
                justifyContent: "center",
                flexDirection: "column",
                lineHeight: "90%",
              }}
            >
              <span className=" mb-1 text_arrow_top">{e[0]}</span>
              <div className="anchoPaso1_respon">
                <span className="letraPequeñaCardTitleBook fw-bold">
                  {e[1]}
                </span>
              </div>
            </div>

            <div
              className="position-absolute fw-bold booking-all"
              style={{
                background: "var(--backgroundOnboardingObjective)",
                bottom: "9%",
                right: "5%",
                padding: "2%",
                fontSize: "1vmin",
                color: "var(--white)",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <span
                style={{ fontSize: "0.5vmin" }}
                onClick={() =>
                  e[1] == "presenciales"
                    ? window.open(
                        `${localStorage.membershipCompanyElevva}${localStorage.email}&${localStorage.mobile_numberMob}`
                      )
                    : categoryClick([e[3], e[4]])
                }
              >
                <i className="bi bi-chevron-right"></i>
              </span>
            </div>
          </div>
        ))}
      </PerfectScrollbar>
    </div>
  );
};

export const CategoriasMobile = (props) => {
  const navigate = useNavigate();
  const indexStep = props.indexStep || null;
  const categoryClick = props.categoryClick;
  const categorias =
    localStorage.membershipCompanyElevva &&
    localStorage.email &&
    localStorage.mobile_numberMob
      ? [
          [
            "Clases",
            "presenciales",
            process.env.PUBLIC_URL + "/assets/img/CLASES_PRESENCIALES.jpg",
            true,
            null,
          ],
          [
            "Clases",
            "grupales",
            process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
            true,
            null,
          ],
          [
            "Intensidad",
            "baja",
            process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
            false,
            10,
          ],
          [
            "Intensidad",
            "media",
            process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
            false,
            20,
          ],
          [
            "Intensidad",
            "alta",
            process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
            false,
            30,
          ],
        ]
      : [
          [
            "Clases",
            "grupales",
            process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
            true,
            null,
          ],
          [
            "Intensidad",
            "baja",
            process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
            false,
            10,
          ],
          [
            "Intensidad",
            "media",
            process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
            false,
            20,
          ],
          [
            "Intensidad",
            "alta",
            process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
            false,
            30,
          ],
        ];
  return (
    <div
      id={"categoryReservaTutorialMobile"}
      className="col-12 col-md-3"
      style={{
        background: "white",
        height: "100%",
        padding: "2%",

        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="d-flex my-auto">
        <div
          onClick={() => navigate(`/Dashboard`)}
          className=" fw-bold booking-all"
          style={{
            background: "transparent",
            border: "1px solid #A8C2D7",
            padding: "2%",
            fontSize: "1vmin",
            height: "fit-content",
            cursor: "pointer",
            color: "#A8C2D7",
            borderRadius: "10px",
          }}
        >
          <span style={{ fontSize: "0.5vmin" }}>
            <i className="bi bi-chevron-left"></i>
          </span>
        </div>
        <span className="fw-bold my-auto ms-2 text_arrow_top">
          {" "}
          Mis reservas Elevva
        </span>
      </div>
      <hr />
      <div
        style={{
          lineHeight: "110%",
          margin: "2vmin auto",
          textAlign: "left",
          width: "90%",
        }}
      >
        <span className="text_arrow_top">Selecciona</span>
        <br />
        <span className="fw-bold text_arrow_top"> Categoría de interés</span>
      </div>
      <div
        id={"All_categories"}
        className="d-flex d-md-none mobile-welcome-login-row justify-content-stretch  text-center w-100 "
        style={{ top: "25%", height: "60vh" }}
      >
        <PerfectScrollbar className="">
          {categorias.map((e, i) => (
            <div
              onClick={() =>
                e[1] == "presenciales"
                  ? window.open(
                      `${localStorage.membershipCompanyElevva}${localStorage.email}&${localStorage.mobile_numberMob}`
                    )
                  : categoryClick([e[3], e[4]])
              }
              id={i == 0 ? "first_category" : "second_category"}
              key={"cat" + i}
              className="position-relative mx-auto"
              style={{
                backgroundImage: `url(${e[2]})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: "20px",
                width: "90%",
                height: "23%",
                marginTop: "1.5vmin",
                marginBottom: "1.5vmin",
              }}
            >
              <div
                className="position-absolute "
                style={{
                  color: "var(--elevvaTextTitle)",
                  display: "flex",
                  height: "100%",
                  left: "5%",
                  justifyContent: "center",
                  flexDirection: "column",
                  lineHeight: "90%",
                  textAlign: "left",
                }}
              >
                <span className=" mb-1 text_arrow_top">{e[0]}</span>
                <div className="anchoPaso1_respon">
                  <span
                    className="letraPequeñaCardTitleBook"
                    style={{ fontWeight: "600" }}
                  >
                    {e[1]}
                  </span>
                </div>
              </div>

              <div
                className="position-absolute fw-bold booking-all"
                style={{
                  background: "var(--backgroundOnboardingObjective)",
                  bottom: "9%",
                  right: "5%",
                  padding: "2%",
                  fontSize: "1vmin",
                  color: "var(--white)",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-right"></i>
                </span>
              </div>
            </div>
          ))}
        </PerfectScrollbar>
      </div>
    </div>
  );
};
export const CategoriasEmpty = (props) => {
  const navigate = useNavigate();

  return (
    <div
      id={"categoryReservaTutorial"}
      className="col-md-3"
      style={{
        background: "white",
        height: "100%",
        padding: "2%",
        borderRadius: "20px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="d-flex my-auto">
        <div
          onClick={() => navigate(-1)}
          className=" fw-bold booking-all"
          style={{
            background: "transparent",
            border: "1px solid #A8C2D7",
            padding: "2%",
            fontSize: "1vmin",
            height: "fit-content",
            cursor: "pointer",
            color: "#A8C2D7",
            borderRadius: "10px",
          }}
        >
          <span style={{ fontSize: "0.5vmin" }}>
            <i className="bi bi-chevron-left"></i>
          </span>
        </div>
        <span
          className="fw-bold my-auto ms-2"
          style={{
            color: "var(--elevvaTextTitle)",
            fontSize: "calc(0.8rem + 0.3vw)",
          }}
        >
          {" "}
          Mis reservas Elevva
        </span>
      </div>
      <hr />
    </div>
  );
};
export const Paso1 = (props) => {
  const indexStep = props.indexStep || 0;
  const typeClasses = props.typeClasses;
  const trainings = props.trainings;
  const intensity = props.intensity || null;
  const loading = props.loading || false;
  const handleClick = props.handleClick;
  const backClick = props.backClick;
  const idJoyride = props.idJoyride;

  if (loading) {
    return (
      <div
        className="d-flex col-12 col-md-9"
        style={{
          background: "white",
          height: "100%",
          overflow: "hidden",
          borderRadius: "20px",
          flexDirection: "column",
          justifyContent: "center",
          gap: "calc(1rem + 0.8vmin)",
        }}
      >
        {" "}
        <Loading />{" "}
      </div>
    );
  }

  return (
    <>
      <div
        className="d-none d-md-flex  col-9"
        style={{
          background: "white",
          height: "100%",
          overflow: "hidden",
          borderRadius: "20px",
          flexDirection: "column",
          justifyContent: "center",
          gap: "calc(1rem + 0.8vmin)",
        }}
      >
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {" "}
          <div className="d-flex flex-column" style={{ padding: "0 5%" }}>
            <span
              style={{
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(1.5rem + 0.4vw)",
              }}
            >
              {" "}
              Selecciona
            </span>
            <span
              style={{
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(1.5rem + 0.4vw)",
              }}
            >
              {" "}
              <strong> tu disciplina de interés</strong>
            </span>

            {/* <div
              id={"filtrosTutorial"}
              className=" d-flex  justify-content-between my-2"
              style={{ height: "calc(2.5rem + 0.4vw)", width: "80%" }}
            >
              {" "}
              <input
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "calc(0.7rem + 0.3vmin)",
                  height: "auto",
                  width: "50vmin",
                }}
                placeholder="Busca tu disciplina"
                className="redondo-butoonSearch px-2"
              ></input>
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5%",
                  height: "auto",
                  width: "10vmin",
                }}
              >
                <img
                  style={{
                    maxWidth: "4vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/filter_button.png"}
                ></img>
                <span style={{ fontSize: "1.5vmin" }}>Filtrar</span>
              </button>
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5%",
                  height: "auto",
                  width: "10vmin",
                }}
              >
                <img
                  style={{
                    maxWidth: "4vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/order_button.png"}
                />
                <span style={{ fontSize: "1.5vmin" }}>Ordenar</span>
              </button>
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",

                  height: "auto",
                  width: "10vmin",
                }}
              >
                <span
                // onClick={() => navigate(`/Individuales`)}
                >
                  <i
                    className="bi bi-chevron-right"
                    style={{ color: "#b6c0ce" }}
                  ></i>
                </span>
              </button>
            </div> */}
          </div>
        </div>

        <div className="container p-0">
          <div
            className="col-12  mx-auto videosTutotialalto"
            style={{ background: "#FDF1E8", height: "100%" }}
          >
            <Allvideos
              stepIndex={indexStep}
              groupTraining={typeClasses}
              spaceBetween={0}
              ancho={"auto"}
              grow={"growingTutorial"}
              alto={"calc(19rem - 0.7vw)"}
              widthSwiper={"80%"}
              id="numbers"
              idJoyride={"totalClasesTutorial"}
              buttonClick={(e) => handleClick(e)}
              textTitleOne={"Reserva tu primer "}
              textTitleTwo={"clase grupal en vivo gratis"}
              intensity={intensity}
              //trainingsId={trainingsId}
              videos={trainings && trainings}
              className="d-flex justify-content-center flex-column"
            />
          </div>
        </div>
      </div>

      <div
        className="d-flex d-md-none col-12"
        style={{
          background: "white",
          height: "100%",
          overflow: "hidden",

          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="d-flex my-auto p-3">
          <div
            onClick={backClick}
            className=" fw-bold booking-all"
            style={{
              background: "transparent",
              border: "1px solid #A8C2D7",
              padding: "2%",
              fontSize: "1vmin",
              height: "fit-content",
              cursor: "pointer",
              color: "#A8C2D7",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "0.5vmin" }}>
              <i className="bi bi-chevron-left"></i>
            </span>
          </div>
          <span className="fw-bold my-auto ms-3 text_arrow_top">
            {" "}
            Mis reservas Elevva
          </span>
        </div>
        <hr />
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {" "}
          <div className="d-flex flex-column" style={{ padding: "0 5%" }}>
            <span
              style={{
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(1.5rem + 0.4vw)",
              }}
            >
              {" "}
              Selecciona
            </span>
            <span
              style={{
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(1.5rem + 0.4vw)",
              }}
            >
              {" "}
              <strong> tu disciplina de interés</strong>
            </span>

            {/* <div
              id={"filtrosTutorialMob"}
              className=" d-flex  justify-content-between my-2"
              style={{ height: "calc(3rem + 0.4vw)", width: "100%" }}
            >
              {" "}
              <input
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",

                  height: "auto",
                  width: "50vmin",
                }}
                placeholder="Busca tu disciplina"
                className="redondo-butoonSearch px-2"
              ></input>
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5%",
                  height: "auto",
                  width: "3rem",
                }}
              >
                <img
                  style={{
                    maxWidth: "4vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/filter_button.png"}
                ></img>
                <span style={{ fontSize: "0.7rem", color: "#9DACBD" }}>
                  Filtrar
                </span>
              </button>
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5%",
                  height: "auto",
                  width: "3rem",
                }}
              >
                <img
                  style={{
                    maxWidth: "4vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/order_button.png"}
                />
                <span style={{ fontSize: "0.7rem", color: "#9DACBD" }}>
                  Ordenar
                </span>
              </button>
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",

                  height: "auto",
                  width: "3rem",
                }}
              >
                <span
                // onClick={() => navigate(`/Individuales`)}
                >
                  <i
                    className="bi bi-chevron-right"
                    style={{ color: "#b6c0ce" }}
                  ></i>
                </span>
              </button>
            </div> */}
          </div>
        </div>

        <div className="container p-0">
          <div
            className="col-12  mx-auto videosTutotialalto"
            style={{ background: "#FDF1E8", height: "100%" }}
          >
            <Allvideos
              stepIndex={indexStep}
              groupTraining={typeClasses}
              spaceBetween={0}
              ancho={"auto"}
              grow={"growingTutorial"}
              alto={"calc(19rem - 0.7vw)"}
              widthSwiper={"70%"}
              rightArrow={"0"}
              id="numbers"
              idJoyride={idJoyride}
              buttonClick={(e) => handleClick(e)}
              textTitleOne={"Reserva tu primer "}
              textTitleTwo={"clase grupal en vivo gratis"}
              intensity={intensity}
              //trainingsId={trainingsId}
              videos={trainings && trainings}
              className="d-flex justify-content-center flex-column"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Paso2 = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const infoTraining = props.infoTraining || null;
  const recomendaciones = props.recomendaciones || false;
  const tutorial = props.tutorial;

  const [entrenamientos_grupales, setentrenamientos_grupales] = useState(null);
  useEffect(() => {
    if (tutorial == false && infoTraining) {
      const inicioday = Moment().format().slice(0, 11) + "00:00";

      instance
        .get(`/booking/group/${infoTraining.id}/schedule/`)
        .then((res) => {
          const data = res.data.filter((e) => e.schedule_date >= inicioday);
          if (data) {
            setentrenamientos_grupales(data);
          }
        })
        .catch((err) => {});
    }
  }, [infoTraining]);

  const ButtonTrainerStart = (props) => {
    const infoTraining = props.infoTraining;
    const classes = props.classes || null;
    const sizeFont = props.sizeFont || null;
    return (
      <div className={classes}>
        <button
          className="me-1"
          style={{
            background: "#F4F0ED",
            border: "1px solid #F4F0ED",
            borderRadius: "20px",
            color: "#394F65",
            fontWeight: "300",
            padding: "1% 8%",
            fontSize: sizeFont,
          }}
        >
          {/* 45min */}
          {infoTraining && infoTraining.time} {""} {"Min"}
        </button>
        <button
          className="mx-1"
          style={{
            background: "#F4F0ED",
            border: "1px solid #F4F0ED",
            borderRadius: "20px",
            color: "#394F65",
            fontWeight: "300",
            padding: "1% 8%",
            fontSize: sizeFont,
          }}
        >
          {/* 500 cal */}
          {infoTraining && infoTraining.calories} {""}
          {"Cal"}
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (infoTraining) {
      setIsLoading(false);
    }
  }, [infoTraining]);

  return (
    <>
      <div
        className=" d-none d-md-flex col-9"
        style={{
          background: "white",
          height: "100%",

          borderRadius: "20px",

          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div
              className="col-6 d-flex"
              style={{
                position: "relative",
                justifyContent: "center",
                padding: "5%",
              }}
            >
              <img
                style={{
                  margin: "0 4%",
                  width: "96%",
                  backgroundImage: "cover",borderRadius:"20px",
                  objectFit: "cover",
                }}
                src={
                  infoTraining && infoTraining.cover_picture
                  // Aquí se pasa la propiedad cover_picture del objeto
                }
                alt=""
              />
              <TitleRow
                infoTraining={infoTraining}
                handleclick={() => navigate(-1)}
              />
            </div>

            <div
              className="col-6 d-flex h-100"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                gap: "2%",
                padding: "5% 5% 5% 0",
              }}
            >
              <PerfectScrollbar
                className="pe-3 d-flex flex-column justify-content-center"
                style={{ height: "90%", gap: "1rem" }}
              >
                <ButtonTrainerStart
                  infoTraining={infoTraining}
                  sizeFont={"auto"}
                />

                <div
                  className="my-2 d-flex flex-column"
                  style={{ gap: "1rem" }}
                >
                  <span
                    style={{
                      color: "#394F65",
                      fontWeight: "400",
                      fontSize: "calc(0.9rem + 0.3vw)",
                    }}
                  >
                    Metodologia
                  </span>
                  <span
                    style={{
                      color: "#A9B6C4",

                      fontSize: "calc(0.9rem + 0.3vmin)",
                    }}
                  >
                    Disciplina {infoTraining.group ? "GRUPAL" : "individual"}
                  </span>
                </div>

                <div
                  className="d-flex flex-column my-2"
                  style={{ gap: "1rem" }}
                >
                  <span
                    style={{
                      color: "#394F65",
                      fontWeight: "400",
                      fontSize: "calc(0.9rem + 0.3vw)",
                    }}
                  >
                    Información
                  </span>

                  <span
                    style={{
                      color: "#A9B6C4",

                      fontSize: "calc(0.9rem + 0.3vmin)",
                    }}
                  >
                    {infoTraining.description}{" "}
                  </span>
                </div>

                {infoTraining.group ? (
                  <span
                    style={{
                      color: "#394F65",
                      fontWeight: "400",
                      fontSize: "calc(0.7rem + 0.3vw)",
                    }}
                  >
                    {/* Cupo disponible: 20 personas */}
                    {tutorial ? (
                      <p>Cupos disponibles:20 personas</p>
                    ) : (
                      <p>
                        {entrenamientos_grupales &&
                        entrenamientos_grupales.length > 0
                          ? ` ${
                              entrenamientos_grupales[0].capacity -
                              entrenamientos_grupales[0].current_capacity
                            }  ${
                              entrenamientos_grupales[0].capacity -
                                entrenamientos_grupales[0].current_capacity >
                              1
                                ? "cupos disponibles"
                                : "cupo disponible"
                            }`
                          : "Sin cupos disponibles"}
                      </p>
                    )}
                  </span>
                ) : null}
              </PerfectScrollbar>
              <div className="my-2" style={{ height: "10%" }}>
                <ButtonMob
                  handleClick={() =>
                    infoTraining.group
                      ? navigate(`/selectGrupalList/${infoTraining.id}`)
                      : navigate(`/selectTeacher/${infoTraining.id}`)
                  }
                  id={"tutorialButtonReserva"}
                  icon={"ms-2 bi bi-calendar3 right"}
                  className="marginButtonReserva"
                  text="Reservar"
                />
              </div>
            </div>
          </>
        )}
      </div>
      {/* mobile */}
      {isLoading ? (
        <Loading />
      ) : (
        <div
          className="d-md-none mobile-login w-100"
          style={{ height: "100%" }}
        >
          <div
            className="containerBGClase"
            style={{
              backgroundSize: "cover",
              height: "45vh",
              backgroundImage: `url(${
                infoTraining && infoTraining.main_picture
              })`,
            }}
          >
            <div
              className="w-auto m-auto d-flex justify-content-center"
              style={{
                position: "absolute",
                top: "5%",
                left: "50%",
                height: "7%",
                transform: "translate(-50%, 5%)",
                alignItems: "center",
              }}
            >
              {" "}
              <img
                style={{ width: "15vmin" }}
                src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
              />
            </div>
            <TitleRow
              infoTraining={infoTraining}
              handleclick={() => {
                navigate(`/reservas`);
                localStorage.elevvaPageReserva = 2;
              }}
            />
          </div>
          <div
            className="mobile-welcome-login-row  text-left w-100 "
            style={{ top: "40vh", justifyContent: "start" }}
          >
            <div className={"d-flex flex-column justify-content-center gap-3"}>
              <ButtonTrainerStart
                infoTraining={infoTraining}
                classes={"justify-content-center d-flex my-3"}
                sizeFont={"1rem"}
              />
              <div
                className="m-auto "
                style={{ width: "85%", textAlign: "justify" }}
              >
                <span
                  style={{
                    color: "#A9B6C4",

                    fontSize: "1rem",
                  }}
                >
                  {infoTraining && infoTraining.description}{" "}
                </span>

                {tutorial ? (
                  <p className="mt-2">Cupos disponibles:20 personas</p>
                ) : infoTraining.group ? (
                  <p className="mt-2">
                    {entrenamientos_grupales &&
                    entrenamientos_grupales.length > 0
                      ? ` ${
                          entrenamientos_grupales[0].capacity -
                          entrenamientos_grupales[0].current_capacity
                        }  ${
                          entrenamientos_grupales[0].capacity -
                            entrenamientos_grupales[0].current_capacity >
                          1
                            ? "cupos disponibles"
                            : "cupo disponible"
                        }`
                      : "Sin cupos disponibles"}
                  </p>
                ) : null}
              </div>
              <ButtonMob
                handleClick={() =>
                  infoTraining.group
                    ? navigate(`/selectGrupalList/${infoTraining.id}`)
                    : navigate(`/selectTeacher/${infoTraining.id}`)
                }
                id={"tutorialButtonReservaMobile"}
                icon={"ms-2 bi bi-calendar3 right"}
                text={"Reservar"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export function renderCorazones(total) {
  const corazones = [];
  const partes = total && total.split(".");

  const totalCorazones = 5;
  const corazonesPintados = partes[0] == "-1" ? 0 : partes[0];

  for (let i = 0; i < totalCorazones; i++) {
    if (i < Number(corazonesPintados)) {
      corazones.push(
        <i className="bi bi-award-fill mr-3" style={{ color: "#8c9ae7" }}></i>
      );
    } else {
      corazones.push(<i className="bi bi-award"></i>);
    }
  }

  return corazones;
}
export const Loading = (props) => {
  const heigthLoading = props.heigthLoading || "100vh";
  return (
    <div
      className="spinner-container-welcome flex-column w-100"
      style={{ height: heigthLoading }}
    >
      <img className="my-2" src={elevva} alt="Welcome" />
      <BarLoader color="#8d97fd" width={100} />
    </div>
  );
};
export const Paso6 = (props) => {
  const infoTraining = props.infoTraining || null;
  const profesores = props.profesores || null;
  const imageSuperior = props.imageSuperior;
  const [modalInfo, setModalInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  //renderizando input
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    if (infoTraining) {
      setIsLoading(false);
    }
  }, [infoTraining]);

  function handleRadioClick(e) {
    props.handlerClick(e.target.value);
    setSelected(e.target.value);
  }
  function handleRadioClicknuev(e) {
    // console.log(e);
    props.handlerClick(e);
    setSelected(e);
  }

  function handleClickTrainer(e) {
    return setModalInfo(
      <ModalConoceme
        handleCancellcargue={() => setModalInfo(null)}
        error="tru"
        infoId={e}
        handleClick={(e) => {
          setModalInfo(null);
          handleRadioClicknuev(e);
        }}
        text={
          "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
        }
        classesMsg=""
        renderCorazones={renderCorazones}
      />
    );
  }
  function handleClickTraMobile(idTrainer) {
    navigate(`/trainerInfo/${infoTraining.id}/${idTrainer}`);
  }

  const ComponentTeacher = (props) => {
    const idJoyride = props.idJoyride;

    function clickconoceme(event) {
      props.clickconoceme(event);
    }
    return (
      <PerfectScrollbar
        className="w-100"
        style={{ height: "60%", padding: "0 5%" }}
      >
        <div className=" classTeachers  w-100">
          <div
            key={"teacher-aleatorio"}
            id={idJoyride}
            className="d-flex  border-color justify-content-center paddingGrowTeacher w-100"
            style={{
              backgroundColor: selected == 1 ? "#DEE2F8" : "#f4f0ed",
              filter:
                selected == 1
                  ? " drop-shadow(0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5))"
                  : "",
              maxHeight: "calc(5rem + 0.5vw)",
            }}
          >
            <div className="col-11 col-md-12 d-flex justify-content-between align-items-center ">
              <span className="img-fluid mr-3" style={{ overflow: "hidden" }}>
                <img
                  className="w-100 h-100"
                  style={{ backgroundSize: "contain" }}
                  src={process.env.PUBLIC_URL + "/img/ENTRENADORES.png"}
                  alt="placeholder"
                />
              </span>
              <div className="col-5 ps-2" style={{ textAlign: "left" }}>
                <span className="textTeacherOne">Profesor aleatorio</span>
                <br />
                {/* <span className="textTeacherOne">1 crédito</span> */}

                <div className="d-flex align-items-center"></div>
              </div>
              <br />
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <button
                  style={{ border: "none" }}
                  className="m-auto d-flex "
                ></button>
              </div>
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2%",
                }}
              ></div>
              <div className="ml-3">
                <input
                  type="radio"
                  id="radio"
                  value={1}
                  name="teachers"
                  onClick={handleRadioClick}
                />
              </div>
            </div>
          </div>

          {profesores &&
            profesores.map((e, i) => (
              <div key={"teacher" + i}>
                <div
                  className="d-flex  border-color justify-content-center paddingGrowTeacher w-100"
                  style={{
                    backgroundColor: e.id == selected ? "#DEE2F8" : "#f4f0ed",
                    maxHeight: "calc(5rem + 0.5vw)",
                    filter:
                      e.id == selected
                        ? " drop-shadow(0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5))"
                        : "",
                  }}
                >
                  <div className="col-11 col-md-12 d-flex justify-content-between align-items-center ">
                    <span
                      className="img-fluid mr-3"
                      style={{ overflow: "hidden" }}
                    >
                      <img
                        className="w-100"
                        style={{
                          backgroundSize: "contain",
                          // transform: "translateY(-15%)",
                        }}
                        src={e.profile_pic}
                        alt="placeholder"
                      />
                    </span>

                    <div className="col-5 ps-2" style={{ textAlign: "left" }}>
                      <span className="textTeacherOne">{e.name}</span>
                      <br />
                      {/* <span className="textTeacherOne">2 créditos</span> */}
                      <div className="d-flex align-items-center">
                        {renderCorazones(e.current_rating)}
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: "2%",
                      }}
                    >
                      <button
                        value={e.id}
                        onClick={() => clickconoceme(e?.id)}
                        className="m-auto d-flex buttonVerPlan"
                      >
                        Conóceme
                      </button>
                    </div>
                    <div className="ml-3">
                      <input
                        type="radio"
                        id="radio"
                        value={e.id}
                        name="teachers"
                        onClick={handleRadioClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </PerfectScrollbar>
    );
  };

  //renderiza corazones de iconos

  return (
    <>
      {modalInfo}
      <div
        className="d-none d-md-flex col-9"
        style={{
          background: "white",
          height: "100%",
          alignItems: "center",
          borderRadius: "20px",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <div
            className="d-flex w-100"
            style={{
              background: "white",
              height: "85%",
              gap: "2%",
              alignItems: "center",
              borderRadius: "20px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex "
              style={{
                padding: "0 5%",
                height: "20%",
                width: "90%",
                justifyContent: "center",
                flexDirection: "column",
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/img/imagestatic-reservas.png"
                })`,
                backgroundSize: "cover",
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
                borderRadius: "20px",
              }}
            >
              <div className="d-flex my-auto">
                <div
                  onClick={() => navigate(-1)}
                  className=" fw-bold booking-all"
                  style={{
                    background: "transparent",
                    border: "1px solid #A8C2D7",
                    padding: "1%",
                    fontSize: "1vmin",
                    height: "fit-content",
                    cursor: "pointer",
                    color: "#A8C2D7",
                    borderRadius: "10px",
                    position: "relative",
                    left: "1%",
                  }}
                >
                  <span style={{ fontSize: "0.5vmin" }}>
                    <i className="bi bi-chevron-left"></i>
                  </span>
                </div>
                <div style={{ padding: "0% 3% 0% 2%", lineHeight: "220%" }}>
                  {" "}
                  <div
                    style={{
                      color: "#394F65",
                      fontSize: "4vmin",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "300",
                        color: "#394F65",
                        fontSize: "4vmin",
                      }}
                    >
                      Entrenamiento
                    </span>
                    <br />
                    <strong>{infoTraining.name}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex w-100"
              style={{
                flexDirection: "column",
                justifyContent: "center",
                height: "15%",
              }}
            >
              {" "}
              <div className="d-flex flex-column" style={{ padding: "0 5%" }}>
                <span
                  style={{
                    color: "var(--elevvaTextTitle)",
                    fontSize: "calc(1.0rem + 0.4vw)",
                  }}
                >
                  {" "}
                  <i className="bi bi-1-circle"></i> Selecciona{" "}
                  <strong> tu profesor</strong>
                </span>
              </div>
            </div>
            <ComponentTeacher
              idJoyride={"tutorialTeacher"}
              clickconoceme={(e) => handleClickTrainer(e)}
            />{" "}
          </div>
        )}
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div
          className="d-md-none mobile-login w-100"
          style={{ height: "100%" }}
        >
          <div
            className="containerBGClase"
            style={{
              backgroundSize: "cover",
              height: "30vh",
              backgroundImage: `url(${
                infoTraining && infoTraining.main_picture
              })`,
            }}
          >
            <div
              id="tutorialTeacherMobile"
              className="w-auto m-auto d-flex justify-content-center"
              style={{
                position: "absolute",
                top: "5%",
                left: "50%",
                height: "7%",
                transform: "translate(-50%, 5%)",
                alignItems: "center",
              }}
            >
              {" "}
              <img
                style={{ width: "15vmin" }}
                src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
              />
            </div>
            <TitleRow
              infoTraining={infoTraining}
              handleclick={() => navigate(-1)}
            />
          </div>
          <div
            className="mobile-welcome-login-row  text-center w-100"
            style={{ gap: "5%", top: "28vh", justifyContent: "stretch" }}
          >
            <span
              className="mt-4"
              style={{
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(1.3rem + 0.4vw)",
              }}
            >
              {" "}
              <i className="bi bi-1-circle"></i> Selecciona{" "}
              <strong> tu profesor</strong>
            </span>
            <ComponentTeacher
              idJoyride={"tutorialMobileEntre"}
              clickconoceme={(e) => handleClickTraMobile(e)}
            />
          </div>
        </div>
      )}
    </>
  );
};

const RenderMonths = (props) => {
  // const laptop = window.matchMedia("(max-width: 920px)");
  const months = props.months;
  const Trainer = props.Trainer;
  const selMonth = useRef(null);
  const [currentIndexMonth, setCurrentIndexMonth] = useState(0);

  const onSelectMonth = props.onSelectMonth;
  const selectMonth = (index) => {
    setCurrentIndexMonth(index);
  };
  function moveCurrentIndexMonth(newIndex) {
    if (newIndex < 0) newIndex = 0;
    else if (newIndex >= months.length) newIndex = months.length - 1;
    setCurrentIndexMonth(newIndex);
  }

  useEffect(() => {
    onSelectMonth(currentIndexMonth);
  }, [currentIndexMonth, Trainer]);

  return (
    <>
      <div className="months100 months">
        <div
          className="left"
          onClick={() => moveCurrentIndexMonth(currentIndexMonth - 1)}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </div>
        <div className="selMonth" ref={selMonth}>
          {months.map((m, index) => {
            const name = m.format("MMMM[,] YYYY");

            return (
              <div
                key={index}
                onClick={() => selectMonth(index)}
                id={index}
                className={currentIndexMonth === index ? "active" : "noactive"}
              >
                {name}
              </div>
            );
          })}
        </div>
        <div
          className="right"
          onClick={() => moveCurrentIndexMonth(currentIndexMonth + 1)}
        >
          <i className="bi bi-arrow-right-circle-fill"></i>
        </div>
      </div>
    </>
  );
};

const RenderDays = (props) => {
  const moment = extendMoment(Moment);
  const tutorialDay = props.tutorialDay || null;
  const selectedMonth = props.month;
  const [modalInfo, setModalInfo] = useState(null);
  const onSelectDay1 = props.onSelectDay1;

  const AllHours = props.AllHours;
  const zonahorairachange = props.zonahorairachange;

  const possibleDays = [];

  useEffect(() => {
    if (tutorialDay) {
      onSelectDay1(tutorialDay);
    }
  }, []);

  if (selectedMonth != null) {
    const startOfMonth = selectedMonth.clone().startOf("month");
    const endOfMonth = selectedMonth.clone().endOf("month");

    const range = moment.range(startOfMonth, endOfMonth);

    for (let day of range.by("day")) {
      if (day) {
        // only add the future days
        possibleDays.push(day);
      }
    }
  }

  const [displayLoading, setDisplayLoading] = useState(null);

  const possibleDaysy = {};

  if (selectedMonth == null) return null;
  const clickHandler = (event) => {
    let NUMEROdAY = event.target.dataset.user;

    let idx = event.target.dataset.indexNumber;

    // if (NUMEROdAY.slice(5, 7) == selectedMonth.format("MM")) {}
    function hideModal() {
      setModalInfo(null);
    }

    const boilaq = document.getElementById(`dayrounded${NUMEROdAY}`);

    if (NUMEROdAY < moment().format("YYYY-MM-DD")) {
      setModalInfo(
        <ModalInfo
          error="false"
          handleClick={hideModal}
          classcorrecta={"recuerdaTitle"}
          handleCancellcargue={hideModal}
          title="Recuerda"
          text="No puedes reservar antes de tu día actual"
          classesMsg="colorText_Modal"
        />
      );
    } else {
      if (boilaq.classList.contains("active")) {
        boilaq.classList.remove("active");
        const enviarFecha1 =
          (possibleDaysy[idx] =
          idx =
            [
              NUMEROdAY +
                " " +
                moment.tz(AllHours[0], zonahorairachange).format("LT"),
              null,
            ]);

        onSelectDay1(enviarFecha1);

        setTimeout(() => {
          setDisplayLoading(null);
        }, 700);
        event.preventDefault();
      } else {
        const enviarFecha =
          (possibleDaysy[idx] =
          idx =
            [
              NUMEROdAY +
                " " +
                moment.tz(AllHours[0], zonahorairachange).format("LT"),
              1,
            ]);

        onSelectDay1(enviarFecha);

        boilaq.classList.add("active");
        setTimeout(() => {
          setDisplayLoading(null);
        }, 700);
        event.preventDefault();
      }
    }
  };
  return (
    <>
      <div className=" days colorcajanumerodia ">
        <div
          className="selDays"
          style={{
            background: "white",
            width: "auto",
            fontWeight: "bold",
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
          }}
        >
          {possibleDays &&
            possibleDays.slice(0, 7).map((valueName, idx) => (
              <span key={idx} style={{ color: "#394F65" }}>
                {valueName.format("ddd").replace(".", "")}
              </span>
            ))}
        </div>
        <div className="selDays" key={"MES" + selectedMonth.format("MM")}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: " repeat(7, 1fr)",
              listStyle: "none",
              padding: "0",
            }}
          >
            {possibleDays
              ? possibleDays.map((value, idx) => {
                  const dayName = value.format("ddd");

                  const dayNumb = value.format("D");

                  return (
                    <span
                      key={"day" + idx}
                      data-user={value.format("YYYY-MM-DD")}
                      id={"dayrounded" + value.format("YYYY-MM-DD")}
                      data-index-number={idx}
                      className="rounded-circle"
                      onClick={clickHandler}
                    >
                      {dayNumb}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      {displayLoading}
      {modalInfo}
    </>
  );
};

const TotalReservas = (props) => {
  const id = props.idTrainer;
  const vistaMostrar = props.vistaMostrar || null;
  const AllHours = props.AllHours;
  const totalDays = props.totalDays;
  const zonahorairachange = props.zonahorairachange;
  const onSelectDay1 = props.onSelectDay1;
  const day = props.day;
  const indexStep = props.indexStep;
  const [modalInfo, setModalInfo] = useState(null);
  function hideModal() {
    setModalInfo(null);
  }
  const Trainer = props.Trainer;
  // const indiceDay = Object.keys(day);
  const moment = extendMoment(Moment);
  require("moment-timezone");

  const clickHandler = async (event) => {
    const evento = event.target.dataset.indexNumber.slice(0, 10);

    onSelectDay1([
      selectedDate1s[0].slice(0, 10) + " " + selectedDate1s[0].slice(11, 16),
      null,
    ]);
    // setselsetarTrue(false);
    const boilaq = document.getElementById(`dayrounded${evento}`);

    if (boilaq && boilaq.classList.contains("active")) {
      boilaq.classList.remove("active");
    }

    event.preventDefault();
  };

  const [selsetarTrue, setselsetarTrue] = useState(true);
  const [selectedDate1s, setSelectedDate1s] = useState(day);
  // const [TimeCaseLida, setTimeCaseLida] = useState(null);
  if (!day || day[1] == null) {
    totalDays(day);
  } else totalDays(selectedDate1s);

  useEffect(() => {
    if (selsetarTrue && zonahorairachange) {
      const date = selectedDate1s[0];
      const dateHour = date.slice(11, date.length);

      const trainerY = Trainer
        ? instance.get(
            `booking/pre-book/?trainer=${Trainer}&date=${date}&tz=${zonahorairachange}&training=${id}`
          )
        : instance.get(
            `booking/pre-book/?date=${date}&tz=${zonahorairachange}&training=${id}`
          );
      trainerY
        .then((resEn) => {
          if (resEn.data.cost == 1 && Trainer == null && dateHour != "1:00") {
            setModalInfo(
              <ModalInfo
                error="false"
                handleClick={hideModal}
                handleCancellcargue={hideModal}
                classcorrecta={"recuerdaTitle"}
                title="Recuerda"
                text=" Ten en cuenta que si reservas tu clase con más de 8 horas para el inicio de la misma, tendrá un valor de un crédito."
                classesMsg="colorText_Modal"
              />
            );
          } else if (
            resEn.data.cost == 2 &&
            Trainer == null &&
            dateHour != "1:00"
          ) {
            setModalInfo(
              <ModalInfo
                error="false"
                handleClick={hideModal}
                handleCancellcargue={hideModal}
                classcorrecta={"recuerdaTitle"}
                title="Recuerda"
                text=" Ten en cuenta que si reservas tu clase faltando entre 4 y 8 horas para el inicio de la misma, tendrá un valor de dos créditos
en lugar de uno."
                classesMsg="colorText_Modal"
              />
            );
          } else if (
            resEn.data.cost == 3 &&
            Trainer == null &&
            dateHour != "1:00"
          ) {
            setModalInfo(
              <ModalInfo
                error="false"
                handleClick={hideModal}
                handleCancellcargue={hideModal}
                classcorrecta={"recuerdaTitle"}
                title="Recuerda"
                text="Ten en cuenta que si reservas tu clase faltando entre 1 y 4 horas para el inicio de la misma, tendrá un valor de tres créditos en lugar de uno."
                classesMsg="colorText_Modal"
              />
            );
          }
          // alert(resEn.data.cost);
          if (resEn.data) {
            setselsetarTrue(false);
            setSelectedDate1s([
              selectedDate1s[0].slice(0, 10) +
                " " +
                selectedDate1s[0].slice(11, 16),
              resEn.data.cost,
            ]);
          }
        })
        .catch(async (err) => {
          if (
            err.response &&
            err.response.data[0].includes(
              "puede reservar con un profesor específico"
            )
          ) {
            setModalInfo(
              <ModalInfo
                error="false"
                handleClick={hideModal}
                handleCancellcargue={hideModal}
                classcorrecta={"recuerdaTitle"}
                sizeTitle={"1.3rem"}
                title="Recuerda"
                text="Si quieres reservar tu clase con tu profesor preferido, debes hacerlo con 24 horas de anticipación."
                classesMsg="colorText_Modal"
              />
            );
          }
          setselsetarTrue(false);
          setSelectedDate1s([
            selectedDate1s[0].slice(0, 10) +
              " " +
              selectedDate1s[0].slice(11, 16),
            err.response.data,
          ]);
        });
      setselsetarTrue(false);
    }
  }, [Trainer, selectedDate1s]);

  function handlechange(event, array) {
    // var timeStart = moment();
    // var timeEnd = moment(array[0].slice(0, 10) + " " + event);
    // console.log(array, event);
    setselsetarTrue(true);

    setSelectedDate1s([array[0].slice(0, 10) + " " + event, array[1]]);
    // var diff = timeEnd.diff(timeStart, "minutes");
    // setTimeCaseLida(diff);
  }
  if (selectedDate1s && selectedDate1s.length > 0 && day[1]) {
    return (
      <>
        <div
          id="clavelDese"
          className={
            vistaMostrar && vistaMostrar == 1
              ? "d-none "
              : "d-flex position-relative changecolortable my-3 "
          }
          style={
            indexStep && indexStep == 12
              ? {
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  textAlign: "center",
                  padding: "2%",
                  zIndex: "9999",
                }
              : {
                  justifyContent: "space-around",
                  borderRadius: "20px",
                  textAlign: "center",
                  padding: "2%",
                }
          }
        >
          <button
            key={"optiones" + selectedDate1s[0]}
            onClick={clickHandler}
            className=" d-flex justify-content-center"
            data-index-number={selectedDate1s}
            style={{
              alignItems: "center",
              background: "transparent",
              cursor: "pointer",
              color: "#A8C2D7",
              border: "none",
              width: "15%",
            }}
          >
            <span className="trashIcon_res">
              <i
                onClick={clickHandler}
                data-index-number={selectedDate1s}
                className="bi bi-trash3"
              ></i>
            </span>
          </button>
          <div
            className="d-flex "
            style={{
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "left",
              padding: "2%",
              width: "45%",
            }}
          >
            <span className="fw-bold my-auto titleOneBook">
              {" "}
              {moment
                .tz(`${selectedDate1s[0].slice(0, 10)}`, zonahorairachange)
                .format(" DD [de] MMMM ")}
            </span>

            {/* {typeof selectedDate1s[1] == "number" &&
              selectedDate1s[1] == 1 ? (
                `${selectedDate1s[1]} crédito`
              ) : typeof selectedDate1s[1] == "number" &&
                selectedDate1s[1] !== 1 ? (
                `${selectedDate1s[1]} créditos`
              ) : (
                <div className="no_reservar_error">NO SE PUDO RESERVAR</div>
              )} */}

            {typeof selectedDate1s[1] == "number" &&
            selectedDate1s[1] == 1 ? null : typeof selectedDate1s[1] ==
                "number" && selectedDate1s[1] !== 1 ? null : ( // {/* `${selectedDate1s[1]} créditos` */}
              <span className="fw-bold my-auto titleOneBook">
                <div className="no_reservar_error">NO SE PUDO RESERVAR</div>{" "}
              </span>
            )}
          </div>

          <div className="my-auto" style={{ width: "50%" }}>
            {day ? (
              <div
                className="input-select-step7 position-relative text-left"
                id="escogerhora"
                style={{ textAlign: "left" }}
              >
                <select
                  id={"select1" + day}
                  onChange={(e) => {
                    let timeStart = moment();
                    let convertDay = day[0].slice(0, 11);
                    let hourTarget = e.target.value;
                    let timeEnd = moment(convertDay + e.target.value);
                    let diff = timeEnd.diff(timeStart, "minutes");

                    if (
                      localStorage.membership &&
                      localStorage.membership == 26 &&
                      diff < 480
                    ) {
                      handlechange("-1:00", day);
                      setModalInfo(
                        <ModalInfo
                          error="false"
                          handleClick={hideModal}
                          handleCancellcargue={hideModal}
                          classcorrecta={"recuerdaTitle"}
                          sizeTitle={"1.3rem"}
                          title="Tu plan actual solo te permite reservar
con 8 horas de antelación"
                          text="Te invitamos a reservar tu clase en otro horario"
                          classesMsg="colorText_Modal"
                        />
                      );
                    } else {
                      handlechange(hourTarget, day);
                    }
                  }}
                  className="styleScroll widthHeight my-auto p-0 hourOption"
                >
                  {AllHours
                    ? AllHours.map((ema, iu) => (
                        <option
                          name="horasreservas"
                          key={"option" + iu}
                          id={"all" + iu}
                          value={moment
                            .tz(`${ema}`, zonahorairachange)
                            .format("LT")}
                        >
                          {selectedDate1s
                            ? moment
                                .tz(`${ema}`, zonahorairachange)
                                .format("LT") +
                              " / " +
                              moment
                                .tz(`${ema}`, zonahorairachange)
                                .add(1, "H")
                                .format("LT ")
                            : null}
                        </option>
                      ))
                    : "-----"}
                </select>
              </div>
            ) : (
              <div> hoal </div>
            )}
          </div>
        </div>

        {modalInfo}
      </>
    );
  } else return null;
};
export const Paso7 = (props) => {
  const moment = extendMoment(Moment);
  require("moment-timezone");
  const location = useLocation();
  const infoTraining = props.infoTraining || null;
  const vistaMostrar = props.vistaMostrar || null;
  const [modalInfo, setModalInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [zonahorairachange, setZonahorairachange] = useState(null);
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const Trainer = props.Trainer;
  const tutorialDay = props.tutorialDay || null;
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectProfesor, setselectProfesor] = useState(null);
  const [TotalDaysOn, setTotalDaysOn] = useState(null);
  const [TotalDaysOnOff, setTotalDaysOnOff] = useState(null);
  const indexStep = props.indexStep || null;
  const [vamosReservar, setvamosReservar] = useState({});
  const [disablebutton, setdisablebutton] = useState(true);
  const [chekedCualquierProfe, setchekedCualquierProfe] = useState(true);
  const [creditosreservados, setcreditosreservados] = useState(0);
  const [creditosUsuario, setcreditosUsuario] = useState(null);

  useEffect(() => {
    if (localStorage.trainerSelected) {
      setselectProfesor(localStorage.trainerSelected);
    }
    vercred();
  }, []);
  useEffect(() => {
    if (vamosReservar != {}) {
      setdisablebutton(false);
    }
  }, [vamosReservar]);
  function vercred() {
    instance.get("users/").then((response) => {
      const data_user = response.data;

      if (data_user) {
        setcreditosUsuario(data_user.customer.credits_balance);
      }
    });
  }

  useEffect(() => {
    if (TotalDaysOn) {
      setvamosReservar({
        ...vamosReservar,
        [TotalDaysOn[0].slice(0, 10)]: TotalDaysOn,
      });
    }
  }, [TotalDaysOn]);

  useEffect(() => {
    if (TotalDaysOnOff) {
      setvamosReservar({
        ...vamosReservar,
        [TotalDaysOnOff[0].slice(0, 10)]: TotalDaysOnOff,
      });
    }
  }, [TotalDaysOnOff]);
  useEffect(() => {
    setcreditosreservados(
      Object.values(vamosReservar)

        .map((e) => e[1])
        .filter((e) => typeof e == "number")
        .reduce((a, b) => a + b, 0)
    );
    if (tutorialDay) {
      let day = tutorialDay[0].slice(0, 10);
      let boilaq = document.getElementById(`dayrounded${day}`);
      boilaq && boilaq.classList.add("active");
    }
  }, [vamosReservar]);

  useEffect(() => {
    if (vistaMostrar && vistaMostrar != 2) {
      if (localStorage.mobileBooking) {
        localStorage.removeItem("mobileBooking");
      }

      setvamosReservar({});
    }
    let unselected = document.getElementsByClassName("rounded-circle");
    for (let i = 0; i < unselected.length; i++) {
      if (unselected[i].classList.contains("active")) {
        unselected[i].classList.remove("active");
      }
    }
  }, [chekedCualquierProfe]);
  const AllHours = [
    moment("01:00", "HH:mm").format(),
    moment("02:00", "HH:mm").format(),
    moment("03:00", "HH:mm").format(),
    moment("04:00", "HH:mm").format(),
    moment("05:00", "HH:mm").format(),
    moment("06:00", "HH:mm").format(),
    moment("07:00", "HH:mm").format(),
    moment("08:00", "HH:mm").format(),
    moment("09:00", "HH:mm").format(),
    moment("10:00", "HH:mm").format(),
    moment("11:00", "HH:mm").format(),
    moment("12:00", "HH:mm").format(),
    moment("13:00", "HH:mm").format(),
    moment("14:00", "HH:mm").format(),
    moment("15:00", "HH:mm").format(),
    moment("16:00", "HH:mm").format(),
    moment("17:00", "HH:mm").format(),
    moment("18:00", "HH:mm").format(),
    moment("19:00", "HH:mm").format(),
    moment("20:00", "HH:mm").format(),
    moment("21:00", "HH:mm").format(),
    moment("22:00", "HH:mm").format(),
    moment("23:00", "HH:mm").format(),
    moment("24:00", "HH:mm").format(),
  ];

  useEffect(() => {
    axios.get("https://ipwhois.app/json/").then((res) => {
      if (res.data) {
        setZonahorairachange(res.data.timezone);
      }
    });
  }, []);

  const now = moment();
  const future = now.clone().add("11", "months");
  const range = moment.range(now, future);
  const months = [];
  for (let month of range.by("month")) {
    months.push(month);
  }

  function caseLida() {
    let ArrayTotal = Object.values(vamosReservar);
    let lleno = false;

    for (const key in ArrayTotal) {
      let timeStart = moment();
      let timeEnd = moment(ArrayTotal[key][0]);
      let diff = timeEnd.diff(timeStart, "minutes");
      if (ArrayTotal[key][1] != null && diff <= 1440) lleno = true;
    }

    return lleno;
  }
  function reviewReservas() {
    let ArrayTotal = Object.values(vamosReservar);
    let lleno = false;

    for (const key in ArrayTotal) {
      if (ArrayTotal[key][1] != null) lleno = true;
    }

    return lleno;
  }
  function guardarStorage() {
    let ArrayTotal = Object.values(vamosReservar);
    let arraySend = [];
    for (const key in ArrayTotal) {
      if (ArrayTotal[key][1] != null) arraySend.push(ArrayTotal[key]);
    }
    localStorage.creditoReser = creditosreservados;
    localStorage.setItem("reservasAll", JSON.stringify(arraySend));
    navigate(`/resumenReserva/${infoTraining.id}`);
  }

  function guardarStorageMobile() {
    let ArrayTotal = Object.values(vamosReservar);

    let arraySend = [];
    for (const key in ArrayTotal) {
      if (ArrayTotal[key][1] != null) arraySend.push(ArrayTotal[key]);
    }
    localStorage.setItem("mobileBooking", JSON.stringify(arraySend));
    navigate(`/ScheduleMobile/${infoTraining.id}`);
  }

  useEffect(() => {
    if (localStorage.mobileBooking) {
      let allBooks = JSON.parse(localStorage.mobileBooking);
      let nuevosJson = {};
      for (let i = 0; i < allBooks.length; i++) {
        nuevosJson[allBooks[i][0].slice(0, 10)] = allBooks[i];
      }
      setvamosReservar(nuevosJson);
    }
  }, [location.pathname]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {modalInfo}
      {laptop575.matches ? (
        <div
          className=" d-none d-md-flex col-9"
          style={{
            background: "white",
            height: "100%",
            alignItems: "center",
            borderRadius: "20px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <div
              className="d-flex w-100"
              style={{
                background: "white",
                height: "85%",
                gap: "2%",
                alignItems: "center",
                borderRadius: "20px",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                className="d-flex "
                style={{
                  padding: "0 5%",
                  height: "20%",
                  width: "90%",
                  justifyContent: "center",
                  flexDirection: "column",
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/img/imagestatic-reservas.png"
                  })`,
                  backgroundSize: "cover",
                  backgroundPositionX: "top",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "20px",
                }}
              >
                <div className="d-flex my-auto">
                  <div
                    onClick={() => navigate(-1)}
                    className=" fw-bold booking-all"
                    style={{
                      background: "transparent",
                      border: "1px solid #A8C2D7",
                      padding: "1%",
                      fontSize: "1vmin",
                      height: "fit-content",
                      cursor: "pointer",
                      color: "#A8C2D7",
                      borderRadius: "10px",
                      position: "relative",
                      left: "1%",
                    }}
                  >
                    <span style={{ fontSize: "0.5vmin" }}>
                      <i className="bi bi-chevron-left"></i>
                    </span>
                  </div>
                  <div style={{ padding: "0% 3% 0% 2%", lineHeight: "220%" }}>
                    {" "}
                    <div
                      style={{
                        color: "#394F65",
                        fontSize: "4vmin",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "300",
                          color: "#394F65",
                          fontSize: "4vmin",
                        }}
                      >
                        Entrenamiento
                      </span>
                      <br />
                      <strong>{infoTraining && infoTraining.name}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex col-12"
                style={{ height: "80%", padding: "0 5%" }}
              >
                {/* primera mitad */}
                <div className="col-6 p-2">
                  <PerfectScrollbar
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "90%",
                    }}
                  >
                    <div className="d-flex my-auto">
                      <div
                        className=" fw-bold booking-all"
                        style={{ color: "#394F65", height: "fit-content" }}
                      >
                        <span style={{ fontSize: "4vmin" }}>
                          <i className="bi bi-2-circle"></i>
                        </span>
                      </div>
                      <div
                        style={{ padding: "0% 3% 0% 2%", lineHeight: "220%" }}
                      >
                        {" "}
                        <div
                          style={{
                            color: "#394F65",
                            fontSize: "4vmin",
                            lineHeight: "100%",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "300",
                              color: "#394F65",
                              fontSize: "4vmin",
                            }}
                          >
                            Selecciona tu
                          </span>
                          <br />
                          <strong>fecha de actividad</strong>
                        </div>
                      </div>
                    </div>
                    <div
                      className="my-2"
                      style={{ lineHeight: "100%", padding: "0 6%" }}
                    >
                      {" "}
                      <span
                        style={{
                          fontWeight: "300",
                          color: "#A9B6C4",
                          fontSize: "2.3vmin",
                        }}
                      >
                        Recuerda que puedes seleccionar más de un día para
                        entrenar.
                      </span>
                    </div>
                    <div className="my-auto" id={"bookingBook"}>
                      <RenderMonths
                        months={months}
                        Trainer={Trainer}
                        onSelectMonth={(index) =>
                          setSelectedMonth(months[index])
                        }
                      />
                      <RenderDays
                        tutorialDay={tutorialDay}
                        month={selectedMonth}
                        zonahorairachange={zonahorairachange}
                        AllHours={AllHours}
                        selectedDate1={selectedDate1}
                        onSelectDay1={
                          (date) => setTotalDaysOn(date)
                          // || console.log(date)
                        }
                      />{" "}
                    </div>
                  </PerfectScrollbar>

                  <ButtonMob
                    handleClick={() => navigate(-1)}
                    icon={"bi bi-chevron-left"}
                    className=""
                    text="Regresar"
                  />
                </div>
                {/* segunda mitad */}
                <PerfectScrollbar className="col-6 py-2 px-3">
                  <div className="d-flex my-auto">
                    <div
                      className=" fw-bold booking-all"
                      style={{ color: "#394F65" }}
                    >
                      <span style={{ fontSize: "4vmin" }}>
                        <i className="bi bi-3-circle"></i>
                      </span>
                    </div>
                    <div style={{ padding: "0% 3% 0% 2%" }}>
                      {" "}
                      <div
                        style={{
                          color: "#394F65",
                          fontSize: "4vmin",
                          lineHeight: "100%",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "300",
                            color: "#394F65",
                            fontSize: "4vmin",
                          }}
                        >
                          Selecciona tu
                        </span>
                        <br />
                        <strong>hora de actividad</strong>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    {Object.values(vamosReservar).length > 0
                      ? Object.values(vamosReservar).map((e, i) => (
                          <TotalReservas
                            indexStep={indexStep}
                            onSelectDay1={(date) => setTotalDaysOn(date)}
                            Trainer={selectProfesor}
                            key={"reserva" + i}
                            id={i}
                            totalDays={(date) => setTotalDaysOnOff(date)}
                            idTrainer={infoTraining?.id}
                            zonahorairachange={zonahorairachange}
                            AllHours={AllHours}
                            day={e}
                          />
                        ))
                      : null}

                    {reviewReservas() ? (
                      <>
                        {" "}
                        {/* <span
                          className="my-1 h-auto"
                          style={{ fontSize: "calc(0.5rem + 0.3vw)" }}
                        >
                          Costo total de tu reserva - {creditosreservados}{" "}
                          {creditosreservados > 1 || creditosreservados == 0
                            ? "créditos"
                            : "crédito"}
                        </span> */}
                        {caseLida() ? (
                          <div
                            className={"h-auto"}
                            style={{
                              color: "#CB3D82",
                              fontSize: "calc(0.5rem + 0.3vw)",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            Tienes reservas dentro de las 24 horas a su inicio,
                            recuerda que si las cancelas en estado confirmado,
                            perderás tus créditos.
                          </div>
                        ) : null}{" "}
                        <ButtonMob
                          handleClick={guardarStorage}
                          icon={"ms-2 bi bi-calendar3 right"}
                          className=""
                          text="Reserva"
                        />
                      </>
                    ) : null}
                  </div>
                </PerfectScrollbar>
              </div>{" "}
            </div>
          )}
        </div>
      ) : (
        <div className="mobile-login w-100" style={{ height: "100%" }}>
          <div
            className="containerBGClase"
            style={{
              backgroundSize: "cover",
              height: "30vh",
              backgroundImage: `url(${
                infoTraining && infoTraining.main_picture
              })`,
            }}
          >
            <div
              className="w-auto m-auto d-flex justify-content-center"
              style={{
                position: "absolute",
                top: "5%",
                left: "50%",
                height: "7%",
                transform: "translate(-50%, 5%)",
                alignItems: "center",
              }}
            >
              {" "}
              <img
                style={{ width: "15vmin" }}
                src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
              />
            </div>
            <TitleRow
              infoTraining={infoTraining}
              handleclick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div
            className="mobile-welcome-login-row  text-center w-100"
            style={{ top: "27vh" }}
          >
            <div
              className={
                (vistaMostrar && vistaMostrar == 2) ||
                (indexStep && indexStep == 13)
                  ? "d-none"
                  : ""
              }
              style={{ padding: "4% 4%" }}
            >
              <span
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "calc(1.3rem + 0.4vw)",
                  lineHeight: "100%",
                }}
              >
                {" "}
                <i className="bi bi-2-circle me-2"></i> Selecciona tus{" "}
                <strong> fechas de entrenamiento</strong>
              </span>
              <div
                className="mt-2"
                style={{
                  lineHeight: "100%",
                  padding: "0 12%",
                  textAlign: "left",
                }}
              >
                <span
                  style={{
                    fontWeight: "300",
                    color: "#A9B6C4",
                    fontSize: "0.9rem",
                  }}
                >
                  Recuerda que puedes seleccionar más de un día para entrenar.
                </span>
              </div>
            </div>
            {/* mobile */}
            <PerfectScrollbar
              className={
                (vistaMostrar && vistaMostrar == 2) ||
                (indexStep && indexStep == 13)
                  ? "d-none"
                  : ""
              }
            >
              <div className="my-auto h-100" id={"bookingBookMobile"}>
                <RenderMonths
                  months={months}
                  Trainer={Trainer}
                  onSelectMonth={(index) => setSelectedMonth(months[index])}
                />
                <RenderDays
                  tutorialDay={tutorialDay}
                  month={selectedMonth}
                  zonahorairachange={zonahorairachange}
                  AllHours={AllHours}
                  selectedDate1={selectedDate1}
                  onSelectDay1={(date) => setTotalDaysOn(date)}
                />{" "}
              </div>
            </PerfectScrollbar>
            <div
              className={
                (vistaMostrar && vistaMostrar == 1) ||
                (indexStep && indexStep == 12)
                  ? "d-none"
                  : ""
              }
              style={{ padding: "4% 4%" }}
            >
              <span
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "calc(1.3rem + 0.4vw)",
                }}
              >
                {" "}
                <i className="bi bi-3-circle  me-2"></i> Selecciona tus{" "}
                <strong> horas de entrenamiento</strong>
              </span>
            </div>
            <PerfectScrollbar
              className={
                (vistaMostrar && vistaMostrar == 1) ||
                (indexStep && indexStep == 12)
                  ? "d-none"
                  : ""
              }
              style={{ padding: "0% 4%" }}
            >
              <div
                className={vistaMostrar && vistaMostrar == 1 ? "d-none" : ""}
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                {Object.values(vamosReservar).length > 0 ? (
                  Object.values(vamosReservar).map((e, i) => (
                    <TotalReservas
                      vistaMostrar={vistaMostrar}
                      indexStep={indexStep}
                      onSelectDay1={(date) => setTotalDaysOn(date)}
                      Trainer={selectProfesor}
                      key={"reserva" + i}
                      id={i}
                      totalDays={(date) => setTotalDaysOnOff(date)}
                      idTrainer={infoTraining?.id}
                      zonahorairachange={zonahorairachange}
                      AllHours={AllHours}
                      day={e}
                    />
                  ))
                ) : (
                  <span>No tienes clases reservadas</span>
                )}

                {reviewReservas() ? (
                  <>
                    {" "}
                    {/* <span
                      className={"my-1 h-auto"}
                      style={{ fontSize: "1rem" }}
                    >
                      Costo total de tu reserva - {creditosreservados}{" "}
                      {creditosreservados > 1 || creditosreservados == 0
                        ? "créditos"
                        : "crédito"}
                    </span> */}
                    {caseLida() ? (
                      <div
                        className={"h-auto"}
                        style={{
                          color: "#CB3D82",
                          fontSize: "1rem",
                          fontWeight: "400",
                          textTransform: "none",
                        }}
                      >
                        Tienes reservas dentro de las 24 horas a su inicio,
                        recuerda que si las cancelas en estado confirmado,
                        perderás tus créditos.
                      </div>
                    ) : null}{" "}
                  </>
                ) : null}
              </div>
            </PerfectScrollbar>
            <ButtonMob
              handleClick={guardarStorage}
              icon={"ms-2 bi bi-calendar3 right"}
              className={
                (vistaMostrar && vistaMostrar == 1) ||
                (indexStep && indexStep == 12)
                  ? "d-none"
                  : ""
              }
              text="Reserva"
            />
            <ButtonMob
              handleClick={guardarStorageMobile}
              className={
                (vistaMostrar && vistaMostrar == 2) ||
                (indexStep && indexStep == 13)
                  ? "d-none"
                  : ""
              }
              text="Continuar"
            />
          </div>
        </div>
      )}
    </>
  );
};

export const Paso4Mostrar = (props) => {
  const moment = extendMoment(Moment);
  require("moment-timezone");
  const navigate = useNavigate();
  const infoTraining = props.infoTraining || null;
  const customerInfo = props.customerInfo || null;
  const zonahorairachange = props.zonahorairachange;
  const reservasAll = JSON.parse(localStorage.getItem("reservasAll"));
  const [trainer, setTrainer] = useState([]);
  const costoReservas = localStorage.creditoReser;

  useEffect(() => {
    if (localStorage.trainerSelected) {
      let selectTrainer =
        props.trainers &&
        props.trainers.filter(
          (train) => train.id == localStorage.trainerSelected
        );
      setTrainer(selectTrainer);
    }
  }, []);

  function restantes() {
    if (customerInfo) {
      let calculate = customerInfo.credits_balance - costoReservas;
      let plural = calculate > 1 || calculate == 0 ? " créditos" : " crédito";
      return calculate + plural;
    }
  }
  return (
    <>
      <div
        className=" d-none d-md-flex col-9"
        style={{
          background: "white",
          height: "100%",

          borderRadius: "20px",

          justifyContent: "center",
        }}
      >
        <div
          className="col-6 d-flex"
          style={{
            position: "relative",
            justifyContent: "center",
            padding: "5%",
          }}
        >
          <img
            style={{
              margin: "0 4%",
              width: "92%",
              backgroundImage: "cover",
              objectFit: "cover",
              borderRadius: "20px",
            }}
            src={
              infoTraining && infoTraining.cover_picture
              // Aquí se pasa la propiedad cover_picture del objeto
            }
            alt=""
          />

          <div
            style={{ top: "11%", position: "absolute", left: "18%" }}
            className="w-100"
          >
            <div className="d-flex my-auto "></div>
          </div>
        </div>
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            gap: "1%",
            padding: "5% 5% 5% 0",
          }}
        >
          <div className="d-flex justify-content-center h-auto">
            <div
              onClick={() => navigate(-1)}
              className=""
              style={{
                background: "transparent",
                border: "1px solid #A8C2D7",
                padding: "1vmin",

                height: "fit-content",
                cursor: "pointer",
                color: "#A8C2D7",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontSize: "1rem" }}>
                <i className="bi bi-chevron-left"></i>
              </span>
            </div>
            <div className=" my-auto mx-2 d-flex flex-column justify-content-center  ">
              <span
                className="mt-2"
                style={{ color: "#394F65", fontSize: "calc(0.7rem + 0.3vw)" }}
              >
                Resumen
              </span>
              <span
                style={{
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "calc(1.3rem + 0.3vw)",
                }}
              >
                de tu reserva
              </span>{" "}
            </div>
          </div>

          <div
            className="d-flex justify-content-center flex-column gap-1"
            style={{ height: "80%" }}
          >
            <PerfectScrollbar
              className="d-flex flex-column gap-2"
              style={{
                padding: "0 4%",
                justifyContent: reservasAll.length > 4 ? "stretch" : "center",
              }}
            >
              {reservasAll.map((book) => (
                <div
                  id="Selectclickreserva"
                  className="d-flex"
                  style={{
                    background: "#F4F0ED",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "25px",
                    padding: "2%",

                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "calc(1rem + 0.3vw)",
                    }}
                  >
                    <i className="bi bi-clock-history"></i>
                  </span>

                  {trainer && trainer.length > 0 ? (
                    <span
                      style={{
                        textAlign: "center",
                        color: "#394F65",

                        fontSize: "calc(0.5rem + 0.3vw)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {trainer[0].name}
                    </span>
                  ) : null}
                  <span
                    style={{
                      textAlign: "center",
                      color: "#D74688",

                      fontSize: "calc(0.5rem + 0.3vw)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {infoTraining && infoTraining.name}
                  </span>
                  <span
                    style={{
                      textAlign: "center",
                      color: "#394F65",

                      fontSize: "calc(0.5rem + 0.3vw)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {moment
                      .tz(book[0], "YYYY-MM-DD H:mm", true, zonahorairachange)
                      .format(" DD [de] MMMM")}
                    {" / " +
                      moment
                        .tz(book[0], "YYYY-MM-DD H:mm", true, zonahorairachange)
                        .format("hh:mm a") +
                      " / " +
                      moment
                        .tz(book[0], "YYYY-MM-DD H:mm", true, zonahorairachange)
                        .add(1, "h")
                        .format("hh:mm a")}
                  </span>
                </div>
              ))}
            </PerfectScrollbar>
            <hr />
            {/* <div>
              <div className="d-flex justify-content-center col-12">
                <div
                  className="col-6 my-auto"
                  style={{
                    textAlign: "right",
                    fontSize: "calc(0.5rem + 0.3vw)",
                  }}
                >
                  Clase de {infoTraining && infoTraining.name}:{" "}
                </div>
                <div
                  className="col-6 my-auto"
                  style={{
                    fontSize: "calc(0.5rem + 0.4vw)",
                    fontWeight: "bold",
                  }}
                >
                  {costoReservas}{" "}
                  {costoReservas > 1 || costoReservas == 0
                    ? "créditos"
                    : "crédito"}
                </div>
              </div>
              <div className="d-flex justify-content-center col-12">
                <div
                  className="col-6 my-auto"
                  style={{
                    textAlign: "right",
                    fontSize: "calc(0.5rem + 0.3vw)",
                  }}
                >
                  Descuento:{" "}
                </div>
                <div
                  className="col-6 my-auto"
                  style={{
                    fontSize: "calc(0.5rem + 0.4vw)",
                    fontWeight: "bold",
                  }}
                >
                  {costoReservas}{" "}
                  {costoReservas > 1 || costoReservas == 0
                    ? "créditos"
                    : "crédito"}
                </div>
              </div>
              <div className="d-flex justify-content-center col-12">
                <div
                  className="col-6 my-auto"
                  style={{
                    textAlign: "right",
                    fontSize: "calc(0.5rem + 0.3vw)",
                  }}
                >
                  Créditos restantes:{" "}
                </div>
                <div
                  className="col-6 my-auto"
                  style={{
                    fontSize: "calc(0.5rem + 0.4vw)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {restantes()}
                </div>
              </div>
            </div> */}

            <div className="d-flex py-2" style={{ justifyContent: "center" }}>
              <ButtonMob
                handleClick={() => {
                  localStorage.trainingBook = infoTraining.id;
                  localStorage.reservaSuccess = false;
                  if (customerInfo.terms == true) {
                    navigate(`/Book/${infoTraining.id}`);
                  } else {
                    navigate(`/Onboarding`);
                  }
                }}
                icon={"ms-2 bi bi-calendar3 right"}
                className=""
                text="Reserva"
              />
            </div>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div
        className="d-md-none mobile-login w-100"
        style={{ height: "100%", background: "white" }}
      >
        <div
          className="d-flex justify-content-start h-auto"
          style={{ padding: "2% 10%" }}
        >
          <div
            onClick={() => navigate(-1)}
            className=""
            style={{
              background: "transparent",
              border: "1px solid #A8C2D7",
              padding: "0.4rem",
              height: "fit-content",
              cursor: "pointer",
              color: "#A8C2D7",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "1.1rem" }}>
              <i className="bi bi-chevron-left"></i>
            </span>
          </div>
          <div className=" my-auto mx-2 d-flex flex-column justify-content-start">
            <span
              className="mt-1"
              style={{ color: "#394F65", fontSize: "1.2rem" }}
            >
              Resumen
            </span>
            <span
              style={{
                color: "#394F65",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              de tu reserva
            </span>{" "}
          </div>
        </div>
        <hr />
        <div
          className="mobile-welcome-login-row w-100 "
          style={{ top: "25vh", justifyContent: "stretch" }}
        >
          <PerfectScrollbar
            className="d-flex flex-column gap-2"
            style={{
              padding: "0 4%",
              height: "60%",
              justifyContent: reservasAll.length > 4 ? "stretch" : "center",
            }}
          >
            {reservasAll.map((book) => (
              <div
                id="Selectclickreserva"
                className="d-flex"
                style={{
                  background: "#F4F0ED",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "25px",
                  padding: "2%",

                  width: "100%",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  <i className="bi bi-clock-history"></i>
                </span>

                {trainer && trainer.length > 0 ? (
                  <span
                    style={{
                      textAlign: "center",
                      color: "#394F65",

                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {trainer[0].name}
                  </span>
                ) : null}
                <span
                  style={{
                    textAlign: "center",
                    color: "#D74688",

                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {infoTraining && infoTraining.name}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",

                    fontSize: "1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  {moment
                    .tz(book[0], "YYYY-MM-DD H:mm", true, zonahorairachange)
                    .format("MMMM DD [de] YYYY")}
                  {" / " +
                    moment
                      .tz(book[0], "YYYY-MM-DD H:mm", true, zonahorairachange)
                      .format("h:mm a") +
                    " / " +
                    moment
                      .tz(book[0], "YYYY-MM-DD H:mm", true, zonahorairachange)
                      .add(1, "h")
                      .format("h:mm a")}
                </span>
              </div>
            ))}
          </PerfectScrollbar>
        </div>
        <div
          className="mobile-welcome-login-row  text-left w-100"
          style={{ top: "auto" }}
        >
          <div className="m-auto " style={{ width: "85%" }}>
            <hr />
            {localStorage.membership ? null : (
              <div>
                <div className="d-flex justify-content-center col-12">
                  <div
                    className="col-7 my-auto"
                    style={{
                      textAlign: "right",
                      fontSize: "1rem",
                      color: "#394F65",
                    }}
                  >
                    Clase de {infoTraining && infoTraining.name}:{" "}
                  </div>

                  <div
                    className="col-5 my-auto ms-1"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "#394F65",
                    }}
                  >
                    {costoReservas}{" "}
                    {costoReservas > 1 || costoReservas == 0
                      ? "créditos"
                      : "crédito"}
                  </div>
                </div>
                <div className="d-flex justify-content-center col-12">
                  <div
                    className="col-7 my-auto"
                    style={{
                      textAlign: "right",
                      fontSize: "1rem",
                      color: "#394F65",
                    }}
                  >
                    Descuento:{" "}
                  </div>
                  <div
                    className="col-5 my-auto ms-1"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "#394F65",
                    }}
                  >
                    {costoReservas}{" "}
                    {costoReservas > 1 || costoReservas == 0
                      ? "créditos"
                      : "crédito"}
                  </div>
                </div>
                <div className="d-flex justify-content-center col-12">
                  <div
                    className="col-7 my-auto"
                    style={{
                      textAlign: "right",
                      fontSize: "1rem",
                      color: "#394F65",
                    }}
                  >
                    Créditos restantes:{" "}
                  </div>
                  <div
                    className="col-5 ms-1 my-auto"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "#394F65",
                    }}
                  >
                    {" "}
                    {restantes()}
                  </div>
                </div>
              </div>
            )}
            <ButtonMob
              handleClick={() => {
                localStorage.trainingBook = infoTraining.id;
                localStorage.reservaSuccess = false;
                if (customerInfo.terms == true) {
                  navigate(`/Book/${infoTraining.id}`);
                } else {
                  navigate(`/Onboarding`);
                }
              }}
              icon={"ms-2 bi bi-calendar3 right"}
              className=""
              text="Reservar"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Paso5 = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const trainer = props.trainer;
  const idJoyrideMobi = props.idJoyrideMobi || null;
  const nameTraining = props.nameTraining;
  const reservasAll = props.reservasAll;
  const zonahorairachange = props.zonahorairachange;
  const mostrarClases = props.mostrarClases;
  const moment = extendMoment(Moment);
  require("moment-timezone");
  useEffect(() => {
    if (reservasAll && reservasAll.length > 0) {
      setIsLoading(false);
    }
  }, [mostrarClases]);
  return (
    <>
      <div
        className=" d-none d-md-flex col-9"
        style={{
          background: "white",
          height: "100%",
          alignItems: "center",
          borderRadius: "20px",

          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {" "}
            <div
              className="col-6 d-flex"
              style={{
                position: "relative",
                justifyContent: "center",
                padding: "0 5%",
                height: "85%",
              }}
            >
              <img
                className="w-100"
                style={{ padding: "0% 4%", backgroundImage: "cover" }}
                src={
                  process.env.PUBLIC_URL + "/img/finalreserva.png"
                  // Aquí se pasa la propiedad cover_picture del objeto
                }
                alt="book"
              />

              <div
                style={{ top: "11%", position: "absolute", left: "18%" }}
                className="w-100"
              >
                <div className="d-flex my-auto "></div>
              </div>
            </div>
            <div
              className="col-6 d-flex text-center"
              style={{
                justifyContent: "center",
                flexDirection: "column",

                height: "85%",
                overflow: "auto",
                padding: "5% 5% 5% 0",
              }}
            >
              <div
                className="d-flex text-center justify-content-stretch "
                style={{
                  flexDirection: "column",
                  gap: "calc(3%)",
                  height: "calc(100%)",
                }}
              >
                <div
                  className="my-auto"
                  style={{
                    color: "#394F65",
                    fontSize: "calc(0.7rem + 0.3vw)",
                    lineHeight: "60%",
                    height: "auto",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <span style={{ textAlign: "justify" }}>
                      {" "}
                      Entrenamientos <br></br>
                      <span
                        className="my-auto"
                        style={{
                          color: "#394F65",
                          fontWeight: "bold",
                          fontSize: "calc(1.7rem + 0.5vmin)",
                          whiteSpace: "nowrap",
                          height: "auto",
                          lineHeight: "90%",
                        }}
                      >
                        reservados
                      </span>
                    </span>
                  </div>
                </div>{" "}
                <hr className="my-auto" style={{ border: "1px solid " }} />
                <div
                  className="fw-600 m-auto"
                  style={{
                    height: "auto",
                    background: "#B07EBC",
                    color: "white",
                    width: "100%",
                    fontSize: "calc(1.0rem + 0.3vmin)",
                    padding: "3% 0",
                  }}
                >
                  ¡Disfruta <strong>tu clase!</strong>
                </div>
                {mostrarClases ? (
                  <PerfectScrollbar
                    className="d-flex flex-column gap-2"
                    style={{
                      padding: "0 4%",
                      justifyContent:
                        reservasAll.length > 2 ? "stretch" : "center",
                    }}
                  >
                    {reservasAll &&
                      reservasAll.map((book) => (
                        <div
                          id="ReservadoTutotial"
                          className="d-flex"
                          style={{
                            background: "#F4F0ED",
                            justifyContent: "center",
                            flexDirection: "column",
                            borderRadius: "25px",
                            padding: "2%",

                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              textAlign: "center",
                              fontSize: "calc(1rem + 0.3vw)",
                            }}
                          >
                            <i className="bi bi-clock-history"></i>
                          </span>

                          {trainer && trainer.length > 0 ? (
                            <span
                              style={{
                                textAlign: "center",
                                color: "#394F65",

                                fontSize: "calc(0.5rem + 0.3vw)",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {trainer}
                            </span>
                          ) : null}
                          <span
                            style={{
                              textAlign: "center",
                              color: "#D74688",

                              fontSize: "calc(0.5rem + 0.3vw)",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {nameTraining && nameTraining}
                          </span>
                          <span
                            style={{
                              textAlign: "center",
                              color: "#394F65",

                              fontSize: "calc(0.5rem + 0.3vw)",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {moment
                              .tz(
                                book[0],
                                "YYYY-MM-DD H:mm",
                                true,
                                zonahorairachange
                              )
                              .format(" DD [de] MMMM")}
                            {" / " +
                              moment
                                .tz(
                                  book[0],
                                  "YYYY-MM-DD H:mm",
                                  true,
                                  zonahorairachange
                                )
                                .format("hh:mm a") +
                              " / " +
                              moment
                                .tz(
                                  book[0],
                                  "YYYY-MM-DD H:mm",
                                  true,
                                  zonahorairachange
                                )
                                .add(1, "h")
                                .format("hh:mm a")}
                          </span>
                        </div>
                      ))}
                  </PerfectScrollbar>
                ) : (
                  "No tienes reservas"
                )}
                <div
                  className="d-flex m-auto"
                  style={{
                    border: "1px solid #9DACBD",
                    padding: "2%",
                    borderRadius: "20px",
                    width: "45vmin",
                    height: "auto",
                  }}
                >
                  <div
                    className="my-auto"
                    style={{ padding: "4%", borderRight: "1px solid #9DACBD" }}
                  >
                    <img
                      style={{ width: "10vmin" }}
                      src={process.env.PUBLIC_URL + "/img/compus.png"}
                    />
                  </div>
                  <div style={{ padding: "6%" }}>
                    <span
                      className="d-flex"
                      style={{
                        color: "#9DACBD",
                        textAlign: "justify",
                        lineHeight: "100%",
                        fontSize: "calc(0.4rem + 0.3vw)",
                      }}
                    >
                      Recuerda que puedes tomar tu clases a través de un
                      computador, celular, ipad o tablet.<br></br> <br></br>{" "}
                      Para una experiencia optima, recomendamos usar el
                      navegador Google Chrome.
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex mx-auto"
                  style={{ justifyContent: "center", height: "auto" }}
                >
                  <button
                    onClick={() => navigate("/Dashboard")}
                    style={
                      props.indexStep == 7
                        ? {
                            background: "var(--elevva)",
                            border: "1px solid var(--elevva)",
                            borderRadius: "20px",
                            color: "var(--white)",
                            fontWeight: "400",
                            width: "100px",
                            zIndex: "9999",
                            padding: "2% 12%",
                          }
                        : {
                            background: "var(--elevva)",
                            border: "1px solid var(--elevva)",
                            borderRadius: "20px",
                            color: "var(--white)",
                            fontWeight: "400",
                            width: "100px",
                            padding: "2% 12%",
                          }
                    }
                  >
                    Ir a <i className="bi bi-house"></i>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* mobile */}

      {isLoading ? (
        <Loading />
      ) : (
        <div
          className="d-md-none mobile-login w-100"
          style={{ height: "100%" }}
        >
          <div
            className="containerBGClase"
            style={{
              backgroundSize: "cover",
              height: "25vh",
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/img/modeloReservaBook.png"
                // Aquí se pasa la propiedad cover_picture del objeto
              })`,
            }}
          >
            <div
              className="px-4 d-flex flex-column position-relative"
              style={{ verticalAlign: "middle", top: "30%" }}
            >
              <div
                className="my-auto"
                style={{
                  color: "#394F65",
                  fontSize: "1rem",
                  lineHeight: "100%",
                  height: "auto",
                }}
              >
                Entrenamientos
              </div>
              <div
                className="my-auto"
                style={{
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "calc(1.5rem + 0.5vmin)",
                  whiteSpace: "nowrap",
                  height: "auto",
                  lineHeight: "100%",
                }}
              >
                reservados
              </div>
            </div>
          </div>
          <div
            className="mobile-welcome-login-row justify-content-start  text-center w-100"
            style={{ top: "25vh", overflow: "hidden" }}
          >
            <div
              className="fw-600"
              style={{
                background: "#B07EBC",
                color: "white",
                width: "100%",
                fontSize: "calc(1.0rem + 0.5vmin)",
                padding: "3% 0",
              }}
            >
              ¡Disfruta <strong>tu clase!</strong>
            </div>
            {mostrarClases ? (
              <PerfectScrollbar
                className="d-flex flex-column  justify-content-stretch gap-2"
                style={{ padding: "7% 4% 2% 4%", height: "40%" }}
              >
                {reservasAll &&
                  reservasAll.map((book) => (
                    <div
                      id={idJoyrideMobi && idJoyrideMobi}
                      className="d-flex my-0"
                      style={{
                        background: "#F4F0ED",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: "25px",
                        padding: "2%",

                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                        }}
                      >
                        <i className="bi bi-clock-history"></i>
                      </span>

                      {trainer && trainer.length > 0 ? (
                        <span
                          style={{
                            textAlign: "center",
                            color: "#394F65",

                            fontSize: "1rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {trainer}
                        </span>
                      ) : null}
                      <span
                        style={{
                          textAlign: "center",
                          color: "#D74688",

                          fontSize: "1rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {nameTraining && nameTraining}
                      </span>
                      <span
                        style={{
                          textAlign: "center",
                          color: "#394F65",

                          fontSize: "1rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {moment
                          .tz(
                            book[0],
                            "YYYY-MM-DD H:mm",
                            true,
                            zonahorairachange
                          )
                          .format(" DD [de] MMMM")}
                        {" / " +
                          moment
                            .tz(
                              book[0],
                              "YYYY-MM-DD H:mm",
                              true,
                              zonahorairachange
                            )
                            .format("hh:mm a") +
                          " / " +
                          moment
                            .tz(
                              book[0],
                              "YYYY-MM-DD H:mm",
                              true,
                              zonahorairachange
                            )
                            .add(1, "h")
                            .format("hh:mm a")}
                      </span>
                    </div>
                  ))}
              </PerfectScrollbar>
            ) : (
              <span className=" h-50 m-auto">No tienes reservas</span>
            )}
          </div>

          <div
            className="mobile-welcome-login-row  text-center w-100"
            style={{ top: "auto" }}
          >
            <div
              className="m-auto "
              style={{ width: "85%", textAlign: "justify" }}
            >
              <div
                className="d-flex m-auto my-4"
                style={{
                  border: "1px solid #9DACBD",
                  padding: "2%",
                  borderRadius: "20px",
                  width: "100%",
                  height: "auto",
                }}
              >
                <div
                  className="my-auto"
                  style={{ padding: "4%", borderRight: "1px solid #9DACBD" }}
                >
                  <img
                    style={{ width: "4rem" }}
                    src={process.env.PUBLIC_URL + "/img/compus.png"}
                  />
                </div>
                <div style={{ padding: "6%" }}>
                  <span
                    className="d-flex"
                    style={{
                      color: "#9DACBD",
                      textAlign: "justify",
                      lineHeight: "100%",
                      fontSize: "0.8rem",
                    }}
                  >
                    Recuerda que puedes tomar tu clases a través de un
                    computador, celular, ipad o tablet. Para una experiencia
                    optima, recomendamos usar el navegador Google Chrome.
                  </span>
                </div>
              </div>
            </div>
            <div
              className="d-flex m-3"
              style={{ justifyContent: "center", height: "auto" }}
            >
              <button
                onClick={() => navigate("/Dashboard")}
                style={
                  props.indexStep == 7 && laptop575.matches
                    ? {
                        background: "var(--elevva)",
                        border: "1px solid var(--elevva)",
                        borderRadius: "20px",
                        color: "var(--white)",
                        fontWeight: "400",
                        width: "120px",
                        zIndex: "9999",
                        padding: "2% 2%",
                      }
                    : {
                        background: "var(--elevva)",
                        border: "1px solid var(--elevva)",
                        borderRadius: "20px",
                        color: "var(--white)",
                        fontWeight: "400",
                        width: "120px",
                        padding: "2% 2%",
                      }
                }
              >
                Ir a <i className="bi bi-house"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Paso3 = (props) => {
  const trainers = props.trainers;
  const tomorrow = props.tomorrow || null;
  const infoTraining = props.infoTraining || null;
  const groupalInfo = props.groupalInfo || null;
  const zonahorairachange = props.zonahorairachange || null;
  const [earvalorgrupal, setearvalorgrupal] = useState(null);
  const alertError = props.alertError;
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location.pathname);
  function searchClasegrupales(reservar) {
    let arraydegroup = [];
    arraydegroup.push([
      reservar.slice(0, 10) + " " + reservar.slice(11, 16),
      infoTraining.credits,
    ]);
    localStorage.creditoReser = infoTraining.credits;
    localStorage.setItem("reservasAll", JSON.stringify(arraydegroup));
    navigate(`/resumenReserva/${infoTraining.id}`);
  }

  return (
    <div
      className="d-flex col-9"
      style={{
        background: "white",
        height: "100%",

        borderRadius: "20px",

        justifyContent: "center",
      }}
    >
      <div
        className="col-6 d-flex"
        style={{
          position: "relative",
          justifyContent: "center",
          padding: "5%",
        }}
      >
        <img
          style={{
            margin: "0 4%",
            width: "96%",
            objectFit: "cover",
            borderRadius: "20px",
          }}
          src={
            location.pathname.includes("selectGrupalList")
              ? infoTraining?.cover_picture
              : process.env.PUBLIC_URL + "/img/mascarareservafinal.png"

            // Aquí se pasa la propiedad cover_picture del objeto
          }
          alt=""
        />

        <div
          style={{ top: "11%", position: "absolute", left: "18%" }}
          className="w-100"
        >
          <div className="d-flex my-auto ">
            <div
              onClick={() => navigate(-1)}
              className=""
              style={{
                background: "transparent",
                border: "1px solid #A8C2D7",
                padding: "1vmin",

                height: "fit-content",
                cursor: "pointer",
                color: "#A8C2D7",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontSize: "1rem" }}>
                <i className="bi bi-chevron-left"></i>
              </span>
            </div>
            <div className=" my-auto mx-2 d-flex flex-column gap-2 justify-content-center  ">
              <span
                className="mt-2"
                style={{ color: "#394F65", fontSize: "calc(0.7rem + 0.3vw)" }}
              >
                Entrenamiento
              </span>
              <span
                style={{
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "calc(1.3rem + 0.3vw)",
                }}
              >
                {infoTraining ? infoTraining.name : "Zumba grupal"}
              </span>{" "}
              {infoTraining ? null : (
                <button
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "300",
                    maxWidth: "60%",
                    padding: "1% 0%",
                  }}
                >
                  GRATIS
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-6 d-flex"
        style={{
          justifyContent: "center",
          flexDirection: "column",
          gap: "2%",
          padding: "5% 5% 5% 0",
        }}
      >
        <div
          className="col-6 d-flex w-100"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            gap: "2%",
            padding: "0 4%",
          }}
        >
          <span
            style={{
              color: "#394F65",

              fontSize: "calc(1rem + 0.3vw)",
            }}
          >
            {" "}
            Selecciona tu
          </span>

          <span
            style={{
              color: "#394F65",
              fontWeight: "600",
              fontSize: "calc(1rem + 0.3vw)",
            }}
          >
            día y hora de clase
          </span>
          {infoTraining ? (
            <div
              className="d-flex flex-column justify-content-strench h-100"
              style={
                groupalInfo && groupalInfo.length > 2
                  ? { overflowY: "auto" }
                  : null
              }
            >
              {groupalInfo &&
                groupalInfo.map((trainer) => (
                  <div
                    key={trainer.id}
                    className="d-flex position-relative changecolortable my-1"
                    style={{
                      justifyContent: "space-around",
                      borderRadius: "40px",
                      textAlign: "center",
                      padding: "2.5%",
                    }}
                  >
                    <div
                      className="d-flex "
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          color: "#394F65",

                          fontSize: "calc(0.7rem + 0.2vw)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Prof. {trainer.trainer.full_name}
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          color: "#394F65",
                          fontSize: "calc(0.7rem + 0.2vw)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {trainer.capacity - trainer.current_capacity}{" "}
                        {trainer.capacity - trainer.current_capacity == 1
                          ? "CUPO"
                          : "CUPOS"}{" "}
                      </span>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: "2%",
                      }}
                    >
                      {Moment(trainer.schedule_date).format("YYYY-MM-DD")}{" "}
                      <br /> {Moment(trainer.schedule_date).format("hh:mm a")}
                    </div>
                    <div className="my-auto d-flex  position-relative">
                      <input
                        name="radioteachers"
                        type="radio"
                        value={trainer.schedule_date}
                        onClick={(e) => {
                          setearvalorgrupal(e.target.value);
                        }}
                      ></input>{" "}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div
              className="d-flex flex-column justify-content-strench h-100"
              style={{ overflowY: "auto" }}
              id={"SelectHourTutorial"}
            >
              {trainers &&
                trainers.map((trainer) => (
                  <div
                    key={trainer.id}
                    className="d-flex position-relative changecolortable my-1"
                    style={{
                      justifyContent: "space-around",
                      borderRadius: "40px",
                      textAlign: "center",
                      padding: "2.5%",
                    }}
                  >
                    <div
                      className="d-flex "
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          color: "#394F65",

                          fontSize: "calc(0.7rem + 0.2vw)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Prof. {trainer.name}
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          color: "#394F65",

                          fontSize: "calc(0.7rem + 0.2vw)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        20 CUPOS{" "}
                      </span>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: "2%",
                      }}
                    >
                      {/* <span>{trainer.dia}</span>
  <span>{trainer.hora}</span> */}
                      {tomorrow}
                    </div>
                    <div className="my-auto d-flex  position-relative">
                      <input
                        name="radioteachers"
                        type="radio"
                        // onChange={() => handleTrainerChange(trainer)}
                      ></input>{" "}
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div
            className="my-2"
            style={{
              color: "#394F65",
              fontWeight: "600",
              fontSize: "1.5vmin",
            }}
          >
            <span
              style={{
                textAlign: "center",
                color: "#394F65",
                fontSize: "calc(0.7rem + 0.3vw)",
                whiteSpace: "nowrap",
              }}
            >
              Costo total de tu reserva -{" "}
              {infoTraining?.credits ? infoTraining.credits : 0} créditos
            </span>
          </div>
          <div className="my-0 ">
            <ButtonMob
              icon={"ms-2 bi bi-calendar3 right"}
              className=""
              text="Reserva"
              handleClick={() => searchClasegrupales(earvalorgrupal)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Paso3Mobile = (props) => {
  const tomorrow = props.tomorrow;
  const profesores = props.profesores;
  const trainers = props.trainers;
  const infoTraining = props.infoTraining || null;
  const groupalInfo = props.groupalInfo;
  const [earvalorgrupal, setearvalorgrupal] = useState(null);
  const navigate = useNavigate();
  function searchClasegrupales(reservar) {
    let arraydegroup = [];
    arraydegroup.push([
      reservar.slice(0, 10) + " " + reservar.slice(11, 16),
      infoTraining.credits,
    ]);
    localStorage.creditoReser = infoTraining.credits;
    localStorage.setItem("reservasAll", JSON.stringify(arraydegroup));
    navigate(`/resumenReserva/${infoTraining.id}`);
  }
  const ComponentTeacher = (props) => {
    return (
      <PerfectScrollbar
        className="w-100"
        style={{ height: "60%", padding: "0 5%" }}
      >
        {infoTraining ? (
          <div className=" classTeachers  w-100 ">
            {groupalInfo &&
              groupalInfo.map((trainer) => (
                <div
                  id={"grupalMobileTut"}
                  key={"tutorialteacher" + trainer.id}
                  className="d-flex  border-color justify-content-center paddingGrowTeacher w-100 changecolortable"
                  style={{
                    maxHeight: "calc(5rem + 0.5vw)",
                  }}
                >
                  <div className="col-11 col-md-12 d-flex justify-content-between align-items-center ">
                    <div className="col-5" style={{ textAlign: "left" }}>
                      <span className="">
                        {" "}
                        Prof. {trainer.trainer.full_name}
                      </span>
                      <br />
                      <span className="textTeacherOne">
                        {trainer.capacity - trainer.current_capacity}{" "}
                        {trainer.capacity - trainer.current_capacity == 1
                          ? "CUPO"
                          : "CUPOS"}{" "}
                      </span>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: "2%",
                      }}
                    >
                      <span value={trainer.id} className="m-auto d-flex ">
                        {Moment(trainer.schedule_date).format("YYYY-MM-DD")}{" "}
                        <br /> {Moment(trainer.schedule_date).format("hh:mm a")}
                      </span>
                    </div>
                    <div className="ml-3">
                      <input
                        type="radio"
                        id="radio"
                        value={trainer.schedule_date}
                        onClick={(e) => {
                          setearvalorgrupal(e.target.value);
                        }}
                        name="teachers"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className=" classTeachers  w-100 ">
            {profesores &&
              profesores.map((e, i) => (
                <div
                  id={"grupalMobileTut"}
                  key={"tutorialteacher" + i}
                  className="d-flex  border-color justify-content-center paddingGrowTeacher w-100 changecolortable"
                  style={{
                    maxHeight: "calc(5rem + 0.5vw)",
                  }}
                >
                  <div className="col-11 col-md-12 d-flex justify-content-between align-items-center ">
                    <div className="col-5" style={{ textAlign: "left" }}>
                      <span className="textTeacherOne">Prof. Hugo Ortiz</span>
                      <br />
                      <span className="textTeacherOne">10 cupos</span>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: "2%",
                      }}
                    >
                      <span value={e.id} className="m-auto d-flex ">
                        Agosto 17 <br />
                        9:00 / 10:00 am
                      </span>
                    </div>

                    <div className="ml-3">
                      <input
                        type="radio"
                        id="radio"
                        value={e.id}
                        name="teachers"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </PerfectScrollbar>
    );
  };
  return (
    <>
      <div
        className="d-none d-md-flex col-9"
        style={{
          background: "white",
          height: "100%",

          borderRadius: "20px",

          justifyContent: "center",
        }}
      >
        <div
          className="col-6 d-flex"
          style={{
            position: "relative",
            justifyContent: "center",
            padding: "5%",
          }}
        >
          <img
            className="w-100"
            style={{ padding: "0 4%", backgroundImage: "cover" }}
            src={
              process.env.PUBLIC_URL + "/img/mascarareservafinal.png"
              // Aquí se pasa la propiedad cover_picture del objeto
            }
            alt=""
          />

          <div
            style={{ top: "11%", position: "absolute", left: "18%" }}
            className="w-100"
          >
            <div className="d-flex my-auto ">
              <div
                className=""
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "1vmin",

                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "1rem" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div className=" my-auto mx-2 d-flex flex-column gap-2 justify-content-center  ">
                <span
                  className="mt-2"
                  style={{ color: "#394F65", fontSize: "calc(0.7rem + 0.3vw)" }}
                >
                  Entrenamiento
                </span>
                <span
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "calc(1.3rem + 0.3vw)",
                  }}
                >
                  Zumba grupal
                </span>{" "}
                <button
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "300",
                    maxWidth: "60%",
                    padding: "1% 0%",
                  }}
                >
                  GRATIS
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            gap: "2%",
            padding: "5% 5% 5% 0",
          }}
        >
          <div
            className="col-6 d-flex w-100"
            style={{
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              gap: "2%",
              padding: "0 4%",
            }}
          >
            <span
              style={{
                color: "#394F65",

                fontSize: "calc(1rem + 0.3vw)",
              }}
            >
              {" "}
              Selecciona tu
            </span>

            <span
              style={{
                color: "#394F65",
                fontWeight: "600",
                fontSize: "calc(1rem + 0.3vw)",
              }}
            >
              día y hora de clase
            </span>
            <div
              className="d-flex flex-column justify-content-strench h-100"
              style={{ overflowY: "auto" }}
              id={"SelectHourTutorial"}
            >
              {trainers &&
                trainers.map((trainer) => (
                  <div
                    key={trainer.id}
                    className="d-flex position-relative changecolortable my-1"
                    style={{
                      justifyContent: "space-around",
                      borderRadius: "40px",
                      textAlign: "center",
                      padding: "2.5%",
                    }}
                  >
                    <div
                      className="d-flex "
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          color: "#394F65",

                          fontSize: "calc(0.7rem + 0.2vw)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Prof. {trainer.name}
                      </span>
                      <span
                        style={{
                          textAlign: "left",
                          color: "#394F65",

                          fontSize: "calc(0.7rem + 0.2vw)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        20 CUPOS{" "}
                      </span>
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        padding: "2%",
                      }}
                    >
                      {/* <span>{trainer.dia}</span>
  <span>{trainer.hora}</span> */}
                      {tomorrow}
                    </div>
                    <div className="my-auto d-flex  position-relative">
                      <input
                        name="radioteachers"
                        type="radio"
                        // onChange={() => handleTrainerChange(trainer)}
                      ></input>{" "}
                    </div>
                  </div>
                ))}
            </div>
            <div
              className="my-2"
              style={{
                color: "#394F65",
                fontWeight: "600",
                fontSize: "1.5vmin",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  color: "#394F65",

                  fontSize: "calc(0.7rem + 0.3vw)",
                  whiteSpace: "nowrap",
                }}
              >
                Costo total de tu reserva - 0 créditos
              </span>
            </div>
            <div className="my-0 ">
              <ButtonMob
                icon={"ms-2 bi bi-calendar3 right"}
                className=""
                text="Reserva"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <div
          className="containerBGClase"
          style={{
            backgroundSize: "cover",
            height: "35vh",
            backgroundImage: `url(${infoTraining?.main_picture})`,
          }}
        >
          <div
            className="w-auto m-auto d-flex justify-content-center"
            style={{
              position: "absolute",
              top: "5%",
              left: "50%",
              height: "7%",
              transform: "translate(-50%, 5%)",
              alignItems: "center",
            }}
          >
            {" "}
            <img
              style={{ width: "15vmin" }}
              src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
            />
          </div>
          <div className="w-70 w-md-100 ubication_arrow_title">
            <div className="d-row my-auto ">
              <div
                onClick={() => navigate(-1)}
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "1vmin",
                  width: "fit-content",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "1rem" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div className=" my-auto mx-2 d-flex flex-column gap-2 justify-content-center ">
                <span className="mt-2  text_arrow_top">Entrenamiento</span>
                <span
                  style={{
                    width: "5rem",
                    color: "#394F65",
                    fontWeight: "bold",
                    lineHeight: "100%",
                    fontSize: "calc(1.3rem + 0.3vw)",
                  }}
                >
                  {infoTraining ? infoTraining.name : "Zumba"}
                </span>{" "}
                {infoTraining ? null : (
                  <button
                    style={{
                      background: "var(--elevva)",
                      border: "1px solid var(--elevva)",
                      borderRadius: "20px",
                      color: "var(--white)",
                      fontWeight: "300",
                      maxWidth: "60%",
                      padding: "1% 0%",
                    }}
                  >
                    GRATIS
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="mobile-welcome-login-row  text-center w-100"
          style={{ gap: "5%" }}
        >
          <span
            className="mt-2"
            style={{
              color: "var(--elevvaTextTitle)",
              fontSize: "calc(1.3rem + 0.4vw)",
            }}
          >
            {" "}
            Selecciona tu <br />
            <span
              style={{
                fontWeight: "500",
                color: "var(--elevvaTextTitle)",
                fontSize: "calc(1.3rem + 0.4vw)",
              }}
            >
              día y hora de clase{" "}
            </span>
          </span>
          <ComponentTeacher />
          <div
            className="my-2"
            style={{
              color: "#394F65",
              fontWeight: "600",
              fontSize: "1.5vmin",
            }}
          >
            {/* <span
              style={{
                textAlign: "center",
                color: "#394F65",
                fontSize: "calc(0.9rem + 0.4vw)",
                whiteSpace: "nowrap",
              }}
            >
              Costo total de tu reserva -{" "}
              {infoTraining?.credits ? infoTraining.credits : 0} créditos
            </span> */}
          </div>
          <div className="my-0 ">
            <ButtonMob
              icon={"ms-2 bi bi-calendar3 right"}
              className=""
              text="Reserva"
              handleClick={() => searchClasegrupales(earvalorgrupal)}
            />
          </div>
        </div>
      </div>
    </>
  );
};


export function whatMonth(day){
  if(day>=0 && day<29){
    return 1
  }
  else if(day>28 && day<57){
    return 2
  }
  else if(day>56 && day<85){
    return 3
  }
  
  else if(day>84 && day<113){
    return 4
  }
  else if(day>112 && day<141){
    return 5
  }
  else if(day>140 && day<169){
    return 6
  }
  else if(day>168 && day<197){
    return 7
  }
  else return 8
  
  }
  export function whatWeek(day){
    if(day>=0 && day<29){
      return 0
    }
    else if(day>28 && day<57){
      return 4
    }
    else if(day>56 && day<85){
      return 8
    }
    
    else if(day>84 && day<113){
      return 12
    }
    else if(day>112 && day<141){
      return 16
    }
    else if(day>140 && day<169){
      return 20
    }
    else if(day>168 && day<197){
      return 24
    }
    else return 28
    
    }

  export function whatMonthONturn(day){
    if(day>=0 && day<29){
      return 0
    }
    else if(day>28 && day<57){
      return 28
    }
    else if(day>56 && day<85){
      return 56
    }
    
    else if(day>84 && day<113){
      return 84
    }
    else if(day>112 && day<141){
      return 112
    }
    else if(day>140 && day<169){
      return 140
    }
    else if(day>168 && day<197){
      return 168
    }
    else return 196
    
    }

    export function whatmonthSwiper(day){
      if(day>=0 && day<29){
        return 0
      }
      else if(day>28 && day<57){
        return 1
      }
      else if(day>56 && day<85){
        return 2
      }
      
      else if(day>84 && day<113){
        return 3
      }
      else if(day>112 && day<141){
        return 4
      }
      else if(day>140 && day<169){
        return 5
      }
      else if(day>168 && day<197){
        return 6
      }
      else return 7
      
      }