import { useNavigate, useParams } from "react-router-dom";
import { instance, laptop575, Loadingspinner, ModalInfoVideosFilter } from "../../utils/utils";
import { useEffect, useState, useRef } from "react";
import { AllImagenes } from "../Ejercicios/allvideos";
import { Videos_two } from "../Ejercicios/othervideos";
import { ReactComponent as BackArrow } from "../../assets/newIcons/arrow-back.svg";
import { ReactComponent as BackArroWhite } from "../../assets/newIcons/arrow-back-white.svg"



const VerTipo = (props) => {
  const history = useNavigate();
  const { tipo } = useParams();
  const searchText = useRef(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [Category, setCategory] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const images = props.AllVideos;
  const Ocultar = () => {
    setModalInfo(null);
  };


  const [allTopics, setallTopics] = useState(null);
  function changeOptionVideos(valuesearch) {
    
    if (valuesearch != "") {
      instance
      .get(`videos/programs/?search=${valuesearch}`)
      .then((response) => {  
        console.log(response.data) 
        const all_topics = response.data;
       setallTopics(all_topics);
      
      })
      .catch((error) => {
        console.log(error);
      });
    } else {

      instance
      .get(`videos/programs`)
      .then((response) => {   
        const all_topics = response.data;
       setallTopics(all_topics)
      })
      .catch((error) => {
        console.log(error);
      });

     
    }
    // setfiltervideos(videofiltro);
  }

  const [inputs, setInputs] = useState({
    ["workout_equipment"]: [[]],
    ["main_topic"]: [[]],                    
    ["workout_zone"]: [[]],
    ["skill_level"]: [[]],
    ["workout_time"]: [[]],
    ["workout_type"]: [[]],
    ["discipline"]: [[]],
  });
 
  useEffect(() => {
   
    var pather = localStorage.filterCompany;
    if (pather) {
      let filtroa = pather.split(",")[0];
      let filtrob = pather.split(",")[1];

      setInputs((val) => ({ ...val, [filtroa]: [[filtrob]] }));

      instance
      .get(`videos/programs/?${filtroa}=${filtrob}`)
      .then((response) => {   
        const all_topics = response.data;
        setisLoading(false)
       setallTopics(all_topics);
       localStorage.removeItem("filterCompany");
      })
      .catch((error) => {
        console.log(error);
      });
    
    }else{
      instance
      .get(`videos/programs`)
      .then((response) => {   
        const all_topics = response.data;
        setisLoading(false);
        console.log(all_topics)
       setallTopics(all_topics)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [localStorage]);
  function filtrarButtons(valuesearch, local) {
    var pepe = inputs[local][0].filter((e) => e != valuesearch);
    setInputs((val) => ({
      ...val,
      [local]: [pepe],
    }));
  }
  function conocerNumberReverse(a,b){
    if(b== "skill_level"){
      let stringSearch='';
      a.forEach(function(elemento, indice) {

        if(elemento== 10 || elemento== "Bajo"){
          stringSearch += 10
        }else if(a == 20 || elemento== 'Medio')
        {stringSearch += 20 }
         else stringSearch += 30
         if (indice !== a.length - 1) {
          stringSearch += ',';
        }
      })
    return stringSearch
    }else if(b=='workout_time'){
      let stringSearch='';
      a.forEach(function(elemento, indice) {

        if(elemento== 10 || elemento =="Entre 10 a 30 minutos")
        { stringSearch += 10;
        }
        else if(elemento == 20 || elemento == 'Entre 30 a 60 minutos')
        { stringSearch += 20 
        }
        else  stringSearch += 30;
        if (indice !== a.length - 1) {
          stringSearch += ',';
        }
      })
      return stringSearch;
    }else if(b== "workout_zone"){
      let stringSearch='';
      a.forEach(function(elemento, indice) {
        if(elemento== 10 || elemento=="Tren inferior")
        { stringSearch += 10;}
        else if(elemento == 20 || elemento =='Abdomen')
        { stringSearch += 20; }
        else if(elemento == 30 || elemento =='Tren superior' )
        { stringSearch += 30; }
        else return  stringSearch += 40;
 if (indice !== a.length - 1) {
          stringSearch += ',';
        }
      });
    return stringSearch
    }else if(b== "workout_type"){
      let stringSearch='';
      a.forEach(function(elemento, indice) {

        if(elemento== 10 || elemento =="Cardiovascular")
        {stringSearch += 10}
        else if(elemento == 20 || elemento =='Fuerza')
        {stringSearch += 20 }
        else if(elemento == 30 || elemento =='Movilidad' )
        {stringSearch += 30}
        else stringSearch += 40
        if (indice !== a.length - 1) {
          stringSearch += ',';
        }
      })
      return stringSearch
    }
    else if(b== "discipline"){
      let stringSearch='';
      a.forEach(function(elemento, indice) {
        if(elemento== 10 || elemento == "Yoga")
        {stringSearch += 10}
        else if(elemento == 20 || elemento == 'Funcional')
        {stringSearch += 20 }
        else if(elemento == 30 || elemento== 'Fuerza' )
        {stringSearch += 30}
        else if(elemento == 40 || elemento == 'Balance' )
        {stringSearch += 40}
        else if(elemento == 50 || elemento == 'Bajo impacto' )
        {stringSearch += 50}
        else if(elemento == 60 ||elemento == 'Cardio funcional' )
        {stringSearch += 60}
        else if(elemento == 70 || elemento == 'HIIT' )
        {stringSearch += 70}
        else if(elemento == 80 || elemento == 'Pilates' )
        {stringSearch += 80}
        else if(elemento == 90 || elemento == 'Nutrición' )
        {stringSearch += 90}
        else stringSearch += 100;
        if (indice !== a.length - 1) {
          stringSearch += ',';
        }
      })
    return stringSearch
     
    } else if(b== "main_topic"){
      let stringSearch='';
      a.forEach(function(elemento, indice) {
        if(elemento== 10 || elemento == "Entrenamiento")
        {stringSearch += 10}
     else stringSearch += 20;
        if (indice !== a.length - 1) {
          stringSearch += ',';
        }
      })
    return stringSearch
     
    }
    
    else{
      let stringSearch='';
      a.forEach(function(elemento, indice) {
        if(elemento== 10 || elemento == "Mancuernas")
        {stringSearch += 10}
        else if(elemento == 20 || elemento == 'Bandas cerradas de resistencia')
        {stringSearch += 20 }
        else if(elemento == 30 || elemento == 'Mat de yoga' )
        {stringSearch += 30 }
        else if(elemento == 40 || elemento == 'Colchoneta' )
        {stringSearch += 40}
        else if(elemento == 50 || elemento == 'Muñequeras de peso' )
        {stringSearch += 50 }
        else if(elemento == 60 || elemento == 'Fit ball / Balón de pilates' )
        {stringSearch += 60}
        else if(elemento== 70 || elemento == 'Bandas de resistencia con mangos' )
        {stringSearch += 70 }
        else if(elemento == 80 || elemento == 'Guantes de boxeo' )
        {stringSearch += 80 }
        else if(elemento == 90 || elemento == 'Lazo de salto' )
        {stringSearch += 90 }
        else if(elemento == 100 || elemento== 'Set de pump' )
        {stringSearch += 100 }
        else if(elemento == 110 ||elemento == 'Caminadora eléctrica' )
        {stringSearch +=110 }
        else if(elemento == 120 ||elemento == 'Bicicleta estática' )
        {stringSearch +=120 }
        else if(elemento == 130 || elemento == 'Implementos del hogar' )
        {stringSearch +=130 }
        else stringSearch += 140;
        if (indice !== a.length - 1) {
          stringSearch += ',';
        }
      })
      return stringSearch
    }
      }
    //   function conocerNumberReverse(a,b){
    //     if(b== "skill_level"){
    //       if(a== 10 || a== "Bajo"){return 10}else if(a == 20 || a== 'Medio'){return 20 }else return 30
        
    //     }else if(b=='workout_time'){
    //       if(a== 10 || a =="Entre 10 a 30 minutos")
    //       {return 10}
    //       else if(a == 20 || a == 'Entre 30 a 60 minutos')
    //       {return 20 }
    //       else return 30
    //     }else if(b== "workout_zone"){
    //       let stringSearch='';
    //       a.forEach(function(elemento, indice) {
    //         if(elemento== 10 || elemento=="Tren inferior")
    //         { stringSearch += 10;}
    //         else if(elemento == 20 || elemento =='Abdomen')
    //         { stringSearch += 20; }
    //         else if(elemento == 30 || elemento =='Tren superior' )
    //         { stringSearch += 30; }
    //         else return  stringSearch += 40;
    //  if (indice !== a.length - 1) {
    //           stringSearch += ',';
    //         }
    //       });
    //     return stringSearch
    //     }else if(b== "workout_type"){
    //       if(a== 10 || a =="Cardiovascular")
    //       {return 10}
    //       else if(a == 20 || a =='Fuerza')
    //       {return 20 }
    //       else if(a == 30 || a =='Movilidad' )
    //       {return 30}
    //       else return 40
    //     }
    //     else if(b== "discipline"){
    //       if(a== 10 || a == "Yoga")
    //       {return 10}
    //       else if(a == 20 || a == 'Funcional')
    //       {return 20 }
    //       else if(a == 30 || a == 'Fuerza' )
    //       {return 30 }
    //       else if(a == 40 || a == 'Balance' )
    //       {return 40 }
    //       else if(a == 50 || a == 'Bajo impacto' )
    //       {return 50}
    //       else if(a == 60 || a == 'Cardio funcional' )
    //       {return 60 }
    //       else if(a == 70 || a == 'HIIT' )
    //       {return 70}
    //       else if(a == 80 || a == 'Pilates' )
    //       {return 80 }
    //       else if(a == 90 || a == 'Nutrición' )
    //       {return 90 }
    //       else return 100
    //     }else{
    //       if(a== 10 || a == "Mancuernas")
    //       {return 10}
    //       else if(a == 20 || a == 'Bandas cerradas de resistencia')
    //       {return 20 }
    //       else if(a == 30 || a == 'Mat de yoga' )
    //       {return 30 }
    //       else if(a == 40 || a == 'Colchoneta' )
    //       {return 40}
    //       else if(a == 50 || a == 'Muñequeras de peso' )
    //       {return 50 }
    //       else if(a == 60 || a == 'Fit ball / Balón de pilates' )
    //       {return 60}
    //       else if(a == 70 || a == 'Bandas de resistencia con mangos' )
    //       {return 70 }
    //       else if(a == 80 || a == 'Guantes de boxeo' )
    //       {return 80 }
    //       else if(a == 90 || a == 'Lazo de salto' )
    //       {return 90 }
    //       else if(a == 100 || a == 'Set de pump' )
    //       {return 100 }
    //       else if(a == 110 || a == 'Caminadora eléctrica' )
    //       {return 110 }
    //       else if(a == 120 || a == 'Implementos del hogar' )
    //       {return 120 }
    //       else return 130
    //     }
    //       }
  var handleChange = (event) => {
    let name = Object.keys(event);
    let value = Object.values(event);

    setInputs((val) => ({ ...val, [name]: value }));
  };
  var handleInputCategory = (event) => {
    setCategory(event);
  };

   useEffect(() => {
    let workout=inputs["workout_zone"][0][0]&&inputs["workout_zone"][0][0].length>0?conocerNumberReverse(inputs["workout_zone"][0],'workout_zone'):'';
    
    let equipment=inputs["workout_equipment"][0][0]&&inputs["workout_equipment"][0][0].length>0?conocerNumberReverse(inputs["workout_equipment"][0],'workout_equipment'):'';
    let skill=inputs["skill_level"][0][0]&&inputs["skill_level"][0][0].length>0?conocerNumberReverse(inputs["skill_level"][0],'skill_level'):'';

    let time=inputs["workout_time"][0][0]&&inputs["workout_time"][0][0].length>0?conocerNumberReverse(inputs["workout_time"][0],'workout_time'):'';

    let type=inputs["workout_type"][0][0]&&inputs["workout_type"][0][0].length>0?conocerNumberReverse(inputs["workout_type"][0],'workout_type'):'';

    let disciplina=inputs["discipline"][0][0]&&inputs["discipline"][0][0].length>0?conocerNumberReverse(inputs["discipline"][0],'discipline'):'';
    let mainTopic=inputs["main_topic"][0][0]&&inputs["main_topic"][0][0].length>0?conocerNumberReverse(inputs["main_topic"][0],'main_topic'):'';

    instance
      .get(`videos/programs/?main_topic__in=${mainTopic}&workout_zone__in=${workout}&workout_equipment__in=${equipment}&skill_level__in=${skill}&workout_time__in=${time}&workout_type__in=${type}&discipline__in=${disciplina}`)
      .then((response) => {   
        const all_topics = response.data;
       setallTopics(all_topics);
      
      })
      .catch((error) => {
        console.log(error);
      });

  },[inputs]);

  function conocerNameFilter(a,b){
    console.log(a,"what")
if(b== "skill_level"){
  if(a== 10 || a== "Bajo"){return "Bajo"}else if(a == 20 || a== 'Medio'){return 'Medio' }else return "Alto"

}else if(b== "main_topic"){
  if(a== 10 || a== "Entrenamiento"){return "Entrenamiento"}
  else return "Nutrición"

}

else if(b=='workout_time'){
  if(a== 10 || a =="Entre 10 a 30 minutos")
  {return "Entre 10 a 30 minutos"}
  else if(a == 20 || a == 'Entre 30 a 60 minutos')
  {return 'Entre 30 a 60 minutos' }
  else return "Más de 60 minutos"
}else if(b== "workout_zone"){
  if(a== 10 || a=="Tren inferior")
  {return "Tren inferior"}
  else if(a == 20 || a =='Abdomen')
  {return 'Abdomen' }
  else if(a == 30 || a =='Tren superior' )
  {return 'Tren superior' }
  else return "Todo el cuerpo"
}else if(b== "workout_type"){
  if(a== 10 || a =="Cardiovascular")
  {return "Cardiovascular"}
  else if(a == 20 || a =='Fuerza')
  {return 'Fuerza' }
  else if(a == 30 || a =='Movilidad' )
  {return 'Movilidad' }
  else return "Flexibilidad"
}
else if(b== "discipline"){
  if(a== 10 || a == "Yoga")
  {return "Yoga"}
  else if(a == 20 || a == 'Funcional')
  {return 'Funcional' }
  else if(a == 30 || a == 'Fuerza' )
  {return 'Fuerza' }
  else if(a == 40 || a == 'Balance' )
  {return 'Balance' }
  else if(a == 50 || a == 'Bajo impacto' )
  {return 'Bajo impacto' }
  else if(a == 60 || a == 'Cardio funcional' )
  {return 'Cardio funcional' }
  else if(a == 70 || a == 'HIIT' )
  {return 'HIIT' }
  else if(a == 80 || a == 'Pilates' )
  {return 'Pilates' }
  else if(a == 90 || a == 'Nutrición' )
  {return 'Nutrición' }
  else return "Mente"
}else{
  if(a== 10 || a == "Mancuernas")
  {return "Mancuernas"}
  else if(a == 20 || a == 'Bandas cerradas de resistencia')
  {return 'Bandas cerradas de resistencia' }
  else if(a == 30 || a == 'Mat de yoga' )
  {return 'Mat de yoga' }
  else if(a == 40 || a == 'Colchoneta' )
  {return 'Colchoneta' }
  else if(a == 50 || a == 'Muñequeras de peso' )
  {return 'Muñequeras de peso' }
  else if(a == 60 || a == 'Fit ball / Balón de pilates' )
  {return 'Fit ball / Balón de pilates' }
  else if(a == 70 || a == 'Bandas de resistencia con mangos' )
  {return 'Bandas de resistencia con mangos' }
  else if(a == 80 || a == 'Guantes de boxeo' )
  {return 'Guantes de boxeo' }
  else if(a == 90 || a == 'Lazo de salto' )
  {return 'Lazo de salto' }
  else if(a == 100 || a == 'Set de pump' )
  {return 'Set de pump' }
  else if(a == 110 || a == 'Caminadora eléctrica' )
  {return 'Caminadora eléctrica' }
  else if(a == 120 || a == 'Implementos del hogar' )
  {return 'Implementos del hogar' }
  else return "Sin equipos peso corporal"
}
  }

  return (
    <div
      className="d-flex col-12 col-md-10 m-auto"
      style={{
        height: "100vh",
      }}
    >
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          
          {tipo == "Buscar" ? (
            <div
              className="containerBackgroundCreateuserSearch d-flex"
              style={{
                padding: "0% 4%",
                justifyContent: "center",
                flexDirection: "column",
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/img/buscaralto.jpg"
                })`,
              }}
            >
            <div className="d-flex" style={{alignItems:"center"
                
              }}>
        {" "}
        <button
          onClick={() => history(-1)}
          style={{ background: "transparent", border: "none" }}
        >
          <BackArroWhite width={30}  />
        </button>
        <span className="title my-auto fw-light" style={{color:"white"}}>
         Regresar
        </span>
      </div>
              <span
                style={{ fontWeight: "700", color: "#ffff", fontSize: "40px" }}
              >
                Buscar
              </span>
              <div style={{background:"transparent"}}
                className="form searchEjercicio py-2 d-flex"
                to="/videos/Buscar"
              >
                <input
                  placeholder="Buscar..."
                  ref={searchText}
                  type="search"
                  className="input_searchvideossearch ejercicios_c  py-2 "
                  onChange={(e) => changeOptionVideos(e.target.value)}
                ></input>
                <img
                  onClick={() =>
                    setModalInfo(
                      <ModalInfoVideosFilter
                         disciplinas={allTopics.map((e) => e.name)}
                        error="false"
                        handleClick={() =>
                          setInputs({
                            ["workout_equipment"]: [[]],
                            ["main_topic"]: [[]],
                            ["workout_zone"]: [[]],
                            ["skill_level"]: [[]],
                            ["workout_time"]: [[]],
                            ["workout_type"]: [[]],
                            ["discipline"]: [[]],
                          })
                        }
                        handleInputCategory={(e) => handleInputCategory(e)}
                        handleInput={(e) => handleChange(e)}
                        handleCancel={() => {
                          Ocultar();
                        }}
                      />
                    )
                  }
                  className="mx-2"
                  style={{ width: "35px", cursor: "pointer" }}
                  src={process.env.PUBLIC_URL + "/img/filter.svg"}
                />
              </div>
              <div
                className="d-flex my-1"
                style={{ justifyContent: "flex-start" }}
              >
                {Object.entries(inputs).map((e, index) => (
                  <div key={"mayor" + index}>
                    {e[1][0].length > 0
                      ? e[1][0].map((but, i) => (
                          <button
                            key={"but" + i}
                            onClick={() => filtrarButtons(but, e[0])}
                            className="py-1 mx-1"
                            style={{
                              borderRadius: "20px",
                              border: "1px solid white",
                              background: "transparent",
                              color: "white",
                            }}
                          >
                            <span className="ms-1">{conocerNameFilter(but,e[0])} </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-x me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </button>
                        ))
                      : null}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {allTopics &&allTopics.length > 0 ? (
            <div
              id="routeA"
              style={{
                paddingLeft: "5%",
              }}
            >
              <AllImagenes
                textTitleOne=""
                textTitleTwo={tipo == "Buscar" ? "Programas" : tipo}
                id={"vertipo"}
                contenido="search"
                videos={allTopics}
                textealo={tipo}
                className="sliderNetFlixvideocomidas"
              />
            </div>
          ) : (
            isLoading ? <div className="mt-5"> <Loadingspinner
        customStyle="grow"
        size={ "5rem"}
      /> </div> :  <div
              className="w-100 text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                fontSize: "25px",
                height: "calc(100vh - 392px)",
              }}
            >
              <span
                className="fw-bold"
                style={{
                  color: "#394F65",
                }}
              >
                No pudimos filtrar con esta búsqueda.
              </span>
            </div>
          )}
         
          <div
            id="routeB"
            style={
              tipo == "Buscar"
                ? {
                    backgroundColor: "#ffff",
                    paddingLeft: "5%",
                    paddingTop: "3%",
                  }
                : {
                    backgroundColor: "#FDF1E8",
                    paddingLeft: "5%",
                    paddingTop: "3%",
                  }
            }
          >
            {allTopics &&
              allTopics
              
                .map((e) => (
                  <Videos_two
                    textTitleOne=""
                    textTitleTwo={e.name}
                
                    videosTime={[e]}
                    key={"videos" + e.id}
                    id={e.id}
                  />
                ))}
          </div>
        </div>
      ) : (
          <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {" "}
          <div
            className="bannervideos d-flex py-3"
            style={{
              background: "#ffff",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
            }}
          >
            <div className="mx-3 my-1 d-flex">
          {" "}
          <button
            onClick={() => history(-1)}
            className={"button_w_h_back"}
            style={{ background: "transparent", border: "none" }}
          >
            <BackArrow width={40}  />
          </button>
          <span className="title my-auto fw-light">
           Regresar
          </span>
        </div>
            
          </div>
          <div className="w-100">
            <div
              className="containerBackgroundCreateuserre d-flex "
              style={{
                padding: "0% 5%",
                justifyContent: "center",
                flexDirection: "column",

                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/img/masksearchmobile.jpg"
                })`,
              }}
            >
              <span
                className="mt-3 fw-bold"
                style={{ fontWeight: "700", color: "#ffff", fontSize: "20px" }}
              >
                Buscar{" "}
              </span>
              <div style={{background:"transparent"}}
                className="form searchEjercicio py-2 d-flex"
                to="/videos/Buscar"
              >
                <input
                  placeholder="Buscar..."
                  ref={searchText}
                  type="search"
                  className="input_searchvideossearch ejercicios_c  py-1 "
                  onChange={(e) => changeOptionVideos(e.target.value)}
                ></input>
                <img
                  onClick={() =>
                    setModalInfo(
                      <ModalInfoVideosFilter
                      disciplinas={allTopics.map((e) => e.name)}
                        error="false"
                        handleClick={() =>
                          setInputs({
                            ["workout_equipment"]: [[]],
                            ["main_topic"]: [[]],
                            ["workout_zone"]: [[]],
                            ["skill_level"]: [[]],
                            ["workout_time"]: [[]],
                            ["workout_type"]: [[]],
                            ["discipline"]: [[]],
                          })
                        }
                        handleInputCategory={(e) => handleInputCategory(e)}
                        handleInput={(e) => handleChange(e)}
                        handleCancel={() => {
                          Ocultar();
                        }}
                      />
                    )
                  }
                  className="mx-2"
                  style={{ width: "30px", cursor: "pointer" }}
                  src={process.env.PUBLIC_URL + "/img/filter.svg"}
                />
              </div>
              <div
                className="d-flex mb-3 mt-2"
                style={{
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {Object.entries(inputs).map((e, index) => (
                  <div key={"entries" + index}>
                    {e[1][0].length > 0
                      ? e[1][0].map((but, i) => (
                          <button
                            key={i}
                            onClick={() => filtrarButtons(but, e[0])}
                            className="py-auto d-flex m-1"
                            style={{
                              borderRadius: "20px",
                              border: "1px solid white",
                              background: "transparent",
                              color: "white",
                              alignItems:"center"
                            }}
                          >
                            <span className="ms-1">{conocerNameFilter(but,e[0])} </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-x me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </button>
                        ))
                      : null}
                  </div>
                ))}
              </div>
           
            </div>
          </div>
          {allTopics &&allTopics.length > 0 ? (
            <div
              id="routeA"
              style={{
                paddingLeft: "5%",
              }}
            >
              <AllImagenes
                textTitleOne="Nuestros"
                textTitleTwo={tipo == "Buscar" ? "Programas" : tipo}
                id={"pin"}
                contenido="search"
                videos={allTopics}
                textealo={tipo}
                className="sliderNetFlixvideocomidas"
              />
            </div>
          ) : (
            isLoading ? <div className="mt-3"> <Loadingspinner
        customStyle="grow"
        size={ "3rem"}
      /> </div> :   <div
              className="w-100 text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                fontSize: "25px",
                height: "calc(100vh - 392px)",
              }}
            >
              <span
                className="fw-bold"
                style={{
                  color: "#394F65",
                }}
              >
                No pudimos filtrar con esta búsqueda.
              </span>
            </div>
          )}
          <br />
          <div
            id="routeB"
            style={
              tipo == "Buscar"
                ? {
                    backgroundColor: "#ffff",
                    paddingLeft: "5%",
                    paddingTop: "3%",
                  }
                : {
                    backgroundColor: "#FDF1E8",
                    paddingLeft: "5%",
                    paddingTop: "3%",
                  }
            }
          >
            {allTopics &&
              allTopics
               
                .map((e, index) => (
                  <Videos_two
                    textTitleOne="Programa"
                    id={e.id}
                    key={"filtervideos" + e.id}
                    textTitleTwo={e.name}
                    contenido={e.category}
                    videosTime={[e]}
                  />
                ))}
          </div>
        </div>
      )}
      <br/>
      {modalInfo}{" "}
    </div>
  );
};
export default VerTipo;
