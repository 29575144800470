import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import "./documents.css";
import { useEffect, useState, useRef } from "react";

import { ButtonMob, instance } from "../../utils/utils";

export const OnboardingTrainer = (props) => {
  const navigate = useNavigate();
  const elevvaT = props.elevvaT;
  var estudiosFilter = "Certificado de estudios";
  var cedulaFilter = "Cédula";
  var bankFilter = "Certificado bancario";
  var rutFilter = "RUT";
  var estudiosId = elevvaT("Study");
  var cedulaId = elevvaT("cardId");
  var bankId = elevvaT("bank");
  var rutId = elevvaT("RUT");
  const [acumulado, setacumulado] = useState([]);
  const [estudios, setestudios] = useState(null);
  const [estudiosRef, setestudiosRef] = useState("none");
  const [cardIdC, setcardIdC] = useState(null);
  const [cardIdCRef, setcardIdCRef] = useState("none");
  const [bank, setbank] = useState(null);
  const [bankRef, setbankRef] = useState("none");
  const [rut, setrut] = useState(null);
  const [rutRef, setrutRef] = useState("none");
  const array_documents = [
    {
      id: estudiosId,
      nameId: estudios,
      filterId: estudiosFilter,
      refId: estudiosRef,
    },
    {
      id: cedulaId,
      nameId: cardIdC,
      filterId: cedulaFilter,
      refId: cardIdCRef,
    },
    {
      id: bankId,
      nameId: bank,
      filterId: bankFilter,
      refId: bankRef,
    },
    {
      id: rutId,
      nameId: rut,
      filterId: rutFilter,
      refId: rutRef,
    },
  ];
  useEffect(() => {
    let filtradoEstudios = acumulado.filter((e) =>
      Object.keys(e).includes(estudiosFilter)
    );

    let filtradoCedula = acumulado.filter((e) =>
      Object.keys(e).includes(cedulaFilter)
    );

    let filtradoRut = acumulado.filter((e) =>
      Object.keys(e).includes(rutFilter)
    );

    let filtradoBank = acumulado.filter((e) =>
      Object.keys(e).includes(bankFilter)
    );
    if (filtradoEstudios.length > 0) {
      setestudios(filtradoEstudios[0][estudiosFilter].name);
    }
    if (filtradoCedula.length > 0) {
      setcardIdC(filtradoCedula[0][cedulaFilter].name);
    }
    if (filtradoRut.length > 0) {
      setrut(filtradoRut[0][rutFilter].name);
    }
    if (filtradoBank.length > 0) {
      setbank(filtradoBank[0][bankFilter].name);
    }
  }, [acumulado]);
  const handleSubmit = (event) => {
    if (acumulado.length < 4) {
      if (!estudios) {
        setestudiosRef("inherit");
        setTimeout(() => {
          setestudiosRef("none");
        }, 5000);
      }
      if (!cardIdC) {
        setcardIdCRef("inherit");
        setTimeout(() => {
          setcardIdCRef("none");
        }, 5000);
      }
      if (!bank) {
        setbankRef("inherit");
        setTimeout(() => {
          setbankRef("none");
        }, 5000);
      }
      if (!rut) {
        setrutRef("inherit");
        setTimeout(() => {
          setrutRef("none");
        }, 5000);
      }
      event.preventDefault();
    } else {
      const arrayEntries = acumulado.map((e) => Object.entries(e));
      arrayEntries.forEach(function (value) {
        const formDataDocuments = new FormData();
        formDataDocuments.append("file", value[0][1]);
        formDataDocuments.append("name", value[0][0]);
        instance
          .post(`trainers/documentation/`, formDataDocuments, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => res.data)
          .catch((err) => console.log(err.response));
        event.preventDefault();
      });
    }
  };
  function acumulation(date) {
    return setacumulado(date);
  }

  const FileInput = (props) => {
    const id = props.id;
    const isRequired = props.isRequired;
    const onSelectDay1 = props.onSelectDay1;
    const placeholderInput = props.placeholderInput;
    const texto = props.texto;
    const classes = props.classes || "";
    const handlechange = (event) => {
      var changeCer_estudios = event.target.files[0];

      onSelectDay1((old) => {
        let totalarray = old.filter((e) => e[texto]);

        if (totalarray.length > 0) {
          return old.filter((e) => (e[texto] = changeCer_estudios));
        } else {
          return [...old, { [texto]: changeCer_estudios }];
        }
      });

      event.preventDefault();
    };

    return (
      <div id={id} className={`${classes}`}>
        <div
          className="input-group input-style-elevva-documents"
          style={{ background: "white" }}
        >
          <input
            name={id}
            style={{ background: "white", border: "none" }}
            disabled={true}
            type="text"
            placeholder={placeholderInput}
            className="form-control custom-file-input"
          />
          <label className="input-group-append mb-0">
            <span className="btn  input-file-btn">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/save_img.svg"}
                className="mx-3"
              />{" "}
              <input
                id={"sel" + id}
                name={id}
                type="file"
                onChange={(e) => handlechange(e)}
                hidden
              />
            </span>
          </label>
        </div>

        <span style={{ display: isRequired }} className="text-color-error">
          {" "}
          {elevvaT("required")}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="d-md-none   ">
        <div
          className="justify-center text-center py-3"
          style={{ background: "var(--elevvaTopNav)" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
          />
        </div>
        <div
          className=" h-100 w-100 justify-center p-3 "
          style={{ alignContent: "center", alignItems: "center" }}
        >
          <form onSubmit={handleSubmit}>
            <h1 className="text-color-title">
              {elevvaT("Attach")}
              <br />
              <strong>{elevvaT("documentation")}</strong>
            </h1>
            <hr className="mx-5" />
            <label className="text-color-subtitle my-2">
              {elevvaT("Certified-documents")}
            </label>
            {array_documents.map((e, index) => (
              <FileInput
                key={index}
                placeholderInput={e.nameId ? e.nameId : e.id}
                isRequired={e.refId}
                id={e.id}
                onSelectDay1={(date) => acumulation(date)}
                texto={e.filterId}
                classes={"my-3"}
              />
            ))}

            <ButtonMob
              colorButton={`var(--elevvabutonLogin_User)`}
              className={"button_large_extend"}
              text={elevvaT("complete_button")}
              icon={"bi bi-chevron-right"}
            ></ButtonMob>
          </form>
        </div>
      </div>
      <div className=" d-none d-md-flex col-12 ">
        <div
          className="imagen-LoginCustomer-WelcomePost col-6"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/img/StartwithUs_web.jpg"
            })`,
          }}
        ></div>

        <div className="col-6 ">
          <div
            className="box-Welcome-login text-left w-100"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/assets/img/backgroundElevva-logo.jpg"
              })`,
              backgroundSize: "cover",
              backgroundPositionX: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <form onSubmit={handleSubmit}>
              <h1 className="text-color-title">
                {elevvaT("Attach")}
                <br />
                <strong>{elevvaT("documentation")}</strong>
              </h1>
              <label className="text-color-subtitle">
                {elevvaT("Certified-documents")}
              </label>

              <div className="d-flex col-12">
                {array_documents.slice(0, 2).map((e, index) => (
                  <FileInput
                    key={index}
                    placeholderInput={e.nameId ? e.nameId : e.id}
                    isRequired={e.refId}
                    id={e.id}
                    onSelectDay1={(date) => acumulation(date)}
                    texto={e.filterId}
                    classes={"my-3 px-2 col-6"}
                  />
                ))}
              </div>
              <div className="d-flex col-12">
                {" "}
                {array_documents.slice(2, 4).map((e, index) => (
                  <FileInput
                    key={index}
                    placeholderInput={e.nameId ? e.nameId : e.id}
                    isRequired={e.refId}
                    id={e.id}
                    onSelectDay1={(date) => acumulation(date)}
                    texto={e.filterId}
                    classes={"my-3 px-2 col-6"}
                  />
                ))}
              </div>
              <ButtonMob
                colorButton={`var(--elevvabutonLogin_User)`}
                text={elevvaT("complete_button")}
                className={"button_large_extend"}
                icon={"bi bi-chevron-right"}
              ></ButtonMob>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
