import { useState } from "react";
import { useEffect } from "react";
import { ButtonMob, instance, laptop575, ModalInfo, ModalInfoProcess } from "../../utils/utils";
import { MisSesiones, ModeloResults2, TableSesions } from "./helpResults";
import moment from "moment";
import { useNavigate,useParams } from "react-router-dom";
import { Misreservas, useCompareDate } from "../Mis clases/misclases_utils";
import iconoFlechaElevva from "../../assets/newIcons/ICO-FLECHA-ELEVVA.svg";
import facebookIcon from "../../assets/newIcons/facebook.svg"
import instagramIcon from "../../assets/newIcons/instagram.svg"
import whatsappIcon from "../../assets/newIcons/whatsapp.svg"
import { useRef } from "react";
import html2canvas from 'html2canvas'
import axios from "axios";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
const Results = (props) => {
// console.log(window.location.host)
  const [copyAllEntrenamientos, setCopyAllEntrenamientos] = useState(null);
  const [Infortodo, setInfortodo] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [modalInfo, setModalInfo] = useState(null);
  const { idTraining } = useParams();
  const [horas_grafica1, sethoras1] = useState(null);
  const [maxl, setmaxl] = useState(0);
  const [min, setmin] = useState(0);
  const [axx, setaxx] = useState(0);
  const [amin, setamin] = useState(0);
  const [MadMax, setmadMax] = useState(0);
  const [age, setage] = useState(null);
  const [allcolors_5, setallcolors_5] = useState([]);
  

  let getHour = function (date) {
    return date.getTime();
  };

  var maxl0 =age? 220 - age:0;
  var axx0 = 0;
  var amin0 = 0;
  var scale0 = 0;
  const maximo = maxl0;
  const intenso = (maximo * 90) / 100;
  const moderado = (maximo * 80) / 100;
  const luz = (maximo * 70) / 100;
  const muy_suave = (maximo * 60) / 100;
  const base = (maximo * 50) / 100;
  var min0 = base;
 
  useEffect(() => {

    instance
    .get("users/current/")
    .then((response) => {
          
      const customer = response.data.customer;
      setage(customer.age)
        
      setUserData(customer);
    })
    .catch((error) => {
      console.log(error);
    });

///other

    const copyEntrenamientos = [];
    const copyDisciplines = [];
    const getData = async () => {
      await instance.get("customers/disciplines/").then(async (res) => {
        const mor = res.data;
        if (mor) {
          for (const i of mor) {
            const idDiscipline = i.discipline.id;
            const UUid = i.id;
            const kcal = i.kcal;
            const data = i.date;
            const hr_max = i.hr_max;
            const hr_med = i.hr_med;
            const status = i.status;
            copyDisciplines[UUid] = {
              id: "d" + idDiscipline,
              UUid: UUid,
              data: data,
              status: i.discipline.active,
              titulo: i.discipline.name.toLowerCase(),
              descripcion: i.discipline.summary,
              icon: i.discipline.icon,
              numeric_value: hr_med,
              duracion: i.duration,
              calorias: kcal,
            };
          }
          // console.log(copyDisciplines);
        }
      });

      await instance.get("booking/?any").then(async (res) => {
      
        const data =res.data.filter(
                (e) =>
                  e.status > 30 &&
                  e.status != 70 &&
                  e.status != 90 &&
                  e.status != 50 &&
                  e.date < moment().format()
              );

        if (data) {
         
          for (const i of data.filter((e) => e.training)) {
            const name = i.training.name;
            const description = i.training.description;
            const icon = i.training.icon;
            const UUid = i.id;
            const dataT = i.date;
            
            const img = i.training.cover_picture; //.match(/(img[\d]*b.png)/)[0]

            if (data != null) {
              copyEntrenamientos[UUid] = {
                id: UUid,
                titulo: name.toLowerCase(),
                img: img,
                UUid:UUid,
                data: dataT,
                icon: icon
              };
            }
          }
        }
      });
      
      let totalSesions=copyEntrenamientos
      .filter((e) => e)
      .concat(copyDisciplines.filter((e) => e))
 
      setCopyAllEntrenamientos(
        totalSesions.sort(useCompareDate)
      );
    };
    getData();
 
    
  }, []);

  useEffect(() => {
    if(idTraining && age){

      redirecSesion(idTraining)
    }
  }, [idTraining,age]);

async function redirecSesion(id){
  setModalInfo(
    <ModalInfoProcess
  />
  )

if(id != 'all'){
if(!id.includes("d")){
  async function startFunction(){
  let info = [];
 await instance.get(`booking/book/${id}`).then(async (res) => {
    const infoEntrenamientoPUS = res.data;
    if (infoEntrenamientoPUS) {
      console.log(infoEntrenamientoPUS)
   
   await instance.get(`booking/book/${id}/workoutlog/`).then(async (res) => {
      const infoEntrenamiento = res.data;
     
      if (infoEntrenamiento) {
      
            const daysselected = res.data;
          console.log(daysselected,base,muy_suave)
            if (daysselected.length > 0) {
              let peso_graficads = [];
              let peso_graficads1 = [];
              let maximo1 = [];
              let intenso1 = [];
              let moderado1 = [];
              let luz1 = [];
              let muy_suave1 = [];
              // setLoading_data(null);
             

                  daysselected.map((e, index) => {
                    peso_graficads[e.id] = {
                      x: getHour(new Date(e.date)),
                      y: e.heart_rate,
                    };
                  });

                  function newNumber(number, base) {
                    if (number <= base && number > 0) {
                      var numeroAleatorio =
                        Math.floor(Math.random() * (luz - 15 - base + 1)) +
                        base;
                      return numeroAleatorio;
                    } else {
                      var numeroAleatorio =
                        Math.floor(Math.random() * (muy_suave - base + 1)) +
                        base;
                      if (number > 40) {
                        return number;
                      } else {
                        if (number < 10) {
                          return null;
                        } else {
                          return numeroAleatorio;
                        }
                      }
                    }
                  }
                  daysselected.map((e, index) => {
                    // if(e.heart_rate != 0){

                    if (e.heart_rate > 5) {
                      peso_graficads1[e.id] = {
                        x: getHour(new Date(e.date)),
                        y: newNumber(e.heart_rate, base),
                      };
                    } else {
                      peso_graficads1[e.id] = {
                        x: 0,
                        y: 0,
                      };
                    }
                  });
                  function encontrarNumeroMasGrande(array) {
                    return array.reduce(
                      (max, numero) => (numero > max ? numero : max),
                      array[0]
                    );
                  }
                  let madMax = peso_graficads
                    .filter((e) => e)
                    .map((el) => el.y);

                  setmadMax(encontrarNumeroMasGrande(madMax));
               
                peso_graficads

                  .map((e) => e.y)
                  .forEach((e) => {
                    if (e > 0) {
                      if (intenso <= e) {
                        maximo1.push(e);
                      } else if (moderado <= e && e < intenso) {
                        intenso1.push(e);
                      } else if (luz <= e && e < moderado) {
                        moderado1.push(e);
                      } else if (muy_suave <= e && e < luz) {
                        luz1.push(e);
                      } else {
                        muy_suave1.push(e);
                      }
                    }
                  });
                
                setallcolors_5([
                  maximo1,
                  intenso1,
                  moderado1,
                  luz1,
                  muy_suave1,
                ]);
  
                peso_graficads
                  .filter((e) => e)
                  .forEach(function (e, i) {
                    axx0 = Math.max(axx0, e.x);
                    if (i === 0) {
                      min0 = maxl0;
  
                      amin0 = axx0;
                    }
                    min0 = Math.min(100, e.y);
                    amin0 = Math.min(amin0, e.x);
                  });
                if (peso_graficads.filter((e) => e).length > 10) {
                  setamin(amin0);
                } else {
                  setamin(100);
                }
  
                setaxx(axx0);
                setmin(min0);
                setmaxl(maxl0);
              
               sethoras1(
                  peso_graficads1.length > 0 &&
                    peso_graficads1.filter((e) => e)
                );
              
              
            }else {
              let peso_graficads1 = [];
              peso_graficads1[0] = {
                x: 0,
                y: 0,
              };
              sethoras1(
                peso_graficads1.length > 0 &&
                  peso_graficads1.filter((e) => e)
              );
              setallcolors_5([]);
            }
         
          

        const reducer = (previousValue, currentValue) =>
          previousValue + currentValue;
        var total_rate = infoEntrenamiento.map((e, i) => e.heart_rate);
        var total_date = infoEntrenamiento
          .map((e, i) => e.date.replace("T", " ").slice(0, 16))
          .sort(function (a, b) {
            var nameA = a; // ignore upper and lowercase
            var nameB = b; // ignore upper and lowercase
            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });

        var total_kcal = infoEntrenamiento.map((e, i) => e.kcal);
        var tot_f =
          infoEntrenamiento && total_date.length
            ? moment(total_date[0])
            : null;
        var tot_f1 =
          infoEntrenamiento && total_date.length
            ? moment(total_date[total_date.length - 1])
            : null;
        const secs = tot_f ? tot_f.diff(tot_f1, "seconds") : 0;
        info[infoEntrenamientoPUS.id] = {
          calorias:
            infoEntrenamiento && total_kcal.length > 0
              ? total_kcal[0] + ".0"
              : "0.0",
          tiempo:
            infoEntrenamiento && total_date.length > 0
              ? moment.utc(secs * 1000).format("HH:mm:ss")
              : "00:00:00",
          nombresesion: infoEntrenamientoPUS.training.name,
          icon: infoEntrenamientoPUS.training.icon,
          distance: "____",
          date: moment(
            infoEntrenamientoPUS.date,
            "YYYY-MM-DD HH:mm"
          ).format("dddd DD MMMM YYYY LT a"),
          puntosMob:
            infoEntrenamientoPUS && infoEntrenamientoPUS.status == 40
              ? "1"
              : "0",
          hr_med:
            total_rate.length > 0 && total_rate.reduce(reducer)
              ? Math.round(total_rate.reduce(reducer) / total_rate.length)
              : "0.00",
          hr_max:
            infoEntrenamiento && total_rate.length > 0
              ? total_rate.sort(function (a, b) {
                  var nameA = b; // ignore upper and lowercase
                  var nameB = a; // ignore upper and lowercase
                  if (nameA > nameB) {
                    return -1;
                  }
                  if (nameA < nameB) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
                })[total_rate.length - 1]
              : "0.00",
        };

     setInfortodo(info.filter((e) => e));
        setModalInfo(
         null
        )
      }
    });
  }
  }).catch(err=>console.log(err.response))
}
startFunction()
}else{
 async function startFunction(){
  let info = [];

 await instance.get(`customers/disciplines/${id.replace("d", "")}`).then((res) => {
   const infoEntrenamiento = res.data;

   if (infoEntrenamiento) {
     info[infoEntrenamiento.id] = {
       calorias: infoEntrenamiento.kcal + ".0",
       tiempo: moment
         .utc(infoEntrenamiento.duration * 1000)
         .format("HH:mm:ss"),
       puntosMob: "1",
       date: moment(infoEntrenamiento.date, "YYYY-MM-DD HH:mm").format(
         "dddd DD MMMM YYYY LT a"
       ),
       nombresesion: infoEntrenamiento.discipline.name,
       icon: infoEntrenamiento.discipline.icon,
       distance: (Number(infoEntrenamiento.distance) / 1000).toFixed(2),
       hr_med: infoEntrenamiento.hr_med,
       hr_max: infoEntrenamiento.hr_max,
     };

     setInfortodo(info.filter((e) => e));
     instance
     .get(`customers/healthlog/?discipline=${id.replace("d", "")}`)
     .then( (res) => {
     
       const daysselected = res.data;
       if (daysselected.length > 0) {
         let peso_graficads = [];
         let peso_graficads1 = [];
         let maximo1 = [];
         let intenso1 = [];
         let moderado1 = [];
         let luz1 = [];
         let muy_suave1 = [];
         function newNumber(number,base){
          if(number <= base){
            var numeroAleatorio = Math.floor(Math.random() * ((luz - 13) - base + 1)) + base;
            return numeroAleatorio
          } else return number
         }
     
        daysselected.map((e, index) => {
       
           peso_graficads1[e.id] = {
             x: getHour(new Date(e.date)),
             y: newNumber(e.numeric_value,base)
           };
       
         });
        
         daysselected.map((e, index) => {
          // if(e.numeric_value != 0){

            peso_graficads[e.id] = {
              x: getHour(new Date(e.date)),
              y: e.numeric_value,
            };
          // }
         });

         peso_graficads
           .map((e) => e.y)
           .forEach((e) => {
             if (e > 0) {
               if (intenso <= e && e <= maximo) {
                 maximo1.push(e);
               } else if (moderado <= e && e < intenso) {
                 intenso1.push(e);
               } else if (luz <= e && e < moderado) {
                 moderado1.push(e);
               } else if (muy_suave <= e && e < luz) {
                 luz1.push(e);
               } else {
                 muy_suave1.push(e);
               }
             }
           });
           function encontrarNumeroMasGrande(array) {
            return array.reduce((max, numero) => (numero > max ? numero : max), array[0]);
          }
        let madMax=  peso_graficads
          .filter((e) => e).map(el=>el.y);
          setmadMax(encontrarNumeroMasGrande(madMax));
         setallcolors_5([maximo1, intenso1, moderado1, luz1, muy_suave1]);
         peso_graficads
           .filter((e) => e)
           .forEach(function (e, i) {
          
             axx0 = Math.max(axx0, e.x);
             if (i === 0) {
               min0 = maxl0;

               amin0 = axx0;
             }
             min0 = Math.min(100, e.y);
             amin0 = Math.min(amin0, e.x);
           });
         if (peso_graficads.filter((e) => e).length > 10) {
           setamin(amin0);
         } else {
           setamin(100);
         }

         setaxx(axx0);
         setmin(min0);
         setmaxl(maxl0);
        sethoras1(
           peso_graficads1.length > 0 && peso_graficads1.filter((e) => e)
         );
         setModalInfo(
          null
         )  
       
        
       } 
     })
     .catch((err) => {
       if (err.response) {
    
       }
     });


   }
 });
}
 startFunction()
}
}
}

  useEffect(() => {
   
    if(copyAllEntrenamientos &&copyAllEntrenamientos.length == 0){
      setModalInfo(
        <ModalInfo
        error="none"
        handleClick={()=>navigate("/Dashboard")}
        textButtonConfirmar={"Entendido"}
        classcorrecta={"recuerdaTitle"}
        icono={
            iconoFlechaElevva
          }
          iconoWidth={"1.5rem"}
        handleCancellcargue={()=>navigate("/Dashboard")}
        title={`Debes generar data para <br/> poder visualizar este contenido`}
        text={`Agenda tus clases, usa nuestros dispositivos para generar información completa que nos ayudará a mejorar tu calidad de vida. `}
        classesMsg="colorText_Modal"
      />
      );
    }

if(copyAllEntrenamientos &&copyAllEntrenamientos.length> 0 && idTraining =="all"){
  let idCreated = typeof copyAllEntrenamientos[0].id =="number"? copyAllEntrenamientos[0].UUid:"d" + copyAllEntrenamientos[0].UUid
  navigate(`/results/${idCreated}`)
 
}

  }, [copyAllEntrenamientos]);

  const captureScreenshot = () => {
    setModalInfo(
      <ModalInfoProcess
    />
    )
    html2canvas(imageRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        // Crear un objeto File a partir del blob
        const file = new File([blob], 'captura.png', { type: blob.type });
        let formData = new FormData();
       
        formData.append("image", file);
        instance
        .post("app/share/image/",formData)
        .then((response) => {
          const url = new URL(response?.data?.url);
          const urlString = url.toString();
          setModalInfo(
            null
           )
    shareImage(urlString)
   
          
        })
        .catch((error) => {
          console.log(error);
        });
        
        // Aquí tienes el objeto File de la imagen capturada
        // Puedes enviar el objeto File al servidor o realizar otras acciones con él
       
      });
    });
  };

  function shareImage(ImageFile){
   

    setModalInfo( <div
   
      className="modalInfo justify-content-center align-items-center d-flex"
     
    >
      <div
        className={
          "modalInfoContenido d-flex justify-content-center flex-column"
        }
      >
      <div> <img src={iconoFlechaElevva}  style={{width:"1.5rem"}}/></div> 
    <div><br/> Comparte los resultados <br/> de tu entrenamiento</div> 
    <div className="d-flex justify-content-center mt-2">
      <img onClick={()=> laptop575.matches ? facebookShare(ImageFile):Shareall(ImageFile)} className="mx-1" style={{width:"2rem",cursor:"pointer"}} src={facebookIcon}></img>
    { laptop575.matches?null: <img onClick={()=>Shareall(ImageFile)} style={{width:"2rem",cursor:"pointer"}} src={instagramIcon}></img>}
      <img onClick={()=>laptop575.matches ? whatsappShare(ImageFile):Shareall(ImageFile)} className="mx-1" style={{width:"2rem",cursor:"pointer"}} src={whatsappIcon}></img>
    </div>
    <ButtonMob text ={"No gracias"} handleClick={()=>setModalInfo(null)}/>
      </div>
     
    </div>)
  }

  function facebookShare(imageFile){
  

    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageFile)}`;

    // Abre una ventana emergente para compartir en Facebook
    window.open(shareUrl, "_blank");
    
  }

  function whatsappShare(imageFile){
   
     let host =`https://app.staging.elevva.com.co/share.html`;

    //  const whatsappUrl = `https://api.whatsapp.com/send?text=hola`;
    // const whatsappUrl = `https://api.whatsapp.com/send?text=` + encodeURIComponent(host) +" " + `${imageFile}`;
const whatsappUrl = `https://api.whatsapp.com/send?text=` +  `${imageFile}`;
window.open(whatsappUrl, `_blank`);
   
    //  window.open( whatsappUrl,innerHTML);
    //  w.document.close();
    // Abre una ventana emergente para compartir en whatsapp
    // window.open( whatsappUrl,"_blank");
  
  }
  
 function Shareall(ImageFile){
    
      // iife here
      (async () => {
        if (!("share" in navigator)) {
          return;
        }
        // `element` is the HTML element you want to share.
        // `backgroundColor` is the desired background color.
        const canvas = await html2canvas(imageRef.current);
  
        // canvas.style.width = "40px";
        // canvas.style.height = "20px";
        // canvas.width = 256;
        // canvas.height = 171;
  
        // console.log(canvas);
        canvas.toBlob(async (blob) => {
          // Even if you want to share just one file you need to
          // send them as an array of files.
          const files = [new File([blob], "image.png", { type: blob.type })];
  
          const shareData = {
            file: files,
            title: "results",
            text: "Elevvaplayer results",
            url: ImageFile,
          };
          // console.log(files);
          if (shareData && navigator.canShare(shareData)) {
            try {
              await navigator.share(shareData);
            } catch (err) {
              alert("Lo sentimos, no fue posible compartir tu imagen");
              if (err.name !== "AbortError") {
                console.error(err.name, err.message);
              }
            }
          } else {
            alert("nada");
            console.warn("Sharing not supported", shareData);
          }
        });
      })();
    
  

    

    // Abre una ventana emergente para compartir en whatsapp
    // window.open(instagramUrl, "_blank");
    
  }

return (<> {modalInfo}
{ laptop575.matches? <div
  className="d-none d-md-block col-10" style={{height:'100%'}}
>
  <div
    className=" position-relative "
    style={{
      background: "rgba(0,0,0,.7)",
      height: "100vh",
      padding: "1% 3% 1% 2%",
    }}
  >
    <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
    <MisSesiones bookings={copyAllEntrenamientos?copyAllEntrenamientos:[]}  infoAll={Infortodo}
     handleClick={(e)=>{
      navigate(`/results/${e}`)
      }}
       />

<div ref={imageRef} className="d-flex col-md-9 flex-column justify-content-center" style={{height:"100%",gap: "2%"}}>
<div className="w-100" style={{height:"40%"}}>
<TableSesions infoAll={Infortodo}  shareImage={()=> captureScreenshot()}
  
   /></div>
   <div className="w-100" style={{height:"60%"}}>
<ModeloResults2 hours={horas_grafica1} base={base} maxl={MadMax}
 maximo={maximo}   muy_suave={muy_suave} luz={luz} moderado={moderado} intenso={intenso}  colorsPaint={allcolors_5}
     
   /></div>
      </div>
          </div>
          
        </div>
      </div>
      :
       <div ref={imageRef} className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
      <TableSesions borderBody={'0'} infoAll={Infortodo} mobileOn={true} shareImage={()=> captureScreenshot()}
 />
  <ModeloResults2 maxl={MadMax} hours={horas_grafica1 && horas_grafica1} base={base}
 maximo={maximo}   muy_suave={muy_suave} luz={luz} moderado={moderado} intenso={intenso}  colorsPaint={ allcolors_5}
     
   />    
      
      </div>}
      
      </>)


}
  export default Results;