import React, { useRef } from "react"; // import { Lesiones } from "../Lesiones/lesiones";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "swiper/swiper.min.css";

import "swiper/swiper-bundle.min.css";
import "./viewgroup.css";
import { Allcategory, ReservaCustomer } from "./utils";
import { perlo, instance, NuevaAxiosURL } from "../../utils/utils";
import Low  from "../Baja/Low"
const Reservas = (props) => {
  //console.log(props.AllVideos);
  const navigate = useNavigate();
  const pathHere = props.path;
  const [trainings, setTrainings] = useState([]);

  const AllVideos = trainings
  //const AllVideos = props.AllVideos
  // const elevvaT = props.elevvaT;
  //console.log(AllVideos);


  const avisos = {
    aviso_one: [
      "Adquiere todos los beneficios de ser elevva+. Selecciona tu plan ahora y eleva tu estilo de vida",
      "Inicia tu prueba gratuita de 7 dias.",
      "var(--barraPublicidad)",
    ],
    aviso_two: [
      "Mejora tu plan y disfruta de muchos más beneficios con elevva+",
      "Mejorar tu plan.",
      "var(--elevvaTextTitle)",
    ],
    aviso_three: [
      "Tu prueba gratuita ha expirado. Selecciona un plan y sigue disfrutando elevva+. ",
      "Activar plan.",
      "var(--elevva)",
    ],
  };

  // Entrenadores
  //https://api.staging.elevva.com.co/api/v1/trainings

  useEffect(() => {
    instance
      .get(`${NuevaAxiosURL}trainings`)
      .then((response) => {
        setTrainings(response.data);

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //const trainingsId = trainings

  const [counter, setcounter] = useState(0);
  const [barraPublicidad, setbarraPublicidad] = useState(true);
  // const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_two`]);
  const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_one`]);
  const Barra = [
    [
      "Progreso",
      <i className="bi bi-house"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/Inicio",
    ],

    [
      "Mi perfil",
      <i class="bi bi-person-circle"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Videos de bienestar y entrenamiento",
      <i className="bi bi-play-btn-fill"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Reserva de clases",
      <i className="bi bi-calendar-date"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/groupals",
    ],
    [
      "Adquiere equipos",
      <i className="bi bi-box2"></i>,
      useRef(null),
      useRef(null),
      "/Usuario/groupals",
    ],
    [
      "Preguntas frecuentes",
      <i className="bi bi-chat-right-quote"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Términos y condiciones políticas de privacidad",
      <i class="bi bi-pencil-square"></i>,
      useRef(null),
      useRef(null),
      "#",
    ],
    [
      "Cerrar sesión",
      <i className="bi bi-box-arrow-left"></i>,
      useRef(null),
      useRef(null),
      "out",
    ],
  ];
  const categorias = [
    [
      "Clases",
      "grupales en vivo",
      process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
    ],
    [
      "Intensidad",
      "baja",
      process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
    ],
    [
      "Intensidad",
      "media",
      process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
    ],
    [
      "Intensidad",
      "alta",
      process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
    ],
  ];
  const selectOption = (
    refContainer,
    index,
    classess,
    arr,
    onlyone,
    pathHere
  ) => {
    if (onlyone === true) {
      for (const iterator of arr) {
       
        iterator[2].current.classList = classess;
        iterator[2].current.active = false;
        iterator[3] = false;
      }
    }

    if (refContainer.current.active === true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (pathHere == "out") {
      perlo();
    } else {
      return navigate(pathHere);
    }
  };
  const [reservaId, setreservaId] = useState(null);
  const [reservaOptions, setreservaOptions] = useState(null);

  // useEffect(() => {
  //   var reserva = AllVideos;
  //   setreservaId(
  //     <Allcategory
  //       AllVideos={AllVideos}
  //       handleClick={(e) =>
  //         setreservaId(<ReservaCustomer id={e} AllVideos={reserva} />)
  //       }
  //     />
  //   );
  // }, [AllVideos]);
  useEffect(() => {
    var activeme = Barra.filter((e) => e[0].includes(pathHere));

    if (activeme[0][2]) {
      activeme[0][2].current.classList =
        "d-flex option-page fw-bold  py-3 active";
      activeme[0][2].current.active = true;
    }
  }, [pathHere]);
  return (
    <div
      className="d-flex col-12 "
      style={{
        height: "100%",
      }}
    >
      <div
        className="col-2"
        style={{
          flexDirection: "column",
        }}
      >
        <div
          className=" w-100 mx-auto py-4  text-center"
          style={{ height: "20%" }}
        >
          <img
            alt="logo_alta_black"
            style={{ padding: "5% 20%", width: "80%" }}
            src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
          />
        </div>
        <div
          className=" w-100"
          style={{
            height: "80%",
          }}
        >
          {Barra.map((elementos, index) => (
            <div
              key={index}
              ref={elementos[2]}
              style={{ fontWeight: "600" }}
              className="d-flex option-page text-color-title  py-3 "
              onClick={() =>
                selectOption(
                  elementos[2],
                  index,
                  "d-flex option-page fw-bold  py-3 ",
                  Barra,
                  true,
                  elementos[4]
                )
              }
            >
              <div className="my-auto mx-2 Iconresponsive">{elementos[1]} </div>
              <div className="mx-2 letraPequeña">{elementos[0]} </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="col-10  position-relative"
        style={{
          background: "rgba(0,0,0,.7)",
          height: "100vh",

          padding: "2% 3% 2% 2%",
        }}
      >
        <div
          className="bannervideos d-flex py-3"
          style={{
            // backgroundColor: "#FDF1E8",
            position: "sticky",
            // top: "0",
            // zIndex: "3",
            zIndex: "0",
            minheight: "10vmin",
            left: "0",
            flexDirection: "column",
            marginLeft: "-38px",
            marginTop: "-37px",
          }}
        >
          <div
            className={barraPublicidad ? `d-flex ` : `d-none `}
            style={{
              background: avisosvarios[2],
              color: "var(--white)",
              maxWidth: "70%",
              padding: "5px 7vmin",
              justifyContent: "center",
              height: "3rem",
              flexDirection: "row",
              alignContent: "center",
              borderTopRightRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <span
              className=" my-auto mx-2"
              style={{
                fontSize: "1.3vmin",
              }}
            >
              {avisosvarios[0]}{" "}
              <Link
                className="fw-bold"
                style={{ color: "var(--white)" }}
                // to="/ejercicios"
                to="/Usuario/groupals" //Reserva clases grupales
              >
                {avisosvarios[1]}
              </Link>
            </span>
            <span
              className="  my-auto"
              style={{
                fontSize: "1.5vmin",
                cursor: "pointer",
              }}
              onClick={() => setbarraPublicidad(!barraPublicidad)}

            >
              <i className="bi bi-x-circle"></i>
            </span>
          </div>
        </div>
        <div className="d-flex col-12" style={{ gap: "2%", height: "97%" }}>
          <div
            className="col-3"
            style={{
              background: "white",
              height: "100%",
              padding: "2%",
              borderRadius: "20px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="d-flex my-auto">
              <div
                onClick={() => navigate(`/Usuario/Inicio`)}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <span
                className="fw-bold my-auto ms-2"
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "2.0vmin",
                }}
              >
                {" "}
                Mis reservas Elevva
              </span>
            </div>
            <hr />
            <div style={{ lineHeight: "110%", margin: "2vmin auto" }}>
              <span
                style={{
                  color: "rgba(57,79,101,0.5)",
                  fontSize: "2.0vmin",
                }}
              >
                Selecciona
              </span>
              <br />
              <span
                className="fw-bold"
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "2.0vmin",
                }}
              >
                {" "}
                Categoria de interes
              </span>
            </div>
            {categorias.map((e) => (
              <div
                className="position-relative mx-auto"
                style={{
                  backgroundImage: `url(${e[2]})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "20px",
                  width: "100%",
                  height: "18%",
                  marginTop: "1.5vmin",
                  marginBottom: "1.5vmin",
                }}
              >
                <div
                  className="position-absolute "
                  style={{
                    color: "var(--elevvaTextTitle)",
                    display: "flex",
                    height: "100%",
                    left: "5%",
                    justifyContent: "center",
                    flexDirection: "column",
                    lineHeight: "90%",
                  }}
                >
                  <span className="letraPequeñaother mb-1">{e[0]}</span>
                  <div style={{ maxWidth: "10vmin", lineHeight: "110%" }}>
                    <span className="letraPequeñaCardTitleBook fw-bold">
                      {e[1]}
                    </span>
                  </div>
                </div>

                <div
                  className="position-absolute fw-bold booking-all"
                  style={{
                    background: "var(--backgroundOnboardingObjective)",
                    bottom: "9%",
                    right: "5%",
                    padding: "2%",
                    fontSize: "1vmin",
                    color: "var(--white)",
                    borderRadius: "10px",
                  }}
                >
                  <span style={{ fontSize: "0.5vmin" }}
                  // onClick={() => navigate(`/Usuario/Low`)}
                  ><i className="bi bi-chevron-right"></i>
                  </span>
                </div>
              </div>
            ))}
          </div>
          {reservaId ? (
            reservaId
          ) : (
            <Allcategory
              AllVideos={AllVideos}
              trainings={trainings}
              handleClick={(e) =>
                setreservaId(
                  <ReservaCustomer
                  trainings={trainings}
                    id={e}
                    AllVideos={AllVideos}
                    handleClick={(e) => setreservaId(e)}
                  />
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Reservas;
