import React, { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import "./calendarioProfe.css"
const CalendarProfe = () => {
  const [date, setData] = useState(new Date());
  const [dates, setDates] = useState([])
  const dateAlreadyClicked = (dates, date) => dates.some(d => dates(date).isSame(dates(d), 'day'))
  const datesExcept = (dates, date) => dates.filter(d => !dates(date).isSame(dates(d), 'day'))
  const onClickDay = date => {
    
    setDates([...dates, date])
    // if day is already clicked, remove it from state
    // if (dateAlreadyClicked(dates, date)) setDates(datesExcept(dates, date))
    // else setDates([...dates, date])
  }
  const onChange = date => {
    setData(date);
  };

  return (
    <div>
      <Calendar onChange={onChange} value={date}  onClickDay={onClickDay} /> 
    </div>
  );
};

export default CalendarProfe;