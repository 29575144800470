import React from "react";
import "../../utils/utils.css";

import { ButtonMob, ComponenteKnow, instance } from "../../utils/utils";
import { Loading, renderCorazones } from "../TutorialReserva/stepsHelp";
import { Allvideos } from "../grupales/utils";
import { useEffect } from "react";
import { useState } from "react";
// ModalInfoPlan
export const ModalConoceme = React.forwardRef((props, ref) => {
  const text = props.text || "none";
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const infoId = props.infoId;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const [infoTrainer, setinfoTrainer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const elevvaT = props.elevvaT;

  useEffect(() => {

    instance
    .get(`trainers/detail/${infoId}`)
    .then((response) => {
      
      setinfoTrainer(response.data)
   
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);


  function handleClick(event) {
    props.handleClick(event);
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }
 

  useEffect(() => {
    if (infoTrainer) {
      setIsLoading(false);
    }
  }, [infoTrainer]);

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        style={{     backgroundColor: "white",
    padding: '3%',
    position: "relative",
    borderRadius:" 10px",
    textAlign: "center", }}
        className={" col-8"}
      >
     

     {isLoading ? (
        <Loading heigthLoading={"85vh"}/>
      ) :  <div className="col-12 d-flex" style={{height:'85vh'}}>
          <div
            className="col-5 d-flex "
            style={{ position: "relative", justifyContent: "center",height:'100%',overflow:'hidden',borderRadius:'20px' }}
          >
            <img src={infoTrainer?.profile_pic}></img>
            <div style={{ top: "5%", position: "absolute", left: "18%" }}>
              <div className="d-flex my-auto">
                <div
                  onClick={handleCancellcargue}
                  className=" fw-bold booking-all"
                  style={{
                    background: "transparent",
                    border: "1px solid #A8C2D7",
                    padding: "5%",
                    fontSize: "1vmin",
                    height: "fit-content",
                    cursor: "pointer",
                    color: "#A8C2D7",
                    borderRadius: "10px",
                  }}
                >
                  <span style={{ fontSize: "0.5vmin" }}>
                    <i className="bi bi-chevron-left"></i>
                  </span>
                </div>
               {infoTrainer? <div className=" my-auto mx-2">
                  <div
                    className="my-1"
                    style={{ color: "#394F65", fontSize: "2.5vmin" }}
                  >
                {infoTrainer?.name.split(" ")[0]}
                  </div>
                  <div
                    style={{
                      color: "#394F65",
                      fontWeight: "bold",
                      fontSize: "3.5vmin",
                    }}
                  >
                    <span
                      style={{
                        color: "#394F65",
                        fontWeight: "600",
                        fontSize: "3.5vmin",
                      }}
                    >
                      {infoTrainer?.name.split(" ")[1]}
                    </span>{" "}
                  </div>{" "}
                  <br />{" "}
                </div>:null}
              </div>
            </div>
          </div>
         <ComponenteKnow infoTrainer={infoTrainer} clicKnow={()=>handleClick(infoTrainer?.id)}/>
        </div>}
      </div>
    </div>
  );
});
