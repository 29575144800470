import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonMob, laptop575 } from "../../utils/utils";
import { useState, useRef, useEffect } from "react";
import "./vertipoindividual.css";
import { Videos_two } from "../Ejercicios/othervideos";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPlayer from "react-player";
import { ReactComponent as BackArrow } from "../../assets/newIcons/arrow-back.svg";
const VerinfoIndividualTutorial = (props) => {
  // const { id } = useParams();
  const history = useNavigate();
  const stepIndex=props.stepIndex
  const searchText = useRef(null);
  console.log(stepIndex)
  const [AllVideos, setAllVideos] = useState(null);
 
  useEffect(() => {
    axios
      .get("/data/videos.json").then((res) =>{
        
        setAllVideos(res.data.filter((e) => e.id == 2))});
  }, []);
console.log(AllVideos)
  const avisos = {
    aviso_one: [
      "Adquiere todos los beneficios de ser elevva+. Selecciona tu plan ahora y eleva tu estilo de vida",
      "Inicia tu prueba gratuita de 7 dias.",
      "var(--barraPublicidad)",
    ],
    aviso_two: [
      "Mejora tu plan y disfruta de muchos más beneficios con elevva+",
      "Mejorar tu plan.",
      "var(--elevvaTextTitle)",
    ],
    aviso_three: [
      "Tu prueba gratuita ha expirado. Selecciona un plan y sigue disfrutando elevva+. ",
      "Activar plan.",
      "var(--elevva)",
    ],
  };

  const [counter, setcounter] = useState(0);
  const [barraPublicidad, setbarraPublicidad] = useState(true);
  // const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_two`]);
  const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_one`]);
  
  return (
    <div
    className="d-flex m-auto col-12 col-md-10"
    style={{
      height: "100vh",
    }}
    >
      {/* {laptop575.matches ? (
        <div className="mx-auto" style={{ width: "7.1%" }}></div>
      ) : null} */}
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
              flexDirection: "column",
            }}
          >
            <div
              className="py-2 mx-3 banner_one "
              style={{
                background: "rgb(241, 246, 249)",

                color: "#394F65",
              }}
            >
              {" "}
              <div 
                onClick={() => {
                  localStorage.pathCom = AllVideos[0].category;

                  return history(-1);
                }}
                className=" position-absolute"
                style={{
                  left: "5%",
                  top: "50%",
                  transform:"translateY(-50%)",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {" "}
                <div  id={"backInfoTutorial"} className="d-flex m-auto" style={{alignItems:"center"
                
              }}>
        {" "}
        <button
          // onClick={() => history("/videos")}
         
          style={{ background: "transparent", border: "none" }}
        >
          <BackArrow width={30}  />
        </button>
        <span className="title my-auto fw-light">
         Regresar
        </span>
      </div>
            </div>
              <Link
                className="form  my-2 d-flex"
                style={{ float: "right", marginRight: "5%" }}
                 to="/videos/Buscar"
                
              >
                <button
                  className="elevation-3"
                  style={{
                    bottom: "19%",
                    right: "7%",
                    background: "white",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "50%",
                    padding: "1vmin",

                    color: "#394F65",

                    zIndex: "10",
                    border: "2px solid transparent",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 17 17"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
      
          <div
            className="d-flex col-12"
            style={{
              paddingLeft: "5%",
            }}
          >
            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="position-relative" style={{ padding: "2%" }}>
                {AllVideos ? (
                  <div
                    className="d-flex"
                    style={{
                      padding: "0%",
                      height: "55vmin",
                      justifyContent: "center",
                    }}
                  >
                    <ReactPlayer
                      className="position-relative playervideoIndividual "
                      url={AllVideos[0].videoclip}
                      config={{
                        file: {
                          attributes: {
                            preload: "true",
                          },
                        },
                      }}
                      playIcon={
                        <button
                          style={{
                            color: "#ffff",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="currentColor"
                            className="bi bi-play-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                          </svg>
                        </button>
                      }
                      light={
                        process.env.PUBLIC_URL + AllVideos[0].background_image
                      }
                      height="100%"
                      width={"100%"}
                      playing={true}
                      controls={true}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "80vmin",
                padding: "0 5%",
              }}
            >
              <span
                style={{
                  color: "#CB3D82",
                  fontSize: "2.5vmin",

                  fontWeight: "bold",
                }}
              >
                Programa
              </span>
              <span
                style={{
                  color: "#394F65",
                  fontSize: "3vmin",

                  fontWeight: "600",
                }}
              >
                {AllVideos && AllVideos[0].name}
              </span>
              <div className="my-1">
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "2vmin",

                    fontWeight: "500",
                  }}
                >
                  Progreso
                </span>

                {AllVideos ? (
                  <div
                    className="progressCompanie my-1 fw-bold"
                    style={{
                      maxWidth: "60vmin",
                    }}
                  >
                    <ProgressBar
                      now={20}
                      animated
                      variant="SOME_NAME"
                      label={`1/${AllVideos[0].videos.length}`}
                      style={
                        laptop575.matches
                          ? {
                              width: "100%",
                              border: "2px solid #DAA1BD",
                              height: "2.0vmin",
                              borderRadius: "1rem",
                              backgroundColor: "#DAA1BD",
                            }
                          : {
                              marginTop: "1vh",
                              width: "22vw",
                              border: "2px solid #C1CCFC",
                              height: "1.5rem",
                              borderRadius: "1rem",
                              backgroundColor: "#C1CCFC",
                            }
                      }
                    />
                  </div>
                ) : null}
              </div>
              <span
                className="my-1"
                style={{
                  color: "#394F65",
                  fontSize: "2.5vmin",

                  fontWeight: "500",
                  fontSize: "2vmin",
                }}
              >
                {AllVideos && AllVideos[0].apto}
              </span>
              <span
                style={{
                  color: "#394F65",
                  fontSize: "1.5vmin",
                }}
              >
                {AllVideos && AllVideos[0].tema}
              </span>
              <div
                className="d-flex my-3"
                style={{
                  color: "#394F65",
                  fontSize: "1.5vmin",
                }}
              >
                {AllVideos ? (
                  <img
                    style={{ width: "7vmin", height: "7vmin" }}
                    src={process.env.PUBLIC_URL + AllVideos[0].photo_teacher}
                  />
                ) : null}
                <span className="my-auto mx-3">
                  {AllVideos && AllVideos[0].type_teacher}
                  <br />
                  <span
                    className="fw-bold"
                    style={{
                      color: "#394F65",
                      fontSize: "2.2vmin",
                    }}
                  >
                    {AllVideos && AllVideos[0].person_turn}
                  </span>
                </span>
              </div>

              <span
                style={{
                  color: "#394F65",
                  fontSize: "1.5vmin",
                  maxWidth: "40vmin",
                }}
              >
                {AllVideos && AllVideos[0].cv}
              </span>
              <div className="position-relative ">
                <button
                  style={{
                    marginTop: "2vmin",
                    marginBottom: "1vmin",
                    width: "15vmin",
                  }}
                  onClick={() => history(`/videos/card/${AllVideos[0].id}/1`)}
                  className="buttonIndividualTypesverahora elevation-10"
                >
                  EMPIEZA YA
                </button>
              </div>
            </div>
          </div>
         {AllVideos? <div 
            style={{
              backgroundColor: "#FDF1E8",
              paddingLeft: "5%",
              paddingTop: "1.8%",
            }}
          >
            <Videos_two 
              idTutorial={"allvideosTutorialInfo"}
              textTitleOne="Clases "
              textTitleTwo="del programa"
              videosTime={AllVideos}
              stepIndex={stepIndex}
              id="pin"
            />
            <br />
          </div>:null}
        </div>
      ) : (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100%",

            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex py-3"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
            }}
          >
            <div   className="d-flex"
              onClick={() => {
                localStorage.pathCom = AllVideos[0].category;

                return history(-1);
              }}
              style={{
                justifyContent: "center",
                cursor: "pointer",
                width: "20%",
                margin: "auto",
                fontWeight: "500",
                alignItems: "center",
              }}
            >
                 <button id={"backInfoTutorial"}
          // onClick={() => history("/videos")}
         
          style={{ background: "transparent", border: "none" }}
        >
          <BackArrow width={30}  />
        </button>
            </div>
            <div
              className="my-auto d-flex"
              style={{
                justifyContent: "center",
                width: "60%",

                margin: "auto",
                alignItems: "center",
              }}
            >
              {" "}
              <img
                style={{ width: "75%", padding: "0% 3% 0% 0%" }}
                src={process.env.PUBLIC_URL + "/img/logosEmpresas.png"}
              />{" "}
            </div>
            <div
              style={{
                width: "20%",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link to="/videos/Buscar">
                <button
                  className="d-flex m-auto"
                  style={{
                    bottom: "19%",
                    right: "2%",
                    background: "white",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "50%",

                    width: "30px",
                    height: "30px",
                    padding: "20%",
                    color: "#9DACBD",
                    zIndex: "10",
                    border: "1px solid rgba(157,172,189,1)",
                  }}
                >
                  <svg
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    // maxWidth="80"
                    // maxHeight="80"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
          <div
            className="d-row col-12"
            style={{
              padding: "0% 5%",
            }}
          >
            {" "}
            <br />
            <div
              className="col-12 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="position-relative" style={{ padding: "0%" }}>
                {AllVideos ? (
                  <div
                    style={{
                      padding: "0%",
                      height: "55vmin",
                    }}
                  >
                    <ReactPlayer
                      className="position-relative playervideoIndividual"
                      url={AllVideos[0].videoclip}
                      playIcon={
                        <button
                          style={{
                            color: "#ffff",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="currentColor"
                            className="bi bi-play-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                          </svg>
                        </button>
                      }
                      config={{
                        file: {
                          attributes: {
                            preload: "true",
                          },
                        },
                      }}
                      light={
                        process.env.PUBLIC_URL + AllVideos[0].background_image
                      }
                      height="100%"
                      width={"100%"}
                      playing={true}
                      controls={true}
                    />
                  </div>
                ) : null}
                {/* <img
                    style={{ width: "100%", borderRadius: "25px" }}
                    className="position-relative playervideoIndividual"
                    src={process.env.PUBLIC_URL + AllVideos[0].background_image}
                  /> */}
              </div>
            </div>
            <div
              className="col-12 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "100%",
                padding: "0 2%",
              }}
            >
              <span
                style={{
                  color: "#CB3D82",

                  fontWeight: "bold",
                }}
              >
                Programa
              </span>

              <span
                style={{
                  color: "#394F65",

                  fontWeight: "600",
                }}
              >
                {AllVideos && AllVideos[0].name}
              </span>
              <div className="my-1">
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                  }}
                >
                  Progreso
                </span>

                {AllVideos ? (
                  <div
                    className="progressCompanie my-1 fw-bold"
                    style={{
                      maxWidth: "150vmin",
                    }}
                  >
                    <ProgressBar
                      now={20}
                      animated
                      variant="SOME_NAME"
                      label={`1/${AllVideos[0].videos.length}`}
                      style={{
                        marginTop: "1vh",
                        width: "80vmin",
                        border: "2px solid #C1CCFC",
                        height: "5.5vmin",
                        borderRadius: "1rem",
                        backgroundColor: "#C1CCFC",
                      }}
                    />
                  </div>
                ) : null}
              </div>

              <span
                className="my-1"
                style={{
                  color: "#394F65",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
                {AllVideos && AllVideos[0].apto}
              </span>

              <span
                style={{
                  color: "#394F65",
                  fontSize: "0.9rem",
                }}
              >
                {AllVideos && AllVideos[0].tema}
              </span>
              <div
                className="d-flex my-3"
                style={{
                  color: "#394F65",
                }}
              >
                {AllVideos ? (
                  <img
                    style={{ width: "12vmin", height: "12vmin" }}
                    src={process.env.PUBLIC_URL + AllVideos[0].photo_teacher}
                  />
                ) : null}
                <span className="my-auto mx-3">
                  {AllVideos && AllVideos[0].type_teacher}
                  <br />
                  <span
                    className="fw-bold"
                    style={{
                      color: "#394F65",
                      fontSize: "0.9rem",
                    }}
                  >
                    {AllVideos && AllVideos[0].person_turn}
                  </span>
                </span>
              </div>

              <span
                style={{
                  color: "#394F65",
                  fontSize: "0.7rem",
                  maxWidth: "100%",
                }}
              >
                {AllVideos && AllVideos[0].cv}
              </span>
            </div>
            <br />
            <div
              className="position-relative "
              // style={{ bottom: "13%", left: "9%" }}
            >
              <button
                style={{
                  marginTop: "1vmin",
                  marginBottom: "1vmin",
                  fontSize: "0.6rem",
                  width: "40%",
                }}
                onClick={() => history(`/videos/card/${AllVideos[0].id}/1`)}
                className="buttonIndividualTypesverahora elevation-10"
              >
                EMPIEZA YA
              </button>
            </div>
          </div>
          <br />
         {AllVideos? <div
            style={{
              backgroundColor: "rgb(253, 241, 232)",
              paddingLeft: "5%",
              paddingTop: "1.8%",
            }}
          >
            <Videos_two
              textTitleOne="Clases "
              textTitleTwo="del programa"
              videosTime={AllVideos}
              id="pin"
            />
            <br />
          </div>:null}
          <br />
          <br />

          <br />
        </div>
      )}
    </div>
  );
};
export default VerinfoIndividualTutorial;