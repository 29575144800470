import { useEffect } from "react";
import React, { useRef } from "react";
import {
  Categorias,
  
  CategoriasEmpty,
  
  Loading,
  
  Paso5,
  
  Paso6,
  
} from "../TutorialReserva/stepsHelp";
import { useNavigate, useParams } from "react-router-dom";
import { instance, ModalInfo, SetModalError } from "../../utils/utils";
import { useState } from "react";
import "moment/locale/es";

const BookingBook = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalInfo, setModalInfo] = useState(null);
  const [infoTraining, setinfoTraining] = useState(null);
  const [trainerGlobal, setTrainerGlobal] = useState(null);
  const [customerInfo, setcustomerInfo] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const costoReservas = localStorage.creditoReser;
  const zonahorairachange = props.zonahorairachange;
  const selectProfesor = localStorage.trainerSelected
    ? localStorage.trainerSelected
    : null;
  const reservasAll = JSON.parse(localStorage.getItem("reservasAll"));

  useEffect(() => {
    if (selectProfesor) {
      instance
        .get(`trainers/detail/${selectProfesor}`)
        .then((response) => {
          setTrainerGlobal(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    instance
      .get("customers/")
      .then((response) => {
        setcustomerInfo(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (customerInfo && JSON.parse(localStorage.reservaSuccess) == false) {
      console.log(customerInfo);

      if (
        Number(costoReservas) <= customerInfo.credits_balance ||
        localStorage.membership
      ) {
        instance
          .get(`trainings/${id}`)
          .then((response) => {
            if (response.data) {
              setinfoTraining(response.data);

              if (response.data.group) {
                var formDatagroup = new FormData();
                formDatagroup.append("training", id);

                formDatagroup.append("date", reservasAll[0][0]);
                formDatagroup.append("tz", zonahorairachange.timezone);
                instance
                  .post(`/booking/group/book/`, formDatagroup)
                  .then((res) => {
                    if (res.data) {
                      setisLoading(false);

                      localStorage.reservaSuccess = true;
                    }
                  })
                  .catch((err) => {
                    if (err.response) {
                      setisLoading(false);
                      setModalInfo(
                        <ModalInfo
                          error="true"
                          handleClick={() =>
                            navigate(`/selectGrupalList/${id}`)
                          }
                          handleCancellcargue={() =>
                            navigate(`/selectGrupalList/${id}`)
                          }
                          title="Lo sentimos"
                          text={err.response.data[0]}
                          classesMsg="colorText_Modal my-2"
                        />
                      );
                    }
                  });
              } else {
                for (let idClase in reservasAll) {
                  var formData3 = new FormData();

                  if (selectProfesor) {
                    formData3.append("trainer", selectProfesor);
                    formData3.append("training", id);
                    formData3.append("date", reservasAll[idClase][0]);
                    formData3.append("tz", zonahorairachange.timezone);
                  } else {
                    formData3.append("training", id);
                    formData3.append("date", reservasAll[idClase][0]);
                    formData3.append("tz", zonahorairachange.timezone);
                  }
                  instance
                    .post(`booking/book/`, formData3)
                    .then((resEn) => {
                      if (resEn.data) {
                        setisLoading(false);
                        if (reservasAll.length - 1 == idClase) {
                          localStorage.reservaSuccess = true;
                        }
                      }
                    })
                    .catch((err) => {
                      if (err.response) {
                        setisLoading(false);
                        if (err.response.status === 403) {
                          setModalInfo(
                            <ModalInfo
                              error="false"
                              handleClick={() => navigate(`/Dashboard`)}
                              textButtonConfirmar={"Mejorar mi plan"}
                              classcorrecta={"recuerdaTitleBlock"}
                              handleCancellcargue={() => navigate(`/Dashboard`)}
                              title={`Tu plan actual : </br> ${err.response.data.detail} </br>`}
                              text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
                              classesMsg="colorText_Modal"
                            />
                          );
                        } else {
                          setModalInfo(
                            <SetModalError
                              handleClick={() => navigate("/Dashboard")}
                              title={
                                err.response.data
                                  ? "Lo sentimos"
                                  : "Algo salió mal"
                              }
                              classesMsg="colorText_Modal my-2"
                              msg={
                                err.response.data
                                  ? "<br/>" +
                                    err.response.data[0] +
                                    "<br/><br/>"
                                  : null
                              }
                            />
                          );
                        }
                      }
                    });
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setisLoading(false);
        setModalInfo(
          <ModalInfo
            error="none"
            handleCancellcargue={() => navigate("/Dashboard")}
            // handleClick={() =>
            //   setModalInfo(<Redirect push={true} to={`/Usuario/creditos`} />)
            // }
            classcorrecta={"recuerdaTitle"}
            title="Sin créditos"
            text={"No tienes los créditos suficientes para esta compra"}
            classesMsg="colorText_Modal"
          />
        );
      }
    }
  }, [customerInfo, id]);

  const hideModal = () => {
    setModalInfo(null);
  };

  return (
  isLoading ? <Loading/> : <>  {modalInfo} <div className="d-none d-md-block  col-10 " style={{ height: "100%" }}>
     
      <div
        className=" position-relative "
        style={{
          background: "rgba(0,0,0,.7)",
          height: "100vh",
          padding: "2% 3% 2% 2%",
        }}
      >
        <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
         <CategoriasEmpty />
           <Paso5 reservasAll={reservasAll} customerInfo={customerInfo} trainer={trainerGlobal &&trainerGlobal.name} mostrarClases={true}
            nameTraining={infoTraining && infoTraining.name}  zonahorairachange={zonahorairachange && zonahorairachange.timezone}
          />
        </div>
      </div>
    </div>
    <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
    <Paso5 reservasAll={reservasAll} customerInfo={customerInfo} trainer={trainerGlobal &&trainerGlobal.name} mostrarClases={true}
            nameTraining={infoTraining && infoTraining.name}  zonahorairachange={zonahorairachange && zonahorairachange.timezone}
          />
        </div>
 </> );
};
export default BookingBook;
