
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation,EffectCoverflow  } from "swiper";
import 'swiper/swiper-bundle.css';
const TestSlider = (props) => {
  SwiperCore.use([Pagination, Autoplay, Navigation,EffectCoverflow]);
  return(<Swiper     effect={'coverflow'}
  grabCursor={true}
  centeredSlides={true}
  loop={true}
  slidesPerView={'auto'}
  coverflowEffect={{
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 2.5,
  }}

  style={{ margin: "auto ", width: '100%' }}
  className=" py-3"

  pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
  
  spaceBetween={20}
  slidesOffsetBefore={0}
  slidesOffsetAfter={0}
  slidesPerGroup={1}
  slidesPerGroupSkip={1}
>
    <SwiperSlide 
    key={'1'}

 
    style={{ width: 'auto', height: 'auto' }}
  > <div
    
        className="content position-relative"
        style={{ width: "12rem", }}
      >
        {" "}
        <img
          id={'4'}
          className=""
          src={"https://picsum.photos/800/802/?random"}
          style={{ borderRadius: "20px", cursor: "pointer" }}
          width={"90%"}
          height="100%"
        />
      </div>
 
  </SwiperSlide>
  <SwiperSlide 
    key={'2'}

 
    style={{ width: 'auto', height: 'auto' }}
  > <div
    
        className="content position-relative"
        style={{ width: "12rem", }}
      >
        {" "}
        <img
        
          className=""
          src={"https://picsum.photos/800/802/?random"}
          style={{ borderRadius: "20px", cursor: "pointer" }}
          width={"90%"}
          height="100%"
        />
      </div>
 
  </SwiperSlide>
  <SwiperSlide 
    key={'2'}

 
    style={{ width: 'auto', height: 'auto' }}
  > <div
    
        className="content position-relative"
        style={{ width: "12rem", }}
      >
        {" "}
        <img
        
          className=""
          src={"https://picsum.photos/800/802/?random"}
          style={{ borderRadius: "20px", cursor: "pointer" }}
          width={"90%"}
          height="100%"
        />
      </div>
 
  </SwiperSlide>
  <SwiperSlide 
    key={'2'}

 
    style={{ width: 'auto', height: 'auto' }}
  > <div
    
        className="content position-relative"
        style={{ width: "12rem", }}
      >
        {" "}
        <img
        
          className=""
          src={"https://picsum.photos/800/802/?random"}
          style={{ borderRadius: "20px", cursor: "pointer" }}
          width={"90%"}
          height="100%"
        />
      </div>
 
  </SwiperSlide>
   </Swiper>
  )
}
export default TestSlider;