import Media from "react-media";
import { useNavigate, useParams } from "react-router-dom";
import giro1 from "../../assets/img/reservasimages/giroTop.png";
import giro2 from "../../assets/img/reservasimages/giroBot.png";
import logo_eleva from "../../assets/img/reservasimages/Logo eleva_giro.png";
import fondo from "../../assets/img/tipos_ejercicios.png";
import iconoDisconnected from "../../assets/newIcons/BANDANOCONECTADA.svg"
import lupa from "../../assets/img/reservasimages/lupa.png";
import iconoConnected from "../../assets/newIcons/BANDACONECTADA.svg"
import camera from "../../assets/img/reservasimages/camera.png";
import wifi from "../../assets/img/reservasimages/wifi.png";
import mic from "../../assets/img/reservasimages/mic.png";
import system from "../../assets/img/reservasimages/system.png";
import check from "../../assets/img/reservasimages/check.png";
import myCamera from "../../assets/img/reservasimages/myCamera.png";
import customAvatar from "../../assets/img/reservasimages/customAvatar.png";
import band_conected from "../../assets/newIcons/conected_band.svg"
import "./Clase.css";
import { useEffect, useRef, useState } from "react";

import {
  showOrHidePanel,
  rtc,
  instance,
  disconnectPeer,
  connectPeerToPeer,
  connectPeerToPeerUser,
  ContenedorClase,
  fancyTimeFormat,
  ModalInfo,
  Panel,
  timeOutcheckVideo,
  timeOutcheckAudio,
  toCheckRemoteAudioWebView,
  ButtonMob,
  userType,
  Loadingspinner,
  laptop575,
  basename,
  initSocketConnection,
  booking_finish_customer_emit,
  sync_clock,
  hr_data,
  hr_data_emit,
  closeSocketConnection,
  booking_client_connected_emit,
  get_users_room_emit,
  socket,
} from "../../utils/utils.js";
import AgoraRTC from "agora-rtc-sdk-ng";
import star from "../../assets/img/reservasimages/stars_llamada.png";
import starfill from "../../assets/img/reservasimages/stars_fill_llamada.png";
import flama from "../../assets/img/reservasimages/flama.png";
import corazon from "../../assets/img/reservasimages/corazon.png";
import cerrar from "../../assets/img/reservasimages/Grupo 6107.png";
import Moment from "moment";
import "moment/locale/es";
import { extendMoment } from "moment-range";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Tooltip } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import muted from "../../assets/img/svgcall/SonidoOff.svg";
import noMuted from "../../assets/img/svgcall/SonidoOn.svg";
import videoOn from "../../assets/img/svgcall/videoOn.svg";
import videoOff from "../../assets/img/svgcall/videoOff.svg";
import headerWait from "../../assets/img/svgcall/headerWait.svg";

SwiperCore.use([Navigation]);

const Clase = (props) => {
  AgoraRTC.setLogLevel(3);
  const [isChecked, setChecked] = useState(null);
  const [maxl0, setmaxl0] = useState(220);
  const [user, setUserData] = useState(null);

  // const user = props.user;
  const navigate = useNavigate();

  useEffect(() => {
    const checked = localStorage.checked;

    instance
      .get("users/current/")
      .then((response) => {
        setmaxl0(220 - response.data?.customer?.age);
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    if (checked) {
      const now = Date.now();
      const diff = now - checked;
      if (diff <= 1800000) {
        // Less than 30 min, so no check again
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else setChecked(false);
  }, []);

  return (
    <>
      <Media
        query="(max-width: 470px)"
        render={() => (
          <div className="claseFondo">
            <div
              style={{
                background: "#7886F5",
                color: "white",

                textAlign: "center",
              }}
            >
              <img
                src={logo_eleva}
                alt="giro"
                width={100}
                className="m-3 p-auto"
              />
            </div>
            <div className="container px-5">
              <div className="info">
                <img src={giro1} alt="giro" />

                <br />
                <div style={{ color: "#9EACED" }}>
                  Tu sesión de entrenamiento Iniciara en unos segundos.
                </div>
                <br />
                <br />
                <img src={giro2} alt="giro" />
                <br />
                <div style={{ color: "#9EACED" }}>
                  Por favor gira tu celular para iniciarla correctamente.
                </div>
              </div>
            </div>
            <div
              className="position-absolute fw-bold m-auto"
              style={{ color: "white", top: "1%", left: "2%" }}
            >
              <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faAngleLeft} size="3x" color="white" />
              </div>
            </div>
          </div>
        )}
      />
      <Media
        query="(min-width: 471px)"
        render={() => (
          <>
            <div
              className="containerBGClase"
              style={{ backgroundImage: `url(${fondo})` }}
            ></div>
            {isChecked === true && user ? (
              <ComenzarClase user={user} maxl0={maxl0} isChecked={isChecked} />
            ) : null}
            {isChecked === false ? (
              <ComprobarClase
                onChecked={(inf) => setChecked(inf)}
                isChecked={isChecked}
              />
            ) : null}
          </>
        )}
      />
    </>
  );
};

const ComenzarClase = (props) => {
  const arrRutinas = useRef(null);
  const moment = extendMoment(Moment);
  require("moment-timezone");
  const maxl0 = props.maxl0;
  const myPanelTab = useRef(null);
  const myPanel = useRef(null);
  const miEntrenamiento = useRef(null);
  const miEntrenamientoTab = useRef(null);
  const refRutinas = useRef(null);
  const { reserva } = useParams();
  const user = props.user;
  const [bandConnected, setBandConnected] = useState(
    JSON.parse(localStorage.bandElevvaConnected) || false
  );
  const [idClase, setIdClase] = useState(null);
  const [rutina, setRutina] = useState(null);
  const [BookGroup, setBookGroup] = useState(false);
  const [hourSync, sethourSync] = useState(null);
  const [flagDisconnected, setflagDisconnected] = useState(false);

  const [nombreCliente, setNombreCliente] = useState("Jhon Doe");
  const [estrellas, setEstrellas] = useState(1);
  const [avatar, setAvatar] = useState(null);
  const [frecuenciaCustomer, setfrecuenciaCustomer] = useState(0);
  const [kcalCustomer, setkcalCustomer] = useState(0);
  const [HrData, setHrData] = useState({ heart_rate: 0, kcal: 0 });
  const [NowConnected, setNowConnected] = useState([]);
  const [modalInfo, setModalInfo] = useState(null);
  const actualTime = useRef(0);
  const [percentageactual, setpercentageactual] = useState(null);
  const [pitar, setpitar] = useState(null);
  const [pitarTABATA, setpitarTABATA] = useState(null);
  const [pitoboxing, setpitoboxing] = useState(null);
  const [MethodologyNow, setMethodologyNow] = useState(null);
  const [startime, setstartime] = useState(false);
  const [cargarRutinaClose, setcargarRutinaClose] = useState(false);
  console.log("HrData----------", HrData);
  const submitCancelarClase = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui CancelarReserva">
            <h1
              className="title text-center"
              style={{ fontSize: "2.0vw", width: "100%" }}
            >
              ¿Deseas cancelar tu entrenamiento?
            </h1>
            <div className="msg text-center" style={{ fontSize: "2vw" }}>
              ¿Estas seguro de cancelar la clase?
            </div>
            <div className="buttons">
              <button
                className="No letradeseascancelar"
                onClick={onClose}
                style={{
                  width: "100%",
                  padding: "auto",
                }}
              >
                No quiero
                <br /> cancelar
              </button>
              <button
                style={{
                  width: "100%",
                  padding: "auto",
                }}
                className="Si letradeseascancelar"
                onClick={async () => {
                  const resC = await instance.post(
                    `booking/book/cancel/${reserva}`
                  );
                  onClose();
                  // setModalInfo(<Redirect to={`/${userType}/inicio`} />);

                  window.location.href = `${basename}/${userType}/inicio`;
                }}
              >
                Cancelar el entrenamiento
              </button>
            </div>
          </div>
        );
      },
    });
  };

  function modifyRutinaColor(idx) {
    if (!refRutinas.current) return;

    const pos = idx;
    console.log(pos);
    // actualRutina.current = pos;
    for (let index = pos; index >= 0; index--) {
      const rut1 = refRutinas.current.querySelector("#rutina-" + index);

      if (rut1) {
        rut1.classList.add("active");
      }
      const rut2 = refRutinas.current.querySelector("#rutinaMSG-" + index);
      if (rut2) {
        rut2.classList.add("active");
      }
    }
  }

  useEffect(() => {
    if (localStorage.MethodologyCustomer && rutina) {
      console.log(rutina);

      modifyRutinaColor(Number(localStorage.MethodologyCustomer));
    }
  }, [rutina]);
  useEffect(() => {
   
    initSocketConnection({
      
      onConnected: (data) => {
        // Aquí actualizas el estado con los datos recibidos desde el servidor para el evento "booking_trainer_connected"
      },
      // Función de devolución de llamada para manejar la desconexión
      onDisconnected: (reasonDisconnect) => {
        if (reasonDisconnect && localStorage.allBokkingsElevva) {
          get_users_room_emit();
          // let userDisconected = JSON.parse(reasonDisconnect);
          // let oneCustomer = JSON.parse(localStorage.allBokkingsElevva);
          // let totConnectedBool = oneCustomer.find(
          //   (e) => e.id == userDisconected.user
          // );
          // totConnectedBool.connected = false;
          // console.log(totConnectedBool);
          // setbookings(
          //   oneCustomer
          //     .filter((e) => e.id != userDisconected.user)
          //     .concat(totConnectedBool)
          // );
          // localStorage.allBokkingsElevva = JSON.stringify(
          //   oneCustomer
          //     .filter((e) => e.id != userDisconected.user)
          //     .concat(totConnectedBool)
          // );
        }
        // Puedes agregar lógica adicional aquí si es necesario
      },
      onConnectedHrDevice: (band) => {
        let stateResponse = JSON.parse(band);
        if (stateResponse.customer == localStorage.id) {
          setBandConnected(true);
          localStorage.bandElevvaConnected = true;
          setModalInfo(
            <ModalInfo
              error="none"
              classcorrecta={"recuerdaTitle"}
              textButtonAction="Entendido"
              handleCancellcargue={() => setModalInfo(null)}
              handleReservar={() => setModalInfo(null)}
              title="La banda HR"
              text="Se ha conectado de manera exitosa</br></br>"
              iconoWidth={"2.5rem"}
              textSizeUp={"1rem"}
              icono={iconoConnected}
            />
          );
        }
      },
      onDisconnectedHrDevice: (bandDisconnected) => {
        // Aquí puedes poner el código que quieres que se ejecute solo una vez
        let stateResponse = JSON.parse(bandDisconnected);
        if (stateResponse.customer == localStorage.id) {
          setflagDisconnected(true); // Actualizamos el flag para que no se vuelva a ejecutar
          setBandConnected(false);
          console.log("se desconecto la banda");
        }

        console.log("se desconecto la banda");
        // Puedes agregar lógica adicional aquí si es necesario
      },
      onworkoutlogData: (workoutlog) => {
        if (workoutlog && workoutlog != undefined) {
          let frecuenciaCustomer = workoutlog.heart_rate;
          let kcalCustomer = workoutlog.kcal;

          setfrecuenciaCustomer(frecuenciaCustomer);
          setkcalCustomer(kcalCustomer);
          console.log(workoutlog)

          setHrData((val) => ({ ...val, [workoutlog.booking_id]: workoutlog }));
        }
      },
      onChangeExerciseData: (change) => {
        let indiceMetodology = arrRutinas.current.indexOf(
          arrRutinas.current.filter((e) => e.id == change)[0]
        );
        localStorage.MethodologyCustomer = indiceMetodology;
        modifyRutinaColor(indiceMetodology);
      },
      onStart: (start) => {
        console.log("what1");
        modifyRutinaColor(0);
      },
      onRoom: (room) => {
        console.log(room);
      },
      onFinishCustomer: (finish) => {
        // window.location.href = `${basename}/results/${reserva}`;
      },
      syncLock: (lock) => {
        console.log(lock);
        if (lock) {
          sethourSync(moment(lock * 1000));

          if (localStorage.room_id) {
            setstartime(true);
          }
        }
      },
      onHrdevice: (hrdevice) => {
        console.log(hrdevice);
      },
      onWorkoutAll: (workAll) => {
        if (cargarRutinaClose == false) {
          if (workAll && workAll != undefined) {
            localStorage.setItem("lastWorkoutlog", JSON.stringify(workAll));
          }
        }
      },
      onFinishTrainer: (finish) => {
        console.log(finish);

        cancelarClase();
      },
      onCustomerConnectedGroup: (whoConected) => {
        if (whoConected && localStorage.allBokkingsElevva) {
          get_users_room_emit();
        }
      },
      onUsersInRoom: (usersRoom) => {
        let usersRoomConnected = usersRoom.users;
        let inputArray = JSON.parse(localStorage.allBokkingsElevva);

        const uniqueSet = new Set(inputArray.map(JSON.stringify));

        // Convertir el Set de nuevo a un array
        const oneCustomer = Array.from(uniqueSet).map(JSON.parse);
        console.log("cutomers-localstorgafe", oneCustomer);
        let totConnectedBool = oneCustomer.filter((e) =>
          usersRoomConnected.includes(e.id)
        );
        if (totConnectedBool) {
          totConnectedBool.map((element) => {
            element.connected = true;
            return element;
          });

          console.log(totConnectedBool);

          setbookings(totConnectedBool);
        }
      },

      customProperty: reserva,
    });
  }, []);

  useEffect(() => {
    if (NowConnected.length > 0) {
      localStorage.saveClassActive = JSON.stringify(NowConnected);
    }
  }, [NowConnected]);

  useEffect(() => {
    if (
      localStorage.lastWorkoutlog &&
      arrRutinas &&
      arrRutinas.current &&
      cargarRutinaClose == false &&
      BookGroup === false
    ) {
      let lastWorkout = JSON.parse(localStorage.getItem("lastWorkoutlog"));

      let indiceMetodology = arrRutinas?.current.indexOf(
        arrRutinas.current.filter((e) => e.id == lastWorkout?.methodology)[0]
      );
      localStorage.MethodologyCustomer = indiceMetodology;
      modifyRutinaColor(indiceMetodology);
      setcargarRutinaClose(true);
    }
  }, [cargarRutinaClose, arrRutinas.current, localStorage.lastWorkoutlog]);

  useEffect(() => {
    if (flagDisconnected) {
      setModalInfo(
        <ModalInfo
          error="none"
          textButtonAction="Entendido"
          handleCancellcargue={() => setModalInfo(null)}
          handleReservar={() => setModalInfo(null)}
          title="La Banda HR se ha desconectado"
          classcorrecta={"recuerdaTitle"}
          text="Conéctala desde tu aplicación móvil </br></br>"
          iconoWidth={"2.5rem"}
          textSizeUp={"1rem"}
          icono={iconoConnected}
        />
      );
    }
  }, [flagDisconnected]);

  // useEffect(() => {
  //   if(flagConnected){

  //   }
  //     }, [flagConnected]);

  useEffect(() => {
    function funcionConRetraso() {
      let musc = document.getElementById("bottondisaperar");
      if (musc) {
        musc.style = "display:inline";
      }
    }
    let identificadorTiempoDeEspera;
    function temporizadorDeRetraso(date, hourSync) {
      const fechaclar = moment(date).format("dddd DD [de] MMMM / HH:mm a");
      console.log(date, fechaclar);
      const timeStart = hourSync;
      const timeEnd = moment(fechaclar, "dddd DD [de] MMMM / HH:mm a");
      timeEnd.add(1, "hour");
      const diff = (3600 - timeEnd.diff(timeStart, "seconds")) * 1000;
      actualTime.current = diff;
      identificadorTiempoDeEspera = setTimeout(
        funcionConRetraso,
        660000 - diff
      );
    }
    async function conectarclase() {
      await instance
        .get(`/booking/book/prepare/${reserva}`)
        .then(async (resEn) => {
          const data = resEn.data;
          if (data) {
            const info = data.session.routine;
            const book_grupal = data.training.group;
            const LasRutinas = info;
            console.log("---------", book_grupal);
            setBookGroup(book_grupal);
            if (book_grupal === false) {
              arrRutinas.current = LasRutinas;
              localStorage.room_idUser = data.room_id;
              if (localStorage.MethodologyCustomer) {
                modifyRutinaColor(Number(localStorage.MethodologyCustomer));
              }
              setRutina(
                LasRutinas.map((val, idx) => {
                  const laRutina = val.name;
                  const name = val.name.toLowerCase();
                  const time = parseInt(laRutina.time);
                  let classes = `${name} text-capitalize itemRutina `;
                  const classesCircle = `${name}  rounded-circle circle `;
                  return (
                    <div key={idx}>
                      <div className={classes} id={`rutina-${idx}`}>
                        <span>
                          <div
                            className={classesCircle}
                            id={`rutinaMSG-${idx}`}
                          ></div>
                        </span>
                        {name}
                      </div>
                      {LasRutinas.length - 1 == idx ? null : (
                        <span
                          style={
                            laptop575.matches
                              ? {
                                  borderLeft: "1px solid #FBDBC6",
                                  marginLeft: "2vmin",
                                }
                              : {
                                  borderLeft: "1px solid #FBDBC6",
                                  marginLeft: "0.75rem",
                                }
                          }
                        ></span>
                      )}
                    </div>
                  );
                })
              );
            } else {
              await instance
                .get(`/booking/group/customers/${data.group_schedule}`)
                .then((resEn) => {
                  const totalBookings = resEn.data;
                  booking_client_connected_emit({});
                  get_users_room_emit();
                  setbookings(
                    totalBookings.map((e) => ({ ...e, connected: false }))
                  );
                  localStorage.allBokkingsElevva = JSON.stringify(
                    totalBookings.map((e) => ({ ...e, connected: false }))
                  );
                })
                .catch((err) => {
                  console.error("Error en api/clientes/reservas/group", err);
                });
            }
            setNombreCliente(data.trainer.full_name);
            setEstrellas(data.trainer.current_rating);
            setAvatar(
              process.env.REACT_APP_URL_SERVER + data.trainer.profile_pic
            );

            instance
              .post(`booking/book/start/${reserva}`)
              .then((res) => {
                const info = res.data;

                if (info) {
                  if (info.status == 60) {
                    temporizadorDeRetraso(
                      moment.tz(info.date, info.tz).format(),
                      hourSync
                    );
                    if (!modalShowed.current) {
                      modalShowed.current = true;

                      setModalInfo(
                        <div className="containerComprobar">
                          <div className="containerModal_eleva_class">
                            <img
                              src={headerWait}
                              alt="header"
                              className="iconHeaderWait"
                            />
                            <div
                              className="title scroll"
                              style={{ color: "#394F65" }}
                            >
                              Hola atleta, tu profesor esta preparando
                              <br />
                              <strong>
                                tu clase y en un momento ingresará.
                              </strong>
                            </div>
                            <div className="items scroll">
                              <div className="text-center ">
                                {" "}
                                {laptop575.matches ? <br /> : null}
                                Recuerda al ingresar a tu clase, verificar que
                                los iconos de audio y video en la parte inferior
                                de esta pantalla se encuentren activos.
                                <br />
                                <br />
                                <div className="iconsParentClase">
                                  <div className="iconsAfterClase">
                                    <div>
                                      <img src={videoOff} alt="video off" />
                                      <img src={muted} alt="sonido off" />
                                    </div>
                                    <span className="my-1">Inactivos</span>
                                  </div>
                                  <div className="iconsAfterClase">
                                    <div>
                                      <img src={videoOn} alt="video on" />
                                      <img src={noMuted} alt="sonido on" />
                                    </div>
                                    <span className="my-1">Activos</span>
                                  </div>
                                </div>
                              </div>

                              <div className="cancelarpero">
                                Si tu profesor no ingresa dentro de los
                                siguientes 11 minutos, puedes cancelar tu clase
                                sin costo alguno y tu credito será devuelto.
                                <br />
                                {laptop575.matches ? <br /> : null}
                                <button
                                  id="bottondisaperar"
                                  onClick={submitCancelarClase}
                                  style={{
                                    display: "none",
                                  }}
                                >
                                  Cancelar Entrenamiento
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }

                    setTimeout(() => {
                      conectarclase();
                    }, 5000);
                  } else {
                    setModalInfo(null);
                    console.log(book_grupal);
                    if (book_grupal) {
                      const widgetOriginal = document.querySelector(
                        "iframe[title='chat widget']"
                      );

                      const objcStartGroup = {
                        id_clase: info?.room_id,
                        uid: user.customer.user,
                        nameClient: user.first_name,
                        reserva: reserva,
                        classType: "stream",
                        type: 10,
                        agoraToken: info?.agora_token,
                      };

                      if (widgetOriginal) {
                        const widget2 =
                          widgetOriginal.contentDocument.querySelector(
                            "body .tawk-min-container"
                          );

                        widget2.style = `display:none !important`;
                      }
                      connectPeerToPeerUser(objcStartGroup, true);
                    } else {
                      const objcStart = {
                        id_clase: info.room_id,
                        uid: user.customer.user,
                        reserva: reserva,
                        type: 10,
                        agoraToken: info?.agora_token,
                      };
                      connectPeerToPeer(objcStart, true);
                    }

                    setIdClase(reserva);
                  }
                }
              })
              .catch((err) => {
                console.error(
                  "Error en api/clientes/reservas/conectar-clase",
                  err
                );
              });
          }
        })
        .catch((err) => {
          setModalInfo(
            <CancelarClase
              user={user}
              stepdecancel={1}
              idClase={idClase}
              reserva={reserva}
              noCancelar={noCancelar}
              BookGroup={BookGroup}
            />
          );
        });
    }
    conectarclase();
    // returned function will be called on component unmount
    return () => {
      disconnectPeer();
    };
  }, [reserva]);

  const maximo = maxl0;
  const intenso = (maximo * 90) / 100;
  const moderado = (maximo * 80) / 100;
  const luz = (maximo * 70) / 100;
  const muy_suave = (maximo * 60) / 100;

  const modalShowed = useRef(false);

  let ejecutartgetpost = false;
  useEffect(() => {
    // play2();  ---pitosssss
    if (pitar) localStorage.workoutsetActual = pitar;
  }, [pitar]);

  useEffect(() => {
    // play2();  ---pitosssss
    if (pitarTABATA) localStorage.workoutsetActual = pitarTABATA;
    // setTimeout(() => {
    // play2();  ---pitosssss
    // }, 20000);
  }, [pitarTABATA]);
  // useEffect(() => {
  //   play3();
  // }, [pitoboxing]);
  // async function next(
  //   status_class,
  //   x,
  //   y,
  //   z,
  //   percentage,
  //   incrementometo,
  //   contador
  // ) {
  //   let laquees = gettrue(
  //     status_class,
  //     x,
  //     y,
  //     z,
  //     percentage,
  //     incrementometo,
  //     contador
  //   );

  //   if (laquees.length > 0) {
  //     return new Promise((res, rej) => {
  //       let go = false;
  //       var formData = new FormData();
  //       formData.append(
  //         "percentage",
  //         Number(laquees[3]) > 87
  //           ? Number(laquees[3]).toFixed()
  //           : Number(laquees[3])
  //       );
  //       formData.append("heart_rate", -1);
  //       formData.append("workoutset", laquees[2]);
  //       formData.append("methodology", laquees[1]);
  //       formData.append("methodology_series", laquees[0]);
  //       formData.append("kcal", -1);

  //       instance
  //         .post(`booking/book/${reserva}/workoutlog/`, formData)
  //         .then((resEn) => {
  //           const status_class = resEn.data;
  //           if (status_class) {
  //             go = true;
  //             if (
  //               arrRutinas.current[x].type != 50 ||
  //               arrRutinas.current[x].type != 70
  //             ) {
  //               if (arrRutinas.current[x].type == 60) {
  //                 setpitarTABATA(status_class.workoutset);
  //               } else {
  //                 setpitar(status_class.workoutset);
  //               }
  //             } else {
  //               if (y == 0) {
  //                 setpitar(status_class.workoutset);
  //               }
  //             }
  //           }
  //         })
  //         .catch((err) => {
  //           setModalInfo(
  //             <CancelarClase
  //               user={user}
  //               stepdecancel={2}
  //               idClase={idClase}
  //               reserva={reserva}
  //               noCancelar={noCancelar}
  //             />
  //           );
  //         });
  //       setTimeout(() => {
  //         if (go == true) {
  //           res(false);
  //         } else {
  //           rej(true);
  //         }
  //       }, laquees[4] * 1000);
  //     });
  //   } else {
  //     return false;
  //   }
  // }

  // useEffect(() => {
  //   async function trackingworkoutlog() {
  //     setTimeout(() => {
  //       if (ejecutartgetpost == false) {
  //         ejecutartgetpost = true;
  //         instance
  //           .get(`booking/book/${reserva}/workoutlog/last/`)
  //           .then(async (resEn) => {
  //             const status_class = resEn.data;
  //             if (
  //               status_class &&
  //               status_class.booking_status < 51 &&
  //               arrRutinas.current
  //             ) {
  //               if (status_class.trainer_change == true) {
  //                 async function recorrer(status_class) {
  //                   var m;
  //                   var z;
  //                   let inicio;
  //                   var contador;
  //                   var percentage;
  //                   if (status_class.methodology == null) {
  //                     m = 0;
  //                     z = 1;
  //                     inicio = 0;
  //                     contador = 0;
  //                     percentage = status_class.percentage;
  //                   } else {
  //                     if (
  //                       localStorage.workoutsetActual == status_class.workoutset
  //                     ) {
  //                       contador = localStorage.contadorActual;
  //                       var xx =
  //                         arrRutinas.current &&
  //                         arrRutinas.current.indexOf(
  //                           arrRutinas.current.filter(
  //                             (e) => e.id == status_class.methodology
  //                           )[0]
  //                         );
  //                       var yy =
  //                         arrRutinas.current &&
  //                         arrRutinas.current[
  //                           arrRutinas.current.indexOf(arrRutinas.current[xx])
  //                         ].set.indexOf(
  //                           arrRutinas.current[
  //                             arrRutinas.current.indexOf(arrRutinas.current[xx])
  //                           ].set.filter(
  //                             (e) => e.id == status_class.workoutset
  //                           )[0]
  //                         );
  //                       if (
  //                         arrRutinas.current[
  //                           arrRutinas.current.indexOf(
  //                             arrRutinas.current.filter(
  //                               (e) => e.id == status_class.methodology
  //                             )[0]
  //                           )
  //                         ].series > status_class.methodology_series
  //                       ) {
  //                         percentage = status_class.percentage;
  //                         inicio = yy - -1;
  //                         z = status_class.methodology_series;
  //                         m = xx;
  //                       } else {
  //                         z = status_class.methodology_series;
  //                         percentage = status_class.percentage;
  //                         m = xx;
  //                         if (
  //                           arrRutinas.current[m].type == 60 ||
  //                           arrRutinas.current[m].type == 40
  //                         )
  //                           inicio = arrRutinas.current[m].set[yy - -1]
  //                             ? yy - -1
  //                             : 0;
  //                         else inicio = yy - -1;
  //                       }
  //                     } else {
  //                       contador = 0;
  //                       percentage = status_class.percentage;
  //                       if (
  //                         arrRutinas.current[
  //                           arrRutinas.current.indexOf(
  //                             arrRutinas.current.filter(
  //                               (e) => e.id == status_class.methodology
  //                             )[0]
  //                           )
  //                         ].series > status_class.methodology_series
  //                       ) {
  //                         inicio = 0;
  //                         z = status_class.methodology_series - -1;
  //                         m = arrRutinas.current.indexOf(
  //                           arrRutinas.current.filter(
  //                             (e) => e.id == status_class.methodology
  //                           )[0]
  //                         );
  //                       } else {
  //                         z =
  //                           arrRutinas.current[
  //                             arrRutinas.current.indexOf(
  //                               arrRutinas.current.filter(
  //                                 (e) => e.id == status_class.methodology
  //                               )[0]
  //                             )
  //                           ].series > status_class.methodology_series
  //                             ? status_class.methodology_series
  //                             : 1;

  //                         m =
  //                           arrRutinas.current.indexOf(
  //                             arrRutinas.current.filter(
  //                               (e) => e.id == status_class.methodology
  //                             )[0]
  //                           ) - -1;
  //                         inicio = 0;
  //                       }
  //                     }
  //                   }
  //                   if (
  //                     arrRutinas.current[m].type == 60 ||
  //                     arrRutinas.current[m].type == 40
  //                   ) {
  //                     while (contador < arrRutinas.current[m].time) {
  //                       for (
  //                         let i = inicio;
  //                         i < arrRutinas.current[m].set.length;
  //                         i++
  //                       ) {
  //                         if (arrRutinas.current[m].type == 40) {
  //                           contador = contador - -60;
  //                         } else if (arrRutinas.current[m].type == 60) {
  //                           contador = contador - -30;
  //                         } else {
  //                           contador =
  //                             contador - -arrRutinas.current[m].set[i].time;
  //                         }

  //                         localStorage.contadorActual = contador;

  //                         await next(
  //                           status_class,
  //                           m,
  //                           i,
  //                           z,
  //                           percentage,
  //                           i + 1 - inicio,
  //                           contador
  //                         );
  //                       }
  //                     }
  //                   } else {
  //                     for (
  //                       let i = inicio;
  //                       i < arrRutinas.current[m].set.length;
  //                       i++
  //                     ) {
  //                       contador =
  //                         contador - -arrRutinas.current[m].set[i].time;
  //                       localStorage.contadorActual = contador;
  //                       if (m == 0 && i == 0) {
  //                         setpitoboxing(1);
  //                       }
  //                       await next(
  //                         status_class,
  //                         m,
  //                         i,
  //                         z,
  //                         percentage,
  //                         i + 1 - inicio,
  //                         contador
  //                       );
  //                     }
  //                   }

  //                   instance
  //                     .post(`booking/book/${reserva}/workoutlog/last/`)
  //                     .then((resEn) => {
  //                       const status_class = resEn.data;
  //                       if (status_class) {
  //                         localStorage.removeItem("contadorActual");
  //                         localStorage.removeItem("workoutsetActual");
  //                       }
  //                     })
  //                     .catch((err) => {
  //                       if(err.response){
  //                       setModalInfo(
  //                         <SetModalError
  //                         handleClick={() => setModalInfo(null)}
  //                         title="Algo salio mal"
  //                       />
  //                       );
  //                     }
  //                     });
  //                   return false;
  //                 }
  //                 ejecutartgetpost = await recorrer(status_class);
  //               } else {
  //                 modifyRutinaColor(
  //                   arrRutinas.current.indexOf(
  //                     arrRutinas.current.filter(
  //                       (e) => e.id == status_class.methodology
  //                     )[0]
  //                   )
  //                 );
  //                 ejecutartgetpost = false;
  //               }
  //             } else {
  //               if (status_class.booking_status > 51 && status_class.booking_status !=60) {
  //                 ejecutartgetpost = true;
  //                 setModalInfo(
  //                   <CancelarClase
  //                     user={user}
  //                     stepdecancel={2}
  //                     idClase={idClase}
  //                     reserva={reserva}
  //                     noCancelar={noCancelar}
  //                   />
  //                 );
  //               } else {
  //                 ejecutartgetpost = false;
  //               }
  //             }

  //             trackingworkoutlog();
  //           })
  //           .catch((err) => console.log(err.response));
  //       }
  //     }, 1000);
  //   }
  //   trackingworkoutlog();
  // }, []);

  // function gettrue(getclass, x, y, z, percentage, incrementometo, contador) {
  //   var series = [];
  //   var methodology = [];
  //   var workoutset = [];
  //   var percentaged = [];
  //   var tiempodemora = [];

  //   var cuantasmetodologias = 100 / arrRutinas.current.length;
  //   var morato;
  //   var division;
  //   var setdemetodologias;
  //   division = cuantasmetodologias / arrRutinas.current[x].set.length;

  //   setdemetodologias = division / arrRutinas.current[x].series;
  //   if (arrRutinas.current[x].type == 50 || arrRutinas.current[x].type == 70) {
  //     tiempodemora.push(1);
  //   } else if (arrRutinas.current[x].type == 40) {
  //     tiempodemora.push(60);
  //   } else if (arrRutinas.current[x].type == 60) {
  //     tiempodemora.push(30);
  //   } else {
  //     tiempodemora.push(arrRutinas.current[x].set[y].time);
  //   }
  //   series.push(z);

  //   methodology.push(arrRutinas.current[x].id);
  //   workoutset.push(arrRutinas.current[x].set[y].id);
  //   if (
  //     contador > 0 &&
  //     tiempodemora[0] * arrRutinas.current[x].set.length < contador
  //   ) {
  //     if (percentage >= cuantasmetodologias * (x - -1)) {
  //       percentaged.push(percentage);
  //     } else {
  //       percentaged.push(
  //         percentage - -(setdemetodologias * arrRutinas.current[x].set.length)
  //       );
  //     }
  //   } else {
  //     if (percentage >= cuantasmetodologias * (x - -1)) {
  //       percentaged.push(percentage);
  //     } else {
  //       percentaged.push(percentage - -(setdemetodologias * incrementometo));
  //     }
  //   }

  //   modifyRutinaColor(x);

  //   if (contador > 0 && contador > arrRutinas.current[x].time) {
  //     morato = [];
  //   } else {
  //     morato = series
  //       .concat(methodology)
  //       .concat(workoutset)
  //       .concat(Number.parseFloat(percentaged).toFixed(2))
  //       .concat(tiempodemora);
  //   }
  //   return morato;
  // }

  const [timeLeft, setTimeLeft] = useState(fancyTimeFormat(0));
  let intervalSearch = null;
  const [objReserva, setReserva] = useState(null);
  const [relojIniciar, setrelojIniciar] = useState(null);
  const [bookings, setbookings] = useState(null);
  useEffect(() => {
    async function conectarreloj() {
      setTimeout(() => {
       instance
          .get(`booking/book/${reserva}`)
          .then((resEn) => {
            const status_class = resEn.data.status;
            setrelojIniciar(status_class);

            if (status_class !== 30) {
              conectarreloj();
            }
          })
          .catch((err) => console.log(err.response));
      }, 5000);
    }
    conectarreloj();
  }, []);
  console.log(bookings);
  useEffect(() => {
    instance.get(`booking/book/${reserva}`).then((res) => {
      const data = res.data;
      const fecha = data.date.replace("T", " ").slice(0, 16);

      if (data != null) {
        setReserva({
          id: reserva,
          fecha: fecha,
        });
      }
    });
    return () => {
      clearInterval(timeOutcheckVideo);
      clearInterval(timeOutcheckAudio);
    };
  }, []);

  useEffect(() => {
    if (relojIniciar == 30) {
      sync_clock();
    }
  }, [relojIniciar]);
  let intervalId; // Variable para almacenar el ID del intervalo
  function detenerIntervalo() {
    clearInterval(intervalId);
  }
  function iniciarIntervalo(actualTime) {
    intervalId = setInterval(() => {
      if (actualTime > 0) {
        actualTime -= 1;

        setTimeLeft(fancyTimeFormat(actualTime));
        if (!bandConnected) {
          if (BookGroup) {
            console.log("entra 1");
            hr_data_emit({
              booking_id: Number(reserva),
              percentage: 100,
              kcal: -1,
              heart_rate: -1,
            });
          } else {
            console.log("entra 2");
            hr_data_emit({
              booking_id: Number(reserva),

              kcal: -1,
              heart_rate: -1,
            });
          }
        }
      }
    }, 1000); // Intervalo de 1 segundo
  }
  useEffect(() => {
    if (startime) {
      detenerIntervalo();
      setstartime(false);
    }
  }, [startime]);

  useEffect(() => {
    // Función para iniciar el intervalo
    if (hourSync) {
      if (objReserva == null) return false;
      const fechaclar = moment(objReserva.fecha).format(
        "dddd DD [de] MMMM / HH:mm a"
      );
      const timeStart = hourSync;
      const timeEnd = moment(fechaclar, "dddd DD [de] MMMM / HH:mm a");

      timeEnd.add(1, "hour");
      const diff = timeEnd.diff(timeStart, "seconds");
      actualTime.current = diff;
      iniciarIntervalo(actualTime.current);
      console.log("objReserva", objReserva);
    }
  }, [hourSync]);

  function cancelarClase() {
    showOrHidePanel(myPanel, myPanelTab, "left", true);
    setModalInfo(
      <CancelarClase
        user={user}
        stepdecancel={percentageactual && percentageactual > 95 ? 2 : 1}
        idClase={idClase}
        reserva={reserva}
        noCancelar={noCancelar}
        BookGroup={BookGroup}
      />
    );
  }

  function noCancelar() {
    setModalInfo(null);
  }

  const paneles = (
    <>
      {BookGroup ? (
        <Panel
          align="left"
          classNameContent="myPanel"
          contentRef={myPanel}
          tabRef={myPanelTab}
          tabCerrar={cerrar}
          tabAvatar={avatar ? avatar : customAvatar}
          classNameTab="myPanelTab"
        >
          <div className="logo" style={{ background: "#7886F5" }}>
            <img alt="logo" src={logo_eleva} style={{ margin: "4% auto " }} />
          </div>

          <PerfectScrollbar>
            {bookings &&
              bookings
                .filter((person) => person.id == user.customer.user)
                .map((e, indice) => (
                  <div
                    key={"person" + indice}
                    className="py-2"
                    style={{
                      width: "90%",
                      display: "grid",
                      gridTemplateColumns: "repeat(1,1fr)",
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ borderBottom: "1px solid #ADBAC4" }}
                    >
                      <div style={{ position: "relative", padding: "0.3vw 0" }}>
                        {HrData &&
                        HrData[e.booking_id] &&
                        HrData[e.booking_id].heart_rate > 0 ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                            }}
                          >
                            {" "}
                            <img
                              style={{ width: "2.5vmin", height: "2.5vmin" }}
                              alt="banda"
                              src={band_conected}
                            />
                          </div>
                        ) : null}
                        <img
                          src={e.profile_pic}
                          style={{
                            width: "5vw",
                            height: "5vw",
                            borderRadius: "60px",
                            padding: "0.5vw",
                            objectFit: "cover",
                          }}
                        />{" "}
                      </div>
                      <div
                        style={{
                          padding: "0.5vw",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            lineHeight: "1",
                            color: "#394F65",
                            fontSize: "0.7vw",
                          }}
                        >
                          {e["full_name"]} <br />
                          <span
                            style={{ fontWeight: "500", fontSize: "0.7vw" }}
                          >
                            {" "}
                            {e["level"] == 20
                              ? "INTERMEDIO"
                              : e["level"] == 30
                              ? "AVANZADO"
                              : e["level"] == 10
                              ? "PRINCIPIANTE"
                              : null}
                          </span>
                        </div>

                        {HrData &&
                        HrData[e.booking_id] &&
                        HrData[e.booking_id].heart_rate > 0 ? (
                          <>
                            {" "}
                            <div className="d-flex">
                              <div style={{ padding: "0.4vw 0" }}>
                                <img
                                  src={corazon}
                                  style={{
                                    width: "1.2vw",
                                    height: "1.2vw",
                                  }}
                                />
                              </div>
                              <div
                                style={
                                  HrData[e.booking_id].heart_rate >=
                                  (220 - e["age"]) * 0.9
                                    ? {
                                        color: "#E16250",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : (220 - e["age"]) * 0.8 <=
                                        HrData[e.booking_id].heart_rate &&
                                      HrData[e.booking_id].heart_rate <
                                        (220 - e["age"]) * 0.9
                                    ? {
                                        color: "#FFB6A4",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : (220 - e["age"]) * 0.7 <=
                                        HrData[e.booking_id].heart_rate &&
                                      HrData[e.booking_id].heart_rate <
                                        (220 - e["age"]) * 0.8
                                    ? {
                                        color: "#F5D7CF",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : (220 - e["age"]) * 0.6 <=
                                        HrData[e.booking_id].heart_rate &&
                                      HrData[e.booking_id].heart_rate <
                                        (220 - e["age"]) * 0.7
                                    ? {
                                        color: "#7988F6",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : {
                                        color: "#6EE1FB",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                }
                              >
                                {HrData[e.booking_id].heart_rate &&
                                HrData[e.booking_id].heart_rate > 0
                                  ? HrData[e.booking_id].heart_rate
                                  : "--"}
                              </div>
                            </div>
                            <div className="d-flex">
                              <div style={{ padding: "0.4vw 0" }}>
                                <img
                                  src={flama}
                                  style={{
                                    width: "1.1vw",
                                    height: "1.4vw",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  fontSize: "1.4vw",
                                  padding: "0 0.6vw",
                                }}
                              >
                                {HrData[e.booking_id].kcal}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              color: "#394F65",
                              fontSize: "1.7vw",
                              padding: "0 0.6vw",
                            }}
                          >
                            --
                          </div>
                        )}
                      </div>
                    </div>{" "}
                  </div>
                ))}

            {bookings &&
              bookings
                .filter(
                  (person) =>
                    person.id != user.customer.user &&
                    Object.keys(HrData) &&
                    Object.keys(HrData).includes(String(person.booking_id)) &&
                    person.connected == true
                )
                .map((e, indice) => (
                  <div
                    key={"person" + indice}
                    className="py-2"
                    style={{
                      width: "90%",
                      display: "grid",
                      gridTemplateColumns: "repeat(1,1fr)",
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ borderBottom: "1px solid #ADBAC4" }}
                    >
                      <div style={{ position: "relative", padding: "0.3vw 0" }}>
                        {HrData &&
                        HrData[e.booking_id] &&
                        HrData[e.booking_id].heart_rate > 0 ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                            }}
                          >
                            {" "}
                            <img
                              style={{ width: "2.5vmin", height: "2.5vmin" }}
                              alt="banda"
                              src={band_conected}
                            />
                          </div>
                        ) : null}
                        <img
                          src={e.profile_pic}
                          style={{
                            width: "5vw",
                            height: "5vw",
                            borderRadius: "60px",
                            padding: "0.5vw",
                            objectFit: "cover",
                          }}
                        />{" "}
                      </div>
                      <div
                        style={{
                          padding: "0.5vw",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            lineHeight: "1",
                            color: "#394F65",
                            fontSize: "0.7vw",
                          }}
                        >
                          {e["full_name"]} <br />
                          <span
                            style={{ fontWeight: "500", fontSize: "0.7vw" }}
                          >
                            {" "}
                            {e["level"] == 20
                              ? "INTERMEDIO"
                              : e["level"] == 30
                              ? "AVANZADO"
                              : e["level"] == 10
                              ? "PRINCIPIANTE"
                              : null}
                          </span>
                        </div>

                        {HrData &&
                        HrData[e.booking_id] &&
                        HrData[e.booking_id].heart_rate > 0 ? (
                          <>
                            {" "}
                            <div className="d-flex">
                              <div style={{ padding: "0.4vw 0" }}>
                                <img
                                  src={corazon}
                                  style={{
                                    width: "1.2vw",
                                    height: "1.2vw",
                                  }}
                                />
                              </div>
                              <div
                                style={
                                  HrData[e.booking_id].heart_rate >=
                                  (220 - e["age"]) * 0.9
                                    ? {
                                        color: "#E16250",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : (220 - e["age"]) * 0.8 <=
                                        HrData[e.booking_id].heart_rate &&
                                      HrData[e.booking_id].heart_rate <
                                        (220 - e["age"]) * 0.9
                                    ? {
                                        color: "#FFB6A4",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : (220 - e["age"]) * 0.7 <=
                                        HrData[e.booking_id].heart_rate &&
                                      HrData[e.booking_id].heart_rate <
                                        (220 - e["age"]) * 0.8
                                    ? {
                                        color: "#F5D7CF",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : (220 - e["age"]) * 0.6 <=
                                        HrData[e.booking_id].heart_rate &&
                                      HrData[e.booking_id].heart_rate <
                                        (220 - e["age"]) * 0.7
                                    ? {
                                        color: "#7988F6",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                    : {
                                        color: "#6EE1FB",
                                        fontSize: "1.4vw",
                                        padding: "0 0.6vw",
                                      }
                                }
                              >
                                {HrData[e.booking_id].heart_rate &&
                                HrData[e.booking_id].heart_rate > 0
                                  ? HrData[e.booking_id].heart_rate
                                  : "--"}
                              </div>
                            </div>
                            <div className="d-flex">
                              <div style={{ padding: "0.4vw 0" }}>
                                <img
                                  src={flama}
                                  style={{
                                    width: "1.1vw",
                                    height: "1.4vw",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  fontSize: "1.4vw",
                                  padding: "0 0.6vw",
                                }}
                              >
                                {HrData[e.booking_id].kcal}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              color: "#394F65",
                              fontSize: "1.7vw",
                              padding: "0 0.6vw",
                            }}
                          >
                            --
                          </div>
                        )}
                      </div>
                    </div>{" "}
                  </div>
                ))}
          </PerfectScrollbar>
          {/* {bandConnected ? (
            <div style={{ position: "absolute", top: "0", left: "-25%" }}>
              {" "}
              <img
                style={{ width: "5vmin", height: "5vmin" }}
                alt="banda"
                src={band_conected}
              />
            </div>
          ) : null} */}

          <div>
            {/* <div className="Cardio infoClas ">
            <img src={corazon} alt="cardio" />
            <div>
              <div className="datosClass">
                <span
                  className="valor"
                  style={
                    frecuenciaCustomer >= intenso
                      ? { color: "#E16250" }
                      : moderado <= frecuenciaCustomer &&
                        frecuenciaCustomer < intenso
                      ? { color: "#FFB6A4" }
                      : luz <= frecuenciaCustomer &&
                        frecuenciaCustomer < moderado
                      ? { color: "#F5D7CF" }
                      : muy_suave <= frecuenciaCustomer &&
                        frecuenciaCustomer < luz
                      ? { color: "#7988F6" }
                      : frecuenciaCustomer > 0 && frecuenciaCustomer < muy_suave
                      ? { color: "#6EE1FB" }
                      : { color: "#9DACBD" }
                  }
                >
                  {frecuenciaCustomer && frecuenciaCustomer > 0
                    ? frecuenciaCustomer
                    : "- - -"}
                </span>
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: laptop575.matches ? "auto" : "1.5vw",
                }}
              >
                {" "}
                Frecuencia cardíaca
              </span>
            </div>
          </div> */}
          </div>

          <div className="Finalizar">
            <button className="btnFinalizar" onClick={cancelarClase}>
              Finalizar sesión
            </button>
          </div>
        </Panel>
      ) : (
        <Panel
          align="left"
          classNameContent="myPanel"
          contentRef={myPanel}
          tabRef={myPanelTab}
          tabCerrar={cerrar}
          tabAvatar={avatar ? avatar : customAvatar}
          classNameTab="myPanelTab"
        >
          <div className="logo" style={{ background: "#7886F5" }}>
            <img alt="logo" src={logo_eleva} style={{ margin: "4% auto " }} />
          </div>

          <div className="imgEntrenador1 position-relative">
            <img
              alt="av"
              src={avatar ? avatar : myCamera}
              className="rounded-circle"
            />
            {bandConnected ? (
              <div style={{ position: "absolute", top: "0", left: "-25%" }}>
                {" "}
                <img
                  style={{ width: "5vmin", height: "5vmin" }}
                  alt="banda"
                  src={band_conected}
                />
              </div>
            ) : null}
          </div>

          <div className="imgEntrenador">
            <div className="infoPeer align-center">
              <div className="name">{nombreCliente}</div>
              <div className="calificacionEntrenador">
                <img
                  src={estrellas >= 1.0 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 2.0 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 3.0 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 4.0 ? starfill : star}
                  alt="etrella"
                  className=""
                />
                <img
                  src={estrellas >= 5.0 ? starfill : star}
                  alt="etrella"
                  className=""
                />
              </div>
            </div>
          </div>
          <div>
            <div className="Cardio infoClas ">
              <img src={corazon} alt="cardio" />
              <div>
                <div className="datosClass">
                  <span
                    className="valor"
                    style={
                      frecuenciaCustomer >= intenso
                        ? { color: "#E16250" }
                        : moderado <= frecuenciaCustomer &&
                          frecuenciaCustomer < intenso
                        ? { color: "#FFB6A4" }
                        : luz <= frecuenciaCustomer &&
                          frecuenciaCustomer < moderado
                        ? { color: "#F5D7CF" }
                        : muy_suave <= frecuenciaCustomer &&
                          frecuenciaCustomer < luz
                        ? { color: "#7988F6" }
                        : frecuenciaCustomer > 0 &&
                          frecuenciaCustomer < muy_suave
                        ? { color: "#6EE1FB" }
                        : { color: "#9DACBD" }
                    }
                  >
                    {frecuenciaCustomer && frecuenciaCustomer > 0
                      ? frecuenciaCustomer
                      : "- - -"}
                  </span>
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: laptop575.matches ? "auto" : "1.5vw",
                  }}
                >
                  {" "}
                  Frecuencia cardíaca
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="Calorias infoClas infoClas_F ">
              <img src={flama} alt="cardio" />
              <div>
                <div className="datosClass">
                  <span className="valor">
                    {kcalCustomer && kcalCustomer > 0
                      ? kcalCustomer.toFixed(0)
                      : "- - -"}
                  </span>
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: laptop575.matches ? "auto" : "1.5vw",
                  }}
                >
                  {" "}
                  Calorías quemadas
                </span>
              </div>
            </div>
          </div>

          <div className="textFinalizar">
            <div>¿Quieres finalizar tu sesión de entrenamiento?</div>
            <span
              style={{ fontSize: laptop575.matches ? "calc(1rem)" : "1.5vw" }}
            >
              Ten en cuenta que si finalizas tu sesión, no podrás volver a
              ingresar.
            </span>
          </div>
          <div className="Finalizar">
            <button className="btnFinalizar" onClick={cancelarClase}>
              Finalizar sesión
            </button>
          </div>
        </Panel>
      )}
      {BookGroup ? (
        <div
          className="tiempo"
          style={{
            background: "rgb(38,38,38,0.7)",
            borderRadius: "7px",
            position: "fixed",
            width: "16%",
            textAlign: "center",
            padding: "0.5vw 0",
            right: "0 ",
            zIndex: "999999",
            fontSize: "4vw",
            fontWeight: "700",
            fontWeight: "bold",
            color: "white",
          }}
        >
          <span style={{ fontSize: "4vw" }}>{timeLeft}</span>
          <br />
          <div
            style={{
              fontSize: "1vw",
              fontWeight: "700",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {" "}
            Tiempo de sesión
          </div>
        </div>
      ) : (
        <Panel
          align="right"
          classNameContent="miEntrenamiento"
          contentRef={miEntrenamiento}
          tabRef={miEntrenamientoTab}
          tabAvatar={corazon}
          tabCerrar={cerrar}
          classNameTab="miEntrenamientoTab"
        >
          <div
            className="titulo"
            style={{ color: "#FFFFFF", background: "#395056" }}
          >
            Ruta de entrenamiento
          </div>
          <div className="rutaContainer">
            <div className="rutas" ref={refRutinas}>
              {rutina}
            </div>
          </div>
          <div className="tiempo" style={{ color: "#394F65" }}>
            <span style={{ color: "#9DACBD" }}>{timeLeft}</span>
            <br />
            Sesión de entrenamiento
          </div>
        </Panel>
      )}
    </>
  );
  return (
    <>
      <ContenedorClase paneles={paneles} clientestream={BookGroup} />
      {modalInfo}
    </>
  );
};

const CancelarClase = (props) => {
  SwiperCore.use([Pagination]);
  const idClase = props.idClase;
  const reserva = props.reserva;
  const stepdecancel = props.stepdecancel || null;
  const user = props.user;
  const [msgCancelar, setMsgCancelar] = useState("");
  const [nivelCancelar, setNivelCaneclar] = useState(10);
  const [setp, setStep] = useState(stepdecancel ? stepdecancel : 1);
  const [loadingCancelar, setLoadingCancelar] = useState(null);
  const  BookGroup=props.BookGroup || false

  function noCancelar() {
    props.noCancelar();
  }

  function renderStars(refStep) {
    return (
      <div className="estrellas" ref={refStep} value={0}>
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s1"
          onClick={() => setStars(refStep, 1) || NextSlider()}
        />

        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s2"
          onClick={() => setStars(refStep, 2) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s3"
          onClick={() => setStars(refStep, 3) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s4"
          onClick={() => setStars(refStep, 4) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s5"
          onClick={() => setStars(refStep, 5) || NextSlider()}
        />
      </div>
    );
  }

  function setStars(theRef, pos) {
    theRef.current.setAttribute("value", pos);

    for (let index = 1; index <= 5; index++) {
      const estrella = theRef.current.querySelector("#s" + index);
      if (pos >= index) estrella.src = starfill;
      else estrella.src = star;
    }
  }

  async function cancelarDefinitivo() {
    setLoadingCancelar(
      <Loadingspinner
        customStyle="grow"
        size={laptop575.matches ? "5rem" : "3rem"}
      />
    );

    const array = [];
    array.push(
      nivelCancelar,
      Number(refPresentacion.current.getAttribute("value")),
      Number(refLugar.current.getAttribute("value")),
      Number(refConocmiento.current.getAttribute("value")),
      Number(refEnergia.current.getAttribute("value"))
    );

    const formData = new FormData();
    formData.append("comment", msgCancelar);
    formData.append("answers", array);
    formData.append("hr", 0);

    instance
      .post(`/booking/book/finish/${reserva}`, {
        comment: msgCancelar,
        answers: array,
        hr: BookGroup? -1: 0,
      })
      // .post(`/booking/book/finish/${reserva}`, formData)
      .then((res) => {
        if (res.data) {
          booking_finish_customer_emit({
            booking_id: reserva,
            customer_id: localStorage.id,
          });
           window.location.href = `${basename}/results/${reserva}`;
        }
      })
      .catch((err) => {
        setLoadingCancelar(
          <div style={{ color: "red" }}>
            Ocurrio un error al momento de finalizar
          </div>
        );
        setTimeout(() => {
          setLoadingCancelar(null);
        }, 3000);
      });
  }

  const [theSwipper, setTheSwipper] = useState(null);

  const refPresentacion = useRef(null);
  const refLugar = useRef(null);
  const refConocmiento = useRef(null);
  const refEnergia = useRef(null);

  function NextSlider() {
    theSwipper.slideNext();
  }

  // function ValueLabelComponent(props) {
  //   const { children, open, value } = props;

  //   return (
  //     <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
  //       {children}
  //     </Tooltip>
  //   );
  // }
  function nos_fuimos() {
    window.location.href = `${basename}/results/${reserva}`;
  }
  function eliminarstorage() {
    nos_fuimos();
  }

  return (
    <>
      <div className="containerFinalizar">
        <div className="containerModal" style={{ position: "relative" }}>
          {setp == 1 ? (
            <>
              {" "}
              <form onSubmit={(e) => setStep(2)}>
                <h1 className="title">Finalizar sesión</h1>
                <br />
                <div className="subtitle">
                  Cuéntanos por qué deseas finalizar
                  <br />
                  tu sesión de entrenamiento.
                </div>
                <div className="observaciones">
                  <textarea
                    required
                    style={{ color: "#9DACBD" }}
                    placeholder="Escribe aquí tu motivo"
                    rows={7}
                    onInput={(e) => setMsgCancelar(e.target.value)}
                  ></textarea>
                </div>
                <div className="botones">
                  <button className="sifin me-2" type="submit">
                    Confirmar finalización
                  </button>
                  <button className="nofin" onClick={noCancelar}>
                    No deseo finalizar
                  </button>
                </div>
              </form>
            </>
          ) : setp == 2 ? (
            <>
              <div className="swiper-pagination"> </div>
              <h2 className="title">Ayúdanos a mejorar</h2>

              <p className="subtitle">
                <br />
                Responde estas 5 preguntas que nos permiten
                <br />
                mejorar continuamente nuestro servicio.
              </p>
              {laptop575.matches ? <br /> : null}
              <hr></hr>
              <Swiper
                // pagination={{
                //   clickable: true ,
                //    el: ".swiper-pagination",
                //     type: "custom",

                //    renderCustom: function (swiper, current, total) {
                //     if (theSwipper == null) {
                //       setTheSwipper(swiper);
                //    }

                //    },
                // }}
                pagination={{
                  clickable: true,
                  el: ".bulletsQuestions",
                  type: "custom",
                  renderCustom: function (swip, current) {
                    if (theSwipper == null) {
                      setTheSwipper(swip);
                    }

                    // if(vid){

                    // handleChange_bullet(vid[current - 1].main_topic);
                    // }
                    current -= 1;

                    const render_img_desk = [1, 2, 3, 4, 5];
                    if (render_img_desk) {
                      return render_img_desk
                        .map((value, index) => {
                          return `<div key=${index} id=${
                            "pointerCompanie" + index
                          } 
                   class='pointerCompanie swiper-pagination-bullet mx-2 ${
                     current == index ? "active" : ""
                   }'></div>`;
                        })
                        .join("");
                    }
                  },
                }}
                spaceBetween={10}
                slidesPerView={"auto"}
                navigation
                allowTouchMove={false}
                className="preguntas"
                onSlideChange={(sp) => {
                  const toolt = document.querySelector(".MuiTooltip-popper");
                  if (toolt) {
                    if (sp.activeIndex == 0) {
                      toolt.classList.add("visible");
                      toolt.classList.remove("invisible");
                    } else {
                      toolt.classList.add("invisible");
                      toolt.classList.remove("visible");
                    }
                  }
                }}
              >
                <SwiperSlide className="pregunta">
                  <div className="title_question_class">
                    ¿Cómo estuvo el nivel de dificultad del entrenamiento?
                    <br />
                    <br />
                  </div>
                  <div className="d-flex justify-evenly ">
                    <ButtonMob
                      className={`${
                        nivelCancelar == 10 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Fácil"}
                      handleClick={() => setNivelCaneclar(10) || NextSlider()}
                    />
                    <ButtonMob
                      className={`${
                        nivelCancelar == 20 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Adecuado"}
                      handleClick={() => setNivelCaneclar(20) || NextSlider()}
                    />
                    <ButtonMob
                      className={`${
                        nivelCancelar == 30 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Dificil"}
                      handleClick={() => setNivelCaneclar(30) || NextSlider()}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div className="title_question_class">
                    La presentación personal del profesor fue <br />
                    <br />
                  </div>
                  {renderStars(refPresentacion)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div className="title_question_class">
                    El espacio, ambiente y lugar en el que el profesor dicto la
                    clase fue <br />
                    <br />
                  </div>
                  {renderStars(refLugar)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div className="title_question_class">
                    El profesor demostró conocimiento y experiencia durante la
                    clase <br />
                    <br />
                  </div>
                  {renderStars(refConocmiento)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div className="title_question_class">
                    ¿El profesor durante el entrenamiento trasmitió una buena
                    energía y motivación? <br />
                    <br />
                  </div>
                  {renderStars(refEnergia)}

                  <div className="botones">
                    {loadingCancelar ? (
                      loadingCancelar
                    ) : (
                      <button
                        className="sifin me-2"
                        style={{ width: "auto" }}
                        onClick={cancelarDefinitivo}
                      >
                        Confirmar finalización
                      </button>
                    )}
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="bulletsQuestions"></div>
            </>
          ) : setp == 3 ? (
            eliminarstorage()
          ) : null}
        </div>
      </div>
    </>
  );
};
{
  /* <Redirect to={`/Usuario/resumen/${reserva}/${idClase}`} /> */
}

export const ComprobarClase = (props) => {
  const isChecked = props.isChecked;

  const refSystem = useRef(null);
  const refMic = useRef(null);
  const refCamera = useRef(null);
  const refWifi = useRef(null);
  const [modalInfo, setModalInfo] = useState(null);
  function hideModal() {
    setModalInfo(null);
  }

  useEffect(() => {
    async function checkSystem() {
      if (refSystem.current == null) return false;
      let timerSpeed = null;

      var imageAddr =
        "https://api.elevva.com.co/uploads/trainings/mains/img3_xbrDtFw.jpg";
      var downloadSize = 4995374; //bytes
      function ShowProgressMessage(msg) {
        if (console) {
          if (typeof msg == "string") {
          } else {
            for (var i = 0; i < msg.length; i++) {
              console.log(msg[i]);
            }
          }
        }
      }

      function InitiateSpeedDetection() {
        ShowProgressMessage("Loading the image, please wait...");
        window.setTimeout(MeasureConnectionSpeed, 1);
      }

      function MeasureConnectionSpeed() {
        var startTime, endTime;
        var download = new Image();
        download.onload = function () {
          endTime = new Date().getTime();
          showResults();
        };
        download.onerror = function (err, msg) {
          ShowProgressMessage("Invalid image, or error downloading");
        };
        startTime = new Date().getTime();
        var cacheBuster = "?nnn=" + startTime;
        download.src = imageAddr + cacheBuster;
        function showResults() {
          var duration = (endTime - startTime) / 1000;
          var bitsLoaded = downloadSize * 8;
          var speedBps = (bitsLoaded / duration).toFixed(2);
          var speedKbps = (speedBps / 1024).toFixed(2);
          var speedMbps = (speedKbps / 1024).toFixed(2);
          ShowProgressMessage([
            "Your connection speed is:",
            speedBps + " bps",
            speedKbps + " kbps",
            speedMbps + " Mbps",
          ]);
          if (speedMbps >= 1) {
            console.log(
              `Su velocidad (${speedMbps} Mbps) es aceptable para la clase.`
            );
          } else {
            console.log(
              `Su velocidad (${speedMbps} Mbps) no es recomendable para la clase.`
            );
            setModalInfo(
              <ModalInfo
                error="true"
                handleClick={hideModal}
                title="Tu ancho de banda no es el adecuado para la clase."
                text="Es posible que experimentes caídas de señal durante la clase."
                classesMsg=""
              />
            );
          }
          localStorage.setItem("checked", Date.now());
          clearTimeout(timerSpeed);
          checked();
        }
      }

      const handleDeviceError = (err, customMsg) => {
        let reload = false;
        let title = "Ocurrio un error";
        let msg =
          "Ocurrio un error al momento de obtener información del dispositivo";
        if (err && err.code === "PERMISSION_DENIED") {
          msg = "Debes permitir el acceso al dispositivo desde el navegador.";
          reload = true;
        }
        if (customMsg) {
          msg = customMsg;
          if (customMsg.includes("correcta del micrófono")) {
            reload = true;
          }
        }
        if (reload === true) {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={() => window.location.reload()}
              title={title}
              text={msg + (err ? "-" + err.code : "")}
              classesMsg=""
            />
          );
        } else {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title={title}
              text={msg}
              classesMsg=""
            />
          );
        }
      };

      const createVideo = async (camID) => {
        const selected = document.querySelector("#listVideo");
        const videoDiv = document.querySelector("#testDevice");
        selected.setAttribute("disabled", true);
        videoDiv.style = "width: 440px; height: 280px; display: none";
        // console.log(camID);

        const localTestDevice = await AgoraRTC.createCameraVideoTrack({
          cameraId: camID,
        }).catch((e) => handleDeviceError(e, "createCameraVideoTrack"));

        localTestDevice.play("testDevice");
        const checkVideo = await AgoraRTC.checkVideoTrackIsActive(
          localTestDevice
        ).catch((e) => handleDeviceError(e, "checkVideoTrackIsActive"));
        // console.log(`${camID} is ${checkVideo ? "available" : "unavailable"}`);
        localTestDevice.stop();
        localTestDevice.close();
        if (checkVideo) {
          // all ok
          rtc.selectedCamId = camID; // Save the cam selected to use later in current lesson
          return true;
        } else {
          selected.value = "";
          selected.removeAttribute("disabled");
          return false;
        }
      };

      const createAudio = async (audioID) => {
        const selected = document.querySelector("#listMicro");
        const audioDiv = document.querySelector("#testDevice");
        audioDiv.innerHTML = "";
        selected.setAttribute("disabled", true);
        const localTestDevice = await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: audioID,
        }).catch((e) => handleDeviceError(e, "createMicrophoneAudioTrack"));
        localTestDevice.setVolume(200);
        const checkAudio = await AgoraRTC.checkAudioTrackIsActive(
          localTestDevice
        ).catch((e) => handleDeviceError(e, "checkAudioTrackIsActive"));
        localTestDevice.stop();
        localTestDevice.close();
        audioDiv.style = "";
        audioDiv.innerHTML = "";
        if (checkAudio) {
          // all ok
          rtc.selectedMicId = audioID;
          return true;
        } else {
          selected.value = "";
          selected.removeAttribute("disabled");
          return false;
        }
      };

      const checkImg = document.createElement("img");
      checkImg.src = check;
      checkImg.style.marginLeft = "1%";
      checkImg.style.width = "3.5%";
      //Check the system requirements
      refSystem.current.style.visibility = "visible";
      const checkSysReq = AgoraRTC.checkSystemRequirements();
      if (checkSysReq) {
        refSystem.current.querySelector(".text").append(checkImg.cloneNode());
      }

      const cameraDiv = refCamera.current;
      //creamos el span para poner la camara ahí (No se verá)
      const testDevice = document.createElement("span");
      testDevice.id = "testDevice";
      cameraDiv.append(testDevice);
      //check the mic
      const micDiv = refMic.current;
      const selectMicro = document.createElement("select");
      selectMicro.id = "listMicro";
      selectMicro.className = "form-select";
      const spanMic = micDiv.querySelector("span");
      // y lo añadimos al span despues del texto
      spanMic.append(selectMicro);
      micDiv.style.visibility = "visible";

      // const audioDevices = await AgoraRTC.getDevices().catch((e) =>
      //   handleDeviceError(e, "getMicrophones")
      // );
      const audioDevices1 = await AgoraRTC.getDevices(function (devices) {
        devices.filter(function (device) {
          return device.kind === "audiooutput" || device.kind === "audioinput";
        });
      });
      const audioDevices = await AgoraRTC.getMicrophones().catch((e) =>
        handleDeviceError(e, "getMicrophones")
      );

      if (!audioDevices) {
        // No encontro microfonos

        return false;
      }
      if (audioDevices.length == 0) {
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Lo sentimos, no hemos encontrado ningún micrófono."
            text="Por favor verifica que este bien conectado y funcione correctamente."
            classesMsg=""
          />
        );
      }
      let audioOk = false;
      for (let [index, micro] of audioDevices.entries()) {
        const option = document.createElement("option");
        option.value = micro.deviceId;
        option.text = micro.label;
        if (micro.label === "") {
          option.text = "Microfono " + (index + 1);
        }
        selectMicro.append(option);

        if (index === 0) {
          option.selected = true;
          audioOk = await createAudio(micro.deviceId);

          if (audioOk) {
            micDiv.querySelector(".text").append(checkImg.cloneNode());
          } else {
            handleDeviceError(
              null,
              "No se pudo obtener información correcta del micrófono. ¿Hay salida de audio conectada?."
            );
            return false;
          }
        }
      }

      //Check the camera
      //Creamos el select que tendra la lista de camaras coenctadas
      const selectVideo = document.createElement("select");
      selectVideo.id = "listVideo";
      selectVideo.className = "form-select";
      const spanCamera = cameraDiv.querySelector("span"); // y lo añadimos al span despues del texto
      spanCamera.append(selectVideo);
      cameraDiv.style.visibility = "visible";
      const cameras = await AgoraRTC.getCameras().catch((e) =>
        handleDeviceError(e, "getCameras")
      );
      if (!cameras) {
        // No encontro las camarás

        return false;
      }
      let videoOk = null;
      if (cameras.length === 0) {
        setModalInfo(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            title="Lo sentimos, no hemos encontrado ninguna cámara."
            text="Por favor verifica que este bien conectada y funcione correctamente."
            classesMsg=""
          />
        );
      } else {
        for (let [index, camera] of cameras.entries()) {
          const option = document.createElement("option");
          option.value = camera.deviceId;
          option.text = camera.label;
          selectVideo.append(option);
          //if (index === 0) {
          option.selected = true;
          videoOk = await createVideo(camera.deviceId);
          if (videoOk) {
            cameraDiv.querySelector(".text").append(checkImg.cloneNode());
            break;
          }
          //}
        }
      }

      if (videoOk) {
        refWifi.current.style.visibility = "visible";
        InitiateSpeedDetection();
        timerSpeed = setTimeout(() => {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Tu ancho de banda no es el adecuado para la clase."
              text="Es posible que experimentes caídas de señal durante la clase."
              classesMsg=""
            />
          );
          localStorage.setItem("checked", Date.now());
          checked();
        }, 7000);
      } else {
        handleDeviceError(null);
      }
    }
    if (refSystem.current != undefined) {
      if (refSystem.current != null) {
        checkSystem();
      }
    }
  }, [refSystem]);
  const checked = () => {
    props.onChecked(true);
  };
  if (isChecked) return <></>;
  return (
    <div className="containerComprobar">
      <div className="containerModal_eleva_class">
        <div className="iconTitle text-center my-1">
          <img src={lupa} alt="lupa" className="lupa_clase_eleva" />
        </div>
        <div
          className="title text-center"
          style={{ color: "#394F65", fontSize: "4vmin" }}
        >
          Estamos comprobando que tu sistema funcione
          <br />
          <strong> adecuadamente para que tu experiencia sea la mejor.</strong>
        </div>
        <div className="items">
          <div
            className="row g-0 mt-2"
            ref={refSystem}
            style={{ visibility: "hidden" }}
          >
            <div className="col-2 icon">
              <img src={system} alt="" className="iconItem" />
            </div>
            <div className="col-10 text">Requisitos del sistema:</div>
          </div>
          <div
            className="row g-0 mt-2"
            ref={refCamera}
            style={{ visibility: "hidden" }}
          >
            <div className="col-2 icon "></div>
            <div className="col-10">
              Obteniendo lista de dispositivos de audio y video.
              <br />
              Permite el uso de los mismos.
            </div>
            <div className="col-2 icon ">
              <img src={camera} alt="" className="iconItem" />
            </div>
            <div className="col-10 text d-inline-flex align-items-center">
              Seleccionando una cámara:
              <span></span>
            </div>
          </div>
          <div
            className="row g-0 mt-2"
            ref={refMic}
            style={{ visibility: "hidden" }}
          >
            <div className="col-2 icon ">
              <img src={mic} alt="" className="iconItem" />
            </div>
            <div className="col-10 text d-inline-flex align-items-center">
              Seleccionando un micrófono:
              <span></span>
            </div>
          </div>
          <div
            className="row g-0 mt-2"
            ref={refWifi}
            style={{ visibility: "hidden" }}
          >
            <div className="col-2 icon ">
              <img src={wifi} alt="" className="iconItem" />
            </div>
            <div className="col-10 text">
              Obteniendo información del ancho de banda
            </div>
            <div className="text-center">
              <div
                className="spinner-border"
                role="status"
                style={{ width: "4vmin", height: "4vmin" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalInfo}
    </div>
  );
};

export default Clase;
