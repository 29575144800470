import {
    perlo,
} from "../../utils/utils";

// import { ModalProfe } from "./ModalProfe"
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import "../inicio/inicio.css";
import { useNavigate,} from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ButtonMob } from "../../utils/utils";
import { ModalProfe } from "../Modal/ModalProfe"


export function Allvideos(props) {

    const images = props.videos;
    function handleClick(event) {
        props.buttonClick(event);
    }

    useEffect(() => {
        var principal = document.querySelector(".swiper-wrapper").children;

        for (let i = 0; i < principal.length; i++) {
            // le asiganos un evento mouseover
            principal[i].addEventListener("mouseover", (e) => {
                if (e.target.id == i) {
                    principal[i].children[0].children[0].classList.add("growing");
                }
            });
            principal[i].addEventListener("mouseout", (e) => {
                if (e.target.id == i) {
                    principal[i].children[0].children[0].classList.remove("growing");
                }
            });
        }
    });

    const imagen = {
      "name": "Metodo eleva funcional",
      "category": "Entrenamiento",
      "thumbnail": "/img/cabezal.png",
    }
    const id = props.id;
    SwiperCore.use([Pagination, Autoplay, Navigation]);
    const render_img_desk =
        images &&
        images.map((imagen, index) => {
            return (
                <SwiperSlide
                    key={index}
                    value={imagen.id}
                    className={props.className}
                    style={{ width: "22vmin", minHeight: "34vmin" }}
                >
                    <div className="d-flex position-relative imagenreserva">
                        <div
                            onClick={() => handleClick(imagen.id)}
                            className="content position-relative "
                            style={{ minWidth: "25vmin" }}
                        >
                            {" "}
                            <img
                                id={index}
                                src={"/img/mente2.png"}
                                style={{ borderRadius: "20px", cursor: "pointer" }}
                                width={"80%"}
                                height="100%"
                            />
                            <div
                                className="position-absolute w-100"
                                style={{
                                    top: "0",
                                    color: "#394F65",
                                    padding: "8% 10%",
                                    maxWidth: "80%",
                                }}
                            >
                                {/* {imagen.subcategory} */}
                                entrenamiento

                                <br />
                                <div
                                    className="fw-bold"
                                    style={{
                                        fontSize: "1.7vmin",
                                        lineHeight: "100%",
                                    }}
                                >
                                    {imagen.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            );
        }
        );

    return (
        <div className="position-relative reconocer">
        
            <br /> 
            <div className="d-flex swiper-pagination_p  my-3">
                {id == "numbers" ? (
                    <>
                        <div
                            className={"d-flex position-absolute"}
                            style={{ top: "50%", left: "2.5%" }}
                        >
                            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
                        </div>
                        <div
                            className={"d-flex position-absolute"}
                            style={{ top: "50%", right: "2.5%" }}
                        >
                            <div className={`swiper-button-next-${id} tonin-next`}></div>
                        </div>
                    </>
                ) : (
                    <div className={"d-flex"}>
                        <div className={`swiper-button-prev-${id} tonin-prev`}></div>
                        <div className={`swiper-button-next-${id} tonin-next`}></div>
                    </div>
                )}
            </div>
            <Swiper
                style={{ margin: "auto ", width: "85vmin" }}
                className="slidersNetflixReservas py-3"
                navigation={{
                    nextEl: `.swiper-button-next-${id}`,
                    prevEl: `.swiper-button-prev-${id}`,
                }}
                modules={[Navigation]}
                slidesPerView="auto"
                spaceBetween={0}
                slidesOffsetBefore={0}
                slidesOffsetAfter={0}
                slidesPerGroup={1}
                slidesPerGroupSkip={1}
            >
                {render_img_desk}
                <div className="d-flex position-relative imagenreserva">
                  <div className="content position-relative">
                    <img src="/img/mente2.png"></img>
                    <div className="position-absolute w-100">Entrenamiento</div>
                  <br />
                  {/* <div className="fw-bold">Elevva Balance</div> */}
                  </div>
                </div>
            </Swiper>
        </div>
    );
}
export const Allcategory = (props) => {
  const [modalInfo, setModalInfo] = useState(null);
  function handleClick(event) {
    props.handleClick(event);
  }

  return (
    
    <div
      className="col-9"
      style={{
        background: "white",
        height: "100%",
        padding: "2% 0",
        borderRadius: "20px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >            {modalInfo}

      <div
        className="d-flex"
        style={{
          height: "20%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {" "}
        <div style={{ padding: "0 5%", lineHeight: "130%" }}>
          <span
            style={{
              color: "var(--elevvaTextTitle)",
              fontSize: "2.5vmin",
            }}
          >
            {" "}
            Selecciona <br /> <strong> tu disciplina de interés</strong>
          </span>
          <div className="d-flex" style={{ margin: "1vmin 0" }}>
            <div className="me-4 my-auto">
              {" "}
              <input
                style={{ padding: "5% 4%", height: "5vmin" }}
                placeholder="Busca tu disciplina"
                className="redondo-butoon"
              ></input>
            </div>
            <div className="mx-1">
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2% 3%",
                  height: "5vmin",
                  width: "10vmin",
                }}
              >
                <img
                  style={{
                    paddingTop: "5%",
                    maxWidth: "4vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/filter_button.png"}
                ></img>
                <span style={{ fontSize: "1.3vmin" }}>Filtrar</span>
              </button>
            </div>
            <div className="mx-1">
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2%",
                  height: "5vmin",
                  width: "10vmin",
                }}
              >
                <img
                  style={{
                    paddingTop: "5%",
                    maxWidth: "3vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/order_button.png"}
                ></img>
                <span style={{ fontSize: "1.3vmin" }}>Ordenar</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex"
        style={{
          height: "80%",
          background: "var(--clasesPending)",
          justifyContentc: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ padding: "4% 3% 0% 4%", lineHeight: "220%" }}>
          {" "}
          <div
            style={{
              color: "#394F65",
              fontSize: "4vmin",
            }}
          >
            <span
              style={{
                fontWeight: "300",
                color: "#394F65",
                fontSize: "4vmin",
              }}
            >
              Reserva tu primer{" "}
            </span>
            <br />
            <strong> clase grupal en vivo gratis</strong>
          </div>
        </div>
        <br />
        <Allvideos
          id="numbers"
          buttonClick={(e) => handleClick(e)}
          textTitleOne={"Reserva tu primer "}
          textTitleTwo={"clase grupal en vivo gratis"}
          videos={
            props.AllVideos &&
            props.AllVideos.filter((e) => e.category == "Entrenamiento")
          }
          className="videosreserva"
        />
      </div>
    </div>
  );
}


export const IndividualMedia = (props) => {
    const elevvaT = props.elevvaT;
    const pathHere = props.path;
    const navigate = useNavigate();
    function handleClick(event) {
      props.buttonClick(event);
    }
    const theme1 = {
        axis: {
            fontSize: "3px",

            domain: {
                line: {
                    stroke: "#ffff",
                    strokeWidth: 0.5,
                },
            },
            ticks: {
                line: {
                    stroke: "#ffff",
                    strokeWidth: 0.5,
                },
                text: {
                    fill: "var(--elevvaArrowColor)",
                },
            },
            legend: {
                text: {
                    fontSize: "3vw",

                    fontWeight: "700",
                },
            },
        },
        grid: {
            line: {
                stroke: "var(--backgroundGraficGrid)",

                strokeWidth: 1,
            },
        },
    };
    const Barra = [
        [
            "Progreso",
            <i className="bi bi-house"></i>,
            useRef(null),
            useRef(null),
            "/Usuario/Inicio",
        ],
        [
            "Mi perfil",
            <i class="bi bi-person-circle"></i>,
            useRef(null),
            useRef(null),
            "/Usuario/Perfil",
        ],
        [
            "Videos de bienestar y entrenamiento",
            <i className="bi bi-play-btn-fill"></i>,
            useRef(null),
            useRef(null),
            "#",
        ],
        [
            "Reserva de clases",
            <i className="bi bi-calendar-date"></i>,
            useRef(null),
            useRef(null),
            "/Usuario/groupals",
        ],
        [
            "Adquiere equipos",
            <i className="bi bi-box2"></i>,
            useRef(null),
            useRef(null),
            "#",
        ],
        [
            "Preguntas frecuentes",
            <i className="bi bi-chat-right-quote"></i>,
            useRef(null),
            useRef(null),
            "#",
        ],
        [
            "Términos y condiciones políticas de privacidad",
            <i class="bi bi-pencil-square"></i>,
            useRef(null),
            useRef(null),
            "#",
        ],
        [
            "Cerrar sesión",
            <i className="bi bi-box-arrow-left"></i>,
            useRef(null),
            useRef(null),
            "out",
        ],
    ];
    const [counter, setcounter] = useState(0);
    const [modalInfo, setModalInfo] = useState(null);
    useEffect(() => {
        const rootr = document.documentElement;
        var number = document.getElementById("number");
        var count = 0;
        rootr.style.setProperty(
            "--percentageCircularBar",
            300 - (300 * counter) / 100 + "%"
        );
        setInterval(() => {
            if (count === counter) {
                clearInterval();
            } else {
                count += 1;
                if (number) number.innerHTML = count + "%";
            }
        }, 30);
    }, [counter]);
    useEffect(() => {
        setcounter(50);
    }, []);


    const selectOption = (
        refContainer,
        index,
        classess,
        arr,
        onlyone,
        pathHere
    ) => {
        if (onlyone === true) {
            for (const iterator of arr) {
                
                iterator[2].current.classList = classess;
                iterator[2].current.active = false;
                iterator[3] = false;
            }
        }

        if (refContainer.current.active === true) {
            refContainer.current.classList = classess;
            refContainer.current.active = false;
        } else {
            refContainer.current.classList = classess + " active";
            refContainer.current.active = true;
        }
        if (pathHere === "out") {
            perlo();
        } else {
            return navigate(pathHere);
        }
    };

    const categorias = [
        [
            "Clases",
            "grupales",
            process.env.PUBLIC_URL + "/assets/img/category_grupal.jpg",
        ],
        [
            "Intensidad",
            "baja",
            process.env.PUBLIC_URL + "/assets/img/category_baja.jpg",
        ],
        [
            "Intensidad",
            "media",
            process.env.PUBLIC_URL + "/assets/img/category_media.jpg",
        ],
        [
            "Intensidad",
            "alta",
            process.env.PUBLIC_URL + "/assets/img/category_alta.jpg",
        ],
    ];

    const entrenamientoHoras = [
        {
            dia: "Enero 13",
            credito: "2 créditos",
            hora: "9:00 / 10:00 am"
        },
        {
            dia: "Enero 19",
            credito: "2 créditos",
            hora: "9:00 / 10:00 am"
        },
        {
            dia: "Enero 13",
            credito: "2 créditos",
            hora: "9:00 / 10:00 am"
        }
    ]

    return (
        <div
            className="d-flex col-12 "
            style={{
                height: "100%",
            }}
        >
            <div
                className="col-2"
                style={{
                    flexDirection: "column",
                }}
            >
                <div
                    className=" w-100 mx-auto py-4  text-center"
                    style={{ height: "20%" }}
                >
                    <img
                        alt="logo_alta_black"
                        style={{ padding: "5% 20%", width: "80%" }}
                        src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
                    />
                </div>
                <div
                    className=" w-100"
                    style={{
                        height: "80%",
                    }}
                >
                    {Barra.map((elementos, index) => (
                        <div
                            key={index}
                            ref={elementos[2]}
                            style={{ fontWeight: "600" }}
                            className="d-flex option-page text-color-title  py-3 "
                            onClick={() =>
                                selectOption(
                                    elementos[2],
                                    index,
                                    "d-flex option-page fw-bold  py-3 ",
                                    Barra,
                                    true,
                                    elementos[4]
                                )
                            }
                        >
                            <div className="my-auto mx-2 Iconresponsive">{elementos[1]} </div>
                            <div className="mx-2 letraPequeña">{elementos[0]} </div>
                        </div>
                    ))}
                </div>
            </div>
            {modalInfo}
            <div
                className="col-10  position-relative"
                style={{
                    background: "rgba(0,0,0,.7)",
                    height: "100vh",

                    padding: "2% 3% 2% 2%",
                }}
            >
                <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
                    <div
                        className="col-3"
                        style={{
                            background: "white",
                            height: "100%",
                            padding: "2%",
                            borderRadius: "20px",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <div className="d-flex my-auto">
                            <div
                                onClick={() => navigate(`/Usuario/Inicio`)}
                                className=" fw-bold booking-all"
                                style={{
                                    background: "transparent",
                                    border: "1px solid #A8C2D7",
                                    padding: "2%",
                                    fontSize: "1vmin",
                                    height: "fit-content",
                                    cursor: "pointer",
                                    color: "#A8C2D7",
                                    borderRadius: "10px",
                                }}
                            >
                                <span style={{ fontSize: "0.5vmin" }}>
                                    <i className="bi bi-chevron-left"></i>
                                </span>
                            </div>
                            <span
                                className="fw-bold my-auto ms-2"
                                style={{
                                    color: "var(--elevvaTextTitle)",
                                    fontSize: "2.0vmin",
                                }}
                            >
                                {" "}
                                Mis reservas Elevva
                            </span>
                        </div>
                        <hr />
                        <div style={{ lineHeight: "110%", margin: "2vmin auto" }}>
                            <span
                                style={{
                                    color: "rgba(57,79,101,0.5)",
                                    fontSize: "2.0vmin",
                                }}
                            >
                                Selecciona
                            </span>
                            <br />
                            <span
                                className="fw-bold"
                                style={{
                                    color: "var(--elevvaTextTitle)",
                                    fontSize: "2.0vmin",
                                }}
                            >
                                {" "}
                                Categoria de interés
                            </span>
                        </div>
                        {categorias.map((e) => (
                            <div
                                className="position-relative mx-auto"
                                style={{
                                    backgroundImage: `url(${e[2]})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "20px",
                                    width: "100%",
                                    height: "18%",
                                    marginTop: "1.5vmin",
                                    marginBottom: "1.5vmin",
                                }}
                            >
                                <div
                                    className="position-absolute "
                                    style={{
                                        color: "var(--elevvaTextTitle)",
                                        display: "flex",
                                        height: "100%",
                                        left: "5%",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        lineHeight: "90%",
                                    }}
                                >
                                    <span className="letraPequeñaother mb-1">{e[0]}</span>
                                    <div style={{ maxWidth: "10vmin", lineHeight: "110%" }}>
                                        <span className="letraPequeñaCardTitleBook fw-bold">
                                            {e[1]}
                                        </span>
                                    </div>
                                </div>

                                <div
                                    className="position-absolute fw-bold booking-all"
                                    style={{
                                        background: "var(--backgroundOnboardingObjective)",
                                        bottom: "9%",
                                        right: "5%",
                                        padding: "2%",
                                        fontSize: "1vmin",
                                        color: "var(--white)",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <span style={{ fontSize: "0.5vmin" }}>
                                        <i className="bi bi-chevron-right"></i>
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="col-9"
                        style={{
                            background: "white",
                            height: "100%",
                            padding: "2% 0",
                            borderRadius: "20px",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            className="d-flex"
                            style={{
                                height: "20%",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <div style={{ padding: "0 5%", lineHeight: "130%" }}>
                                <span
                                    style={{
                                        color: "var(--elevvaTextTitle)",
                                        fontSize: "2.5vmin",
                                    }}
                                >
                                    {" "}
                                    Selecciona <br /> <strong> tu disciplina de interés</strong>
                                </span>
                                <div className="d-flex" style={{ margin: "1vmin 0" }}>
                                    <div className="me-4 my-auto">
                                        {" "}
                                        <input
                                            style={{ padding: "5% 4%", height: "5vmin" }}
                                            placeholder="Busca tu disciplina"
                                            className="redondo-butoon"
                                        ></input>
                                    </div>
                                    <div className="mx-1">
                                        <button
                                            className="redondo-butoon d-flex"
                                            style={{
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                padding: "2% 3%",
                                                height: "5vmin",
                                                width: "10vmin",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    paddingTop: "5%",
                                                    maxWidth: "4vmin",
                                                }}
                                                src={process.env.PUBLIC_URL + "/assets/img/filter_button.png"}
                                            ></img>
                                            <span style={{ fontSize: "1.3vmin" }}onClick={() =>
                          setModalInfo(
                            <ModalProfe
                              handleCancellcargue={() => setModalInfo(null)}
                              error="tru"
                              text={
                                "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                              }
                              classesMsg=""
                            />
                          )
                        }>Filtrar</span>
                                        </button>
                                    </div>
                                    <div className="mx-1">
                                        <button
                                            className="redondo-butoon d-flex"
                                            style={{
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                padding: "2%",
                                                height: "5vmin",
                                                width: "10vmin",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    paddingTop: "5%",
                                                    maxWidth: "3vmin",
                                                }}
                                                src={process.env.PUBLIC_URL + "/assets/img/order_button.png"}
                                            ></img>
                                            <span style={{ fontSize: "1.3vmin" }}>Ordenar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="d-flex"
                            style={{
                                height: "80%",
                                background: "var(--clasesPending)",
                                justifyContentc: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div style={{ padding: "4% 3% 0% 4%", lineHeight: "220%" }}>
                                {" "}
                                <div
                                    style={{
                                        color: "#394F65",
                                        fontSize: "4vmin",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: "300",
                                            color: "#394F65",
                                            fontSize: "4vmin",
                                        }}
                                    >
                                        Reserva tu primer{" "}
                                    </span>
                                    <br />
                                    <strong> clase grupal en vivo gratis</strong>
                                </div>
                            </div>
                            <br />
                            <Allvideos
                                id="numbers"
                                buttonClick={(e) => handleClick(e)}
                                textTitleOne={"Reserva tu primer "}
                                textTitleTwo={"clase grupal en vivo gratis"}
                                videos={
                                    props.AllVideos &&
                                    props.AllVideos.filter((e) => e.category == "Entrenamiento")
                                }
                                className="videosreserva"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export const ReservaCustomer = (props) => {
  const navigate = useNavigate();
  var selectReserva = props.AllVideos.filter((e) => e.id == props.id);

  const [options, setoptions] = useState("inicio");
  function handleClick() {
    props.handleClick(null, true);
  }
  function optionsClick() {
    setoptions("seleccionar");
  }
  const reservas = [
    {
      prof: "Hugo Ortiz",
      cupos: "10",
      dia: "Agosto 17",
      hora: "9:00/10:00 am",
    },
    {
      prof: "Hugo Ortiz",
      cupos: "10",
      dia: "Agosto 17",
      hora: "9:00/10:00 am",
    },
    {
      prof: "Hugo Ortiz",
      cupos: "10",
      dia: "Agosto 17",
      hora: "9:00/10:00 am",
    },
  ];
  return (
    <div
      className="col-9 d-flex"
      style={{
        background: "white",
        height: "100%",
        padding: "2%",
        justifyContent: "center",
        borderRadius: "20px",
      }}
    >
      <div
        className="col-6 d-flex"
        style={{ position: "relative", justifyContent: "center" }}
      >
        <img
          src={
            options == "final"
              ? process.env.PUBLIC_URL + "/img/mascarareservafinal.png"
              : options == "reservafinal"
              ? process.env.PUBLIC_URL + "/img/finalreserva.png"
              : process.env.PUBLIC_URL + "/img/marcaraEjemplo.png"
          }
        />
        {options == "final" || options == "reservafinal" ? null : (
          <div style={{ top: "5%", position: "absolute", left: "5%" }}>
            <div className="d-flex my-auto">
              <div
                onClick={handleClick}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div className=" my-auto mx-2">
                <div
                  className="my-1"
                  style={{ color: "#394F65", fontSize: "2.0vmin" }}
                >
                  Entrenamiento
                </div>
                <div
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "3vmin",
                  }}
                >
                  {selectReserva[0].name}
                </div>{" "}
                <br />{" "}
                <button
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "300",
                    padding: "1% 12%",
                  }}
                >
                  GRATIS
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {options == "seleccionar" ? (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            padding: "2%",
          }}
        >
          <span
            style={{
              color: "#394F65",

              fontSize: "3vmin",
            }}
          >
            {" "}
            Selecciona tu
          </span>

          <span
            style={{
              color: "#394F65",
              fontWeight: "600",
              fontSize: "3vmin",
            }}
          >
            día y hora de clase
          </span>
          <br />
          {reservas.map((e, i) => (
            <div
              key={i}
              className="d-flex position-relative changecolortable my-1"
              style={{
                justifyContent: "space-around",
                borderRadius: "40px",
                textAlign: "center",
                padding: "2%",
              }}
            >
              <div
                className="d-flex "
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <span>Prof.{e.prof}</span>
                <span>{e.cupos}CUPOS</span>
              </div>
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <span>{e.dia}</span>
                <span>{e.hora}</span>
              </div>
              <div className="my-auto d-flex  position-relative">
                <input
                  key={`${i}`}
                  name="radioteachers"
                  type="radio"
                  value={i}
                ></input>{" "}
              </div>
            </div>
          ))}
          <div
            className="my-2"
            style={{
              color: "#394F65",
              fontWeight: "600",
              fontSize: "1.5vmin",
            }}
          >
            Costo total de tu reserva - 0 créditos
          </div>
          <ButtonMob handleClick={() => setoptions("final")} text="Reserva" />
        </div>
      ) : options == "final" ? (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: "2%",
          }}
        >
          <div className="d-flex my-auto">
            <div
              onClick={() => setoptions("seleccionar")}
              className=" fw-bold booking-all"
              style={{
                background: "transparent",
                border: "1px solid #A8C2D7",
                padding: "2%",
                fontSize: "1vmin",
                height: "fit-content",
                cursor: "pointer",
                color: "#A8C2D7",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontSize: "0.5vmin" }}>
                <i className="bi bi-chevron-left"></i>
              </span>
            </div>
            <div className=" my-auto mx-2">
              <div
                className="my-0"
                style={{ color: "#394F65", fontSize: "2.5vmin" }}
              >
                Resumen
              </div>
              <div
                style={{
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "3vmin",
                  whiteSpace: "nowrap",
                }}
              >
                de tu reserva
              </div>{" "}
              <div
                className="d-flex"
                style={{
                  background: "#F4F0ED",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "25px",
                  padding: "5%",
                  width: "38vmin",
                  margin: "8vmin 0",
                }}
              >
                <span style={{ textAlign: "center", fontSize: "3vmin" }}>
                  <i className="bi bi-clock-history"></i>
                </span>

                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Prof. Hugo Ortiz
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#D74688",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Zumba grupal
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Agosto 17 de 2020 / 9:00 a.m / 10:00 a.m
                </span>
              </div>
              <hr />
              <div className="d-flex py-2" style={{ justifyContent: "center" }}>
                <button
                  // handleClick={() => navigate("/Usuario/Inicio")}
                  onClick={() => setoptions("reservafinal")}
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "400",
                    padding: "2% 12%",
                  }}
                >
                  Reservar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : options == "reservafinal" ? (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: "2%",
          }}
        >
          <div className=" m-auto">
            <div
              style={{
                color: "#394F65",
                fontSize: "3.0vmin",
                lineHeight: "100%",
              }}
            >
              Entrenamientos
            </div>
            <div
              style={{
                color: "#394F65",
                fontWeight: "bold",
                fontSize: "3.5vmin",
                whiteSpace: "nowrap",
              }}
            >
              reservados
            </div>{" "}
            <hr />
            <div
              className="fw-600 mx-auto"
              style={{
                background: "#B07EBC",
                color: "white",
                width: "50vmin",
                fontSize: "2vmin",
                padding: "3% 0",
              }}
            >
              ¡Disfruta <strong>tu clase!</strong>
            </div>
            <div
              className="d-flex"
              style={{
                background: "#F4F0ED",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                borderRadius: "25px",
                padding: "5%",
                width: "38vmin",
                margin: "8vmin auto",
              }}
            >
              <span style={{ textAlign: "center", fontSize: "3vmin" }}>
                <i className="bi bi-clock-history"></i>
              </span>

              <span
                style={{
                  textAlign: "center",
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "1.5vmin",
                  whiteSpace: "nowrap",
                }}
              >
                Prof. Hugo Ortiz
              </span>
              <span
                style={{
                  textAlign: "center",
                  color: "#D74688",
                  fontWeight: "bold",
                  fontSize: "1.5vmin",
                  whiteSpace: "nowrap",
                }}
              >
                Zumba grupal
              </span>
              <span
                style={{
                  textAlign: "center",
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "1.5vmin",
                  whiteSpace: "nowrap",
                }}
              >
                Agosto 17 de 2020 / 9:00 a.m / 10:00 a.m
              </span>
            </div>
            <div
              className="d-flex mx-auto"
              style={{
                border: "1px solid #9DACBD",
                padding: "2%",
                borderRadius: "20px",
                width: "45vmin",
              }}
            >
              <div
                className="my-auto"
                style={{ padding: "4%", borderRight: "1px solid #9DACBD" }}
              >
                <img
                  style={{ width: "10vmin" }}
                  src={process.env.PUBLIC_URL + "/img/compus.png"}
                />
              </div>
              <div style={{ padding: "4%" }}>
                <span
                  style={{
                    color: "#9DACBD",
                    textAlign: "justify",
                    fontSize: "1vmin",
                  }}
                >
                  Recuerda que puedes tomar tu clases a través de un computador,
                  celular, ipad o tablet. Para una experiencia optima,
                  recomendamos usar el navegador Google Chrome.
                </span>
              </div>
            </div>
            <br />
            <div className="d-flex py-2" style={{ justifyContent: "center" }}>
              <button
                onClick={() => navigate("/Usuario/Inicio")}
                style={{
                  background: "var(--elevva)",
                  border: "1px solid var(--elevva)",
                  borderRadius: "20px",
                  color: "var(--white)",
                  fontWeight: "400",
                  padding: "2% 12%",
                }}
              >
                Ir a <i class="bi bi-house"></i>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            padding: "2%",
          }}
        >
          <div>
            <button
              className="me-1"
              style={{
                background: "#F4F0ED",
                border: "1px solid #F4F0ED",
                borderRadius: "20px",
                color: "#394F65",
                fontWeight: "300",
                padding: "1% 8%",
              }}
            >
              45min
            </button>
            <button
              className="mx-1"
              style={{
                background: "#F4F0ED",
                border: "1px solid #F4F0ED",
                borderRadius: "20px",
                color: "#394F65",
                fontWeight: "300",
                padding: "1% 8%",
              }}
            >
              500 cal
            </button>
          </div>
          <br />
          <div>
            <span
              style={{
                color: "#394F65",
                fontWeight: "400",
                fontSize: "2vmin",
              }}
            >
              Metodologia
            </span>
            <br />
            <span
              style={{
                color: "#A9B6C4",
                fontWeight: "bold",
                fontSize: "1.5vmin",
              }}
            >
              Disciplina grupal
            </span>
          </div>
          <br />
          <div>
            <span
              style={{
                color: "#394F65",
                fontWeight: "400",
                fontSize: "2vmin",
              }}
            >
              Información
            </span>
            <br />
            <span
              style={{
                color: "#A9B6C4",
                fontWeight: "bold",
                fontSize: "1.5vmin",
              }}
            >
              {selectReserva[0].tema}
            </span>
          </div>
          <br />
          <div>
            <span
              style={{
                color: "#394F65",
                fontWeight: "400",
                fontSize: "2vmin",
              }}
            >
              Cupo disponible:20 personas
            </span>
          </div>
          <div className="my-3">
            <ButtonMob
              handleClick={optionsClick}
              className="marginButtonReserva"
              text="Nueva reserva"
            />
          </div>
          
        </div>
      )}
    </div>
  );
};



