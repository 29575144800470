import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./perfil.css";
import Moment from "moment";
import { laptop1600 } from "../../utils/utils";
import { ReactComponent as ValoraNutricional } from "../../assets/newIcons/valora_nutricional.svg";
import { ReactComponent as GrupalIcon } from "../../assets/newIcons/grupalIcon.svg";
import { ReactComponent as ReposoIcon } from "../../assets/newIcons/ICO-PLAN-REPOSO.svg";
import { ReactComponent as ValoraIco } from "../../assets/newIcons/ICO-PLAN-VALORACION.svg";
import { ReactComponent as TrainIcon } from "../../assets/newIcons/ICO-PLAN-ENTRENAMIENTO.svg";
import { ReactComponent as CardioIcon } from "../../assets/newIcons/ICO-PLAN-CARDIO.svg";
import { ReactComponent as VideoIcon } from "../../assets/newIcons/ICO-PLAN-VIDEO.svg";
import { ReactComponent as FuerzaIcon } from "../../assets/newIcons/Entrenamiento_fuerza.svg";
import { ReactComponent as FisioterapiaIcon } from "../../assets/newIcons/fisioterapia_recuperacion.svg";
import { ReactComponent as LibreIcon } from "../../assets/newIcons/entrenamiento_libre.svg";
import { ReactComponent as EstiramientoIcon } from "../../assets/newIcons/sesion_estiramiento.svg";
import iconImageCamera from "../../assets/assets/camera_change.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { whatMonthONturn, whatWeek } from "../TutorialReserva/stepsHelp";

export function MiniPerfil(props) {
  const widthContainer = props.widthContainer;
  const idJoyride = props.idJoyride;
  const handlePassword = props.handlePassword;
  const handlePlan = props.handlePlan;
  const firstName = props.firstName;
  const lastName = props.lastName;
  const profilePic = props.profilePic;
  const padreclass = props.padreclass;
  const email = props.email;
  const mobile_number = props.mobile_number;
  const mobileON = props.mobileON || false;
  const showPopCamera = props.showPopCamera;

  return (
    <div
      className=" mx-auto position-relative d-flex padding_perfil_Container"
      id={idJoyride}
      style={{
        background: "white",
        width: widthContainer,
        gap: "2%",
        borderRadius: "20px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <PerfilName
        mobileON={mobileON}
        padreclass={padreclass}
        lastName={lastName}
        firstName={firstName}
        profilePic={profilePic}
        email={email}
        mobile_number={mobile_number}
        showPopCamera={showPopCamera}
      />
      <div className="d-flex  mx-auto my-2  my-md-0 col-8 col-md-8  flex-column justify-content-center">
        <div className="py-2 d-flex" style={{ justifyContent: "center" }}>
          <button
            className="w-100 d-flex guardarTension justify-content-center"
            onClick={handlePassword}
          >
            Cambiar tu contraseña
          </button>
        </div>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <button
            style={{ background: "transparent", color: "var(--elevva)" }}
            className="w-100 d-flex guardarTension justify-content-center"
            onClick={handlePlan}
          >
            Ver tu plan
          </button>
        </div>
      </div>
    </div>
  );
}

export const PerfilName = (props) => {
  const firstName = props.firstName;
  const lastName = props.lastName;
  const profilePic = props.profilePic;
  const padreclass = props.padreclass;
  const email = props.email;
  const mobile_number = props.mobile_number;
  const mobileON = props.mobileON || false;
  const showPopCamera = props.showPopCamera;
  return (
    <div
      className={padreclass}
      style={
        mobileON
          ? { background: "#F9F9F9", padding: "10% 2%" }
          : { justifyContent: "center" }
      }
    >
      {" "}
      <span
        className="fw-bold  d-flex col-5 mx-auto"
        style={{
          alignItems: "center",
          justifyContent: "center",
          color: "var(--elevvaTextTitle)",
        }}
      >
        <div style={{ position: "relative" }}>
          <img
            src={profilePic || localStorage.profile_pic}
            alt="Avatar"
            className="rounded-circle imageCustomerPerfil"
          />
          <div
            style={{
              position: "absolute",
              top: "70%",
              left: "0%",
              cursor: "pointer",
            }}
          >
            <img
              width={50}
              height={50}
              src={iconImageCamera}
              onClick={showPopCamera}
            ></img>
          </div>
        </div>
      </span>
      <div className="  mx-auto  d-flex col-6 letraNamePerfil">
        {/* localStorage.variable, se trata como objeto */}

        {firstName && lastName ? (
          <>
            <span
              className="letraPequeña fw-bold"
              style={{ lineHeight: "100%" }}
            >
              {`${firstName.toUpperCase()}`}
              <br />
              {`${lastName.toUpperCase()}`}
            </span>

            <hr />
            <span className="fw-light letraPequeña">
              {email.length > 18 ? email.slice(0, 18) + "..." : email}
            </span>
            <hr />
            <span className="fw-light letraPequeña">{mobile_number}</span>
          </>
        ) : null}
      </div>
    </div>
  );
};

export const ObjetivosPanel = (props) => {
  const TrainingEstadistics = props.TrainingEstadistics;
  const idPanel = props.idPanel;
  const paddingProm = props.paddingProm || "0 2%";
  const widthCases = props.widthCases || "40%";
  const backgroundPromed = props.backgroundPromed || "var(--white)";

  return (
    <div
      id={idPanel}
      className=" mx-auto d-flex flex-column"
      style={{
        width: widthCases,
        background: backgroundPromed,
        borderRadius: "20px",
        padding: paddingProm,
        gap: "2%",
      }}
    >
      <span
        className="letraPequeña my-2"
        style={{
          color: "var(--elevvaTextTitle)",
        }}
      >
        Objetivos <strong>semanales</strong>
      </span>
      <div
        className=" mx-auto d-flex w-100"
        style={{
          gap: "2%",
        }}
      >
        {TrainingEstadistics.map((e, i) => (
          <div
            key={i}
            style={{
              background: e[2],
              width: "calc(100%/3)",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              borderRadius: "1.1rem",
              padding: "3%",
            }}
          >
            <button
              style={{
                background: "var(--white)",
                borderRadius: "0.9rem",
                border: "1px solid transparent",
                margin: "0 auto 0 0",
              }}
            >
              <img
                alt="e1"
                src={e[1]}
                style={{ width: "6vmin", height: "6vmin" }}
              />
            </button>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                marginTop: "10%",
                justifyContent: "center",
              }}
            >
              <span
                className="fw-bold letraPequeñaotherDashboard"
                style={{ color: e[5], lineHeight: "90%" }}
              >
                {ReactHtmlParser(e[0])}
              </span>

              <span
                className="fw-bold "
                style={{
                  color: "var(--elevvaTextTitle)",
                  fontSize: "2vmin",
                }}
              >
                {/* Se muestra la carga usuario */}
                {/* {e[4]} */}
                {e[4]}
              </span>

              <span
                className="letraPequeñaotherDashboard"
                style={{ color: "var(--elevvaTextTitle)" }}
              >
                {e[3]}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const mestraining = [
  {
    name: "Valoración física / Control",
    icon: <ValoraIco width={"100%"} height={"100%"} />,
    color: "#70AAD8",
  },
  {
    name: "Valoración Nutricional / Control",
    icon: <ValoraNutricional width={"100%"} height={"100%"} />,
    color: "#99FF00",
  },
  {
    name: "Programa Cardio",
    icon: <CardioIcon width={"100%"} height={"100%"} />,
    color: "#A43E80",
  },
  {
    name: "Entrenamiento 1 a 1",
    icon: <TrainIcon width={"100%"} height={"100%"} />,
    color: "#8089CC",
  },

  {
    name: "Clase grupal",
    icon: <GrupalIcon width={"100%"} height={"100%"} />,
    color: "#69D2D1",
  },
  {
    name: "Video pregrabado",
    icon: <VideoIcon width={"100%"} height={"100%"} />,
    color: "#4B6380",
  },
  {
    name: "Día de descanso",
    icon: <ReposoIcon width={"100%"} height={"100%"} />,
    color: "#CBA791",
  },
  {
    name: "Sesión de Estiramiento",
    icon: <EstiramientoIcon width={"100%"} height={"100%"} />,
    color: "#C22C2C",
  },
  {
    name: "Entrenamiento de Fuerza",
    icon: <FuerzaIcon width={"100%"} height={"100%"} />,
    color: "#F3539B",
  },
  {
    name: "Fisioterapia y recuperación",
    icon: <FisioterapiaIcon width={"100%"} height={"100%"} />,
    color: "#FFDC97",
  },
  {
    name: "Entrenamiento libre",
    icon: <LibreIcon width={"100%"} height={"100%"} />,
    color: "#FF6100",
  },
];

export const ResumenCompras = (props) => {
  const idPanel = props.idPanel;
  const paddingProm = props.paddingProm || "2%";
  const widthCases = props.widthCases || "30%";
  const backgroundPromed = props.backgroundPromed || "var(--white)";
  const dataTransaccions = props.dataTransaccions;
  const popUptransacciones = props.popUptransacciones;
  const stepIndex = props.stepIndex;

  function searchNumbers(str) {
    return str.match(/\d+/g);
  }

  return (
    <div
      id={idPanel}
      className=" mx-auto d-flex flex-column"
      style={{
        width: widthCases,
        background: backgroundPromed,
        borderRadius: "20px",
        padding: paddingProm,
        gap: "2%",
      }}
    >
      <span
        className="letraPequeña my-auto"
        style={{
          color: "var(--elevvaTextTitle)",
        }}
      >
        Resumen <strong>de compras</strong>
      </span>
      {dataTransaccions && dataTransaccions[0] ? (
        <div className="containerCompras ">
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Clase</span>
            <span className="letraPequeña fw-light">
              {searchNumbers(dataTransaccions[0]?.description)}
            </span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Fecha</span>
            <span className="letraPequeña fw-light">
              {Moment(dataTransaccions[0]?.date).format("YYYY-MM-DD")}
            </span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Hora</span>
            <span className="letraPequeña fw-light">
              {Moment(dataTransaccions[0]?.date).format("LT a")}
            </span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Valor</span>
            <span className="letraPequeña fw-light">
              {" "}
              {Number(dataTransaccions[0]?.amount) == 1
                ? Number(dataTransaccions[0]?.amount) + " crédito"
                : Number(dataTransaccions[0]?.amount) < 500
                ? Number(dataTransaccions[0]?.amount) + " créditos"
                : dataTransaccions[0]?.amount +
                  " " +
                  dataTransaccions[0]?.currency}{" "}
            </span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Estado</span>
            <span className="letraPequeña fw-light">
              {" "}
              {dataTransaccions[0]?.status == 4
                ? "Aprobada"
                : dataTransaccions[0]?.status == 6
                ? "Declinada"
                : dataTransaccions[0]?.status == 7
                ? "Fondos insuficientes"
                : "Sin procesar"}
            </span>
          </div>
        </div>
      ) : (
        <div
          className="text-center"
          style={{ fontSize: " calc(0.4rem + 0.4vw" }}
        >
          No tienes transacciones
        </div>
      )}

      {stepIndex == 4 ? (
        <div className="containerCompras ">
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Clase</span>
            <span className="letraPequeña fw-light">1087</span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Fecha</span>
            <span className="letraPequeña fw-light">2023-06-01</span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Hora</span>
            <span className="letraPequeña fw-light">12:45 pm</span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Valor</span>
            <span className="letraPequeña fw-light">1 crédito </span>
          </div>
          <div className="itemCompras d-flex flex-column">
            <span className="letraPequeña fw-bold">Estado</span>
            <span className="letraPequeña fw-light"> Aprobada</span>
          </div>
        </div>
      ) : null}

      <button
        className="m-auto d-flex relative"
        style={{
          color: "var(--white)",

          padding: "1% 5%",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          verticalAlign: "middle",
          background: "var(--elevva)",
          borderRadius: "20px",
          border: "1px solid var(--elevva)",
          fontSize: "1.4vmin",
        }}
        onClick={popUptransacciones}
      >
        Ver más <i className="bi bi-chevron-right"></i>
      </button>
    </div>
  );
};

export const ObjetivosCustomer = (props) => {
  const stepIndex = props.stepIndex || 0;

  const objetivesCustomer = props.objetivesCustomer;
  const arrayPrograma = props.arrayPrograma;
  const programaTraining = props.programaTraining;
  const joyRide = props.joyRide;
  const objetivesOldCustomer = props.objetivesOldCustomer;
  const userData = props.userData;
  const mesPrograma = props.mesPrograma;
  const currentDay = whatMonthONturn(userData?.membership?.current_plan_day);
  const curretnWeek = whatWeek(userData?.membership?.current_plan_day);
  console.log("actualPlanMembership", userData);
  const actualPlanMembership = mesPrograma;
  return (
    <div
      style={{
        borderRadius: "20px",
        display: "grid",
        gridTemplateColumns: "auto",
        width: "70%",
        height: "100%",
        gap: "1%",
      }}
    >
      <div
        className="activityandprogress direcccionately"
        style={{
          background: "white",
          height: "100%",
          borderRadius: "20px",

          padding: "2% 5%",
        }}
      >
        <div className="d-flex flex-column h-100 justify-content-evenly">
          {" "}
          <span
            className="letraPequeña"
            style={{
              color: "var(--elevvaTextTitle)",
            }}
          >
            Objetivos <strong>del paciente</strong>
          </span>
          {(userData?.customer?.objectives.length != 0 &&
            userData?.customer?.wellness_objective != 0) ||
          userData?.customer?.wellness_objective != null ? (
            <div className="content-select-gender d-flex justify-content-around">
              {objetivesCustomer.map((elemento) =>
                userData?.customer?.objectives.includes(elemento.code) ? (
                  <div className="d-flex " style={{ alignItems: "center" }}>
                    <div>{elemento.icon}</div>
                    <div>
                      <span className="letraPequeña text-objective-jus">
                        {elemento.name}
                      </span>
                    </div>
                  </div>
                ) : null
              )}
              {objetivesOldCustomer.map((elemento) =>
                userData?.customer?.wellness_objective == elemento.code ? (
                  <div className="d-flex " style={{ alignItems: "center" }}>
                    <div>{elemento.icon}</div>
                    <div>
                      <span className="letraPequeña text-objective-jus">
                        {elemento.name}
                      </span>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          ) : (
            <div className="d-flex " style={{ alignItems: "center" }}>
              <div
                style={{
                  borderRadius: "50%",
                  background: "var(--backgroundOnboardingObjective)",
                  width: "50px",
                  height: "50px",
                }}
              ></div>
              <div>
                <span
                  className="letraPequeña text-objective-jus"
                  style={{ width: "50px" }}
                >
                  ---
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        id={joyRide}
        className="d-flex w-100"
        style={{
          gap: "2%",
          margin: "1% 0px 0px",
        }}
      >
        <div
          className=" w-100 text-center"
          style={{
            background: "white",
            display: "flex",
            gap: "1vmin",
            flexDirection: "column",
            justifyContent: "center",
            borderRadius: "20px",
            alignItems: "center",
            position: "relative",
            padding: "2%",
          }}
        >
          <div className="d-flex col-12">
            <div
              style={{ width: "20%" }}
              className="d-flex flex-column justify-content-center"
            >
              <div
                className="letraPequeña"
                style={{
                  color: "var(--elevvaTextTitle)",
                }}
              >
                Programa de
              </div>

              <div
                className="letraPequeña"
                style={{
                  color: "var(--elevvaTextTitle)",
                }}
              >
                <strong>entrenamiento</strong>
              </div>
              <br />
              <button
                className="mx-auto d-flex relative"
                style={{
                  color: "var(--white)",

                  padding: "5%",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  verticalAlign: "middle",
                  background: "var(--elevva)",
                  borderRadius: "20px",
                  border: "1px solid var(--elevva)",
                  fontSize: "1.4vmin",
                }}
                onClick={programaTraining}
              >
                Ver plan de <br /> entrenamiento{" "}
                <i className="bi bi-chevron-right"></i>
              </button>
            </div>
            <div className=" d-flex h-100" style={{ gap: "1%", width: "80%" }}>
              {arrayPrograma.length > 0 &&
                arrayPrograma.map((container, indice) => (
                  <div
                    className="d-flex flex-column"
                    style={{
                      background: "var(--programa-training)",
                      borderRadius: "20px",
                      width: "25%",
                      minHeight: laptop1600.matches
                        ? "calc(20rem + 1vmin)"
                        : "calc(12rem + 1vmin)",
                      padding: "2% 0",
                    }}
                  >
                    <span className="letraPequeña fw-light">
                      {"Semana " + (container.semana - -curretnWeek)}
                    </span>
                    <div class="dotted-line-container my-2">
                      <div class="dotted-line"></div>
                      <div class="dot"></div>
                    </div>
                    {stepIndex == 3 ? (
                      <>
                        {container.contenido.map((item, index) => (
                          <div className="d-flex  col-10 mx-auto my-1">
                            <div
                              className="col-3 d-flex flex-column m-auto justify-content-center"
                              style={{
                                background:
                                  index == 0
                                    ? "#70AAD8"
                                    : index == 1
                                    ? "#4B6380"
                                    : "var(--elevva)",
                                borderRadius: "100%",
                                width: "30px",
                                height: "30px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="d-flex flex-column m-auto justify-content-center"
                                style={{ width: "12px", height: "12px" }}
                              >
                                {item.icon}
                              </div>
                            </div>
                            <div className="col-8 m-auto">
                              <div
                                className="letraPequeña"
                                style={{ textAlign: "left" }}
                              >
                                {item.name}
                              </div>{" "}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <PerfectScrollbar
                        style={{ height: "calc(26vh + 6vmin)" }}
                      >
                        {actualPlanMembership && actualPlanMembership.length > 0
                          ? actualPlanMembership
                              .slice(
                                indice * 7 + currentDay,
                                indice * 7 + currentDay + 7
                              )
                              .map((item) => (
                                <div className="d-flex  col-10 mx-auto my-1">
                                  <div
                                    className="col-3 d-flex flex-column m-auto justify-content-center"
                                    style={{
                                      background: iconReviewColor(item.type),
                                      borderRadius: "100%",
                                      width: "30px",
                                      height: "30px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="d-flex flex-column m-auto justify-content-center"
                                      style={{ width: "12px", height: "12px" }}
                                    >
                                      {iconReview(item.type)}
                                    </div>
                                  </div>
                                  <div className="col-8 m-auto">
                                    <div
                                      className="letraPequeña"
                                      style={{ textAlign: "left" }}
                                    >
                                      {item.label}
                                    </div>{" "}
                                  </div>
                                </div>
                              ))
                          : null}
                      </PerfectScrollbar>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export function iconReviewColor(e) {
  if (e == "valoracion_fisica") {
    return "#70AAD8";
  } else if (e == "valoracion_nutricional") {
    return "#99FF00";
  } else if (e == "cardio") {
    return "#A43E80";
  } else if (e == "reserva") {
    return "#8089CC";
  } else if (e == "grupal") {
    return "#69D2D1";
  } else if (e == "video") {
    return "#4B6380";
  } else if (e == "sesion_estiramiento") {
    return "#C22C2C";
  } else if (e == "entrenamiento_fuerza") {
    return "#F3539B";
  } else if (e == "fisioterapia_recuperacion") {
    return "#FFDC97";
  } else if (e == "entrenamiento_libre") {
    return "#FF6100";
  } else {
    return "#CBA791";
  }
}

export function iconReview(e) {
  if (e == "valoracion_fisica") {
    return mestraining[0].icon;
  } else if (e == "valoracion_nutricional") {
    return mestraining[1].icon;
  } else if (e == "cardio") {
    return mestraining[2].icon;
  } else if (e == "reserva") {
    return mestraining[3].icon;
  } else if (e == "grupal") {
    return mestraining[4].icon;
  } else if (e == "video") {
    return mestraining[5].icon;
  } else if (e == "descanso") {
    return mestraining[6].icon;
  } else if (e == "sesion_estiramiento") {
    return mestraining[7].icon;
  } else if (e == "entrenamiento_fuerza") {
    return mestraining[8].icon;
  } else if (e == "fisioterapia_recuperacion") {
    return mestraining[9].icon;
  } else if (e == "entrenamiento_libre") {
    return mestraining[10].icon;
  } else {
    return mestraining[1].icon;
  }
}