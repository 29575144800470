import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonMob, instance, laptop575 } from "../../utils/utils";
import { useState, useRef, useEffect } from "react";
import "./vertipoindividual.css";
import { Videos_two } from "../Ejercicios/othervideos";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPlayer from "react-player";
import { ReactComponent as BackArrow } from "../../assets/newIcons/arrow-back.svg";
import { Loading } from "../TutorialReserva/stepsHelp";

const VerinfoIndividual = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const searchText = useRef(null);
  // ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
  // ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
  // ["Cuerpo saludable", process.env.PUBLIC_URL + "/img/video1.png"],
  // ["Mente saludable", process.env.PUBLIC_URL + "/img/video2.png"],
  // ["Hábitos alimenticios", process.env.PUBLIC_URL + "/img/video3.png"],
  // ["Manejo del estres", process.env.PUBLIC_URL + "/img/video4.png"],
  const [AllVideos, setAllVideos] = useState(null);
  useEffect(() => {
    instance
    .get(`videos/programs`)
      .then((res) => {
        setisLoading(false)
        setAllVideos(res.data.filter((e) => e.id == id))});
  }, []);

  const avisos = {
    aviso_one: [
      "Adquiere todos los beneficios de ser elevva+. Selecciona tu plan ahora y eleva tu estilo de vida",
      "Inicia tu prueba gratuita de 7 dias.",
      "var(--barraPublicidad)",
    ],
    aviso_two: [
      "Mejora tu plan y disfruta de muchos más beneficios con elevva+",
      "Mejorar tu plan.",
      "var(--elevvaTextTitle)",
    ],
    aviso_three: [
      "Tu prueba gratuita ha expirado. Selecciona un plan y sigue disfrutando elevva+. ",
      "Activar plan.",
      "var(--elevva)",
    ],
  };

  const [counter, setcounter] = useState(0);
  const [barraPublicidad, setbarraPublicidad] = useState(true);
  // const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_two`]);
  const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_one`]);
  
  return (
    isLoading ? <Loading/> :  <div
      className="d-flex m-auto col-12 col-md-10"
      style={{
        height: "100vh",
      }}
    >
      {/* {laptop575.matches ? (
        <div className="mx-auto" style={{ width: "7.1%" }}></div>
      ) : null} */}
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
              flexDirection: "column",
            }}
          >
            <div
              className="py-2 mx-3 banner_one "
              style={{
                background: "rgb(241, 246, 249)",

                color: "#394F65",
              }}
            >
              {" "}
              <div
                onClick={() => {
                  localStorage.pathCom = AllVideos[0].main_topic;

                  return history('/videos');
                }}
                className=" position-absolute"
                style={{
                  left: "5%",
                  top: "50%",
                  transform:"translateY(-50%)",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {" "}
                <div className="d-flex m-auto" style={{alignItems:"center"
                
                }}>
          {" "}
          <button
            // onClick={() => history("/videos")}
           
            style={{ background: "transparent", border: "none" }}
          >
            <BackArrow width={30}  />
          </button>
          <span className="title my-auto fw-light">
           Regresar
          </span>
        </div>
              </div>
             
              <Link
                className="form  my-2 d-flex"
                style={{ float: "right", marginRight: "5%" }}
                 to="/videos/Buscar"
                
              >
                <button
                  className="elevation-3"
                  style={{
                    bottom: "19%",
                    right: "7%",
                    background: "white",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "50%",
                    padding: "1vmin",

                    color: "#394F65",

                    zIndex: "10",
                    border: "2px solid transparent",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 17 17"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
          <div
                className="bannervideos d-flex"
                style={{
                  backgroundColor: "#FDF1E8",
                  position: "sticky",
                  top: "0",
                  // zIndex: "3",
                  zIndex: "0",
                  minheight: "10vmin",
                  left: "0",
                  flexDirection: "column",
                }}
              >
              {/* <div
          className={barraPublicidad ? `d-flex ` : `d-none `}
          style={{
            background: avisosvarios[2],
            color: "var(--white)",
            // maxWidth: "70%",
            padding: "5px 7vmin",
            justifyContent: "center",
            height: "3rem",
            flexDirection: "row",
            alignContent: "center",
            // borderTopRightRadius: "15px",
            // borderBottomRightRadius: "15px",
          }}
        >
          <span
            className=" my-auto mx-2"
            style={{
              fontSize: "1.3vmin",
            }}
          >
            {avisosvarios[0]}{" "}
            <Link
              className="fw-bold"
              style={{ color: "var(--white)" }}
              // to="/ejercicios"
              to="/Usuario/groupals" //Reserva clases grupales
            >
              {avisosvarios[1]}
            </Link>
          </span>
          <span
            className="  my-auto"
            style={{
              fontSize: "1.5vmin",
              cursor: "pointer",
            }}
            onClick={() => setbarraPublicidad(!barraPublicidad)}

          >
            <i className="bi bi-x-circle"></i>
          </span>
        </div> */}
              </div>
          <div
            className="d-flex col-12"
            style={{
              paddingLeft: "5%",
            }}
          >
            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="position-relative" style={{ padding: "2%" }}>
                {AllVideos ? (
                  <div
                    className="d-flex"
                    style={{
                      padding: "0%",
                      height: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ReactPlayer 
                      className="position-relative playervideoIndividual "
                      url={AllVideos[0].preview} 
                      preload="auto"
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload'
          },
        },
      }}
                      playIcon={
                        <button
                          style={{
                            color: "#ffff",
                            background: "transparent",
                            border: "none",
                            height:"40vmin"
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="currentColor"
                            className="bi bi-play-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                          </svg>
                        </button>
                      }
                      light={
                        AllVideos[0].poster
                      }
                    height={"auto"}
                      width={"100%"}
                      playing={true}
                      controls={true}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "80vmin",
                padding: "0 5%",
              }}
            >
              <span
                style={{
                  color: "#CB3D82",
                  fontSize: "2.5vmin",

                  fontWeight: "bold",
                }}
              >
                Programa
              </span>
              <span
                style={{
                  color: "#394F65",
                  fontSize: "3vmin",

                  fontWeight: "600",
                }}
              >
                {AllVideos && AllVideos[0].name}
              </span>
              <div className="my-1">
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "2vmin",

                    fontWeight: "500",
                  }}
                >
                  Progreso
                </span>

                {AllVideos ? (
                  <div
                    className="progressCompanie my-1 fw-bold"
                    style={{
                      maxWidth: "60vmin",
                    }}
                  >
                    <ProgressBar
                      now={AllVideos[0].customer_progress==0? 100:AllVideos[0].customer_progress}
                      animated
                      variant="SOME_NAME"
                      label={`${AllVideos[0].customer_progress}%`}
                      style={
                        laptop575.matches
                          ? {
                              width: "100%",
                              border: "2px solid #DAA1BD",
                              height: "4.0vmin",
                              borderRadius: "1rem",
                              backgroundColor: "#DAA1BD",
                            }
                          : {
                              marginTop: "1vh",
                              width: "22vw",
                              border: "2px solid #C1CCFC",
                              height: "1.5rem",
                              borderRadius: "1rem",
                              backgroundColor: "#C1CCFC",
                            }
                      }
                    />
                  </div>
                ) : null}
              </div>
             { AllVideos && AllVideos[0].notes?<span
                className="my-1"
                style={{
                  color: "#394F65",
                  fontSize: "2.5vmin",

                  fontWeight: "500",
                  fontSize: "2vmin",
                }}
              >
               {AllVideos[0].notes}

              </span>:null}
              {AllVideos && AllVideos[0].description?<span
                style={{
                  color: "#394F65",
                  fontSize: "1.5vmin",
                }}
              >
             {AllVideos[0].description
}
              </span>:null}
              <div
                className="d-flex my-3"
                style={{
                  color: "#394F65",
                  fontSize: "1.5vmin",
                }}
              >
                {AllVideos ? (
               <span className="img-fluid" style={{overflow:"hidden"}}><img className="w-100"
                    style={{backgroundSize:"contain", transform:" translateY(-15%)" }}
                    src={AllVideos &&AllVideos[0]?.coach_picture || process.env.PUBLIC_URL + '/img/EntrenamientobalancePic.png'}
                  /></span>   
                ) : null}
              { AllVideos && AllVideos[0].coach ?<span className="my-auto mx-3">
                  Coach
                  <br />
                  <span
                    className="fw-bold"
                    style={{
                      color: "#394F65",
                      fontSize: "2.2vmin",
                    }}
                  >
                    { AllVideos[0].coach}
                  </span>
                </span>:null}
              </div>

             {AllVideos && AllVideos[0].coach_info? <span
                style={{
                  color: "#394F65",
                  fontSize: "1.5vmin",
                  maxWidth: "40vmin",
                }}
              >
                { AllVideos[0].coach_info}
              </span>:null}
              <div className="position-relative ">
                <button
                  style={{
                    marginTop: "2vmin",
                    marginBottom: "1vmin",
                    width: "15vmin",
                  }}
                  onClick={() => history(`/videos/card/${id}/${AllVideos[0].videos[0].id}`)}
                  className="buttonIndividualTypesverahora elevation-10"
                >
                  EMPIEZA YA
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FDF1E8",
              paddingLeft: "5%",
              paddingTop: "1.8%",
            }}
          >
 {AllVideos &&
              AllVideos
               
                .map((e, index) => (
                  <Videos_two
                      textTitleOne="Clases"
                textTitleTwo="del programa"
                    id={e.id}
                    key={"filtervideos" + e.id}
                   
                    contenido={e.category}
                    videosTime={[e]}
                  />
                ))}

            {/* <Videos_two
              textTitleOne="Clases "
              textTitleTwo="del programa"
              videosTime={AllVideos}
              id="pin"
            /> */}
            <br />
          </div>
        </div>
      ) : (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100%",

            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex py-3"
            style={{
              background: "#ffff",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
            }}
          >
       <div className="mx-3 my-1 d-flex">
          {" "}
          <button
            onClick={() =>{
              localStorage.pathCom = AllVideos[0].main_topic;

return history('/videos');
            }}
            className={"button_w_h_back"}
            style={{ background: "transparent", border: "none" }}
          >
            <BackArrow width={40}  />
          </button>
          <span className="title my-auto fw-light">
           Regresar
          </span>
        </div>
          </div>
          <div
            className="d-row col-12"
            style={{
              padding: "0% 5%",
            }}
          >
            {" "}
            <br />
            <div
              className="col-12 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="position-relative" style={{ padding: "0%" }}>
                {AllVideos ? (
                  <div
                    style={{
                      padding: "0%",
                      height: "55vmin",
                    }}
                  >
                    <ReactPlayer
                      className="position-relative playervideoIndividual"
                      url={AllVideos[0].preview}
                      preload="auto"
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload',
          },
        },
      }}
                      playIcon={
                        <button
                          style={{
                            color: "#ffff",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="currentColor"
                            className="bi bi-play-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                          </svg>
                        </button>
                      }
                   
                      light={
                        AllVideos[0].poster
                      }
                      height="100%"
                      width={"100%"}
                      playing={true}
                      controls={true}
                    />
                  </div>
                ) : null}
                {/* <img
                    style={{ width: "100%", borderRadius: "25px" }}
                    className="position-relative playervideoIndividual"
                    src={process.env.PUBLIC_URL + AllVideos[0].background_image}
                  /> */}
              </div>
            </div>
            <div
              className="col-12 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "100%",
                padding: "0 2%",
              }}
            >
              <span
                style={{
                  color: "#CB3D82",

                  fontWeight: "bold",
                }}
              >
                Programa
              </span>

              <span
                style={{
                  color: "#394F65",

                  fontWeight: "600",
                }}
              >
                {AllVideos && AllVideos[0].name}
              </span>
              <div className="my-1">
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                  }}
                >
                  Progreso
                </span>

                {AllVideos ? (
                  <div
                    className="progressCompanie my-1 fw-bold"
                    style={{
                      maxWidth: "150vmin",
                    }}
                  >
                    <ProgressBar
                      now={AllVideos[0] &&AllVideos[0].customer_progress==0? 100:AllVideos[0].customer_progress}
                     label={`${AllVideos[0].customer_progress}%`}
                      animated
                      variant="SOME_NAME"
                      style={{
                        marginTop: "1vh",
                        width: "80vmin",
                        border: "2px solid #C1CCFC",
                        height: "5.5vmin",
                        borderRadius: "1rem",
                        backgroundColor: "#C1CCFC",
                      }}
                    />
                  </div>
                ) : null}
              </div>

             { AllVideos && AllVideos[0].notes?<span
                className="my-1"
                style={{
                  color: "#394F65",
                  fontSize: "0.8rem",
                  fontWeight: "500",
                }}
              >
             {AllVideos[0].notes}
              </span>:null}

              {AllVideos && AllVideos[0].description?<span
                style={{
                  color: "#394F65",
                  fontSize: "0.9rem",
                }}
              >
                {AllVideos[0].description}
              </span>:null}
            { AllVideos && AllVideos[0].coach? <div
                className="d-flex my-3"
                style={{
                  color: "#394F65",
                }}
              >
                {AllVideos ? (
                  <span className="img-fluid" style={{overflow:"hidden"}}><img className="w-100"
                    style={{backgroundSize:"contain", transform:" translateY(-15%)" }}
                    src={AllVideos &&AllVideos[0]?.coach_picture || process.env.PUBLIC_URL + '/img/EntrenamientobalancePic.png'}
                  /></span>   
              
                ) : null}
                <span className="my-auto mx-3">
                  Coach
                  <br />
                  <span
                    className="fw-bold"
                    style={{
                      color: "#394F65",
                      fontSize: "0.9rem",
                    }}
                  >
                    {AllVideos[0].coach}
                  </span>
                </span>
              </div>:null}

          {   AllVideos && AllVideos[0].coach_info? <span
                style={{
                  color: "#394F65",
                  fontSize: "0.7rem",
                  maxWidth: "100%",
                }}
              >
              {AllVideos[0].coach_info}
              </span>:null}
            </div>
            <br />
            <div
              className="position-relative "
              // style={{ bottom: "13%", left: "9%" }}
            >
              <button
                style={{
                  marginTop: "1vmin",
                  marginBottom: "1vmin",
                  fontSize: "0.6rem",
                  width: "40%",
                }}
                onClick={() => history(`/videos/card/${id}/${AllVideos[0].videos[0].id}`)}
                className="buttonIndividualTypesverahora elevation-10"
              >
                EMPIEZA YA
              </button>
            </div>
          </div>
          <br />
          <div
            style={{
              backgroundColor: "rgb(253, 241, 232)",
              paddingLeft: "5%",
              paddingTop: "1.8%",
            }}
          >
          {AllVideos &&
              AllVideos
               
                .map((e, index) => (
                  <Videos_two
                      textTitleOne="Clases"
                textTitleTwo="del programa"
                    id={e.id}
                    key={"filtervideos" + e.id}
                   
                    contenido={e.category}
                    videosTime={[e]}
                  />
                ))}
            <br />
          </div>
          <br />
          <br />

          <br />
        </div>
      )}
    </div>
  );
};
export default VerinfoIndividual;
