/* eslint-disable no-lone-blocks */
// import { Redirect } from "react-router-dom";

import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useEffect, useState, useRef } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import Switch from "react-switch";
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg"
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import {
  basename,
  ButtonMob,
  instance,
  ModalInfo,
  ModalInfoMembresias,
  ModalInfo1,
  NuevaAxiosURL,
  SetModalError,
  userType,
  Loadingspinner,
  laptop575,
  CustomModal,
  CustomModalOk,
  CustomModalError,
  ModalCuenta,
  allAvatars
} from "../../utils/utils";
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateUtils from "@date-io/moment";
//importar modal
import "../../utils/utils.css";
import "swiper/swiper.min.css";
import ReactTooltip from "react-tooltip";
import * as locales from "react-date-range/dist/locale";
import "./onboardingCustomer.css";
import Webcam from "react-webcam";

import axios from "axios";
import { Lesiones } from "../Lesiones/lesiones";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

// import  locales from 'react-date-range/dist/locale/index';

import { useNavigate, } from "react-router-dom";

SwiperCore.use([Pagination]);
export const OnboardingCustomer = (props) => {
  const [confirmado, setModalConfirm] = useState(null);

  // console.log(locales["es"]);
  // console.log(moment());
  const [Modal, setModal] = useState(null);
  const [webcamActive, setwebcamActive] = useState(false);
  const [clasemodalBotons, setclasemodalBotons] = useState("d-none");
  const [clasemodalAvatars, setclasemodalAvatars] = useState("d-none");
  const [Paises, setPaises] = useState(null);
  const [ciudades, setciudades] = useState(null);
  const [buttonChange, setbuttonChange] = useState("Ver vista Posterior");
  const backView = useRef(null);
  const textBirth = useRef(null);
  const textBirthBackground = useRef(null);
  const frontalView = useRef(null);
  const modalesDolores = useRef(null);
  const modalesDoloresBack = useRef(null);
  const elevvaT = props.elevvaT;

  // agregando a
  // const [previewPhotoProfile, setPreviewPhotoProfile] = useState(camera);
  const [photoProfile, setPhotoProfile] = useState(null);
  const [chekeaprofile, setchekeaprofile] = useState(true);
  const [modificarLesiones, setModificarLesiones] = useState(false);

  // console.log(modificarLesiones, "soy lesiones");
  const salvedadMedico = useRef(null);
  const [footBar, setFootBar] = useState(false);



  const [gender, setGender] = useState('');

  const [nacimiento, setNacimiento] = useState("2000-01-01");

  const [estatura, setEstatura] = useState("");
  const [peso, setPeso] = useState(null);
  const [tel, setTel] = useState(null);
  const [ciudad, setciudad] = useState(null);
  const [pais, setpais] = useState(null);

  const [direccion, setDireccion] = useState(null);
  const [tiempoActividad, settiempoActividad] = useState(null);
  const [agotamientoActividad, setAgotamientoActividad] = useState(null);
  const [sesionesActividad, setsesionesActividad] = useState(null);
  const [intensidadActividad, setintensidadActividad] = useState(null);
  const [franjaActividad, setfranjaActividad] = useState(null);
  const [medioTransporte, setmedioTransporte] = useState(null);
  const [transportation, setTransportation] = useState(null)
  const [tiempoTransporte, settiempoTransporte] = useState(null);
  const [preguntasMedicas, setPreguntasMedicas] = useState(false);
  const [preguntaEnfermedad, setPreguntaEnfermedad] = useState(false);

  const navigate = useNavigate();
  const [answer7Text, setAnswer7Text] = useState('');

  const [inputs, setInputs] = useState({
    imagen: "",
    birthday: "",
    height: "",
    weight: "",
    country: "",
    city: "",
    address: "",
    transportation_time: "",
    transportation: "",
    emergency_contact: "",
    emergency_contact_phone: "",
    answer_1: false,
    answer_2: false,
    answer_3: false,
    answer_4: false,
    answer_5: false,
    answer_6: false,
    answer_7: false,
    answer7Text: answer7Text,
    answer_8: false,
    answer_9: false,
  });

 
  const [answer_1, setCheckPreg1] = useState(false);
  const [answer_2, setCheckPreg2] = useState(false);
  const [answer_3, setCheckPreg3] = useState(false);
  const [answer_4, setCheckPreg4] = useState(false);
  const [answer_5, setCheckPreg5] = useState(false);
  const [answer_6, setCheckPreg6] = useState(false);
  const [answer_7, setCheckPreg7] = useState(false);
  const [answer_8, setCheckPreg8] = useState(false);
  const [answer_9, setCheckPreg9] = useState(false);


  const [textodolor, settextodolor] = useState("");

  const preguntas_salud = [
    [
      "¿Algún médico le ha dicho que tiene problemas del corazón y que sólo debe hacer actividades físicas recomendadas por un médico?",
      "answer_8",
      inputs["answer_8"],
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Tiene dolor en el pecho cuando hace alguna actividad física?",
      "answer_2",
      inputs["answer_2"],
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿En el último mes, ¿ha tenido dolor en el pecho cuando no estaba haciendo actividades físicas?",
      "answer_9",
      inputs["answer_9"],
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Pierde el equilibrio por mareos, o ha perdido alguna vez el conocimiento?",
      "answer_4",
      inputs["answer_4"],
      "col-2 ps-3 align-self-center",
    ],
    [
      "¿Tiene problema en algún hueso o articulación que pueda ser agravado por un cambio en su actividad física?",
      "answer_5",
      inputs["answer_5"],
      "col-2 ps-3 align-self-center",
    ],
    // [
    //   "¿Está tomando medicamentos recetados por el médico para la presión arterial o para el corazón (por ejemplo, pastillas diuréticas)?",
    //   "answer_6",
    //   inputs["answer_6"],
    //   "col-2 ps-3 align-self-center",
    // ]
    // ,
    // [
    //   "¿Sabe de cualquier otra razón en contra de que ejercite?",
    //   "answer_7",
    //   inputs["answer_7"],
    //   "col-2 ps-3 align-self-center",
    // ],
  ];
  const pregunta_one = [
    [
      elevvaT("like_exercise"),
      "answer_1",
      inputs["answer_1"],
      "col-2 ps-3 align-self-center",
    ],
  ];
  const pregunta_three = [
    [
      elevvaT("activity_physical_right"),
      "answer_3",
      inputs["answer_3"],
      "col-2 ps-3 align-self-center",
    ],
  ];
  const objetivos = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      "Perder peso",
      process.env.PUBLIC_URL + "/assets/img/onboarding/loseB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/loseA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Mantener peso",
      process.env.PUBLIC_URL + "/assets/img/onboarding/physiqueB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/physiqueA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Salud",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/healthA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Fuerza",
      process.env.PUBLIC_URL + "/assets/img/onboarding/forceB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/forceA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Tonificar",
      process.env.PUBLIC_URL + "/assets/img/onboarding/toneB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/toneA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Mejorar nivel",
      process.env.PUBLIC_URL + "/assets/img/onboarding/levelupB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/levelupA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];

  const [selectObjetivos, setselectObjetivos] = useState(
    new Array(objetivos.length)
  );
  const [selectObjetivos1, setselectObjetivos1] = useState(null);

  const transportes_time = [
    ["De 2 a 3 horas", useRef(null)],
    ["Media hora", useRef(null)],
    ["Más de 3 horas", useRef(null)],
  ];
  const actividad_fisica = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      "Yoga pilates",
      process.env.PUBLIC_URL + "/assets/img/onboarding/yogaA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/yogaB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Ejercicio funcional",
      process.env.PUBLIC_URL + "/assets/img/onboarding/funcionalA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/funcionalB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Caminar /Correr",
      process.env.PUBLIC_URL + "/assets/img/onboarding/caminarA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/caminarB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Rumba",
      process.env.PUBLIC_URL + "/assets/img/onboarding/rumbaA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/rumbaB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Ciclismo",
      process.env.PUBLIC_URL + "/assets/img/onboarding/ciclismoA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/ciclismoB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      "Otros",
      process.env.PUBLIC_URL + "/assets/img/onboarding/otrosA.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/otrosB.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];
  const [actividadFisica, setactividadFisica] = useState(
    new Array(actividad_fisica.length)
  );

  useEffect(() => {
    searchTerminos(); // search all the faqs
  }, []);
  console.log("erg-e",actividadFisica) 
  useEffect(() => {
    console.log("erge",actividadFisica) 
  }, [actividadFisica]);
  const actividad_time = [
    ["Ninguna", useRef(null)],
    ["Menos de 3", useRef(null)],
    ["3 a 5 veces", useRef(null)],
    ["+ de 5 veces", useRef(null)],
  ];
  const actividad_agotado = [
    ["Normal", useRef(null)],
    ["Cansado", useRef(null)],
    ["Muy cansado", useRef(null)],
  ];
  const actividad_sesiones = [
    ["No hago sesiones", useRef(null)],
    ["Menos de 30 min", useRef(null)],
    ["Entre 30 y 60 min", useRef(null)],
    ["Más de 60 min", useRef(null)],
  ];
  const actividad_intensidad = [
    ["No hago sesiones", useRef(null)],
    ["Baja", useRef(null)],
    ["Media", useRef(null)],
    ["Alta", useRef(null)],
  ];
  const franja_horaria = [
    ["06:00 a 11:00", useRef(null)],
    ["11:00 a 17:00", useRef(null)],
    ["17:00 a 22:00", useRef(null)],
  ];

  const [contactoName, setContactoName] = useState(null);
  const [contactoTel, setContactoTel] = useState(null);
  const [cargarname, setcargarname] = useState(false);
  const [infoLesiones, setInfoLesiones] = useState(null);


  const question_2 = [
    [
      `Presión <br/> constante`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/presion_constanteB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/presion_constanteA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Paciente  <br/> y calmado`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/calmadoB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/calmadoA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];
  const transportes = [
    // titulo, img unselected, img selected, ref container, ref image
    [
      `Carro /  <br/> Moto`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/carroB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/carroA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Bicicleta <br/>`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/biciB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/biciA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    [
      `Transporte <br/> público`,
      process.env.PUBLIC_URL + "/assets/img/onboarding/busB.svg",
      process.env.PUBLIC_URL + "/assets/img/onboarding/busA.svg",
      useRef(null),
      useRef(null),
      useRef(null),
    ],
  ];

  useEffect(() => {
    instance
      .get(`customers/injuries/?customer=${localStorage.id}`)
      .then((response) => {
        const info = response.data;

        if (info != null) {
          setInfoLesiones(info);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return "axios request cancelled";
        }
        return err;
      });
  }, []);
  useEffect(() => {
    instance.get(`${NuevaAxiosURL}users/`).then((res) => {
      const info = res.data;

      if (info != null) {
        if (
          info.customer.emergency_contact != "" &&
          info.customer.emergency_contact_phone != ""
        ) {
          setcargarname(true);
          // formRefcargar.current.children[0].children.namedItem(
          //   "name_contacto"
          // ).value = info.customer.emergency_contact;
          setContactoName(info.customer.emergency_contact);
          // formRefcargar.current.children[1].children.namedItem(
          //   "tel_contacto"
          // ).value = info.customer.emergency_contact_phone;
          setContactoTel(info.customer.emergency_contact_phone);
        }
      }
    });
  }, [])

  const selectCircleText = (refContainer, index, classess, arr, onlyone) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        iterator[1].current.classList = classess;
        iterator[1].current.active = false;
        iterator[2] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
    } else {
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
    }
    if (arr == actividad_time) {
      actividad_time[index][2] = true;
      settiempoActividad(index);
    }
    if (arr == actividad_sesiones) {
      actividad_sesiones[index][2] = true;
      setsesionesActividad(index);
    }
    if (arr == actividad_intensidad) {
      actividad_intensidad[index][2] = true;
      setintensidadActividad(index);
    }
    if (arr == actividad_agotado) {
      actividad_agotado[index][2] = true;
      setAgotamientoActividad(index);
    }
    if (arr == franja_horaria) {
      franja_horaria[index][2] = true;
      setfranjaActividad(index);
    }
    if (arr == transportes_time) {
      transportes_time[index][2] = refContainer.current.active;
      settiempoTransporte(index)
    }
  };
  const handleCheckedPreg = (index) => {
    switch (index) {
      case 0:
        setCheckPreg1(answer_1 ? false : true);
        break;
      case 1:
        setInputs((val) => ({
          ...val,
          ["answer_2"]: inputs["answer_2"] ? false : true,
        }));
        // setCheckPreg2(answer_2 ? false : true);
        break;
      case 2:
        setCheckPreg3(answer_3 ? false : true);
        break;
      case 3:
        setCheckPreg4(answer_4 ? false : true);
        break;
      case 4:
        setCheckPreg5(answer_5 ? false : true);
        break;
      case 5:
        setCheckPreg6(answer_6 ? false : true);
        break;
      case 6:
        setCheckPreg7(answer_7 ? false : true);
        break;
      case 7:
        setCheckPreg8(answer_8 ? false : true);
        break;
      case 8:
        setCheckPreg9(answer_9 ? false : true);
        break;
      default:
        break;
    }
  };

  const [canNext, setCanNext] = useState(false);
  const [numberStep, setnumberStep] = useState(null);


  // Slider 1
  const handleGeneroChange = (e) => {
    setGender(e.target.value);
  };

  useEffect(() => {
    if (
      answer_1 == true ||
      answer_2 == true ||
      answer_3 == true ||
      answer_4 == true ||
      answer_5 == true ||
      answer_6 == true ||
      answer_7 == true ||
      answer_8 == true ||
      answer_9 == true
    ) {
      setPreguntasMedicas(true);
    }
    if (
      answer_1 == false &&
      answer_2 == false &&
      answer_3 == false &&
      answer_4 == false &&
      answer_5 == false &&
      answer_6 == false &&
      answer_7 == false &&
      answer_8 == false &&
      answer_9 == false
    ) {
      setPreguntasMedicas(false);
    }
    if (answer_7 == true) {
      setPreguntaEnfermedad(true);
    } else {
      setPreguntaEnfermedad(false);
    }
  }, [
    answer_1,
    answer_2,
    answer_3,
    answer_4,
    answer_5,
    answer_6,
    answer_7,
    answer_8,
    answer_9
  ]);

  const renderSwitchh = (
    index,
    label,
    name,
    isCheck,
    textclasses,
    switchClasses,
    ananswer
  ) => {
    const shouldRenderTextArea = name === "answer_7" && isCheck;
    //const shouldShowTextArea = shouldRenderTextArea && showTextArea;
    return (
      <>
     
        <div style={{ display: "flex"}}>
          <Switch
            name={name}
            onChange={(value) =>
              setInputs((val) => ({
                ...val,
                [name]: value,
              }))
            }
            checked={!!isCheck}
            className={switchClasses}
            handleDiameter={25}
            height={35}
            width={75}
            onHandleColor="#FFFFFF"
            offHandleColor="#9EACED"
            offColor="#E0E7FF"
            onColor="#9EACED"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "#9EACED",
                  paddingRight: 2,
                  fontWeight: "700",
                }}
              >
                NO
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "white",
                  paddingRight: 2,
                  fontWeight: "700",
                }}
              >
                SI
              </div>
            }
          />
        </div>
      </>
    );

  };
  const [placeholderText, setPlaceholderText] = useState("");



  const renderCircleImg = (arr_data, title, classes, onlyone) => {
    return (
      <>
        <div className="fw-bold lh-1 py-3 preg letrasreal ">{title}</div>
        <div className="row g-0" style={{ padding: "0 2vw" }}>
          {arr_data.map((objetivo, index, this_array) => {
            return (
              <div
                key={index}
                ref={objetivo[3]}
                className={classes}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  selectCircleImg(
                    objetivo[3],
                    objetivo[4],
                    index,
                    classes,
                    this_array,
                    onlyone
                  )
                }
              >
                <div
                  ref={objetivo[4]}
                  id="icon"
                  style={{ backgroundImage: `url(${objetivo[1]})` }}
                  className="rounded-circle"
                ></div>
                <div id="text" className="lh-1 pt-1">
                  {objetivo[0]}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderCircleText = (arr_data, title, classes, onlyone) => {
    return (
      <>
        <div className="fw-bold lh-1 py-3 preg letrasreal">{title}</div>
        <div className="row g-0 marginimportantpaso5">
          {arr_data.map((objetivo, index, this_array) => {
            return (
              <div
                key={index}
                ref={objetivo[1]}
                className={classes}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  selectCircleText(
                    objetivo[1],
                    index,
                    classes,
                    this_array,
                    onlyone
                  )
                }
              >
                <div
                  id="text"
                  className="rounded-circle d-flex justify-content-center align-items-center lh-1"
                >
                  {objetivo[0]}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  // 

  useEffect(() => {
    

    axios.get("/data/paises.json").then((res) => setPaises(res.data));

    axios.get("/data/ciudades.json").then((res) => setciudades(res.data));
  }, []);
  useEffect(() => {

  }, [medioTransporte]);

  const Ocultar = () => {
    setTimeout(() => {
      setModal(null);
    }, 50000);
  };
  const hideModal = () => {
    setModal(null);
  };
  const selectCircleImg = (
    refContainer,
    refImg,
    index,
    classess,
    arr,
    onlyone
  ) => {
    if (onlyone == true) {
      for (const iterator of arr) {
        iterator[3].current.classList = classess;
        iterator[3].current.style.fontWeight = `400`;
        iterator[3].current.active = false;
        iterator[4].current.style.backgroundImage = `url(${iterator[1]})`;
        iterator[4].current.style.border = `6px solid var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[4].current.style.backgroundColor = `var(--backgroundOnboardingObjectiveBorderOff)`;
        iterator[5] = false;
      }
    }
    if (refContainer.current.active == true) {
      refContainer.current.classList = classess;
      refContainer.current.active = false;
      refImg.current.style.backgroundImage = `url(${arr[index][1]})`;
      refImg.current.style.backgroundColor = `var(--backgroundOnboardingObjectiveBorderOff)`;
      refContainer.current.style.fontWeight = `400`;
    } else {
      refContainer.current.style.fontWeight = `600`;
      refContainer.current.classList = classess + " active";
      refContainer.current.active = true;
      refImg.current.style.backgroundImage = `url(${arr[index][2]})`;
      refImg.current.style.backgroundColor = `var(--backgroundOnboardingObjective)`;
    }
    if (arr == objetivos) {
      // objetivos[index][5] = refContainer.current.active;
      // const copy = selectObjetivos;
      // copy[index] = refContainer.current.active;
      objetivos[index][5] = refContainer.current.active;
      inputs["objectives"] = (index + 1) * 10;
      setselectObjetivos1((index + 1) * 10);
    }
    if (arr == transportes) {
      transportes[index][5] = refContainer.current.active;
      setmedioTransporte(index);
    }
    if (arr == question_2) {
      question_2[index][5] = refContainer.current.active;
      inputs["presion"] = index;
    }
    if (arr == actividad_fisica) {
      actividad_fisica[index][5] = refContainer.current.active;
      const copy = actividadFisica;
      copy[index] = refContainer.current.active;
      setactividadFisica(copy);
    }

  };

  const changeView = (ref) => {
    if (ref == backView) {
      backView.current.style.display = "block";
      frontalView.current.style.display = "none";
      setbuttonChange("Ver vista frontal");
    } else {
      backView.current.style.display = "none";
      frontalView.current.style.display = "block";
      setbuttonChange("Ver vista Posterior");
    }
  };

  const lookCamera = () => {
    setwebcamActive(true);
  };
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const BannerAvatars = (props) => {
    const avatarPersons = props.avatarPersons;
    const render_avatarPersons = avatarPersons.map((imagen, index) => {
      return (
        <SwiperSlide key={index} className="w-100 my-3 mx-auto p-3">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3,1fr)",
              justifySelf: " center",
              justifyItems: "center",
              gap: "1%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {imagen.map((avatar, index) => (
              <button
                key={index}
                className="buttonIcon_Avatar"
                onClick={() => {
                  axios.get(avatar).then((res) => {
                    getBase64FromUrl(`${avatar}`).then((avata) => {
                     
                      var blob = dataURItoBlob(avata);
                      let imagen_firma = new File([blob], "photoAvatar.png", {
                        type: "image/png",
                      });
                      setPreviewPhotoProfile(URL.createObjectURL(imagen_firma));
                      setInputs((val) => ({
                        ...val,
                        ["profile_pic"]: imagen_firma,
                      }));
                      setclasemodalBotons("d-none");
                      setclasemodalAvatars("d-none");
                    });
                  });
                }}
              >
                <img className="iconavatar" src={avatar} />
              </button>
            ))}
          </div>
        </SwiperSlide>
      );
    });

    return (
      <>
        <Swiper
          style={{ maxWidth: "60vmin" }}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            el: ".bulletsPublicidad",
            type: "custom",
            clickable: true,
            renderCustom: function (swip, current, total) {
              current -= 1;
              return avatarPersons
                .map((value, index) => {
                  return `<div class=' swiper-pagination-bullet pointerinAvatar mx-2 ${current == index ? "active" : ""
                    }'></div>`;
                })
                .join("");
            },
          }}
        >
          {render_avatarPersons}
        </Swiper>

        <div className="bulletsPublicidad"></div>
      </>
    );
  };

  const ModalInfoAvatares = React.forwardRef((props, ref) => {
    const display = props.display || "flex";
    const position = props.position || "fixed";
    const title = props.title;
    let styleModal = {
      display: display,
      position: position,
      whiteSpace: "pre-line",
    };
    return (
      <div
        ref={ref}
        className={`${props.classes} modalInfo justify-content-center align-items-center`}
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div
            className="position-absolute m-4"
            style={{
              color: "var(--elevvaTextSubtitle)",
              top: "0",
              right: "0",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => setclasemodalAvatars("d-none")}
          >
            <i className="bi bi-x-circle-fill"></i>
          </div>
          <div>
            <img
              className="logo-camera-responsive"
              src={process.env.PUBLIC_URL + "/assets/img/select_image.png"}
            />
          </div>
          <h4 className="text-color-title mt-2">{ReactHtmlParser(title)}</h4>

          <BannerAvatars avatarPersons={allAvatars} />
        </div>
      </div>
    );
  });
  // console.log(modalesDolores, "soy modal dolores");
  const ModalInfoCargarImagenes = React.forwardRef((props, ref) => {
    const display = props.display || "flex";
    const position = props.position || "fixed";
    const title = props.title;
    let styleModal = {
      display: display,
      position: position,
      whiteSpace: "pre-line",
    };
    function handleClickCamera() {
      props.handleClickCamera();
    }
    function hideModal() {
      props.hideModal();
    }
    return (
      <div
        ref={ref}
        className={`${props.classes} modalInfo justify-content-center align-items-center`}
        style={styleModal}
      >
        <div className="modalInfoContenido">
          <div
            className="position-absolute "
            style={{
              color: "var(--elevvaTextSubtitle)",
              top: "3%",
              right: "3%",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => setclasemodalBotons("d-none")}
          >
            {" "}
            <i className="bi bi-x-circle-fill"></i>
          </div>

          <div>
            <img
              className="logo-camera-responsive"
              src={process.env.PUBLIC_URL + "/assets/img/select_image.png"}
            />
          </div>
          <h4 className="text-color-title my-3">{ReactHtmlParser(title)}</h4>
          <div  className="position-relative mx-auto w-100 d-flex justify-content-center flex-column gap-md-2">
          <div className="position-relative mx-auto w-100 d-flex justify-content-center">
            <ButtonMob
              text={elevvaT("upload_photo")}
              className="btnAction  py-2 sizebuttonLarge"
            />{" "}
            <input
              type="file"
              name="profile_pic"
              accept="image/*"
              className="photoProfile"
              id="profile_pic"
              onChange={changePhoto}
            />
          </div>
          <div className="position-relative mx-auto w-100 d-none d-md-flex justify-content-center ">
          <ButtonMob
            text={elevvaT("take_photo")}
            className="btnAction  py-2 sizebuttonLarge"
            handleClickCamera={handleClickCamera}
          />
           </div>
           <div className="position-relative mx-auto w-100 d-flex justify-content-center">
          <ButtonMob
            text={elevvaT("choose_avatar")}
            className="btnAction  py-2 sizebuttonLarge escoger_avatar_bottom"
            handleClickCamera={() => setclasemodalAvatars("")}
          />
           </div>
           </div>
        </div>
      </div>
    );
  });
  const [currentSlider, setCurrentSlider] = useState(1);
  const [theSwipper, setTheSwipper] = useState(null);
  // const [canNext, setCanNext] = useState(false);
  const [previewPhotoProfile, setPreviewPhotoProfile] = useState(
    process.env.PUBLIC_URL + "/assets/img/camera_update_picture.png"
  );
  var handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs((val) => ({ ...val, [name]: value }));
  };
  useEffect(() => {
    if (checkCurrentForm(false)) {
      setCanNext(true);
    }
    return () => { };
  }, [currentSlider]);
  function nextSlider() {
    setCanNext(false);
    theSwipper.slideNext();
    if (checkCurrentForm()) {
    }
  }

  function prevSlider() {
    theSwipper.slidePrev();
  }
  const CompletarFooter = (props) => {
    
    const cargarname = props.cargarname;
    const [modalInfo, setModalInfo] = useState(null);
    const chekeaprofile = props.chekeaprofile;

    const numberStep = props.numberStep;

    const current = props.current;
    const valorllenotelefono = cargarname ? 6 : 7;
    const renderFootBar = current == valorllenotelefono ? true : false;
    const siguienteborrar = document.querySelector(
      ".CompletarFooter .anterior"
    );
    if (siguienteborrar) {
      if (current >= 1 && current < 2) {
        siguienteborrar.style = "background-color: transparent;";
      } else {
        siguienteborrar.style = "background-color: #CB3D82";
      }
    }
    // console.log("renderFootBar", renderFootBar);
    function completeProfile() {
      props.completeProfile();
    }
    function nextSlider() {
      props.nextSlider();
      // if (inputs["answer_2"] || !inputs["answer_4"] || !inputs["answer_5"] || !inputs["answer_6"] || !inputs["answer_7"] || !inputs["answer_8"] || !inputs["answer_9"]) {
      // if (inputs["answer_8"] || inputs["answer_2"] || inputs["answer_9"] || inputs["answer_4"] || inputs["answer_5"] || inputs["answer_6"] || inputs["answer_7"]) {
      //   if(inputs["emergency_contact"] || inputs["emergency_contact_phone"]) {
      //   // setModal(
      //   //   <CustomModalOk
      //   //     show={true}
      //   //     handleClose={hideModal}
      //   //     title="Antes de empezar a hacer actividad física"
      //   //     classesMsg="Te recomendamos contar con aval médico para empezar a entrenar con nosotros. "
      //   //     classesMsgs=" Envianos un correo a sercivioalcliente@sportcheck.com.com para activar tu cuenta"
      //   //   // text={" \n\n" + faltaLLenar.join("\n")}
      //   //   //classesMsg={"wewww"}
      //   //   sendDataFinal={sendDataFinal}
      //   //   />
      //   // );
      //     // setTimeout(() => {
      //     //   sendDataFinal()

      //     // }, 50000);
      //   }
      // }
      
      // // else if  (inputs["answer_8"] || inputs["answer_2"] || inputs["answer_9"] || inputs["answer_4"] || inputs["answer_5"] || inputs["answer_6"] || inputs["answer_7"]) {
      //   else if (inputs["emergency_contact_phone"]) {

        
      //   //alert("enfermo de mierda")
      //   console.log("bienvenido");
      //   //sendDataFinal()
      //   alert("bienvenido")

      // }
      // else {
      //   console.log("hola");
      //   //modal de bienvenida
      //   sendDataFinal()
      // }
    }

    
    function prevSlider() {
      props.prevSlider();
    }
    function mostraryatusabes() {
      if (chekeaprofile === true) {
        const fin = document.getElementById("tyc1");
        if (fin) {
          fin.style = "display:none";
        }
        {
          setModalInfo(
            <ModalInfoMembresias
              error="false"
              handleClick={() => {
                setModalInfo(null);
                setModificarLesiones(true);
                completeProfile();
              }}
              title="¡Ahora eres Elevva +!"
              text={
                "Tu cuenta a sido creada exitosamente. Al dar click vivirás una nueva experiencia."
              }
              classesMsg={"FaltanItemsmobster"}
            />
          );
        }
      }
    }

    const classNext = `siguiente ${props.canNext == true ? "canNext" : ""}`;
    return modalInfo == null ? (
      <>
        <nav className="CompletarFooter navFooter  next_stop " style={{height:'10%'}}>
          <ButtonMob
             className={current ==1 ? "d-none": ""}
            icon={"bi bi-chevron-left"}
            colorButton={`var(--elevva)`}
            handleClick={prevSlider}
            // handleClick={() => navigate(`/Usuario/groupals`)}
            
            text={"Anterior"}
          ></ButtonMob>
          <ButtonMob
            icon={"bi bi-chevron-right"}
            colorButton={`var(--elevvabutonLogin_User)`}
            handleClick={nextSlider}
            className=""
            text={"Siguiente"}
          // handleClick={() => navigate(`/Usuario/groupals`)}
          ></ButtonMob>
          {/* )} */}
        </nav>
      </>
    ) : (
      <>{modalInfo}</>
    );
  };

  const instance = axios.create({
    baseURL: `${NuevaAxiosURL}`,

    headers: { Authorization: "Bearer " + localStorage.my_code },
  });

  useEffect(() => {
  

    if (checkCurrentForm(false)) {
      setCanNext(true);
    }
    return () => { };
  }, [
    currentSlider,
    photoProfile,
    gender,
    nacimiento,
    estatura,
    peso,
    ciudad,
    direccion,
    selectObjetivos,
    selectObjetivos1,
    actividadFisica,
    tiempoActividad,
    agotamientoActividad,
    sesionesActividad,
    intensidadActividad,
    franjaActividad,
     medioTransporte,
    transportation,
    tiempoTransporte,
    contactoName,
    contactoTel,
  ]);

  function checkCurrentForm(show_warn = true) {
    const checkSlider = currentSlider;

    let lleno = false;

    function showModalWarn(faltaLLenar) {
      if (show_warn) {
    
        setModal(
          <ModalInfo
            error="true"
            handleClick={hideModal}
            handleCancellcargue={hideModal}
            text={
              "Faltan algunos datos por llenar: \n\n"  + faltaLLenar.join("\n")
            }
            classesMsg={"FaltanItems"}
          />
        );

        // setModal(
        //   <CustomModal
        //     show={true}
        //     handleClose={hideModal}
        //     title="Falta algo por llenar:"
        //     // text={" \n\n" + faltaLLenar.join("\n")}
        //     items={items}
        //     classesMsg={"FaltanItems"}
        //   />
        // );
      }
    }
    const faltaLLenar = [];
    switch (checkSlider) {
      case 1:
        if (!inputs["profile_pic"]) {
          faltaLLenar.push( `<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Foto de perfil`);
        }
        if (!gender) {
          faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Género`
          );
        }
        if (inputs["birthday"] === "") {
          faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Fecha de nacimiento`);
        }
        if (!inputs["height"])  faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Estatura`);

        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }

        return true;
      case 2:
        if (!inputs["weight"]) faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Peso`);
        if (!inputs["country"]) {faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>País`);}
        if (!inputs["city"]) {faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Ciudad de residencia`);}

        if (!inputs["address"]){ faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Dirección de residencia`);}
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;

      case 4:
        lleno = false;
        if (inputs["answer_3"]) {
          for (const key in actividadFisica) {
            if (actividadFisica[key] === true) lleno = true;
          }
          if (!lleno) faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Actividad física`);
          if (null === tiempoActividad) faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Tiempo de actividad`);
          if (null === agotamientoActividad) {faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Agotamiento por actividad`);}
          if (null === sesionesActividad){ faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Sesiones por actividad`);}
          if (null === intensidadActividad){
            faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Intensidad por actividad`);
          }
        
          if (null === franjaActividad){faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Franja horaria de actividad`);}
           
        }
        if (medioTransporte ==null) {
          faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i> Medio de transporte`);
        }
     
        if (tiempoTransporte == null){
          faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Tiempo en el transporte`);
        }
         
        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;
      case 6:
        if (!inputs["emergency_contact"]){
          faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i>Nombre del contacto`);
        }
        
        if (!inputs["emergency_contact_phone"]){
          faltaLLenar.push(`<i class="bi bi-info-circle align-middle me-2 colorIconMessage"></i> Teléfono del contacto`);
        }
        

        if (faltaLLenar.length !== 0) {
          if (show_warn) {
            showModalWarn(faltaLLenar);
          }
          return false;
        }
        return true;
      // case 7 : 
      //     if(inputs["answer_8"]) {
      //       alert("estoy lleno")
      //     }
      //     return true
      default:
        return true;
    }

  }



  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const labelInput = (name, inputClasses, placeholder, type) => {
    return (
      <>
        <input
          type={type}
          name={name}
          className={inputClasses}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </>
    );
  };
  const videoConstraints = {
    width: 500,
    maxWidth: "5vw",
    height: 500,
    facingMode: "user",
    position: "absolute",
  };

  const changePhoto = (event) => {
    const photo = event.target.files[0];

    setInputs((val) => ({
      ...val,
      ["profile_pic"]: photo,
    }));
    //setPhotoProfile(photo);
    setPreviewPhotoProfile(URL.createObjectURL(photo));
   
    setclasemodalBotons("d-none");
  };
  function searchTerminos() {
    const formData = new FormData();
  }

 

  const Paso1 = (
    <SwiperSlide className="slider_form d-flex flex-column " style={{height:'100%',padding:'0 2%'}}>
    <div style={{height:'87vh',alignItems:'center',}}>
      <div className=" w-100 d-none d-md-flex" style={{height:'auto'}}>
        <div style={{ width: "100%", minWidth: "380px",height:'auto' }}>
          <h1 className="text-color-title m-auto">
            {elevvaT("complete")} <strong>{elevvaT("your_profile")}</strong>
          </h1>
        </div>
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
            
          }}
        >
          <hr />
        </div>
      </div>
      <div>  <h2 className="text-color-title-low leftTextoInterno"  >{elevvaT("know_you")}</h2></div>
    
      <div className="d-flex flex-column flex-md-row justify-content-center"  style={{alignItems:'center',height:'calc(100vh - 59%)'}}>
        <div
          className="mx-auto my-3 my-md-auto position-relative"
          onClick={() => setclasemodalBotons("")}
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/img/warning.png"}
            alt="info"
            data-effect="solid"
            data-place="bottom"
            className="btnInfo position-absolute"
            data-event="click focus"
            data-background-color="#CB3D82"
            data-text-color="white"
            data-class="tooltipPhoto"
            data-tip="<strong>¿Por qué necesitamos tu foto?</strong><br/>Todas tus rutinas son personalizadas y solamente para ti. Si alguien más toma tus clases, es posible que pueda sufrir algún tipo de lesión."
            data-html={true}
          />
          <ReactTooltip globalEventOff="click" />

          <label
            htmlFor="profile_pic"
            className="labelPhoto rounded-circle"
            style={{
              backgroundImage: `url(${previewPhotoProfile})`,
            }}
          ></label>
        </div>
        <div className="mx-auto my-3 my-md-auto">
        
          <div className="content-select-gender">
            <select style={gender != ""?null:{color:"var(--elevvaTextSubtitle)"}} className="form-control input-style-elevva p-relative" name="gender" value={gender} onChange={handleGeneroChange}>
              <option value="" >Género</option>
              <option value="M">Mujer</option>
              <option value="H">Hombre</option>
              <option value="N/A">N/A</option>
            </select>
          </div>

          <input
            style={inputs["birthday"] !=""?{cursor: "pointer" }:{ color: "var(--elevvaTextSubtitle)", cursor: "pointer" }}
            id={"birthday-date"}
            type={"text"}
            readOnly={true}
            name={"birthday"}
            value={
              inputs["birthday"] == ""
                ? elevvaT("date_birth")
                : inputs["birthday"]
            }
            className={"input-style-elevva w-100 my-2  "}
            placeholder={elevvaT("date_birth")}
            onClick={() => {
              // textBirthBackground.current.style = "display:block";
              textBirth.current.style =
                "display:flex;z-index:3 ;background-color: rgba(0, 0, 0, .8);top: 0;left: 0; right: 0;bottom: 0;align-items:center;justify-content: center";
            }}
          />

          {labelInput(
            "height",
            "input-style-elevva w-100",
            elevvaT("height"),
            "number"
          )}
        </div>
      </div></div>
    </SwiperSlide>
  );
  const Paso2 = (
    <SwiperSlide className="slider_form d-flex flex-column " style={{height:'100%',padding:'0 2%'}}>
     <div style={{height:'87vh',alignItems:'center',}}>
      <div className=" w-100 d-none d-md-flex" style={{height:'auto'}}>
        <div style={{ width: "100%", minWidth: "380px" }}>
          <h1 className="text-color-title m-auto">
            {elevvaT("complete")} <strong>{elevvaT("your_profile")}</strong>
          </h1>
        </div>
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
          }}
        >
          <hr />
        </div>
      </div>
      <h2 className="text-color-title-low leftTextoInterno">{elevvaT("tell_us")}</h2>

      <div
      className="d-flex flex-column justify-content-center longResponsive"  style={{height:'calc(100vh - 55%)'}}
        // style={{
        //   justifyContent: "center",
        //   maxWidth: "80%",
        //   margin: "auto",
        //   marginTop: "10%",
        // }}
      >
        <div className="">
          <div className="">
            {labelInput(
              "weight",
              "input-style-elevva w-100 my-2",
              elevvaT("weight"),
              "number"
            )}
          </div>
          <div className="content-select-gender">
            <select
              name="country"
              className="form-control input-style-elevva p-relative my-2"
              style={
                inputs["country"] == ""
                  ? { color: "var(--elevvaTextSubtitle)" }
                  : { color: "black" }
              }
              onChange={handleChange}
            >
              <option value="">{elevvaT("country")}</option>
              <option value="Colombia,CO">Colombia</option>
              {Paises &&
                Paises.map((timezone) => (
                  <option value={[timezone.name, timezone.isoCode]}>
                    {timezone.name}
                  </option>
                ))}
            </select>
            <i></i>

            
          </div>
        </div>

        <div>
          <div className="direccionubicar">
            {labelInput(
              "address",
              "input-style-elevva w-100 my-2",
              elevvaT("address"),
              "text"
            )}
            <div className="content-select-gender">
              <select
                name="city"
                className="form-control input-style-elevva p-relative my-2"
                onChange={handleChange}
                style={
                  inputs["city"] == ""
                    ? { color: "var(--elevvaTextSubtitle)" }
                    : { color: "black" }
                }
              >
                <option value="">{elevvaT("city")}</option>
                {inputs["country"] && inputs["country"].includes("Colombia")?<><option value={"Bogotá"}>Bogotá D.C</option>
                <option value={"Medellín"}>Medellín</option>
                <option value={"Cali"}>Cali</option>
                <hr style={{color:"var(--elevvaTexttitleLow)"}}/></>:null}
                {inputs["country"] &&
                  inputs["country"].split(",")[1] &&
                  ciudades
                  ? ciudades.filter(
                      (e) =>
                        e.countryCode ==
                        String(inputs["country"].split(",")[1])
                    )
                    .sort(function (a, b) {
                      var nameA = a.name.toLowerCase(); // ignore upper and lowercase
                      var nameB = b.name.toLowerCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      // names must be equal
                      return 0;
                    })
                    .map((timezone) => (
                      <option value={timezone.name}>{timezone.name}</option>
                    ))
                  : null}
              </select>
              <i></i>
            </div>
          </div>
        </div>
      </div></div>
    </SwiperSlide>
  );
  const Paso3 = (
   
    <SwiperSlide className="slider_form d-flex flex-column " style={{height:'100%',padding:'0 2%'}}>
    <div style={{height:'87vh',alignItems:'center',}}>
    <div className=" w-100 d-none d-md-flex" style={{height:'auto'}}>
        <div style={{ width: "100%", minWidth: "380px" }}>
          <h1 className="text-color-title m-auto">
            {elevvaT("complete")} <strong>{elevvaT("your_profile")}</strong>
          </h1>
        </div>
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
          }}
        >
          <hr />
        </div>
      </div>
      <h2 className="text-color-title-low leftTextoInterno">{elevvaT("text_termino")}</h2>     <br />
      <PerfectScrollbar  style={{alignItems:'center',height:'55%'}}>
      <div className="d-flex flex-column justify-content-center gap-4 px-2 ps-md-0 pe-md-4" style={{alignItems:'center'}}  >
    
        <div className=""
          style={{ color: "var(--elevvaTexttitleLow)" }}>
     
      
          <p>TERMINOS Y CONDICIONES USUARIOS / ENTRENADORES </p>
          <p>
            ELEVVA (nombre comercial de Sportcheck SAS) es una empresa de tecnología de la salud (HealtTech) que, por medio de desarrollos tecnológicos, busca simplificar y volver mucho más accesible el entrenamiento personalizado de alta calidad en Colombia y Latinoamérica. ELEVVA es más que un portal que une entrenadores con deportistas; exhibimos tipos de entrenamiento físico a deportistas, para que luego los contraten con entrenadores personalizados inscritos al portal. Ofrecemos a los entrenadores entrenamientos personalizados para cada uno de los deportistas inscritos en ELEVVA, ajustados a las tendencias del fitness, pero sobre todo a sus necesidades de acuerdo a la salud y condición física de cada deportista; gestionamos los pagos de los deportistas a los entrenadores cuando utilicen el Sitio como medio para prestar sus servicios a través de video llamadas; creamos un mundo del fitness con nuestro contenido, y promovemos la vida sana. </p>
          <p>Por favor lea estos Términos y Condiciones cuidadosamente, antes de acceder a este Sitio o utilizar los servicios del mismo. Éstos regulan el acceso y uso al sitio web, a la aplicación móvil para Android e iOS y las páginas de redes sociales controladas por ELEVVA, así como los servicios que ELEVVA presta a entrenadores y deportistas. Al registrarse como usuario o al visitar, navegar o usar los servicios de ELEVVA de cualquier manera, usted (como “usuario”) acepta y confirma estar sujeto a estos Términos de Servicio (“Términos”), que forman un acuerdo vinculante entre usted y ELEVVA. Si el usuario no acepta los Términos aquí establecidos, no deberá utilizar el Sitio ni sus servicios. En la medida en que ELEVVA se encuentra constantemente trabajando para mejorar la prestación de sus servicios, es posible que ELEVVA actualice y/o modifique estos Términos cada cierto tiempo. Cuando ello ocurra, ELEVVA enviará una notificación a los usuarios con el objetivo de que éstos puedan consultar las actualizaciones y/o modificaciones a los Términos de forma previa a su entrada en vigor. Si un usuario continúa usando el Sitio o nuestros servicios una vez que las actualizaciones o modificaciones hayan entrado en vigor, se entenderá que acepta dichas actualizaciones o modificaciones. Las palabras “Usted” o “Usuario” tal como se usan aquí, se refieren a todas las personas naturales o jurídicas o entidades de cualquier naturaleza que accedan a este Sitio por cualquier razón. </p>
        </div>
      </div>
      </PerfectScrollbar> </div>

    </SwiperSlide>
  );
  const Paso4 = (
    <SwiperSlide className="slider_form d-flex flex-column " style={{height:'100%',padding:'0 2%'}}>
    <div style={{height:'87vh',alignItems:'center',}}>
      <div className=" w-100 d-none d-md-flex" style={{height:'auto'}}>
        <div style={{ width: "100%", minWidth: "380px" }}>
          <h1 className="text-color-title m-auto">
            {elevvaT("complete")} <strong>{elevvaT("your_profile")}</strong>
          </h1>
        </div>
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
          }}
        >
          <hr />
        </div>
      </div>
      <h2 className="text-color-title-low leftTextoInterno"  style={{height:'auto'}}>
        {elevvaT("have_injuries_subtitle")}
      </h2>
      <div
      className="d-flex flex-column flex-md-row justify-content-stretch"  style={{height:'auto',alignItems:'center',}}>

<div className="d-md-none m-auto text-center" style={{width:'70%',lineHeight:'100%'}}>
          <span
          
            style={{ fontWeight: "300", textAlign: "center",color:'#9DACBD',fontSize:'0.9rem', }}
          >
            {elevvaT("tellus_onboarding")} {" "}{elevvaT("comment_body_onboarding")}
          </span></div>
        <Lesiones     sendDataLes={modificarLesiones}
          modalesDolores={modalesDolores}
          modalesDoloresBack={modalesDoloresBack}
          elevvaT={elevvaT}
          frontalView={frontalView}
          backView={backView}
        />
        <div className="m-auto">
          <span
            className="d-none d-md-block text-color-title-low "
            style={{ fontWeight: "500", textAlign: "center" }}
          >
            {elevvaT("tellus_onboarding")} <br />
            {elevvaT("comment_body_onboarding")}
          </span>
          <ButtonMob
            className="sizebuttonLarge marginButtonLesion"
            handleClick={() =>
              buttonChange == "Ver vista frontal"
                ? changeView(frontalView)
                : changeView(backView)
            }
            text={buttonChange}
          />
        </div>
      </div></div>
    </SwiperSlide>
  );
  const Paso5 = (
    <SwiperSlide className="slider_form d-flex flex-column " style={{height:'100%',padding:'0 2%'}}>
     <div style={{height:'87vh',alignItems:'center',}}>
       <div className=" w-100 d-none d-md-flex" style={{height:'auto'}}>
        <div style={{ width: "100%", minWidth: "380px" }}>
          <h1 className="text-color-title m-auto">
            {elevvaT("complete")} <strong>{elevvaT("your_profile")}</strong>
          </h1>
        </div>
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
          }}
        >
          <hr />
        </div>
      </div>
      <h2 className="text-color-title-low leftTextoInterno">{elevvaT("help_create_profile")}</h2>
      <PerfectScrollbar  style={{alignItems:'center',height:'60%'}}>
      <div className="d-flex flex-column justify-content-center gap-4" style={{alignItems:'center'}}  >
        {pregunta_one.map((value, index) => {
          return (
            <div className="d-flex  py-2 col-10 col-md-12">
      
            {value[0] ? (
          <div
            className="words_health col-6 col-md-4 my-auto"
          
          >
            {value[0]}
          </div>
        ) : null}  
              <div key={index} className="d-flex col-6 my-auto col-md-8 justify-content-start ">
                {renderSwitchh(
                  index,
                  value[0],
                  value[1],
                  value[2],
                  "col-8 text-left fw-bold lh-1 d-flex align-items-center words_health",
                  value[3]
                )}
              </div>

              <div className="my-auto py-3"></div>
            </div>
          );
        })}
        <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
            {elevvaT("accompaniment")}
          </div>
          <div className="  d-flex m-auto col-12 col-md-8 mt-1 mt-md-0">
            {" "}
            {question_2.map((objetivo, index) => {
              return (
                <div className=" formSteps py-2 mx-auto mx-md-0">
                  <div
                    key={index}
                    ref={objetivo[3]}
                    className="objetivoP"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      selectCircleImg(
                        objetivo[3],
                        objetivo[4],
                        index,
                        "objetivoP",
                        question_2,
                        true
                      )
                    }
                  >
                    <div
                      ref={objetivo[4]}
                      id="icon"
                      style={{ backgroundImage: `url(${objetivo[1]})` }}
                      className="rounded-circle  text-center"
                    ></div>
                    <div
                      id="text"
                      className="p-3  text-center "
                      style={{
                        color: "var(--elevvaTextTitle)",
                      }}
                    >
                      { ReactHtmlParser( objetivo[0])}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
         
        </div>
          
        {pregunta_three.map((value, index) => {
          return (
            <div className="d-flex  col-10 col-md-12">
      
      {value[0] ? (
    <div
  className="words_health col-6 col-md-4 my-auto"
    
    >
      {value[0]} </div>):null}
            <div className="d-flex col-6 col-md-8 justify-content-start my-auto">
                  {renderSwitchh(
                    index,
                    null,
                    value[1],
                    value[2],
                    " text-left fw-bold lh-1 d-flex align-items-center words_health",
                    value[3]
                  )}
               
              
            </div>
            </div>
          );
        })}
        {inputs["answer_3"] == true ? (
          <>
            <div className="d-flex flex-column flex-md-row py-1 col-10 col-md-12  borderHave">
              <div  className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start">
                {elevvaT("activity_physics")}
              </div>
              <div className="m-auto m-md-0 row col-md-5 pt-4 pt-md-0">
                {actividad_fisica.map((objetivo, index) => {
                  return (
                    <div className=" formSteps py-auto col-4 ">
                      <div
                        key={index}
                        ref={objetivo[3]}
                        className="objetivoP justify-objetivoP "
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          selectCircleImg(
                            objetivo[3],
                            objetivo[4],
                            index,
                            "objetivoP justify-objetivoP",
                            actividad_fisica,
                            false
                          )
                        }
                      >
                        <div
                          ref={objetivo[4]}
                          id="icon"
                          style={{ backgroundImage: `url(${objetivo[1]})` }}
                          className="rounded-circle  text-center"
                        ></div>
                        <div
                          id="text"
                          className="py-3  text-center "
                          style={{
                            color: "var(--elevvaTextTitle)",
                            margin: "0",
                            maxWidth: "80px",height:" auto"
                            
                           
                     
                          }}
                        >
                          {objetivo[0]}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
         

            <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
                {elevvaT("many_times_week")}
              </div>
              <div className=" d-flex  justify-content-around m-0 pt-4 pt-md-0">
                {" "}
                {actividad_time.map((objetivo, index, arr) => {
                  return (
                    <div
                      key={index}
                      ref={objetivo[1]}
                      className="objetivo_text_ve objetivo_text_small rounded-circle col-3 col-md-4 mx-2 position-relative"
                      style={{ cursor: "pointer", alignItems: "center" }}
                      onClick={() =>
                        selectCircleText(
                          objetivo[1],
                          index,
                          "col-3 col-md-4 objetivo_text_ve  rounded-circle mx-2",
                          arr,
                          true
                        )
                      }
                    >
                      <div
                        id="text"
                        className="rounded-circle d-flex align-items-center text-center position-absolute"
                      >
                        {objetivo[0]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
         
            <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
                {elevvaT("long_sessions")}
              </div>
              <div className=" d-flex  justify-content-around m-0 pt-4 pt-md-0 ">
                {" "}
                {actividad_sesiones.map((objetivo, index, arr) => {
                  return (
                    <div
                      key={index}
                      ref={objetivo[1]}
                      className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 justify-content-center position-relative"
                      style={{ cursor: "pointer", alignItems: "center" }}
                      onClick={() =>
                        selectCircleText(
                          objetivo[1],
                          index,
                          "col-4 objetivo_text_ve rounded-circle mx-2",
                          arr,
                          true
                        )
                      }
                    >
                      <div
                        id="text"
                        className="rounded-circle d-flex align-items-center text-center position-absolute"
                      >
                        {objetivo[0]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
         
            <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
                {elevvaT("intensity_level_sessions")}
              </div>
              <div className=" d-flex  justify-content-around m-0 pt-4 pt-md-0 ">
                {" "}
                {actividad_intensidad.map((objetivo, index, arr) => {
                  return (
                    <div
                      key={index}
                      ref={objetivo[1]}
                      className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 position-relative"
                      style={{ cursor: "pointer", alignItems: "center" }}
                      onClick={() =>
                        selectCircleText(
                          objetivo[1],
                          index,
                          "col-4 objetivo_text_ve rounded-circle mx-2",
                          arr,
                          true
                        )
                      }
                    >
                      <div
                        id="text"
                        className="rounded-circle d-flex align-items-center text-center position-absolute"
                      >
                        {objetivo[0]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
           
            <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
                {elevvaT("how_tired")}
              </div>
              <div className=" d-flex  justify-content-around m-0 pt-4 pt-md-0 ">
                {" "}
                {actividad_agotado.map((objetivo, index, arr) => {
                  return (
                    <div
                      key={index}
                      ref={objetivo[1]}
                      className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 position-relative"
                      style={{ cursor: "pointer", alignItems: "center" }}
                      onClick={() =>
                        selectCircleText(
                          objetivo[1],
                          index,
                          "col-4 objetivo_text_ve rounded-circle mx-2",
                          arr,
                          true
                        )
                      }
                    >
                      <div
                        id="text"
                        className="rounded-circle d-flex align-items-center text-center position-absolute"
                      >
                        {objetivo[0]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
         
            <div className="d-flex flex-column flex-md-row col-10 col-md-12  borderHave py-3">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
                {elevvaT("take_your_workouts")}
              </div>
              <div className=" d-flex  justify-content-around m-0 pt-4 pt-md-0 ">
                {" "}
                {franja_horaria.map((objetivo, index, arr) => {
                  return (
                    <div
                      key={index}
                      ref={objetivo[1]}
                      className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2 position-relative"
                      style={{ cursor: "pointer", alignItems: "center" }}
                      onClick={() =>
                        selectCircleText(
                          objetivo[1],
                          index,
                          "col-4 objetivo_text_ve rounded-circle mx-2",
                          arr,
                          true
                        )
                      }
                    >
                      <div
                        id="text"
                        className="rounded-circle d-flex align-items-center text-center position-absolute"
                      >
                        {objetivo[0]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
           
          </>
        ) : null}

        <>
        <div className="d-flex flex-column flex-md-row  pb-md-0 col-10 col-md-12  borderHave">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
              {elevvaT("transportation")}
            </div>
            <div className="d-flex m-auto col-12 col-md-8 mt-1 mt-md-0">
              {" "}
              {transportes.map((objetivo, index) => {
                return (
                  <div className=" formSteps py-2 m-auto m-md-0">
                    <div
                      key={index}
                      ref={objetivo[3]}
                      className="objetivoP"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        selectCircleImg(
                          objetivo[3],
                          objetivo[4],
                          index,
                          "objetivoP",
                          transportes,
                          true
                        )
                      }
                    >
                      <div
                        ref={objetivo[4]}
                        id="icon"
                        style={{ backgroundImage: `url(${objetivo[1]})` }}
                        className="rounded-circle  text-center mx-2"
                      ></div>
                      <div
                        id="text"
                        className=" text-center py-2 "
                        style={{
                          color: "var(--elevvaTextTitle)",
                          margin: "auto",
                         
                        }}
                      >
                        {ReactHtmlParser(objetivo[0])}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
         
          <div className="d-flex flex-column flex-md-row py-2 col-10 col-md-12  borderHave">
          <div
            className="words_health d-flex col-12 col-md-4 my-auto  justify-content-center justify-content-md-start"
          >
              {elevvaT("las_dayly")}
            </div>
            <div className="d-flex m-auto col-12 col-md-8 mt-1  justify-content-around m-md-0 ms-md-auto justify-content-md-start" style={{gap:"1rem"}}>
              {" "}
              {transportes_time.map((objetivo, index, arr) => {
                return (
                  <div
                    key={index}
                    ref={objetivo[1]}
                    className="objetivo_text_ve objetivo_text_small rounded-circle col-4 mx-2  mx-md-0 position-relative"
                    style={{ cursor: "pointer", alignItems: "center" }}
                    onClick={() =>
                      selectCircleText(
                        objetivo[1],
                        index,
                        "objetivo_text_ve  objetivo_text_small rounded-circle col-4 mx-2 mx-md-0",
                        arr,
                        true
                      )
                    }
                  >
                    <div
                      id="text"
                      className="rounded-circle d-flex align-items-center text-center position-absolute"
                    >
                      {objetivo[0]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      </div></PerfectScrollbar></div>
    </SwiperSlide>
  );
  const Paso6 = (
    <SwiperSlide className="slider_form d-flex flex-column " style={{height:'100%',padding:'0 2%'}}>
    <div style={{height:'87vh',alignItems:'center',}}>
      <div className=" w-100 d-none d-md-flex " style={{height:'auto'}}>
        <div style={{ width: "100%", minWidth: "380px" }}>
          <h1 className="text-color-title m-auto">
            {elevvaT("complete")} <strong>{elevvaT("your_profile")}</strong>
          </h1>
        </div>
        <div
          className="m-auto"
          style={{
            width: "100%",
            color: "var(--elevvaTexttitleLow)",
          }}
        >
          <hr />
        </div>
      </div>
      <h2 className="text-color-title-low leftTextoInterno">{elevvaT("help_create_profile")}</h2>
      <PerfectScrollbar  style={{alignItems:'center',height:'60%'}}>
      <div className="d-flex flex-column justify-content-center gap-4" style={{alignItems:'center'}}  >
        <div className="d-flex flex-column flex-md-row mt-2 mt-md-0 gap-2 my-md-3 justify-content-center col-12 col-md-9">
          {labelInput(
            "emergency_contact",
            "input-style-elevva col-11 m-auto col-md-6 ",
            elevvaT("Emergency_contact"),
            "text"
          )}
          {labelInput(
            "emergency_contact_phone",
            "input-style-elevva col-11 m-auto col-md-6",
            elevvaT("Telephone_contact"),
            "number"
          )}
        </div>
        {preguntas_salud.map((value, index, this_array) => {
          return (
            <div className="d-flex  col-11 col-md-12">
      
      {value[0] ? (
    <div
  className="words_health col-8 col-md-6 my-auto"
    
    >
      {value[0]} </div>):null}
            <div className="d-flex col-4 col-md-6 justify-content-start my-auto">
                {renderSwitchh(
                  index,
                  value[0],
                  value[1],
                  value[2],
                  "col-8 text-left fw-bold lh-1 d-flex align-items-center ",
                  value[3]
                )}
              </div>
              {/* {value[1] === "answer_7" && value[2] && (
                <div className="col-12">
                  <MDBTextArea
                    label="Otra razón"
                    labelStyle={{ color: "rgb(123, 134, 250)" }}
                    id="textAreaExample"
                    row={4}
                    onChange={(event) =>
                      setInputs((val) => ({ ...val, "answer7Text": event.target.value }))
                    }
                    // onChange={(event) => setAnswer7Text(event.target.value)}
                    // value={answer7Text}
                    // placeholder={placeholderText}
                    value={inputs[value]}
                    placeholder={placeholderText}
                  />
                </div>
              )} */}
            </div>

          );
        })}

      </div></PerfectScrollbar>
      </div>
  
    </SwiperSlide>
  );

  //console.log(gender);
  const completeProfile = () => {
    //console.log(gender, "imagen");
// alert('llegari')
 
      // if (preguntasMedicas === true) {
      //   return (salvedadMedico.current.style.display = "flex");
      // } else {
        sendData();
      // }
    
    
  };

  const sendData = () => {
    // setModal(
    //   <ModalInfo
    //     error="none"
    //     handleClick={hideModal}
    //     title="Procesando"
    //     text={<Loadingspinner customStyle="grow" size="5rem" />}
    //     classesMsg=""
    //   />
    // );

    // const imgName = photoProfile;

    sendDataFinal();
  };
  const sendDataFinal = async(imgName) => {


      setModificarLesiones(false);
    var tipoActividadSel = [];
    actividadFisica.filter(function (value, index, arr) {
      if (value === true) {
        let new_index = index + 1;

        tipoActividadSel.push(new_index);
      }
      return true;
    });

    var formData = new FormData();
    var formData1 = new FormData();
    var formData2 = new FormData();

    formData.append("gender", gender == "M" ? 2 : gender == "H" ? 1 : 9);
    formData.append(
      "birthday",
      moment(inputs["birthday"], "DD/MM/YYYY").format("YYYY-MM-DD"));
    formData.append("height", inputs["height"]);
    formData.append("weight", inputs["weight"]);
    formData.append('profile_pic', inputs["profile_pic"]); //Fotito

    if (inputs["answer_3"]) {
      tipoActividadSel.forEach((item) =>
        formData2.append("preference_activity", item)
      );
      formData2.append("physical_active", inputs["answer_3"] ? true : false);
      formData2.append("transportation",medioTransporte + 1);
      formData2.append("transportation_time", tiempoTransporte + 1);
      formData2.append("preference_activity_per_week", tiempoActividad);
      formData2.append("preference_intensity", intensidadActividad * 10);

      formData2.append("preference_session_time", sesionesActividad);
      formData2.append(
        "preference_fatigue_level",
        (agotamientoActividad + 1) * 10
      );
      formData2.append("preference_training_time", (franjaActividad + 1) * 10);
      formData2.append("terms", true);
    } else {
      formData2.append("physical_active", inputs["answer_3"] ? true : false);
      formData2.append("transportation",medioTransporte + 1);
      formData2.append("transportation_time",tiempoTransporte + 1);
      formData2.append("terms", true);
    }
    // Preguntas medicas
   
    formData.append("health_safe_heart", inputs["answer_8"] === "" ? "false" : inputs["answer_8"]);
    formData.append("health_safe_chest", inputs["answer_2"] === "" ? "false" : inputs["answer_2"]);
    formData.append("health_safe_chest_last", inputs["answer_9"] === "" ? "false" : inputs["answer_9"]);
    formData.append("health_safe_balance", inputs["answer_4"] === "" ? "false" : inputs["answer_4"]);
    formData.append("health_safe_bones", inputs["answer_5"] === "" ? "false" : inputs["answer_5"]);
    formData.append("health_safe_medicated", inputs["answer_6"] === "" ? "false" : inputs["answer_6"]);
    formData.append("health_safe_other", inputs["answer_7"] === "" ? "false" : inputs["answer_7"]);
    //console.log(  inputs["answer7Text"]);
    formData.append("health_safe_other_text", inputs["answer7Text"]);
    formData.append("terms", true);

    //Número de contacto
    formData.append("emergency_contact", inputs["emergency_contact"] === "" ? "" : inputs["emergency_contact"]);
    formData.append("emergency_contact_phone", inputs["emergency_contact_phone"] === "" ? "" : inputs["emergency_contact_phone"]);

    formData1.append("address", inputs["address"]);
    formData1.append("city", inputs["city"]);
    formData1.append("country", inputs["country"]);

   

   await instance
      .patch(`${NuevaAxiosURL}users/`, formData1)
      .then((res) => {
       
        setModificarLesiones(true);
       
       })
      .catch((err) => {
        setModal(
          <CustomModalError
            show={true}
            handleClose={hideModal}
            title="Algo salio mal"
            classesMsg="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
          />
        );
      });
      await   instance
      .post(`customers/onboarding/`, formData2)
      .then((res) => {
        if (res.data) {
          setModificarLesiones(true);
       
       
        }
      })
      .catch((err) => {
        console.log(err.response);
        setModal(
          <CustomModalError
            show={true}
            handleClose={hideModal}
            title="Algo salio mal"
            classesMsg="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
          />
        );
      });
      await   instance
      .patch(`customers/`, formData)
      .then((res) => {
        if (res.data) {
         
          
      
           // Enviar los cambios en las lesiones
       navigate(`/Book/${localStorage.trainingBook}`)
        }
      })
      .catch((err) => {
        console.log(err.response);
        setModal(
          <CustomModalError
            show={true}
            handleClose={hideModal}
            // title="Algo salio mal"
            //classesMsg="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
          />
        );
      });
  };

  // const renderFootBar = (
  //   <>
  //     <nav className="footerStep6 ubicarfooter" onClick={completeProfile}>
  //       <div className="lh-1 siguiente">
  //         Empieza a reservar{" "}
  //         <ModalInfo
  //           // icon={faCheckCircle}
  //           size="2x"
  //           color="#2A9DA5"
  //           className="ms-2"
  //         />
  //       </div>
  //     </nav>
  //   </>
  // );

  function nextSlider() {
    if (checkCurrentForm()) {
      setCanNext(false);
      theSwipper.slideNext();

      if (currentSlider == 6) {
      
        // if (preguntasMedicas == true) {
        //   salvedadMedico.current.style.display = "flex";
        // }else{

          setModal(
            <ModalInfo
              error="none"
              iconoWidth={"calc(3.0rem + 0.5vmin)"}
                  textSizeUp={"1.0rem"}
              textButtonAction="Finalizar"
              handleCancellcargue={hideModal}
               handleReservar={completeProfile}
            
            
              icono={icoPositivo}
              classcorrecta={"recuerdaTitle"}
              title={` Tu perfil ha sido <br/> creado con éxito <br/>`}
              classesMsg="colorText_Modal"
              text={laptop575.matches?
                `<br/>Empieza tu viaje a una vida  saludable y disminuye  <br/>  los  riesgos de la  hipertensión fácil   y sencillo <br/> con elevva.<br/> <br/>`:`<br/>Empieza tu viaje a una vida  saludable y disminuye  los  riesgos de la  hipertensión fácil  y sencillo  con elevva.<br/> `
              }
            />
          );
       
        
      }
    }
  }

  function prevSlider() {
    theSwipper.slidePrev();
  }
  const fechaActual = new Date();

  return (
    <>
      <ModalInfo
        handleClick={() => {
          salvedadMedico.current.style.display = "none";
          sendData();
        }}
        error="false"
        title="Antes de empezar a hacer actividad física"
        ref={salvedadMedico}
        display="none"
        text="Te recomendamos contar con aval médico para empezar a entrenar con nosotros."
      />
      <div className="d-flex col-12 CompletarPerfil">
        <div className="d-none d-md-block col-md-1 background-left-onboarding ">
          <div className="m-auto w-100 ">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo_elevva_alta.png"}
              className="logo-center-onboarding"
            />
          </div>
        </div>
        <div className="col-12 col-md-11 background-right-onboarding " style={{height:"100%"}}>
          <div className="d-none d-md-flex inside-content-number">{`0${numberStep}`}</div>
          <div className="inside-content-imgLogo d-none d-md-flex">
            <img
              className="img-logo-back-onboarding"
              src={process.env.PUBLIC_URL + "/assets/img/logooutback.png"}
            />
          </div>
          <div className="w-100 d-md-none m-auto d-flex justify-content-center " style={{position:'relative',height:'7%', background:'#F1F6F9',alignItems:'center'}}> <img
             style={{width:'20%' }} 
              src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
            /></div>

<div className="d-md-none my-auto py-1" ><h1 className="text-color-title text-center m-auto " style={{height:'auto'}}>
            {elevvaT("complete")} <strong>{elevvaT("your_profile")}</strong>
          </h1></div>
          <div className="stepsBullets bulletsOnbor" ></div>
         <div className="d-md-none m-auto" style={{height:'auto',width:'50%'}}> <hr /></div>
          <div className="d-flex flex-column flex-md-row contain-info-onboarding" >
            <img
              className="d-none d-md-flex  col-md-3"
              src={process.env.PUBLIC_URL + "/assets/img/onboarding-girl.jpg"}
            />
            <div className="d-none d-md-flex col-md-1" ></div>
            <div className="col-12 col-md-9" id={"swiper_onboarding_customer"} >
              {/* <button onClick={sendDataFinal}>api</button> */}
              <Swiper style={{height:"100%"}}
                spaceBetween={100}
                slidesPerView={1}
              
                allowTouchMove={false}
                pagination={{
                  el: ".stepsBullets",
                  type: "custom",
                  renderCustom: function (swiper, current, total) {
                    setnumberStep(current);
                    if (theSwipper == null) {
                      setTheSwipper(swiper);
                    }
                    setCurrentSlider(current);
                    return `<div class='swiper-pagination-bullet Bullet position-relative  ${current >= 0 ? "active " : ""
                      }'><div class='centraNumberSteps'>1</div></div>
                                                        <div class='swipersep  ${current >= 1
                        ? "active"
                        : ""
                      }'  ></div>
                                                        <div class='swiper-pagination-bullet Bullet position-relative  ${current >= 2
                        ? "active"
                        : ""
                      }'><div class='centraNumberSteps'>2</div></div>
                                                        <div class='swipersep  ${current > 2
                        ? "active"
                        : "noactive"
                      }'  ></div>
                                                        <div class='swiper-pagination-bullet Bullet position-relative  ${current >= 3
                        ? "active"
                        : ""
                      }'><div class='centraNumberSteps'>3</div></div>
                                                        <div class='swipersep  ${current > 3
                        ? "active"
                        : "noactive"
                      }'  ></div>
                                                        <div class='swiper-pagination-bullet Bullet position-relative ${current >= 4
                        ? "active"
                        : ""
                      }'><div class='centraNumberSteps'>4</div></div>
                                                        <div class='swipersep  ${current > 4
                        ? "active"
                        : "noactive"
                      }'  ></div>
                                                        <div class='swiper-pagination-bullet Bullet position-relative  ${current >= 5
                        ? "active"
                        : ""
                      }'><div class='centraNumberSteps'>5</div></div>
                                                        <div class='swipersep  ${current > 5
                        ? "active"
                        : ""
                      }'  ></div> <div class='swiper-pagination-bullet Bullet position-relative  ${current >= 6 ? "active" : ""
                      }'><div class='centraNumberSteps'>6</div></div>
                                                      
                                                    `;
                  },
                  clickable: false,
                }}
              >
                <>
                {Paso1}
                 
                  {Paso2}
                 {Paso4}
                   {Paso5} 
               {Paso3}
                    {Paso6}
                </>
              </Swiper>
             
              {confirmado}
            </div>
          
          </div>
          {Modal}
          <CompletarFooter
            // current={currentSlider}
            // nextSlider={nextSlider}
            // prevSlider={prevSlider}
            // canNext={canNext}
            cargarname={cargarname}
            contactoTel={contactoTel}
            numberStep={numberStep}
            current={currentSlider}
            completeProfile={completeProfile}
            nextSlider={nextSlider}
            prevSlider={prevSlider}
            chekeaprofile={chekeaprofile}
            canNext={canNext}
          />
          <div className="d-none d-md-flex inside-content-full"></div>
        </div>
        {modalesDolores && modalesDolores.current}
        {modalesDoloresBack.current}{" "}
        <div 
          ref={textBirth}
          className="position-absolute"
          style={{
          display: "none",
        }}
        // onClick={() => (textBirth.current.style = "display:none")}
        >
          {" "}
          <Calendar 
        maxDate={new Date(fechaActual.setFullYear(fechaActual.getFullYear() - 18))}
            color={"var(--elevva)"}
            nextButton="next month button"
            fixedHeight={true}
            locale={locales["es"]}
            onChange={(value) => {
              setInputs((val) => ({
                ...val,
                ["birthday"]: moment(value, "YYYY-MM-DD").format("L"),
              }));
              textBirth.current.style = " display: none";
            }}
          />

        </div>
        <ModalInfoCargarImagenes
          handleClickCamera={lookCamera}
          classes={clasemodalBotons}
          title={`${elevvaT("Choose_your")} <strong>${elevvaT(
            "profile_picture"
          )}</strong>`}
        />
        <ModalInfoAvatares
          classes={clasemodalAvatars}
          title={`${elevvaT("Select_avatar")} <strong>${elevvaT(
            "avatar"
          )}</strong>`}
        />
        {webcamActive ? (
          <div className="modalInfo">
            <div
              className="position-absolute "
              style={{
                top: "50%",
                transform: "translate(-50%, -50%)",
                left: " 50%",
              }}
            >
              <Webcam
                audio={false}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              >
                {({ getScreenshot }) => (
                  <>
                    <div>{getScreenshot}</div>
                    <button
                      className="btnMOB  "
                      style={{
                        position: "absolute",
                        bottom: "0%",
                        fontSize: "1rem",

                        transform: "translateX(-50%)",
                        left: " 50%",
                        color: "var(--white)",
                        background: "var(--elevvabutonLogin_User)",
                      }}
                      onClick={() => {
                        const imageSrc = getScreenshot();
                        var blob = dataURItoBlob(imageSrc);
                        var imagen_firma = new File([blob], "photoUser.jpg", {
                          type: "image/jpg",
                        });
                        setInputs((val) => ({
                          ...val,
                          ["profile_pic"]: imagen_firma,
                        }));
                        setPreviewPhotoProfile(URL.createObjectURL(imagen_firma));
                        setwebcamActive(false);
                        setclasemodalBotons("d-none");
                      }}
                    >
                      Capturar foto
                    </button>
                    <div
                      className="position-absolute m-4"
                      style={{
                        color: "var(--white)",
                        top: "0",
                        right: "0",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setwebcamActive(false);
                        setclasemodalBotons("d-none");
                      }}
                    >
                      {" "}
                      <i className="bi bi-x-circle-fill"></i>
                    </div>
                  </>
                )}
              </Webcam>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
