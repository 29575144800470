import { useEffect } from "react";
import React, { useRef } from "react";
import { Categorias, Paso2 } from "../TutorialReserva/stepsHelp";
import { useNavigate,useParams  } from "react-router-dom";
import { instance } from "../../utils/utils";
import { useState } from "react";
import { ButtonTutorial, ButtonTutorialReserva } from "../Dashboard/helpResponsive";

const TrainingId = (props) => {
  const navigate = useNavigate();
  const user= props.user;
  const { id } = useParams();
  const [infoTraining, setinfoTraining] = useState(null);
  useEffect(() => {
    instance
      .get(`trainings/${id}`)
      .then((response) => {
        setinfoTraining(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  function handleClick(e){
    
    localStorage.setItem("trainingVector", JSON.stringify(e));
    return navigate(`/reservas`)
  
   }


  return (<>
    <div
    className="d-none d-md-block col-10" style={{height:'100%'}}
  >
    <div
      className=" position-relative "
      style={{
        background: "rgba(0,0,0,.7)",
        height: "100vh",

        padding: "2% 3% 2% 2%",
      }}
    >
      <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
      <Categorias  categoryClick={handleClick}/>
        <Paso2 infoTraining={infoTraining} tutorial={false}/>
        <ButtonTutorial handleClick={()=>navigate(`/TutorialReserva/${id}`)}/>
          
            </div>
          </div>
        </div>
        <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <Paso2 infoTraining={infoTraining} tutorial={false}/>

        <ButtonTutorialReserva handleClick={()=>navigate(`/TutorialResponsive/${id}`)}/>
        
        </div>
        
        </>)

}
export default TrainingId;