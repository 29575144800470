
import React from "react";
import {  useLocation } from "react-router-dom";
import { checkPathNavFoot, checkPathNavFootMobile, instance, laptop575, perlo } from "../utils/utils";
import "./NavBar.css";
import { ComponentIcons } from "../components/Dashboard/helpResponsive";
import { Allitems } from "./utilNav";
import { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useState } from "react";
;

const NavBar = (props) => {
  const typeCustomer = props.typeCustomer;
  const [notifications, setnotifications] = useState(null);

  const location = useLocation();
  let intervalNotifications = null;
  useEffect(() => {

    intervalNotifications = setInterval(async () => {
      if (localStorage.profile_pic) {
        if (
          !window.location.pathname.includes("/clase/") &&
          !window.location.pathname.includes("/clase/") &&
          !window.location.pathname.includes("/clasestream/") &&
          !window.location.pathname.includes("/ingreso") &&
          !window.location.pathname.includes("/registrosagora") &&
          !window.location.pathname.includes("/ejercicios/Buscar") && 
          !window.location.pathname.includes("/recuperar") && 
          !window.location.pathname.includes("/selectMembership/Usuario") &&
          window.location.pathname !== `/`
        )
      {  instance
        .get("app/notifications/unread")
        .then((res) => res.data)
        .then((data) => {
          setnotifications(data)
        
        })
        .catch((err) => console.error(err));}
      }
    }, 15000);
    return () => {
      clearInterval(intervalNotifications);
    };
  }, []);

  return (
   laptop575.matches?
     <>{/* web */}
 
   {checkPathNavFoot(location.pathname, typeCustomer) ? null : <div className="col-2 navbarscroll d-none d-md-block">
     <div
       className=" w-100 mx-auto py-4  text-center"
       style={{ height: "15%" }}
     >
       <img
         alt="logo_alta_black"
         style={{ padding: "5% 20%", width: "80%" }}
         src={process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
       />
     </div>
     <Allitems  typeCustomer={typeCustomer} />
   </div>}</>:<>
      {/* mobile */}
      {checkPathNavFootMobile(location.pathname, typeCustomer) ? null :    <nav
       id={"navMobile"}
        style={location.pathname.includes("/perfil")?{ backgroundColor: "transparent"}:{ backgroundColor: "#F1F6F9", position: "sticky" }}
        className="navbar fixed-top d-md-none"
      >
        <div className="container-fluid col-11" style={{ flexWrap: "initial" }}>
          <button  id="panelDesplegable" style={{color:"transparent"}}
            className="navbar-toggler col-2"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar" 
          >
            <span className={location.pathname.includes("/perfil")?"navbar-toggler-icon filter":"navbar-toggler-icon"}></span>
          </button>
          <div
            className="w-auto m-auto d-flex justify-content-center col-8"
            style={{
              position: "relative",
              height: "7%",
              background: location.pathname.includes("/perfil")?"transparent":"#F1F6F9",
              alignItems: "center",
            }}
          >
            {" "}
            <img
              style={{ width: "15vmin" }}
              src={location.pathname.includes("/perfil")?process.env.PUBLIC_URL + "/assets/img/logo_alta_white.png":process.env.PUBLIC_URL + "/assets/img/logo_alta_black.png"}
            />
          </div>
          <ComponentIcons notificationsq={notifications}  pointIcon={"2vmin"}
            classPadre={"d-flex flex-row col-2"}
            fontSizeicons={"6vmin"}
            leftIcon={"90%"}
            mxIcons={"px-2"}
          />

          <div
            className="offcanvas offcanvas-end w-100"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
            <Allitems typeCustomer={typeCustomer}/>
            </div>
          </div>
        </div>
      </nav>}

      
    </>
   
  );
};
export default NavBar;
