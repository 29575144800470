import { useEffect } from "react";
import React, { useRef } from "react";
import {
  Categorias,
  
  Paso6,
  
} from "../TutorialReserva/stepsHelp";
import { useNavigate, useParams } from "react-router-dom";
import { instance, ModalInfo } from "../../utils/utils";
import { useState } from "react";
import "moment/locale/es";

const SelectTrainer = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalInfo, setModalInfo] = useState(null);
  const [infoTraining, setinfoTraining] = useState(null);
  const [trainerGlobal, setTrainerGlobal] = useState(null);
  const [MembershipInfo, setMembershipInfo] = useState(null);
  useEffect(() => {
    instance
      .get(`trainings/${id}`)
      .then((response) => {
        setinfoTraining(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
   
    if (localStorage.membership) {
      instance
        .get(`memberships/${localStorage.membership}/detail`)
        .then((response) => {
          setMembershipInfo(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function handleClick(e){
    localStorage.setItem("trainingVector", JSON.stringify(e));
    return navigate(`/reservas`)
  
   }

  const hideModal = () => {
    setModalInfo(null);
  };

  function activarTrainer(e){
    let idTrainer = e;
    if (MembershipInfo &&MembershipInfo.rules.includes(5) && idTrainer > 1) {
      setModalInfo(
        <ModalInfo
          error="false"
          textButtonAction="Mejorar plan"
          handleCancellcargue={hideModal}
          handleReservar={() => {
            navigate(`#`);
          }}
          iconoWidth={"1.5rem"}
          textSizeUp={"1.2rem"}
          text={
            "Tu plan actual no permite seleccionar profesor"
          }
        />
      );
    } else {
      if(idTrainer==1){
        localStorage.removeItem('trainerSelected')
      }else{

      localStorage.trainerSelected =idTrainer
      }
     navigate(`/selectSchedule/${id}`)
    }
  }
 
  return (<>
      {modalInfo}
    <div className="d-none d-md-block  col-10 " style={{ height: "100%" }}>
      <div
        className=" position-relative "
        style={{
          background: "rgba(0,0,0,.7)",
          height: "100vh",
          padding: "2% 3% 2% 2%",
        }}
      >
        <div className="d-flex col-12" style={{ gap: "2%", height: "100%" }}>
         <Categorias categoryClick={handleClick} />
           <Paso6  profesores={infoTraining && infoTraining.trainers}
            infoTraining={infoTraining}
            imageSuperior={infoTraining && infoTraining.main_picture}
            handlerClick={activarTrainer}
          />
        </div>
      </div>
    </div>
    <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <Paso6 infoTraining={infoTraining}  profesores={infoTraining && infoTraining.trainers}
            imageSuperior={infoTraining && infoTraining.main_picture}  
            handlerClick={activarTrainer}/>
        
        </div>
    </>
  );
};
export default SelectTrainer;
