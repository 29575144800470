import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation  } from "swiper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { ButtonMob, instance, laptop575, NuevaAxiosURL, urlImages } from "../../utils/utils";






export function Allvideos(props) {
  const spaceBetween = props.spaceBetween || 0;
  const ancho = props.ancho || '22vmin'
  const arrowDisplay = props.arrowDisplay || 'd-flex'
  const alto = props.alto || '34vmin'
  const grow = props.grow || 'growing'
  const widthSwiper = props.widthSwiper || "85vmin";
  const stepIndex = props.stepIndex;
  const groupTraining = props.groupTraining;
  const sliderWidth = props.sliderWidth || "90%";
  const cardWidth = props.cardWidth || "calc(12rem + 2.5vmin)";
  const classSwiper = props.classSwiper || "py-5";
  const classMarginCard = props.classSwiper || "my-3";
  const intensity=props.intensity || null;
  const rightArrow=props.rightArrow || "2.5%"
  //const trainingsId = props.trainings
  //const images = props.videos;
  function handleClick(event) {
    props.buttonClick(event);
  }


  // Filtrar entrenador si es para clases grupales es true
  let filterEntrenador = props.videos && props.videos.filter(item => item.time?item.group == groupTraining:item );
if(groupTraining ==false && intensity){

  filterEntrenador= filterEntrenador.filter(item=>item.intensity ==intensity)
}
 

  useEffect(() => {
    var principal = document.querySelector(".swiper-wrapper")?.children;

    if(principal && principal.length>0){
    for (let i = 0; i < principal.length; i++) {
      // le asiganos un evento mouseover
      principal[i].addEventListener("mouseover", (e) => {
        if (e.target.id == i) {
          principal[i].children[0].children[0].classList.add(grow);
        }
      });
      principal[i].addEventListener("mouseout", (e) => {
        if (e.target.id == i) {
          principal[i].children[0].children[0].classList.remove(grow);
        }
      });
    }
  }
  });

  const id = props.id;
  SwiperCore.use([Pagination, Autoplay, Navigation]);
  const render_img_desk =
    filterEntrenador && filterEntrenador.length>0 &&
    filterEntrenador.map((training, index) => {
      return (
        <SwiperSlide 
          key={index}
          value={training.id}
          className={props.className}
          style={{ width: ancho, height: alto }}
        >
          <div className={index==1 && stepIndex===3 ?`growingTutorial d-flex position-relative imagenreserva`:`d-flex position-relative imagenreserva`}>
            <div
              onClick={() => handleClick(training.id)}
              className="content position-relative"
              style={{ width:cardWidth, }}
            >
              {" "}
              <img
                id={index}
                className=""
                src={training.icon.includes(urlImages)? training.cover_picture:urlImages +training.cover_picture}
                style={{ borderRadius: "20px", cursor: "pointer" }}
                width={sliderWidth}
                height="100%"
              />
              <div
                className="position-absolute w-100"
                style={{
                  top: "0",
                  color: "#394F65",
                  padding: "8% 10%",
                  maxWidth: "80%",
                  fontSize:"0.8rem"
                }}
              >
              
                {"Entrenamiento"}
                <br />
                <div
                  className="fw-bold name_TrainingCard"
                
                >
                  {training.name}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });

if(filterEntrenador &&filterEntrenador.length ==0){
  return <div className="d-flex justify-content-center flex-column mx-5 mx-md-auto my-auto text-center" style={{height:alto}}>No se encontraron resultados con esta búsqueda o categoría</div>
}
 else return (
    <div className="position-relative reconocer">
      
      <div className={`d-flex swiper-pagination_p  ${classMarginCard}`}>
        {id == "numbers" ? (
          <>
            <div
              className={`${arrowDisplay} position-absolute`}
              style={{ top: "50%", left: "2.5%" }}
            >
              <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            </div>
            <div
          className={`${arrowDisplay} position-absolute`}
              style={{ top: "50%", right: rightArrow }}
            >
              <div className={`swiper-button-next-${id} tonin-next`}></div>
            </div>
          </>
        ) : (
          <div className={"d-flex"}>
            <div className={`swiper-button-prev-${id} tonin-prev`}></div>
            <div className={`swiper-button-next-${id} tonin-next`}></div>
          </div>
        )}
      </div>
     <div className="d-md-none px-5 py-4" style={{lineHeight:"140%"}}>
     <span style={{color:"#394F65",fontSize:"calc(1.5rem + 0.4vw)"}}>{groupTraining?"Clases":"Intensidad"}</span><br/>
      <span style={{color:"#394F65",fontSize:"calc(1.5rem + 0.4vw)",fontWeight:"500"}}>
      {groupTraining?"Grupales":intensity ==10?"Baja":intensity ==20?"Media":"Alta"}</span> </div>
      <Swiper 
       id={props.idJoyride || 'swiperIde'}
        style={{ margin: "auto ", width: widthSwiper }}
        className={`slidersNetflixReservas ${classSwiper}`}
        navigation={{
          nextEl: `.swiper-button-next-${id}`,
          prevEl: `.swiper-button-prev-${id}`,
        }}
        modules={[Navigation  ]}
        slidesPerView="auto"
        spaceBetween={spaceBetween}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        slidesPerGroup={1}
        
        slidesPerGroupSkip={1}
      >
      
    {render_img_desk}
        
      </Swiper>
    </div>
  );
}
export const Allcategory = (props) => {
  //const trainingsId = props.trainingsId
  function handleClick(event) {
    props.handleClick(event);
  }

  return (
    <div
      className="col-9"
      style={{
        background: "white",
        height: "100%",
        padding: "2% 0",
        borderRadius: "20px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        className="d-flex"
        style={{
          height: "20%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {" "}
        <div style={{ padding: "0 5%", lineHeight: "130%" }}>
          <span
            style={{
              color: "var(--elevvaTextTitle)",
              fontSize: "2.5vmin",
            }}
          >
            {" "}
            Selecciona <br /> <strong> tu disciplina de interés</strong>
          </span>
          <div className="d-flex" style={{ margin: "1vmin 0" }}>
            <div className="me-4 my-auto">
              {" "}
              <input
                style={{ padding: "5% 4%", height: "5vmin" }}
                placeholder="Busca tu disciplina"
                className="redondo-butoon"
              ></input>
            </div>
            <div className="mx-1">
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2% 3%",
                  height: "5vmin",
                  width: "10vmin",
                }}
              >
                <img
                  style={{
                    paddingTop: "5%",
                    maxWidth: "4vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/filter_button.png"}
                ></img>
                <span style={{ fontSize: "1.3vmin" }}>Filtrar</span>
              </button>
            </div>
            <div className="mx-1">
              <button
                className="redondo-butoon d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2%",
                  height: "5vmin",
                  width: "10vmin",
                }}
              >
                <img
                  style={{
                    paddingTop: "5%",
                    maxWidth: "3vmin",
                  }}
                  src={process.env.PUBLIC_URL + "/assets/img/order_button.png"}
                ></img>
                <span style={{ fontSize: "1.3vmin" }}>Ordenar</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex"
        style={{
          height: "80%",
          background: "var(--clasesPending)",
          justifyContentc: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ padding: "4% 3% 0% 4%", lineHeight: "220%" }}>
          {" "}
          <div
            style={{
              color: "#394F65",
              fontSize: "4vmin",
            }}
          >
            <span
              style={{
                fontWeight: "300",
                color: "#394F65",
                fontSize: "4vmin",
              }}
            >
              Reserva tu primer{" "}
            </span>
            <br />
            <strong> clase grupal en vivo gratis</strong>
          </div>
        </div>
        <br />
        <Allvideos
          id="numbers"
          buttonClick={(e) => handleClick(e)}
          textTitleOne={"Reserva tu primer "}
          textTitleTwo={"clase grupal en vivo gratis"}
          //trainingsId={trainingsId}
          videos={
            props.AllVideos &&
            props.AllVideos.filter((e) => e.category == "Entrenamiento")
          }
          className="videosreserva"
        />
      </div>
    </div>
  );
};
export const ReservaCustomer = (props) => {
  const navigate = useNavigate();
  const selectReserva = props.AllVideos.filter((e) => e.id == props.id);
  

  // Filtrar id
  const id = selectReserva.map((e) => {
    return e.id
  })


  // credits
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const { trainers, trainingsIds } = selectReserva[0];

  const handleTrainerChange = (trainer) => {
    setSelectedTrainer(trainer);
  };

  const [tomorrow, setTomorrow] = useState('');

  //Dias +1
  useEffect(() => {
    const today = new Date();
    const tomorrowDate = new Date(today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const month = monthNames[tomorrowDate.getMonth()];
    const day = tomorrowDate.getDate();

    setTomorrow(`${month} ${day}`);
  }, []);

  //console.log(tomorrow); 

  // Set hora
  const defaultHours = [
    "9:00 / 10:00 am",
    "10:00 / 11:00 am",
    "11:00 / 12:00 pm",
    "12:00 / 1:00 pm",
    "1:00 / 2:00 pm",
    "2:00 / 3:00 pm",
    "3:00 / 4:00 pm",
    "4:00 / 5:00 pm",
    "5:00 / 6:00 pm",
    "6:00 / 7:00 pm"
  ];

  const generateHoursArray = () => {
    const hoursArray = [];
    for (let i = 9; i <= 12; i++) {
      let hour;
      let ampm;
      if (i === 12) {
        hour = 12;
        ampm = "pm";
      } else if (i > 12) {
        hour = i - 12;
        ampm = "pm";
      } else {
        hour = i;
        ampm = "am";
      }
      hoursArray.push(`${hour}:00 ${ampm}`);
    }
    return hoursArray;
  }


  const [options, setoptions] = useState("inicio");
  function handleClick() {
    props.handleClick(null, true);
  }
  function optionsClick() {
    setoptions("seleccionar");
  }
  const reservas = [
    {
      // prof: "Hugo Ortiz",
      // cupos: "10",
      // dia: "Agosto 17",
      hora: "9:00/10:00 am",
    },
    {
      // prof: "Hugo Ortiz",
      // cupos: "10",
      // dia: "Agosto 17",
      hora: "11:00/12:00 am",
    },
    {
      // prof: "Hugo Ortiz",
      // cupos: "10",
      // dia: "Agosto 17",
      hora: "1:00/2:00 am",
    },
  ];

  // Clase más profe {id}
  // {id del entrenmaiento}
  //https://api.staging.elevva.com.co/api/v1/booking/group/${clase}/schedule/
  const [trainingsId, setTrainingsId] = useState([]);
  useEffect(() => {
    instance
      .get(`${NuevaAxiosURL}booking/group/${id[0]}/schedule`)
      .then((response) => {
        setTrainingsId(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  //console.log(trainingsId);

  return (
    <div
      className="col-9 d-flex"
      style={{
        background: "white",
        height: "100%",
        padding: "2%",
        justifyContent: "center",
        borderRadius: "20px",
      }}
    >
      <div
        className="col-6 d-flex"
        style={{ position: "relative", justifyContent: "center" }}
      >
        {selectReserva.map((training) => (
          <img
            key={training.id}
            src={
              options === "final"
                ? process.env.PUBLIC_URL + "/img/mascarareservafinal.png"
                : options === "reservafinal"
                  ? process.env.PUBLIC_URL + "/img/finalreserva.png"
                  // : process.env.PUBLIC_URL + "/img/marcaraEjemplo.png"
                  : training.cover_picture // Aquí se pasa la propiedad cover_picture del objeto
            }
            alt=""
          />))}
        {options == "final" || options == "reservafinal" ? null : (
          <div style={{ top: "5%", position: "absolute", left: "6%" }}>
            <div className="d-flex my-auto">
              <div
                onClick={handleClick}
                className=" fw-bold booking-all"
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "2%",
                  fontSize: "1vmin",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "0.5vmin" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div className=" my-auto mx-2">
                <div
                  className="my-1"
                  style={{ color: "#394F65", fontSize: "2.0vmin" }}
                >
                  Entrenamiento
                </div>
                <div
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "3vmin",
                  }}
                >
                  {selectReserva[0].name}
                </div>{" "}
                <br />{" "}
                <button
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "300",
                    padding: "1% 12%",
                  }}
                >
                  GRATIS
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {options == "seleccionar" ? (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            padding: "2%",
          }}
        >
          <span
            style={{
              color: "#394F65",

              fontSize: "3vmin",
            }}
          >
            {" "}
            Selecciona tu
          </span>

          <span
            style={{
              color: "#394F65",
              fontWeight: "600",
              fontSize: "3vmin",
            }}
          >
            día y hora de clase
          </span>
          <br />
          {trainers.map((trainer) => (
            <div
              key={trainer.id}
              className="d-flex position-relative changecolortable my-1"
              style={{
                justifyContent: "space-around",
                borderRadius: "40px",
                textAlign: "center",
                padding: "2%",
              }}
            >
              <div
                className="d-flex "
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Prof. {trainer.name}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  {trainingsId[0].capacity} CUPOS </span>

              </div>
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2%",
                }}
              >
                {/* <span>{trainer.dia}</span>
                <span>{trainer.hora}</span> */}
                {tomorrow}
              </div>
              <div className="my-auto d-flex  position-relative">
                <input
                  name="radioteachers"
                  type="radio"
                  onChange={() => handleTrainerChange(trainer)}
                ></input>{" "}
              </div>
            </div>
          ))}

          <div
            className="my-2"
            style={{
              color: "#394F65",
              fontWeight: "600",
              fontSize: "1.5vmin",
            }}
          >

            {/* VALIDACION DE CREDITOS POR MEDIO DEL INPUT */}
            {/* <p>
                Costo total de tu reserva: {selectedTrainer ? trainingsId[0].cost || selectedTrainer.cost + " créditos" : "Selecciona un entrenador para ver el costo total."}
              </p> */}

            <span
              style={{
                textAlign: "center",
                color: "#394F65",
                fontWeight: "bold",
                fontSize: "1.5vmin",
                whiteSpace: "nowrap",
              }}
            >
              Costo total de tu reserva - 0 créditos
            </span>

          </div>
          <ButtonMob handleClick={() => setoptions("final")} text="Reserva" />
        </div>
      ) : options == "final" ? (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: "2%",
          }}
        >
          <div className="d-flex my-auto">
            <div
              onClick={() => setoptions("seleccionar")}
              className=" fw-bold booking-all"
              style={{
                background: "transparent",
                border: "1px solid #A8C2D7",
                padding: "2%",
                fontSize: "1vmin",
                height: "fit-content",
                cursor: "pointer",
                color: "#A8C2D7",
                borderRadius: "10px",
              }}
            >
              <span style={{ fontSize: "0.5vmin" }}>
                <i className="bi bi-chevron-left"></i>
              </span>
            </div>
            <div className=" my-auto mx-2">
              <div
                className="my-0"
                style={{ color: "#394F65", fontSize: "2.5vmin" }}
              >
                Resumen
              </div>
              <div
                style={{
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "3vmin",
                  whiteSpace: "nowrap",
                }}
              >
                de tu reserva
              </div>{" "}
              <div
                className="d-flex"
                style={{
                  background: "#F4F0ED",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "25px",
                  padding: "5%",
                  width: "38vmin",
                  margin: "8vmin 0",
                }}
              >
                <span style={{ textAlign: "center", fontSize: "3vmin" }}>
                  <i className="bi bi-clock-history"></i>
                </span>
                {trainers.map((trainer) => (

                  <span
                    style={{
                      textAlign: "center",
                      color: "#394F65",
                      fontWeight: "bold",
                      fontSize: "1.5vmin",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* <span>Prof. {trainer[0].name}</span> */}

                    Prof. Hugo Ortiz
                  </span>
                ))}
                <span
                  style={{
                    textAlign: "center",
                    color: "#D74688",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Zumba grupal
                </span>
                <span
                  style={{
                    textAlign: "center",
                    color: "#394F65",
                    fontWeight: "bold",
                    fontSize: "1.5vmin",
                    whiteSpace: "nowrap",
                  }}
                >
                  Agosto 17 de 2020 / 9:00 a.m / 10:00 a.m
                </span>
              </div>
              <hr />
              <div className="d-flex py-2" style={{ justifyContent: "center" }}>
                <button
                  // handleClick={() => navigate("/Usuario/Inicio")}
                  onClick={() => setoptions("reservafinal")}
                  style={{
                    background: "var(--elevva)",
                    border: "1px solid var(--elevva)",
                    borderRadius: "20px",
                    color: "var(--white)",
                    fontWeight: "400",
                    padding: "2% 12%",
                  }}
                >
                  Reservar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : options == "reservafinal" ? (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            padding: "2%",
          }}
        >
          <div className=" m-auto">
            <div
              style={{
                color: "#394F65",
                fontSize: "3.0vmin",
                lineHeight: "100%",
              }}
            >
              Entrenamientos
            </div>
            <div
              style={{
                color: "#394F65",
                fontWeight: "bold",
                fontSize: "3.5vmin",
                whiteSpace: "nowrap",
              }}
            >
              reservados
            </div>{" "}
            <hr />
            <div
              className="fw-600 mx-auto"
              style={{
                background: "#B07EBC",
                color: "white",
                width: "50vmin",
                fontSize: "2vmin",
                padding: "3% 0",
              }}
            >
              ¡Disfruta <strong>tu clase!</strong>
            </div>
            <div
              className="d-flex"
              style={{
                background: "#F4F0ED",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                borderRadius: "25px",
                padding: "5%",
                width: "38vmin",
                margin: "8vmin auto",
              }}
            >
              <span style={{ textAlign: "center", fontSize: "3vmin" }}>
                <i className="bi bi-clock-history"></i>
              </span>

              <span
                style={{
                  textAlign: "center",
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "1.5vmin",
                  whiteSpace: "nowrap",
                }}
              >
                Prof. Hugo Ortiz
              </span>
              <span
                style={{
                  textAlign: "center",
                  color: "#D74688",
                  fontWeight: "bold",
                  fontSize: "1.5vmin",
                  whiteSpace: "nowrap",
                }}
              >
                Zumba grupal
              </span>
              <span
                style={{
                  textAlign: "center",
                  color: "#394F65",
                  fontWeight: "bold",
                  fontSize: "1.5vmin",
                  whiteSpace: "nowrap",
                }}
              >
                Agosto 17 de 2020 / 9:00 a.m / 10:00 a.m
              </span>
            </div>
            <div
              className="d-flex mx-auto"
              style={{
                border: "1px solid #9DACBD",
                padding: "2%",
                borderRadius: "20px",
                width: "45vmin",
              }}
            >
              <div
                className="my-auto"
                style={{ padding: "4%", borderRight: "1px solid #9DACBD" }}
              >
                <img
                  style={{ width: "10vmin" }}
                  src={process.env.PUBLIC_URL + "/img/compus.png"}
                />
              </div>
              <div style={{ padding: "4%" }}>
                <span
                  style={{
                    color: "#9DACBD",
                    textAlign: "justify",
                    fontSize: "1vmin",
                  }}
                >
                  Recuerda que puedes tomar tu clases a través de un computador,
                  celular, ipad o tablet. Para una experiencia optima,
                  recomendamos usar el navegador Google Chrome.
                </span>
              </div>
            </div>
            <br />
            <div className="d-flex py-2" style={{ justifyContent: "center" }}>
              <button
                onClick={() => navigate("/Usuario/Inicio")}
                style={{
                  background: "var(--elevva)",
                  border: "1px solid var(--elevva)",
                  borderRadius: "20px",
                  color: "var(--white)",
                  fontWeight: "400",
                  padding: "2% 12%",
                }}
              >
                Ir a <i class="bi bi-house"></i>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="col-6 d-flex"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            padding: "2%",
          }}
        >
          <div className="py-5">
            <button
              className="me-1"
              style={{
                background: "#F4F0ED",
                border: "1px solid #F4F0ED",
                borderRadius: "20px",
                color: "#394F65",
                fontWeight: "300",
                padding: "1% 8%",
              }}
            >
              {/* 45min */}
              {selectReserva[0].time} {""} {"Min"}
            </button>
            <button
              className="mx-1"
              style={{
                background: "#F4F0ED",
                border: "1px solid #F4F0ED",
                borderRadius: "20px",
                color: "#394F65",
                fontWeight: "300",
                padding: "1% 8%",
              }}
            >
              {/* 500 cal */}
              {selectReserva[0].calories} {""}{"Cal"}
            </button>
          </div>
          <br />
          <br />

          <div className="py-2">
            <span
              style={{
                color: "#394F65",
                fontWeight: "400",
                fontSize: "2vmin",
              }}
            >
              Metodologia
            </span>
            <br />

            {/* <span
              style={{
                color: "#A9B6C4",
                fontWeight: "bold",
                fontSize: "1.5vmin",
              }}
            >
              Disciplina grupal
            </span> */}
          </div>
          <div className="py-2">
            {/* <span
              style={{
                color: "#394F65",
                fontWeight: "400",
                fontSize: "2vmin",
              }}
            >
              Metodologia
            </span>
            <br /> */}

            <span
              style={{
                color: "#A9B6C4",
                fontWeight: "bold",
                fontSize: "1.5vmin",
              }}
            >
              Disciplina grupal
            </span>
          </div>
          <div className="py-3">
            <span
              style={{
                color: "#394F65",
                fontWeight: "400",
                fontSize: "2vmin",
              }}
            >
              Información
            </span>
            <br />
            <br />

            <span
              style={{
                color: "#A9B6C4",
                fontWeight: "bold",
                fontSize: "1.5vmin",
              }}
            >
              {selectReserva[0].description}
            </span>
          </div>
          <br />
          <div>
            <span
              style={{
                color: "#394F65",
                fontWeight: "400",
                fontSize: "2vmin",
              }}
            >
              {/* Cupo disponible: 20 personas */}
              {trainingsId.length > 0 && <p>Cupos disponibles: {trainingsId[0].capacity} personas</p>}
            </span>
          </div>
          <div className="my-3">
            <ButtonMob
              handleClick={optionsClick}
              className="marginButtonReserva"
              text="Nueva reserva"
            />
          </div>
        </div>
      )}
    </div>
  );
};
