import Media from "react-media";
import { useNavigate, useParams } from "react-router-dom";
import logo_eleva from "../../assets/img/reservasimages/Logo eleva_giro.png";
import giro1 from "../../assets/img/reservasimages/giroTop.png";
import giro2 from "../../assets/img/reservasimages/giroBot.png";
import fondo from "../../assets/img/tipos_ejercicios.png";
import myCamera from "../../assets/img/reservasimages/myCamera.png";
import customAvatar from "../../assets/img/reservasimages/customAvatar.png";

import "./Clase.css";
import { useEffect, useRef, useState } from "react";
import {
  showOrHidePanel,
  instance,
  disconnectPeer,
  connectPeerToPeerUser,
  ContenedorClase,
  fancyTimeFormat,
  Panel,
  SetModalError,
  userType,
  timeOutcheckVideo,
  timeOutcheckAudio,
  ButtonMob,
  Loadingspinner,
  basename,
} from "../../utils/utils.js";
import AgoraRTC from "agora-rtc-sdk-ng";
import star from "../../assets/img/reservasimages/star-unfill.png";
import starfill from "../../assets/img/reservasimages/star-fill.png";
import flama from "../../assets/img/reservasimages/flama.png";
import corazon from "../../assets/img/reservasimages/corazon.png";
import cerrar from "../../assets/img/reservasimages/Grupo 6107.png";
import Moment from "moment";
import "moment/locale/es";
import { extendMoment } from "moment-range";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from "swiper";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import muted from "../../assets/img/svgcall/SonidoOff.svg";
import noMuted from "../../assets/img/svgcall/SonidoOn.svg";
import videoOn from "../../assets/img/svgcall/videoOn.svg";
import videoOff from "../../assets/img/svgcall/videoOff.svg";
import headerWait from "../../assets/img/svgcall/headerWait.svg";
import { ComprobarClase } from "./Clase";

SwiperCore.use([Navigation]);

const Clasestream = (props) => {
  AgoraRTC.setLogLevel(3);
  const [isChecked, setChecked] = useState(null);
  const [user, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checked = localStorage.checked;
    instance
    .get("users/current/")
    .then((response) => {  

      setUserData(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
    if (checked) {
      const now = Date.now();
      const diff = now - checked;
      if (diff <= 1800000) {
        // Less than 30 min, so no check again
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else setChecked(false);
  }, []);

  return (
    <>
      <Media
        query="(max-width: 470px)"
        render={() => (
          <div
            className="claseFondo"
            // style={{ backgroundImage: `url(${orientation})` }}
          >
            <div
              style={{
                background: "#7886F5",
                color: "white",

                textAlign: "center",
              }}
            >
              <img
                src={logo_eleva}
                alt="giro"
                width={100}
                className="m-3 p-auto"
              />
            </div>
            <div className="container px-5">
              <div className="info">
                <img src={giro1} alt="giro" />
                <br />
                <div style={{ color: "#9EACED" }}>
                  Tu sesión de entrenamiento Iniciara en unos segundos.
                </div>
                <br />
                <br />
                <img src={giro2} alt="giro" />
                <br />
                <div style={{ color: "#9EACED" }}>
                  Por favor gira tu celular para iniciarla correctamente.
                </div>
              </div>
            </div>
            <div
              className="position-absolute fw-bold m-auto"
              style={{ color: "white", top: "1%", left: "2%" }}
            >
              <div
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer", margin: "14% auto" }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="2x" color="white" />
              </div>
            </div>
          </div>
        )}
      />
      <Media
        query="(min-width: 471px)"
        render={() => (
          <>
            <div
              className="containerBGClase"
              style={{ backgroundImage: `url(${fondo})` }}
            ></div>
            {isChecked === true && user ? (
              <ComenzarClase user={user} imagenCompanies={logo_eleva} />
            ) : null}
            {isChecked === false ? (
              <ComprobarClase
                onChecked={(inf) => setChecked(inf)}
                isChecked={isChecked}
              />
            ) : null}
          </>
        )}
      />
    </>
  );
};

const ComenzarClase = (props) => {
  const moment = extendMoment(Moment);
  const imagenCompanies = props.imagenCompanies || null;
  const myPanelTab = useRef(null);
  const myPanel = useRef(null);

  const { reserva } = useParams();
  const user = props.user;

  const [idClase, setIdClase] = useState(null);

  const [nombreCliente, setNombreCliente] = useState("Jhon Doe");
  const [estrellas, setEstrellas] = useState(1);
  const [avatar, setAvatar] = useState(null);

  const [modalInfo, setModalInfo] = useState(null);

 
  const modalShowed = useRef(false);
  useEffect(() => {
    AgoraRTC.getDevices().then((devices) => {
      const audioDevices = devices.filter(function (device) {
        return device.kind === "audiooutput" || device.kind === "audioinput";
      });
    });
    AgoraRTC.getMicrophones().then((devices) => {
      const audioDevices1 = devices.filter(function (device) {
        return device.kind === "audiooutput";
      });
      const audioDevices2 = devices.filter(function (device) {
        return device.kind === "audioinput";
      });
    });

    async function conectarclase() {
      await instance
        .get(`/booking/book/prepare/${reserva}`)
        .then((resEn) => {
          const data = resEn.data;
          if (data) {
            setNombreCliente(data.trainer.full_name);
            setEstrellas(data.trainer.current_rating);
            setAvatar(process.env.REACT_APP_URL_SERVER + data.trainer.profile_pic);

            instance
              .post(`booking/book/start/${reserva}`)
              .then((res) => {
                const info = res.data;
                if (info) {
                  if (info.status == 60) {
                    localStorage.roomId = info.room_id;

                    if (!modalShowed.current) {
                      modalShowed.current = true;
                      setModalInfo(
                        <div className="containerComprobar">
                          <div className="containerModal_eleva_class">
                            <img
                              src={headerWait}
                              alt="header"
                              className="iconHeaderWait"
                            />
                            <div className="title scroll">
                              Hola atleta, tu profesor esta preparando
                              <br />
                              tu clase y en un momento ingresará.
                            </div>
                            <div className="items scroll">
                              <div
                                className="recomen"
                                style={{ overflow: "hidden" }}
                              >
                                Recuerda al ingresar a tu clase, verificar que
                                los iconos de audio y video en la parte inferior
                                de esta pantalla se encuentren activos.
                                <br />
                                <br />
                                <div className="iconsParentClase">
                                  <div className="iconsAfterClase">
                                    <div>
                                      <img src={videoOff} alt="video off" />
                                      <img src={muted} alt="sonido off" />
                                    </div>
                                    <span>Inactivos</span>
                                  </div>
                                  <div className="iconsAfterClase">
                                    <div>
                                      <img src={videoOn} alt="video on" />
                                      <img src={noMuted} alt="sonido on" />
                                    </div>
                                    <span>Activos</span>
                                  </div>
                                </div>
                              </div>
                              <div className="cancelar">
                                Tenemos una variedad de contenido al que puedes
                                acceder con un click, rétate a conocerte a ti
                                mismo !
                                <br />
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }

                    setTimeout(() => {
                      conectarclase();
                    }, 5000);
                  } else {
                    setModalInfo(null);
                   console.log(user)
                    const objcStart = {
                      id_clase: info?.room_id,
                      uid: user.customer.user,
                      nameClient: user.first_name,
                      reserva: reserva,
                      classType: "stream",
                      type: 10,
                      agoraToken: info?.agora_token,
                    };
                   
                    connectPeerToPeerUser(objcStart, true);
                    setIdClase(reserva);
                  }
                }
              })
              .catch((err) => {
                console.error(
                  "Error en api/clientes/reservas/conectar-clase",
                  err
                );
              });
          }
        })
        .catch((err) => {
          setModalInfo(
            <SetModalError handleClick={() => setModalInfo(null)} />
          );
        });
    }
    conectarclase();
    // returned function will be called on component unmount
    return () => {
      disconnectPeer();
    };
  }, []);


  const actualTime = useRef(0);

  const [timeLeft, setTimeLeft] = useState(fancyTimeFormat(0));
  var intervalSearch = null;
  const [objReserva, setReserva] = useState(null);
  const [relojIniciar, setrelojIniciar] = useState(null);
  const [frecuenciaCustomer, setfrecuenciaCustomer] = useState(0);
  const [kcalCustomer, setkcalCustomer] = useState(0);

  useEffect(() => {
    async function conectarreloj() {
      setTimeout(() => {
        instance
          .get(`booking/book/${reserva}`)
          .then((resEn) => {
            const status_class = resEn.data.status;
           
            setrelojIniciar(status_class);

            if (status_class !== 30) {
              conectarreloj();
            }
          })
          .catch((err) => console.log(err.response));
      }, 5000);
    }
    conectarreloj();

    instance.get(`booking/book/${reserva}`).then((res) => {
      const data = res.data;
      const fecha = data.date.replace("T", " ").slice(0, 16);

      if (data != null) {
        setReserva({
          id: reserva,
          fecha: fecha,
        });
      }
    });
    return () => {
      clearInterval(timeOutcheckVideo); // clear the timer to check icon color
      clearInterval(timeOutcheckAudio); // clear the timer to check icon color
    };
  }, []);

  useEffect( () => {
    if (relojIniciar == 40) {
      setModalInfo(
        <CancelarClase
          user={user}
          step={2}
          imagenCompanies={imagenCompanies}
          idClase={idClase}
          reserva={reserva}
          noCancelar={noCancelar}
        />
      );
    } else if (relojIniciar == 30 && !localStorage.firstregisterclassstream) {
      var formData = new FormData();
      formData.append("percentage", 100);
      formData.append("heart_rate", -1);
      formData.append("workoutset", 10);
      formData.append("kcal", -1);

      instance
        .post(`booking/book/${reserva}/workoutlog/`, formData)
        .then((resEn) => {
          const status_class = resEn.data;
          if (status_class) {
         
            setfrecuenciaCustomer(status_class.heart_rate);
            setkcalCustomer(status_class.kcal);
            localStorage.firstregisterclassstream = "check";
          }
        })
        .catch((err) => console.log(err.response));
    }

    // }, 5000);
  }, [relojIniciar]);

  

  useEffect(() => {
    if(relojIniciar == 30){
      if (objReserva == null) return false;
    const fechaclar = moment(objReserva.fecha).format(
      "dddd DD [de] MMMM / HH:mm a"
    );
    const timeStart = moment();
    const timeEnd = moment(fechaclar, "dddd DD [de] MMMM / HH:mm a");
    timeEnd.add(1, "hour");
    const diff = timeEnd.diff(timeStart, "seconds");
    actualTime.current = diff;

    setInterval(() => {
      if (actualTime.current > 0) {
        actualTime.current -= 1;
        setTimeLeft(fancyTimeFormat(actualTime.current));
      }
    }, 1000);
  }
  }, [relojIniciar]);

  function cancelarClase() {
    showOrHidePanel(myPanel, myPanelTab, "left", true);
    setModalInfo(
      <CancelarClase
        imagenCompanies={imagenCompanies}
        user={user}
        idClase={idClase}
        reserva={reserva}
        noCancelar={noCancelar}
      />
    );
  }

  function noCancelar() {
    setModalInfo(null);
  }

  const paneles = (
    <>
      <Panel
        align="left"
        classNameContent="myPanel"
        contentRef={myPanel}
        tabRef={myPanelTab}
        tabCerrar={cerrar}
        tabAvatar={avatar ? avatar : customAvatar}
        classNameTab="myPanelTab"
      >
        <div className="logo" style={{ background: "#7886F5" }}>
          <img
            alt="logo"
            src={ logo_eleva}
            style={
              imagenCompanies
                ? { margin: "6% auto ", minWidth: "20vmin" }
                : { margin: "4% auto " }
            }
          />
        </div>
        <div className="imgEntrenador1">
          <img
            alt="av"
            src={avatar ? avatar : myCamera}
            className="rounded-circle"
          />
        </div>
        <div className="imgEntrenador">
          <div className="infoPeer align-center">
            <div className="name">{nombreCliente}</div>
            <div className="calificacionEntrenador">
              <img
                src={estrellas >= 1.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 2.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 3.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 4.0 ? starfill : star}
                alt="etrella"
                className=""
              />
              <img
                src={estrellas >= 5.0 ? starfill : star}
                alt="etrella"
                className=""
              />
            </div>
          </div>
        </div>

        <div className="Cardio infoClas ">
          <img src={corazon} alt="cardio" />
          <div className="datos">
            <span className="valor">
              {" "}
              {frecuenciaCustomer && frecuenciaCustomer > 0
                ? frecuenciaCustomer
                : "_ _ _ _"}
            </span>
            <br />
            Frecuencia cardiaca
          </div>
        </div>
        <div className="Calorias infoClas infoClas_F ">
          <img src={flama} alt="cardio" />
          <div className="datos">
            <span className="valor">
              {kcalCustomer && kcalCustomer > 0
                ? kcalCustomer.toFixed(0)
                : "_ _ _ _ "}
            </span>
            <br />
            Calorías quemadas
          </div>
        </div>
        <div className="textFinalizar">
          <div>¿Quieres finalizar tu sesión de entrenamiento?</div>
          Ten en cuenta que si finalizas tu sesión, no podrás volver a ingresar.
        </div>
        <div className="Finalizar">
          <button className="btnFinalizar" onClick={cancelarClase}>
            Finalizar sesión
          </button>
        </div>
      </Panel>
      <div
        className="tiempo"
        style={{
          background: "rgb(38,38,38,0.7)",
          borderRadius: "7px",
          position: "fixed",
          width: "16%",
          textAlign: "center",
          padding: "0.5vw 0",
          right: "0 ",
          zIndex: "999999",
          fontSize: "4vw",
          fontWeight: "700",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <span>{timeLeft}</span>
        <br />
        <div
          style={{
            fontSize: "1vw",
            fontWeight: "700",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {" "}
          Tiempo de sesión
        </div>
      </div>
    </>
  );
  return (
    <>
      <ContenedorClase paneles={paneles} clientestream={true} />
      {modalInfo}
    </>
  );
};

const CancelarClase = (props) => {
  const reserva = props.reserva;
  const step = props.step || null;
  const imagenCompanies = props.imagenCompanies || null;
  const [msgCancelar, setMsgCancelar] = useState("");
  const [nivelCancelar, setNivelCaneclar] = useState(10);
  const [setp, setStep] = useState(step ? step : 1);
  const [loadingCancelar, setLoadingCancelar] = useState(null);

  function noCancelar() {
    props.noCancelar();
  }
  function valuetext(value) {
    if (value == 1) return `Facil`;
    else if (value == 3) return `Adecuado`;
    else if (value == 5) return `Muy dificil`;
    return "No deberia salir";
  }

  function renderStars(refStep) {
    return (
      <div className="estrellas" ref={refStep} value={0}>
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s1"
          onClick={() => setStars(refStep, 1) || NextSlider()}
        />

        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s2"
          onClick={() => setStars(refStep, 2) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s3"
          onClick={() => setStars(refStep, 3) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s4"
          onClick={() => setStars(refStep, 4) || NextSlider()}
        />
        <img
          src={star}
          alt="estrella"
          className="estrella"
          id="s5"
          onClick={() => setStars(refStep, 5) || NextSlider()}
        />
      </div>
    );
  }

  function setStars(theRef, pos) {
    theRef.current.setAttribute("value", pos);

    for (let index = 1; index <= 5; index++) {
      const estrella = theRef.current.querySelector("#s" + index);
      if (pos >= index) estrella.src = starfill;
      else estrella.src = star;
    }
  }
  const [theSwipper, setTheSwipper] = useState(null);
  function NextSlider() {
    theSwipper.slideNext();
  }
  async function cancelarDefinitivo() {
    setLoadingCancelar(<Loadingspinner customStyle="grow" size="5rem" />);

    const array = [];
    array.push(
      nivelCancelar,
      Number(refPresentacion.current.getAttribute("value")),
      Number(refLugar.current.getAttribute("value")),
      Number(refConocmiento.current.getAttribute("value")),
      Number(refEnergia.current.getAttribute("value"))
    );

    instance
      .post(`/booking/book/finish/${reserva}`, {
        comment: msgCancelar,
        answers: array,
        hr: -1,
      })

      .then((res) => {
        if (res.data) {
          localStorage.removeItem("firstregisterclassstream");
         
            window.location.href = `${basename}/results/${reserva}`;
          
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingCancelar(
          <div style={{ color: "red" }}>
            Ocurrio un error al momento de finalizar
          </div>
        );
        setTimeout(() => {
          setLoadingCancelar(null);
        }, 3000);
      });
  }

  const refPresentacion = useRef(null);
  const refLugar = useRef(null);
  const refConocmiento = useRef(null);
  const refEnergia = useRef(null);

  return (
    <>
      <div className="containerFinalizar">
        <div className="containerModal">
          {setp == 1 ? (
            <>
              <form onSubmit={(e) => setStep(2)}>
                <h1 className="title">Finalizar sesión</h1>
                <div className="subtitle">
                  Cuéntanos como estuvo tu
                  <br />
                  sesión de entrenamiento.
                </div>
                <div className="observaciones">
                  <textarea
                    placeholder="Escribe aquí tu motivo"
                    rows={7}
                    required
                    onInput={(e) => setMsgCancelar(e.target.value)}
                  ></textarea>
                </div>
                <div className="botones">
                  <button className="sifin me-2 w-50">
                    Confirmar finalización
                  </button>
                  <button className="nofin w-50" onClick={noCancelar}>
                    No deseo finalizar
                  </button>
                </div>
              </form>
            </>
          ) : setp == 2 ? (
            <>
              <div className="swiper-pagination"></div>
              <h2 className="title">Ayudanos a mejorar</h2>
              <p className="subtitle">
                Responde estas 5 preguntas que nos permiten
                <br />
                mejorar continuamente nuestro servicio.
              </p>
              <Swiper
                pagination={{
                  el: ".swiper-pagination",
                  type: "custom",
                  renderCustom: function (swiper, current, total) {
                    if (theSwipper == null) {
                      setTheSwipper(swiper);
                    }
                  },
                }}
                spaceBetween={10}
                slidesPerView={"auto"}
                navigation
                allowTouchMove={false}
                className="preguntas"
                onSlideChange={(sp) => {
                  const toolt = document.querySelector(".MuiTooltip-popper");
                  if (toolt) {
                    if (sp.activeIndex == 0) {
                      toolt.classList.add("visible");
                      toolt.classList.remove("invisible");
                    } else {
                      toolt.classList.add("invisible");
                      toolt.classList.remove("visible");
                    }
                  }
                }}
              >
                <SwiperSlide className="pregunta">
                  <div>
                    ¿Cómo estuvo el nivel de dificultad del entrenamiento?
                    <br />
                    <br />
                  </div>
                  <div className="d-flex justify-evenly ">
                    <ButtonMob
                      colorButton={"#9EACED"}
                      className={`${
                        nivelCancelar == 10 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Fácil"}
                      onClick={() => setNivelCaneclar(10) || NextSlider()}
                    />
                    <ButtonMob
                      colorButton={"#9EACED"}
                      className={`${
                        nivelCancelar == 20 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Adecuado"}
                      onClick={() => setNivelCaneclar(20) || NextSlider()}
                    />
                    <ButtonMob
                      colorButton={"#9EACED"}
                      className={`${
                        nivelCancelar == 30 ? "activo" : ""
                      } btnCancelNivel`}
                      text={"Dificil"}
                      onClick={() => setNivelCaneclar(30) || NextSlider()}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>La presentación personal del profesor fue</div>
                  {renderStars(refPresentacion)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>
                    El espacio, ambiente y lugar en el que el profesor dicto la
                    clase fue
                  </div>
                  {renderStars(refLugar)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>
                    El profesor demostró conocimiento y experiencia durante la
                    clase
                  </div>
                  {renderStars(refConocmiento)}
                </SwiperSlide>
                <SwiperSlide className="pregunta">
                  <div>
                    ¿El profesor durante el entrenamiento trasmitió una buena
                    energía y motivación?
                  </div>
                  {renderStars(refEnergia)}

                  <div className="botones">
                    {loadingCancelar ? (
                      loadingCancelar
                    ) : (
                      <button
                        className="sifin me-2 "
                        onClick={cancelarDefinitivo}
                      >
                        Confirmar finalización
                      </button>
                    )}
                  </div>
                </SwiperSlide>
              </Swiper>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Clasestream;
//             if ( arrRutinas.current ) {
//               if (status_class.trainer_change == true) {
//                 async function recorrer(status_class) {
//                   var m;
//                   var z;
//                   let inicio;
//                   var contador;
//                   var percentage;
//                   if (status_class.methodology == null) {
//                     m = 0;
//                     z = 1;
//                     inicio = 0;
//                     contador = 0;
//                     percentage = status_class.percentage;
//                   } else {
//                     if (
//                       localStorage.workoutsetActual == status_class.workoutset
//                     ) {
//                       contador = localStorage.contadorActual;
//                       var xx =
//                         arrRutinas.current &&
//                         arrRutinas.current.indexOf(
//                           arrRutinas.current.filter(
//                             (e) => e.id == status_class.methodology
//                           )[0]
//                         );
//                       var yy =
//                         arrRutinas.current &&
//                         arrRutinas.current[
//                           arrRutinas.current.indexOf(arrRutinas.current[xx])
//                         ].set.indexOf(
//                           arrRutinas.current[
//                             arrRutinas.current.indexOf(arrRutinas.current[xx])
//                           ].set.filter(
//                             (e) => e.id == status_class.workoutset
//                           )[0]
//                         );
//                       if (
//                         arrRutinas.current[
//                           arrRutinas.current.indexOf(
//                             arrRutinas.current.filter(
//                               (e) => e.id == status_class.methodology
//                             )[0]
//                           )
//                         ].series > status_class.methodology_series
//                       ) {
//                         percentage = status_class.percentage;
//                         inicio = yy - -1;
//                         z = status_class.methodology_series;
//                         m = xx;
//                       } else {
//                         z = status_class.methodology_series;
//                         percentage = status_class.percentage;
//                         m = xx;
//                         if (
//                           arrRutinas.current[m].type == 60 ||
//                           arrRutinas.current[m].type == 40
//                         )
//                           inicio = arrRutinas.current[m].set[yy - -1]
//                             ? yy - -1
//                             : 0;
//                         else inicio = yy - -1;
//                       }
//                     } else {
//                       contador = 0;
//                       percentage = status_class.percentage;
//                       if (
//                         arrRutinas.current[
//                           arrRutinas.current.indexOf(
//                             arrRutinas.current.filter(
//                               (e) => e.id == status_class.methodology
//                             )[0]
//                           )
//                         ].series > status_class.methodology_series
//                       ) {
//                         inicio = 0;
//                         z = status_class.methodology_series - -1;
//                         m = arrRutinas.current.indexOf(
//                           arrRutinas.current.filter(
//                             (e) => e.id == status_class.methodology
//                           )[0]
//                         );
//                       } else {
//                         z =
//                           arrRutinas.current[
//                             arrRutinas.current.indexOf(
//                               arrRutinas.current.filter(
//                                 (e) => e.id == status_class.methodology
//                               )[0]
//                             )
//                           ].series > status_class.methodology_series
//                             ? status_class.methodology_series
//                             : 1;

//                         m =
//                           arrRutinas.current.indexOf(
//                             arrRutinas.current.filter(
//                               (e) => e.id == status_class.methodology
//                             )[0]
//                           ) - -1;
//                         inicio = 0;
//                       }
//                     }
//                   }
//                   if (
//                     arrRutinas.current[m].type == 60 ||
//                     arrRutinas.current[m].type == 40
//                   ) {
//                     while (contador < arrRutinas.current[m].time) {
//                       for (
//                         let i = inicio;
//                         i < arrRutinas.current[m].set.length;
//                         i++
//                       ) {
//                         if (arrRutinas.current[m].type == 40) {
//                           contador = contador - -60;
//                         } else if (arrRutinas.current[m].type == 60) {
//                           contador = contador - -30;
//                         } else {
//                           contador =
//                             contador - -arrRutinas.current[m].set[i].time;
//                         }

//                         localStorage.contadorActual = contador;

//                         await next(
//                           status_class,
//                           m,
//                           i,
//                           z,
//                           percentage,
//                           i + 1 - inicio,
//                           contador
//                         );
//                       }
//                     }
//                   } else {
//                     for (
//                       let i = inicio;
//                       i < arrRutinas.current[m].set.length;
//                       i++
//                     ) {
//                       contador =
//                         contador - -arrRutinas.current[m].set[i].time;
//                       localStorage.contadorActual = contador;
//                       if (m == 0 && i == 0) {
//                         setpitoboxing(1);
//                       }
//                       await next(
//                         status_class,
//                         m,
//                         i,
//                         z,
//                         percentage,
//                         i + 1 - inicio,
//                         contador
//                       );
//                     }
//                   }

//                   instance
//                     .post(`booking/book/${reserva}/workoutlog/last/`)
//                     .then((resEn) => {
//                       const status_class = resEn.data;
//                       if (status_class) {
//                         localStorage.removeItem("contadorActual");
//                         localStorage.removeItem("workoutsetActual");
//                       }
//                     })
//                     .catch((err) => {
//                       if(err.response){
//                       setModalInfo(
//                         <SetModalError
//                         handleClick={() => setModalInfo(null)}
//                         title="Algo salio mal"
//                       />
//                       );
//                     }
//                     });
//                   return false;
//                 }
//                 await recorrer(status_class);
//               } else {
//                 modifyRutinaColor(
//                   arrRutinas.current.indexOf(
//                     arrRutinas.current.filter(
//                       (e) => e.id == status_class.methodology
//                     )[0]
//                   )
//                 );
              
//               }
    
  
   
// }
//       }
//       trackingworkoutlog(workoutlogData);
