
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReactComponent as BackArrow} from "../../assets/newIcons/arrow-back.svg"
import ReactHtmlParser from "react-html-parser";
import {
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  instance,
  NuevaAxiosURL,
  ButtonMob,
  laptop575,
} from "../../utils/utils";
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg";
import { MiniPerfil, ObjetivosCustomer } from "./helpPerfil";
import { ReactComponent as Estado } from "../../assets/newIcons/OBJ-ESTADO.svg";
import { ReactComponent as Peso } from "../../assets/newIcons/OBJ-PESO.svg";
import { ReactComponent as Tension } from "../../assets/newIcons/OBJ-TENSION.svg";
import { ReactComponent as Nutricion } from "../../assets/newIcons/OBJ-NUTRICION.svg";
import { ReactComponent as Diabetes } from "../../assets/newIcons/OBJ-DIABETES.svg";

const MisObjetivos = (props) => {
  const [modalInfo, setModalInfo] = useState(null);
  const [objetives_user, setobjetives_user] = useState(null);
  const [userData, setUserData] = useState([]);
  const [objetivos_prom, setobjetivos_prom] = useState("--- dias");
  const [pasos_prom, setpasos_prom] = useState("---");
  const [calorias_prom, setcalorias_prom] = useState("---");
  const navigate = useNavigate();
  const location = useLocation();
 

  const hideModal = () => {
    setModalInfo(null);
  };
  
  useEffect(() => {
 instance
      .get("users/current/")
      .then((response) => {
        
        setUserData(response.data);
     
      })
      .catch((error) => {
        console.log(error);
      });
      instance
      .get("customers/metrics/weekly/")
      .then((res) => {
        if (res.data) {
          // console.log("............................", res.data);
          setobjetives_user(res.data.current);
        }
      })
      .catch((err) => {
        if (err.response) {
          setModalInfo(
            <ModalInfo
              error="true"
              handleClick={hideModal}
              title="Algo salio mal"
              text="Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos"
              classesMsg=""
            />
          );
        }
      });
  }, [location.pathname]);

  function plural(valor){
if(valor == 1) {return "día"}
else return "días"
  }

  useEffect(() => {
    let dias= objetives_user?.days_activity == 0 ? "--- días": objetives_user?.days_activity +  ' ' +  plural(objetives_user?.days_activity)
    setobjetivos_prom(dias);
    setpasos_prom(objetives_user?.distance == 0 ? "---": objetives_user?.distance)
    setcalorias_prom(objetives_user?.kcal == 0 ? "---": objetives_user?.kcal)

}, [objetives_user]);

  const TrainingEstadistics = [
    [
      "Objetivo <br/> de <br/> entrenamiento",
      process.env.PUBLIC_URL + "/assets/img/objTraining.svg",
      "var(--objEntrenamiento)",
      " a la <br/> semana  ",
      objetivos_prom,
      "var(--colorObjTraining)",
    ],
    [
      "Objetivo  de <br/> pasos <br/> recorridos",
      process.env.PUBLIC_URL + "/assets/img/distance3D.svg",
      "var(--colorsesionesKm)",
      "Pasos a la semana",
      pasos_prom,
      "var(--elevvabutonLogin_User)",
    ],
    [
      "Objetivo <br/>  de <br/> quema calórica",
      process.env.PUBLIC_URL + "/assets/img/cal3D.svg",
      "var(--colorsesionesCal)",
      "Kilocalorías  <br/> a la semana",
      calorias_prom,
      "var(--textCal)",
    ],
  ];

  const objetivesCustomer=[
    {name:"Perder peso",icon:<Peso/>,code:10},
  
    {name:"Mejorar estado físico",icon:<Estado/>,code:30},
    {name:"Mejorar Nivel",icon:<Nutricion/>,code:60}
  ]
  const objetivesOldCustomer=[
    
    {name:"Mejorar tensión",icon:<Tension/>,code:10},
    {name:"Controlar diabetes",icon:<Diabetes/>,code:20},
    {name:"Mejorar estado físico",icon:<Estado/>,code:30},
    
  ]

  return(<> {modalInfo} 

<div className="mx-3 my-3 d-flex">
   <button onClick={()=>navigate(-1)} className={"button_w_h_back"} style={{background:"transparent",border:"none",}}>
   <BackArrow width={"100%"} height={"100%"} /></button><span className="title my-auto fw-light" style={{fontSize:"1.2rem"}}>Mis <strong>objetivos</strong></span>     
      

      </div>
      <div className=" mx-auto" 
  style={{
    width: "100%",
    background:"#F9F9F9",
   
    padding: "2%",
    gap: "2%",
  }}>
      <span className="title my-auto fw-light" style={{fontSize:"1.2rem"}}>Objetivos  <strong>semanales</strong></span>
      </div>
      <div id="ObjetivosSemanales"
  
  className=" mx-auto d-flex"
  style={{
    width: "100%",
    background:"#F9F9F9",
   
    padding: "2%",
    gap: "2%",
  }}
>

   {TrainingEstadistics.map((e, i) => (
    <div
      key={i}
      style={{
        background: e[2],
        width: "calc(100%/3)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "1.1rem",
        padding: "3% 4%",
      }}
    >
      <button
        style={{
          background: "var(--white)",
          borderRadius: "0.9rem",
          border: "1px solid transparent",
          margin: "0 auto 0 0",
          padding:"4%"
        }}
      >
        <img className="image-results-promedios"
          alt="e1"
          src={e[1]}
        
        />
      </button>

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginTop: "10%",
          justifyContent: "center",
        }}
      >
        <span
          className="fw-bold letraPequeResults"
          style={{ color: e[5], lineHeight: "120%" }}
        >
          {ReactHtmlParser(e[0])}
        </span>

        <span
          className=""
          style={{
            color: "var(--elevvaTextTitle)",
            fontSize: "calc(0.6rem + 1.3vmin)",
          }}
        >
          {/* Se muestra la carga usuario */}
          {/* {e[4]} */}
          {e[4]}  {e[3] == "por minuto"?<span
          className="letraPequeResults"
          style={{ color: "var(--elevvaTextTitle)",fontWeight:"400" }}
        > Pulsaciones</span>:""}
        
        </span>

        <span
          className="letraPequeResults"
          style={{ color: "var(--elevvaTextTitle)" }}
        >
          {ReactHtmlParser(e[3])} 
        </span>
      </div>
    </div>
  ))}
  </div>
  <div className="my-2" style={{padding: "2%"}}>
  <span className="title my-auto fw-light " style={{fontSize:"1.2rem"}}>Objetivos  <strong>del paciente</strong></span>

  </div>
  {userData?.customer?.objectives.length !=0 &&userData?.customer?.wellness_objective !=0 || userData?.customer?.wellness_objective != null  ?<div className="content-select-gender d-flex flex-column" style={{alignItems:"center"}}>
                        {objetivesCustomer.map(elemento=>
                           userData?.customer?.objectives.includes(elemento.code)? <div className="d-flex my-2" style={{alignItems:"center",width:"15rem"}}>
                          <div className="col-3">{elemento.icon}</div>
                          <div className="col-9"><span className="letraPequeña text-objective-jus">{elemento.name}</span>
                          </div>

                         </div>:null)}
                         {objetivesOldCustomer.map(elemento=>
                          userData?.customer?.wellness_objective== elemento.code? <div className="d-flex my-2" style={{alignItems:"center",width:"15rem"}}>
                          <div className="col-3">{elemento.icon}</div>
                          <div className="col-9"><span className="letraPequeña text-objective-jus">{elemento.name}</span>
                          </div>

                         </div>:null)}
                         </div>:<div className="content-select-gender d-flex flex-column" style={{alignItems:"center"}}>
                         <div className="d-flex my-2" style={{alignItems:"center",width:"15rem"}}>
                          <div className="col-3" style={{borderRadius:"50%",background:"var(--backgroundOnboardingObjective)",width:"50px",height:"50px"}}></div>
                          <div className="col-9"><span className="letraPequeña text-objective-jus">---</span>
                          </div>

                         </div>
                         </div>}
                        
  <br/><br/>
    </>
  )
}
  export default MisObjetivos;