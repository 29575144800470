import { useEffect } from "react";
import React from "react";

import {useParams, useNavigate } from "react-router-dom";
import { ComponenteKnow, instance, ModalInfo, TitleRow } from "../../utils/utils";
import { useState } from "react";
import { Loading } from "../TutorialReserva/stepsHelp";

const TrainerCv = (props) => {
  const { id ,idTrainer} = useParams();
  const [modalInfo, setModalInfo] = useState(null);
  const [infoTrainer, setinfoTrainer] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [infoTraining, setinfoTraining] = useState(null);
  const [MembershipInfo, setMembershipInfo] = useState(null);
  const navigate = useNavigate();

  const hideModal = () => {
    setModalInfo(null);
  };

  useEffect(() => {
    instance
      .get(`trainings/${id}`)
      .then((response) => {
        setinfoTraining(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      instance.get("users/").then((response) => { 
       }) .catch((error) => {
        console.log(error);
      });
    if (localStorage.membership) {
      instance
        .get(`memberships/${localStorage.membership}/detail`)
        .then((response) => {
          ;
          setMembershipInfo(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    instance
    .get(`trainers/detail/${idTrainer}`)
    .then((response) => {
    
      setinfoTrainer(response.data)
   
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);
  useEffect(() => {
    if (infoTrainer) {
      setIsLoading(false);
    }
  }, [infoTrainer]);

  function activarTrainer(e){
    let idTrainer = e;
    if (MembershipInfo &&MembershipInfo.rules.includes(5) && idTrainer > 1) {
      setModalInfo(
        <ModalInfo
          error="false"
          textButtonAction="Mejorar plan"
          handleCancellcargue={hideModal}
          handleReservar={() => {
            navigate(`#`);
          }}
          iconoWidth={"1.5rem"}
          textSizeUp={"1.2rem"}
          text={
            "Tu plan actual no permite seleccionar profesor"
          }
        />
      );
    } else {
      if(idTrainer==1){
        localStorage.removeItem('trainerSelected')
      }else{

      localStorage.trainerSelected =idTrainer
      }
     navigate(`/selectSchedule/${id}`)
    }
  }

return(
  <div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>

  {modalInfo}
       {isLoading ? (
        <Loading />
      ) : (<div className="d-md-none mobile-login w-100" style={{ height: "100%" }}>
        <div  
          className="containerBGClase"
          style={{backgroundSize: "cover",height: "35vh",
            backgroundImage: `url(${infoTrainer?.profile_pic})`,
          }}
        >
        

<div 
            
            className="w-70 ubication_arrow_title"
          >
            <div className="d-flex my-auto flex-column ">
              <div
                onClick={() => navigate(-1)}
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "1vmin",
                  width: "fit-content",
                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "1rem" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div className=" my-auto mx-2 d-flex flex-column justify-content-center ">
                <span
                  className="mt-2  text_arrow_top"
               
                >
                 
                </span>
                <span
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    lineHeight: "100%",
                    fontSize: "calc(1.3rem + 0.3vw)",
                  }}
                >
                  
                  {infoTrainer?.name.split(" ")[0]}
                </span>{" "}
                <span
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    lineHeight: "100%",
                    fontSize: "calc(1.3rem + 0.3vw)",
                  }}
                >
             
                  {infoTrainer?.name.split(" ")[1]}
                </span>
          
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-welcome-login-row  text-left w-100">
      
      
          <ComponenteKnow infoTrainer={infoTrainer} clicKnow={()=>activarTrainer(infoTrainer?.id)}/>

        </div>
      </div>)}
        </div>
)
}
export default TrainerCv;