import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { instance, laptop575, Loadingspinner, ModalInfo, NuevaAxiosURL } from "../../utils/utils";
import { useState, useRef, useEffect } from "react";
import "./vertipoindividual.css";
import { Videos_two } from "../Ejercicios/othervideos";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import axios from "axios";
import { ReactComponent as BackArrow } from "../../assets/newIcons/arrow-back.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import  { ModalElevva } from "../../utils/utils"
import { Loading } from "../TutorialReserva/stepsHelp";
const VerTipoIndividual = () => {
  const { id, idvideo } = useParams();
  const history = useNavigate();
  const searchText = useRef(null);

  const [AllVideos1, setAllVideos1] = useState(null);
  const [controlsOn, setcontrolsOn] = useState(true);
  const [modalInfo, setmodalInfo] = useState(true);
  const [isLoading, setisLoading] = useState(true);
  const [loadingVideos, setLoadingVideos] = useState(true);

  useEffect(() => {
    setTimeout(() => {

      setLoadingVideos(false)
    },1000)
  
      instance
      .get(`videos/programs`)
        .then((res) =>{
          setisLoading(false);
          setAllVideos1(res.data.filter((e) => e.id == id))});
  }, []);

  // const playerRef = useRef(null);
 
  function irFiltro(a, b) {
    localStorage.filterCompany = `${a},${b}`;

    return history("/videos/Buscar");
  }


  // validando carga de contenido
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener('load', () => {
      
      setIsPageLoaded(true);
    });
  }, []);

  // modal para video
  const [showAlertModal, setShowAlertModal] = useState(false);
  //console.log(showAlertModal);

  function handleVideoEnd() {
    setShowAlertModal(true);
    //console.log(setShowAlertModal, "function");
  }

  // close
  function handleClose() {
    setShowAlertModal(false);
  }


useEffect(() => {
 
  if (isPageLoaded) {
    function limite() {
   
    }
    limite()
  }
}, [isPageLoaded,]);
  const avisos = {
    aviso_one: [
      "Adquiere todos los beneficios de ser elevva+. Selecciona tu plan ahora y eleva tu estilo de vida",
      "Inicia tu prueba gratuita de 7 dias.",
      "var(--barraPublicidad)",
    ],
    aviso_two: [
      "Mejora tu plan y disfruta de muchos más beneficios con elevva+",
      "Mejorar tu plan.",
      "var(--elevvaTextTitle)",
    ],
    aviso_three: [
      "Tu prueba gratuita ha expirado. Selecciona un plan y sigue disfrutando elevva+. ",
      "Activar plan.",
      "var(--elevva)",
    ],
  };


  // const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_two`]);
  
  const [VideoDurationList, setVideoDurationList] = useState(60);
  const [functionExecuted, setFunctionExecuted] = useState(false);
  const handleDuration = (duration, index) => {
    setVideoDurationList( duration );
  };
  const handlePlay = () => {
   
   
    // Aquí puedes realizar cualquier acción adicional que desees cuando se inicie la reproducción
  };
  function hideModal() {
    setmodalInfo(null);
  }
    const handleProgress = (state) => {
      // console.log('Progreso del video:', state.played);
      // console.log('Tiempo actual:', state.playedSeconds);
      let videoWatched=AllVideos1[0].videos.filter((e) => e.id == idvideo)[0].watched
      // console.log('Porcentaje completado:', state.played * 100,videoWatched);

      if(state.played * 100 >70 && functionExecuted == false && videoWatched ==false){
        instance
        .post(`videos/${idvideo}/watched`)
            .then((response) => {
              const data = response.data;
          
              if(data){
                setFunctionExecuted(true);
              }
              
              
            })
            .catch((error) => {
              console.log(error, "Error al marcar video");
            })
      }
      // else{
      //   setmodalInfo(
      //     <ModalInfo
      //       error="false"
      //       handleClick={hideModal}
      //       handleCancellcargue={hideModal}
      //       classcorrecta={"recuerdaTitle"}
      //       title="Recuerda"
      //       text="Superaste el limite de visualizaciones para videos diarios"
      //       classesMsg="colorText_Modal"
      //     />
      //   );
      // }
    };

  

  
  return (
    isLoading ? <Loading/> : <div
      className="d-flex m-auto col-12 col-md-10"
      style={{
        height: "100vh",
      }}
    >
    {modalInfo}
      {laptop575.matches ? (
        <div
          className="mx-auto"
          style={{
            width: "100%",
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex"
            style={{
              background: "rgb(241, 246, 249)",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
              flexDirection: "column",
            }}
          >
            <div
              className="py-2 mx-3 banner_one "
              style={{
                background: "rgb(241, 246, 249)",

                color: "#394F65",
              }}
            >
              {" "}
              <div
                onClick={() => {
              

                  return history(`/videos/info/${id}`);
                }}
                // history.push(`/ejercicios/info/${id.slice(0, id.length - 1)}`)

                className=" position-absolute"
                style={{
                  left: "5%",
                  top: "50%",
                  transform:"translateY(-50%)",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >
                {" "}
                <div className="d-flex m-auto" style={{alignItems:"center"
                
                }}>
          {" "}
          <button
            
           
            style={{ background: "transparent", border: "none" }}
          >
            <BackArrow width={30}  />
          </button>
          <span className="title my-auto fw-light">
           Regresar
          </span>
        </div>
              </div>
        
              <Link
                className="form  my-2 d-flex"
                style={{ float: "right", marginRight: "5%" }}
                to="/videos/Buscar"
              >
                <button
                  className="elevation-3"
                  style={{
                    bottom: "19%",
                    right: "7%",
                    background: "white",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    borderRadius: "50%",
                    padding: "1vmin",

                    color: "#394F65",

                    zIndex: "10",
                    border: "2px solid transparent",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 17 17"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </Link>
            
            </div>
          </div>
          <div
                className="bannervideos d-flex"
                style={{
                  backgroundColor: "#FDF1E8",
                  position: "sticky",
                  top: "0",
                  // zIndex: "3",
                  zIndex: "0",
                  minheight: "10vmin",
                  left: "0",
                  flexDirection: "column",
                }}
              >
        
              </div>
          <br />
          <div
            className="d-flex col-12"
            style={{
              paddingLeft: "3%",
            }}
          >
            <div
              className="col-6 d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {AllVideos1 ? (
                <><div className={loadingVideos?"d-flex":"d-none"}>
               <Loadingspinner  customStyle="grow" size="2rem"/> 
               </div>
                <div className={loadingVideos?"d-none":"d-block"} style={{ padding: "2%", height: "auto",
                      justifyContent: "center", }}>
                  <ReactPlayer 
                     className={"position-relative playervideoIndividual"}
                    url={
                      AllVideos1[0].videos.filter((e) => e.id == idvideo)[0]
                        .file
                    }
                    onProgress={handleProgress}
                    onDuration={(duration) => handleDuration(duration)}
                   
                    preload="auto"
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload',
          },
        },
      }}
                    playIcon={
                      <button
                        style={{
                          color: "#ffff",
                          background: "transparent",
                          border: "none",
                          height:"40vmin"
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          fill="currentColor"
                          className="bi bi-play-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                        </svg>
                      </button>
                    }
                    light={loadingVideos?false:  AllVideos1[0].videos.filter((e) => e.id == idvideo)[0].background
                    }
                    height="auto"
                    width={"100%"}
                    playing={true}
                    controls={controlsOn}
                    onEnded={handleVideoEnd}
                  
                  />
                     {/* {showAlertModal && (
                      <ModalElevva 
                        isOpen={showAlertModal}
                        handleClose={handleClose}  
                      />
                     )} */}
                </div></>
              ) : null}
            </div>

            <div
              className="col-6 d-flex"
              style={{
                padding: "0 5%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#394F65",
                  fontSize: "3vmin",

                  fontWeight: "600",
                }}
              >
                {AllVideos1 &&
                  AllVideos1[0].videos.filter((e) => e.id == idvideo)[0].name}
              </span>

              <span
                style={{
                  color: "#CB3D82",
                  fontSize: "1.5vmin",

                  fontWeight: "600",
                }}
              >
                {Math.round(VideoDurationList/60)} min - {AllVideos1 && AllVideos1[0].name}
              </span>

              <span
                className="my-1"
                style={{
                  color: "#394F65",
                  fontSize: "1.6vmin",
                }}
              >
               
                {AllVideos1 && AllVideos1[0].videos.filter((e) => e.id == idvideo)[0].description}
              </span>

             
                <PerfectScrollbar className="d-flex col-12" style={{height:"35vmin",}}>
                <div className="col-6">
            { AllVideos1 &&AllVideos1[0].workout_zone? <div className="my-2">
                <div className="mb-1"
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",

                    fontWeight: "600",
                  }}
                >
                  Zona a trabajar 
                </div>

                <div
                  className="d-flex "
                  style={{
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                  }}
                >
              
                   
                   {  AllVideos1? <button
                       
                        onClick={() => irFiltro("workout_zone", AllVideos1 &&
                    AllVideos1[0].workout_zone)}
                        className="buttonIndividualTypes m-1"
                      >
                       {  AllVideos1 &&
                    AllVideos1[0].workout_zone == 10 ?"Tren inferior":AllVideos1[0].workout_zone == 20 ?'Abdomen': AllVideos1[0].workout_zone == 30 ?"Tren superior":"Todo el cuerpo"}
                      </button>:null}
                   
                </div>
              </div>:null}
            { AllVideos1 && AllVideos1[0].workout_time? <div className="my-2">
                <div className="mb-1"
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",

                    fontWeight: "600",
                  }}
                >
                  Duración
                </div>
                <div
                  className="d-flex w-100"
                  style={{
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                  }}
                >
                 
                      <button
                      onClick={() => irFiltro("workout_time", AllVideos1 &&
                    AllVideos1[0].workout_time)}
                        className="buttonIndividualTypes m-1"
                      >
                         {  AllVideos1 &&
                    AllVideos1[0].workout_time
 == 10 ?"Entre 10 a 30 minutos":AllVideos1[0].workout_time
 == 20 ?'Entre 30 a 60 minutos':"Más de 60 minutos"}
                      </button>
                  
                </div>
              </div>:null}
              { AllVideos1 && AllVideos1[0].workout_equipment && AllVideos1[0].workout_equipment.length>0? <div className="my-2">
                <div className="mb-1"
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",

                    fontWeight: "600",
                  }}
                >
                  Equipo para entrenar
                </div>
                <div
                  className="d-flex w-100"
                  style={{
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                  }}
                >
                 
                      {AllVideos1[0].workout_equipment.map(equipo=><button
                      onClick={() => irFiltro("workout_equipment", equipo)}
                        className="buttonIndividualTypes mx-1 my-2"
                      >
                         {  equipo
 == 10 ?"Mancuernas":equipo
 == 20 ?'Bandas cerradas de resistencia':equipo
 == 30 ?'Mat de yoga':equipo
 == 40 ?'Colchoneta':equipo
 == 50 ?'Muñequeras de peso':equipo
 == 60 ?'Fit ball / Balón de pilates':equipo
 == 70 ?'Bandas de resistencia con mangos':equipo
 == 80 ?'Guantes de boxeo':equipo
 == 90 ?'Lazo de salto':equipo
 == 100 ?'Set de pump':equipo
 == 110 ?'Caminadora eléctrica':equipo
 == 120 ?'Bicicleta estática':equipo
 == 130 ?'Implementos del hogar':'Sin equipos peso corporal'}
                      </button>)}
                  
                </div>
              </div>:null}

</div>
<div className="col-6">
{ AllVideos1 && AllVideos1[0].workout_type? <div className="my-2">
                <div className="mb-1"
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",

                    fontWeight: "600",
                  }}
                >
                  Tipo de entrenamiento
                </div>
                <div
                  className="d-flex w-100"
                  style={{
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                  }}
                >
                  
                      <button
                       
                        onClick={() => irFiltro("skill_level", AllVideos1[0].workout_type)}
                        className="buttonIndividualTypes m-1"
                      >
                      {  AllVideos1 &&
                    AllVideos1[0].workout_type
 == 10 ?"Anaeróbico":AllVideos1[0].workout_type
 == 20 ?'Aeróbico':"Mixto"}
                      </button>
                    
                </div>
              </div>:null}
              { AllVideos1 && AllVideos1[0].skill_level? <div className="my-2">
                <div className="mb-1"
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",

                    fontWeight: "600",
                  }}
                >
                  Nivel
                </div>
                <div
                  className="d-flex w-100"
                  style={{
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                  }}
                >
                  
                      <button
                       
                        onClick={() => irFiltro("skill_level", AllVideos1[0].skill_level)}
                        className="buttonIndividualTypes m-1"
                      >
                      {  AllVideos1 &&
                    AllVideos1[0].skill_level
 == 10 ?"Bajo":AllVideos1[0].skill_level
 == 20 ?'Medio':"Alto"}
                      </button>
                    
                </div>
              </div>:null}
              { AllVideos1 && AllVideos1[0].discipline.length>0? <div className="my-2">
                <div className="mb-1"
                  style={{
                    color: "#394F65",
                    fontSize: "1.5vmin",

                    fontWeight: "600",
                  }}
                >
                  Disciplina
                </div>
                <div
                  className="d-flex w-100"
                  style={{
                    justifyContent: "flex-start",

                    flexWrap: "wrap",
                  }}
                >
                 
                      {AllVideos1[0].discipline.map(disci=><button
                      onClick={() => irFiltro("discipline", disci)}
                        className="buttonIndividualTypes m-1"
                      >
                         {  disci
 == 10 ?"Yoga":disci
 == 20 ?'Funcional':disci
 == 30 ?'Fuerza':disci
 == 40 ?'Balance':disci
 == 50 ?'Bajo impacto':disci
 == 60 ?'Cardio funcional':disci
 == 70 ?'HIIT':disci
 == 80 ?'Pilates':'Nutrición'}
                      </button>)}
                  
                </div>
              </div>:null}
</div>
              
                </PerfectScrollbar>
           
            </div>
          </div>

          <div
            style={{
              paddingLeft: "3%",
            }}
          >
            <span
              style={{
                color: "#394F65",
                fontSize: "2vmin",
                padding: "0 2%",
                fontWeight: "600",
              }}
            >
              Programa {AllVideos1 && AllVideos1[0].name}
            </span>
            <br />
            <div
              className="my-1"
              style={{
                color: "#394F65",
                fontSize: "1.3vmin",
                padding: "0 2%",
                maxWidth: "600px",
              }}
            >
              {AllVideos1 && AllVideos1[0].description}
            </div>

            <span
              style={{
                color: "#CB3D82",
                fontSize: "13px",
                padding: "1% 2%",
                fontWeight: "600",
              }}
            >
              DURACIÓN: {AllVideos1 &&AllVideos1[0].videos.length} {AllVideos1 &&AllVideos1[0].videos.length == 1? "CLASE":"CLASES"}
            </span>
          </div>
          <br />
          <div
            style={{
              backgroundColor: "#FDF1E8",
              paddingLeft: "5%",
              paddingTop: "1%",
            }}
          >
            {AllVideos1 &&
              AllVideos1.map((e, index) => (
                  <Videos_two
                     textTitleOne="Clases"
                textTitleTwo="del programa"
                    id={e.id}
                    key={"filtervideos" + e.id}
                   
                    contenido={e.category}
                    videosTime={[e]}
                  />
                ))}
            <br />
            <br />
          </div>
        </div>
      ) : (
        <div
          className="mx-auto movies"
          style={{
            width: "100%",
            height: "100%",

            overflowX: "hidden",
          }}
        >
          <div
            className="bannervideos d-flex py-3"
            style={{
              background: "#ffff",
              position: "sticky",
              top: "0",
              zIndex: "3",
              minheight: "10vmin",
              left: "0",
            }}
          >
            <div className="mx-3 my-1 d-flex">
          {" "}
          <button
            onClick={() => {
                 

                  return history(`/videos/info/${id}`);
                }}
            className={"button_w_h_back"}
            style={{ background: "transparent", border: "none" }}
          >
            <BackArrow width={40}  />
          </button>
          <span className="title my-auto fw-light">
           Regresar
          </span>
        </div>
            
          </div>

          <div>
          
            <div
              className="d-row col-12"
              style={{
                padding: "0% 5%",
              }}
            >
              <div
                className="col-12 d-flex"
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {AllVideos1 ? (
                  <div
                    style={{
                      padding: "0%",
                      height: "50vmin",
                    }}
                  >
                    <ReactPlayer
                      className={loadingVideos?"position-relative playervideoIndividual":"d-none"}
                      url={
                        AllVideos1 &&AllVideos1[0].videos.filter((e) => e.id == idvideo)[0]
                        .file
                    }
                    onProgress={handleProgress}
                    preload="auto"
                    
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload',
          },
        },
      }}
                    onDuration={(duration) => handleDuration(duration)}
                      playIcon={
                        <button
                          style={{
                            color: "#ffff",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="currentColor"
                            className="bi bi-play-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                          </svg>
                        </button>
                      }
                      light={
                        AllVideos1 &&  AllVideos1[0].videos.filter((e) => e.id == idvideo)[0]
                        .background
                      }
                      height="100%"
                      width={"100%"}
                      playing={true}
                      controls={controlsOn}
                      onEnded={handleVideoEnd}

                    />
                    
                  </div>
                ) : null}
            
              </div>
             
                 <div
                className="col-12 d-flex"
                style={{
                  padding: "0 0%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "#394F65",
                    fontSize: "1.3rem",

                    fontWeight: "600",
                  }}
                >
                  {AllVideos1 &&
                    AllVideos1[0].videos.filter((e) => e.id == idvideo)[0].name}
                </span>

                <span
                  style={{
                    color: "#CB3D82",
                    fontSize: "1rem",

                    fontWeight: "600",
                  }}
                >
                {Math.round(VideoDurationList/60)} min - {AllVideos1 && AllVideos1[0].name}
                </span>

                <span
                  className="my-2"
                  style={{
                    color: "#394F65",
                    fontSize: "0.8rem",
                  }}
                >
                  {AllVideos1 && AllVideos1[0].description}
                  
                </span>

               
              {   AllVideos1 && AllVideos1[0].workout_zone? <div>
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "0.9rem",

                      fontWeight: "600",
                    }}
                  >
                    Zona a trabajar
                  </span>
                  <div
                    className="d-flex "
                    style={{
                      marginTop: "1vmin",
                      marginBottom: "1vmin",
                      justifyContent: "flex-start",

                      flexWrap: "wrap",
                    }}
                  >
                
                        <button
                     
                          onClick={() => irFiltro("workout_zone", AllVideos1[0].workout_zone)}
                          className="buttonIndividualTypes m-1"
                        >
                         {  AllVideos1 &&
                    AllVideos1[0].workout_zone == 10 ?"Tren inferior":AllVideos1[0].workout_zone == 20 ?'Abdomen': AllVideos1[0].workout_zone == 30 ?"Tren superior":"Todo el cuerpo"}
                        </button>
                     
                  </div>
                </div>:null}
                { AllVideos1 && AllVideos1[0].workout_time? <div>
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "0.9rem",

                      fontWeight: "600",
                    }}
                  >
                    Duración
                  </span>
                  <div
                    className="d-flex "
                    style={{
                      marginTop: "1vmin",
                      marginBottom: "1vmin",
                      justifyContent: "flex-start",

                      flexWrap: "wrap",
                    }}
                  >
                   
                        <button
                        
                          onClick={() => irFiltro("workout_time", AllVideos1[0].workout_time)}
                          className="buttonIndividualTypes m-1"
                        >
                          {  AllVideos1 &&
                    AllVideos1[0].workout_time
 == 10 ?"Entre 10 a 30 minutos":AllVideos1[0].workout_time
 == 20 ?'Entre 30 a 60 minutos':"Más de 60 minutos"}
                        </button>
                   
                  </div>
                </div>:null}
                { AllVideos1 && AllVideos1[0].skill_level? <div>
                  <span
                    style={{
                      color: "#394F65",
                      fontSize: "0.9rem",

                      fontWeight: "600",
                    }}
                  >
                    Nivel
                  </span>
                  <div
                    className="d-flex "
                    style={{
                      marginTop: "1vmin",
                      marginBottom: "1vmin",
                      justifyContent: "flex-start",

                      flexWrap: "wrap",
                    }}
                  >
                
                        <button
                          
                          onClick={() => irFiltro("skill_level", AllVideos1[0].skill_level)}
                          className="buttonIndividualTypes m-1"
                        >
                          {  AllVideos1 &&
                    AllVideos1[0].skill_level
 == 10 ?"Bajo":AllVideos1[0].skill_level
 == 20 ?'Medio':"Alto"}
                        </button>
                    
                  </div>
                </div>:null}
              </div>
            </div>
            <br />
            <div
              style={{
                backgroundColor: "#FDF1E8",
                paddingLeft: "5%",
                paddingTop: "1%",
              }}
            >
              {AllVideos1 &&
              AllVideos1.map((e, index) => (
                  <Videos_two
                     textTitleOne="Clases"
                textTitleTwo="del programa"
                    id={e.id}
                    key={"filtervideos" + e.id}
                  
                    contenido={e.category}
                    videosTime={[e]}
                  />
                ))}
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      )}
    </div>
  );
};
export default VerTipoIndividual;
